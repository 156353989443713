import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table, Space, Loader, DatePicker, Label } from 'ebs-design';
import { useReports } from 'hooks';
import { getIndexes, formatByIndexes } from 'utils';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfYearDateFormat,
  formatDateFilters,
} from 'libs';
import { ReportInvoicesSales, ReportType } from 'types';
import { RangePickerStateProps } from '../utils';

const key = 'type';

export const InvoicesSales: React.FC = () => {
  const { t } = useIntl();

  const [range, setRange] = React.useState<RangePickerStateProps>([null, null]);

  const { data, isLoading } = useReports<ReportInvoicesSales[]>(ReportType.INVOICES_SALES, {
    ...(range?.[0] && { date_from: range?.[0] && formatDateFilters(range?.[0]) }),
    ...(range?.[1] && { date_to: range?.[1] && formatDateFilters(range?.[1]) }),
  });

  const indexes = React.useMemo(() => (data ? getIndexes({ data, key }) : {}), [data]);

  const columns: any = React.useMemo(
    () => [
      {
        title: t('type'),
        render: (item) => ({
          ...(item.type && { children: t(item.type.toLowerCase()) }),
          props: {
            rowSpan: indexes[item.type] ? indexes[item.type] : 0,
          },
        }),
        width: 100,
      },
      {
        title: t('month'),
        dataIndex: 'month_number',
        render: (month) => t(`month${month}`).slice(0, 3),
      },
      {
        title: t('total_nr_of_invoices'),
        dataIndex: 'number',
        width: 100,
      },
      {
        title: t('amount'),
        children: [
          {
            title: t('average_total'),
            dataIndex: 'amount_average_total',
          },
          {
            title: t('total'),
            dataIndex: 'amount_total',
          },
        ],
      },
      {
        title: t('commission'),
        children: [
          {
            title: t('average_total'),
            dataIndex: 'commission_average_total',
          },
          {
            title: t('total'),
            dataIndex: 'commission_total',
          },
        ],
      },
      {
        title: t('guarantee'),
        children: [
          {
            title: t('average_total'),
            dataIndex: 'guarantee_average_total',
          },
          {
            title: t('total'),
            dataIndex: 'guarantee_total',
          },
        ],
      },
    ],
    [t, indexes],
  );

  const dataTable = React.useMemo(
    () => (data ? formatByIndexes({ data, indexes, key }) : []),
    [data, indexes],
  );

  React.useEffect(() => {
    setRange([firstDayOfYearDateFormat, currentDayDateFormat]);
  }, []);

  return (
    <Loader loading={isLoading}>
      <>
        <Space className="mb-15" align="start" direction="vertical">
          <h4>{t('sales_report')}</h4>

          <Space>
            <Label text="Period" />

            <DatePicker.RangeInput
              value={range}
              onChange={(value) => setRange(value as any)}
              isClearable
              placeholderText={t('choose_period')}
              showYearDropdown
              dateFormat={dateFormat}
            />
          </Space>
        </Space>

        <Table data={dataTable} columns={columns} emptyCell="---" />
      </>
    </Loader>
  );
};
