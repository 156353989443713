import * as React from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Modal,
  Button,
  Icon,
  Space,
  Form,
  Input,
  useForm,
  useNotify,
  InputPhone,
  Loader,
} from 'ebs-design';
import { SmartSelect } from 'components';
import { company, users } from 'api';
import { UserContext } from 'contexts';
import { Properties, User } from 'types';
import { validateResponse, extractResponseProps } from 'utils';

interface Props {
  id: number;
  data: User[];
  visible: boolean;
  onClose: () => void;
  onCreate: () => void;
}

export const AddContactModal: React.FC<Props> = ({
  id,
  data: dataContacts,
  visible = false,
  onClose,
  onCreate,
}) => {
  const { t } = useIntl();
  const [form] = useForm();
  const { user } = React.useContext(UserContext);
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [selected, setSelected] = React.useState<number | undefined>();

  React.useEffect(() => {
    if (!!selected && selected !== user?.id) {
      form.resetFields();
    }
  }, [form, selected, user]);

  React.useEffect(() => {
    if (user && selected === user.id) {
      form.setFieldsValue({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
      });
    }
  }, [selected, user, form]);

  const { isLoading: isLoadingUser } = useQuery(
    ['autocomplete', selected],
    () => users.getUser(selected!),
    {
      enabled: !!selected && selected !== user?.id,
      onSuccess: ({ first_name, last_name, email, phone }) => {
        form.setFieldsValue({ first_name, last_name, email, phone });
      },
    },
  );

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const addUser = useMutation(
    (data: Properties) =>
      company.update({ companyId: id, data: { contacts: [...dataContacts, data] } }),
    {
      onError: (err) => {
        validateResponse.bind(null, form, err);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company');
        handleClose();
        onCreate();
        notify.success({ title: t('company'), description: t('success_data_save') });
      },
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddSubmit = ({ autocomplete, ...data }: Properties) => {
    addUser.mutate(data);
  };

  const onValuesChange = ({ autocomplete }) => {
    if (autocomplete) {
      setSelected(autocomplete);
    }
  };

  return (
    <Modal open={visible} onClose={handleClose} title={t('add_contact')} size="small">
      <Modal.Content className="px-20">
        <Form
          form={form}
          type="horizontal"
          onFinish={handleAddSubmit}
          onValuesChange={onValuesChange}
          labelOptions={{ className: 'text-right', col: { size: 3 } }}
        >
          <Form.Field
            name="autocomplete"
            label={t('autocomplete')}
            extra={t('complete_fields_with_data_of_user')}
          >
            <SmartSelect
              api={company.getUsers}
              filters={{ companyId: user?.company?.id }}
              transform={(results) =>
                results.map(({ id, first_name, last_name, email }) => ({
                  value: id,
                  text:
                    ((first_name || last_name) &&
                      `${first_name} ${last_name} ${email ? `(${email})` : ``}`) ||
                    '---',
                }))
              }
              enabled={!!user?.company?.id}
            />
          </Form.Field>

          <div className="pb-15">
            <Loader loading={isLoadingUser}>
              <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
                <Input />
              </Form.Field>

              <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
                <Input />
              </Form.Field>

              <Form.Field name="phone" label={t('phone')}>
                <InputPhone country="md" />
              </Form.Field>

              <Form.Field name="email" label={t('email')} rules={[{ required: true }]}>
                <Input type="email" />
              </Form.Field>
            </Loader>
          </div>

          <Modal.Footer style={{ margin: '0 -20px -30px' }}>
            <Space justify="space-between">
              <Button onClick={handleClose}>{t('cancel')}</Button>

              <Button
                type="primary"
                prefix={<Icon type="check" model="bold" />}
                onClick={() => form.submit()}
              >
                {t('save')}
              </Button>
            </Space>
          </Modal.Footer>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
