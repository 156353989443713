import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import PhoneInput from 'react-phone-input-2';
import { useIntl } from 'estafette-intl';
import { Button, Form, Input, Space, Icon, Modal, useForm, useNotify } from 'ebs-design';
import { users } from 'api';
import { useRoles } from 'hooks';
import { User, SystemRole, Properties } from 'types';
import { extractResponseProps } from 'utils';
import { SmartSelect, InputNumericForm } from 'components';

interface AddUserModalProps {
  data: Partial<User<string>>;
  role: string;
  onClose: () => void;
}

export const AddUserModal: React.FC<AddUserModalProps> = ({ data, role, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const queryClient = useQueryClient();
  const { users: usersRoles } = useRoles();
  const notify = useNotify();
  const [isCreditOfficer, setIsCreditOfficer] = React.useState(false);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const create = useMutation(users.create, {
    onSuccess: () => {
      handleClose();
      queryClient.invalidateQueries('users');
      notify.success({ title: t('users'), description: t('success_data_save') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const update = useMutation(users.update, {
    onSuccess: () => {
      handleClose();
      queryClient.invalidateQueries('users');
      notify.success({ title: t('users'), description: t('success_data_change') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  React.useEffect(() => {
    if (data && data.id) {
      form.setFieldsValue({
        ...data,
        roles_id: usersRoles
          .filter((role) => data.roles?.includes(role.text as string))
          .map((role) => role.value),
      });
    }
  }, [data, form, usersRoles]);

  const handleSubmit = React.useCallback(
    (values) => {
      if (data.id) {
        update.mutate({ id: data.id, ...values });
      } else {
        create.mutate(values);
      }
    },
    [data, create, update],
  );

  const onValuesChange = React.useCallback(
    (field: Properties) => {
      if ('roles_id' in field) {
        const roles = usersRoles
          .filter(({ text }) =>
            [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR].includes(
              text as SystemRole,
            ),
          )
          .map(({ value }) => value);

        setIsCreditOfficer(field.roles_id.some((role) => roles.includes(role)));
      }
    },
    [usersRoles],
  );

  React.useEffect(() => {
    const defaultRole = [usersRoles.find((userRole) => (userRole.text as string) === role)?.value];
    const value = { roles_id: defaultRole };

    form.setFieldsValue(value);
    onValuesChange(value);
  }, [form, role, usersRoles, onValuesChange]);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      title={t(data.id ? 'edit_user' : 'add_user')}
      size="small"
    >
      <Modal.Content className="px-20 py-30">
        <Form
          form={form}
          type="horizontal"
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          labelOptions={{ col: { size: 4 } }}
          controlOptions={{ col: { size: 7 } }}
          fieldRow={{ gy: 3 }}
        >
          <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
            <Input />
          </Form.Field>

          <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
            <Input />
          </Form.Field>

          <Form.Field name="email" label={t('email')} rules={[{ required: true }]}>
            <Input type="email" />
          </Form.Field>

          <Form.Field name="idnp" label={t('idnp')} rules={[{ required: true, len: 13 }]}>
            <Input type="number" />
          </Form.Field>

          <Form.Field name="phone" label={t('phone')} rules={[{ required: true }]}>
            <PhoneInput country="md" />
          </Form.Field>

          <Form.Field name="roles_id" label={t('roles')} rules={[{ required: true }]}>
            <SmartSelect
              mode="multiple"
              options={usersRoles.map((role) => ({ ...role, text: t(role.text as string) }))}
            />
          </Form.Field>

          {isCreditOfficer && (
            <InputNumericForm
              name="bonus_percentage"
              label={t('bonus_percentage')}
              rules={[{ required: true }]}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            loading={create.isLoading || update.isLoading}
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
