import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { company } from 'api';
import { useNotify } from 'ebs-design';
import { UserContext } from 'contexts';
import { useRoles } from 'hooks';
import { Properties, Results, User } from 'types';
import { extractResponseProps } from 'utils';

export const useAPI = ({ companyId, params }: Properties): Properties => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { t } = useIntl();
  const { id } = useParams();
  const { user } = React.useContext(UserContext);
  const { customers: customersRoles, users: usersRoles } = useRoles();

  /* Users */
  const patchUser = useMutation(
    ({ data, userId }: Properties) =>
      company.patchUser((companyId || id || user?.company?.id) as number, userId, data),
    {
      onMutate: async ({ userId, data }: Properties) => {
        const { id: companyId, ...queryParams } = params;
        const query = [
          'company-users',
          { companyId: companyId || id || user?.company?.id, ...queryParams },
        ];
        const prevData = queryClient.getQueryData(query) as Results<User<string[]>>;

        queryClient.setQueryData(query, {
          ...prevData,
          results: prevData.results.map((user) => {
            if (user.id === userId) {
              return {
                ...user,
                roles: data.roles_id.map(
                  (roleId) =>
                    [...customersRoles, ...usersRoles].find(({ value }) => value === roleId)?.text,
                ),
              };
            }

            return user;
          }),
        });

        return () => queryClient.setQueryData(query, prevData);
      },
      onError: (err, _, rollback: any) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );

        rollback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company-users');
        notify.success({ title: t('company_users'), description: t('success_data_change') });
      },
    },
  );

  const deleteUser = useMutation(
    (userId: number) => company.deleteUser({ companyId: id || user!.company?.id, userId }),
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company-users');
        notify.success({ title: t('company_users'), description: t('success_data_delete') });
      },
    },
  );

  return {
    patchUser,
    deleteUser,
  };
};
