import { SystemRole } from 'types';

const manageRoles = [
  SystemRole.SY_ADMIN,
  SystemRole.SY_CREDIT_OFFICER,
  SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
  SystemRole.SY_RISK_MANAGER,
  SystemRole.SY_CREDIT_COMMITTEE,
];

export default {
  read: {
    // Adherents table
    filterByStatus: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    filterByRequestStatus: [SystemRole.SY_RISK_MANAGER],
    columns: {
      bonus: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
      kyc: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    },

    // Adherent details page
    adherentTabs: {
      AdherentDetails: Object.keys(SystemRole),
      AdherentAffiliates: manageRoles,
      AdherentRates: manageRoles,
      AdherentContract: [...manageRoles, SystemRole.SY_COLLECTOR],
      AdherentInvoices: [...manageRoles, SystemRole.SY_COLLECTOR],
      AdherentCreditCommittee: [SystemRole.SY_ADMIN, SystemRole.SY_CREDIT_COMMITTEE],
      AdherentScoring: manageRoles,
      AdherentDebtors: manageRoles,
      AdherentManagement: manageRoles,
    },
    history: manageRoles,
    kyc: Object.keys(SystemRole), // Only to read report
    reports: manageRoles,
  },
  edit: {
    individualRates: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_RISK_MANAGER,
      SystemRole.SY_CREDIT_COMMITTEE,
    ],
  },
  delete: {},
  create: {
    individualRates: [SystemRole.SY_ADMIN],
    conclusion: {
      SY_RISK_MANAGER: [SystemRole.SY_RISK_MANAGER],
      SY_CREDIT_OFFICER: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    },
    futureRates: [SystemRole.SY_ADMIN, SystemRole.SY_RISK_MANAGER],
  },
  perform: {
    blockAdherent: [SystemRole.SY_ADMIN],
    confirmKYC: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    approve: [SystemRole.SY_CREDIT_COMMITTEE],
    decline: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_RISK_MANAGER,
      SystemRole.SY_CREDIT_COMMITTEE,
    ],
    reexamination: [SystemRole.SY_CREDIT_COMMITTEE],
    sendToApprove: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_RISK_MANAGER,
    ],
    sign: [SystemRole.SY_RISK_MANAGER, SystemRole.SY_ADMIN],
  },
};
