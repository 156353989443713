export interface CertifiedValidation {
  is_valid: boolean;
  valid_from: string;
  valid_to: string;
}

export interface CertifiedCer {
  private_file: string;
}

export interface CertifiedPfx {
  pfx_file: string;
  pfx_password: string;
}

export enum CertifiedType {
  CER = 'cer',
  PFX = 'pfx',
}
