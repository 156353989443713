import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Button, Icon, Input, Label, Modal, Space, useNotify } from 'ebs-design';
import { requests } from 'api';
import { UserContext } from 'contexts';
import { Currencies, FullCompany, RequestStatus, SystemRole } from 'types';
import { extractResponseProps } from 'utils';

interface Props extends FullCompany {
  proposed_limit?: string;
}

export const EditLimitModal: React.FC<Props> = ({
  request,
  limit,
  proposed_limit,
  proposed_limits,
  children,
}) => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { userRoles } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('0');
  const [notified, setNotified] = React.useState(false);

  const isCreditOfficer = React.useMemo(
    () =>
      userRoles.some((role) =>
        [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR].includes(
          role as SystemRole,
        ),
      ),
    [userRoles],
  );

  const isRiskManager = React.useMemo(
    () => userRoles.some((role) => [SystemRole.SY_RISK_MANAGER].includes(role as SystemRole)),
    [userRoles],
  );

  const { mutate, isLoading } = useMutation(
    ({ proposed_limit }: { proposed_limit: number }) =>
      requests.patch({
        id: request?.id,
        stage:
          isCreditOfficer &&
          [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR].includes(
            request.stage as SystemRole,
          )
            ? SystemRole.SY_RISK_MANAGER
            : SystemRole.SY_CREDIT_COMMITTEE,
        status: RequestStatus.REVIEW,
        ...(isRiskManager ? { limit: proposed_limit } : { proposed_limit }),
      }),
    {
      onSuccess: () => {
        onToggleHandler();

        queryClient.invalidateQueries('company');
        queryClient.invalidateQueries('company-request');

        notify.success({ title: t('company'), description: t('success_data_change') });
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const hasLimit = React.useMemo(
    () => proposed_limit && !!parseInt(proposed_limit),
    [proposed_limit],
  );

  React.useEffect(() => {
    setValue(
      ((!hasLimit && proposed_limits.BIC) || proposed_limit || limit.current)
        .toString()
        .replace(/\s+/g, ''),
    );
  }, [limit, hasLimit, proposed_limit, proposed_limits]);

  React.useEffect(() => {
    if (open && !hasLimit && !proposed_limits.BIC && !notified) {
      notify.error({ title: t('proposed_limits'), description: t('missing_bic_data') });
      setNotified(true);
    } else if (!open) setNotified(false);
  }, [t, open, hasLimit, proposed_limits, notify, notified]);

  const onToggleHandler = () => setOpen((i) => !i);

  const onSave = () => mutate({ proposed_limit: parseFloat(value) });

  return (
    <>
      <Modal
        open={open}
        className="pc-modal--extra-small"
        size="small"
        header={t('change_limit')}
        onClose={onToggleHandler}
      >
        <Modal.Content>
          <Space justify="center">
            {t('sum')}
            <Input
              type="number"
              suffix={<Label text={Currencies.MDL} disabled />}
              value={value}
              onChange={(v) => setValue(v as string)}
            />
          </Space>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onToggleHandler}> {t('cancel')} </Button>

            <Button
              type="primary"
              prefix={<Icon type="check" model="bold" />}
              loading={isLoading}
              onClick={onSave}
            >
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
      <div onClick={onToggleHandler}>{children}</div>
    </>
  );
};
