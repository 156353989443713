import { axios, cancelTokenHandler, stringifyUrl } from 'libs';
import { History, Results } from 'types';

export const history = {
  getList: async (params) => {
    const { data } = await axios.get<Results<History>>(stringifyUrl('/histories/list/', params), {
      cancelToken: cancelToken(history.getList.name),
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(history);
