import * as React from 'react';
import useSetState from 'react-use/esm/useSetState';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Modal,
  Space,
  Button,
  Loader,
  Row,
  Col,
  AvatarInline,
  Label,
  Badge,
  Alert,
} from 'ebs-design';
import { attachments } from 'api';
import { useFileFetch } from 'hooks';
import { PDFViewer } from 'components';
import Steps from 'features/company-form/Steps';
import { UserContext } from 'contexts';
import { Properties, AttachmentType, SystemRole, CustomerRole } from 'types';
import { baseName, downloadFile } from 'utils';
import { formatDateTime } from 'libs';

interface Props {
  open: boolean;
  data?: Properties[];
  selected: number[];
  isSystemRole: boolean;
  isLoading?: boolean;
  onClose: () => void;
}

interface NavigationProps {
  step?: number;
  prev?: number;
  next?: number;
}

const defaultNavigation = {
  step: undefined,
  prev: undefined,
  next: undefined,
};

export const AdditionalActModal: React.FC<Props> = ({
  open: isOpen,
  data: $data = [],
  selected,
  isSystemRole,
  isLoading = false,
  onClose,
}) => {
  const { t } = useIntl();
  const { userRoles } = React.useContext(UserContext);

  const [open, setOpen] = React.useState(false);
  const [{ step, prev, next }, setNavigation] = useSetState<NavigationProps>(defaultNavigation);

  const { mutate, isLoading: isLoadingSign } = useMutation(
    ({ id, redirect_url }: Properties) => attachments.getSign(id, redirect_url),
    {
      onSuccess: (response) => {
        window.open(response.redirect_url, '_self');
      },
    },
  );

  React.useEffect(() => setOpen(isOpen), [isOpen]);

  const data = React.useMemo(
    // eslint-disable-next-line no-sequences
    () => $data.reduce((results, u) => ((results[u.id] = u), results), {}),
    [$data],
  );
  const stepData = React.useMemo(() => step && data[step], [data, step]);

  const file = useFileFetch(stepData ? stepData.url : '');

  const currentUserRoleCoordinates = userRoles?.find((i) => i in CustomerRole)
    ? CustomerRole
    : SystemRole;

  React.useEffect(() => {
    if (!step && open) {
      const keys = Object.keys(data);
      const i = Object.values(data).findIndex(
        ({ signatures }) =>
          !signatures?.find((ii) =>
            Boolean(ii.user.roles.find((ii) => ii.name in currentUserRoleCoordinates)),
          ),
      );

      setNavigation({
        ...(keys[i] && { step: parseInt(keys[i]) }),
        ...(keys[i + 1] && { next: parseInt(keys[i + 1]) }),
      });
    }
  }, [data, open, step, setNavigation, currentUserRoleCoordinates]);

  const canSign = React.useMemo(
    () =>
      stepData &&
      !stepData.signatures.some(
        ({ user, signed }) =>
          signed &&
          user.roles.some(({ name }) =>
            isSystemRole
              ? Object.values(SystemRole).includes(name)
              : Object.values(CustomerRole).includes(name),
          ),
      ),
    [stepData, isSystemRole],
  );

  const onSignClick = React.useCallback(() => {
    if (stepData) {
      mutate({
        id: stepData.id,
        redirect_url: `${window.location.origin}${baseName}/invoices${
          next ? `?signId=${selected.join()}` : ''
        }`,
      });
    }
  }, [mutate, stepData, next, selected]);

  const onCloseHandler = React.useCallback(() => {
    onClose();
    setOpen(false);
    setNavigation(defaultNavigation);
  }, [onClose, setNavigation]);

  const onNext = React.useCallback(() => {
    const keys = Object.keys(data);
    const _next = keys[keys.findIndex((id) => parseInt(id) === next) + 1];

    setNavigation({
      ...(step && { prev: step }),
      ...(next && { step: next }),
      next: _next ? parseInt(_next) : undefined,
    });
  }, [data, next, step, setNavigation]);

  const onPrev = React.useCallback(() => {
    if (!prev) {
      onCloseHandler();
    } else {
      const keys = Object.keys(data);
      const _prev = keys[keys.findIndex((id) => parseInt(id) === prev) - 1];

      setNavigation({
        next: step,
        step: prev,
        prev: _prev ? parseInt(_prev) : undefined,
      });
    }
  }, [data, step, prev, setNavigation, onCloseHandler]);

  return (
    <Modal
      open={open}
      size="large"
      className="pc-act-modal"
      onClose={onCloseHandler}
      header={
        <Space className="pc-act-modal__header" align="center">
          {t('additional_act')}

          <Steps className="mb-0">
            {Object.keys(data).map((id, i) => (
              <Steps.Step
                key={i}
                count={i + 1}
                title={t(
                  data[id].type === AttachmentType.ANNEX
                    ? 'additional_deed_assignment'
                    : 'notification_assignment_receivables',
                )}
                status={
                  step === parseInt(id) ? 'active' : data[id].signed ? 'completed' : undefined
                }
              />
            ))}
          </Steps>
        </Space>
      }
    >
      <Modal.Content className="p-0 overflow-hidden">
        <Loader loading={isLoading || file.isLoading || !step}>
          <Row className="pc-act-modal__row">
            <Col size={3} className="pc-act-modal__sidebar">
              <Label type="ghost" text={t('signatures')} />

              {stepData && stepData.signatures.length ? (
                stepData.signatures.map(({ user, signed, signed_timestamp }, i) => (
                  <Badge
                    key={i}
                    type={signed ? 'success' : 'warning'}
                    text={signed ? formatDateTime(signed_timestamp) : t('pending')}
                  >
                    <AvatarInline
                      type="primary"
                      alt={`${user.first_name} ${user.last_name}`}
                      description={user.email}
                    />
                  </Badge>
                ))
              ) : (
                <Alert type="info" message={t('no_data_found')} />
              )}
            </Col>
            <Col size={9}>
              <PDFViewer file={file.data!} style={{ marginBottom: -4 }} />
            </Col>
          </Row>
        </Loader>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button type="ghost" onClick={onPrev}>
            {t(prev ? 'back' : 'cancel')}
          </Button>

          <Space>
            <Button
              type="fill"
              onClick={() => stepData && downloadFile(stepData.url, stepData.name)}
              disabled={!stepData}
            >
              {t('download')}
            </Button>

            {!isSystemRole || (isSystemRole && !step) ? (
              <Button
                type="primary"
                loading={isLoadingSign}
                onClick={onSignClick}
                disabled={!canSign || !stepData}
              >
                {t(canSign ? 'electronically_sign' : 'signed')}
              </Button>
            ) : null}

            <Button type="primary" onClick={onNext} disabled={!step || !next}>
              {t('next_step')}
            </Button>
          </Space>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
