import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Alert, Container } from 'ebs-design';
import image from 'resources/404.svg';

export const ErrorFallback: React.FC = () => {
  const { t } = useIntl();

  return (
    <Container className="pc-error">
      <img src={image} alt="" width="100%" />
      <Alert type="error" message={t('somethingWrong')} />
    </Container>
  );
};
