import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Loader } from 'ebs-design';
import { company } from 'api/company';
import { Layout, IndividualRates, HistoryRates, FutureRates } from 'components/organisms';
import { usePermissions } from 'hooks';
import { Permissions } from 'types';

import { AdherentLayout } from '../AdherentLayout';
import { AdherentProfile } from '../AdherentProfile';

export const Rates: React.FC = () => {
  const { id } = useParams();
  const can = usePermissions(Permissions.ADHERENTS);

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  return (
    <Layout>
      <AdherentLayout>
        <Loader loading={isLoading}>
          {data && <AdherentProfile data={data} isEditCategory={true} />}
        </Loader>
        <IndividualRates isCreateFutureRates={can.create.futureRates} />
        <FutureRates isCreateFutureRates={can.create.futureRates} />
        <HistoryRates />
      </AdherentLayout>
    </Layout>
  );
};
