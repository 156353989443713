import * as React from 'react';
import { ErrorFallback } from 'components';

interface Props {
  hasError: boolean;
}

const isProduction = process.env.NODE_ENV !== 'development';

export default class ErrorBoundary extends React.Component<Record<string, any>, Props> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (!isProduction) {
      console.error(error);
    }
  }

  render() {
    if (this.state.hasError && isProduction) {
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}
