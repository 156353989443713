import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, Card, Loader, Space, Table, useNotify } from 'ebs-design';
import { company } from 'api';
import { CompanyType, FactoringType } from 'types';
import { extractResponseProps, formatCategoryRates, getCategoryRatesByType } from 'utils';
import { formatDate } from 'libs';

interface Props {
  companyType?: CompanyType;
  isCreateFutureRates?: boolean;
}

export const FutureRates = ({
  companyType = CompanyType.ADHERENT,
  isCreateFutureRates = false,
}: Props) => {
  const { t } = useIntl();
  const { id } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['company', 'future-rates', { type: companyType }],
    () => company.getCompanyFutureRatesById(id, { type: companyType }),
    {
      enabled: !!id,
      select: (data) => ({
        regression: formatCategoryRates(getCategoryRatesByType(data)),
        noRegression: formatCategoryRates(
          getCategoryRatesByType(data, FactoringType.NO_REGRESSION),
        ),
      }),
    },
  );

  const { mutate, isLoading: isLoadingMutate } = useMutation(
    () => company.setCompanyFutureRates(id, { type: companyType }),
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['individual', 'rates']);
        queryClient.invalidateQueries(['company', 'future-rates']);
        queryClient.invalidateQueries(['company', 'history-rates']);
        notify.success({ title: t('rates'), description: t('success_data_save') });
      },
    },
  );

  const childrenColumns = [
    {
      title: t('category'),
      render: ({ category_name, rates_length }) => ({
        children: category_name,
        props: { rowSpan: rates_length },
      }),
    },
    {
      title: t('days'),
      render: ({ value }) => (value >= 0 ? `${value} ${t('days')}` : '-'),
    },
    {
      title: t('commission'),
      dataIndex: 'commission',
      render: (commission) => `${commission}%`,
    },
    {
      title: t('guarantee'),
      dataIndex: 'guarantee',
      render: (guarantee) => `${guarantee}%`,
    },
    {
      title: `${t('commission')} + ${t('guarantee')}`,
      dataIndex: 'gc_percent',
      render: (gc_percent) => `${gc_percent}%`,
    },
  ];

  const columnsRegression = React.useMemo(
    () => [
      {
        title: t('with_right_refund'),
        children: childrenColumns,
      },
    ],
    [t, isLoading],
  );

  const columnsNoRegression = React.useMemo(
    () => [
      {
        title: t('without_right_refund'),
        children: childrenColumns,
      },
    ],
    [t, isLoading],
  );

  return (
    <Card hidden={!data?.noRegression?.length} className="mb-25">
      <Card.Header>
        <Space align="center" justify="space-between" wrap>
          <h4>{t('future_rates')}</h4>
          {isCreateFutureRates && (
            <Button
              type="primary"
              onClick={() => mutate()}
              disabled={
                (!data?.regression?.length && !data?.noRegression?.length) || isLoadingMutate
              }
              loading={isLoadingMutate}
            >
              {t('apply_rates_now')}
            </Button>
          )}
        </Space>
      </Card.Header>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <>
          {data?.regression?.[0]?.timestamp && (
            <p className="ml-18 mt-12 mb-12">{`${t('date')}: ${formatDate(
              data?.regression?.[0]?.timestamp,
            )}`}</p>
          )}
          <Card.Body className="p-0">
            <Table
              className="pc-rates__table"
              columns={columnsRegression}
              data={data?.regression}
            />
          </Card.Body>
          {data?.noRegression?.[0]?.timestamp && (
            <p className="ml-18 mt-12 mb-2">
              {`${t('date')}: ${formatDate(data?.noRegression?.[0]?.timestamp)}`}
            </p>
          )}
          <Card.Body className="p-0">
            <Table
              className="pc-rates__table"
              columns={columnsNoRegression}
              data={data?.noRegression}
            />
          </Card.Body>
        </>
      )}
    </Card>
  );
};
