import React, { FC, useState, useMemo, useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Table,
  Card,
  Space,
  Button,
  InputSearch,
  Icon,
  SortBy,
  Loader,
  useNotify,
  Actions,
  ButtonGroup,
} from 'ebs-design';
import { committees as committeesAPI } from 'api';
import { useFilters, useQueryParams } from 'hooks';
import { Committee, Column, CommitteeStatus } from 'types';
import {
  defaultFilters,
  stringifyFactoringRange,
  getSortOptions,
  extractResponseProps,
} from 'utils';
import { Plus } from 'resources';
import { formatDate } from 'libs';

import { Pagination } from 'components';

import { CommitteeModal } from './CommitteeModal';
import { transformData } from './utils';
import { MyProfileLayout } from '../../MyProfileLayout';

export const Committees: FC = () => {
  const { t } = useIntl();
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const [filters, setFilters] = useFilters({ ...defaultFilters, ...params });

  const [committee, setCommittee] = useState<Committee | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const notify = useNotify();

  const committees = useQuery(['committees', filters], () => committeesAPI.getList(filters), {
    select: transformData,
  });

  const { mutate } = useMutation(committeesAPI.update, {
    onSuccess() {
      queryClient.invalidateQueries('committees');
      notify.success({ title: t('committee'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const deleteCommittee = useMutation(committeesAPI.delete, {
    onSuccess() {
      queryClient.invalidateQueries('committees');
      notify.success({ title: t('committees'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const onChangeSort = useCallback((ordering) => setFilters({ ordering }), [setFilters]);
  const onChangeSearch = useCallback((search) => setFilters({ search }), [setFilters]);

  const columns: Column<any>[] = useMemo(
    () => [
      {
        title: t('committee'),
        dataIndex: 'title',
        filter: 'title',
        align: 'center',
        render: (item, row) => ({
          children: item,
          props: {
            rowSpan: row._cellData?.rowSpan,
          },
        }),
      },
      {
        title: t('members'),
        align: 'center',
        width: '18%',
        render: (item, row) => {
          let children;
          if (!item._user) {
            children = '-';
          } else {
            children = `${item._user.last_name} ${item._user.first_name}`;
          }
          return {
            children,
            props: {
              className: row._cellData.className,
            },
          };
        },
      },
      {
        title: t('role'),
        align: 'center',
        width: '18%',
        render: (item, row) => {
          let children;
          if (!item._user) {
            children = '-';
          } else {
            children = item._user.roles.map((role) => t(role.name)).join(', ');
          }
          return {
            children,
            props: {
              className: row._cellData.className,
            },
          };
        },
      },
      {
        title: t('approval_limit'),
        align: 'center',
        render: (item, row) => ({
          children: stringifyFactoringRange(item.factoring_range),
          props: {
            rowSpan: row._cellData.rowSpan,
          },
        }),
      },
      {
        title: t('minimum_nr_of_votes_in_order_to_approve_the_decision'),
        width: '18%',
        onHeaderCell: () => ({
          className: 'px-20',
        }),
        render: (item, row) => ({
          children: `${item.votes_required}`,
          props: {
            rowSpan: row._cellData.rowSpan,
            className: 'px-20',
          },
        }),
      },
      {
        title: t('created_at'),
        align: 'center',
        dataIndex: 'timestamp',
        render: (value, row) => ({
          children: formatDate(value),
          props: {
            rowSpan: row._cellData.rowSpan,
            className: 'px-20',
          },
        }),
      },
      {
        title: t('disabled_date'),
        align: 'center',
        dataIndex: 'disabled_timestamp',
        render: (value, row) => ({
          children: value ? formatDate(value) : '---',
          props: {
            rowSpan: row._cellData.rowSpan,
            className: 'px-20 table-cell--border-right-none',
          },
        }),
      },
      {
        title: '',
        action: true,
        render: (item, row) => ({
          children: (
            <Actions>
              <Actions.Item
                onClick={() => {
                  setModalVisible(true);
                  setCommittee(item);
                }}
              >
                {t('edit')}
              </Actions.Item>
              <Actions.Item
                onClick={() =>
                  mutate([
                    item.id,
                    {
                      status:
                        item.status === CommitteeStatus.ACTIVE
                          ? CommitteeStatus.INACTIVE
                          : CommitteeStatus.ACTIVE,
                      ...(item.status === CommitteeStatus.INACTIVE
                        ? { disabled_timestamp: null }
                        : {}),
                    },
                  ])
                }
              >
                {t(item.status === CommitteeStatus.ACTIVE ? 'set_inactive' : 'set_active')}
              </Actions.Item>
              <Actions.Item
                onClick={() => {
                  deleteCommittee.mutate(item.id);
                }}
              >
                {t('delete')}
              </Actions.Item>
            </Actions>
          ),
          props: {
            rowSpan: row._cellData.rowSpan,
            className: 'table-cell--border-right-none',
          },
        }),
      },
    ],
    [t, deleteCommittee, mutate],
  );

  const sortOptions = useMemo(() => getSortOptions(columns), [columns]);

  const handleModalClose = useCallback(() => {
    setModalVisible(false);
    setCommittee(null);
  }, []);

  const onChangeStatus = useCallback(
    (status?: boolean) =>
      setFilters(() => ({
        status:
          status !== undefined
            ? status
              ? CommitteeStatus.ACTIVE
              : CommitteeStatus.INACTIVE
            : status,
      })),
    [setFilters],
  );

  return (
    <>
      {modalVisible && <CommitteeModal committee={committee} onClose={handleModalClose} />}
      <MyProfileLayout>
        <Space align="center" justify="space-between" className="mt-5 mb-20">
          <Space align="center">
            <h3 className="page-title">
              {t('committee_settings')} ({committees.data?.count || 0})
            </h3>
            <InputSearch
              placeholder={t('search')}
              styleType="fill"
              value={filters.search}
              onSearch={onChangeSearch}
              isClearable
            />
          </Space>
          <Space align="center">
            <ButtonGroup>
              <Button
                type={!filters?.status ? 'primary' : 'ghost'}
                onClick={() => onChangeStatus()}
              >
                {t('all')}
              </Button>

              <Button
                type={filters?.status === CommitteeStatus.ACTIVE ? 'primary' : 'ghost'}
                onClick={() => onChangeStatus(true)}
              >
                {t('active')}
              </Button>

              <Button
                type={filters?.status === CommitteeStatus.INACTIVE ? 'primary' : 'ghost'}
                onClick={() => onChangeStatus(false)}
              >
                {t('inactive')}
              </Button>
            </ButtonGroup>

            <SortBy options={sortOptions} value={filters?.ordering} onChange={onChangeSort} />

            <Button
              type="ghost"
              prefix={<Icon component={Plus} />}
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {t('add_new')}
            </Button>
          </Space>
        </Space>
        <Card>
          <Card.Body className="p-0">
            <Loader loading={committees.isLoading}>
              <Table
                className="table-no-border pc-committees__table"
                columns={columns}
                data={committees.data?.results}
              />
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Pagination data={committees.data} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </MyProfileLayout>
    </>
  );
};
