import * as React from 'react';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { Icon, Button, Card, Space, ListGroup, Upload } from 'ebs-design';
import { useUpload, usePermissions } from 'hooks';
import { downloadFile } from 'utils';
import { invoices } from 'api/invoices';
import { Invoice, Properties, Permissions, AttachmentType } from 'types';
import { Document, Download, Plus } from 'resources';

interface Props {
  data?: Invoice;
  onUpdate: () => void;
}

export const OtherDocuments: React.FC<Props> = ({ data, onUpdate }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const can = usePermissions(Permissions.INVOICES);
  const uploadProps = useUpload();

  const updateInvoice = useMutation(
    (data: Properties) => invoices.patchInvoice(id as string, data),
    {
      onSuccess: onUpdate,
    },
  );

  const otherDocuments = React.useMemo(
    () =>
      data?.attachments &&
      data.attachments.filter((attachment) => attachment.type === AttachmentType.OTHER),
    [data],
  );

  const onUploadAttachment = React.useCallback(
    (file) => {
      if (data) {
        updateInvoice.mutate({
          attachments_id: [...data.attachments.map((attachment) => attachment.id), file[0].id],
        });
      }
    },
    [data, updateInvoice],
  );

  return (
    <Card className="mb-25">
      <Card.Header bordered>
        <Space justify="space-between">
          <h4>{t('other_documents')}</h4>

          {can.create?.otherDocuments && (
            <Upload onSuccess={(fileData) => onUploadAttachment(fileData)} {...uploadProps}>
              <Button type="fill" prefix={<Icon component={Plus} />}>
                {t('upload')}
              </Button>
            </Upload>
          )}
        </Space>
      </Card.Header>
      <Card.Body className="p-0">
        <ListGroup className="no-border">
          {otherDocuments?.length ? (
            otherDocuments.map((document) => (
              <ListGroup.Item key={document.id}>
                <Space justify="space-between">
                  <Space>
                    <Icon component={Document} />
                    {document.name}
                  </Space>

                  <Button
                    prefix={<Icon component={Download} />}
                    onClick={() => downloadFile(document.url, document.name)}
                  >
                    {t('download')}
                  </Button>
                </Space>
              </ListGroup.Item>
            ))
          ) : (
            <div className="p-10 text-center">{t('no_data')}</div>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
