import React, { FC, useMemo, useCallback, createRef } from 'react';
import { useIntl } from 'estafette-intl';
import { useQueries, useMutation, useQueryClient } from 'react-query';
import { Form, FormInstance, Input, Row, Col, Table, Label, useNotify } from 'ebs-design';
import { nomenclature } from 'api';
import { useFormula } from 'hooks';
import { Unit, Classifier } from 'types';
import { extractResponseProps } from 'utils';
import { SmartSelect, InputNumericForm } from 'components';

import {
  minimumOperatorOptions,
  maximumOperatorOptions,
  getInitialValues,
  transformValues,
} from './utils';

export interface ClassifierFormValues {
  title: string;
  formulae: string;
  maximum: string;
  minimum: string;
  minimum_operator: '>' | '>=';
  maximum_operator: '<' | '<=';
  _units: Unit | number;
  score: string;
  categories: {
    maximum: string;
    minimum: string;
    minimum_operator: '>' | '>=';
    maximum_operator: '<' | '<=';
    _units: Unit | number;
  }[];
}

export interface ClassifierFormProps {
  form: FormInstance;
  closeModal(): void;
  classifier: Classifier | null;
}

export const ClassifierForm: FC<ClassifierFormProps> = ({ form, closeModal, classifier }) => {
  const { t } = useIntl();
  const ref = createRef<HTMLDivElement>();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [formula, setFormula] = useFormula(classifier);

  const [categories, currencies]: any[] = useQueries([
    {
      queryKey: ['categories'],
      queryFn: () => nomenclature.getCategories(),
    },
    {
      queryKey: ['currencies'],
      queryFn: () => nomenclature.getCurrencies(),
    },
  ]);

  const unitsOptions = useMemo(() => {
    const options = [
      {
        value: Unit.DAYS,
        text: t('days'),
      },
      {
        value: Unit.MONTHS,
        text: t('months'),
      },
      {
        value: Unit.YEARS,
        text: t('years'),
      },
      {
        value: Unit.PERCENT,
        text: t('percent'),
      },
    ];

    if (!currencies.data?.results) {
      return options;
    }

    return [
      ...options,
      // eslint-disable-next-line
      ...currencies?.data?.results?.map((currency) => ({
        value: currency.id,
        text: currency.code_name,
      })),
    ];
  }, [currencies.data, t]);

  const columns: any[] = useMemo(
    () => [
      {
        title: t('category_name'),
        dataIndex: 'code_name',
        width: '16.66%',
      },
      {
        title: t('minimum_value'),
        width: '16.66%',
        render: (item) => <InputNumericForm name={['categories', item.id, 'minimum']} />,
      },
      {
        title: t('minimum_operator'),
        width: '16.66%',
        render: (item) => (
          <Form.Field name={['categories', item.id, 'minimum_operator']}>
            <SmartSelect isClearable={false} options={minimumOperatorOptions} rootRef={ref} />
          </Form.Field>
        ),
      },
      {
        title: t('maximum_value'),
        width: '16.66%',
        render: (item) => <InputNumericForm name={['categories', item.id, 'maximum']} />,
      },
      {
        title: t('maximum_operator'),
        width: '16.66%',
        render: (item) => (
          <Form.Field name={['categories', item.id, 'maximum_operator']}>
            <SmartSelect isClearable={false} options={maximumOperatorOptions} rootRef={ref} />
          </Form.Field>
        ),
      },
      {
        title: (
          <>
            {t('units')}
            <span className="ebs-form__field__required">*</span>
          </>
        ),
        width: '16.66%',
        render: (item) => (
          <Form.Field name={['categories', item.id, '_units']} rules={[{ required: true }]}>
            <SmartSelect isClearable={false} options={unitsOptions} rootRef={ref} />
          </Form.Field>
        ),
      },
    ],
    [unitsOptions, t, ref],
  );

  const createClassifier = useMutation(nomenclature.createClassifier, {
    onSuccess: () => {
      queryClient.invalidateQueries('classifiers');
      closeModal();
      notify.success({ title: t('classifiers'), description: t('success_data_save') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const updateClassifier = useMutation(nomenclature.updateClassifier, {
    onSuccess: () => {
      queryClient.invalidateQueries('classifiers');
      closeModal();
      notify.success({ title: t('classifiers'), description: t('success_data_change') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const initialValues = useMemo(() => getInitialValues(classifier), [classifier]);

  const handleSubmit = useCallback(
    (values: ClassifierFormValues) => {
      const body = transformValues(values);
      if (classifier) {
        updateClassifier.mutate([classifier.id, body]);
        return;
      }
      createClassifier.mutate(body);
    },
    [classifier, updateClassifier, createClassifier],
  );

  const onValuesChange = (field) => {
    if (field.formula) {
      setFormula(field.formula);
    }
  };

  const renderFormula = useMemo(
    () =>
      formula.map((item) => {
        if (!isNaN(Number(item as string))) {
          return (
            <Label
              status="danger"
              type="ghost"
              text={`rd.${item}`}
              className="pc-classifier__formula"
            />
          ) as React.ReactNode;
        }

        return item;
      }),
    [formula],
  );

  return (
    <div ref={ref} style={{ maxHeight: '60vh', overflow: 'auto' }}>
      <Form
        form={form}
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <Form.Field name="title" label={t('classifier')} rules={[{ required: true }]}>
          <Input />
        </Form.Field>
        <Form.Group label={t('rules')}>
          <Row>
            <Col size={4}>
              <Form.Field name="formulae" label={t('description')}>
                <Input />
              </Form.Field>
            </Col>
            <Col size={8}>
              <Form.Field
                name="formula"
                label={t('formula')}
                rules={[{ required: true }]}
                extra={renderFormula}
              >
                <Input />
              </Form.Field>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group label={t('norm')}>
          <Row>
            <Col size={3}>
              <InputNumericForm name="minimum" label={t('minimum_value')} />
            </Col>
            <Col size={2}>
              <Form.Field name="minimum_operator" label={t('minimum_operator')}>
                <SmartSelect isClearable={false} options={minimumOperatorOptions} rootRef={ref} />
              </Form.Field>
            </Col>
            <Col size={3}>
              <InputNumericForm name="maximum" label={t('maximum_value')} />
            </Col>
            <Col size={2}>
              <Form.Field name="maximum_operator" label={t('maximum_operator')}>
                <SmartSelect isClearable={false} options={maximumOperatorOptions} rootRef={ref} />
              </Form.Field>
            </Col>
            <Col size={2}>
              <Form.Field name="_units" label={t('units')} rules={[{ required: true }]}>
                <SmartSelect isClearable={false} options={unitsOptions} rootRef={ref} />
              </Form.Field>
            </Col>
          </Row>
        </Form.Group>
        <InputNumericForm name="score" label={t('weight_score')} rules={[{ required: true }]} />
        <Table
          data={categories.data?.results}
          columns={columns}
          className="pc-classifier-form__categories__table"
        />
      </Form>
    </div>
  );
};
