import { useMemo, useState, ReactNode } from 'react';
import { Classifier } from 'types';
import { renderFormula } from 'utils';

export const useFormula = (classifier: Classifier | null): [ReactNode[], any] => {
  const [extra, setFormula] = useState(classifier?.formula || '');

  const formula = useMemo(() => renderFormula(extra, classifier), [extra, classifier]);

  return [formula, setFormula];
};
