import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Button, DatePicker, Form, Modal, Space, Table, useForm, useNotify } from 'ebs-design';
import { company } from 'api';
import { FactoringType } from 'types';
import { extractResponseProps } from 'utils';
import { toISO } from 'libs';
import { InputNumericForm } from './InputNumericForm';

type Props = {
  factoringType?: FactoringType;
  companyId?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const AddFutureRatesModal = ({ factoringType, companyId, isOpen, onClose }: Props) => {
  const { t } = useIntl();
  const notify = useNotify();
  const [form] = useForm();

  const { data, isLoading } = useQuery(
    ['individual', 'rates', { factoring_type__type: factoringType, ordering: 'value' }],
    () =>
      company.getRates(companyId, {
        factoring_type__type: factoringType,
        ordering: 'value',
      }),
    {
      enabled: !!factoringType && !!companyId,
    },
  );

  const { mutate, isLoading: isLoadingMutate } = useMutation(company.createCompanyFutureRate, {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      notify.success({ title: t('rates'), description: t('success_data_save') });
      onClose();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        title: (
          <Space justify="space-between">
            {t(
              FactoringType.REGRESSION === factoringType
                ? 'with_right_refund'
                : 'without_right_refund',
            )}
            <Form.Field name="timestamp" rules={[{ required: true }]}>
              <DatePicker className="rates-date-picker" isClearable />
            </Form.Field>
          </Space>
        ),
        children: [
          {
            title: t('category'),
            render: ({ id, personalized }) => (
              <>
                <span>{personalized?.category}</span>
                <Form.Field name={[id, 'rate']} initialValue={id} />
                <Form.Field name={[id, 'company_rate']} initialValue={personalized?.id} />
                <Form.Field name={[id, 'company']} initialValue={companyId} />
              </>
            ),
          },
          {
            title: t('days'),
            render: ({ id, value }) => (
              <InputNumericForm
                name={[id, 'value']}
                initialValue={value}
                inputProps={{ disabled: true, suffix: <>{t('days')}</> }}
              />
            ),
          },
          {
            title: t('commission'),
            render: ({ id, personalized }) => (
              <InputNumericForm
                name={[id, 'commission']}
                initialValue={personalized?.commission}
                inputProps={{ suffix: <>%</> }}
              />
            ),
          },
          {
            title: t('guarantee'),
            render: ({ id, personalized }) => (
              <InputNumericForm
                name={[id, 'guarantee']}
                initialValue={personalized?.guarantee}
                inputProps={{ suffix: <>%</> }}
              />
            ),
          },
          {
            title: t('total'),
            render: ({ id, personalized }) => (
              <InputNumericForm
                name={[id, 'gc_percent']}
                initialValue={personalized?.gc_percent}
                inputProps={{ suffix: <>%</> }}
              />
            ),
          },
        ],
      },
    ],
    [t, isLoading],
  );

  const handleSubmit = ({ timestamp, ...values }) => {
    Object.values(values).forEach((item) => {
      mutate({
        timestamp: toISO(timestamp),
        ...item,
      });
    });
  };

  return (
    <Modal size="small" header={`${t('add')} ${t('future_rates')}`} open={isOpen} onClose={onClose}>
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Content className="p-0">
          <Table className="table-no-border" columns={columns} data={data?.results} />
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onClose}>{t('cancel')}</Button>
            <Button type="primary" submit loading={isLoadingMutate} disabled={isLoadingMutate}>
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
