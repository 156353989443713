import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Card, Space, Row, Col, AvatarInline } from 'ebs-design';
import { StatusLabel } from 'components';
import { Request, RequestStatus } from 'types';

export const CreditCommitteeCard: React.FC<{ data: Request }> = ({ data }) => {
  const { t } = useIntl();
  const currency = data.committee?.factoring_range.currency.code_name || 'MDL';

  return (
    <Card collapsible className="mb-20">
      <Card.Header bordered>
        {data.committee?.title || t('SY_CREDIT_COMMITTEE')} ({data.committee?.users.length || 0})
      </Card.Header>

      <Card.Body>
        <Row gy={4}>
          {data.committee?.users?.map((user, i) => {
            const vote = data.votes.find((v) => v.user.id === user.id);

            return (
              <Col key={i} size={4} sm={2} md={4} lg={3}>
                <Card>
                  <Card.Header>
                    <Space align="start" justify="space-between">
                      <AvatarInline
                        circle
                        alt={`${user.last_name} ${user.first_name}`}
                        description={`${data.committee?.factoring_range.minimum}-${data.committee?.factoring_range.maximum} ${currency}`}
                      />
                      <StatusLabel status={vote?.status || RequestStatus.REVIEW} />
                    </Space>
                  </Card.Header>
                  <Card.Body className="bg-white">
                    <Space>
                      <span className="fw-600 color-primary">{t('limit')}</span>
                      <>{vote?.limit ? `${vote.limit} ${currency}` : '-'}</>
                    </Space>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};
