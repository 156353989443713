import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useSetState } from 'react-use';
import { Loader, Card, Table, Actions, Space, Button, Icon, useNotify } from 'ebs-design';
import { nomenclature } from 'api';
import { Column, FullPenalty, Properties } from 'types';
import { defaultFilters, extractResponseProps, getPenaltyWithText } from 'utils';
import { ConfirmationModal, Pagination } from 'components';
import { Plus } from 'resources';
import { MyProfileLayout } from 'features/profile/MyProfileLayout';
import { PenaltyFormModal } from 'features/profile/pages/Penalties';

export const Penalties: React.FC = () => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [state, setState] = React.useState({ isOpen: false, penaltyId: undefined });
  const [removeId, setRemoveId] = React.useState<number>();

  const [filters, setFilters] = useSetState<Properties>(defaultFilters);

  const { data: penalties, isLoading } = useQuery(['penalties', filters], () =>
    nomenclature.getPenalties(filters),
  );

  const { mutate } = useMutation(nomenclature.deletePenalty, {
    onSuccess() {
      queryClient.invalidateQueries(['penalties']);
      notify.success({ title: t('committees'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('name'),
        dataIndex: 'name',
      },
      {
        title: t('value'),
        dataIndex: 'value',
      },
      {
        title: null,
        action: true,
        render: ({ id }) => (
          <Actions>
            <Actions.Item onClick={() => setState({ isOpen: true, penaltyId: id })}>
              {t('edit')}
            </Actions.Item>
            <Actions.Item onClick={() => setRemoveId(id)}>{t('delete')}</Actions.Item>
          </Actions>
        ),
      },
    ],
    [t],
  );

  const data = React.useMemo(
    () =>
      penalties?.results?.map((fullPenalty: FullPenalty) => ({
        name: fullPenalty?.name,
        id: fullPenalty.id,
        value: getPenaltyWithText(fullPenalty?.penalty, t, 'no_value'),
      })),

    [t, penalties],
  );

  const onConfirmDelete = () => {
    if (removeId) {
      mutate(removeId);

      setRemoveId(undefined);
    }
  };

  return (
    <MyProfileLayout>
      {!!removeId && (
        <ConfirmationModal
          title={`${t('penalty')} - ${removeId}`}
          description={t('are_you_sure_to_want_delete')}
          onConfirm={onConfirmDelete}
          onCancel={() => setRemoveId(undefined)}
          visible={true}
        />
      )}

      {state.isOpen && (
        <PenaltyFormModal
          penaltyId={state?.penaltyId}
          onClose={() => setState({ isOpen: false, penaltyId: undefined })}
        />
      )}

      <Space align="center" justify="space-between" className="mb-20 mt-5">
        <h3 className="page-title">
          {t('penalties')} {penalties?.count || 0}
        </h3>

        <Button
          type="ghost"
          prefix={<Icon component={Plus} />}
          onClick={() => {
            setState({ isOpen: true, penaltyId: undefined });
          }}
        >
          {t('add_new')}
        </Button>
      </Space>

      <Card>
        <Card.Body className="p-0">
          <Loader loading={isLoading}>
            <Table className="table-no-border" columns={columns} data={data} />
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination data={penalties} filters={filters} setFilters={setFilters} />
        </Card.Footer>
      </Card>
    </MyProfileLayout>
  );
};
