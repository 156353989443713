import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Properties } from 'types';

/* eslint-disable no-template-curly-in-string */
export const useValidateMessages = (): Properties => {
  const { t } = useIntl();

  const typeTemplate = React.useMemo(() => "'${name}' " + t('is_not_valid') + ' ${type}', [t]);
  const messages = React.useMemo(
    () => ({
      default: t('validation_error_field') + " '${name}'",
      required: "'${name}' " + t('is_required'),
      enum: "'${name}' " + t('must_be_one_of') + ' [${enum}]',
      whitespace: "'${name}' " + t('cannot_be_empty'),
      date: {
        format: "'${name}' " + t('is_invalid_format_date'),
        parse: "'${name}' " + t('could_not_be_parsed_as_date'),
        invalid: "'${name}' " + t('is_invalid_date'),
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate,
      },
      string: {
        len: "'${name}' " + t('must_be_exactly') + ' ${len} ' + t('characters'),
        min: "'${name}' " + t('must_be_at_least') + ' ${min} ' + t('characters'),
        max: "'${name}' " + t('cannot_be_longer_than') + ' ${max} ' + t('characters'),
        range:
          "'${name}' " +
          t('must_be_between') +
          ' ${min} ' +
          t('and') +
          ' ${max} ' +
          t('characters'),
      },
      number: {
        len: "'${name}' " + t('must_equal') + ' ${len}',
        min: "'${name}' " + t('cannot_be_less_than') + ' ${min}',
        max: "'${name}' " + t('cannot_be_greater_than') + ' ${max}',
        range: "'${name}' " + t('must_be_between') + ' ${min} ' + t('and') + ' ${max}',
      },
      array: {
        len: "'${name}' " + t('must_be_exactly') + ' ${len} ' + t('in_length'),
        min: "'${name}' " + t('cannot_be_less_than') + ' ${min} ' + t('in_length'),
        max: "'${name}' " + t('cannot_be_greater_than') + ' ${max} ' + t('in_length'),
        range:
          "'${name}' " + t('must_be_between') + ' ${min} ' + t('and') + ' ${max} ' + t('in_length'),
      },
      pattern: {
        mismatch: "'${name}' " + t('does_not_match_pattern') + ' ${pattern}',
      },
    }),
    [t, typeTemplate],
  );

  return messages;
};
