import React, { useEffect, useState } from 'react';

export interface PDFViewerProps {
  className?: string;
  file: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
}

export const PDFViewer: React.FC<PDFViewerProps> = ({ file, className, style, onLoad }) => {
  const [rendersCount, setRendersCount] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setRendersCount((i) => ++i);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <object
      key={rendersCount}
      type="application/pdf"
      data={`${file}#zoom=Fit&scrollbar=0&toolbar=0&navpanes=0`}
      className={className}
      onLoad={onLoad}
      style={style}
    >
      <embed
        src={`${file}#zoom=Fit&scrollbar=0&toolbar=0&navpanes=0`}
        type="application/pdf"
        className="full-width"
      />
    </object>
  );
};
