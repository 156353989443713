import { default as queryString } from 'query-string';
import { axios, cancelTokenHandler } from 'libs';
import { Invoice, ListTurnover, Properties, Results, Turnover, TurnoverTotal } from 'types';
import { transformResponse } from 'utils';

export const turnovers = {
  create: async (bodyData: Partial<Turnover>) => {
    return await axios.post<Turnover>(`/turnovers/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(turnovers.create.name),
    });
  },
  update: async (bodyData: Pick<Invoice, 'id' | 'turnovers'>) => {
    const { data } = await axios.patch<Invoice>(`/invoices/${bodyData.id}/turnovers/`, bodyData, {
      cancelToken: cancelToken(turnovers.getList.name),
    });

    return data;
  },
  getList: async (invoiceId: number) => {
    const { data } = await axios.get<Results<Turnover>>(`/invoices/${invoiceId}/turnovers/list/`, {
      cancelToken: cancelToken(turnovers.getList.name),
    });

    return data;
  },
  getCalculate: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<TurnoverTotal>(
      queryString.stringifyUrl(
        {
          url: `/turnovers/calculate/`,
          query: queryKey[1],
        },
        { skipEmptyString: true },
      ),
      {
        cancelToken: cancelToken(turnovers.getList.name),
      },
    );

    return data;
  },
  getTurnoversList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<ListTurnover>>(
      queryString.stringifyUrl(
        {
          url: `/turnovers/list/`,
          query: queryKey[1],
        },
        { skipEmptyString: true },
      ),
      {
        cancelToken: cancelToken(turnovers.getList.name),
      },
    );

    return data;
  },
  getTurnoversById: async (id: number) => {
    const { data } = await axios.get<Results<ListTurnover>>(`/turnovers/${id}/`, {
      cancelToken: cancelToken(turnovers.getList.name),
    });

    return data;
  },
  updateTurnovers: async (bodyData: Partial<Turnover>) => {
    const { data } = await axios.patch(`/turnovers/${bodyData?.id}/`, bodyData, {
      cancelToken: cancelToken(turnovers.getList.name),
    });

    return data;
  },
  deleteTurnovers: async (id?: number) => {
    const { data } = await axios.delete(`/turnovers/${id}/`, {
      cancelToken: cancelToken(turnovers.getList.name),
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(turnovers);
