import { ClassifierCategory, FactoringRange, Penalty } from 'types';

export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

export const generateString = (length = 8) => Math.random().toString(36).slice(-length);

export const stringifyCategoryData = (
  { period, minimum, maximum, minimum_operator, maximum_operator, currency }: ClassifierCategory,
  t: (key: string) => string,
): string => {
  let str = '';
  if (minimum_operator) {
    str += minimum_operator;
  }
  if (minimum) {
    str += +minimum;
    if (maximum) {
      str += '-';
    }
  }
  if (maximum_operator) {
    str += maximum_operator;
  }
  if (maximum) {
    str += +maximum;
  }
  if (period === '%') {
    str += period;
  } else if (/\w+/.test(period)) {
    str = `${str} ${t(period.toLowerCase())}`;
  } else if (period === '-') {
    str = `${str} ${currency?.code_name || period}`;
  }
  return str;
};

export const stringifyFactoringRange = (factoringRange: FactoringRange): string => {
  if (!factoringRange) {
    return '';
  }
  const { minimum, maximum, currency } = factoringRange;
  return `${minimum} - ${maximum} ${currency.code_name || ''}`;
};

export const NO_DATA_PLACEHOLDER = '---';

const formatPenalty = (
  penalty: Penalty,
  t: (
    key: string,
    params?:
      | {
          [key: string]: string;
        }
      | undefined,
  ) => string,
): string | undefined => {
  if (penalty?.state) {
    if (penalty?.rate?.state) {
      return `${penalty?.percent}% + ${t('nbm_rate')}( ${penalty?.rate?.percent} )`;
    }
    return `${penalty?.percent}%`;
  } else if (penalty?.daily?.state) {
    return `${penalty?.daily?.percent}% ${t('per_day')}`;
  } else if (penalty?.daily?.currency?.state) {
    return `${penalty?.daily?.currency?.amount} ${penalty?.daily?.currency?.code_name} ${t(
      'per_day',
    )}`;
  }
};

export const getPenaltyWithText = (
  penalty: Penalty,
  t: (
    key: string,
    params?:
      | {
          [key: string]: string;
        }
      | undefined,
  ) => string,
  placeholder = 'no_penalty',
) => formatPenalty(penalty, t) || t(placeholder);
