import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table } from 'ebs-design';
import { formatNumber } from 'utils';
import { Column } from 'types';
import { BacklogsChartItem } from '../interfaces';

export const BacklogsTable: React.FC<{ data?: BacklogsChartItem[] }> = ({ data = [] }) => {
  const { t } = useIntl();

  const columns: Column<BacklogsChartItem>[] = React.useMemo(
    () => [
      {
        title: t('month'),
        dataIndex: 'name',
      },
      {
        title: t('sum'),
        dataIndex: 'amount',
        render: (value) => formatNumber(value),
      },
      {
        title: t('amount'),
        dataIndex: 'total',
        render: (value) => formatNumber(value, '', 0),
      },
    ],
    [t],
  );

  return <Table data={data} columns={columns} emptyCell="---" scroll={{ y: 420 }} />;
};
