import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { Icon, Label, Card, Space, Switch, useNotify } from 'ebs-design';
import { invoices } from 'api';
import { usePermissions } from 'hooks';
import { Invoices } from 'resources';
import { Invoice, Status, Properties, Permissions } from 'types';
import { BigAvatar, ActionsInvoice } from 'components';
import { extractResponseProps } from 'utils';

import { InvoiceDetails } from './InvoiceDetails';

export const InvoiceProfile: React.FC<{ data?: Invoice }> = ({ data }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const can = usePermissions(Permissions.INVOICES);
  const [checkedInvoice, setCheckedInvoice] = React.useState((data && data.verified) || false);
  const queryClient = useQueryClient();
  const notify = useNotify();
  const status = data && data.status ? data.status : Status.OTHER;

  const { mutate } = useMutation((params: Properties) => invoices.patchInvoice(id, params), {
    onMutate: async (params: Properties) => {
      const query = ['invoice', id];
      const prevData = queryClient.getQueryData(query) as Invoice;

      queryClient.setQueryData(query, {
        ...prevData,
        ...params,
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('invoice');
      notify.success({ title: t('invoice'), description: t('success_data_save') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  React.useEffect(() => {
    if (data && checkedInvoice !== data.verified) {
      setCheckedInvoice(data.verified);
    }
  }, [checkedInvoice, data]);

  return (
    <Card className="mt-15 mb-25">
      <Card.Header>
        <Space justify="space-between">
          <BigAvatar
            title={data?.code}
            subtitle={
              <Label status={Status[status]} type="fill" circle text={t(status.toLowerCase())} />
            }
            icon={<Icon component={Invoices} />}
          />
          {can.perform.verify && (
            <Space>
              <Switch
                checked={checkedInvoice}
                onChange={() => mutate({ verified: !checkedInvoice })}
              />
              <span>{t('debtor_invoice_verified')}</span>
            </Space>
          )}
        </Space>
      </Card.Header>
      <Card.Body>
        <InvoiceDetails data={data} />
      </Card.Body>
      <ActionsInvoice data={data} />
    </Card>
  );
};
