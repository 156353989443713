import { SystemRole, CustomerRole } from 'types';

const manageRoles = [
  SystemRole.SY_ADMIN,
  SystemRole.SY_CREDIT_OFFICER,
  SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
  SystemRole.SY_RISK_MANAGER,
  SystemRole.SY_CREDIT_COMMITTEE,
];

export default {
  read: {
    // Debtors table
    filterByStatus: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    filterByRequestStatus: [SystemRole.SY_RISK_MANAGER],
    columns: {
      kyc: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    },

    // Debtors details page
    debtorTabs: {
      DebtorDetails: [
        ...Object.keys(SystemRole),
        ...Object.keys(CustomerRole),
        SystemRole.SY_COLLECTOR,
      ],
      DebtorAffiliates: [...manageRoles],
      DebtorRates: [...manageRoles, ...Object.keys(CustomerRole)],
      DebtorInvoices: [...manageRoles, ...Object.keys(CustomerRole), SystemRole.SY_COLLECTOR],
      DebtorCreditCommittee: [SystemRole.SY_ADMIN, SystemRole.SY_CREDIT_COMMITTEE],
      DebtorContract: manageRoles,
      DebtorScoring: manageRoles,
      DebtorAdherents: manageRoles,
      DebtorManagement: manageRoles,
    },
    kyc: Object.keys(SystemRole), // Only to read report
    reports: manageRoles,
    conclusions: manageRoles,
  },
  edit: {
    debtor: [
      ...Object.keys(SystemRole).filter(
        (r) => ![SystemRole.SY_COLLECTOR].includes(r as SystemRole),
      ),
      CustomerRole.ADMINISTRATOR,
    ],
    individualRates: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_RISK_MANAGER,
      SystemRole.SY_CREDIT_COMMITTEE,
    ],
  },
  delete: {},
  create: {
    individualRates: [SystemRole.SY_ADMIN],
    conclusion: {
      SY_RISK_MANAGER: [SystemRole.SY_RISK_MANAGER],
      SY_CREDIT_OFFICER: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    },
    futureRates: [SystemRole.SY_ADMIN, SystemRole.SY_RISK_MANAGER],
  },
  perform: {
    blockDebtor: [SystemRole.SY_ADMIN],
    confirmKYC: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    approve: [SystemRole.SY_CREDIT_COMMITTEE],
    decline: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_RISK_MANAGER,
      SystemRole.SY_CREDIT_COMMITTEE,
    ],
    reexamination: [SystemRole.SY_CREDIT_COMMITTEE],
    sendToApprove: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_RISK_MANAGER,
    ],
  },
};
