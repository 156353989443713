import { axios, cancelTokenHandler } from 'libs';
import { CertifiedValidation } from 'types';

export const externals = {
  validateCer: async () => {
    const { data } = await axios.get<CertifiedValidation>(`/externals/reporting/cer/`, {
      cancelToken: cancelToken(externals.validateCer.name),
    });

    return data;
  },
  addCer: async (body: FormData) => {
    const { data } = await axios.post(`/externals/reporting/cer/`, body, {
      cancelToken: cancelToken(externals.addCer.name),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  },

  validatePfx: async () => {
    const { data } = await axios.get<CertifiedValidation>(`/externals/reporting/pfx/`, {
      cancelToken: cancelToken(externals.validatePfx.name),
    });

    return data;
  },

  addPfx: async (body: FormData) => {
    const { data } = await axios.post(`/externals/reporting/pfx/`, body, {
      cancelToken: cancelToken(externals.addPfx.name),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(externals);
