import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import { Table, Card, Space, Loader, Input, Button, useNotify, Actions } from 'ebs-design';
import { formatDate } from 'libs';
import { contracts } from 'api';
import { extractResponseProps, defaultFilters } from 'utils';
import { Column, ContractNumberList } from 'types';
import { Pagination } from 'components';
import { MyProfileLayout } from '../../MyProfileLayout';
import { UpdateNumberModal } from './UpdateNumberModal';

import { useQueryParams } from 'hooks';

export const ContractsNumber: React.FC = () => {
  const { t } = useIntl();
  const queryClient = useQueryClient();
  const { ...params } = useQueryParams();
  const notify = useNotify();

  const [number, setNumber] = React.useState<string | number>('');
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });
  const [contract, setContract] = useSetState<{
    visible: boolean;
    data: Partial<ContractNumberList>;
  }>({
    visible: false,
    data: {},
  });

  const { data: contractNumber } = useQuery(['contracts-number'], contracts.getLastNumber);
  const { data: contractsList, isLoading: isLoadingContracts } = useQuery(
    ['contracts-list', { ...filters }],
    contracts.getNumberList,
  );

  const { mutate: addContractNumber } = useMutation(contracts.addLastNumber, {
    onSuccess: () => {
      queryClient.invalidateQueries(['contracts-number']);
      queryClient.invalidateQueries(['contracts-list']);

      notify.success({
        title: t('contracts'),
        description: t('success_data_change'),
      });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const columns: Column<ContractNumberList>[] = React.useMemo(
    () => [
      {
        title: t('created_by'),
        dataIndex: ['company', 'title'],
      },
      {
        title: t('nr_contract'),
        dataIndex: 'number',
      },
      {
        title: t('order'),
        dataIndex: ['number_instance', 'order'],
      },
      {
        title: t('created_at'),
        render: ({ timestamp }) => (timestamp ? formatDate(timestamp) : '---'),
      },
      {
        title: t('sign_date'),
        render: ({ signed_date }) => (signed_date ? formatDate(signed_date) : '---'),
      },
      {
        title: null,
        action: true,
        render: (data) =>
          !data.signed_date && (
            <Actions>
              <Actions.Item onClick={() => setContract({ visible: true, data })}>
                {t('edit')}
              </Actions.Item>
            </Actions>
          ),
      },
    ],
    [contracts],
  );

  return (
    <>
      <MyProfileLayout>
        <Space className="mt-5 mb-20">
          {t('last_active_contract_number')}
          <Input
            type="number"
            onChange={(value) => setNumber(value)}
            placeholder={contractNumber?.results[0]?.order?.toString()}
          />
          <Button onClick={() => addContractNumber({ order: number })}>{t('add')}</Button>
        </Space>

        <Card>
          <Card.Header>{t('contracts')}</Card.Header>
          <Card.Body className="p-0">
            <Loader loading={isLoadingContracts}>
              <Table
                className="table-no-border pc-contracts__table"
                columns={columns}
                data={contractsList?.results}
                emptyCell="---"
              />
            </Loader>
          </Card.Body>

          <Card.Footer>
            <Pagination data={contractsList} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </MyProfileLayout>

      <UpdateNumberModal
        data={contract.data}
        visible={contract.visible}
        onClose={() => setContract({ visible: false, data: {} })}
      />
    </>
  );
};
