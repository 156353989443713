import * as React from 'react';
import { useQuery } from 'react-query';
import { Card, Loader, Table } from 'ebs-design';
import { CustomerRole, Properties } from 'types';
import { history } from 'api';
import { Pagination } from 'components';
import { useSetState } from 'react-use';
import { defaultFilters } from 'utils';

import { formatData } from './utils';
import { useColumns } from './useColumns';

interface HistoryTableProps {
  id?: number;
  model: string;
  query?: Properties;
  columns?: string[];
  excludeAdherents?: boolean;
}

export const HistoryTable: React.FC<HistoryTableProps> = ({
  id,
  model,
  query = {},
  columns: historyColumns = [],
  excludeAdherents,
}) => {
  const columns = useColumns(historyColumns);

  const [pagination, setPagination] = useSetState({ ...defaultFilters, limit: 200 });

  const { data: historyData, isLoading } = useQuery(
    ['history', { reference: id, model, ...query, ...pagination }],
    ({ queryKey }) => history.getList(queryKey[1]),
  );

  const data = React.useMemo(
    () =>
      historyData?.results
        ? formatData(
            historyData.results.filter(({ user }) =>
              excludeAdherents
                ? !user?.roles.some((role) => Object.keys(CustomerRole).includes(role.name))
                : true,
            ),
            historyColumns,
          )
        : [],
    [historyData, historyColumns, excludeAdherents],
  );

  return (
    <Card>
      <Card.Body className="p-0">
        <Loader loading={isLoading}>
          <Table columns={columns} scroll={{ y: 500 }} data={data} />
        </Loader>
      </Card.Body>
      {!isLoading && (
        <Card.Footer>
          <Pagination data={historyData} filters={pagination} setFilters={setPagination} />
        </Card.Footer>
      )}
    </Card>
  );
};
