import { common } from 'api';

export const MAX_SIZE_UPLOAD = 20971520; // 20MB

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank');
  if (newWindow) {
    newWindow.opener = null;
  }
};

const download = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || '');
  link.setAttribute('target', '_blank');

  // Hide link elment
  link.style.display = 'none';

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const downloadFile = async (
  url: string,
  fileName?: string,
  preview?: boolean,
): Promise<void> => {
  if (!url) {
    return;
  }

  if (preview) {
    openInNewTab(url);
    return;
  }

  try {
    const blob = await common.getFile(url);
    download(blob, fileName);
  } catch (err) {
    console.warn('Error downloading file: >>', err);
  }
};

export const downloadBlobFile = async (blob: Blob, fileName?: string) => {
  try {
    const url = window.URL.createObjectURL(blob);
    download(url, fileName);
  } catch (err) {
    console.warn('Error downloading file: >>', err);
  }
};
