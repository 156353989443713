import * as React from 'react';
import { Link, useParams, useLocation, matchPath } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { Container, Tabs } from 'ebs-design';
import { usePermissions } from 'hooks';
import { routes } from 'routes';
import { getRoute } from 'utils';
import { Permissions } from 'types';
import { stringifyUrl } from 'libs';

export const AdherentLayout: React.FC = ({ children }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const { pathname } = useLocation();
  const can = usePermissions(Permissions.ADHERENTS);

  const matchRoute = React.useMemo(
    () => routes.find((props) => matchPath(pathname, props)),
    [pathname],
  );

  const renderTabs = React.useMemo(
    () =>
      [
        <Tabs.Tab
          key="AdherentDetails"
          tabKey="AdherentDetails"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentDetails', { id }))}>
              {t('general_information')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="AdherentAffiliates"
          tabKey="AdherentAffiliates"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentAffiliates', { id }))}>
              {t('affiliates_company')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="AdherentRates"
          tabKey="AdherentRates"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentRates', { id }))}>{t('rates')}</Link>
          }
        />,
        <Tabs.Tab
          key="AdherentContract"
          tabKey="AdherentContract"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentContract', { id }))}>
              {t('factoring_contract')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="AdherentInvoices"
          tabKey="AdherentInvoices"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentInvoices', { id }))}>
              {t('invoices')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="AdherentDebtors"
          tabKey="AdherentDebtors"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentDebtors', { id }))}>
              {t('debtors')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="AdherentCreditCommittee"
          tabKey="AdherentCreditCommittee"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentCreditCommittee', { id }))}>
              {t('SY_CREDIT_COMMITTEE')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="AdherentScoring"
          tabKey="AdherentScoring"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentScoring', { id }))}>
              {`${t('information_analyzed')} (${t('scoring')})`}
            </Link>
          }
        />,
        <Tabs.Tab
          key="AdherentManagement"
          tabKey="AdherentManagement"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'AdherentManagement', { id }))}>
              {t('management')}
            </Link>
          }
        />,
      ].filter((tab) => !!can.read?.adherentTabs?.[tab.props?.tabKey]),
    [can, id, t],
  );

  return (
    <Container>
      <Tabs activeTab={matchRoute?.name} className="company-tab">
        {renderTabs}
      </Tabs>
      {children}
    </Container>
  );
};
