export const certified = {
  certified_type: {
    en: `Certificate type`,
    ro: `Tipul certificatului`,
    ru: `Тип сертификата`,
  },
  upload_certified: {
    en: `Upload certificate`,
    ro: `Încarcă certificatul`,
    ru: `Загрузите сертификат`,
  },
  certificates: {
    en: `Certificates`,
    ro: `Certificate`,
    ru: `Сертификаты`,
  },
  validate_certified: {
    en: `Certificate validation`,
    ro: `Validarea certificatelor`,
    ru: `Проверка сертификатов`,
  },
  validate: {
    en: `Validate`,
    ro: `Validare`,
    ru: `Проверка`,
  },
  valid: {
    en: `Valid`,
    ro: `Valid`,
    ru: `Действительный`,
  },
  check_validation: {
    en: `Check validation`,
    ro: `Verificați validarea`,
    ru: `Проверить валидность`,
  },
};
