import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Button, Modal, Space, Table } from 'ebs-design';
import { useSetState } from 'react-use';
import { HistoryTable } from 'components';
import { Column, RequestCompany, HistoryModels, RequestType } from 'types';
import { formatDate } from 'libs';

interface Props {
  data: RequestCompany;
}

export const AdherentTable: React.FC<Props> = ({ data }) => {
  const { t } = useIntl();
  const [history, setHistory] = useSetState<{ adherentId?: number; visible: boolean }>({
    visible: false,
  });

  const columns: Column<RequestCompany>[] = React.useMemo(
    () => [
      {
        title: t('factoring_contract_number'),
        dataIndex: ['contract', 'number'],
      },
      {
        title: t('contract_sign_date'),
        dataIndex: ['contract', 'signed_date'],
        render: (signedData) => (signedData ? formatDate(signedData) : '---'),
      },
      {
        title: t('expired_contract_date'),
        dataIndex: ['contract', 'expiring_date'],
        render: (expiringData) => (expiringData ? formatDate(expiringData) : '---'),
      },
      {
        title: t('ceiling'),
        children: [
          {
            title: 'approved',
            render: (rowData: RequestCompany) => rowData.limit?.current,
          },
          {
            title: 'available',
            render: (rowData: RequestCompany) => rowData.limit?.accessible,
          },
        ],
      },
      {
        title: t('nr_of_remaining_invoices'),
        dataIndex: ['statistics', 'invoices', 'remained', 'amount'],
      },
      {
        title: t('amount_on_remaining_invoices'),
        dataIndex: ['statistics', 'invoices', 'remained', 'count'],
      },
      {
        title: null,
        action: true,
        width: 100,
        render: ({ id }) => (
          <Space justify="end">
            <Button
              size="small"
              onClick={() => setHistory({ adherentId: id, visible: true })}
              disabled={history.visible && history.adherentId === id}
            >
              {t('history')}
            </Button>
          </Space>
        ),
      },
    ],
    [t, setHistory, history],
  );

  return (
    <>
      <Table emptyCell="---" columns={columns} data={[data]} />

      <Modal
        open={history.visible}
        size="large"
        title={t('history')}
        onClose={() => setHistory({ visible: false, adherentId: undefined })}
      >
        <Modal.Content className="p-0 pc-history-table">
          <HistoryTable
            model={HistoryModels.REQUEST}
            columns={['limit']}
            query={{ old_company: history.adherentId!, old_type: RequestType.CARD }}
            excludeAdherents
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
