import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import cn from 'classnames';
import { Layout as EBSLayout, Sidebar, Icon, Badge } from 'ebs-design';
import { Chat } from 'components';
import { useModules } from 'hooks';
import { Message } from 'resources/icons';
import logo from 'resources/logo_dark.png';
import { TooltipMenu } from './TooltipMenu';
import { ErrorBoundary } from './ErrorBoundary';
import { Notifications } from './Notifications';
import { getRoute } from 'utils';
import { routes } from 'routes';

interface LayoutProps {
  className?: string;
}

const { Topbar, Content, Footer } = EBSLayout;
const { TopMenu, BottomMenu, Item } = Sidebar;

export const Layout: React.FC<LayoutProps> = ({ className, children }) => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { indexModule, allModules } = useModules();
  const [chatOpen, setChatOpen] = useState(false);
  const [count, setCount] = useState(0);

  const onGo = useCallback((route?: string) => route && push(getRoute(routes, route)), [push]);
  const onTitleClick = useCallback(() => onGo(indexModule?.route), [indexModule, onGo]);

  const chat = useMemo(
    () => <Chat open={chatOpen} onChangeCount={setCount} onClose={() => setChatOpen(false)} />,
    [chatOpen],
  );

  return (
    <>
      <EBSLayout className={cn(className)}>
        <Topbar>
          <Topbar.Toggler />

          <Topbar.Title>
            <img src={logo} onClick={onTitleClick} alt="Bizon Factoring" />
          </Topbar.Title>

          <Topbar.RightSide>
            <Notifications />
            <TooltipMenu />
          </Topbar.RightSide>
        </Topbar>

        <Sidebar>
          <TopMenu toggleText={t('hide')}>
            {Object.keys(allModules).map((module, i) => (
              <Item
                key={i}
                prefix={
                  <Icon
                    {...(allModules[module].iconType
                      ? { type: allModules[module].iconType }
                      : { component: allModules[module].iconComponent })}
                  />
                }
                text={allModules[module].label}
                onClick={() => onGo(allModules[module].route)}
                active={allModules[module].active}
              />
            ))}
          </TopMenu>

          <BottomMenu>
            <Item
              prefix={
                <Badge count={count}>
                  <Icon component={Message} />
                </Badge>
              }
              text={t('chat')}
              onClick={() => setChatOpen(true)}
            />
          </BottomMenu>
        </Sidebar>

        <Content>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Content>

        <Footer />
      </EBSLayout>
      {chat}
    </>
  );
};
