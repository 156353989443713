import * as React from 'react';
import { DatePicker as DPicker, DatePickerProps } from 'ebs-design';
import { dateFormat } from 'libs';

export const DatePicker = React.forwardRef<DatePickerProps, any>((props, ref) => {
  if (props.type !== 'period') {
    return (
      <DPicker ref={ref} isClearable dateFormat={dateFormat} dropdownMode="select" {...props} />
    );
  }

  return (
    <DPicker.Range
      ref={ref}
      isClearable
      dropdownMode="select"
      startProps={{
        dateFormat,
      }}
      endProps={{
        dateFormat,
      }}
      {...props}
    />
  );
});
