import { axios, cancelTokenHandler } from 'libs';
import { Results, User, Properties, UserSetting } from 'types';
import { transformResponse } from 'utils';

export const profile = {
  getProfile: async (headers?: Properties) => {
    const { data } = await axios.get('/users/profile/', {
      headers,
      // cancelToken: cancelToken(profile.getProfile.name),
    });

    return data;
  },

  patchProfile: async (bodyData: User) => {
    const { data } = await axios.patch('/users/profile/', bodyData, {
      transformResponse,
    });

    return data;
  },

  getSettings: async (params: Properties) => {
    const { data } = await axios.get<Results<UserSetting>>('/settings/list/', {
      params,
      cancelToken: cancelToken(profile.getSettings.name),
    });

    return data;
  },

  setSettings: async ({ code_name, ...params }: Properties) => {
    const { data } = await axios.put(`/settings/${code_name}/`, params, {
      cancelToken: cancelToken(profile.setSettings.name),
    });

    return data;
  },

  updateSettings: async ({ code_name, ...params }: Properties) => {
    const { data } = await axios.patch(`/users/settings/${code_name}/`, params, {
      //cancelToken: cancelToken(profile.updateSettings.name),
    });

    return data;
  },

  deleteSettings: async ({ code_name }: Properties) => {
    const { data } = await axios.delete(`/settings/${code_name}/`, {
      cancelToken: cancelToken(profile.deleteSettings.name),
    });

    return data;
  },

  // Users settings
  updateUsersSettingsById: async (bodyData: Properties) => {
    const { userId, id, ...params } = bodyData;
    const { data } = await axios.patch(`/users/${userId}/settings/${id}/`, params);

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(profile);
