import * as React from 'react';
import { useIntl } from 'estafette-intl';
import {
  Button,
  Space,
  AvatarInline,
  Label,
  Card,
  Table,
  Loader,
  Actions,
  Icon,
  firstLetters,
  Switch,
} from 'ebs-design';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'contexts';
import { CustomerRole, Column } from 'types';
import { dateTimeFormat, formatDate } from 'libs';
import { useCompanyUsers, useRoles } from 'hooks';
import { Plus, ArrowRight, ArrowLeft } from 'resources';

import { AddUserModal } from './AddUserModal';
import { FormWizardContext } from '../FormWizard';
import { useAPI } from '../hooks';

interface Props {
  id?: string;
  selecting?: boolean;
}

const UsersData: React.FC<Props> = ({ id, selecting }) => {
  const { t } = useIntl();
  const ctx = React.useContext(FormWizardContext);
  const { push } = useHistory();
  const { user } = React.useContext(UserContext);

  const [visible, setVisible] = React.useState(false);

  const params = React.useMemo(
    () => ({
      id,
      roles__in: [
        CustomerRole.REPRESENTATIVE,
        CustomerRole.ACCOUNTANT,
        CustomerRole.ADMINISTRATOR,
      ].join('__'),
      is_active: true,
      limit: 50,
    }),
    [id],
  );

  const companyUsers = useCompanyUsers(params);

  const { customers: customersRoles, users: usersRoles } = useRoles();
  const { patchUser, deleteUser } = useAPI({ companyId: id, params });

  // It's used to edit selected user
  const [userId, setUserId] = React.useState<number>();

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('name'),
        filter: 'first_name',
        render: ({ first_name, last_name, email }) => {
          const title = [first_name, last_name].join(' ');

          return (
            <AvatarInline
              circle
              alt={title}
              shortAlt={((title && firstLetters(title)) || email[0]).toUpperCase()}
              description={email}
            />
          );
        },
        width: 250,
      },
      ...(!selecting
        ? [
            {
              title: t('role'),
              filter: 'role',
              dataIndex: 'roles',
              key: 'roles',
              render: (roles: string[]) => (
                <span>{Array.isArray(roles) ? roles.join(', ') : '---'}</span>
              ),
            },
          ]
        : []),
      ...(!selecting
        ? [
            {
              title: t('status'),
              dataIndex: 'is_active',
              key: 'is_active',
              render: (status: boolean) => (
                <Label
                  type="fill"
                  status={status ? 'success' : 'danger'}
                  circle
                  text={t(status ? 'active' : 'inactive')}
                />
              ),
            },
          ]
        : []),
      ...(!selecting
        ? [
            {
              title: t('last_activity'),
              dataIndex: 'last_login',
              key: 'last_login',
              render: (date: string) => (date ? formatDate(date, dateTimeFormat) : '---'),
            },
          ]
        : []),
      {
        title: selecting ? (
          <Button prefix={<Icon component={Plus} />} onClick={() => setVisible(true)}>
            {t('add_new')}
          </Button>
        ) : null,
        action: true,
        className: 'text-right',
        render: (rowData) => {
          return (
            <Space justify="end">
              {selecting && (
                <Switch
                  checked={rowData.roles.includes(CustomerRole.CONTACT)}
                  onChange={(value) =>
                    patchUser.mutate({
                      data: {
                        roles_id: !value
                          ? [...customersRoles, ...usersRoles]
                              .filter(
                                ({ text }) =>
                                  rowData.roles.includes(text) && text !== CustomerRole.CONTACT,
                              )
                              .map(({ value }) => value)
                          : [
                              ...[...customersRoles, ...usersRoles]
                                .filter(({ text }) => rowData.roles.includes(text))
                                .map(({ value }) => value),
                              customersRoles.find(({ text }) => text === CustomerRole.CONTACT)
                                ?.value,
                            ],
                      },
                      userId: rowData.id,
                    })
                  }
                />
              )}
              <Actions>
                <Actions.Item
                  onClick={() => {
                    if (user?.id === rowData.id) {
                      push('/profile');
                    } else {
                      setUserId(rowData.id);
                      setVisible(true);
                    }
                  }}
                >
                  {t('edit')}
                </Actions.Item>
                <Actions.Item onClick={() => deleteUser.mutate(rowData.id)}>
                  {t('delete')}
                </Actions.Item>
              </Actions>
            </Space>
          );
        },
      },
    ],
    [t, selecting, patchUser, deleteUser, customersRoles, usersRoles, user, push],
  );

  return (
    <div className="company-form__users">
      <Card className="mb-25">
        {!selecting && (
          <Card.Header bordered>
            <Space justify="space-between">
              <h4 className="card-title">{t('users_data')}</h4>
              <Button prefix={<Icon component={Plus} />} onClick={() => setVisible(true)}>
                {t('add_new')}
              </Button>
            </Space>
          </Card.Header>
        )}
        <Card.Body className="p-0">
          <Loader loading={companyUsers.isLoading}>
            <Table
              size="big"
              className="table-no-border"
              columns={columns}
              data={companyUsers.data?.results}
            />
          </Loader>
        </Card.Body>
      </Card>

      <AddUserModal
        visible={visible}
        onClose={() => {
          setVisible((v) => !v);
          setUserId(undefined);
        }}
        title={t('users_data')}
        userId={userId}
        companyId={id}
      />

      {!selecting && (
        <Card className="px-20 py-15 mb-25">
          <Space justify="end">
            <Button type="ghost" className="text-nowrap" onClick={() => ctx.prevStep()}>
              <span className="company-btn__arrow mr-10">
                <ArrowLeft />
              </span>
              {t('prev_step')}
            </Button>
            <Button type="primary" className="text-nowrap" onClick={() => ctx.nextStep()}>
              {t('back_to_list')}
              <span className="company-btn__arrow ml-10">
                <ArrowRight />
              </span>
            </Button>
          </Space>
        </Card>
      )}
    </div>
  );
};

export default UsersData;
