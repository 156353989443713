export enum ContactMode {
  SMS = 'CONTACT-SMS',
  EMAIL = 'CONTACT-EMAIL',
  PHONE = 'CONTACT-PHONE',
}

export enum SettingType {
  TIME_TO_ACTION_INTERVALS = 'TIME-TO-ACTION-INTERVALS',
  TIME_TO_ACTION_INTERVALS_INACTIVITY = 'TIME-TO-ACTION-INTERVALS-INACTIVITY',
  LIMIT_FILES_UPLOADS = 'LIMIT-FILES-UPLOADS',
  LIMIT_FILES_UPLOADS_INCREASE_LIMIT_FORM = 'LIMIT-FILES-UPLOADS-INCREASE-LIMIT-FORM',
  TIME_TO_CHECK_TRANSACTIONS = 'TIME-TO-CHECK-TRANSACTIONS',
  DELETE_INACTIVE_ACCOUNTS = 'DELETE-INACTIVE-ACCOUNTS',
  TAKE_TASKS = 'TAKE-TASKS',
  CONTACT_SMS = 'CONTACT-SMS',
  CONTACT_EMAIL = 'CONTACT-EMAIL',
  CONTACT_PHONE = 'CONTACT-PHONE',
  CHECK_CATEGORY_FIRST_VALUE = 'CHECK-CATEGORY-FIRST-VALUE',
  CHECK_CATEGORY_SECOND_VALUE = 'CHECK-CATEGORY-SECOND-VALUE',
}

export interface Setting {
  id: number;
  code_name: SettingType;
  period: string;
  state: boolean;
  value: string;
  values: any[];
}
