import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { Button, Icon, Animated, Space, useNotify, Loader, Card } from 'ebs-design';
import { company, invoices } from 'api';
import { UserContext } from 'contexts';
import { usePermissions } from 'hooks';
import {
  Permissions,
  Properties,
  Invoice,
  SystemRole,
  InvoiceStatus,
  RequestStatus,
  FactoringType,
} from 'types';
import { Bell, Check, Cross } from 'resources';
import { extractResponseProps } from 'utils';

enum States {
  APPROVE = 'approve',
  DECLINE = 'decline',
  PAYMENT = 'payment',
  SEND_TO_APPROVE = 'to-approve',
  CANCELLED = 'cancelled',
}

export const ActionsInvoice: React.FC<{ data?: Invoice }> = ({ data }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const can = usePermissions(Permissions.INVOICES);
  const { user, userRoles } = React.useContext(UserContext);

  const [state, setState] = React.useState('');
  const [entity, setEntity] = React.useState('');
  const [action, setAction] = React.useState<Properties>({});

  const { data: dataCompany, isLoading: isLoadingCompany } = useQuery(
    ['company', data?.company.id],
    () => company.get(data!.company.id),
    {
      enabled: !!data?.company,
    },
  );

  const onSuccess = (description) => {
    queryClient.invalidateQueries('invoice');

    notify.success({ title: t('invoice'), description });
  };

  const { mutate, isLoading } = useMutation(
    (params: Properties) => invoices.patchInvoice(id as string, params),
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const { mutate: mutateNotify, isLoading: isLoadingNotify } = useMutation(
    (entity) => invoices.notify(id, entity),
    {
      onSuccess: () => {
        notify.success({ title: t('invoice'), description: t('send_notification_data') });
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const { mutate: mutateActions, isLoading: isLoadingActions } = useMutation(
    (bodyData: Properties) => {
      setAction(bodyData.actions);

      return invoices.actions(id as string, bodyData);
    },
    {
      onSuccess: () => onSuccess(t('send_notification_data')),
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const onNotify = (entity) => {
    setEntity(entity);
    mutateNotify(entity);
  };

  const isCreditOfficer = React.useMemo(
    () => data && data.stage === SystemRole.SY_CREDIT_OFFICER,
    [data],
  );

  const canReview = React.useMemo(
    () =>
      data &&
      ![
        InvoiceStatus.APPROVED,
        InvoiceStatus.CLOSED,
        InvoiceStatus.DENIED,
        InvoiceStatus.SENT_TO_PAYMENT,
      ].includes(data.status),
    [data],
  );

  const disabled = React.useMemo(() => data && !userRoles.includes(data.stage), [data, userRoles]);

  const onCancelled = () => {
    setState(States.CANCELLED);

    mutate(
      { sync_ready: true, status: InvoiceStatus.CANCELLED },
      {
        onSuccess: () => onSuccess(t('has_been_cancelled')),
      },
    );
  };

  const onSendToPay = () => {
    setState(States.PAYMENT);

    mutate(
      { sync_ready: true, status: InvoiceStatus.SENT_TO_PAYMENT },
      {
        onSuccess: () => onSuccess(t('has_been_sent_for_payment')),
      },
    );
  };

  const onSendToApprove = () => {
    setState(States.SEND_TO_APPROVE);

    mutate(
      { stage: SystemRole.SY_RISK_MANAGER },
      {
        onSuccess: () => onSuccess(t('has_been_sent_approve')),
      },
    );
  };

  const onApprove = () => {
    setState(States.APPROVE);

    mutate(
      { status: InvoiceStatus.APPROVED },
      {
        onSuccess: () => onSuccess(t('has_been_approved')),
      },
    );
  };

  const onDecline = () => {
    setState(States.DECLINE);

    mutate(
      { status: InvoiceStatus.DENIED },
      {
        onSuccess: () => onSuccess(t('has_been_declined')),
      },
    );
  };

  const isRiskManager = React.useMemo(
    () => userRoles.includes(SystemRole.SY_RISK_MANAGER),
    [userRoles],
  );

  const canRecession = React.useMemo(
    () => ({
      request:
        can.perform.recession &&
        !data?.recession_executor &&
        (!isRiskManager ||
          (isRiskManager && data?.factoring_type.type === FactoringType.REGRESSION)),
      review: can.perform.reviewRecession && data?.recession_initiator && !data?.recession_executor,
    }),
    [data, can, isRiskManager],
  );

  const showFooter = React.useMemo(
    () =>
      (canRecession.request && !data?.recession_initiator) ||
      can.perform.notify?.collector ||
      can.perform.notify?.debtor ||
      (data?.company && dataCompany?.request?.status === RequestStatus.APPROVED) ||
      canRecession.review ||
      (can.perform.sendToApprove && canReview && isCreditOfficer && !disabled) ||
      (can.perform.sendToPay && !data?.sync_ready) ||
      (can.perform.approve && canReview && !disabled) ||
      (can.perform.decline && canReview && !disabled),
    [canRecession, canReview, can, isCreditOfficer, data, dataCompany, disabled],
  );

  return showFooter ? (
    <Card.Footer>
      <Animated loading={!data}>
        <Space justify="space-between">
          <Space>
            {canRecession.request && !data?.recession_initiator ? (
              <Button
                prefix={<Icon component={Bell} />}
                loading={action?.recession?.initiator_id && isLoadingActions}
                onClick={() =>
                  mutateActions({
                    actions: {
                      recession: {
                        ...(userRoles.includes(SystemRole.SY_RISK_MANAGER)
                          ? {
                              status: RequestStatus.APPROVED,
                              executor_id: user?.id,
                            }
                          : {}),
                        initiator_id: user?.id,
                      },
                    },
                  })
                }
              >
                {t('request_recession')}
              </Button>
            ) : null}
            {can.perform.notify?.collector && (
              <Button
                prefix={<Icon component={Bell} />}
                loading={isLoadingNotify && entity === 'sy_collector'}
                onClick={() => onNotify('sy_collector')}
              >
                {t('notify_collector')}
              </Button>
            )}
            {can.perform.notify?.debtor && (
              <Button
                prefix={<Icon component={Bell} />}
                loading={isLoadingNotify && entity === 'debtor'}
                onClick={() => onNotify('debtor')}
              >
                {t('notify_debtor')}
              </Button>
            )}
          </Space>
          {isLoadingCompany && <Loader.Inline />}
          <Animated loading={isLoadingCompany}>
            {data?.company &&
              dataCompany?.request?.status === RequestStatus.APPROVED &&
              data?.status !== InvoiceStatus.CANCELLED && (
                <Space>
                  {canRecession.review && (
                    <Space>
                      <Button
                        prefix={<Icon component={Check} className="color-success" />}
                        loading={
                          action?.recession?.status === RequestStatus.APPROVED && isLoadingActions
                        }
                        onClick={() =>
                          mutateActions({
                            actions: {
                              recession: {
                                status: RequestStatus.APPROVED,
                                executor_id: user?.id,
                              },
                            },
                          })
                        }
                      >
                        {t('accept_recession')}
                      </Button>
                      <Button
                        prefix={<Icon component={Cross} className="color-danger" />}
                        loading={
                          action?.recession?.status === RequestStatus.DENIED && isLoadingActions
                        }
                        onClick={() =>
                          mutateActions({
                            actions: {
                              recession: {
                                status: RequestStatus.DENIED,
                                executor_id: user?.id,
                              },
                            },
                          })
                        }
                      >
                        {t('refuse_recession')}
                      </Button>
                    </Space>
                  )}

                  {can.perform.sendToApprove && canReview && isCreditOfficer && !disabled ? (
                    <Button
                      type="primary"
                      prefix={<Icon component={Check} />}
                      onClick={onSendToApprove}
                      loading={isLoading && state === States.SEND_TO_APPROVE}
                    >
                      {t('send_to_approve')}
                    </Button>
                  ) : null}

                  {can.perform.sendToPay &&
                    !data?.sync_ready &&
                    data?.status === InvoiceStatus.APPROVED && (
                      <Button
                        type="ghost"
                        prefix={<Icon component={Cross} />}
                        onClick={onCancelled}
                        loading={isLoading && state === States.CANCELLED}
                      >
                        {t('cancelled')}
                      </Button>
                    )}

                  {can.perform.sendToPay &&
                    !data?.sync_ready &&
                    data?.status === InvoiceStatus.APPROVED && (
                      <Button
                        type="primary"
                        prefix={<Icon component={Check} />}
                        onClick={onSendToPay}
                        loading={isLoading && state === States.PAYMENT}
                      >
                        {t('send_to_payment')}
                      </Button>
                    )}

                  {can.perform.approve && canReview && !disabled ? (
                    <Button
                      type="primary"
                      prefix={<Icon component={Check} />}
                      onClick={onApprove}
                      loading={isLoading && state === States.APPROVE}
                    >
                      {t('approve')}
                    </Button>
                  ) : null}
                  {can.perform.decline && canReview && !disabled ? (
                    <Button
                      type="dark"
                      prefix={<Icon component={Cross} />}
                      onClick={onDecline}
                      loading={isLoading && state === States.DECLINE}
                    >
                      {t('decline')}
                    </Button>
                  ) : null}
                </Space>
              )}
          </Animated>
        </Space>
      </Animated>
    </Card.Footer>
  ) : null;
};
