export const reports = {
  reports: {
    en: 'Reports',
    ro: 'Rapoarte',
    ru: 'Отчеты',
  },
  statistic: {
    en: 'Statistic',
    ro: 'Statistic',
    ru: 'Статистика',
  },
  portfolio_profitability: {
    en: 'Portfolio profitability',
    ro: 'Rentabilitatea portofoliului',
    ru: 'Прибыльность портфеля',
  },
  managed_portfolio: {
    en: 'Managed portfolio',
    ro: 'Portofoliul gestionat',
    ru: 'Управляемый портфель',
  },
  backlog_sum: {
    en: 'Backlog, sum',
    ro: 'Restanțe, sumă',
    ru: 'Задолженность, сумма',
  },
  sales_sum: {
    en: 'Sales, sum',
    ro: 'Vânzari, sumă',
    ru: 'Продажи, сумма',
  },
  sales_nr: {
    en: 'Sales, number',
    ro: 'Vânzari, număr',
    ru: 'Продажи, кол.',
  },
  sales_percent: {
    en: 'Sales, percents',
    ro: 'Vânzari, procente',
    ru: 'Продажи, проценты',
  },
  see_chart: {
    en: 'See chart',
    ro: 'Vezi graficul',
    ru: 'См. Диаграмму',
  },
  invoice_dynamics: {
    en: 'Dynamics of the average invoice amount',
    ro: 'Dinamica valorii medii a facturii',
    ru: 'Динамика средней стоимости счета-фактуры',
  },
  inspector_sales: {
    en: 'Sales by inspector',
    ro: 'Vânzari per inspector',
    ru: 'Продажа инспектором',
  },
  sales: {
    en: 'Sales',
    ro: 'Vânzări',
    ru: 'Продажи',
  },
  repayments: {
    en: 'Repayments',
    ro: 'Achitări',
    ru: 'Выплаты',
  },
  sales_repayments: {
    en: 'Sales and repayments',
    ro: 'Vânzări și achitări',
    ru: 'Продажи и выплаты',
  },
  register_payments: {
    en: 'Register of payments made and received',
    ro: 'Registrul plăților efectuate și încasate',
    ru: 'Реестр произведенных и полученных платежей',
  },
  factoring_portfolio: {
    en: 'Factoring portfolio',
    ro: 'Portofoliul de factoring',
    ru: 'Факторинговый портфель',
  },
  payment_order: {
    en: 'Payment order',
    ro: 'Ordin de plată',
    ru: 'Платежное поручение',
  },
  payment_made: {
    en: 'Payment made',
    ro: 'Plata efectuată',
    ru: 'Платеж произведен',
  },
  add_payment: {
    en: 'Add payment',
    ro: 'Adăugare plată',
    ru: 'Добавить платеж',
  },
  edit_payments: {
    en: 'Edit payments',
    ro: 'Editare plăți',
    ru: 'Редактировать платежи',
  },
  operation_date: {
    en: 'Date of operation',
    ro: 'Data operațiunii',
    ru: 'Дата операции',
  },
  operation_type: {
    en: 'Operation type',
    ro: 'Tip operațiune',
    ru: 'Тип операции',
  },
  invoice_value: {
    en: 'Invoice value',
    ro: 'Valoarea Facturii',
    ru: 'Стоимость счета',
  },
  commission_factoring: {
    en: 'Commission factoring',
    ro: 'Comision factoring',
    ru: 'Комиссион факторинг',
  },
  date_term: {
    en: 'Commission factoring',
    ro: 'Comision factoring',
    ru: 'Комиссион факторинг',
  },
};
