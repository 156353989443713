import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Card } from 'ebs-design';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { ReportInvoicesRatios } from 'types';
import { abbreviateNumber } from 'utils';
import { chartColors } from '../utils';

export const InvoicesRatiosChart: React.FC<{ data?: ReportInvoicesRatios[] }> = ({ data = [] }) => {
  const { t } = useIntl();
  const [activeIndex, setActiveIndex] = React.useState(0);

  const renderActiveShape = React.useCallback(
    (props) => {
      const RADIAN = Math.PI / 180;
      const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } =
        props;

      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';

      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" style={{ fontSize: 18 }} fill={fill}>
            {t(payload.type.toLowerCase())}
          </text>

          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={chartColors.bar}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={-18}
            textAnchor={textAnchor}
            fill="#333"
          >
            {`${t('sum')}: ${abbreviateNumber(props.amount)}`}
          </text>

          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
            {`${t('total')}: ${abbreviateNumber(props.total)}`}
          </text>

          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
          >
            {`(${t('rate')} ${props.percentage.toFixed(2)}%)`}
          </text>
        </g>
      );
    },
    [t],
  );

  return (
    <Card className="report-chart__card">
      <Card.Body>
        <ResponsiveContainer width={650} height={320}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={110}
              outerRadius={150}
              fill={chartColors.line}
              dataKey="amount"
              onMouseEnter={(_, index) => setActiveIndex(index)}
            />
          </PieChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};
