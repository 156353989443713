import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table } from 'ebs-design';
import { formatNumber } from 'utils';
import { Column, Currencies, ReportInvoicesActivities } from 'types';

export const InvoicesActivitiesTable: React.FC<{ data?: ReportInvoicesActivities[] }> = ({
  data = [],
}) => {
  const { t } = useIntl();

  const columns: Column<ReportInvoicesActivities>[] = React.useMemo(
    () => [
      {
        title: t('field_of_activity'),
        dataIndex: 'activity',
        className: 'text-wrap',
      },
      {
        title: t('amount'),
        dataIndex: 'total',
        render: (value) => formatNumber(value, '', 0),
      },
      {
        title: t('sum'),
        dataIndex: 'amount',
        render: (value) => formatNumber(value, Currencies.MDL),
      },
      {
        title: t('sales_percent'),
        dataIndex: 'percentage',
        render: (value) => `${formatNumber(value)}%`,
      },
    ],
    [t],
  );

  return <Table data={data} columns={columns} emptyCell="---" scroll={{ y: 365 }} />;
};
