import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Collapse, Space, Form, useForm, Loader, Input } from 'ebs-design';
import { Option } from 'ebs-design/dist/components/molecules/Select/interfaces';
import { SmartSelect } from 'components';
import { profile } from 'api';
import { Properties, SettingType } from 'types';

const checkModes = [SettingType.TIME_TO_ACTION_INTERVALS_INACTIVITY];

export const DistributionToAnotherCO: React.FC = () => {
  const { t } = useIntl();
  const [form] = useForm();

  const { data, isLoading } = useQuery(['settings'], () => profile.getSettings({ limit: 1000 }));

  // Save settings
  const userSettings = useMutation<unknown, unknown, Properties>((data) =>
    profile.setSettings(data),
  );

  React.useEffect(() => {
    // Map settings value to the form
    if (data && !isLoading) {
      const settings = {};
      data.results.forEach(
        (setting) =>
          checkModes.includes(setting.code_name) &&
          (settings[setting.code_name] = {
            period: setting.value,
            value: setting.period,
          }),
      );

      form.setFieldsValue(settings);
    }
  }, [data, form, isLoading]);

  const optionsType: Option[] = React.useMemo(
    () => [
      {
        value: 'MINUTES',
        text: t('minutes'),
      },
      {
        value: 'HOURS',
        text: t('hours'),
      },
      {
        value: 'DAYS',
        text: t('cdays'),
      },
    ],
    [t],
  );

  const handleFinish = React.useCallback(
    (values) => {
      const key = Object.keys(values)[0];

      userSettings.mutate({
        code_name: key,
        ...(values[key].period && { value: values[key].period }),
        ...(values[key].value && { period: values[key].value }),
      });
    },
    [userSettings],
  );

  return (
    <Collapse className="pc-notifications overflow-visible">
      <Collapse.Header>{t('distribution_to_another_credit_officer')}</Collapse.Header>
      <Collapse.Body>
        <Loader loading={isLoading}>
          <Form form={form} onValuesChange={handleFinish} type="vertical">
            <Space>
              <Space size="small" direction="vertical" className="no-wrap">
                <> </>
                {t('if_inspector_does_not_react_to_new_customer_within')}
              </Space>
              <Form.Field
                label={t('period')}
                name={[SettingType.TIME_TO_ACTION_INTERVALS_INACTIVITY, 'period']}
              >
                <Input type="number" />
              </Form.Field>
              <Form.Field
                label={t('type')}
                name={[SettingType.TIME_TO_ACTION_INTERVALS_INACTIVITY, 'value']}
              >
                <SmartSelect
                  isClearable={false}
                  options={optionsType}
                  className="pc-notifications__category select-min-width"
                />
              </Form.Field>
            </Space>
          </Form>
        </Loader>
      </Collapse.Body>
    </Collapse>
  );
};
