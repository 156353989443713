import * as React from 'react';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useHistory, Link } from 'react-router-dom';
import { users } from 'api/users';
import { Tokens, Properties } from 'types';
import { Button, Form, Row, Col } from 'ebs-design';
import { OTPCode } from 'components/molecules';
import AuthenticationModal from '../../AuthenticationModal';
import { stringifyUrl } from 'libs';
import { getRoute } from 'utils';
import { routes } from 'routes';

export const OTPVerifyPage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const [token, setSms] = React.useState('');

  const resetPasswordConfirmRequest = useMutation<Tokens, unknown, Properties>(
    (formData) => users.resetPasswordVerify(formData),
    {
      onSuccess: () => push(getRoute(routes, 'ResetPasswordConfirmPage', { token })),
    },
  );

  const handleSubmit = () => {
    resetPasswordConfirmRequest.mutate({
      token,
    });
  };

  return (
    <AuthenticationModal
      title={t('confirm_password_reset')}
      description={t('then_enter_new_password')}
      footer={
        <>
          {t('already_registered')}{' '}
          <Link to={stringifyUrl(getRoute(routes, 'SignInPage'))}>{t('access_your_account')}</Link>
        </>
      }
    >
      <Form
        type="horizontal"
        onFinish={handleSubmit}
        controlOptions={{ col: { size: 9 } }}
        labelOptions={{ col: { size: 3 } }}
      >
        <Row className="mb-25">
          <Col size={3}>
            <div className="ebs-form__field__label align-items--center justify-content--end">
              {t('code')}
              <span className="ebs-form__field__required">*</span>
            </div>
          </Col>
          <Col size={9}>
            <OTPCode onChange={(value) => setSms(value)} />
          </Col>
        </Row>
        <Row>
          <Col size={3}></Col>
          <Col size={9}>
            <div className="authentication-modal-form-actions">
              <Button type="primary" submit buttonClass="uppercase" disabled={token.length < 4}>
                {t('confirm')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </AuthenticationModal>
  );
};
