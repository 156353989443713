import * as React from 'react';
import { useParams } from 'react-router-dom';
import { MyUsersLayout } from 'features/profile/MyUsersLayout';
import { Users } from './Users';
import { Customers } from './Customers';

const keyUsers = 'USERS';

export const MyUsers: React.FC = () => {
  const { role } = useParams();

  return <MyUsersLayout>{role !== keyUsers ? <Users /> : <Customers />}</MyUsersLayout>;
};
