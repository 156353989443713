import {
  Attachment,
  Invoice,
  User,
  UserRole,
  RequestCompany,
  Comment,
  Committee,
  SystemRole,
} from 'types';

export enum RequestStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  REEXAMINATION = 'REEXAMINATION',
  REVIEW = 'REVIEW',
  OTHER = 'OTHER',
  INFO_REQUIRED = 'INFO_REQUIRED',
}

export enum RequestType {
  INVOICE = 'INVOICE',
  CARD = 'CARD',
  OTHER = 'OTHER',
  INCREASE_LIMIT = 'INCREASE_LIMIT',
}

export interface Request {
  id: number;
  company: RequestCompany;
  attachments: Attachment[];
  attachments_ids: [];
  invoices: Invoice[];
  comment: string;
  conclusions: RequestConclusion[];
  proposed_limit?: string;
  timestamp: string;
  edited_timestamp: string;
  status: RequestStatus;
  stage: UserRole;
  type: RequestType;
  limit: string;
  conditions: string[];
  factoring_range: number;
  committee: Committee;
  user: number;
  comments: Comment[];
  votes: Vote[];
}

export interface RequestConclusion {
  id: number;
  user: User;
  user_id: number;
  comment: string;
  timestamp: string;
  edited_timestamp: string;
}

export interface Vote {
  id: number;
  committee: Committee;
  timestamp: string;
  edited_timestamp: string;
  limit: number | string;
  status: RequestStatus;
  comment: Comment;
  request: number;
  user: User;
}

export type CreateVote = {
  comment?: string;
  status: RequestStatus;
  stage?: SystemRole;
  proposed_limit?: string;
  limit?: string;
};
