import * as React from 'react';
import { Link, useParams, useLocation, matchPath } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { Container, Tabs } from 'ebs-design';
import { usePermissions } from 'hooks';
import { stringifyUrl } from 'libs';
import { routes } from 'routes';
import { getRoute } from 'utils';
import { Permissions } from 'types';

export const DebtorLayout: React.FC = ({ children }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const { pathname } = useLocation();
  const can = usePermissions(Permissions.DEBTORS);

  const matchRoute = React.useMemo(
    () => routes.find((props) => matchPath(pathname, props)),
    [pathname],
  );

  const renderTabs = React.useMemo(
    () =>
      [
        <Tabs.Tab
          key="DebtorDetails"
          tabKey="DebtorDetails"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorDetails', { id }))}>
              {t('general_information')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="DebtorAffiliates"
          tabKey="DebtorAffiliates"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorAffiliates', { id }))}>
              {t('affiliates_company')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="DebtorRates"
          tabKey="DebtorRates"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorRates', { id }))}>{t('rates')}</Link>
          }
        />,
        <Tabs.Tab
          key="DebtorContract"
          tabKey="DebtorContract"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorContract', { id }))}>
              {t('limits_and_rates')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="DebtorInvoices"
          tabKey="DebtorInvoices"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorInvoices', { id }))}>
              {t('invoices')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="DebtorAdherents"
          tabKey="DebtorAdherents"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorAdherents', { id }))}>
              {t('adherents')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="DebtorCreditCommittee"
          tabKey="DebtorCreditCommittee"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorCreditCommittee', { id }))}>
              {t('SY_CREDIT_COMMITTEE')}
            </Link>
          }
        />,
        <Tabs.Tab
          key="DebtorScoring"
          tabKey="DebtorScoring"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorScoring', { id }))}>
              {`${t('information_analyzed')} (${t('scoring')})`}
            </Link>
          }
        />,
        <Tabs.Tab
          key="DebtorManagement"
          tabKey="DebtorManagement"
          label={
            <Link to={stringifyUrl(getRoute(routes, 'DebtorManagement', { id }))}>
              {t('management')}
            </Link>
          }
        />,
      ].filter((tab) => !!can.read?.debtorTabs?.[tab.props?.tabKey]),
    [can, id, t],
  );

  return (
    <Container>
      <Tabs activeTab={matchRoute?.name} className="company-tab">
        {renderTabs}
      </Tabs>
      {children}
    </Container>
  );
};
