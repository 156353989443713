import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Space, Loader, Row, Col, DatePicker, Label } from 'ebs-design';
import { useReports } from 'hooks';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfYearDateFormat,
  formatDateFilters,
} from 'libs';
import { ReportType, ReportBacklogGraph } from 'types';
import { PortfoliosChart } from './charts';
import { PortfoliosTable } from './tables';
import { BacklogsChartItem } from './interfaces';
import { RangePickerStateProps } from './utils';

export const PortfoliosReport: React.FC = () => {
  const { t } = useIntl();
  const [range, setRange] = React.useState<RangePickerStateProps>([null, null]);

  const { data, isLoading } = useReports<ReportBacklogGraph[], BacklogsChartItem[]>(
    ReportType.GRAPHS_PORTFOLIOS,
    {
      ...(range?.[0] && { date_from: range?.[0] && formatDateFilters(range?.[0]) }),
      ...(range?.[1] && { date_to: range?.[1] && formatDateFilters(range?.[1]) }),
    },
    {
      keepPreviousData: true,
      select: (data = []) =>
        data.map((item) => ({
          month: item.month,
          name: t(`month${item.month}`).slice(0, 3),
          amount: item.invoices_amounts,
          total: item.invoices_totals,
          average: item.invoices_average_weighted,
        })),
    },
  );

  React.useEffect(() => {
    setRange([firstDayOfYearDateFormat, currentDayDateFormat]);
  }, []);

  return (
    <>
      <Space wrap className="mb-15" align="start" direction="vertical">
        <h4>{t('portfolio_profitability')} %</h4>

        <Space>
          <Label text="Period" />

          <DatePicker.RangeInput
            value={range}
            onChange={(value) => setRange(value as any)}
            isClearable
            placeholderText={t('choose_period')}
            dateFormat={dateFormat}
          />
        </Space>
      </Space>
      <Loader loading={isLoading}>
        <Row>
          <Col size={5}>
            <PortfoliosTable data={data} />
          </Col>
          <Col size={7}>
            <PortfoliosChart data={data} />
          </Col>
        </Row>
      </Loader>
    </>
  );
};
