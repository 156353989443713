import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table } from 'ebs-design';
import { formatNumber } from 'utils';
import { Column, Currencies } from 'types';
import { DynamicsChartItem } from '../interfaces';

export const DynamicsTable: React.FC<{ data?: DynamicsChartItem[] }> = ({ data = [] }) => {
  const { t } = useIntl();

  const columns: Column<DynamicsChartItem>[] = React.useMemo(
    () => [
      {
        title: t('month'),
        dataIndex: 'name',
      },
      {
        title: t('with_right_refund'),
        dataIndex: 'regression',
        render: (value) => formatNumber(value, Currencies.MDL),
      },
      {
        title: t('without_right_refund'),
        dataIndex: 'no_regression',
        render: (value) => formatNumber(value, Currencies.MDL),
      },
    ],
    [t],
  );

  return <Table data={data} columns={columns} emptyCell="---" scroll={{ y: 365 }} />;
};
