import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Modal,
  Form,
  Input,
  Space,
  InputPhone,
  Button,
  Icon,
  useForm,
  useNotify,
} from 'ebs-design';
import { company } from 'api';
import { UserContext } from 'contexts';
import { useRoles } from 'hooks';
import { Results, Properties, SelectOption, User } from 'types';
import { validateResponse, extractResponseProps } from 'utils';
import { SmartSelect } from 'components';

interface Props {
  visible: boolean;
  filters: Properties;
  edit?: User<string[]>;
  onClose: () => void;
}

const EditUserModal: React.FC<Props> = ({ visible = false, edit, filters, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const { user } = React.useContext(UserContext);
  const { customers, users, isLoading } = useRoles();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (edit && !isLoading) {
      form.setFieldsValue({
        ...edit,
        roles_id: [...customers, ...users]
          .filter(({ text }) => edit.roles.includes(text as string))
          .map(({ value }) => value),
      });
    }
  }, [isLoading, edit, customers, users, form]);

  const updateUser = useMutation((data) => company.updateUser(user!.company?.id, edit!.id, data), {
    onMutate: async (data: Properties) => {
      const query = ['users', { companyId: user!.company?.id, ...filters }];
      const prevData = queryClient.getQueryData(query) as Results<User<string[]>>;

      queryClient.setQueryData(query, {
        ...prevData,
        results: prevData.results.map((item) => {
          if (item.id === edit!.id) {
            item = { ...item, ...data };
          }

          return item;
        }),
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onError: (err, values, rollback: any) => {
      validateResponse(form, err, values);
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
      rollback();
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      onClose();
      notify.success({ title: t('users'), description: t('success_data_change') });
    },
  });

  const isSuperUser = React.useMemo(
    () => edit && users.some(({ text }) => edit.roles.includes(text as string)),
    [edit, users],
  );

  const rolesOptions = React.useMemo(
    () =>
      isSuperUser
        ? [...users, ...customers].map(({ value, text }: SelectOption) => ({
            value,
            text: t(text as string),
          }))
        : customers.map(({ value, text }) => ({ value, text: t(text as string) })),
    [t, users, customers, isSuperUser],
  );

  const onSubmit = React.useCallback((data) => updateUser.mutate(data), [updateUser]);

  return (
    <Modal open={visible} onClose={onClose} title={t('edit_user')} size="small">
      <Form
        form={form}
        type="horizontal"
        onFinish={onSubmit}
        labelOptions={{ className: 'text-right', col: { size: 3 } }}
      >
        <Modal.Content>
          <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
            <Input />
          </Form.Field>

          <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
            <Input />
          </Form.Field>

          <Form.Field name="idnp" label={t('idnp')} rules={[{ required: true, len: 13 }]}>
            <Input type="number" />
          </Form.Field>

          <Form.Field name="roles_id" label={t('roles')} rules={[{ required: true }]}>
            <SmartSelect
              mode="multiple"
              options={rolesOptions}
              disabled={isSuperUser}
              loading={isLoading}
            />
          </Form.Field>

          <Form.Field name="phone" label={t('phone')}>
            <InputPhone country="md" />
          </Form.Field>

          <Form.Field name="email" label={t('email')} rules={[{ required: true }]}>
            <Input type="email" />
          </Form.Field>
        </Modal.Content>

        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onClose}>{t('cancel')}</Button>

            <Button
              type="primary"
              prefix={<Icon type="check" model="bold" />}
              onClick={() => form.submit()}
            >
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
