import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Card, Form, Icon, Modal, Space, useForm, useNotify } from 'ebs-design';
import { ConfirmationModal, InputNumericForm } from 'components';
import { UserContext } from 'contexts';
import { requests } from 'api';
import { extractResponseProps } from 'utils';
import { Request, RequestStatus, SystemRole, CreateVote } from 'types';
import { Check, Cross } from 'resources';

interface Props {
  data: Request;
}

export const RequestActions: React.FC<Props> = ({ data }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { user, userRoles } = React.useContext(UserContext);
  const [visible, setVisible] = React.useState(false);

  const isCreditCommittee = React.useMemo(
    () => data.stage === SystemRole.SY_CREDIT_COMMITTEE && data.status === RequestStatus.REVIEW,
    [data.stage, data.status],
  );

  const committeeUserVoted = React.useMemo(
    () => data.votes.some((vote) => vote.user.id === user?.id) && isCreditCommittee,
    [user, isCreditCommittee, data.votes],
  );

  const vote = useMutation((variables: CreateVote) => requests.createVote(data.id, variables), {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const patchRequest = useMutation(requests.patch, {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  // Handle success
  const onSuccess = (
    type: RequestStatus.APPROVED | RequestStatus.DENIED = RequestStatus.APPROVED,
  ) => {
    notify.success({
      title: t('success'),
      description: type === RequestStatus.APPROVED ? t('requests_sent') : t('requests_declined'),
    });
    queryClient.invalidateQueries('request').then(() => setVisible(false));
    // Invalidate requests
    queryClient.invalidateQueries('requests');
  };

  // Approve request
  const handleApprove = (values) => {
    if (isCreditCommittee) {
      vote.mutate(
        { ...values, status: RequestStatus.APPROVED, limit: values.proposed_limit },
        { onSuccess: () => onSuccess(RequestStatus.APPROVED) },
      );
    } else {
      patchRequest.mutate(
        {
          ...values,
          id: data.id,
          status: RequestStatus.REVIEW,
          stage: SystemRole.SY_CREDIT_COMMITTEE,
        },
        { onSuccess: () => onSuccess(RequestStatus.APPROVED) },
      );
    }
  };

  // Decline request
  const handleDecline = () => {
    if (isCreditCommittee) {
      vote.mutate(
        { status: RequestStatus.DENIED, stage: SystemRole.SY_CREDIT_COMMITTEE },
        { onSuccess: () => onSuccess(RequestStatus.DENIED) },
      );
    } else {
      patchRequest.mutate(
        {
          id: data.id,
          status: RequestStatus.DENIED,
          stage: SystemRole.SY_RISK_MANAGER,
        },
        { onSuccess: () => onSuccess(RequestStatus.DENIED) },
      );
    }
  };

  // Return null if request is approved or denied
  if (
    [RequestStatus.APPROVED, RequestStatus.DENIED].includes(data.status) ||
    committeeUserVoted ||
    !userRoles.includes(data.stage)
  ) {
    return null;
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Space justify="end">
            <Button
              onClick={() => setVisible(true)}
              prefix={<Icon component={Check} />}
              type="primary"
              loading={vote.isLoading || patchRequest.isLoading}
            >
              {isCreditCommittee ? t('approve') : t('send_for_approval')}
            </Button>
            <ConfirmationModal
              description={t('are_you_sure_to_want_decline')}
              onConfirm={handleDecline}
            >
              <Button prefix={<Icon component={Cross} />} type="dark">
                {t('decline')}
              </Button>
            </ConfirmationModal>
          </Space>
        </Card.Header>
      </Card>

      <Modal
        open={visible}
        size="small"
        title={t('propose_limit')}
        onClose={() => setVisible(false)}
      >
        <Modal.Content>
          <Form form={form} onFinish={handleApprove} id="add-comment-form">
            <InputNumericForm
              name="proposed_limit"
              label={t('limit')}
              initialValue={
                data.proposed_limit &&
                parseFloat(data.proposed_limit.replace(/\s+/g, '')).toFixed(2)
              }
            />
          </Form>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="end">
            <Button
              submit
              form="add-comment-form"
              prefix={<Icon type="check" model="bold" />}
              loading={vote.isLoading || patchRequest.isLoading}
            >
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
    </>
  );
};
