import { IndexEntry } from 'components/organisms/IndexEntry';

import {
  SignInPage,
  SignUpPage,
  ActivationPage,
  ResetPasswordPage,
  ConfirmPage,
  OTPVerifyPage,
} from 'features/public/pages';

import { Home } from 'features/home/pages';

import { Adherents } from 'features/adherents/pages';
import {
  Adherent,
  AdherentRates,
  AdherentAffiliatesCompany,
  AdherentInvoices,
  AdherentCreditCommittee,
  AdherentScoring,
  AdherentContract,
  AdherentDebtors,
  AdherentManagement,
} from 'features/adherents/adherent/pages';

import { Requests } from 'features/requests/pages';
import { Request } from 'features/requests/request/pages';

import { Reports } from 'features/reports/pages';

import { Debtors, DebtorForm } from 'features/debtors/pages';
import {
  Debtor,
  DebtorRates,
  DebtorAffiliatesCompany,
  DebtorInvoices,
  DebtorCreditCommittee,
  DebtorScoring,
  DebtorContract,
  DebtorAdherents,
  DebtorManagement,
} from 'features/debtors/debtor/pages';

import { Invoices } from 'features/invoices/pages';
import { InvoiceForm, Invoice } from 'features/invoices/invoice/pages';

import { CompanyForm } from 'features/company-form/pages';

import { LimitsAndRates } from 'features/limitsAndRates/pages';

import { Users } from 'features/users/pages';

import { Penalties as PenaltiesPage } from 'features/penalties';

import {
  Alerts,
  MyProfile,
  Penalties,
  Classifiers,
  Categories,
  MyUsers,
  Contracts,
  Committees,
  Notifications as AdminNotifications,
  Rates,
  RatesFuture,
  RatesHistory,
  ContractsNumber,
} from 'features/profile/pages';

import { ContractSignUpload } from 'features/con-sign/pages';

import { Notifications, NotificationForm } from 'features/notifications/pages';

import { Route } from 'types';
import { NotFound } from './features/NotFound/pages/NotFound';

import { Turnovers } from 'features/turnovers/pages';

export const routes: Route[] = [
  // prettier-ignore

  { name: 'IndexEntry', path: '/', exact: true, component: IndexEntry },

  { name: 'SignInPage', path: '/public/sign-in', exact: true, component: SignInPage },
  { name: 'SignUpPage', path: '/public/sign-up', exact: true, component: SignUpPage },
  { name: 'ActivationPage', path: '/public/activation', exact: true, component: ActivationPage },
  {
    name: 'ResetPasswordPage',
    path: '/public/reset-password',
    component: ResetPasswordPage,
  },
  {
    name: 'ResetPasswordConfirmPage',
    path: '/public/reset-password/:token',
    exact: true,
    component: ConfirmPage,
  },
  {
    name: 'OTPVerifyPage',
    path: '/public/otp-verify',
    component: OTPVerifyPage,
  },

  { name: 'Home', path: '/home', component: Home },

  // Adherents
  { name: 'AdherentDetails', path: '/adherents/:id', component: Adherent, exact: true },
  {
    name: 'AdherentAffiliates',
    path: '/adherents/:id/affiliates',
    component: AdherentAffiliatesCompany,
  },
  { name: 'AdherentRates', path: '/adherents/:id/rates', component: AdherentRates },
  { name: 'AdherentContract', path: '/adherents/:id/contract', component: AdherentContract },
  { name: 'AdherentInvoices', path: '/adherents/:id/invoices', component: AdherentInvoices },
  { name: 'AdherentDebtors', path: '/adherents/:id/debtors', component: AdherentDebtors },
  {
    name: 'AdherentCreditCommittee',
    path: '/adherents/:id/credit-committee',
    component: AdherentCreditCommittee,
  },
  { name: 'AdherentScoring', path: '/adherents/:id/scoring', component: AdherentScoring },
  { name: 'AdherentManagement', path: '/adherents/:id/management', component: AdherentManagement },
  { name: 'Adherents', path: '/adherents', component: Adherents, exact: true },

  // Requests
  { name: 'RequestDetails', path: '/requests/:id', component: Request },
  { name: 'Requests', path: '/requests', component: Requests, exact: true },

  // Reports
  { name: 'Reports', path: '/reports', component: Reports, exact: true },

  // Penalties
  { name: 'PenaltiesPage', path: '/penalties', component: PenaltiesPage },

  // Debtors
  { name: 'DebtorDetails', path: '/debtors/:id', component: Debtor, exact: true },
  { name: 'DebtorAffiliates', path: '/debtors/:id/affiliates', component: DebtorAffiliatesCompany },
  { name: 'DebtorRates', path: '/debtors/:id/rates', component: DebtorRates },
  { name: 'DebtorContract', path: '/debtors/:id/contract', component: DebtorContract },
  { name: 'DebtorInvoices', path: '/debtors/:id/invoices', component: DebtorInvoices },
  {
    name: 'DebtorCreditCommittee',
    path: '/debtors/:id/credit-committee',
    component: DebtorCreditCommittee,
  },
  { name: 'DebtorAdherents', path: '/debtors/:id/adherents', component: DebtorAdherents },
  { name: 'DebtorScoring', path: '/debtors/:id/scoring', component: DebtorScoring },
  { name: 'Debtors', path: '/debtors', component: Debtors, exact: true },
  { name: 'DebtorForm', path: '/debtor/:id', component: DebtorForm },
  { name: 'DebtorManagement', path: '/debtors/:id/management', component: DebtorManagement },

  // Invoices
  { name: 'EditInvoice', path: '/invoices/:id/edit', component: InvoiceForm },
  { name: 'Invoice', path: '/invoices/:id', component: Invoice, exact: true },
  { name: 'AddInvoice', path: '/invoice/create', component: InvoiceForm },
  { name: 'Invoices', path: '/invoices', component: Invoices, exact: true },

  // Users
  { name: 'Users', path: '/users', component: Users },

  { name: 'NotificationForm', path: '/notifications/create', component: NotificationForm },
  { name: 'Notifications', path: '/notifications', component: Notifications, exact: true },

  { name: 'MyProfile', path: '/profile', component: MyProfile, exact: true },
  { name: 'Classifiers', path: '/profile/classifiers', component: Classifiers, exact: true },
  {
    name: 'Categories',
    path: '/profile/classifiers/categories',
    component: Categories,
    exact: true,
  },
  { name: 'Contracts', path: '/profile/contracts', component: Contracts },
  { name: 'Committees', path: '/profile/committees', component: Committees },
  { name: 'MyUsers', path: '/profile/users', component: MyUsers, exact: true },
  { name: 'MyUsersRole', path: '/profile/users/:role', component: MyUsers },
  { name: 'Penalties', path: '/profile/penalties', component: Penalties },
  { name: 'Alerts', path: '/profile/alerts', component: Alerts },
  { name: 'AdminNotifications', path: '/profile/notifications', component: AdminNotifications },
  { name: 'Rates', path: '/profile/rates', component: Rates, exact: true },
  { name: 'RatesHistory', path: '/profile/rates/history', component: RatesHistory, exact: true },
  { name: 'RatesFuture', path: '/profile/rates/future', component: RatesFuture, exact: true },
  { name: 'ContractsNumber', path: '/profile/contracts-number', component: ContractsNumber },

  { name: 'LimitsAndRates', path: '/limits-and-rates', component: LimitsAndRates },

  // Company
  { name: 'CompanyForm', path: '/company-form', component: CompanyForm },

  {
    name: 'ContractSignUpload',
    path: '/contract-sign',
    component: ContractSignUpload,
  },
  { name: 'NotFoundPage', path: '/not-found', component: NotFound, exact: false },

  //Turnovers
  { name: 'Turnovers', path: '/turnovers', component: Turnovers },
];
