import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { default as queryString } from 'query-string';
import { Properties } from 'types';

export const useQueryParams = (): Properties => {
  const { search } = useLocation();
  return React.useMemo(() => queryString.parse(search, { arrayFormat: 'comma' }), [search]);
};

export const useQueryUpdate = (): Properties => {
  const { push, location } = useHistory();

  const updateQuery = React.useCallback(
    (filters) =>
      push(
        `${location.pathname}?${queryString.stringify(filters, {
          skipEmptyString: true,
          skipNull: true,
          arrayFormat: 'comma',
        })}`,
      ),
    [push, location.pathname],
  );

  return {
    updateQuery,
  };
};
