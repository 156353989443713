import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { nomenclature } from 'api';
import { Table, Loader, useNotify, Space, Actions } from 'ebs-design';
import { extractResponseProps, formatCategoryRates, getCategoryRatesByType } from 'utils';
import { FactoringType, CodeName } from 'types';
import { formatDate } from 'libs';
import { ConfirmationModal } from 'components';
import { AddFutureRatesModal, AddRateModal } from 'features/profile/pages';
import { RatesLayout } from 'features/profile/RatesLayout';

export const Rates: React.FC = () => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [removeRateId, setRemoveRateId] = React.useState<number>();
  const [futureRatesType, setFutureRatesType] = React.useState<FactoringType>();
  const [categoryState, setCategoryState] = React.useState<
    | {
        categoryId?: number;
        categoryName?: CodeName;
        factoringType?: FactoringType;
        factoringTypeId?: number;
      }
    | undefined
  >();

  const { data, isLoading } = useQuery(
    ['nomenclatures', 'rates'],
    () => nomenclature.getCategoriesRatesList(),
    {
      select: (data) => ({
        regression: formatCategoryRates(getCategoryRatesByType(data)),
        noRegression: formatCategoryRates(
          getCategoryRatesByType(data, FactoringType.NO_REGRESSION),
        ),
      }),
    },
  );

  const { mutate } = useMutation(nomenclature.deleteCategoriesRate, {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['nomenclatures', 'rates']);
      notify.success({ title: t('rate'), description: t('success_data_delete') });
    },
  });

  const onConfirmDelete = () => {
    if (removeRateId) {
      mutate(removeRateId);

      setRemoveRateId(undefined);
    }
  };

  const childrenColumns = [
    {
      title: t('category'),
      render: ({ category_name, rates_length }) => ({
        children: category_name,
        props: { rowSpan: rates_length },
      }),
    },
    {
      title: t('days'),
      render: ({ value }) => (value >= 0 ? `${value} ${t('days')}` : '-'),
    },
    {
      title: t('commission'),
      dataIndex: 'percent',
      render: (percent) => `${percent}%`,
    },
    {
      title: t('guarantee'),
      dataIndex: 'guarantee',
      render: (guarantee) => `${guarantee}%`,
    },
    {
      title: `${t('commission')} + ${t('guarantee')}`,
      dataIndex: 'gc_percent',
      render: (gc_percent) => `${gc_percent}%`,
    },
    {
      title: null,
      action: true,
      render: ({ id }) => (
        <Actions>
          <Actions.Item onClick={() => setRemoveRateId(id)}>{t('delete')}</Actions.Item>
        </Actions>
      ),
    },
    {
      title: null,
      action: true,
      render: ({
        factoring_type,
        factoring_type_id,
        category_name,
        category_id,
        rates_length,
      }) => ({
        props: { rowSpan: rates_length },
        children: (
          <Actions>
            <Actions.Item
              onClick={() =>
                setCategoryState({
                  categoryId: category_id,
                  categoryName: category_name,
                  factoringType: factoring_type,
                  factoringTypeId: factoring_type_id,
                })
              }
            >
              {t('add_rate')}
            </Actions.Item>
          </Actions>
        ),
      }),
    },
    {
      title: null,
      action: true,
      render: ({ factoring_type, category_length }) => ({
        props: { rowSpan: category_length },
        children: (
          <Actions>
            <Actions.Item onClick={() => setFutureRatesType(factoring_type)}>
              {t('add_future_rates')}
            </Actions.Item>
          </Actions>
        ),
      }),
    },
  ];

  const columnsRegression = React.useMemo(
    () => [
      {
        title: t('with_right_refund'),
        children: childrenColumns,
      },
    ],
    [t, isLoading],
  );

  const columnsNoRegression = React.useMemo(
    () => [
      {
        title: t('without_right_refund'),
        children: childrenColumns,
      },
    ],
    [t, isLoading],
  );

  return (
    <>
      {!!removeRateId && (
        <ConfirmationModal
          title={`${t('rate')} - ${removeRateId}`}
          description={t('are_you_sure_to_want_delete')}
          onConfirm={onConfirmDelete}
          onCancel={() => setRemoveRateId(undefined)}
          visible={true}
        />
      )}
      <AddRateModal
        category={categoryState}
        isOpen={!!categoryState}
        onClose={() => setCategoryState(undefined)}
      />
      <AddFutureRatesModal
        factoringType={futureRatesType}
        isOpen={!!futureRatesType}
        onClose={() => setFutureRatesType(undefined)}
      />

      <RatesLayout>
        <Space align="center" justify="space-between" className="mt-20 mb-16">
          <h4>{t('standard_rates')}</h4>
        </Space>

        {isLoading ? (
          <Loader loading={isLoading} />
        ) : (
          <>
            <Space className="mt-8 mb-8">
              {data?.regression?.[0]?.timestamp && (
                <p>{`${t('date')}: ${formatDate(data?.regression?.[0]?.timestamp)}`}</p>
              )}
            </Space>
            <Table
              className="pc-rates__table"
              columns={columnsRegression}
              data={data?.regression}
            />
            <Space className="mt-10 mb-8">
              {data?.noRegression?.[0]?.timestamp && (
                <p>{`${t('date')}: ${formatDate(data?.noRegression?.[0]?.timestamp)}`}</p>
              )}
            </Space>
            <Table
              className="pc-rates__table mt-10"
              columns={columnsNoRegression}
              data={data?.noRegression}
            />
          </>
        )}
      </RatesLayout>
    </>
  );
};
