import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Button, Icon, Modal, Space } from 'ebs-design';

type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  info: { title: string; idno: number };
};

export const CreateAdherentModal = ({ visible, onClose, onSubmit, info }: Props) => {
  const { t } = useIntl();
  return (
    <Modal open={visible} onClose={onClose} title={t('create_adherent')} size="small">
      <Modal.Content>
        {`${t('confirm_edit_company_information')} ${info.title}, ${info.idno}.`}
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onClose}>{t('cancel')}</Button>

          <Button type="primary" prefix={<Icon type="check" model="bold" />} onClick={onSubmit}>
            {t('confirm')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
