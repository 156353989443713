import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { AvatarInline, Icon, Tooltip, Collapse } from 'ebs-design';
import { Multitenancy } from 'components';
import { UserContext } from 'contexts';
import { axiosHeadersUpdater, stringifyUrl } from 'libs';
import { usePermissions, useFileFetch } from 'hooks';
import { Permissions, SystemRole } from 'types';
import { Edit, AddUser, Logout, User } from 'resources';
import { getRoute } from 'utils';
import { routes } from 'routes';

const languages = ['en', 'ro', 'ru'];

export const TooltipMenu: React.FC = () => {
  const { t, locale, setLocale } = useIntl();
  const { push } = useHistory();
  const { user, onLogout } = React.useContext(UserContext);
  const can = usePermissions(Permissions.PROFILE);

  const hasCompany = React.useMemo(() => !!user?.company, [user]);
  const onChangeLanguage = React.useCallback(
    (lang) => {
      axiosHeadersUpdater(lang);
      setLocale(lang);
      localStorage.setItem('lang', lang);
    },
    [setLocale],
  );

  const file = useFileFetch(user?.picture?.url);

  return (
    <Tooltip
      placement="bottom"
      trigger="click"
      bodyClass="ebs-tooltip__user-menu"
      offset={[-25, 0]}
      tooltip={
        <div>
          <span className="ebs-tooltip__user-menu__title">{t('company_management')}</span>
          <ul className="ebs-tooltip__user-menu__list">
            <Multitenancy />
            {can.read.profileMenu?.editCompany && (
              <li>
                <Link to={stringifyUrl(getRoute(routes, 'CompanyForm'))}>
                  <Icon component={Edit} />
                  {t('edit_company')}
                </Link>
              </li>
            )}
            {can.read.profileMenu?.inviteUser && (
              <li
                onClick={
                  hasCompany
                    ? () => push(getRoute(routes, 'Users', { query: { invite: true } }))
                    : undefined
                }
                className={cn({ disabled: !hasCompany })}
              >
                <Icon component={AddUser} />
                {t('invite_user')}
              </li>
            )}
            {can.read.profileMenu?.addUser && (
              <li
                onClick={() =>
                  push(
                    getRoute(routes, 'MyUsersRole', {
                      role: SystemRole.SY_ADMIN,
                      query: { invite: true },
                    }),
                  )
                }
              >
                <Icon component={AddUser} />
                {t('invite_user')}
              </li>
            )}
            <li>
              <Link to={stringifyUrl(getRoute(routes, 'MyProfile'))}>
                <Icon component={User} />
                {t('profile_settings')}
              </Link>
            </li>
            <Collapse collapsed className="ebs-tooltip__languages">
              <Collapse.Header>
                <li>
                  <Icon type="globe" />
                  {t(locale)}
                </li>
              </Collapse.Header>
              <Collapse.Body>
                {languages
                  .filter((lang) => lang !== locale)
                  .map((lang) => (
                    <li key={lang} onClick={() => onChangeLanguage(lang)}>
                      {t(lang)}
                    </li>
                  ))}
              </Collapse.Body>
            </Collapse>
            <li onClick={onLogout}>
              <Icon component={Logout} />
              {t('logout')}
            </li>
          </ul>
        </div>
      }
    >
      <AvatarInline
        type="light"
        alt={`${user?.first_name} ${user?.last_name}`}
        img={file.data}
        status="active"
        reversed
      />
    </Tooltip>
  );
};
