import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import {
  Card,
  Space,
  Table,
  Actions,
  Loader,
  Container,
  useNotify,
  Modal,
  Button,
  Icon,
  Select,
  Form,
  useForm,
  InputSearch,
} from 'ebs-design';
import { invoices, nomenclature } from 'api';
import { Column, Penalty, Properties } from 'types';
import {
  defaultFilters,
  extractResponseProps,
  getPenaltyWithText,
  NO_DATA_PLACEHOLDER,
} from 'utils';
import { formatDate } from 'libs';
import { Pagination, Layout } from 'components';

export const Penalties: React.FC = () => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [form] = useForm();

  const [contractId, setContractId] = React.useState<number>();
  const [penaltyId, setPenaltyId] = React.useState<number>();
  const [filters, setFilters] = useSetState(defaultFilters);
  const [penaltiesFilters, setPenaltiesFilters] = useSetState<Properties>(defaultFilters);

  const { data, isLoading } = useQuery(['delivery-contracts', filters], () =>
    invoices.getDeliveryContracts(filters),
  );

  const { data: penalties, isLoading: isLoadingPenalties } = useQuery(
    ['penalties', penaltiesFilters],
    () => nomenclature.getPenalties(penaltiesFilters),
  );

  const { data: fullPenalty } = useQuery(
    ['penalty', { id: penaltyId }],
    nomenclature.getPenaltyById,
    {
      enabled: !!penaltyId,
    },
  );

  const penaltiesOptions = React.useMemo(
    () =>
      (penalties?.results || [])?.map(({ id, name }) => ({
        text: name,
        value: id,
      })),
    [penalties, penaltiesFilters],
  );

  const { mutate } = useMutation(
    (data: Partial<Penalty>) => invoices.updateDeliveryContract(contractId!, { penalty: data }),

    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['delivery-contracts']);
        notify.success({ title: t('penalty'), description: t('success_data_save') });
      },
    },
  );

  const handleCloseModal = () => {
    setContractId(undefined);
    setPenaltyId(undefined);
    setPenaltiesFilters(defaultFilters);
  };

  const handleValuesChange = ({ penalty_id }) => setPenaltyId(penalty_id);

  const handleSubmit = () => {
    if (contractId) {
      mutate({ ...fullPenalty?.penalty, penalty_from_contract: true });
    }
    handleCloseModal();
  };

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('delivery_contract'),
        dataIndex: 'number',
      },
      {
        title: t('date'),
        dataIndex: 'date',
        render: (date) => (date ? formatDate(date) : NO_DATA_PLACEHOLDER),
      },
      {
        title: t('debtors'),
        dataIndex: 'debtor',
        render: (debtor) => debtor || NO_DATA_PLACEHOLDER,
      },
      {
        title: t('penalty'),
        dataIndex: 'penalty',
        render: (penalty) => getPenaltyWithText(penalty, t),
      },
      {
        title: null,
        action: true,
        render: ({ id }) => (
          <Actions>
            <Actions.Item onClick={() => setContractId(id)}>{`${t('add')} ${t(
              'penalty',
            )}`}</Actions.Item>
          </Actions>
        ),
      },
    ],
    [t, isLoadingPenalties],
  );

  return (
    <Layout>
      {!!contractId && (
        <Modal
          open={true}
          size="small"
          header={`${t('add')} ${t('penalty')}`}
          onClose={handleCloseModal}
        >
          <Form form={form} onFinish={handleSubmit} onValuesChange={handleValuesChange}>
            <Modal.Content className="p-30">
              <Form.Field name="penalty_id">
                <Select options={penaltiesOptions} loading={isLoadingPenalties}>
                  <Select.Options />
                  <Select.Pagination
                    mode="scroll"
                    count={penalties?.count || 0}
                    page={penaltiesFilters.page}
                    limit={penaltiesFilters.limit}
                    setPage={(page) => setPenaltiesFilters({ page })}
                  />
                </Select>
              </Form.Field>
            </Modal.Content>
            <Modal.Footer>
              <Space justify="space-between">
                <Button onClick={handleCloseModal}>{t('cancel')}</Button>
                <Button type="primary" prefix={<Icon type="check" model="bold" />} submit>
                  {t('save')}
                </Button>
              </Space>
            </Modal.Footer>
          </Form>
        </Modal>
      )}

      <Container>
        <Space className="mt-5 mb-20">
          <h3 className="page-title">
            {t('delivery_contract')} ({data?.count || 0})
          </h3>

          <InputSearch
            placeholder={t('search')}
            styleType="fill"
            value={filters.search}
            onSearch={(search) => setFilters({ search, page: 1 })}
            isClearable
          />
        </Space>
        <Card>
          <Card.Body className="p-0">
            <Loader loading={isLoading}>
              <Table columns={columns} data={data?.results} />
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Pagination data={data} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </Container>
    </Layout>
  );
};
