import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import {
  company,
  invoices,
  requests,
  attachments as attachmentsApi,
  nomenclature,
  common,
} from 'api';
import { useNotify } from 'ebs-design';
import { formatDate } from 'libs';
import { Properties, AttachmentType, CompanyType } from 'types';
import { extractResponseProps, getRoute, validateResponse } from 'utils';
import { routes } from 'routes';

export const useAPI = ({
  form,
  selectedCompany,
  setSelectedCompany,
  setAlternative,
  setAttachments,
  setSelectedContact,
  setExceededLimit,
}: Properties): Properties => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { t } = useIntl();
  const { push } = useHistory();
  const { id } = useParams();

  /* Attachments */
  const getXML = useMutation(attachmentsApi.getXML, {
    onSuccess: (data: Properties) => {
      form.setFieldsValue({
        series: data.series,
        number: data.number,
        ...(data.issued_date && { issuing_date: formatDate(data.issued_date) }),
        ...(data.delivery_date && { delivery_date: formatDate(data.delivery_date) }),
        ...(data.amount && { amount: parseFloat(data.amount).toFixed(2) }),
        company: {
          idno: data.buyer.idno,
          iban: data.buyer.iban,
          code: data.buyer.code,
        },
      });

      getCompanies.mutate({ queryKey: [null, { title: data.buyer.title }] });
    },
  });

  /* Invoices */
  const { isLoading: isLoadingInvoiceForm } = useQuery(
    ['edit-invoice'],
    () => invoices.get(id as string, false),
    {
      enabled: !!id,
      onSuccess: (dataInvoice) => {
        const invoice = dataInvoice.attachments.find((i) => i.type === AttachmentType.INVOICE);
        const contract = dataInvoice.attachments.find((i) => i.type === AttachmentType.CONTRACT);
        const files = dataInvoice.attachments.filter((i) => i.type === AttachmentType.OTHER);
        const xml = dataInvoice.attachments.find((i) => i.type === AttachmentType.XML);

        if (dataInvoice.companies.length) {
          setSelectedCompany({
            value: dataInvoice.companies[0].id,
            text: dataInvoice.companies[0].title,
          });
        } else if (xml) {
          getXML.mutate(xml.id);
        }

        if (dataInvoice.contact_name) {
          setSelectedContact(dataInvoice.contact_name);
        }

        form.setFieldsValue({
          series: dataInvoice.series,
          number: dataInvoice.number,
          ...(dataInvoice.amount && { amount: parseFloat(dataInvoice.amount).toFixed(2) }),
          term_number: dataInvoice.term_number,
          ...(dataInvoice.issuing_date && { issuing_date: formatDate(dataInvoice.issuing_date) }),
          ...(dataInvoice.delivery_date && {
            delivery_date: formatDate(dataInvoice.delivery_date),
          }),
          ...(dataInvoice.factoring_type && { factoring_type_id: dataInvoice.factoring_type.id }),
          ...(dataInvoice.contract && {
            contract: {
              number: dataInvoice.contract.number,
              ...(dataInvoice.contract.date && { date: formatDate(dataInvoice.contract.date) }),
            },
          }),
          ...(dataInvoice.companies.length && { company_id: dataInvoice.companies[0].id }),
          ...(invoice && { attachments_id: [invoice], attachments_ocr: [invoice] }),
          ...((contract || files.length) && {
            attachments: {
              ...(contract && { [AttachmentType.CONTRACT]: contract.id }),
              ...(xml && { [AttachmentType.XML]: xml.id }),
              ...(!!files.length && { [AttachmentType.OTHER]: files.map((i) => i.id) }),
            },
          }),
          delivery_contract: dataInvoice.delivery_contract,
        });

        setAttachments({
          ...(invoice && { [AttachmentType.INVOICE]: invoice.id }),
          ...(contract && { [AttachmentType.CONTRACT]: contract.id }),
          ...(xml && { [AttachmentType.XML]: xml.id }),
          ...(!!files.length && { [AttachmentType.OTHER]: files.map((file) => file.id) }),
        });
      },
    },
  );

  const requestInvoice = useMutation(requests.create, {
    onSuccess: () => {
      push(getRoute(routes, 'Invoices'));
    },
  });

  const updateInvoice = useMutation((data: Properties) => invoices.patchInvoice(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('invoices');

      push(getRoute(routes, 'Invoices'));
      notify.success({ title: t('invoices'), description: t('success_data_change') });
    },
    onError: (err: Properties) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );

      if (err?.response?.data.amount) {
        setExceededLimit(true);
      }
    },
  });

  const postInvoice = useMutation(invoices.create, {
    onError: (err: Properties) => {
      validateResponse.bind(null, form, err);
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );

      if (err?.response?.data.amount) {
        setExceededLimit(true);
      }
    },
    onSuccess: ({ id }) => {
      requestInvoice.mutate({
        type: AttachmentType.INVOICE,
        invoices_id: [id],
      });
      notify.success({ title: t('invoices'), description: t('success_data_save') });
    },
  });

  /* Companies */
  const { data: dataCompany } = useQuery(
    ['company', selectedCompany],
    () => company.get(selectedCompany?.value),
    {
      enabled: !!selectedCompany,
      onSuccess: (company) => {
        form.setFieldsValue({
          company: {
            idno: company.idno,
            iban: company.iban,
            code: company.code,
            email: company.email,
          },
        });
      },
    },
  );

  const getCompanies = useMutation(company.getList, {
    onSuccess: (response, { queryKey }) => {
      form.setFieldsValue(
        response.results.length
          ? {
              company_id: response.results[0].id,
            }
          : {
              company: {
                company_name: queryKey[1].title,
              },
            },
      );
      setSelectedCompany(
        response.results.length
          ? { value: response.results[0].id, text: response.results[0].title }
          : null,
      );
      setAlternative({ company_name: !response.results.length });
    },
  });

  // Add company to history
  const requestCompany = useMutation(requests.create, {
    onSuccess: () => {
      setExceededLimit(false);

      notify.success({
        title: t('increase_limit'),
        description: t('success_request_call'),
      });
    },
  });

  const postCompany = useMutation(company.create, {
    onError: (err, values) => {
      validateResponse(form, err, values);
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const updateCompany = async (companyData) => {
    const { data } = await postCompany.mutateAsync({
      types: [CompanyType.DEBTOR],
      type: CompanyType.DEBTOR,
      title: companyData.company_name,
      idno: companyData.idno,
      assign_company_responsible: true,
      ...(companyData.iban && { iban: companyData.iban }),
      ...(companyData.code && { code: companyData.code }),
    });

    requestCompany.mutate({ type: 'CARD', company_id: data.id });
    queryClient.invalidateQueries('companies-list');

    return data;
  };

  // Request a call
  const requestCall = useMutation(common.requestCall, {
    onSuccess: () => {
      setExceededLimit(false);

      notify.success({
        title: t('request_call'),
        description: t('success_request_call'),
      });
    },
  });

  // Custom data
  const factoringTypesList = useQuery(['nomenclature', 'factoring_types'], () =>
    nomenclature.getFactoringTypes(),
  );

  const factoringTypes = React.useMemo(
    () =>
      factoringTypesList.data?.map((item) => ({
        ...item,
        text: t((item.text as string).toLowerCase()),
      })),
    [t, factoringTypesList],
  );

  const factoringRange = React.useMemo(
    () => [
      { value: 30, text: `0-30 ${t('days')}` },
      { value: 60, text: `31-60 ${t('days')}` },
      { value: 90, text: `61-90 ${t('days')}` },
      { value: 120, text: `91-120 ${t('days')}` },
    ],
    [t],
  );

  return {
    api: {
      getXML,
      requestInvoice,
      postInvoice,
      updateInvoice,
      updateCompany,
      requestCall,
      requestCompany,
    },
    data: {
      company: dataCompany,
      isLoadingInvoiceForm,
      factoringTypes,
      factoringRange,
    },
  };
};
