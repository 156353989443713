import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table } from 'ebs-design';
import { formatNumber } from 'utils';
import { Column, ReportInvoicesRatios, Currencies } from 'types';

export const InvoicesRatiosTable: React.FC<{ data?: ReportInvoicesRatios[] }> = ({ data = [] }) => {
  const { t } = useIntl();

  const totals = data?.reduce(
    (acc, cur) => ({
      ...acc,
      amount: acc.amount + cur.amount,
      total: acc.total + cur.total,
      percentage: acc.percentage + cur.percentage,
    }),
    { type: t('total'), total: 0, amount: 0, percentage: 0 },
  );

  const columns: Column<ReportInvoicesRatios>[] = React.useMemo(
    () => [
      {
        title: t('factoring_type'),
        dataIndex: 'type',
        render: (value) => t(value.toLowerCase()),
      },
      {
        title: t('sum'),
        dataIndex: 'amount',
        render: (value) => formatNumber(value, Currencies.MDL),
      },
      {
        title: t('total'),
        dataIndex: 'total',
        render: (value) => formatNumber(value, '', 0),
      },
      {
        title: t('rate'),
        dataIndex: 'percentage',
        render: (value) => `${formatNumber(value)}%`,
      },
    ],
    [t],
  );

  return <Table data={[...data, totals]} columns={columns} emptyCell="---" />;
};
