import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Card } from 'ebs-design';
import {
  Bar,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import { abbreviateNumber } from 'utils';
import { BacklogsChartItem } from '../interfaces';
import { chartColors } from '../utils';

export const PortfoliosChart: React.FC<{ data?: BacklogsChartItem[] }> = ({ data = [] }) => {
  const { t } = useIntl();

  return (
    <Card className="report-chart__card">
      <Card.Body>
        <ResponsiveContainer width={700} height={600}>
          <ComposedChart data={data}>
            <defs>
              <linearGradient id="colorLine1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartColors.line} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartColors.line} stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" scale="band" />
            <YAxis type="number" tickFormatter={(tick) => abbreviateNumber(tick)} />
            <YAxis
              type="number"
              tickFormatter={(tick) => abbreviateNumber(tick)}
              yAxisId="right"
              orientation="right"
              scale="auto"
            />
            <Tooltip />
            <Legend />
            <Bar
              name={t('managed_portfolio')}
              dataKey="amount"
              barSize={20}
              fill={chartColors.bar}
            />
            <Area
              type="monotone"
              name={t('total_nr_of_invoices')}
              yAxisId="right"
              dataKey="total"
              stroke={chartColors.line}
              fillOpacity={1}
              fill="url(#colorLine1)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};
