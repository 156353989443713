import * as React from 'react';
import { useIntl } from 'estafette-intl';
import env from '@beam-australia/react-env';
import { Button, Icon, Row, Col, NotifyContainer } from 'ebs-design';
import cn from 'classnames';
import logo from 'resources/logo_dark.png';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

const AuthenticationModal: React.FC<Props> = ({
  title,
  description,
  footer,
  className,
  children,
}) => {
  const { t } = useIntl();
  const onProductClick = React.useCallback(() => window.open(env('PRODUCT_URL'), '_blank'), []);

  return (
    <div className={cn('authentication-modal', className)}>
      <NotifyContainer />
      <div className="authentication-modal-wrapper">
        <div className="authentication-modal-preview">
          <div className="authentication-modal-preview-logo">
            <img src={logo} alt="Bizon Factoring" />
          </div>

          <div className="authentication-modal-preview-footer">
            <Button
              className="authentication-modal-preview-button"
              prefix={<Icon type="globe" />}
              block
              onClick={onProductClick}
            >
              {t('visit_our_website')}
            </Button>
          </div>
        </div>

        <div className="authentication-modal-form">
          {title || description ? (
            <div className="authentication-modal-form-header">
              <Row>
                <Col size={3}></Col>

                <Col size={9}>
                  {title && <div className="authentication-modal-form-title">{title}</div>}

                  {description && (
                    <div className="authentication-modal-form-description">{description}</div>
                  )}
                </Col>
              </Row>
            </div>
          ) : null}

          <div className="authentication-modal-form-content">{children}</div>

          {footer && <div className="authentication-modal-form-footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};

export default AuthenticationModal;
