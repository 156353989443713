import { Status, CompanyType, AttachmentType, SystemRole, CustomerRole, Properties } from 'types';

export const getSignPermission = (
  { status, attachments, companies, company }: Properties,
  isSystemRole,
) => {
  const existAttachments = attachments.filter((attachment) =>
    [AttachmentType.ANNEX, AttachmentType.ASSIGNMENT_OF_RECEIVABLES].includes(attachment.type),
  );

  const total = isSystemRole ? 1 : 2;
  const remaining = existAttachments.filter((attachment) =>
    attachment.signatures.some(
      ({ user, signed }) =>
        signed &&
        user.roles.some((role) =>
          isSystemRole
            ? Object.values(SystemRole).includes(role.name)
            : Object.values(CustomerRole).includes(role.name),
        ),
    ),
  ).length;

  return {
    canSelect:
      !existAttachments.length &&
      Status[status] === Status.APPROVED &&
      companies?.some((i) => i.types.includes(CompanyType.DEBTOR) && i.id !== company?.id),
    canReSign: existAttachments.length && remaining < total,
    total,
    remaining,
  };
};
