import { default as queryString } from 'query-string';
import { axios, cancelTokenHandler, stringifyUrl } from 'libs';
import { Results, Properties, UserSignIn, User, UsersSetting } from 'types';
import { transformResponse, formatNumber } from 'utils';

const AUTH_HEADER = {
  headers: {
    'Service-Token': process.env.REACT_APP_SERVICE_TOKEN,
  },
};

export const users = {
  getList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<User>>(stringifyUrl(`/users/list/`, queryKey[1]), {
      transformResponse: (response) =>
        transformResponse(response, {
          properties: [
            'bonus_percentage',
            'bonus_percentage_amount',
            'bonus_percentage_amount_sum',
          ],
          wrapper: (value) => formatNumber(value),
        }),
      cancelToken: cancelToken(users.getList.name),
    });

    return data;
  },

  getUser: async (id: number) => {
    const { data } = await axios.get(`/users/${id}/`, {
      cancelToken: cancelToken(users.getUser.name),
    });

    return data;
  },

  getInvitationsList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<User>>(
      queryString.stringifyUrl(
        {
          url: `/users/invites/list/`,
          query: queryKey[1],
        },
        { skipEmptyString: true },
      ),
      {
        cancelToken: cancelToken(users.getInvitationsList.name),
      },
    );

    return data;
  },

  register: async (formData: Properties) => {
    const { data } = await axios.post('/users/register/', formData, {
      cancelToken: cancelToken(users.register.name),
    });

    return data;
  },

  registerInvitation: async (formData: Properties, token?: string, verify?: boolean) => {
    const { data } = await axios.post(
      stringifyUrl('/users/invite/extended/register/', { token, verify }),
      formData,
      {
        cancelToken: cancelToken(users.register.name),
      },
    );

    return data;
  },

  getInvitation: async (token: string) => {
    const { data } = await axios.get(`/users/invites/?token=${token}`, {
      cancelToken: cancelToken(users.confirm.name),
    });

    return data;
  },

  confirm: async (formData: Properties) => {
    const { data } = await axios.post(`/users/confirm/`, formData, {
      cancelToken: cancelToken(users.confirm.name),
    });

    return data;
  },

  getSettings: async () => {
    const { data } = await axios.get<UsersSetting[]>('/users/settings/list/', {
      cancelToken: cancelToken(users.getSettings.name),
    });

    return data;
  },

  setSettings: async ({ id, code_name, ...params }: Properties) => {
    const { data } = await axios.put(`/users/${id}/settings/${code_name}/`, params, {
      cancelToken: cancelToken(users.setSettings.name),
    });

    return data;
  },

  updateSettings: async ({ id, code_name, ...params }: Properties) => {
    const { data } = await axios.patch(`/users/${id}/settings/${code_name}/`, params, {
      cancelToken: cancelToken(users.updateSettings.name),
    });

    return data;
  },

  deleteSettings: async ({ id, code_name }: Properties) => {
    const { data } = await axios.delete(`/users/${id}/settings/${code_name}/`, {
      cancelToken: cancelToken(users.deleteSettings.name),
    });

    return data;
  },

  login: async (formData: UserSignIn) => {
    const { data } = await axios.post('/users/login/', formData, {
      cancelToken: cancelToken(users.login.name),
    });

    return data;
  },

  refresh: async (formData: Properties) => {
    const { data } = await axios.post('/users/refresh/', formData, {
      cancelToken: cancelToken(users.refresh.name),
    });

    return data;
  },

  resetPassword: async (formData: Properties) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/authentication/restore-request`,
      formData,
      {
        ...AUTH_HEADER,
        cancelToken: cancelToken(users.resetPassword.name),
      },
    );

    return data;
  },

  resetPasswordConfirm: async (formData: Properties) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/authentication/restore-confirm`,
      formData,
      {
        ...AUTH_HEADER,
        cancelToken: cancelToken(users.resetPasswordConfirm.name),
      },
    );

    return data;
  },

  resetPasswordVerify: async (formData: Properties) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/authentication/restore-verify`,
      formData,
      {
        ...AUTH_HEADER,
        cancelToken: cancelToken(users.resetPasswordVerify.name),
      },
    );

    return data;
  },

  setPassword: async (formData: Properties) => {
    const { data } = await axios.post('/users/set-password/', formData, {
      cancelToken: cancelToken(users.setPassword.name),
    });

    return data;
  },

  create: async (formData: Properties) => {
    const { data } = await axios.post('/users/', formData, {
      cancelToken: cancelToken(users.create.name),
    });

    return data;
  },

  update: async (formData: Properties) => {
    const { data } = await axios.patch(`/users/${formData.id}/`, formData, {
      cancelToken: cancelToken(users.update.name),
    });

    return data;
  },

  delete: async (id: number) => {
    return await axios.delete(`/users/${id}/`, {
      cancelToken: cancelToken(users.delete.name),
    });
  },

  inviteSystemUser: async (body: Properties) => {
    const { data } = await axios.post<User>(`/users/invites/create/`, body, {
      cancelToken: cancelToken(users.inviteSystemUser.name),
    });

    return data;
  },

  getUsers: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<User>>(stringifyUrl(`/users/`, queryKey[1]), {
      transformResponse: (response) =>
        transformResponse(response, {
          properties: [
            'bonus_percentage',
            'bonus_percentage_amount',
            'bonus_percentage_amount_sum',
          ],
          wrapper: (value) => formatNumber(value),
        }),
      cancelToken: cancelToken(users.getUsers.name),
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(users);
