import type { ColumnType } from 'ebs-design/dist/components/organisms/Table/Table';
import type { Option } from 'ebs-design/dist/components/molecules/Select/interfaces';

export interface Column<T = object> extends ColumnType<T> {
  checked?: boolean;
  filter?: string;
}

export enum Status {
  SENT = 'info',
  PARTIALLY_PAID = 'warning',
  DENIED = 'text',
  CLOSED = 'text',
  REMAINED = 'info',
  RECESSIONED = 'danger',
  APPROVED = 'success',
  PAID = 'success',
  REVIEW = 'warning',
  REEXAMINATION = 'danger',
  OTHER = 'info',
  INFO_REQUIRED = 'info',
  DRAFT = 'info',
  CANCELLED = 'danger',
  SENT_TO_PAYMENT = 'warning',
}

export enum AddressType {
  LEGAL = 'LEGAL',
  POSTAL = 'POSTAL',
}

export enum OrderBy {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Period {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  SECOND = 'SECOND',
  MICROSECOND = 'MICROSECOND',
}

/**
 * The word periodical is related to period, as in a certain length of time.
 * Periodicals are published with regular lengths of time between issues and
 * are described by that length of time, whether it's daily, weekly, monthly, quarterly, or annual.
 */
export enum Periodical {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MINUTELY = 'MINUTELY',
}

export enum Unit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
  PERCENT = '%',
  MINUS = '-',
}

export enum Currencies {
  MDL = 'MDL',
  USD = 'USD',
  EUR = 'EUR',
}

export type SettlementType = 'RURAL' | 'URBAN';

export type FactoringEntity = { id: number; title: string };
export type Properties = Record<string, any>;

export type LabelStatus = 'success' | 'warning' | 'danger' | 'info';

// Use for select
export type SelectOption = Option;

export type NormalizeType = {
  properties: string[];
  wrapper: (value) => string;
};
