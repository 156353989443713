import * as React from 'react';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Modal,
  Button,
  Space,
  Icon,
  Form,
  Input,
  Textarea,
  InputPhone,
  useForm,
  useNotify,
} from 'ebs-design';
import { common } from 'api';
import { validateResponse } from 'utils';

export const RequestCallModal: React.FC = ({ children }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const [visible, setVisible] = React.useState(false);
  const notify = useNotify();

  const requestCall = useMutation(common.requestCall, {
    onError: validateResponse.bind(null, form),
    onSuccess: () => {
      setVisible(false);

      notify.success({
        title: t('request_call'),
        description: t('success_request_call'),
      });
    },
  });

  const onToggleHandler = () => setVisible((i) => !i);

  const handleSubmit = React.useCallback(
    (data) => {
      requestCall.mutate(data);
    },
    [requestCall],
  );

  return (
    <>
      <Modal
        size="small"
        header={t('request_call')}
        className="pc-confirmation-modal"
        open={visible}
        onClose={onToggleHandler}
      >
        <Form
          type="horizontal"
          form={form}
          onFinish={handleSubmit}
          controlOptions={{ col: { size: 9 } }}
          labelOptions={{ col: { size: 3 } }}
        >
          <Modal.Content>
            <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
              <Input />
            </Form.Field>

            <Form.Field name="last_name" label={t('last_name')}>
              <Input />
            </Form.Field>

            <Form.Field name="phone" label={t('phone')} rules={[{ required: true }]}>
              <InputPhone country="md" />
            </Form.Field>

            <Form.Field name="description" label={t('message')}>
              <Textarea />
            </Form.Field>
          </Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={onToggleHandler}>{t('cancel')}</Button>

              <Button type="primary" submit prefix={<Icon type="check" model="bold" />}>
                {t('send')}
              </Button>
            </Space>
          </Modal.Footer>
        </Form>
      </Modal>
      <div onClick={onToggleHandler}>{children}</div>
    </>
  );
};
