import * as React from 'react';
import { save, load, remove } from 'react-cookies';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { Loader, useNotify } from 'ebs-design';
import { routes } from 'routes';
import { axiosHeadersUpdater, axiosHeaderAPIUpdater, axios } from 'libs';
import { profile, users, tenancy } from 'api';
import { Tokens, User, UserRole, Results, FullCompany } from 'types';
import { getRemainingDays, extractResponseProps, getRoute } from 'utils';

interface Props {
  readonly logged: boolean;
  readonly accessToken?: string;
  readonly hash?: string;
  readonly user?: User;
  readonly userRoles: UserRole[];
  readonly dataTenancy?: Results<FullCompany>;
  readonly isLoadingTenancy: boolean;
  language: string;
  onRedirect: () => void;
  onVerifyUser: (params?: Tokens, redirect?: boolean) => Promise<void>;
  onLogout: () => void;
  onChangeLang: (lang: string) => void;
  onChangeHash: (value: string) => void;
}

// Cookies' options
const cookieOptions: { path: string; expires?: Date } = { path: '/' };
const clearCookies = () => {
  remove('jwt-access-token', { path: '/' });
  remove('jwt-refresh-token', { path: '/' });
  remove('hash', { path: '/' });

  axiosHeadersUpdater();
  axiosHeaderAPIUpdater();
};

// Context
const UserContext = React.createContext<Props>({
  logged: false,
  language: localStorage.getItem('lang') || 'ro',
  hash: undefined,
  userRoles: [],
  dataTenancy: undefined,
  isLoadingTenancy: false,
  onVerifyUser: async (): Promise<void> => Promise.resolve(),
  onLogout: () => null,
  onRedirect: () => null,
  onChangeLang: () => null,
  onChangeHash: () => null,
});

// Provider
const UserProvider = ({
  children,
}: {
  children: (values: any) => React.ReactNode;
}): React.ReactElement => {
  const { t } = useIntl();
  const history = useHistory();
  const [logged, setLogged] = React.useState(false);
  const [hash, setHash] = React.useState(load('hash'));
  const [language, setLanguage] = React.useState<string>(localStorage.getItem('lang') || 'ro');

  const notify = useNotify();
  const enabled = !!load('jwt-access-token');

  // Fetch user profile
  const { data, isLoading, refetch, remove } = useQuery('userProfile', () => profile.getProfile(), {
    retry: 0,
    enabled,
    onError: () => checkRememberMe(),
    onSuccess: () => setLogged(true),
  });

  const user = useMutation(users.refresh, {
    onSuccess: (data) => {
      if (data.access) {
        setLogged(false);

        onVerifyUser(data);
      }
    },
  });

  React.useEffect(() => {
    if (logged && load('jwt-access-token')) refetch();
  }, [logged, refetch]);

  React.useEffect(() => {
    const accessToken = load('jwt-access-token');

    if (!accessToken && !document.location.pathname.match('/public/')) onRedirect();
    // eslint-disable-next-line
  }, []);

  // Change language
  const onChangeLang = React.useCallback((lang: string) => {
    setLanguage(lang);
    axiosHeadersUpdater(lang);
    localStorage.setItem('lang', lang);
    document.body.className = `language-${lang}`;
  }, []);

  const checkRememberMe = () => {
    const token = load('jwt-access-token');
    const refresh = load('jwt-refresh-token');

    if (token && refresh) {
      const isRemember = localStorage.getItem('remember') !== null;
      const tokenDays = getRemainingDays(token);
      const refreshDays = getRemainingDays(refresh);

      if (tokenDays <= 0 && refreshDays >= 0 && isRemember) {
        user.mutate({
          refresh,
        });
      } else {
        clearCookies();

        history.push(getRoute(routes, 'IndexEntry'));
      }
    }
  };

  // Verify user tokens
  const onVerifyUser = async (tokens, redirect = true): Promise<void> => {
    if (tokens.access) {
      save('jwt-access-token', tokens.access, cookieOptions);
    }

    if (tokens.refresh) {
      save('jwt-refresh-token', tokens.refresh, cookieOptions);
    }

    // Update axios header authorization
    axiosHeadersUpdater();

    setLogged(true);

    if (redirect) {
      onRedirect();
    }
  };

  const onRedirect = () => {
    history.push(getRoute(routes, 'IndexEntry'));
  };

  // Logout
  const onLogout = () => {
    clearCookies();
    setLogged(false);
    onRedirect();

    remove();
    removeTenancy();
  };

  const {
    data: dataTenancy,
    isLoading: isLoadingTenancy,
    remove: removeTenancy,
  } = useQuery(['tenancy-companies-list'], tenancy.getCompaniesList, {
    enabled: !!hash,
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );

      onLogout();
    },
  });

  const onChangeHash = React.useCallback((value: string): void => {
    save('hash', value, { path: '/' });
    axiosHeaderAPIUpdater(value);
    setHash(value);
  }, []);

  React.useEffect(() => {
    if (data?.company?.hash && data?.company?.hash !== axios.defaults.headers['X-API-KEY']) {
      axiosHeaderAPIUpdater(data?.company?.hash);
    }
    if (!hash && (data?.company?.hash || dataTenancy?.results.length)) {
      onChangeHash(data?.company?.hash || dataTenancy!.results[0].hash);
    }
  }, [hash, data, dataTenancy, onChangeHash]);

  const values = {
    logged,
    hash,
    dataTenancy,
    isLoadingTenancy,
    user: enabled && logged ? data : {},
    userRoles: logged ? data?.roles?.map((r) => r.name) || [] : [],
    accessToken: load('jwt-access-token'),
    language,
    setHash,
    onVerifyUser,
    onRedirect,
    onLogout,
    onChangeLang,
    onChangeHash,
  };

  return (
    <UserContext.Provider value={values}>
      <Loader loading={isLoading || isLoadingTenancy} fixed>
        {!isLoading && children(values)}
      </Loader>
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider, clearCookies };
