import React, { FC, useState, useMemo, useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Table, Actions, Loader, Space, SortBy, Button, Icon, useNotify } from 'ebs-design';
import { Plus } from 'resources';
import { nomenclature } from 'api';
import { Category, Column, Properties } from 'types';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { getSortOptions, extractResponseProps } from 'utils';
import { ClassifiersLayout } from '../../../ClassifiersLayout';
import { CategoryModal } from './CategoryModal';

export const Categories: FC = () => {
  const { t } = useIntl();
  const { updateQuery } = useQueryUpdate();
  const queryParams = useQueryParams();
  const queryClient = useQueryClient();
  const [modalVisible, setModalVisible] = useState(false);
  const [category, setCategory] = useState<Category | null>(null);
  const notify = useNotify();

  const categories = useQuery(['categories', queryParams], ({ queryKey }) =>
    nomenclature.getCategories(queryKey[1] as Properties),
  );

  const deleteCategory = useMutation(nomenclature.deleteCategory, {
    onSuccess() {
      queryClient.invalidateQueries('categories');
      notify.success({ title: t('categories'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const handleModalClose = useCallback(() => {
    setModalVisible(false);
    setCategory(null);
  }, []);

  const columns: Column[] = useMemo(
    () => [
      {
        title: t('id'),
        dataIndex: 'id',
        filter: 'id',
        width: '25%',
      },
      {
        title: t('name'),
        dataIndex: 'code_name',
        filter: 'code_name',
      },
      {
        title: '',
        action: true,
        render: (item) => (
          <Actions>
            <Actions.Item
              onClick={() => {
                setModalVisible(true);
                setCategory(item);
              }}
            >
              {t('edit')}
            </Actions.Item>
            <Actions.Item
              onClick={() => {
                deleteCategory.mutate(item.id);
              }}
            >
              {t('delete')}
            </Actions.Item>
          </Actions>
        ),
      },
    ],
    [t, deleteCategory],
  );

  const sortOptions = useMemo(() => getSortOptions(columns), [columns]);

  const onChangeSort = useCallback((ordering) => updateQuery({ ordering }), [updateQuery]);

  return (
    <>
      {modalVisible && <CategoryModal category={category} onClose={handleModalClose} />}
      <ClassifiersLayout>
        <Space align="center" justify="space-between" className="mt-5 mb-20 mt-20">
          <h3 className="page-title">
            {t('categories')} ({categories.data?.count || 0})
          </h3>
          <Space>
            <SortBy options={sortOptions} value={queryParams.ordering} onChange={onChangeSort} />
            <Button
              type="ghost"
              prefix={<Icon component={Plus} />}
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {t('add_new')}
            </Button>
          </Space>
        </Space>
        <Loader loading={categories.isLoading}>
          <Table
            data={categories.data?.results}
            columns={columns}
            className="pc-categories__table"
          />
        </Loader>
      </ClassifiersLayout>
    </>
  );
};
