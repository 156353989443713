import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import { Card, Loader, Table, AvatarInline, Actions } from 'ebs-design';
import { useSetState } from 'react-use';

import { company } from 'api';
import { Layout, Pagination, ChangeManagerModal } from 'components';
import { Column, CompanyManagement, User } from 'types';
import { defaultFilters } from 'utils';
import { useQueryParams } from 'hooks';

import { DebtorLayout } from '../DebtorLayout';
import { DebtorProfile } from '../DebtorProfile';

export const Management = () => {
  const { id } = useParams();
  const { t } = useIntl();
  const params = useQueryParams();
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });
  const [manager, setManager] = React.useState<CompanyManagement | null>(null);

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  const { data: managementsData, isLoading: managementsIsLoading } = useQuery(
    ['company', 'management', id, filters],
    () => company.getManagementList(id, filters),
    {
      enabled: Boolean(id),
    },
  );

  const columns: Column<CompanyManagement>[] = React.useMemo(
    () => [
      {
        title: t('email'),
        dataIndex: 'user',
        render: (user: User) => (
          <AvatarInline
            circle
            alt={`${user.first_name} ${user.last_name}`}
            description={user.email}
          />
        ),
      },
      {
        title: null,
        action: true,
        render: (manager: CompanyManagement) => (
          <Actions>
            <Actions.Item onClick={() => setManager(manager)}>{t('change_manager')}</Actions.Item>
          </Actions>
        ),
      },
    ],
    [],
  );

  return (
    <Layout>
      <DebtorLayout>
        <Loader loading={isLoading}>{data && <DebtorProfile data={data} />}</Loader>

        <Card collapsible>
          <Card.Header bordered>{t('users')}</Card.Header>
          <Card.Body className="p-0">
            <Loader loading={managementsIsLoading}>
              <Table
                className="table-no-border"
                columns={columns}
                data={managementsData?.results}
                emptyCell="---"
              />
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Pagination data={managementsData} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </DebtorLayout>

      <ChangeManagerModal manager={manager} onClose={() => setManager(null)} />
    </Layout>
  );
};
