export const invoice = {
  invoice: {
    en: `Invoice`,
    ro: `Factura`,
    ru: `Счет`,
  },
  invoice_number: {
    en: `Invoice №`,
    ro: `Factura №`,
    ru: `Счет №`,
  },
  invoice_number_series: {
    en: `Invoice Nr./series`,
    ro: `Factura Nr./seria`,
    ru: `Счет №/серия`,
  },
  invoice_amount: {
    en: `Invoice Amount`,
    ro: `Suma Facturii`,
    ru: `Сумма счета`,
  },
  invoice_value: {
    en: `Invoice value`,
    ro: `Valoarea Facturii`,
    ru: `Сумма счета`,
  },
  type_invoices: {
    en: `Type of invoices`,
    ro: `Tipul de facturi`,
    ru: `Тип инвойсов`,
  },
  invoice_series: {
    en: `Invoice series`,
    ro: `Seria facturii`,
    ru: `Серия счета`,
  },
  invoice_data: {
    en: `Invoice data`,
    ro: `Date despre factură`,
    ru: `Данные счета`,
  },
  sum_of_invoice: {
    en: `Sum of invoice`,
    ro: `Suma facturii`,
    ru: `Сумма счета`,
  },
  total_nr_of_invoices: {
    en: `Total nr of invoices`,
    ro: `Nr. total de facturi`,
    ru: `Общее количество фактур`,
  },
  warranty_for_refund: {
    en: `Warranty for refund`,
    ro: `Garantie spre restituire`,
    ru: `Гарантия на возврат`,
  },
  nr_of_active_invoices: {
    en: `Nr. of active invoices`,
    ro: `Nr. de facturi active`,
    ru: `Кол. активных счетов`,
  },
  nr_of_remaining_invoices: {
    en: `Nr. of remaining invoices`,
    ro: `Nr. de facturi restante`,
    ru: `Кол. оставшихся счетов`,
  },
  amount_on_remaining_invoices: {
    en: `Amount on remaining invoices`,
    ro: `Suma facturilor restante`,
    ru: `Сумма по оставшимся счетам`,
  },
  outstanding_invoices_commission: {
    en: `Outstanding invoices commission`,
    ro: `Comision facturi restante`,
    ru: `Комиссия по неоплаченным счетам`,
  },
  expired_invoices_number: {
    en: `Nr. of expired invoices`,
    ro: `Nr. de facturi expirate`,
    ru: `Кол. просроченных счетов`,
  },
  amount_expired_invoices: {
    en: `Amount of expired invoices`,
    ro: `Suma facturilor expirate`,
    ru: `Сумма просроченных счетов`,
  },
  average_invoice_expired: {
    en: `Average invoice expired`,
    ro: `Factura media expirata`,
    ru: `В среднем истекший счет`,
  },
  nr_of_invoices_under_examination: {
    en: `Nr. of invoices under examination`,
    ro: `Nr. de facturi in examinare`,
    ru: `Кол. счетов на рассмотрении`,
  },
  amount_on_invoices_under_examination: {
    en: `Amount on invoices under examination`,
    ro: `Suma facturilor in examinare`,
    ru: `Сумма счетов на рассмотрении`,
  },
  average_invoice: {
    en: `Average invoice`,
    ro: `Factura medie`,
    ru: `Средний счет`,
  },
  total_active_invoices: {
    en: `Total active invoices`,
    ro: `Total facturi active`,
    ru: `Всего активных счетов`,
  },
  total_remained_invoices: {
    en: `Total remained invoices`,
    ro: `Total facturi rămase`,
    ru: `Всего оставшихся счетов`,
  },
  sum_of_active_invoices: {
    en: `Sum of active invoices`,
    ro: `Suma facturilor active`,
    ru: `Сумма активных счетов`,
  },
  sum_of_remained_invoices: {
    en: `Sum of remained invoices`,
    ro: `Suma facturilor rămase`,
    ru: `Сумма оставшихся счетов`,
  },
  enter_invoice_details: {
    en: `Enter invoice details`,
    ro: `Introduceți detaliile facturii`,
    ru: `Введите данные счета`,
  },
  total_invoices: {
    en: `Total Invoices`,
    ro: `Total Facturi`,
    ru: `Всего фактур`,
  },
  type_invoices_of_regression: {
    en: `Type of invoices with/ without regression`,
    ro: `Tipul de facturi cu/ fara regres`,
    ru: `Тип фактур с / без регрессии`,
  },
  outstanding_customers: {
    en: 'Outstanding customers',
    ro: 'Clienți restanțieri',
    ru: 'Выдающиеся клиенты',
  },
  new_invoices: {
    en: 'New invoices',
    ro: 'Facturi noi',
    ru: 'Новые счета',
  },
  active_invoices: {
    en: 'Active invoices',
    ro: 'Facturi active',
    ru: 'Активные счета',
  },
  overdue_invoices: {
    en: 'Overdue bills',
    ro: 'Facturi restante',
    ru: 'Просроченные счета',
  },
  inspector_factoring: {
    en: 'Inspector factoring',
    ro: 'Inspector factoring',
    ru: 'Инспектор факторинга',
  },
  guarantee: {
    en: `Guarantee`,
    ro: `Garanție`,
    ru: `Гарантия`,
  },
  total_guarantee: {
    en: `Total guarantee`,
    ro: `Garanție totală`,
    ru: `Общая гарантия`,
  },
  average_guarantee: {
    en: `Average guarantee`,
    ro: `Garanție medie`,
    ru: `Средняя гарантия`,
  },
  back_guarantee: {
    en: 'Money back guarantee',
    ro: 'Garanție spre restituire',
    ru: 'Гарантия возврата денег',
  },
  guarantee_set: {
    en: `Guarantee set`,
    ro: `Garanție setată`,
    ru: `Установленная гарантия`,
  },
  top_outstanding_invoices: {
    en: 'Top outstanding invoices',
    ro: 'Top facturi restante',
    ru: 'Самые неоплаченные счета-фактуры',
  },
  active_average_invoice: {
    en: `Active average invoice`,
    ro: `Factura media active`,
    ru: `Активный средний счет`,
  },
  active_invoices_commission: {
    en: `Active invoice commission`,
    ro: `Comision facturi active`,
    ru: `Комиссия по активным счетам`,
  },
  from_active_invoices: {
    en: 'from active invoices',
    ro: 'din facturi active',
    ru: 'из активных счетов',
  },
  collected_commission: {
    en: `Collected commission`,
    ro: `Comision încasat`,
    ru: `Собранная комиссия`,
  },
  collected_guarantee: {
    en: `Collected guarantee`,
    ro: `Garanție încasată`,
    ru: `Собранная гарантия`,
  },
  collected_penalties: {
    en: `Collected penalties`,
    ro: `Penalități incasate`,
    ru: `Собранных штрафов`,
  },
  collected_payments: {
    en: `Collected payments`,
    ro: `Plăți incasate`,
    ru: `Собранные платежи`,
  },
  average_irr: {
    en: `Weighted average IRR`,
    ro: `IRR mediu ponderat`,
    ru: `Средневзвешенная IRR`,
  },
  invoice_payment: {
    en: `Invoice payment`,
    ro: `Achitare factură`,
    ru: `Оплата счета`,
  },
  invoice_financing: {
    en: 'Invoice financing',
    ro: 'Finanțare factură',
    ru: 'Финансирование счетов',
  },
  InvoiceCancelation: {
    en: 'Invoice cancelation',
    ro: 'Anulare factură',
    ru: 'Отмена счета',
  },
  PenaltyCancelation: {
    en: 'Penalty cancelation',
    ro: 'Anulare penalități',
    ru: 'Отмена штрафа',
  },
  total_payments: {
    en: 'Total payments',
    ro: 'Total achitări',
    ru: 'Итого выплаты',
  },
  invoice_scanning: {
    en: 'The information is extracted',
    ro: 'Se extrage informatia',
    ru: 'Информация извлекается',
  },
  export_file: {
    en: 'Export file',
    ro: 'Exportați fișierul',
    ru: 'Экспорт файла',
  },
  invoices_adherent: {
    en: 'Invoices Adherent',
    ro: 'Facturi Aderent',
    ru: 'Cчета Последователь',
  },
  invoices_debtor: {
    en: 'Invoices Debtor',
    ro: 'Facturi Debitor',
    ru: 'Cчета Должник',
  },
  funded_invoices: {
    en: 'Funded invoices',
    ro: 'Facturi finanțate',
    ru: 'Финансируемые счета',
  },
  penalties_to_recieve: {
    en: 'Penalties to receive',
    ro: 'Penalități spre primire',
    ru: 'Штрафы, которые нужно получить',
  },
  refund_guarantee: {
    en: 'Refund guarantee',
    ro: 'Garanție spre restituire',
    ru: 'Гарантия возврата',
  },
  invoice_debit: {
    en: 'Debt on the invoice',
    ro: 'Datorie pe factură',
    ru: 'Задолженность по счету-фактуре',
  },
  returned_penalties: {
    en: 'Penalties to be returned',
    ro: 'Penalități de restituit',
    ru: 'Штрафы подлежащее возврату',
  },
  receipt_guarantee: {
    en: 'Guarantee for receipt',
    ro: 'Garantie spre primire',
    ru: 'Гарантия получения',
  },
  invoice_term_date: {
    en: 'Invoice payment term',
    ro: 'Termenul de plată a facturii',
    ru: 'Срок оплаты счета',
  },
  invoice_term_days: {
    en: 'Term days',
    ro: 'Termen zile',
    ru: 'Срок дней',
  },
  invoice_payment_term: {
    en: 'Payment term',
    ro: 'Termen achitare',
    ru: 'Условия оплаты',
  },
  automatic_extraction_information: {
    en: 'Automatic extraction of information from the invoice',
    ro: 'Extragerea automată a informației din factură',
    ru: 'Автоматическое извлечение информации из счета-фактуры',
  },
  total_assigned_invoices: {
    en: 'Total assigned invoices',
    ro: 'Total facturi cesionate',
    ru: 'Всего назначенных счетов-фактур',
  },
  amount_of_assigned_invoices: {
    en: 'Amount of assigned invoices',
    ro: 'Suma facturilor cesionate',
    ru: 'Сумма назначенных счетов-фактур',
  },
};
