import $axios, { CancelToken } from 'axios';
import env from '@beam-australia/react-env';
import { load } from 'react-cookies';

export const axios = $axios.create();

// eslint-disable-next-line import/no-named-as-default-member
export const cancelToken = $axios.CancelToken;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = env('BASE_API');

axios.interceptors.request.use(
  (config) => {
    if (config.responseType === 'blob') {
      delete config.headers['X-API-KEY'];
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if ($axios.isCancel(error)) {
      return Promise.reject({ cancel: true, message: 'The endpoint was cancelled' });
    }

    if (error.response) {
      // FIXME: Improve error handlers
      // if (
      //   error.response.status === 403 &&
      //   error.response.data.detail === 'You do not have permission to perform this action.'
      // ) {
      //   history.push(getRoute(routes, 'IndexEntry', { query: { permissions_guard: true } }));
      // }
    }

    return Promise.reject(error);
  },
);

export const axiosHeadersUpdater = (lang?: string) => {
  const token = load('jwt-access-token');

  axios.defaults.headers['Accept-Language'] = lang || localStorage.getItem('lang') || 'ro';

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else if (axios.defaults.headers.common.Authorization) {
    delete axios.defaults.headers.common.Authorization;
  }
};

axiosHeadersUpdater();

export const axiosHeaderAPIUpdater = (hash?: string) => {
  if (!hash) {
    hash = load('hash');
  }

  if (hash) {
    axios.defaults.headers['X-API-KEY'] = hash;
  } else if (axios.defaults.headers['X-API-KEY']) {
    delete axios.defaults.headers['X-API-KEY'];
  }
};

axiosHeaderAPIUpdater();

// FIXME: Fix the interfaces
type GenericObject = { [key: string]: any };

export const createCancelTokenHandler = (apiObject: GenericObject): GenericObject => {
  // initializing the cancel token handler object
  const cancelTokenHandler: GenericObject = {};

  // for each property in apiObject, i.e. for each request
  Object.getOwnPropertyNames(apiObject).forEach((propertyName) => {
    // initializing the cancel token of the request
    const cancelTokenRequestHandler: { cancelToken?: GenericObject } = {};

    // associating the cancel token handler to the request name
    cancelTokenHandler[propertyName] = {
      handleRequestCancellation: () => {
        // if a previous cancel token exists,
        // cancel the request
        cancelTokenRequestHandler.cancelToken &&
          cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`);

        // creating a new cancel token
        cancelTokenRequestHandler.cancelToken = cancelToken.source();

        // returning the new cancel token
        return cancelTokenRequestHandler.cancelToken;
      },
    };
  });

  return cancelTokenHandler;
};

// Create cancel token by name
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const cancelTokenHandler = (apiObject: GenericObject) => {
  const cancelTokenHandlerObject = createCancelTokenHandler(apiObject);

  // FIXME: Why handleRequestCancellation is undefined on deploy???
  return (name: string): CancelToken =>
    cancelTokenHandlerObject[name]?.handleRequestCancellation().token;
};
