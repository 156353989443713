import * as React from 'react';
import namespacedPermissions from 'permissions';
import { UserContext } from 'contexts';
import { Permissions, PermissionEntity, Properties } from 'types';

const defaultState = {
  read: {},
  create: {},
  edit: {},
  delete: {},
  perform: {},
};

export const usePermissions = (namespace: Permissions): PermissionEntity => {
  const { user } = React.useContext(UserContext);
  const [permissions, setPermissions] = React.useState(defaultState);

  const loadPermissions = React.useCallback(
    (roles) => {
      let list = {};

      if (namespacedPermissions[namespace]) {
        list = namespacedPermissions[namespace];
      } else {
        console.log(`${namespace}:`, `Permission with namespace not found.`);
      }

      if (roles.length) {
        const getNestedPermissions = (data): Properties => {
          if (!Array.isArray(data)) {
            return Object.assign(
              {},
              ...Object.keys(data).map((permission) => {
                return { [permission]: getNestedPermissions(data[permission]) };
              }),
            );
          }

          return roles.some((role) => data.includes(role.name));
        };

        setPermissions(
          Object.assign(
            { module: namespace },
            ...Object.keys(list).map((permission) => {
              return { [permission]: getNestedPermissions(list[permission]) };
            }),
          ),
        );
      }
    },
    [namespace],
  );

  React.useEffect(() => {
    if (user?.roles) {
      loadPermissions(user.roles);
    }
  }, [user, loadPermissions]);

  return permissions;
};
