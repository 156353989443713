import * as React from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Button,
  Space,
  AvatarInline,
  Card,
  Table,
  Actions,
  Icon,
  firstLetters,
  Loader,
  Alert,
  useNotify,
} from 'ebs-design';
import { company } from 'api';
import { useCompany } from 'hooks';
import { extractResponseProps, formatNumber } from 'utils';
import { Properties, AssociateType, Unit } from 'types';
import { Plus, ArrowLeft, ArrowRight } from 'resources/icons';

import { AddPartnerModal } from './AddPartnerModal';
import { FormWizardContext } from '../FormWizard';

const FoundersData: React.FC = () => {
  const { t } = useIntl();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { data: companyData, isLoading } = useCompany();
  const ctx = React.useContext(FormWizardContext);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [type, setType] = React.useState<AssociateType>(AssociateType.LEGAL);
  const [data, setData] = React.useState<Properties>({});

  const { mutate, isLoading: isLoadingDelete } = useMutation(company.update, {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries('use-company');
      notify.success({ title: t('company'), description: t('success_data_delete') });
    },
  });

  const onDelete = React.useCallback(
    (id: number) =>
      companyData &&
      mutate({
        companyId: companyData.id,
        data: { associates: companyData.associates.filter((i) => i.id !== id) },
      }),
    [companyData, mutate],
  );

  const onEdit = React.useCallback((params: Properties, type: AssociateType) => {
    setData(params);
    setType(type);
    setVisible(true);
  }, []);

  const columnsLegal = React.useMemo(
    () => [
      {
        title: t('full_title'),
        render: ({ title }) => (
          <AvatarInline circle alt={title} shortAlt={title && firstLetters(title)} />
        ),
        width: 250,
      },
      {
        title: t('idno'),
        dataIndex: 'idno',
        key: 'idno',
      },
      {
        title: t('country_origin'),
        render: ({ country }) => country.name,
      },
      {
        title: t('office'),
        dataIndex: 'address_alternative',
        key: 'address_alternative',
      },
      {
        title: t('amount_of_share'),
        children: [
          {
            title: t('amount'),
            render: ({ amount }) => formatNumber(amount),
          },
          {
            title: Unit.PERCENT,
            render: ({ percent }) => formatNumber(percent),
          },
        ],
      },
      {
        title: null,
        action: true,
        render: (params) => {
          return (
            <Actions>
              <Actions.Item onClick={() => onEdit(params, AssociateType.LEGAL)}>
                {t('edit')}
              </Actions.Item>
              <Actions.Item onClick={() => onDelete(params.id)}>{t('delete')}</Actions.Item>
            </Actions>
          );
        },
      },
    ],
    [t, onEdit, onDelete],
  );

  const columnsIndividuals = React.useMemo(
    () => [
      {
        title: `${t('first_name')} ${t('last_name')}`,
        render: ({ first_name, last_name }) => {
          const title = [first_name, last_name].join(' ');

          return <AvatarInline circle alt={title} shortAlt={firstLetters(title).toUpperCase()} />;
        },
        width: 250,
      },
      {
        title: t('idnp'),
        dataIndex: 'idnp',
        key: 'idnp',
      },
      {
        title: t('country_origin'),
        render: ({ country }) => country.name,
      },
      {
        title: t('amount_of_share'),
        children: [
          {
            title: t('amount'),
            render: ({ amount }) => formatNumber(amount),
          },
          {
            title: Unit.PERCENT,
            render: ({ percent }) => formatNumber(percent),
          },
        ],
      },
      {
        title: null,
        action: true,
        render: (params) => {
          return (
            <Actions>
              <Actions.Item onClick={() => onEdit(params, AssociateType.NATURAL)}>
                {t('edit')}
              </Actions.Item>
              <Actions.Item onClick={() => onDelete(params.id)}>{t('delete')}</Actions.Item>
            </Actions>
          );
        },
      },
    ],
    [t, onEdit, onDelete],
  );

  const onClose = React.useCallback(() => {
    setType(AssociateType.LEGAL);
    setVisible(false);
    setData({});
  }, []);

  const onClickAdd = React.useCallback((value: AssociateType) => {
    setType(value);
    setVisible(true);
  }, []);

  const dataLegals: Properties[] = React.useMemo(
    () =>
      companyData ? companyData.associates.filter((item) => item.type === AssociateType.LEGAL) : [],
    [companyData],
  );

  const dataIndividuals: Properties[] = React.useMemo(
    () =>
      companyData
        ? companyData.associates.filter((item) => item.type === AssociateType.NATURAL)
        : [],
    [companyData],
  );

  const title = React.useMemo(
    () =>
      t(
        `${data.id ? 'edit' : 'add'}_${
          type === AssociateType.LEGAL ? 'legal_entity' : 'individual'
        }`,
      ),
    [t, type, data],
  );

  return (
    <Loader loading={isLoadingDelete || isLoading}>
      <div className="company-form__users">
        <Card className="mb-25">
          <Card.Header bordered>
            <Space justify="space-between">
              <h4 className="card-title">{t('legal_entities')}</h4>
              <Button
                prefix={<Icon component={Plus} />}
                onClick={() => onClickAdd(AssociateType.LEGAL)}
              >
                {t('add_new')}
              </Button>
            </Space>
          </Card.Header>
          <Card.Body className="p-0">
            <Table
              size="big"
              className="table-no-border"
              columns={columnsLegal}
              data={dataLegals}
            />
          </Card.Body>
        </Card>

        <Card className="mb-25">
          <Card.Header bordered>
            <Space justify="space-between">
              <h4 className="card-title">{t('individuals')}</h4>
              <Button
                prefix={<Icon component={Plus} />}
                onClick={() => onClickAdd(AssociateType.NATURAL)}
              >
                {t('add_new')}
              </Button>
            </Space>
          </Card.Header>
          <Card.Body className="p-0">
            <Table
              size="big"
              className="table-no-border"
              columns={columnsIndividuals}
              data={dataIndividuals}
            />
          </Card.Body>
        </Card>

        {visible && <AddPartnerModal type={type} data={data} onClose={onClose} title={title} />}

        {companyData?.associates.length === 0 && (
          <Alert type="warning" message={t('associates_warning')} className="mb-25" />
        )}

        <Card className="px-20 py-15 mb-25">
          <Space justify="end">
            <Button type="ghost" className="text-nowrap" onClick={() => ctx.prevStep()}>
              <span className="company-btn__arrow mr-10">
                <ArrowLeft />
              </span>
              {t('prev_step')}
            </Button>
            <Button
              type="primary"
              className="text-nowrap"
              onClick={() => ctx.nextStep()}
              disabled={companyData?.associates.length === 0}
            >
              {t('next_step')}
              <span className="company-btn__arrow ml-10">
                <ArrowRight />
              </span>
            </Button>
          </Space>
        </Card>
      </div>
    </Loader>
  );
};

export default FoundersData;
