import * as React from 'react';
import { ErrorFallback, Layout } from 'components';

export const NotFound: React.FC = () => {
  return (
    <Layout>
      <ErrorFallback />
    </Layout>
  );
};
