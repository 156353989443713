import { default as queryString } from 'query-string';
import { Properties } from 'types';

export const stringifyUrl = (url: string, query: Properties = {}): string =>
  queryString.stringifyUrl(
    {
      url,
      query,
    },
    { skipEmptyString: true, skipNull: true },
  );
