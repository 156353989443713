import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Label, Icon, Space } from 'ebs-design';
import { useHistory } from 'react-router-dom';
import { usePermissions } from 'hooks';
import { UserContext } from 'contexts';
import { formatDate } from 'libs';
import {
  Invoice,
  Status,
  Column,
  CustomerRole,
  SystemRole,
  Permissions,
  InvoiceStatus,
} from 'types';
import { getCellIconType, renderCellByRole, arrayContainsArray, formatNumber } from 'utils';

export const useInvoiceColumns = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { userRoles } = React.useContext(UserContext);
  const can = usePermissions(Permissions.INVOICES);

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  const hideData = React.useCallback(
    (status: InvoiceStatus) =>
      isAdherent &&
      [
        InvoiceStatus.DRAFT,
        InvoiceStatus.SENT,
        InvoiceStatus.APPROVED,
        InvoiceStatus.DENIED,
      ].includes(status),
    [isAdherent],
  );

  const columns: Column<Invoice>[] = React.useMemo(
    () => [
      ...(can.read?.columns?.adherent
        ? [
            {
              title: t('adherent'),
              dataIndex: ['adherent', 'title'],
              checked: true,
              className: 'text-wrap',
              width: 250,
            },
          ]
        : []),
      ...(can.read?.columns?.debtor
        ? [
            {
              title: t('debtor'),
              dataIndex: ['debtor', 'title'],
              checked: true,
              className: 'text-wrap',
              width: 250,
            },
          ]
        : []),
      {
        title: t('invoice_number'),
        dataIndex: 'number',
        checked: true,
        filter: 'invoice_number',
        onCell: (rowData) => ({
          className: 'ebs-table__cell--clickable',
          onClick: () => push(`/invoices/${rowData.id}`),
        }),
        render: (value, rowData) =>
          rowData?.series || value ? `${rowData?.series || ''}${value || ''}` : '---',
      },
      {
        title: t('issuing_date'),
        checked: true,
        filter: 'issuing_date',
        render: ({ issuing_date }) => (issuing_date ? formatDate(issuing_date) : '---'),
        width: 100,
      },
      {
        title: t('assignment_date'),
        checked: true,
        filter: 'assignment_date',
        render: ({ assignment_date }) => (assignment_date ? formatDate(assignment_date) : '---'),
        width: 100,
      },
      {
        title: t('term_date'),
        checked: true,
        filter: 'term_date',
        render: ({ term_date }) => (term_date ? formatDate(term_date) : '---'),
        width: 100,
      },
      {
        title: t('delivery_date'),
        checked: true,
        filter: 'delivery_date',
        render: ({ delivery_date }) => (delivery_date ? formatDate(delivery_date) : '---'),
        width: 100,
      },
      {
        title: t('status'),
        checked: true,
        filter: 'status',
        render: ({ status }) => (
          <Label status={Status[status]} type="fill" circle text={t(status.toLowerCase())} />
        ),
      },
      {
        title: t('currency'),
        checked: true,
        dataIndex: ['currency', 'code_name'],
      },
      {
        title: t('invoice_amount'),
        checked: true,
        dataIndex: 'amount',
        filter: 'amount',
      },
      {
        title: t('commission'),
        checked: true,
        children: [
          {
            title: '%',
            render: ({ commission }) => commission?.percent,
          },
          {
            title: t('money'),
            render: ({ commission }) => commission?.amount,
          },
        ],
      },
      {
        title: t('guarantee'),
        checked: true,
        children: [
          {
            title: '%',
            render: ({ guarantee }) => guarantee?.percent,
          },
          {
            title: t('money'),
            render: ({ guarantee }) => guarantee?.amount,
          },
        ],
      },
      {
        title: t('type'),
        dataIndex: ['factoring_type', 'code_name'],
        checked: true,
        filter: 'type',
        render: (value) => (value ? t(value.toLowerCase()) : '---'),
      },
      {
        title: t('paid'),
        dataIndex: 'payed',
        checked: true,
        filter: 'payed',
      },
      {
        title: t('sold'),
        checked: true,
        filter: 'sold',
        render: (rowData) => (hideData(rowData.status) ? '---' : rowData.balance),
      },
      ...(can.read?.columns?.outstanding
        ? [
            {
              title: t('outstanding'),
              dataIndex: 'statistics',
              checked: true,
              render: (value) => value?.crontab?.days || '---',
            },
          ]
        : []),
      {
        title: t('paid_penalty'),
        checked: true,
        filter: 'penalty_paid',
        render: ({ penalty_paid }) => formatNumber(penalty_paid) || '---',
      },
      {
        title: t('penalty_sold'),
        checked: true,
        filter: 'penalty_sold',
        render: ({ penalty_sold }) => formatNumber(penalty_sold) || '---',
      },
      {
        title: t('back_guarantee'),
        checked: true,
        filter: 'guarantee',
        render: (rowData) => {
          const value = rowData?.guarantee?.returns?.to_return || 0;
          const isCustomer = userRoles.some((r) => Object.keys(CustomerRole).includes(r));
          const condition = isCustomer ? parseFloat(value) >= 0 : parseFloat(value) <= 0;
          const iconType = getCellIconType(condition);

          return hideData(rowData.status) ? (
            '---'
          ) : (
            <Space size={2}>
              <Label
                status={iconType.status}
                type="fill"
                circle
                icon={<Icon type={iconType.type} className="base-font-size" />}
              />
              {formatNumber(value)}
            </Space>
          );
        },
      },
      {
        title: t('inspector_factoring'),
        dataIndex: ['company', 'management'],
        checked: true,
        render: (management) => renderCellByRole(SystemRole.SY_CREDIT_OFFICER, management),
      },
      ...(can.read?.columns?.risk_manager
        ? [
            {
              title: t(SystemRole.SY_RISK_MANAGER),
              checked: true,
              render: (rowData) =>
                renderCellByRole(SystemRole.SY_RISK_MANAGER, rowData.company.management, false) ||
                renderCellByRole(SystemRole.SY_RISK_MANAGER, rowData.users, false) ||
                '---',
            },
          ]
        : []),
    ],
    [t, push, can, userRoles, hideData],
  );

  return columns;
};
