import * as React from 'react';
import { useNotify } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { UserContext } from 'contexts';
import { MAX_SIZE_UPLOAD } from 'utils';

/**
 * useUpload is used to get common upload props
 */
export const useUpload = (): {
  name: string;
  action: string;
  headers: { [key: string]: string };
  beforeUpload: (file: any, FileList: any[]) => boolean | Promise<void | File | Blob>;
} => {
  const { accessToken } = React.useContext(UserContext);
  const { t } = useIntl();
  const notify = useNotify();

  return {
    name: 'files',
    action: `${process.env.REACT_APP_BASE_API}/attachments/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    beforeUpload: (file) => {
      if (file.size > MAX_SIZE_UPLOAD) {
        notify.error({ title: t('upload'), description: `${t('file_size_exceeds')} 20MB` });
        return false;
      }
      return true;
    },
  };
};
