import * as React from 'react';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import { Card, Space, Table, Actions, Loader, Label, Row, Col, DatePicker } from 'ebs-design';
import { company, invoices } from 'api';
import { CompanyType, InvoicesTurnover, Properties } from 'types';
import { defaultFilters, formatNumber, transformResults } from 'utils';
import { useQueryParams, useQueryUpdate } from 'hooks';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfYearDateFormat,
  formatDateFilters,
} from 'libs';
import { Pagination, SmartSelect } from 'components';
import { AddPaymentModal } from './AddPaymentModal';
import { EditPaymentsModal } from './EditPaymentsModal';
import { useColumns } from './useColumns';
import { PaymentRow } from './PaymentRow';
import { RangePickerStateProps } from '../utils';

interface PaymentsProps {
  activeInvoiceType?: string;
  activeInvoiceId?: number;
  isLoadingCompany?: boolean;
}

export const Payments = ({
  activeInvoiceType,
  activeInvoiceId,
  isLoadingCompany,
}: PaymentsProps) => {
  const { t } = useIntl();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const { assignment_date_from, assignment_date_to, ...restParams } = params;

  const hasValidInvoiceType =
    activeInvoiceType && Object.keys(CompanyType).includes(activeInvoiceType);
  const activeInvoiceTypeParams =
    activeInvoiceType && activeInvoiceId && activeInvoiceType !== 'invoices'
      ? {
          [CompanyType.ADHERENT === activeInvoiceType ? 'company_id' : 'companies_id']:
            activeInvoiceId,
        }
      : {};

  const [filters, setFilters] = useSetState({ ...defaultFilters, ...restParams });
  const [range, setRange] = React.useState<RangePickerStateProps>([null, null]);

  // Actions modal states
  const [payments, setPayments] = useSetState({
    addVisible: false,
    editVisible: false,
    invoiceId: 0,
  });

  const { data, isLoading } = useQuery(
    [
      'invoices-report',
      {
        ...restParams,
        ...activeInvoiceTypeParams,
        ...(range?.[0] && range?.[1] && { assignment_date_from: formatDateFilters(range?.[0]) }),
        ...(range?.[0] && range?.[1] && { assignment_date_to: formatDateFilters(range?.[1]) }),
      },
    ],
    invoices.getReportList,
    { enabled: !isLoadingCompany },
  );

  const { data: calculatedData, isLoading: isLoadingCalculatedData } = useQuery(
    [
      'invoices-report-calculate',
      {
        ...(!hasValidInvoiceType && restParams),
        ...activeInvoiceTypeParams,
        search: null,
        series_number: filters.search,
        ...(!hasValidInvoiceType &&
          range?.[0] &&
          range?.[1] && { assignment_date_from: formatDateFilters(range?.[0]) }),
        ...(!hasValidInvoiceType &&
          range?.[0] &&
          range?.[1] && { assignment_date_to: formatDateFilters(range?.[1]) }),
      },
    ],

    invoices.getCalculatedReportList,
    {
      enabled: !isLoadingCompany && !!data?.results?.length,
    },
  );

  React.useEffect(
    () =>
      updateQuery({
        ...filters,
        assignment_date_from: range?.[0],
        assignment_date_to: range?.[1],
      }),
    [filters, updateQuery, range],
  );

  React.useEffect(() => {
    if (!isLoadingCompany && !hasValidInvoiceType && !assignment_date_from && !assignment_date_to) {
      setRange([firstDayOfYearDateFormat, currentDayDateFormat]);
    } else {
      setRange([assignment_date_from, assignment_date_to]);
    }
  }, []);

  const actionColumn = {
    title: null,
    action: true,
    render: (rowData) => (
      <Actions>
        <Actions.Item onClick={() => setPayments({ addVisible: true, invoiceId: rowData.id })}>
          {t('add_payment')}
        </Actions.Item>

        <Actions.Item onClick={() => setPayments({ editVisible: true, invoiceId: rowData.id })}>
          {t('edit_payments')}
        </Actions.Item>
      </Actions>
    ),
  };

  const columns = useColumns(hasValidInvoiceType ? null : actionColumn);

  // Send additional data to the table row
  const handleRow = (data: InvoicesTurnover): Properties => ({
    data,
    hasActions: !hasValidInvoiceType,
  });

  return (
    <>
      <Space justify="space-between" className="mt-5 mb-15">
        <h3 className="page-title">
          {t('register_payments')} ({data?.count || 0})
        </h3>

        <Space align="end" wrap>
          <Space>
            <Label text={t('invoice')} />

            <SmartSelect
              search
              filters={activeInvoiceTypeParams}
              enabled={
                activeInvoiceTypeParams &&
                !isLoading &&
                !isLoadingCalculatedData &&
                !isLoadingCompany
              }
              api={invoices.getList}
              value={filters.search}
              transform={(response) => transformResults(response, 'series_number', 'series_number')}
              onChange={(search) => setFilters({ search, page: 1 })}
              className="select-min-width"
            />
          </Space>

          {!hasValidInvoiceType && (
            <Space>
              <Space>
                <Label text={t('adherent')} />

                <SmartSelect
                  search
                  api={company.getList}
                  filters={{ types: CompanyType.ADHERENT.toLowerCase() }}
                  value={filters.company_id}
                  transform={transformResults}
                  onChange={(company_id) => setFilters({ company_id, page: 1 })}
                  className="select-min-width"
                />
              </Space>

              <Space>
                <Label text={t('debtor')} />

                <SmartSelect
                  search
                  api={company.getList}
                  filters={{ types: CompanyType.DEBTOR.toLowerCase() }}
                  value={filters?.companies_id}
                  transform={transformResults}
                  onChange={(companies_id) => setFilters({ companies_id, page: 1 })}
                  className="select-min-width"
                />
              </Space>
            </Space>
          )}

          <Space>
            <Label text="Period" />

            <DatePicker.RangeInput
              value={range}
              onChange={(value) => {
                !isLoading &&
                  !isLoadingCalculatedData &&
                  !isLoadingCompany &&
                  setRange(value as any);
              }}
              placeholderText={t('choose_period')}
              isClearable
              showYearDropdown
              dateFormat={dateFormat}
            />
          </Space>
        </Space>
      </Space>

      <Row>
        <Col size={12} sm={8} lg={4} xl={3}>
          <Loader
            height={100}
            loading={isLoadingCalculatedData || !!isLoadingCompany || isLoading}
            hidden={
              !filters.company_id &&
              !filters.companies_id &&
              !filters.search &&
              !hasValidInvoiceType
            }
          >
            <Card collapsible collapsed={false}>
              <Card.Body>
                <Space align="start" direction="vertical">
                  <>
                    <strong>
                      {t(hasValidInvoiceType ? 'invoice_debit' : 'funded_invoices')}:{' '}
                    </strong>
                    {formatNumber(calculatedData?.collected_total)}
                  </>

                  <>
                    <strong>
                      {t(hasValidInvoiceType ? 'returned_penalties' : 'penalties_to_recieve')}:{' '}
                    </strong>
                    {formatNumber(calculatedData?.penalty_total)}
                  </>

                  <>
                    <strong>
                      {t(hasValidInvoiceType ? 'receipt_guarantee' : 'refund_guarantee')}:{' '}
                    </strong>
                    {formatNumber(calculatedData?.warranty_total)}
                  </>
                </Space>
              </Card.Body>
            </Card>
          </Loader>
        </Col>
      </Row>

      <Card className="mt-20">
        <Card.Body className="p-0">
          <Loader loading={isLoading}>
            <Table
              rowKey="id"
              emptyCell="---"
              className="pc-table__subrows table-no-border"
              columns={columns}
              data={data?.results || []}
              onRow={handleRow}
              components={{
                body: {
                  row: PaymentRow,
                },
              }}
            />

            <AddPaymentModal
              key={`add-payment-${payments.invoiceId}`}
              visible={payments.addVisible}
              invoiceId={payments.invoiceId}
              onClose={() => setPayments({ addVisible: false, invoiceId: 0 })}
            />

            <EditPaymentsModal
              key={`edit-payments-${payments.invoiceId}`}
              visible={payments.editVisible}
              invoiceId={payments.invoiceId}
              onClose={() => setPayments({ editVisible: false, invoiceId: 0 })}
            />
          </Loader>
        </Card.Body>

        <Card.Footer>
          <Pagination data={data} filters={filters} setFilters={setFilters} />
        </Card.Footer>
      </Card>
    </>
  );
};
