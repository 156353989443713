import { CustomerRole } from 'types';

export default {
  perform: {
    navigation: {
      companyForm: [CustomerRole.ADMINISTRATOR],
    },
    signContract: [CustomerRole.ADMINISTRATOR],
  },
};
