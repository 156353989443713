import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Button, Modal, Space, Table } from 'ebs-design';
import { useSetState } from 'react-use';
import { HistoryTable } from 'components';
import { Column, RequestCompany, HistoryModels, RequestType } from 'types';
import { formatNumber } from 'utils';

interface Props {
  data: RequestCompany;
}

export const DebtorsTable: React.FC<Props> = ({ data }) => {
  const { t } = useIntl();
  const [history, setHistory] = useSetState<{ debtorId?: number; visible: boolean }>({
    visible: false,
  });

  const columns: Column<RequestCompany>[] = React.useMemo(
    () => [
      {
        title: t('ceiling_available'),
        dataIndex: ['limit', 'accessible'],
        render: (value) => formatNumber(value),
      },
      {
        title: t('nr_of_remaining_invoices'),
        dataIndex: ['statistics', 'invoices', 'remained', 'count'],
        render: (value) => formatNumber(value),
      },
      {
        title: t('amount_on_remaining_invoices'),
        dataIndex: ['statistics', 'invoices', 'remained', 'amount'],
        render: (value) => formatNumber(value),
      },
      {
        title: null,
        action: true,
        width: 100,
        render: ({ id }) => (
          <Space justify="end">
            <Button size="small" onClick={() => setHistory({ debtorId: id, visible: true })}>
              {t('history')}
            </Button>
          </Space>
        ),
      },
    ],
    [t, setHistory],
  );

  return (
    <>
      <Table columns={columns} data={[data]} />

      <Modal
        size="large"
        title={t('history')}
        open={history.visible}
        onClose={() => setHistory({ visible: false, debtorId: undefined })}
      >
        <Modal.Content className="p-0 pc-history-table">
          <HistoryTable
            model={HistoryModels.REQUEST}
            columns={['limit']}
            query={{ old_company: history.debtorId!, old_type: RequestType.CARD }}
            excludeAdherents
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
