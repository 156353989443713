import React, { useEffect, useState } from 'react';
import { templates } from '../../../../api/templates';
import { Button, Form, Space, Upload, useForm, useNotify } from 'ebs-design';
import { AttachmentType, Properties, Results, Template } from '../../../../types';
import { useIntl } from 'estafette-intl';
import { useUpload } from '../../../../hooks';
import { useMutation, useQueryClient } from 'react-query';

interface TemplateRowInterface {
  template: Template;
}

export const TemplateRow = ({ template }: TemplateRowInterface) => {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { t } = useIntl();
  const [form] = useForm();

  const uploadProps = useUpload();

  const [isFirstInit, setIsFirstInit] = useState(true);

  useEffect(() => {
    form.setFieldsValue({ [template.name]: template.attachment });
  }, []);

  const { mutate: onTemplateChange, isLoading: isTemplateChangeLoading } = useMutation(
    ({ id, attachment }) => templates.save({ id, attachment: attachment.id }),
    {
      onMutate: async ({ id, ...bodyData }: Properties) => {
        const query = ['default-templates'];
        const prevData = queryClient.getQueryData(query) as Results<Template>;

        queryClient.setQueryData(query, {
          ...prevData,
          results: prevData.results.map((template) => {
            if (template.id === id) {
              return { ...template, ...bodyData };
            }

            return template;
          }),
        });

        return () => queryClient.setQueryData(query, prevData);
      },
      onError: (err, _, rollback: any) => {
        rollback();

        notify.error({ title: t('client_template'), description: t('error_data_change') });
      },
      onSuccess: () => {
        notify.success({ title: t('client_template'), description: t('success_data_change') });
      },
    },
  );

  const onFieldsChange = (values) => {
    if (values[0].value && values[0].value.length) {
      if (values[0].value[0].uid) {
        return;
      }

      if (isFirstInit) {
        setIsFirstInit(false);

        return;
      }

      onTemplateChange({ id: template.id, attachment: values[0].value[0] });
    }
  };

  return (
    <Form key={template.name} form={form} onFieldsChange={onFieldsChange}>
      <Space>
        <span>{t(template.name)}:</span>
        <Form.Field name={template.name} className="template-list-item">
          <Upload
            defaultValue={template.attachment.url}
            data={{
              type: AttachmentType.TEMPLATE,
            }}
            {...uploadProps}
          >
            <Button loading={isTemplateChangeLoading}>{t('upload')}</Button>
          </Upload>
        </Form.Field>
      </Space>
    </Form>
  );
};
