import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { Card, InputSearch, Space, Table, Loader, Button, useNotify } from 'ebs-design';
import { company, reports } from 'api';
import { UserContext } from 'contexts';
import { Layout, Pagination } from 'components';
import { defaultFilters, arrayContainsArray } from 'utils';
import { useQueryParams } from 'hooks';
import { Column, ReportCompanyType, CustomerRole } from 'types';

import { DebtorLayout } from '../DebtorLayout';
import { DebtorProfile } from '../DebtorProfile';

export const AffiliatesCompany: React.FC = () => {
  const params = useQueryParams();
  const { t } = useIntl();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { userRoles } = React.useContext(UserContext);
  const [requestType, setType] = React.useState('');
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  const { data: affiliates_company, isLoading: isLoadingAffiliates } = useQuery(
    ['affiliates_company', id, filters],
    () => company.affiliates_company({ ...filters, id }),
    {
      enabled: Boolean(id),
    },
  );

  const request = useMutation((type: string) => reports.requestReports({ id, type }), {
    onSuccess: (response, type) => {
      if (response.length) {
        queryClient.invalidateQueries('affiliates_company');
      } else {
        notify.error({ title: t(`request_${type}`), description: t('no_data_found') });
      }

      setType('');
    },
  });

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('enterprise_name'),
        dataIndex: ['affiliate', 'title'],
        filter: 'title',
      },
      {
        title: t('person'),
        dataIndex: 'data',
        className: 'pc-table__subrows__column',
        render: (value) => (
          <>
            <div className="pc-table__subrows__item">{value.founders.name}</div>
            <div className="pc-table__subrows__item">{value.leaders.name}</div>
          </>
        ),
      },
      {
        title: t('affiliates_type'),
        dataIndex: 'data',
        className: 'pc-table__subrows__column pr-0',
        render: (value) => (
          <>
            <div className="pc-table__subrows__item">{value.founders.mode}</div>
            <div className="pc-table__subrows__item">{value.leaders.mode}</div>
          </>
        ),
      },
    ],
    [t],
  );

  const onChangeSearch = React.useCallback((search) => setFilters({ search }), [setFilters]);
  const onRequest = (type: ReportCompanyType) => {
    setType(type);
    request.mutate(type);
  };

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  return (
    <Layout>
      <DebtorLayout>
        <Loader loading={isLoading}>{data && <DebtorProfile data={data} />}</Loader>
        <Card className="company-details__main">
          <Card.Header>
            <Space justify="space-between">
              <Space>
                <h4 className="card-title">{`${t('affiliates_company')} (${
                  affiliates_company?.count || 0
                })`}</h4>

                <InputSearch
                  onSearch={onChangeSearch}
                  placeholder={t('search')}
                  styleType="fill"
                  isClearable
                />
              </Space>
              {!isAdherent && (
                <Space>
                  <Button
                    onClick={() => onRequest(ReportCompanyType.BIC)}
                    loading={requestType === ReportCompanyType.BIC && request.isLoading}
                  >
                    {t('request_bic')}
                  </Button>
                  <Button
                    onClick={() => onRequest(ReportCompanyType.INFODEBIT)}
                    loading={requestType === ReportCompanyType.INFODEBIT && request.isLoading}
                  >
                    {t('request_infodebit')}
                  </Button>
                </Space>
              )}
            </Space>
          </Card.Header>

          <Card.Body className="p-0">
            <Loader loading={isLoadingAffiliates}>
              <Table
                className="pc-penalties__table table-no-border"
                columns={columns}
                data={affiliates_company?.results}
              />
            </Loader>
          </Card.Body>

          <Card.Footer>
            <Pagination data={data} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </DebtorLayout>
    </Layout>
  );
};
