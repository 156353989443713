import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Row, Col, Space, DatePicker } from 'ebs-design';
import { StatisticCard, RadioButtons } from 'components';
import { useReports } from 'hooks/useReports';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfYearDateFormat,
  formatDateFilters,
} from 'libs';
import { ReportType, ReportInvoicesStatusesExtends, Period, Currencies } from 'types';
import {
  reduceInvoiceExtendsStatistic,
  ReportInvoicesReducedStatistic,
  getInvoicesByRange,
  calcPercentOfExpiredInvoice,
  RangePickerStateProps,
} from './utils';

export const FactoringPortfolioReport: React.FC = () => {
  const { t } = useIntl();
  const [period, setPeriod] = React.useState(Period.YEAR);
  const [range, setRange] = React.useState<RangePickerStateProps>([null, null]);

  const periodOptions = React.useMemo(
    () =>
      Object.values([Period.DAY, Period.WEEK, Period.MONTH, Period.YEAR]).map((v) => ({
        value: v,
        text: t(`${v.toLowerCase()}`),
      })),
    [t],
  );

  const { data, refetch } = useReports<
    ReportInvoicesStatusesExtends[],
    ReportInvoicesReducedStatistic
  >(
    ReportType.INVOICES_STATUSES_EXTENDS,
    {
      date_from: range?.[0] && formatDateFilters(range?.[0]),
      date_to: range?.[1] && formatDateFilters(range?.[1]),
      period,
    },
    { select: (data = []) => reduceInvoiceExtendsStatistic(data) },
  );

  React.useEffect(() => {
    refetch();
  }, [period, refetch]);

  // Render active invoices by factoring type
  const renderByFactoringType = (prop: string, suffix = '') => (
    <Space justify="space-between" className="statistic-card__info">
      <div>
        <h4>
          {data?.ACTIVE?.REGRESSION?.[prop] || 0} {suffix}
        </h4>
        <p className="color-primary">{t('with_regression')}</p>
      </div>
      <div>
        <h4>
          {data?.ACTIVE?.NO_REGRESSION?.[prop] || 0} {suffix}
        </h4>
        <p className="color-primary">{t('without_regression')}</p>
      </div>
    </Space>
  );

  // Render ranges of expired invoices
  const renderExpiredInvoicesByRanges = (): React.ReactNode => {
    const breakdowns = data?.EXPIRE?.BREAKDOWN
      ? data?.EXPIRE?.BREAKDOWN.map(({ range, percent }) => ({ range, percent }))
      : [];

    const ranges = getInvoicesByRange(breakdowns);

    return ranges.map(({ range, percent }, idx) => (
      <div key={idx} className="statistic-card__child">
        <h5>{`${range} ${t('days')}`}</h5>
        <p>{percent || 0} %</p>
      </div>
    ));
  };

  React.useEffect(() => {
    setRange([firstDayOfYearDateFormat, currentDayDateFormat]);
  }, []);

  return (
    <>
      <Space justify="space-between" className="mb-20 mt-5">
        <Space>
          <h3 className="page-title">{t('reportFactoringPortfolio')}</h3>
          <RadioButtons options={periodOptions} value={period} onChange={(v) => setPeriod(v)} />
        </Space>
        <DatePicker.RangeInput
          value={range}
          onChange={(value) => setRange(value as any)}
          isClearable
          placeholderText={t('choose_period')}
          dateFormat={dateFormat}
        />
      </Space>
      <Row className="mb-25">
        <Col size={3}>
          <StatisticCard title={t('nr_of_active_invoices')} total={data?.ACTIVE?.TOTAL?.total}>
            {renderByFactoringType('percent', '%')}
          </StatisticCard>
        </Col>

        <Col size={3}>
          <StatisticCard
            title={t('sum_of_active_invoices')}
            total={data?.ACTIVE?.TOTAL?.amounts}
            currency={Currencies.MDL}
          >
            {renderByFactoringType('amounts')}
          </StatisticCard>
        </Col>

        <Col size={3}>
          <StatisticCard
            title={t('active_average_invoice')}
            total={data?.ACTIVE?.TOTAL?.averages}
            currency={Currencies.MDL}
          >
            {renderByFactoringType('averages')}
          </StatisticCard>
        </Col>

        <Col size={3}>
          <StatisticCard
            title={t('active_invoices_commission')}
            total={data?.ACTIVE?.TOTAL?.commissions}
            currency={Currencies.MDL}
          >
            {renderByFactoringType('commissions')}
          </StatisticCard>
        </Col>
      </Row>

      <Row>
        <Col size={3}>
          <StatisticCard
            primary
            title={t('expired_invoices_number')}
            total={data?.EXPIRE?.TOTAL?.total}
            info={{
              title: `${calcPercentOfExpiredInvoice(data)} %`,
              description: t('from_active_invoices'),
            }}
          >
            {renderExpiredInvoicesByRanges()}
          </StatisticCard>
        </Col>

        <Col size={3}>
          <StatisticCard
            primary
            title={t('amount_expired_invoices')}
            total={data?.EXPIRE?.TOTAL?.amounts}
            currency={Currencies.MDL}
          >
            {renderExpiredInvoicesByRanges()}
          </StatisticCard>
        </Col>

        <Col size={3}>
          <StatisticCard
            primary
            title={t('average_invoice_expired')}
            total={data?.EXPIRE?.TOTAL?.averages}
            currency={Currencies.MDL}
          >
            {renderExpiredInvoicesByRanges()}
          </StatisticCard>
        </Col>

        <Col size={3}>
          <StatisticCard
            primary
            title={t('outstanding_invoices_commission')}
            total={data?.EXPIRE?.TOTAL?.commissions}
            currency={Currencies.MDL}
          >
            {renderExpiredInvoicesByRanges()}
          </StatisticCard>
        </Col>
      </Row>
    </>
  );
};
