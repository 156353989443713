import { Attachment } from './attachment';

export enum TemplatesTypes {
  CLIENT = 'client_template',
  PROCURE = 'procure_template',
}

export const apiTemplateType = 'TEMPLATE';

export interface Template {
  id: number;
  attachment: Attachment;
  timestamp: string;
  edited_timestamp: string;
  name: string;
  is_default: boolean;
  company: number;
}
