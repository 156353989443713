import * as React from 'react';
import { Container, Alert } from 'ebs-design';
import { Layout } from 'components/organisms';
import { UserContext } from 'contexts';
import { SystemRole, CustomerRole } from 'types';
import { arrayContainsArray } from 'utils';
import { Adherent } from './Adherent';
import { Admin } from './Admin';
import { Collector } from './Collector';
import { CreditCommittee } from './CreditCommittee';
import { PortfolioReport } from './PortfolioReport'; // Credit Officer and Risk Manager
import { Certified } from './Certified';

export const Home: React.FC = () => {
  const { userRoles } = React.useContext(UserContext);

  // Render home page by user role
  if (
    arrayContainsArray(
      [
        CustomerRole.ADHERENT,
        CustomerRole.DEBTOR,
        CustomerRole.ADMINISTRATOR,
        CustomerRole.ACCOUNTANT,
        CustomerRole.CHIEF_ACCOUNTANT,
        CustomerRole.MANAGER,
        CustomerRole.CONTACT,
        CustomerRole.REPRESENTATIVE,
        CustomerRole.BENEFICIARY,
        CustomerRole.RESPONSIBLE,
      ],
      userRoles,
    )
  ) {
    return <Adherent />;
  } else if (userRoles.includes(SystemRole.SY_ADMIN)) {
    return <Admin />;
  } else if (userRoles.includes(SystemRole.SY_COLLECTOR)) {
    return <Collector />;
  } else if (userRoles.includes(SystemRole.SY_CREDIT_COMMITTEE)) {
    return <CreditCommittee />;
  } else if (
    arrayContainsArray(
      [
        SystemRole.SY_CREDIT_OFFICER,
        SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
        SystemRole.SY_RISK_MANAGER,
      ],
      userRoles,
    )
  ) {
    return <PortfolioReport />;
  } else if (userRoles.includes(SystemRole.SY_IT_MANAGER)) {
    return <Certified />;
  } else {
    return (
      <Layout>
        <Container>
          <Alert type="warning" message="This role doesn't have home page!" />
        </Container>
      </Layout>
    );
  }
};
