import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { Label, Row, Col, useNotify, Loader } from 'ebs-design';
import { useMutation, useQueryClient } from 'react-query';
import { invoices } from 'api';
import { UserContext } from 'contexts';
import { Invoice, CompanyType, Properties, SystemRole, InvoiceStatus } from 'types';
import { formatDate } from 'libs';
import { extractResponseProps, formatNumber, getPenaltyWithText, NO_DATA_PLACEHOLDER } from 'utils';
import { InlineEdit } from 'components';

export const InvoiceDetails: React.FC<{ data?: Invoice }> = ({ data }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { userRoles } = React.useContext(UserContext);
  const currency = data?.currency?.code_name;

  const { mutate, isLoading, variables } = useMutation(
    (params: Properties) => invoices.patchInvoicePartial(id, params),
    {
      onMutate: async (params: Properties) => {
        const query = ['invoice-partial', id];
        const prevData = queryClient.getQueryData(query) as Invoice;

        queryClient.setQueryData(query, {
          ...prevData,
          ...params,
        });

        return () => queryClient.setQueryData(query, prevData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['invoice', id]);
        notify.success({ title: t('invoice'), description: t('success_data_save') });
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  return (
    <>
      <Row>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('debtor')}</h5>
            <p className="invoice-details__value">
              {data?.companies?.find(
                (company) =>
                  company.types.includes(CompanyType.DEBTOR) && company.id !== data?.company?.id,
              )?.title || NO_DATA_PLACEHOLDER}
            </p>
          </div>
        </Col>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('assignment_date')}</h5>
            <p className="invoice-details__value">
              {(data?.assignment_date && formatDate(data.assignment_date)) || NO_DATA_PLACEHOLDER}
            </p>
          </div>
        </Col>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('sum_of_invoice')}</h5>
            <p className="invoice-details__value">
              {data?.amount} {currency}
            </p>
          </div>
        </Col>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('factoring_type')}</h5>
            {data?.factoring_type && (
              <Label
                type="fill"
                status="success"
                text={t(data?.factoring_type?.type.toLocaleLowerCase())}
              />
            )}
          </div>
        </Col>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('commission')} (%)</h5>
            {(userRoles.includes(SystemRole.SY_RISK_MANAGER) &&
              data?.status === InvoiceStatus.SENT) ||
            userRoles.includes(SystemRole.SY_ADMIN) ? (
              <>
                {isLoading && variables && Object.keys(variables).includes('commission_percent') ? (
                  <Loader.Spinner size="small" />
                ) : (
                  <InlineEdit
                    defaultValue={data?.commission?.percent || NO_DATA_PLACEHOLDER}
                    onChange={(commission_percent) => mutate({ commission_percent })}
                    extra="%"
                  />
                )}
              </>
            ) : (
              <p className="invoice-details__value">{data?.commission?.percent}%</p>
            )}
          </div>
        </Col>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">
              {t('commission')} ({t('money')})
            </h5>
            <p className="invoice-details__value">
              {data?.commission?.amount} {currency}
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('paid')}</h5>
            <p className="invoice-details__value">
              {data?.payed} {currency}
            </p>
          </div>
        </Col>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('sold')}</h5>
            <p className="invoice-details__value">
              {data?.balance} {currency}
            </p>
          </div>
        </Col>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('guarantee_set')} (%)</h5>
            {(userRoles.includes(SystemRole.SY_RISK_MANAGER) &&
              data?.status === InvoiceStatus.SENT) ||
            userRoles.includes(SystemRole.SY_ADMIN) ? (
              <>
                {isLoading && variables && Object.keys(variables).includes('guarantee_percent') ? (
                  <Loader.Spinner size="small" />
                ) : (
                  <InlineEdit
                    defaultValue={data?.guarantee?.percent || NO_DATA_PLACEHOLDER}
                    onChange={(guarantee_percent) => mutate({ guarantee_percent })}
                    extra="%"
                  />
                )}
              </>
            ) : (
              <p className="invoice-details__value">{data?.guarantee?.percent}%</p>
            )}
          </div>
        </Col>

        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">
              {t('guarantee_set')} ({t('money')})
            </h5>
            <p className="invoice-details__value">
              {formatNumber(data?.guarantee?.amount)} {currency}
            </p>
          </div>
        </Col>

        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('guaranteed_refund')}</h5>
            <p className="invoice-details__value">
              {formatNumber(data?.guarantee?.returns?.current)} {currency}
            </p>
          </div>
        </Col>

        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('invoice_term_date')}</h5>
            <p className="invoice-details__value">
              {(data?.term_date && formatDate(data?.term_date)) || NO_DATA_PLACEHOLDER}
            </p>
          </div>
        </Col>

        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('invoice_term_days')}</h5>
            <p className="invoice-details__value">{data?.term_number || NO_DATA_PLACEHOLDER}</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('paid_penalty')}</h5>
            <p className="invoice-details__value">
              {formatNumber(data?.penalty_paid)} {currency}
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col size={6} sm={4} md={3} lg={2}>
          <div className="invoice-details">
            <h5 className="invoice-details__label">{t('penalty_sold')}</h5>
            <p className="invoice-details__value">
              {formatNumber(data?.penalty_sold)} {currency}
            </p>
          </div>
        </Col>
      </Row>

      {data?.delivery_contract ? (
        <>
          <Row>
            <Col size={12}>
              <h4>{t('delivery_contract')}</h4>
            </Col>
          </Row>
          <Row>
            <Col size={6} sm={4} md={3} lg={2}>
              <div className="invoice-details">
                <h5 className="invoice-details__label">{t('date')}</h5>
                <p className="invoice-details__value">
                  {(data?.delivery_contract?.date && formatDate(data?.delivery_contract?.date)) ||
                    NO_DATA_PLACEHOLDER}
                </p>
              </div>
            </Col>
            <Col size={6} sm={4} md={3} lg={2}>
              <div className="invoice-details">
                <h5 className="invoice-details__label">{t('additional_data.number')}</h5>
                <p className="invoice-details__value">
                  {data?.delivery_contract?.number || NO_DATA_PLACEHOLDER}
                </p>
              </div>
            </Col>
            <Col size={6} sm={4} md={3} lg={2}>
              <div className="invoice-details">
                <h5 className="invoice-details__label">{t('delivery_date')}</h5>
                <p className="invoice-details__value">
                  {(data?.delivery_date && formatDate(data?.delivery_date)) || NO_DATA_PLACEHOLDER}
                </p>
              </div>
            </Col>

            <Col size={6} sm={4} md={3} lg={2}>
              <div className="invoice-details">
                <h5 className="invoice-details__label">{t('penalties')}</h5>
                <p className="invoice-details__value">
                  {getPenaltyWithText(data?.delivery_contract?.penalty, t)}
                </p>
              </div>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};
