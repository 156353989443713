import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Card, Table } from 'ebs-design';
import { Column, Company, Invoice } from 'types';
import { formatDate } from 'libs';

interface Props {
  data: Invoice[];
}

export const InvoicesTable: React.FC<Props> = ({ data }) => {
  const { t } = useIntl();

  const columns: Column<Invoice>[] = React.useMemo(
    () => [
      {
        title: t('debtor'),
        dataIndex: 'companies',
        render: (companies: Company[]) => (
          <>
            {companies.map((company) => (
              <div key={company.id}>{company.title}</div>
            ))}
          </>
        ),
      },
      {
        title: t('invoice_number'),
        dataIndex: 'number',
        render: (value, rowData) => `${rowData?.series || ''}${value}`,
      },
      {
        title: t('sum_of_invoice'),
        dataIndex: 'balance',
      },
      {
        title: t('sign_date'),
        dataIndex: 'signed_date',
        render: (signedDate) => (signedDate ? formatDate(signedDate) : '---'),
      },
      {
        title: t('delivery_date'),
        dataIndex: 'delivery_date',
        render: (deliveryDate) => (deliveryDate ? formatDate(deliveryDate) : '---'),
      },
      {
        title: t('term'),
        dataIndex: 'term_number',
        render: (term_number) => `${term_number} ${t('days')}`,
      },
      {
        title: t('commission'),
        dataIndex: ['commission', 'percent'],
        render: (value) => `${value}%`,
      },
      {
        title: t('factoring_type'),
        dataIndex: ['factoring_type', 'code_name'],
        render: (value) => t(value?.toLowerCase()),
      },
    ],
    [t],
  );

  return (
    <Card className="mb-20">
      <Card.Header bordered>{t('invoices')}</Card.Header>
      <Card.Body className="p-0">
        <Table className="table-no-border" columns={columns} data={data} />
      </Card.Body>
    </Card>
  );
};
