import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Button, useForm, Form, Space, Icon, Row, Col, Select, useNotify } from 'ebs-design';
import { useMutation, useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import { invoices, turnovers } from 'api';
import {
  ListTurnover,
  Turnover,
  TurnoverOperationType,
  TurnoverPaymentType,
  TurnoverStatusType,
} from 'types';
import { extractResponseProps, validateResponse } from 'utils';
import { DatePicker, SmartSelect, InputNumericForm } from 'components';
import { dateFormat, dayMothYearFormat, formatDate } from 'libs';

interface TurnoversFormProps {
  initialData?: ListTurnover;
  isOpen: boolean;
  onClose: () => void;
}

export const TurnoversForm = ({ isOpen = false, initialData, onClose }: TurnoversFormProps) => {
  const { t } = useIntl();
  const [form] = useForm();
  const queryClient = useQueryClient();
  const notify = useNotify();

  React.useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        ...initialData,
        date: formatDate(initialData?.date),
      });
    }
  }, [initialData, form]);

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(
    (variables: Turnover) =>
      initialData?.id
        ? turnovers.updateTurnovers({
            ...variables,
            id: initialData?.id,
            invoice: initialData?.invoice?.id,
            date: dayjs(variables?.date, dayMothYearFormat).toISOString(),
          })
        : turnovers.create({
            ...variables,
            invoice_id: Number(variables.invoice_id),
            date: dayjs(variables?.date, dayMothYearFormat).toISOString(),
          }),
    {
      onError: (err) => {
        validateResponse.bind(null, form);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('turnovers').then(() => handleClose());
      },
    },
  );

  const modalTitle = initialData ? t('edit_turnovers') : t('create_turnovers');

  return (
    <Modal open={isOpen} onClose={handleClose} title={modalTitle} size="small">
      <Modal.Content className="px-20 py-30">
        <Form form={form} onFinish={(value) => mutate(value)}>
          <Row>
            <Col size={6}>
              <InputNumericForm name="balance" label={t('sold')} rules={[{ required: true }]} />
            </Col>

            <Col size={6}>
              <InputNumericForm
                name="collected"
                label={t('collected')}
                rules={[{ required: true }]}
              />
            </Col>

            <Col size={6}>
              <InputNumericForm
                className="pt-10"
                name="penalty"
                label={t('penalty')}
                rules={[{ required: true }]}
              />
            </Col>

            <Col size={6}>
              <InputNumericForm
                className="pt-10"
                name="days"
                label={t('cdays')}
                rules={[{ required: true }]}
              />
            </Col>

            {!initialData && (
              <Col size={6}>
                <Form.Field
                  className="pt-10"
                  name="operation_type"
                  label={t('operation_type')}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={t('select')}
                    className="select-min-width"
                    options={Object.values(TurnoverOperationType).map((type) => ({
                      value: type,
                      text: t(type),
                    }))}
                  />
                </Form.Field>
              </Col>
            )}

            {!initialData && (
              <Col size={6}>
                <Form.Field
                  className="pt-10"
                  name="payment_type"
                  label={t('payment_type')}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={t('select')}
                    className="select-min-width"
                    options={Object.values(TurnoverPaymentType).map((type) => ({
                      value: type,
                      text: t(type),
                    }))}
                  />
                </Form.Field>
              </Col>
            )}

            {!initialData && (
              <Col size={6}>
                <Form.Field
                  className="pt-10"
                  name="status"
                  label={t('status')}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={t('select')}
                    className="select-min-width"
                    options={Object.keys(TurnoverStatusType).map((type) => ({
                      value: type,
                      text: t(TurnoverStatusType[type]),
                    }))}
                  />
                </Form.Field>
              </Col>
            )}

            {!initialData && (
              <Col className="pt-10" size={6}>
                <Form.Field name="invoice_id" label={t('invoices')} rules={[{ required: true }]}>
                  <SmartSelect
                    search
                    api={invoices.getList}
                    transform={(results) =>
                      results.map(({ id, number }) => ({
                        value: id,
                        text: number || '---',
                      }))
                    }
                    className="select-min-width"
                  />
                </Form.Field>
              </Col>
            )}

            <Col className="pt-10" size={6}>
              <InputNumericForm name="warranty" label={t('warrant')} rules={[{ required: true }]} />
            </Col>

            <Col className="pt-10" size={6}>
              <Form.Field name="date" label={t('turnover_date')} rules={[{ required: true }]}>
                <DatePicker dateFormat={dateFormat} />
              </Form.Field>
            </Col>
          </Row>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            loading={isLoading}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
