import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Space, Icon, Textarea, useNotify } from 'ebs-design';
import { requests } from 'api';
import { Conclusion } from 'types';
import { PaperEdit } from 'resources';
import { extractResponseProps } from 'utils';

interface AddConclusionModalProps {
  id: number;
}

export const AddConclusionModal: React.FC<AddConclusionModalProps> = ({ id }) => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');

  React.useEffect(() => {
    if (!open) {
      setComment('');
    }
  }, [open]);

  const conclusion = useMutation((dataBody: Conclusion) => requests.addConclusion(id, dataBody), {
    onSuccess: () => {
      onToggleHandler();
      queryClient.invalidateQueries('company');
      notify.success({ title: t('company'), description: t('success_data_save') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const onToggleHandler = () => setOpen((i) => !i);

  const onConfirmHandler = () => {
    conclusion.mutate({
      comment,
    });
  };

  return (
    <>
      <Modal
        open={open}
        size="small"
        className="pc-confirmation-modal"
        header={t('add_conclusion')}
        onClose={onToggleHandler}
      >
        <Modal.Content className="p-30">
          <Textarea
            placeholder={`${t('conclusion')}...`}
            value={comment}
            onChange={(value) => setComment(value)}
          />
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onToggleHandler}>{t('cancel')}</Button>

            <Button
              type="primary"
              prefix={<Icon type="check" model="bold" />}
              onClick={onConfirmHandler}
            >
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
      <Button prefix={<Icon component={PaperEdit} />} onClick={onToggleHandler}>
        {t('add_conclusion')}
      </Button>
    </>
  );
};
