import * as React from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Card,
  Form,
  Icon,
  Input,
  Space,
  Button,
  Upload,
  Loader,
  Avatar,
  InputPhone,
  useNotify,
  useForm,
} from 'ebs-design';
import { useHistory } from 'react-router-dom';
import { MyProfileLayout } from 'features/profile/MyProfileLayout';
import { AddImage } from 'resources';
import { useUpload, useQueryParams } from 'hooks';
import { profile } from 'api';
import { CustomerRole, SystemRole, DocumentType } from 'types';
import { validateResponse, extractResponseProps } from 'utils';
import { DatePicker, SmartSelect, InputNumericForm } from 'components';

export const MyProfile: React.FC = () => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const uploadProps = useUpload();
  const queryClient = useQueryClient();
  const { goBack, push } = useHistory();
  const params = useQueryParams();

  const { data, isLoading, isFetching } = useQuery('myProfile', () => profile.getProfile());

  React.useEffect(() => {
    if (data) {
      form.resetFields();
      form.setFieldsValue({
        ...data,
        ...(data.passport && {
          passport: {
            ...data.passport,
            ...(data.passport.attachments?.length
              ? {
                  attachmentpass: data.passport.attachments,
                }
              : { attachmentpass: data?.passport?.attachment }),
          },
        }),

        ...(data.additional_data?.procure && {
          additional_data: {
            ...data.additional_data,
            procure: {
              ...data.additional_data?.procure,
              ...(data.additional_data?.procure.attachment && {
                attachment_data: [
                  {
                    id: data.additional_data.procure?.attachment.id,
                    file_name: data.additional_data.procure?.attachment.file_name,
                    name: data.additional_data.procure?.attachment.name,
                    url: data.additional_data.procure?.attachment.url,
                  },
                ],
              }),
            },
          },
        }),
      });
    }
  }, [data, form]);

  const patchUser = useMutation(profile.patchProfile, {
    onError: (err) => {
      validateResponse.bind(null, form, err);
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries('myProfile');
      queryClient.invalidateQueries('userProfile');
      notify.success({ title: t('my_profile'), description: t('success_data_change') });
      params?.go_to && push(params?.go_to);
    },
  });

  const onCancel = React.useCallback(() => goBack(), [goBack]);

  const handleSubmit = (data: any) => {
    if (!data?.password?.length || !data?.confirm_password?.length) {
      delete data.password;
      delete data.confirm_password;
    }

    patchUser.mutate({
      ...data,

      passport: {
        ...data.passport,
        attachmentpass: undefined,
        attachments_id:
          data?.passport?.attachmentpass && data?.passport?.attachmentpass.map(({ id }) => id),
      },
      ...(data.picture_id && { picture_id: data.picture_id[0].id }),
      additional_data: {
        position: data.additional_data.position,
        procure: {
          ...data.additional_data.procure,
          attachment_data: undefined,
          attachment_id: data.additional_data?.procure?.attachment_data?.[0]?.id,
        },
      },
    });
  };

  const showProcure = React.useMemo(
    () =>
      data?.roles?.some((i) =>
        [SystemRole.SY_ADMIN, SystemRole.SY_RISK_MANAGER, ...Object.keys(CustomerRole)].includes(
          i.name,
        ),
      ),
    [data],
  );

  const docTypeOptions = React.useMemo(
    () => [
      {
        text: t('driver_license'),
        value: DocumentType.DRIVER_LICENSE,
      },
      {
        text: t('bulletin'),
        value: DocumentType.INTERNAL_PASSPORT,
      },
      {
        text: t('passport'),
        value: DocumentType.INTERNATIONAL_PASSPORT,
      },
    ],
    [t],
  );

  return (
    <MyProfileLayout>
      <Loader loading={isLoading || isFetching}>
        <Card>
          <Form
            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
            id="user-profile"
            type="horizontal"
          >
            <input autoComplete="false" type="password" style={{ display: 'none' }} />

            <Card.Header className="user-card">
              <Space align="start">
                <Avatar
                  img={data?.picture?.url}
                  size="big"
                  alt={data && `${data.first_name} ${data.last_name}`}
                />

                <div className="my-profile__upload-box">
                  <h3>{t('upload_avatar')}</h3>
                  <p className="mb-10">{t('upload_avatar_condition')}</p>

                  <Form.Field name="picture_id">
                    <Upload
                      data={{
                        type: 'PICTURE',
                      }}
                      accept="image/*"
                      {...uploadProps}
                    >
                      <Button type="ghost" prefix={<Icon component={AddImage} />}>
                        {t('change_image')}
                      </Button>
                    </Upload>
                  </Form.Field>
                </div>
              </Space>
            </Card.Header>
            <Card.Body>
              <div className="form-content">
                <div className="col-12 col-sm-8 col-md-6 col-lg-5">
                  <div className="form-group">
                    <Form.Field name="first_name" label={t('first_name')}>
                      <Input />
                    </Form.Field>

                    <Form.Field name="last_name" label={t('last_name')}>
                      <Input />
                    </Form.Field>

                    <Form.Field name="email" label={t('email')}>
                      <Input type="email" />
                    </Form.Field>

                    <Form.Field name="phone" label={t('phone')}>
                      <InputPhone />
                    </Form.Field>

                    {data?.roles?.some((role) => role.name === CustomerRole.ADMINISTRATOR) && (
                      <>
                        <Form.Field
                          name={['passport', 'doc_type']}
                          label={t('document_type')}
                          rules={[{ required: true }]}
                        >
                          <SmartSelect options={docTypeOptions} />
                        </Form.Field>

                        <Form.Field
                          name={['passport', 'attachmentpass']}
                          label={t('identity_card')}
                          rules={[{ required: true }]}
                        >
                          {(control) => (
                            <Upload
                              multiple
                              {...uploadProps}
                              {...control}
                              disabled={control?.value?.length >= 2}
                            >
                              <Button disabled={control?.value?.length >= 2}>{t('upload')}</Button>
                            </Upload>
                          )}
                        </Form.Field>
                      </>
                    )}
                  </div>
                  <div className="form-divider" />
                  <div className="form-group">
                    <h3 className="form-heading">{t('change_password')}</h3>
                    <Form.Field name="password" label={t('password')}>
                      <Input type="password" />
                    </Form.Field>

                    <Form.Field name="confirm_password" label={t('confirm_password')}>
                      <Input type="password" />
                    </Form.Field>
                  </div>
                  {showProcure && (
                    <div className="form-group">
                      <h3 className="form-heading">{t('procure')}</h3>
                      <InputNumericForm
                        name={['additional_data', 'procure', 'number']}
                        label={t('additional_data.number')}
                      />

                      <Form.Field
                        name={['additional_data', 'procure', 'date']}
                        label={t('additional_data.date')}
                      >
                        <DatePicker />
                      </Form.Field>

                      <Form.Field
                        name={['additional_data', 'position']}
                        label={t('additional_data.position')}
                      >
                        <Input type="text" />
                      </Form.Field>
                      <Form.Field
                        name={['additional_data', 'procure', 'attachment_data']}
                        label={t('procure')}
                      >
                        <Upload
                          data={{
                            type: 'PROCURE',
                          }}
                          {...uploadProps}
                        >
                          <Button>{t('upload')}</Button>
                        </Upload>
                      </Form.Field>
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </Form>
          <Card.Footer>
            <Space justify="end">
              <Button type="ghost" className="text-nowrap" onClick={onCancel}>
                {t('cancel')}
              </Button>
              <Button type="primary" submit form="user-profile" className="text-nowrap">
                {t('save')}
              </Button>
            </Space>
          </Card.Footer>
        </Card>
      </Loader>
    </MyProfileLayout>
  );
};
