import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Label, Icon, Space } from 'ebs-design';
import { useHistory } from 'react-router-dom';
import { usePermissions } from 'hooks';
import { UserContext } from 'contexts';
import { formatDate } from 'libs';
import { Invoice, Status, Column, CustomerRole, Permissions, InvoiceStatus } from 'types';
import { getCellIconType, arrayContainsArray, formatNumber } from 'utils';

export const useInvoiceColumnsAdherent = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { userRoles } = React.useContext(UserContext);
  const can = usePermissions(Permissions.INVOICES);

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  const hideData = React.useCallback(
    (status: InvoiceStatus) =>
      isAdherent &&
      [
        InvoiceStatus.DRAFT,
        InvoiceStatus.SENT,
        InvoiceStatus.APPROVED,
        InvoiceStatus.DENIED,
      ].includes(status),
    [isAdherent],
  );

  const columns: Column<Invoice>[] = React.useMemo(
    () => [
      ...(can.read?.columns?.debtor
        ? [
            {
              title: t('debtor'),
              dataIndex: ['debtor', 'title'],
              checked: true,
              className: 'text-wrap',
              width: 170,
            },
          ]
        : []),
      {
        title: t('invoice_number_series'),
        dataIndex: 'number',
        checked: true,
        filter: 'invoice_number',
        onCell: (rowData) => ({
          className: 'ebs-table__cell--clickable',
          onClick: () => push(`/invoices/${rowData.id}`),
        }),
        render: (value, rowData) =>
          rowData?.series || value ? `${rowData?.series || ''}${value || ''}` : '---',
      },
      {
        title: t('issuing_date'),
        checked: true,
        filter: 'issuing_date',
        render: ({ issuing_date }) => (issuing_date ? formatDate(issuing_date) : '---'),
        width: 100,
      },
      {
        title: t('assignment_date'),
        checked: true,
        filter: 'assignment_date',
        render: ({ assignment_date }) => (assignment_date ? formatDate(assignment_date) : '---'),
        width: 100,
      },
      {
        title: t('term_pay'),
        checked: true,
        filter: 'term_date',
        render: ({ term_date }) => (term_date ? formatDate(term_date) : '---'),
        width: 100,
      },
      {
        title: t('status'),
        checked: true,
        filter: 'status',
        render: ({ status }) => (
          <Label status={Status[status]} type="fill" circle text={t(status.toLowerCase())} />
        ),
      },
      {
        title: t('invoice_value'),
        checked: true,
        dataIndex: 'amount',
        filter: 'amount',
      },
      {
        title: t('currency'),
        checked: true,
        dataIndex: ['currency', 'code_name'],
      },
      {
        title: t('commission'),
        checked: true,
        className: 'text-center',
        children: [
          {
            title: '%',
            render: ({ commission }) => commission?.percent,
          },
          {
            title: t('sum'),
            render: ({ commission }) => commission?.amount,
          },
        ],
      },
      {
        title: t('guarantee'),
        checked: true,
        className: 'text-center',
        children: [
          {
            title: '%',
            render: ({ guarantee }) => guarantee?.percent,
          },
          {
            title: t('sum'),
            render: ({ guarantee }) => guarantee?.amount,
          },
        ],
      },
      {
        title: t('invoice'),
        className: 'text-center',
        checked: true,
        children: [
          {
            title: t('turnovers'),
            render: ({ payed }) => payed,
          },
          {
            title: t('sold'),
            render: (rowData) => (hideData(rowData.status) ? '---' : rowData.balance),
          },
        ],
      },
      {
        title: t('guarantee_balance'),
        checked: true,
        filter: 'sold',
        render: (rowData) => {
          const value = rowData?.guarantee?.returns?.to_return || 0;
          const isCustomer = userRoles.some((r) => Object.keys(CustomerRole).includes(r));
          const condition = isCustomer ? parseFloat(value) >= 0 : parseFloat(value) <= 0;
          const iconType = getCellIconType(condition);

          return hideData(rowData.status) ? (
            '---'
          ) : (
            <Space size={2}>
              <Label
                status={iconType.status}
                type="fill"
                circle
                icon={<Icon type={iconType.type} className="base-font-size" />}
              />
              {formatNumber(value)}
            </Space>
          );
        },
      },
      {
        title: t('penalty_sold'),
        checked: true,
        filter: 'penalty_sold',
        render: ({ penalty_sold }) => formatNumber(penalty_sold) || '---',
      },
    ],
    [t, push, can, userRoles, hideData],
  );

  return columns;
};
