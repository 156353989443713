import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useLocation, useParams } from 'react-router-dom';
import {
  Adherent,
  EditFile,
  Invoices,
  RatesAndLimits,
  Timer,
  Bell,
  Report,
  Penalties,
  TurnoversIcon,
} from 'resources';
import { usePermissions } from 'hooks';
import { isRouteActive } from 'utils';
import { CustomerRole, Properties, SystemRole, Permissions } from 'types';

export type Roles = CustomerRole | SystemRole;

export interface Module {
  iconType?: string;
  iconComponent?: React.FC;
  label: string;
  route: string;
  params?: { [key: string]: any };
  active: boolean;
  index?: boolean;
  visible?: boolean;
}

export type Modules = { [key: string]: Module };

const getVisibleModules = (modules: Modules): Modules => {
  const visibleModules = {};

  Object.keys(modules).forEach((m) => {
    if (modules[m].visible) {
      visibleModules[m] = modules[m];
    }
  });

  return visibleModules;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useModules = () => {
  const { t } = useIntl();
  const { pathname } = useLocation();
  const params = useParams();
  const can = usePermissions(Permissions.MODULES);

  const modules: Modules = React.useMemo(
    () => ({
      home: {
        iconType: 'home',
        label: t('home'),
        route: 'Home',
        params: {},
        active: isRouteActive(pathname, params, ['Home', 'CompanyForm']),
        index: true,
        visible: can.read['home'],
      },
      users: {
        iconType: 'users',
        label: t('users'),
        route: 'Users',
        params: {},
        active: isRouteActive(pathname, params, ['Users']),
        visible: can.read['users'],
      },
      adherents: {
        iconComponent: Adherent,
        label: t('adherents'),
        route: 'Adherents',
        params: {},
        active: isRouteActive(pathname, params, [
          'Adherents',
          'AdherentDetails',
          'AdherentAffiliates',
          'AdherentRates',
          'AdherentInvoices',
          'AdherentCreditCommittee',
          'AdherentScoring',
          'AdherentContract',
          'AdherentDebtors',
          'AdherentManagement',
        ]),
        visible: can.read['adherents'],
      },
      requests: {
        iconComponent: EditFile,
        label: t('requests'),
        route: 'Requests',
        params: {},
        active: isRouteActive(pathname, params, ['Requests', 'RequestDetails']),
        visible: can.read['requests'],
      },
      reports: {
        iconComponent: Report,
        label: t('reports'),
        route: 'Reports',
        params: {},
        active: isRouteActive(pathname, params, ['Reports']),
        visible: can.read['reports'],
      },
      invoices: {
        iconComponent: Invoices,
        label: t('invoices'),
        route: 'Invoices',
        params: {},
        active: isRouteActive(pathname, params, [
          'Invoices',
          'Invoice',
          'EditInvoice',
          'AddInvoice',
        ]),
        visible: can.read['invoices'],
      },
      notifications: {
        iconComponent: Bell,
        label: t('notifications'),
        route: 'Notifications',
        params: {},
        active: isRouteActive(pathname, params, ['Notifications']),
        visible: can.read['notifications'],
      },
      debtors: {
        iconComponent: Timer,
        label: t('debtors'),
        route: 'Debtors',
        params: {},
        active: isRouteActive(pathname, params, [
          'Debtors',
          'DebtorDetails',
          'DebtorAffiliates',
          'DebtorRates',
          'DebtorInvoices',
          'DebtorCreditCommittee',
          'DebtorScoring',
          'DebtorForm',
          'DebtorContract',
          'DebtorAdherents',
          'DebtorManagement',
        ]),
        visible: can.read['debtors'],
      },
      penalties: {
        iconComponent: Penalties,
        label: t('delivery_contract'),
        route: 'PenaltiesPage',
        params: {},
        active: isRouteActive(pathname, params, ['PenaltiesPage']),
        visible: can.read['penalties'],
      },
      'limits-and-rates': {
        iconComponent: RatesAndLimits,
        label: t('limits_and_rates'),
        route: 'LimitsAndRates',
        params: {},
        active: isRouteActive(pathname, params, ['LimitsAndRates']),
        visible: can.read['limits-and-rates'],
      },
      turnovers: {
        iconComponent: TurnoversIcon,
        label: t('turnovers'),
        route: 'Turnovers',
        params: {},
        active: isRouteActive(pathname, params, ['Turnovers']),
        visible: can.read['turnovers'],
      },
    }),
    [t, pathname, params, can],
  );

  const allModules = getVisibleModules(modules);

  const indexModule: Properties = React.useMemo(
    () => Object.values(allModules).find((m) => m.index) || { route: 'Home' },
    [allModules],
  );

  return {
    allModules,
    indexModule,
    isCommonModule: isRouteActive(pathname, params, ['MyProfile', 'CompanyForm']),
  };
};
