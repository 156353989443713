import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button, Card, Space, Icon, Collapse, useNotify, Loader } from 'ebs-design';
import { UserContext } from 'contexts';
import { company } from 'api';
import { extractResponseProps, getRoute } from 'utils';
import { useCompanyUsers, useCompany } from 'hooks';
import { Delete, Plus, ArrowLeft } from 'resources';

import BeneficiaryForm from './BeneficiaryForm';
import SourceModal from '../SourceModal';
import { FormWizardContext } from '../FormWizard';
import { routes } from 'routes';
import { CompanyType } from 'types';

const Beneficiaries: React.FC = () => {
  const { t } = useIntl();
  const history = useHistory();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const ctx = React.useContext(FormWizardContext);
  const companyData = useCompany();

  const { user } = React.useContext(UserContext);

  const [modalVisible, setModalVisible] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [opened, setOpened] = React.useState<number[]>([]);

  const companyUsers = useCompanyUsers({
    beneficiary: true,
    limit: 50,
  });

  React.useEffect(() => {
    if (companyUsers.data?.results.length && !opened.length) {
      setOpened([companyUsers.data.results[0].id]);
    }
  }, [companyUsers, opened]);

  const onSuccess = (): void => {
    history.push(getRoute(routes, 'Home'));
    notify.success({ title: t('company_users'), description: t('success_data_save') });
  };

  // Handle update company
  const updateCompany = useMutation(company.update, {
    onSuccess: onSuccess,
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  // Handle remove user
  const deleteUser = useMutation(
    (userId: number) => company.deleteUser({ companyId: user!.company?.id, userId }),
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company-users');
        notify.success({ title: t('company_users'), description: t('success_data_delete') });
      },
    },
  );

  const handleDelete = (id: number) => {
    if (id) {
      deleteUser.mutate(id);
    }
  };

  const handleAdd = () => {
    setAddOpen(true);
    queryClient.invalidateQueries('company-users');
  };

  const handleSave = (data?: { management_id: number; found_out_source_id: number }) => {
    updateCompany.mutate({
      companyId: companyData.data?.id,
      data: {
        id: companyData.data?.id,
        ...(data?.management_id
          ? { management_id: [data.management_id] }
          : { assign_company_responsible: true }),
        additional_data: {
          found_out_source_id: data?.found_out_source_id,
          id: companyData.data?.additional_data?.id,
        },
        complete: true,
      },
    });
    setModalVisible(false);
  };

  const onChangeHandler = () => {
    setAddOpen(false);
    companyUsers.refetch();
  };

  const handleCollapseClick = (id: number) => {
    if (opened.includes(id)) {
      setOpened((s) => s.filter((i) => i !== id));
    } else {
      setOpened((s) => [id, ...s]);
    }
  };

  const handleFinishClick = () => {
    if (
      companyData.data?.types.includes(CompanyType.ADHERENT) &&
      companyData.data?.management &&
      companyData.data?.management.length === 0
    ) {
      setModalVisible(true);
    } else {
      handleSave();
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="p-0">
          <Loader loading={companyUsers.isLoading}>
            <Collapse.Group className="no-border">
              {companyUsers.data && companyUsers.data.results.length > 0
                ? companyUsers.data.results.map((beneficiary, idx) => {
                    return (
                      <div key={idx} id={`beneficiary-${idx}`}>
                        <Collapse bordered collapsed={idx !== 0}>
                          <Collapse.Header onClick={() => handleCollapseClick(beneficiary?.id)}>
                            <Space justify="space-between">
                              <Space>
                                <div className="company-collapse__count">{idx + 1}</div>
                                <h4 className="card-title">
                                  {t('effective_beneficiary')} -{' '}
                                  {`${beneficiary?.first_name || ''} ${
                                    beneficiary?.last_name || ''
                                  }`}
                                </h4>
                              </Space>
                              <Button
                                prefix={<Icon component={Delete} />}
                                onClick={() => handleDelete(beneficiary?.id)}
                                loading={deleteUser.isLoading}
                              >
                                {t('delete')}
                              </Button>
                            </Space>
                          </Collapse.Header>
                          <Collapse.Body className="p-0">
                            {opened.includes(beneficiary?.id) && (
                              <BeneficiaryForm
                                userId={beneficiary?.id}
                                onSuccess={onChangeHandler}
                              />
                            )}
                          </Collapse.Body>
                        </Collapse>
                      </div>
                    );
                  })
                : !addOpen && (
                    <div style={{ textAlign: 'center', padding: 40 }}>
                      <h4>{t('no_data')}</h4>
                    </div>
                  )}
              {addOpen && (
                <div id={`beneficiary-new`}>
                  <Collapse bordered collapsed={false}>
                    <Collapse.Header onClick={() => setAddOpen(false)}>
                      <Space justify="space-between">
                        <Space>
                          <div className="company-collapse__count">+</div>
                          <h4 className="card-title">{t('add_beneficiary')}</h4>
                        </Space>
                      </Space>
                    </Collapse.Header>
                    <Collapse.Body className="p-0">
                      <BeneficiaryForm onSuccess={onChangeHandler} />
                    </Collapse.Body>
                  </Collapse>
                </div>
              )}
            </Collapse.Group>
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Space justify="space-between">
            <Button
              type="ghost"
              className="text-nowrap"
              prefix={<Icon component={Plus} />}
              onClick={handleAdd}
              disabled={addOpen}
            >
              {t('add_beneficiary')}
            </Button>

            <Space>
              <Button type="ghost" className="text-nowrap" onClick={() => ctx.prevStep()}>
                <span className="company-btn__arrow mr-10">
                  <ArrowLeft />
                </span>
                {t('prev_step')}
              </Button>
              <Button
                type="primary"
                className="text-nowrap"
                disabled={companyUsers?.data?.results?.length === 0}
                onClick={handleFinishClick}
              >
                {t('finish')}
              </Button>
            </Space>
          </Space>
        </Card.Footer>
      </Card>

      <SourceModal visible={modalVisible} onSave={handleSave} onClose={handleSave} />
    </>
  );
};

export default Beneficiaries;
