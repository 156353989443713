import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Icon, useForm, Space, Loader } from 'ebs-design';
import { Classifier } from 'types';
import { IndicatorsForm, IndicatorsFormValues } from './IndicatorsForm';
import { nomenclature } from 'api';

export interface IndicatorsModalProps {
  classifier: Classifier | null;
  visible?: boolean;

  onClose(): void;
}

export const IndicatorsModal: FC<IndicatorsModalProps> = ({ visible, classifier, onClose }) => {
  const { t } = useIntl();
  const [classifierForm] = useForm<IndicatorsFormValues>();

  const { data } = useQuery<Classifier>(
    ['classifier', { id: classifier?.id }],
    nomenclature.getClassifiersById,
    {
      enabled: Boolean(classifier?.id),
    },
  );

  return (
    <Modal open={visible} size="small" onClose={onClose} title={t('edit_indicators')}>
      <Modal.Content className="p-0">
        <Loader loading={!data}>
          {data && <IndicatorsForm classifier={data} form={classifierForm} closeModal={onClose} />}
        </Loader>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="end">
          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={classifierForm.submit}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
