import { LabelStatus, Column, SystemRole } from 'types';

export const getCellIconType = (condition: boolean): { status: LabelStatus; type: string } =>
  condition ? { status: 'success', type: 'check' } : { status: 'danger', type: 'info' };

export const prepareColumns = <T>(tableName: string | null, columns: Column<T>[]): Column<T>[] => {
  if (!tableName) return columns;

  const selected = localStorage.getItem(`columns-${tableName}`);

  return columns.map((item, i) => ({
    ...item,
    ...(item.checked !== undefined && {
      checked: selected ? JSON.parse(selected).includes(i.toString()) : item.checked,
    }),
  }));
};

export const renderCellByRole = (role: SystemRole, management, empty = true): string | boolean => {
  // Prioritize if the role is able to operate
  const roleUser = management?.find(
    (m) =>
      (m.operating && Array.isArray(m.roles) && m.roles.find((r) => r.name === role)) ||
      (Array.isArray(m.roles) && m.roles?.find((r) => r.name === role)),
  );

  return roleUser ? `${roleUser.first_name || ''} ${roleUser.last_name || ''}` : empty && '---';
};
