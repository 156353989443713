import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import PhoneInput from 'react-phone-input-2';
import { Button, Form, Input, Space, Icon, Modal, useNotify, useForm } from 'ebs-design';
import { company } from 'api';
import { useRoles } from 'hooks';
import { Properties, User } from 'types';
import { extractResponseProps } from 'utils';

interface AddCustomerModalProps {
  data: Partial<User<string>>;
  onClose: () => void;
}

export const AddCustomerModal: React.FC<AddCustomerModalProps> = ({ data, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const queryClient = useQueryClient();
  const { customers: customersRoles } = useRoles();
  const notify = useNotify();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const update = useMutation(
    (bodyData: Properties) =>
      company.patchUser(data.company_id as number, data.id as number, bodyData),
    {
      onSuccess: () => {
        handleClose();
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customers_invitations');
        notify.success({ title: t('users'), description: t('success_data_change') });
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  React.useEffect(() => {
    if (data.id) {
      form.setFieldsValue({
        ...data,
        roles_id: customersRoles
          .filter((role) => data.roles?.includes(role.text as string))
          .map((role) => role.value),
      });
    }
  }, [data, form, customersRoles]);

  const onSubmit = React.useCallback(
    (values) => {
      if (data.id && data.company_id) {
        update.mutate({ ...values, id: data.id });
      } else {
        // TODO: Replace with notification
        console.error('Company id is missing!');
      }
    },
    [data, update],
  );

  return (
    <Modal
      open={true}
      onClose={handleClose}
      title={t(data.id ? 'edit_user' : 'add_user')}
      size="small"
    >
      <Modal.Content className="px-20 py-30">
        <Form
          form={form}
          type="horizontal"
          onFinish={onSubmit}
          labelOptions={{ col: { size: 4 } }}
          controlOptions={{ col: { size: 7 } }}
          fieldRow={{ gy: 3 }}
        >
          <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
            <Input />
          </Form.Field>

          <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
            <Input />
          </Form.Field>

          <Form.Field name="email" label={t('email')} rules={[{ required: true }]}>
            <Input type="email" />
          </Form.Field>

          <Form.Field name="idnp" label={t('idnp')} rules={[{ required: true, len: 13 }]}>
            <Input type="number" />
          </Form.Field>

          <Form.Field name="phone" label={t('phone')} rules={[{ required: true }]}>
            <PhoneInput country="md" />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            loading={update.isLoading}
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
