import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import cn from 'classnames';
import { useIntl } from 'estafette-intl';
import { Tooltip, Badge, Button, Icon, ListGroup, Chips, Space, Label, Loader } from 'ebs-design';
import { notifications } from 'api';
import { UserContext } from 'contexts';
import { Results, Notification, Properties, NotificationTypeLabel } from 'types';
import { Bell } from 'resources';
import { defaultFilters } from 'utils';
import { Pagination } from 'components';

const Notifications: React.FC = () => {
  const { t } = useIntl();
  const queryClient = useQueryClient();
  const { user } = React.useContext(UserContext);

  const notifyContainer = React.createRef<HTMLDivElement>();

  const [filters, setFilters] = useSetState({ ...defaultFilters });

  const { data, isLoading, isFetching } = useQuery(
    ['notifications', { target_id: user?.id, read: false, ...filters }],
    notifications.getList,
    {
      enabled: Boolean(user?.id),
      keepPreviousData: true,
    },
  );

  const notify = useMutation(
    ({ id, ...bodyData }: Properties) => notifications.update(id, bodyData),
    {
      onMutate: async ({ id, ...bodyData }: Properties) => {
        const query = ['notifications', { target_id: user!.id, read: false, ...filters }];
        const prevData = queryClient.getQueryData(query) as Results<Notification>;

        queryClient.setQueryData(query, {
          ...prevData,
          results: prevData.results.map((notification) => {
            if (notification.id === id) {
              return { ...notification, ...bodyData };
            }

            return notification;
          }),
        });

        return () => queryClient.setQueryData(query, prevData);
      },
      onError: (err, _, rollback: any) => rollback(),
      onSuccess: () => queryClient.invalidateQueries('notifications'),
    },
  );

  const hasPagination = React.useMemo(() => data && data.count > defaultFilters.limit, [data]);
  const badgeProps = React.useMemo(
    () => ({
      ...(data && data.count <= 10 && { count: data.count }),
      ...(data && data.count > 10 && { text: data.count.toString() }),
    }),
    [data],
  );

  React.useEffect(() => {
    if (!isLoading && !isFetching && notifyContainer.current) {
      notifyContainer.current.scrollTop = 0;
    }
  }, [isLoading, notifyContainer, isFetching]);

  return (
    <Tooltip
      bodyClass={cn('pc-notify', { 'has-pagination': hasPagination })}
      placement="bottom"
      trigger="click"
      offset={[-8, -2]}
      tooltip={
        <>
          <Loader loading={isFetching} height="50vh">
            <div className="pc-notify__container" ref={notifyContainer}>
              <ListGroup>
                {data?.results.map((item) => (
                  <ListGroup.Item key={item.id}>
                    <Chips
                      checked={item.read}
                      text={
                        <Space align="start">
                          <Icon type={item.read ? 'check-2' : 'eye'} />

                          <Space direction="vertical" size="small" align="start">
                            <h4>{item.metadata?.title || item.title}</h4>
                            <p
                              className="pc-notify__description"
                              dangerouslySetInnerHTML={{ __html: item.metadata?.description }}
                            />
                          </Space>

                          <Label
                            status={NotificationTypeLabel[item.type]}
                            text={t(item.type)}
                            type="ghost"
                            className="pc-notify__type"
                          />
                        </Space>
                      }
                      onChange={() => notify.mutate({ id: item.id, read: !item.read })}
                    />
                  </ListGroup.Item>
                ))}

                {!data?.results.length ? (
                  <ListGroup.Item>
                    <Space className="px-16 py-16">
                      <Label disabled text={t('no_notify')} />
                    </Space>
                  </ListGroup.Item>
                ) : null}
              </ListGroup>
            </div>
          </Loader>

          {hasPagination && (
            <Pagination
              className="pc-notify__pagination p-16"
              data={data}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </>
      }
    >
      <Badge {...badgeProps}>
        <Button size="small" type="primary">
          <Icon component={Bell} className="color-white base-font-size" />
        </Button>
      </Badge>
    </Tooltip>
  );
};

export default Notifications;
