export const languages = {
  en: {
    en: `English`,
    ro: `Engleză`,
    ru: `Английский`,
  },
  ro: {
    en: `Romanian`,
    ro: `Română`,
    ru: `Румынский`,
  },
  ru: {
    en: `Russian`,
    ro: `Rusă`,
    ru: `Русский`,
  },
};
