import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Card,
  Space,
  InputSearch,
  SortBy,
  Actions,
  Loader,
  Switch,
  ButtonGroup,
  Button,
  Label,
  useNotify,
  Icon,
} from 'ebs-design';
import { company as companyAPI } from 'api';
import { UserContext } from 'contexts';
import { usePermissions } from 'hooks';
import {
  Results,
  Debtor,
  Column,
  CompanyType,
  Permissions,
  Properties,
  SystemRole,
  RequestStatus,
} from 'types';
import {
  defaultFilters,
  getSortOptions,
  getCellIconType,
  extractResponseProps,
  getRoute,
} from 'utils';
import { Pagination } from 'components';

import { useDebtorColumns } from '../../debtors/useDebtorColumns';
import { routes } from 'routes';

export const DebtorsTable: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { user } = React.useContext(UserContext);
  const notify = useNotify();

  const queryClient = useQueryClient();
  const can = usePermissions(Permissions.DEBTORS);

  const [filters, setFilters] = useSetState({ ...defaultFilters });

  const { data, isLoading } = useQuery(
    [
      'companies-debtors',
      {
        ...filters,
        types: CompanyType.DEBTOR.toLowerCase(),
        request_stage: SystemRole.SY_CREDIT_COMMITTEE.toLowerCase(),
        request_committee_users_id: user!.id,
        request_status__in: RequestStatus.REVIEW.toLowerCase(),
      },
    ],
    companyAPI.getList,
    {
      enabled: !!user,
    },
  );

  const company = useMutation(
    ({ companyId, data }: Properties) => companyAPI.update({ companyId, data }),
    {
      onMutate: async ({ companyId, data }) => {
        const query = ['company', { ...filters, types: CompanyType.DEBTOR.toLowerCase() }];
        const prevData = queryClient.getQueryData(query) as Results<Debtor>;

        queryClient.setQueryData(query, {
          ...prevData,
          results: prevData.results.map((result) => {
            if (result.id === companyId) {
              Object.keys(data).forEach((item) => {
                result.state = data[item];
              });
            }

            return result;
          }),
        });

        return () => queryClient.setQueryData(query, prevData);
      },
      onError: (err, _, rollback: any) => {
        rollback();
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company');
        notify.success({ title: t('company'), description: t('success_data_save') });
      },
    },
  );

  const defaultColumns = useDebtorColumns();

  const columns: Column[] = React.useMemo(
    () => [
      ...defaultColumns,
      ...(can.read?.columns?.kyc
        ? [
            {
              title: t('kyc_approved'),
              className: 'text-center',
              render: ({ know_your_client_confirmed }) => {
                const iconType = getCellIconType(know_your_client_confirmed);

                return (
                  <Space justify="center">
                    <Label
                      status={iconType.status}
                      type="fill"
                      circle
                      icon={<Icon type={iconType.type} className="base-font-size" />}
                    />
                  </Space>
                );
              },
            },
          ]
        : []),
      ...(can?.perform?.blockDebtor
        ? [
            {
              title: t('block'),
              render: ({ id, state }) => (
                <Switch
                  onChange={() => company.mutate({ companyId: id, data: { state: !state } })}
                  checked={!state}
                />
              ),
            },
          ]
        : []),
      {
        title: null,
        action: true,
        render: ({ id }) => (
          <Actions>
            <Actions.Item onClick={() => push(getRoute(routes, 'DebtorDetails', { id }))}>
              {t('details')}
            </Actions.Item>
            {can?.edit?.debtor && (
              <Actions.Item onClick={() => push(getRoute(routes, 'DebtorForm', { id }))}>
                {t('edit')}
              </Actions.Item>
            )}
          </Actions>
        ),
      },
    ],
    [t, can, push, defaultColumns, company],
  );
  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  return (
    <>
      <Space align="center" justify="space-between" className="mt-30 mb-20">
        <Space align="center">
          <h3 className="page-title">
            {t('debtors')} ({data?.count || 0})
          </h3>
          <InputSearch
            placeholder={t('search')}
            styleType="fill"
            value={filters.search}
            onSearch={(search) => setFilters({ search, page: 1 })}
            isClearable
          />
        </Space>
        <Space>
          {can.read.filterByStatus && (
            <ButtonGroup className="flex-nowrap">
              <Button type="primary">{t('all')}</Button>
              <Button>{t('new')}</Button>
              <Button>{t('kyc_approved')}</Button>
              <Button>{t('waiting_approval')}</Button>
            </ButtonGroup>
          )}

          <SortBy
            options={sortOptions}
            value={filters?.ordering}
            onChange={(ordering) => setFilters({ ordering })}
          />
        </Space>
      </Space>
      <Card>
        <Card.Body className="p-0">
          <Loader loading={isLoading}>
            <Table className="table-no-border" columns={columns} data={user ? data?.results : []} />
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination data={user ? data : {}} filters={filters} setFilters={setFilters} />
        </Card.Footer>
      </Card>
    </>
  );
};
