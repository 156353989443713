import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Loader } from 'ebs-design';
import { company } from 'api/company';
import { Layout, IndividualRates, FutureRates, HistoryRates } from 'components/organisms';
import { usePermissions } from 'hooks';
import { CompanyType, Permissions } from 'types';

import { DebtorLayout } from '../DebtorLayout';
import { DebtorProfile } from '../DebtorProfile';

export const Rates: React.FC = () => {
  const { id } = useParams();
  const can = usePermissions(Permissions.DEBTORS);

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  return (
    <Layout>
      <DebtorLayout>
        <Loader loading={isLoading}>
          {data && <DebtorProfile data={data} isEditCategory={true} />}
        </Loader>
        <IndividualRates isCreateFutureRates={can.create.futureRates} />
        <FutureRates
          isCreateFutureRates={can.create.futureRates}
          companyType={CompanyType.DEBTOR}
        />
        <HistoryRates companyType={CompanyType.DEBTOR} />
      </DebtorLayout>
    </Layout>
  );
};
