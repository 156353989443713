import * as React from 'react';
import { useQuery } from 'react-query';
import { gateway, getOptionsByName } from 'api';
import { axios } from 'libs';
import { SelectOption, SystemRole } from 'types';

interface ReturnProps {
  customers: SelectOption[];
  users: SelectOption[];
  isLoading: boolean;
}

export const useRoles = (): ReturnProps => {
  const request = {
    users: {
      method: 'GET',
      url: `${axios.defaults.baseURL}/nomenclatures/roles/list/?system=true`,
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
      },
    },
    customers: {
      method: 'GET',
      url: `${axios.defaults.baseURL}/nomenclatures/roles/list/`,
      headers: {
        Authorization: axios.defaults.headers.common.Authorization,
      },
    },
  };

  const { data: response, isLoading } = useQuery(['roles'], () => gateway.externals(request), {
    enabled: !!axios.defaults.headers.common.Authorization,
  });

  const data = React.useMemo(
    () => ({
      customers: getOptionsByName(response?.data?.customers).sort((a, b) =>
        (a.text as string).localeCompare(b.text as string),
      ),
      users: getOptionsByName(response?.data?.users)
        .sort((a, b) => (a.text as string).localeCompare(b.text as string))
        .filter((u) => u.text !== SystemRole.SY_ROOT),
      isLoading,
    }),
    [response, isLoading],
  );

  return data;
};
