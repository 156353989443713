import * as React from 'react';
import { useIntl } from 'estafette-intl';
import dayjs from 'dayjs';
import { ColumnType } from 'ebs-design';
import { PaymentOrder, InvoicesTurnover } from 'types';
import { dayMothYearFormat, isDate } from 'libs';
import { formatNumber } from 'utils';
import { isNumber } from '../utils';

enum CollectedPayments {
  OPERATION_DATE = 'date',
  OPERATION_TYPE = 'operation_type',
  INVOICE_VALUE = 'collected',
  GUARANTEE = 'warranty',
  COMMISSION_FACTORING = 'amount',
  PENALTY = 'penalty',
  OUTSTANDING_DAYS = 'days',
}

export const useColumns = (actionColumn?: ColumnType<InvoicesTurnover> | null) => {
  const { t } = useIntl();

  const renderPaymentOrder = (
    paymentsOrders: PaymentOrder[],
    prop: string,
    width?: string,
    isMultiple?: boolean,
    isString?: boolean,
  ) =>
    paymentsOrders?.map((payment, idx) => {
      const value = payment?.[prop];

      let formattedValue;

      switch (true) {
        case typeof value === 'undefined':
          formattedValue = '0';
          break;
        case isString:
          formattedValue = t(value);
          break;
        case isNumber(value):
          formattedValue = formatNumber(value);
          break;
        case isDate(value):
          formattedValue = value && dayjs(value).format(dayMothYearFormat);
          break;
        default:
          formattedValue = '---';
          break;
      }

      return (
        <div
          key={`${value}-${idx}`}
          className="pc-table__subrows__item"
          style={{ width, padding: `${isMultiple ? 0.5 : 1.375}rem 1rem` }}
        >
          {formattedValue}
        </div>
      );
    });

  return [
    {
      title: t('invoice'),
      dataIndex: 'series_number',
    },
    { title: t('adherent'), dataIndex: ['adherent', 'title'] },
    { title: t('debtor'), dataIndex: ['debtor', 'title'] },
    {
      title: t('invoice_payment_term'),
      width: '100px',
      render: ({ term_date }) => (term_date ? dayjs(term_date).format(dayMothYearFormat) : '---'),
    },
    {
      title: t('operation_date'),
      width: '110px',
      render: (rowData) => ({
        children: (
          <div className="pc-table__subrows__cols">
            <td className="pc-table__subrows__turnovers text-center">
              {renderPaymentOrder(
                rowData?.report_turnovers?.turnovers,
                CollectedPayments.OPERATION_DATE,
                '110px',
                rowData?.report_turnovers?.turnovers?.length > 1,
              )}
            </td>
            <td className="pc-table__subrows__turnovers text-center">
              {renderPaymentOrder(
                rowData?.report_turnovers?.turnovers,
                CollectedPayments.OPERATION_TYPE,
                '160px',
                rowData?.report_turnovers?.turnovers?.length > 1,
                true,
              )}
            </td>
            <td className="pc-table__subrows__turnovers text-right">
              {renderPaymentOrder(
                rowData?.report_turnovers?.turnovers,
                CollectedPayments.INVOICE_VALUE,
                '160px',
                rowData?.report_turnovers?.turnovers?.length > 1,
              )}
            </td>
            <td className="pc-table__subrows__turnovers text-right">
              {renderPaymentOrder(
                rowData?.report_turnovers?.turnovers,
                CollectedPayments.GUARANTEE,
                '100px',
                rowData?.report_turnovers?.turnovers?.length > 1,
              )}
            </td>
            <td className="pc-table__subrows__turnovers text-right">
              {renderPaymentOrder(
                [
                  rowData?.commission,
                  ...Array.from({
                    length: (rowData?.report_turnovers?.turnovers?.length || 0) - 1,
                  }),
                ],
                CollectedPayments.COMMISSION_FACTORING,
                '130px',
                [
                  rowData?.commission,
                  ...Array.from({
                    length: (rowData?.report_turnovers?.turnovers?.length || 0) - 1,
                  }),
                ].length > 1,
              )}
            </td>
            <td className="pc-table__subrows__turnovers text-right">
              {renderPaymentOrder(
                rowData?.report_turnovers?.turnovers,
                CollectedPayments.PENALTY,
                '110px',
                rowData?.report_turnovers?.turnovers?.length > 1,
              )}
            </td>
            <td className="pc-table__subrows__turnovers text-right">
              {renderPaymentOrder(
                rowData?.report_turnovers?.turnovers,
                CollectedPayments.OUTSTANDING_DAYS,
                '91px',
                rowData?.report_turnovers?.turnovers?.length > 1,
                true,
              )}
            </td>
          </div>
        ),
        props: {
          className: 'p-0',
          colSpan: 7,
        },
      }),
    },
    {
      title: t('operation_type'),
      width: '160px',
      render: () => ({
        props: {
          colSpan: 0,
        },
      }),
    },
    {
      title: t('invoice_value'),
      width: '160px',
      render: () => ({
        props: {
          colSpan: 0,
        },
      }),
    },
    {
      title: t('guarantee'),
      width: '100px',
      render: () => ({
        props: {
          colSpan: 0,
        },
      }),
    },
    {
      title: t('commission_factoring'),
      width: '130px',
      render: () => ({
        props: {
          colSpan: 0,
        },
      }),
    },
    {
      title: t('penalty'),
      width: '110px',
      render: () => ({
        props: {
          colSpan: 0,
        },
      }),
    },
    {
      title: t('outstanding_days'),
      width: '100px',
      render: () => ({
        props: {
          colSpan: 0,
        },
      }),
    },
    ...(actionColumn ? [actionColumn] : []),
  ];
};
