import * as React from 'react';
import cn from 'classnames';
import Step, { StepProps } from './Step';

interface Props {
  className?: string;
}
interface ContentComposition {
  Step: React.FC<StepProps>;
}

const Steps: React.FC<Props> & ContentComposition = ({ className, children }) => {
  return <div className={cn('steps', className)}>{children}</div>;
};

Steps.Step = Step;

export default Steps;
