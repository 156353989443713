import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { Container } from 'ebs-design';
import { Layout } from 'components';

import FormWizard from '../FormWizard';
import GeneralDataForm from '../GeneralDataForm/GeneralDataForm';
import UsersData from '../UsersData/UsersData';

export const CompanyForm: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();

  const steps = React.useMemo(
    () => [
      {
        title: t('general_data'),
        component: <GeneralDataForm />,
      },
      {
        title: t('users_data'),
        component: <UsersData id={id} />,
      },
    ],
    [t, id],
  );

  return (
    <Layout>
      <Container>
        <FormWizard steps={steps} currentStep={0} />
      </Container>
    </Layout>
  );
};
