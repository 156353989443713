import * as React from 'react';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Card,
  Space,
  Icon,
  Upload,
  useForm,
  Loader,
  Label,
  Alert,
  Modal,
  Select,
  useNotify,
} from 'ebs-design';
import { Option } from 'ebs-design/dist/components/molecules/Select/interfaces';
import { useUpload } from 'hooks';
import { UserContext } from 'contexts';
import { getAPIDateFormat } from 'libs';
import { company, invoices, attachments as attachmentsApi } from 'api';
import {
  Layout,
  NomenclatureRates,
  DatePicker,
  SmartSelect,
  ContactsData,
  InputNumericForm,
} from 'components';
import { Edit, Cross, Plus } from 'resources';
import { Properties, InvoiceStatus, AttachmentType, RequestType, CustomerRole } from 'types';
import { arrayContainsArray, defaultFilters, transformResults, getFieldsErrors } from 'utils';

import { useAPI } from '../../hooks';
import { useMutation, useQuery } from 'react-query';

export const InvoiceForm: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const { goBack } = useHistory();
  const { user, userRoles } = React.useContext(UserContext);
  const [form] = useForm();
  const uploadProps = useUpload();
  const notify = useNotify();

  const [selectedCompany, setSelectedCompany] = React.useState<Option | null>(null);
  const [supplier, setSupplier] = React.useState('');
  const [selectedContact, setSelectedContact] = React.useState<string | undefined>();
  const [isExceededLimit, setExceededLimit] = React.useState(false);
  const [isDraft, setIsDraft] = React.useState(false);
  const [newContracts, setNewContracts] = React.useState<Option[]>([]);

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  const [filters, setFilters] = useSetState<Properties>({
    ...defaultFilters,
    id__exclude: user!.company?.id,
    ...(isAdherent && { companies_id: user!.company?.id }),
  });

  const [attachmentsFilters, setAttachmentsFilters] = useSetState<{
    search?: string;
    id: number;
    type: AttachmentType;
    page?: number;
  }>({
    ...defaultFilters,
    type: AttachmentType.CONTRACT,
    id: user!.company?.id,
  });

  const [attachments, setAttachments] = useSetState<Properties>({
    [AttachmentType.INVOICE]: null,
    [AttachmentType.CONTRACT]: null,
    [AttachmentType.XML]: null,
    [AttachmentType.OTHER]: [],
  });
  const [alternative, setAlternative] = useSetState({
    company_name: false,
  });

  const { api, data } = useAPI({
    form,
    selectedCompany,
    setSelectedCompany,
    setAlternative,
    setAttachments,
    setSelectedContact,
    setExceededLimit,
  });
  const {
    getXML,
    requestInvoice,
    postInvoice,
    updateInvoice,
    updateCompany,
    requestCall,
    requestCompany,
  } = api;

  const { data: companies } = useQuery(['companies-list', filters], company.getList);

  const { data: companyAttachments } = useQuery(
    ['attachment', attachmentsFilters],
    invoices.getCompanyAttachments,
  );

  const { mutate: ocrCompanyMutate, isLoading: isOcrCompanyLoading } = useMutation(
    (params) => company.getList({ queryKey: [0, params] }),
    {
      onSuccess: (data) => {
        if (data.results && data.results[0]) {
          setSelectedCompany({ value: data.results[0].id, text: data.results[0].title });
        }
      },
    },
  );

  const { mutate: ocrMutate, isLoading: isOcrLoading } = useMutation(
    ['attachment-ocr'],
    attachmentsApi.getInvoiceParsedData,
    {
      onSuccess: (data) => {
        const fieldData = {
          ...data,
          issuing_date: data.issued_date,
          company: data.buyer,
        };

        form.setFieldsValue(fieldData);
        form.setFields(getFieldsErrors(fieldData, t('field_not_extracted')));

        if (data?.buyer?.idno) {
          ocrCompanyMutate({ search: data?.buyer?.idno } as any);
        }
      },
      onError: () => {
        notify.error({ title: t('failed_information_extracted') });
      },
    },
  );

  const onCreateCompany = React.useCallback(async () => {
    const formData = form.getFieldsValue();

    const data = await updateCompany({ ...formData.company });

    setAlternative({ company_name: false });

    form.setFieldsValue({
      company_id: data.id,
    });

    setSelectedCompany({ value: data.id, text: data.title });
  }, [form, updateCompany, setAlternative]);

  const handleSubmit = React.useCallback(
    async (formData: Properties, isDraft?: boolean) => {
      let companyId = selectedCompany?.value;

      if (id && !isDraft) {
        formData.status = InvoiceStatus.SENT;
      } else if (isDraft) {
        formData.status = InvoiceStatus.DRAFT;
      }

      if (selectedContact) {
        formData.contact_name = selectedContact;
      }

      if (attachments[AttachmentType.CONTRACT]) {
        formData.delivery_contract.attachment = attachments[AttachmentType.CONTRACT];
      }

      if (!formData.company_id && formData.company.company_name && formData.company.idno) {
        const data = await updateCompany({ ...formData.company });

        companyId = data.id;
      }

      delete formData.attachments;
      delete formData.limit;
      delete formData.company;
      delete formData.company_id;

      let attachments_id = Object.keys(attachments) as AttachmentType[];
      if (attachments_id.length) {
        attachments_id = [
          ...attachments_id
            .filter((attachment) => attachment !== AttachmentType.OTHER)
            .map((attachment) => attachments[attachment])
            .filter((attachment) => attachment),
          ...attachments[AttachmentType.OTHER],
        ];
      }

      if (formData.issuing_date) {
        formData.issuing_date = getAPIDateFormat(formData.issuing_date);
      }

      if (formData.delivery_date) {
        formData.delivery_date = getAPIDateFormat(formData.delivery_date);
      }

      if (formData.delivery_contract.date) {
        formData.delivery_contract.date = getAPIDateFormat(formData.delivery_contract.date);
      }

      if (id) {
        updateInvoice.mutate({
          ...formData,
          ...(companyId && { companies_id: [companyId] }),
          ...(attachments_id.length && { attachments_id }),
        });
      } else {
        postInvoice.mutate({
          ...formData,
          ...(companyId && { companies_id: [companyId] }),
          ...(attachments_id.length && { attachments_id }),
        });
      }
    },
    [attachments, id, selectedCompany, selectedContact, updateInvoice, postInvoice, updateCompany],
  );

  const onSubmit = React.useCallback(() => form.submit(), [form]);
  const onCancel = React.useCallback(() => goBack(), [goBack]);

  const onUploadAttachment = React.useCallback(
    (type: AttachmentType, files) => {
      setAttachments((i) => ({
        [type]:
          type === AttachmentType.OTHER
            ? [...i[AttachmentType.OTHER], ...files.map((i) => i.id)]
            : files[0].id,
      }));

      if (type === AttachmentType.XML) {
        getXML.mutate(files[0].id);
      }
    },
    [setAttachments, getXML],
  );

  const onValuesChange = React.useCallback(
    (value) => {
      if (
        value.attachments &&
        AttachmentType.XML in value.attachments &&
        value.attachments[AttachmentType.XML] === null
      ) {
        setSupplier('');

        form.resetFields();
      }

      if (value.attachments && value.attachments[AttachmentType.CONTRACT]) {
        setAttachments((prevState) => ({
          ...prevState,
          [AttachmentType.CONTRACT]: value.attachments[AttachmentType.CONTRACT],
        }));
      }
    },
    [form],
  );

  const onDraftSave = React.useCallback(() => {
    setIsDraft(true);

    handleSubmit(form.getFieldsValue(), true);

    setIsDraft(false);
  }, [form, handleSubmit]);

  const onOCRUpload = async (fileData) => {
    ocrMutate(fileData[0].id);

    form.setFieldsValue({ attachments_id: fileData[0].id });

    onUploadAttachment(AttachmentType.INVOICE, fileData);
  };

  const companyAttachmentsOptions = React.useMemo(
    () => (companyAttachments && transformResults(companyAttachments?.results)) || [],
    [companyAttachments],
  );

  const selectedCompanyAttachment = React.useMemo(() => {
    const foundAttachment = companyAttachments?.results.find(
      (i) => i.id === attachments[AttachmentType.CONTRACT],
    );

    if (foundAttachment) {
      form.setFieldsValue({ delivery_contract: foundAttachment.delivery_contract });

      return [{ value: foundAttachment.id, text: foundAttachment.name }];
    }

    return undefined;
  }, [companyAttachmentsOptions, attachments]);

  const onCustomClick = (type) => {
    const value = !alternative[type];

    setAlternative({ [type]: value });

    form.setFieldsValue({ [type]: value ? t('other') : undefined });
  };

  const onClickAlternative = () => {
    form.setFieldsValue({
      company_id: undefined,
      company: {
        company_name: data.company?.title,
        ...(!data.company && {
          idno: undefined,
          iban: undefined,
          code: undefined,
        }),
      },
    });

    onCustomClick('company_name');
    setSelectedCompany(null);
  };

  return (
    <Layout>
      <Container>
        <Space className="mt-5 mb-20">
          <h4>{t('enter_invoice_details')}</h4>
        </Space>
        <NomenclatureRates />
        <Card className="mt-20 overflow-visible">
          <Card.Body className="p-0 m-1">
            <Loader
              loading={
                requestInvoice.isLoading ||
                postInvoice.isLoading ||
                updateInvoice.isLoading ||
                data.isLoadingInvoiceForm
              }
            >
              <Form
                type="horizontal"
                form={form}
                draft={isDraft}
                onFinish={handleSubmit}
                onValuesChange={onValuesChange}
                initialValues={{
                  factoring_type: {
                    term: 30,
                  },
                }}
              >
                <Row g={0}>
                  <Col size={6} className="py-20 px-30 form-col--border-right">
                    <h3 className="form-heading">{t('invoice_data')}</h3>

                    <Form.Field name="attachments_ocr" label={<>{t('invoice')}</>}>
                      <Upload
                        data={{
                          type: AttachmentType.INVOICE,
                        }}
                        onSuccess={onOCRUpload}
                        {...uploadProps}
                      >
                        <Space>
                          <Button>{t('upload')}</Button>
                          {isOcrLoading || isOcrCompanyLoading ? (
                            <Space size={5}>
                              <Loader.Spinner size="small" /> {t('invoice_scanning')}
                            </Space>
                          ) : (
                            <Label
                              text={
                                <>
                                  <span className="ebs-form__field__required">* </span>
                                  {t('automatic_extraction_information').toLowerCase()}
                                </>
                              }
                              disabled
                            />
                          )}
                        </Space>
                      </Upload>
                    </Form.Field>

                    <Form.Field label=" ">
                      <Label text={t('or')} disabled />
                    </Form.Field>

                    <div className="form-divider" />

                    <Form.Field
                      name="series"
                      rules={[{ required: true }]}
                      label={t('invoice_series')}
                    >
                      <Input disabled={isOcrLoading || isOcrCompanyLoading} />
                    </Form.Field>

                    <Form.Field
                      name="number"
                      label={t('invoice_number')}
                      rules={[{ required: true }]}
                    >
                      <Input disabled={isOcrLoading || isOcrCompanyLoading} />
                    </Form.Field>

                    <Form.Field name="issuing_date" label={t('issue_date')}>
                      <DatePicker disabled={isOcrLoading || isOcrCompanyLoading} />
                    </Form.Field>

                    <Form.Field name="delivery_date" label={t('delivery_date')}>
                      <DatePicker disabled={isOcrLoading || isOcrCompanyLoading} />
                    </Form.Field>

                    <InputNumericForm
                      name="amount"
                      label={t('sum_of_invoice')}
                      rules={[{ required: true }]}
                      inputProps={{ disabled: isOcrLoading || isOcrCompanyLoading }}
                    />

                    <Form.Field
                      name="attachments_id"
                      label={
                        <>
                          {t('invoice')}
                          <span className="ebs-form__field__required">*</span>
                        </>
                      }
                    >
                      <Upload
                        data={{
                          type: AttachmentType.INVOICE,
                        }}
                        onSuccess={(fileData) => {
                          onUploadAttachment(AttachmentType.INVOICE, fileData);
                        }}
                        disabled={isOcrLoading || isOcrCompanyLoading}
                        {...uploadProps}
                      >
                        <Button>{t('upload')}</Button>
                      </Upload>
                    </Form.Field>

                    <Form.Field label=" ">
                      <Label text={t('or')} disabled />
                    </Form.Field>

                    <Form.Field
                      name={['attachments', AttachmentType.XML]}
                      label={
                        <>
                          {t('xml')}
                          <span className="ebs-form__field__required">*</span>
                        </>
                      }
                    >
                      <Upload
                        data={{
                          type: AttachmentType.XML,
                        }}
                        onSuccess={(fileData) => onUploadAttachment(AttachmentType.XML, fileData)}
                        disabled={isOcrLoading || isOcrCompanyLoading}
                        {...uploadProps}
                      >
                        <Button>{t('upload')}</Button>
                      </Upload>
                    </Form.Field>
                    <Form.Field
                      name={['attachments', AttachmentType.CONTRACT]}
                      rules={[{ required: true }]}
                      label={t('contract')}
                    >
                      {(control) => (
                        <Select
                          key={[...newContracts, ...companyAttachmentsOptions].length}
                          {...control}
                          selected={selectedCompanyAttachment}
                          suffix={
                            <Upload
                              data={{
                                type: AttachmentType.CONTRACT,
                              }}
                              onSuccess={(fileData) => {
                                onUploadAttachment(AttachmentType.CONTRACT, fileData);
                                control.onChange(fileData[0]?.id);
                                setNewContracts((options) => [
                                  ...options,
                                  { text: fileData[0]?.name, value: fileData[0]?.id },
                                ]);
                              }}
                              className="h-100"
                              {...uploadProps}
                            >
                              <Button>
                                <Icon component={alternative.company_name ? Cross : Plus} />
                              </Button>
                            </Upload>
                          }
                          options={[...newContracts, ...companyAttachmentsOptions]}
                        >
                          <Select.Search
                            placeholder={t('searchByIDNO')}
                            value={attachmentsFilters.search}
                            onSearch={(val) =>
                              setAttachmentsFilters({
                                search: val,
                                page: 1,
                              })
                            }
                          />
                          <Select.Pagination
                            count={data?.count || 0}
                            limit={defaultFilters.limit}
                            page={filters.page}
                            setPage={(page) => setFilters({ page })}
                            mode="scroll"
                          />
                        </Select>
                      )}
                    </Form.Field>

                    <Form.Field
                      name={['delivery_contract', 'number']}
                      label={t('contract_number')}
                      rules={[{ required: true }]}
                    >
                      <Input disabled={isOcrLoading || isOcrCompanyLoading} />
                    </Form.Field>

                    <Form.Field
                      name={['delivery_contract', 'date']}
                      label={t('contract_date')}
                      rules={[{ required: true }]}
                    >
                      <DatePicker disabled={isOcrLoading || isOcrCompanyLoading} />
                    </Form.Field>
                  </Col>
                  <Col size={6} className="py-20 px-30">
                    <h3 className="form-heading">{t('other_documents')}</h3>

                    <Form.Field name={['attachments', AttachmentType.OTHER]} label={t('files')}>
                      <Upload
                        multiple
                        onSuccess={(fileData) => onUploadAttachment(AttachmentType.OTHER, fileData)}
                        {...uploadProps}
                      >
                        <Button>{t('upload')}</Button>
                      </Upload>
                    </Form.Field>

                    <div className="form-divider" />

                    <h3 className="form-heading">{t('factoring_type_term')}</h3>

                    <Form.Field
                      name="factoring_type_id"
                      rules={[{ required: true }]}
                      label={t('type')}
                    >
                      <SmartSelect options={data.factoringTypes} />
                    </Form.Field>

                    <Form.Field name="term_number" rules={[{ required: true }]} label={t('term')}>
                      <SmartSelect options={data.factoringRange} />
                    </Form.Field>
                  </Col>
                  <Col size={12} className="overflow-hidden">
                    <div className="form-divider mt-0 mb-0" />
                  </Col>
                  <Col size={12}>
                    <Row>
                      <Col size={6} className="py-20 px-30 form-col--border-right responsive-col">
                        <h3 className="form-heading">{t('debtor_data')}</h3>
                        {supplier.length ? (
                          <Row className="mb-20">
                            <Col size={4}>
                              <Space justify="end">{t('company')}</Space>
                            </Col>
                            <Col size={8}>
                              <Input value={supplier} disabled />
                            </Col>
                          </Row>
                        ) : (
                          <Form.Field
                            name="company_id"
                            label={t('company')}
                            rules={[{ required: !alternative.company_name }]}
                          >
                            <Select
                              onSelect={(value) => value.length && setSelectedCompany(value[0])}
                              selected={selectedCompany ? [selectedCompany] : undefined}
                              suffix={
                                <Button onClick={onClickAlternative}>
                                  <Icon component={alternative.company_name ? Cross : Plus} />
                                </Button>
                              }
                              disabled={Boolean(alternative.company_name)}
                              options={(companies && transformResults(companies?.results)) || []}
                            >
                              <Select.Search
                                placeholder={t('searchByIDNO')}
                                value={filters.search}
                                onSearch={(val) =>
                                  setFilters({
                                    search: val,
                                    page: 1,
                                    ...(isAdherent && {
                                      companies_id: val?.length ? undefined : user!.company?.id,
                                    }),
                                  })
                                }
                              />
                              <Select.Pagination
                                count={data?.count || 0}
                                limit={defaultFilters.limit}
                                page={filters.page}
                                setPage={(page) => setFilters({ page })}
                                mode="scroll"
                              />
                            </Select>
                          </Form.Field>
                        )}

                        {alternative.company_name && (
                          <Form.Field
                            name={['company', 'company_name']}
                            label={t('name')}
                            rules={[{ required: alternative.company_name }]}
                          >
                            <Input />
                          </Form.Field>
                        )}

                        {data.company && data.company.limit.current ? (
                          <Form.Field name="limit" label=" " style={{ pointerEvents: 'none' }}>
                            <Input
                              hasError
                              placeholder={`${t('debtor_limit')} - ${
                                data.company.limit.accessible
                              } lei`}
                            />
                          </Form.Field>
                        ) : null}

                        <Form.Field
                          name={['company', 'idno']}
                          label={t('idno')}
                          rules={[{ required: true }]}
                        >
                          <Input
                            disabled={
                              !alternative.company_name || isOcrLoading || isOcrCompanyLoading
                            }
                          />
                        </Form.Field>

                        <Form.Field name={['company', 'iban']} label={t('iban')}>
                          <Input disabled={!alternative.company_name} />
                        </Form.Field>

                        <Form.Field name={['company', 'code']} label={t('cod_tva')}>
                          <Input
                            disabled={
                              !alternative.company_name || isOcrLoading || isOcrCompanyLoading
                            }
                          />
                        </Form.Field>
                      </Col>
                      <Col size={6} className="py-20 px-30 responsive-col">
                        <h3 className="form-heading">{t('contact_person')}</h3>
                        <Space direction="vertical" align="start" className="responsive-col__space">
                          {data.company ? (
                            <ContactsData
                              id={data.company.id}
                              data={data.company.contacts}
                              selected={selectedContact}
                              onSelect={setSelectedContact}
                            />
                          ) : (
                            <Alert
                              type="warning"
                              message={t('debtor_must_be_selected_or_created')}
                            />
                          )}
                          {alternative.company_name && (
                            <Button type="fill" onClick={onCreateCompany}>
                              {t('create')}
                            </Button>
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Space justify="space-between">
              <Button className="text-nowrap" onClick={onCancel}>
                {t('cancel')}
              </Button>
              <Space>
                <Button
                  type="dark"
                  className="text-nowrap"
                  prefix={<Icon component={Edit} />}
                  onClick={onDraftSave}
                >
                  {t('save_draft')}
                </Button>
                <Button
                  type="primary"
                  submit
                  className="text-nowrap"
                  prefix={<Icon type="check" model="bold" />}
                  onClick={onSubmit}
                >
                  {t('send_to_factoring')}
                </Button>
              </Space>
            </Space>
          </Card.Footer>
        </Card>
      </Container>
      <Modal
        open={isExceededLimit}
        className="pc-confirmation-modal"
        onClose={() => setExceededLimit(false)}
      >
        <Modal.Content>
          <Space direction="vertical">
            <Icon type="warning" />
            <h2>
              {t('invoice')}{' '}
              {`${form.getFieldValue('series') || ''}${form.getFieldValue('number') || ''}`}
            </h2>
            {t('invoice_exceeds_limit')}
          </Space>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button type="ghost" onClick={() => setExceededLimit(false)}>
              {t('cancel')}
            </Button>

            <Space>
              <Button
                type="ghost"
                prefix={<Icon type="check" model="bold" />}
                loading={requestCompany.isLoading}
                onClick={() => requestCompany.mutate({ type: RequestType.INCREASE_LIMIT })}
              >
                {t('request_increase_in_limit')}
              </Button>
              <Button
                type="primary"
                prefix={<Icon type="check" model="bold" />}
                loading={requestCall.isLoading}
                onClick={() =>
                  requestCall.mutate({
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    phone: user?.phone,
                    message: t('request_increase_in_limit'),
                  })
                }
              >
                {t('request_call_from_credit_officer')}
              </Button>
            </Space>
          </Space>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};
