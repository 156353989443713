import React, { FC } from 'react';
import { useIntl } from 'estafette-intl';
import { useMutation, useQueryClient } from 'react-query';
import { Modal, Button, Icon, useForm, Space, useNotify } from 'ebs-design';
import { nomenclature } from 'api';
import { Category } from 'types';
import { CategoryForm, CategoryFormValues } from './CategoryForm';
import { extractResponseProps } from 'utils';

export interface CategoryModalProps {
  category: Category | null;
  onClose(): void;
}

export const CategoryModal: FC<CategoryModalProps> = ({ category, onClose }) => {
  const { t } = useIntl();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const [categoryForm] = useForm<CategoryFormValues>();

  const createCategory = useMutation(nomenclature.createCategory, {
    onSuccess() {
      queryClient.invalidateQueries('categories');
      onClose();
      notify.success({ title: t('categories'), description: t('success_data_save') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const updateCategory = useMutation(nomenclature.updateCategory, {
    onSuccess() {
      queryClient.invalidateQueries('categories');
      onClose();
      notify.success({ title: t('categories'), description: t('success_data_change') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const title = t(category ? 'edit_category' : 'add_category');

  return (
    <Modal open={true} size="small" onClose={onClose} title={title}>
      <Modal.Content>
        <CategoryForm
          category={category}
          form={categoryForm}
          createCategory={createCategory}
          updateCategory={updateCategory}
        />
      </Modal.Content>
      <Modal.Footer>
        <Space justify="end">
          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={categoryForm.submit}
            loading={createCategory.isLoading || updateCategory.isLoading}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
