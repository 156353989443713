import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  AvatarInline,
  Card,
  Collapse,
  Icon,
  ListGroup,
  Space,
  firstLetters,
  Loader,
} from 'ebs-design';
import { company } from 'api';
import { Mail, Phone } from 'resources';
import { Layout } from 'components';
import { usePermissions, useCompanyUsers } from 'hooks';
import { SystemRole, Permissions } from 'types';

import { DebtorLayout } from '../DebtorLayout';
import { DebtorProfile } from '../DebtorProfile';
import { AddConclusionModal } from '../AddConclusionModal';

export const Debtor: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const can = usePermissions(Permissions.DEBTORS);
  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  const { data: companyUsers } = useCompanyUsers({
    id,
    beneficiary: true,
    limit: 50,
  });

  const conclusions = React.useMemo(
    () => ({
      [SystemRole.SY_CREDIT_OFFICER]:
        data && data.request
          ? data.request.conclusions.filter((conclusion) =>
              conclusion.user.roles.some((role) => role.name === SystemRole.SY_CREDIT_OFFICER),
            )
          : [],
      [SystemRole.SY_RISK_MANAGER]:
        data && data.request
          ? data.request.conclusions.filter((conclusion) =>
              conclusion.user.roles.some((role) => role.name === SystemRole.SY_RISK_MANAGER),
            )
          : [],
    }),
    [data],
  );

  return (
    <Layout>
      <DebtorLayout>
        <Loader loading={isLoading}>
          {data && <DebtorProfile data={data} />}

          {can.read.conclusions && (
            <Collapse className="company-details__collapse">
              <Collapse.Header>
                <Space justify="space-between">
                  <h4 className="card-title">{`${t('conclusion')} ${t(
                    SystemRole.SY_RISK_MANAGER,
                  )}`}</h4>

                  {can.create?.conclusion &&
                    can.create.conclusion[SystemRole.SY_RISK_MANAGER] &&
                    data?.request && <AddConclusionModal id={data.request.id} />}
                </Space>
              </Collapse.Header>
              <Collapse.Body className="company-details__collapse__conclusions">
                <ListGroup>
                  {conclusions[SystemRole.SY_RISK_MANAGER].length ? (
                    conclusions[SystemRole.SY_RISK_MANAGER].map((conclusion, i) => {
                      const title = [conclusion.user.first_name, conclusion.user.last_name].join(
                        ' ',
                      );

                      return (
                        <ListGroup.Item key={i}>
                          <Space justify="space-between">
                            {conclusion.comment}
                            <AvatarInline
                              circle
                              alt={title}
                              shortAlt={
                                (title && firstLetters(title)) ||
                                conclusion.user.email[0].toUpperCase()
                              }
                              description={conclusion.user.email}
                            />
                          </Space>
                        </ListGroup.Item>
                      );
                    })
                  ) : (
                    <ListGroup.Item>
                      <Space justify="center">{t('no_data')}</Space>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Collapse.Body>
            </Collapse>
          )}

          {can.read.conclusions && (
            <Collapse className="company-details__collapse">
              <Collapse.Header>
                <Space justify="space-between">
                  <h4 className="card-title">{`${t('conclusion')} ${t(
                    SystemRole.SY_CREDIT_OFFICER,
                  )}`}</h4>

                  {can.create?.conclusion &&
                    can.create.conclusion[SystemRole.SY_CREDIT_OFFICER] &&
                    data?.request && <AddConclusionModal id={data.request.id} />}
                </Space>
              </Collapse.Header>
              <Collapse.Body className="company-details__collapse__conclusions">
                <ListGroup>
                  {conclusions[SystemRole.SY_CREDIT_OFFICER].length ? (
                    conclusions[SystemRole.SY_CREDIT_OFFICER].map((conclusion, i) => {
                      const title = [conclusion.user.first_name, conclusion.user.last_name].join(
                        ' ',
                      );

                      return (
                        <ListGroup.Item key={i}>
                          <Space justify="space-between">
                            {conclusion.comment}
                            <AvatarInline
                              circle
                              alt={title}
                              shortAlt={
                                (title && firstLetters(title)) ||
                                conclusion.user.email[0].toUpperCase()
                              }
                              description={conclusion.user.email}
                            />
                          </Space>
                        </ListGroup.Item>
                      );
                    })
                  ) : (
                    <ListGroup.Item>
                      <Space justify="center">{t('no_data')}</Space>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Collapse.Body>
            </Collapse>
          )}

          <Collapse className="company-details__collapse background">
            <Collapse.Header>
              <h4 className="card-title">{t('contact_data')}</h4>
            </Collapse.Header>
            <Collapse.Body>
              <div className="company-details__contact-cards">
                {data?.contacts.length ? (
                  data?.contacts.map((item, key) => (
                    <Card key={key} className="company-details__contact-card">
                      <AvatarInline
                        circle
                        className="company-details__contact-avatar"
                        alt={`${item.first_name} ${item.last_name}`}
                        description={item.roles
                          ?.map((role) => t(role.name.toLowerCase()))
                          .join(',')}
                      />
                      <ul className="company-details__contact-list">
                        <li>
                          <Icon component={Mail} />
                          {item.email}
                        </li>
                        <li>
                          <Icon component={Phone} />
                          {item.phone}
                        </li>
                      </ul>
                    </Card>
                  ))
                ) : (
                  <Space justify="center">{t('no_data')}</Space>
                )}
              </div>
            </Collapse.Body>
          </Collapse>

          <Collapse className="company-details__collapse background">
            <Collapse.Header>
              <h4 className="card-title">{t('actual_beneficiaries')}</h4>
            </Collapse.Header>
            <Collapse.Body>
              <div className="company-details__contact-cards">
                {companyUsers?.results.length ? (
                  companyUsers.results.map((item, key) => (
                    <Card key={key} className="company-details__contact-card">
                      <AvatarInline
                        circle
                        className="company-details__contact-avatar"
                        alt={`${item.first_name} ${item.last_name}`}
                        description={item.roles?.map((role) => t(role.toLowerCase())).join(',')}
                      />
                      <ul className="company-details__contact-list">
                        <li>
                          <Icon component={Mail} />
                          {item.email}
                        </li>
                        <li>
                          <Icon component={Phone} />
                          {item.phone}
                        </li>
                      </ul>
                    </Card>
                  ))
                ) : (
                  <Space justify="center">{t('no_data')}</Space>
                )}
              </div>
            </Collapse.Body>
          </Collapse>

          <Collapse className="company-details__collapse">
            <Collapse.Header>
              <h4 className="card-title">{t('bank_details')}</h4>
            </Collapse.Header>
            <Collapse.Body>
              <div className="company-details__bank">
                <div className="company-details__bank__cell">
                  <div className="company-details__bank__label">{t('bank')}</div>
                  <div className="company-details__bank__value">
                    {data?.additional_data?.bank?.title || '---'}
                  </div>
                </div>
                <div className="company-details__bank__cell">
                  <div className="company-details__bank__label">{t('iban')}</div>
                  <div className="company-details__bank__value">{data?.iban || '---'}</div>
                </div>
              </div>
            </Collapse.Body>
          </Collapse>
        </Loader>
      </DebtorLayout>
    </Layout>
  );
};
