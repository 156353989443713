import { axios, cancelTokenHandler, stringifyUrl } from 'libs';
import {
  Results,
  Invoice,
  Properties,
  PaymentOrder,
  PaymentOrderUpdate,
  ContractAttachment,
  DeliveryContract,
  InvoicesTurnover,
  TurnoverTotal,
} from 'types';
import { transformResponse, formatNumber } from 'utils';

export const invoices = {
  getList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Invoice>>(
      stringifyUrl(`/invoices/list/`, queryKey[1]),
      {
        transformResponse: (response) =>
          transformResponse(response, {
            properties: [
              'payed',
              'sold',
              'amount',
              'statistics.penalties.interest',
              'statistics.crontab.interest',
              'balance',
              'minimum',
              'guarantee.returns.amount',
              'commission.amount',
              'guarantee.amount',
            ],
            wrapper: (value) => formatNumber(value),
          }),
        cancelToken: cancelToken(invoices.getList.name),
      },
    );

    return data;
  },

  get: async (id: string, transform = true) => {
    const { data } = await axios.get<Invoice>(`/invoices/${id}/`, {
      transformResponse: (response) =>
        transformResponse(
          response,
          transform
            ? {
                properties: [
                  'amount',
                  'balance',
                  'collected',
                  'commission.amount',
                  'commission.percent',
                  'minimum',
                  'payed',
                  'warrant',
                  'rate.commission',
                  'rate.guarantee',
                  'penalty.daily.currency.amount',
                  'penalty.daily.percent',
                  'penalty.percent',
                  'penalty.rate.percent',
                ],
                wrapper: (value) => formatNumber(value),
              }
            : undefined,
        ),
      cancelToken: cancelToken(invoices.get.name),
    });

    return data;
  },

  create: async (bodyData: Properties) => {
    const { data } = await axios.post<Invoice>(`/invoices/`, bodyData, {
      cancelToken: cancelToken(invoices.create.name),
    });

    return data;
  },

  delete: async (id: number) => {
    return await axios.delete(`/invoices/${id}/`, {
      cancelToken: cancelToken(invoices.delete.name),
    });
  },

  patchInvoice: async (id: string, bodyData: Properties) => {
    return await axios.patch(`/invoices/${id}/`, bodyData, {
      cancelToken: cancelToken(invoices.patchInvoice.name),
    });
  },

  patchInvoicePartial: async (id: string, bodyData: Properties) => {
    return await axios.patch(`/invoices/${id}/partial/`, bodyData, {
      cancelToken: cancelToken(invoices.patchInvoicePartial.name),
    });
  },

  patchPenalties: async (id: number, bodyData: Properties) => {
    return await axios.patch(`/invoices/${id}/penalties/`, bodyData, {
      cancelToken: cancelToken(invoices.patchPenalties.name),
    });
  },

  getNotificationsList: async (id: number) => {
    const { data } = await axios.get<Results<Invoice>>(
      `/invoices/${id}/notifications/list/?limit=50`,
      {
        cancelToken: cancelToken(invoices.getNotificationsList.name),
      },
    );

    return data;
  },

  getPaymentsOrdersList: async (id: number, query?: Properties) => {
    const { data } = await axios.get<Results<PaymentOrder>>(
      stringifyUrl(`/invoices/${id}/payments-orders/list/`, query),
      {
        cancelToken: cancelToken(invoices.getPaymentsOrdersList.name),
      },
    );

    return data;
  },

  notify: async (id, entity): Promise<Invoice> => {
    const { data } = await axios.post(`/invoices/${id}/notifications/${entity}/`, null, {
      cancelToken: cancelToken(invoices.notify.name),
    });

    return data;
  },

  actions: async (id: string, bodyData: Properties) => {
    return await axios.patch(`/invoices/${id}/actions/`, bodyData, {
      cancelToken: cancelToken(invoices.actions.name),
    });
  },

  patchPaymentsOrders: async (id: number, bodyData: PaymentOrderUpdate) => {
    return await axios.patch<PaymentOrderUpdate>(`/invoices/${id}/payments-orders/`, bodyData, {
      cancelToken: cancelToken(invoices.patchPaymentsOrders.name),
    });
  },

  getCompanyAttachments: async ({ queryKey }) => {
    const { id, ...params } = queryKey[1];

    return await axios
      .get<Results<ContractAttachment>>(
        stringifyUrl(`/invoices/${id}/attachments/list/all/`, params),
        {
          cancelToken: cancelToken(invoices.getCompanyAttachments.name),
        },
      )
      .then(({ data }) => data);
  },

  getExportFile: async ({ queryKey: [, params] }: Properties) => {
    const { data } = await axios.get<Properties>(stringifyUrl(`/invoices/export/`, params), {
      cancelToken: cancelToken(invoices.getExportFile.name),
    });

    return data;
  },

  getDeliveryContracts: async (params: Properties = {}) => {
    const { data } = await axios.get<Results<DeliveryContract>>('/invoices/delivery/contracts/', {
      params,
      cancelToken: cancelToken(invoices.getDeliveryContracts.name),
    });

    return data;
  },

  getDeliveryContractById: async ({ queryKey }: Properties) => {
    const { id } = queryKey[1];
    const { data } = await axios.get<DeliveryContract>(`/invoices/delivery/contracts/${id}/`, {
      cancelToken: cancelToken(invoices.getDeliveryContractById.name),
    });

    return data;
  },

  updateDeliveryContract: async (id: number, body: Properties) => {
    const { data } = await axios.patch(`/invoices/delivery/contracts/${id}/`, body, {
      cancelToken: cancelToken(invoices.updateDeliveryContract.name),
    });
    return data;
  },

  getReportList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<InvoicesTurnover>>(
      stringifyUrl(`/invoices/turnovers/report/`, queryKey[1]),
      {
        cancelToken: cancelToken(invoices.getReportList.name),
      },
    );

    return data;
  },

  getCalculatedReportList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<TurnoverTotal>(
      stringifyUrl(`/invoices/turnovers/calculate/`, queryKey[1]),
      {
        cancelToken: cancelToken(invoices.getCalculatedReportList.name),
      },
    );

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(invoices);
