import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Space, Button, Icon, useNotify, Animated } from 'ebs-design';
import { requests } from 'api';
import { UserContext } from 'contexts';
import { usePermissions } from 'hooks';
import { FullCompany, Permissions, RequestStatus, CreateVote, SystemRole } from 'types';
import { Check, Cross, UpdateArrow } from 'resources';
import { extractResponseProps } from 'utils';

import { EditLimitModal, CommentModal } from '../molecules';

interface Props {
  permissions: Permissions;
  data: FullCompany;
}

export const ActionsCompany: React.FC<Props> = ({ data, permissions }) => {
  const { t } = useIntl();
  const can = usePermissions(permissions);
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [lastState, setLastState] = React.useState<RequestStatus | undefined>();

  const { user: userData, userRoles } = React.useContext(UserContext);

  const { data: dataRequest, isLoading: isLoadingVotes } = useQuery(
    ['company-request'],
    () => requests.get(data!.request.id.toString(), true, false),
    {
      enabled: !!data?.request,
    },
  );

  const { mutate, isLoading } = useMutation(
    (params: CreateVote) => requests.patch({ id: data.request?.id, ...params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('company-request');
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const voteData = React.useMemo(
    () =>
      data?.request &&
      dataRequest &&
      userData &&
      dataRequest?.votes?.find(({ user }) => user.id === userData.id),
    [dataRequest, userData, data],
  );

  const disabledVote = React.useMemo(
    () =>
      !dataRequest ||
      (dataRequest &&
        (!userRoles.includes(dataRequest.stage) || dataRequest?.status !== RequestStatus.REVIEW)),
    [userRoles, dataRequest],
  );

  const vote = useMutation(
    (variables: CreateVote) =>
      voteData
        ? requests.updateVote(data.request?.id, voteData.id, variables)
        : requests.createVote(data.request?.id, variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('company-request');
        queryClient.invalidateQueries('company');
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const isCreditCommittee = React.useMemo(
    () => data.request && data.request?.stage === SystemRole.SY_CREDIT_COMMITTEE,
    [data],
  );

  // Approve request
  const handleApprove = React.useCallback(
    (comment: string, limit: string) => {
      setLastState(RequestStatus.APPROVED);
      vote.mutate({ comment: comment || undefined, limit, status: RequestStatus.APPROVED });
    },
    [vote],
  );

  // Decline request
  const handleDecline = React.useCallback(
    (comment: string, limit: string) => {
      setLastState(RequestStatus.DENIED);

      if (isCreditCommittee) {
        vote.mutate({ comment, limit, status: RequestStatus.DENIED });
      } else {
        mutate({
          status: RequestStatus.DENIED,
        });
      }
    },
    [isCreditCommittee, vote, mutate],
  );

  // Approve request
  const handleReexamination = React.useCallback(
    (comment: string, limit: string) => {
      setLastState(RequestStatus.REEXAMINATION);

      vote.mutate({
        comment,
        limit,
        stage: SystemRole.SY_CREDIT_OFFICER,
        status: RequestStatus.REEXAMINATION,
      });
    },
    [vote],
  );

  const SendToApprove = React.useMemo(
    () =>
      can.perform?.sendToApprove && !disabledVote ? (
        <EditLimitModal proposed_limit={dataRequest?.proposed_limit} {...data}>
          <Button prefix={<Icon component={Check} />}>{t('send_to_approve')}</Button>
        </EditLimitModal>
      ) : null,
    [t, can, disabledVote, dataRequest, data],
  );

  const Reexamination = React.useMemo(
    () =>
      can.perform?.reexamination && !disabledVote ? (
        <CommentModal
          proposed_limit={dataRequest?.proposed_limit}
          onConfirm={handleReexamination}
          disabledLimit
        >
          <Button
            type="primary"
            prefix={<Icon component={UpdateArrow} />}
            loading={vote.isLoading && lastState === RequestStatus.REEXAMINATION}
          >
            {t('re_examination')}
          </Button>
        </CommentModal>
      ) : null,
    [t, can, lastState, disabledVote, dataRequest, vote.isLoading, handleReexamination],
  );

  const Approve = React.useMemo(
    () =>
      can.perform?.approve && !disabledVote ? (
        <CommentModal
          proposed_limit={voteData?.limit.toString() || dataRequest?.proposed_limit}
          onConfirm={handleApprove}
        >
          <Button
            type="primary"
            prefix={<Icon component={Check} />}
            loading={vote.isLoading && lastState === RequestStatus.APPROVED}
          >
            {voteData ? t('revote') : t('approve')}
          </Button>
        </CommentModal>
      ) : null,
    [t, can, disabledVote, dataRequest, vote.isLoading, lastState, handleApprove, voteData],
  );

  const Decline = React.useMemo(
    () =>
      can.perform?.decline &&
      !disabledVote &&
      [RequestStatus.REVIEW, RequestStatus.REEXAMINATION, RequestStatus.INFO_REQUIRED].includes(
        data?.request?.status,
      ) ? (
        <CommentModal
          proposed_limit={dataRequest?.proposed_limit}
          onConfirm={handleDecline}
          disabledLimit
        >
          <Button
            type="dark"
            prefix={<Icon component={Cross} />}
            loading={(vote.isLoading || isLoading) && lastState === RequestStatus.DENIED}
          >
            {t('decline')}
          </Button>
        </CommentModal>
      ) : null,
    [t, can, disabledVote, lastState, dataRequest, data, vote.isLoading, isLoading, handleDecline],
  );

  const Actions = React.useMemo(() => {
    switch (dataRequest?.stage) {
      case SystemRole.SY_RISK_MANAGER:
        return (
          <Space>
            {SendToApprove}
            {Decline}
          </Space>
        );

      case SystemRole.SY_CREDIT_OFFICER:
      case SystemRole.SY_CREDIT_OFFICER_SUPERIOR:
        return (
          <Space>
            {SendToApprove}
            {Decline}
          </Space>
        );

      case SystemRole.SY_CREDIT_COMMITTEE:
        return (
          <Space>
            {Reexamination}
            {Approve}
            {Decline}
          </Space>
        );

      default:
        return null;
    }
  }, [dataRequest, SendToApprove, Reexamination, Approve, Decline]);

  return (
    <Animated loading={isLoadingVotes || !data?.request}>
      <Space justify="space-between">{data?.request && Actions}</Space>
    </Animated>
  );
};
