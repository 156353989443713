import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { Actions, Card, Loader, SortBy, Space, Table, useNotify } from 'ebs-design';
import {
  Layout,
  Pagination,
  ConfirmationModal,
  MultipleCheckBoxSelect,
  SmartSelect,
} from 'components';
import { useInvoiceColumns } from 'features/invoices/useInvoiceColumns';
import { defaultFilters, getSortOptions, extractResponseProps } from 'utils';
import { useQueryParams, useQueryUpdate, usePermissions, useColumns } from 'hooks';
import { company, invoices } from 'api';
import { UserContext } from 'contexts';
import { Results, Invoice, Column, Permissions, InvoiceStatus, SystemRole } from 'types';

import { DebtorLayout } from '../DebtorLayout';
import { DebtorProfile } from '../DebtorProfile';

export const Invoices: React.FC = () => {
  const params = useQueryParams();
  const { t } = useIntl();
  const { id } = useParams();
  const { push } = useHistory();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { userRoles } = React.useContext(UserContext);
  const can = usePermissions(Permissions.INVOICES);
  const { updateQuery } = useQueryUpdate();
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });
  const [confirmDelete, setConfirmDelete] = React.useState<Invoice | undefined>();

  React.useEffect(() => updateQuery({ ...filters }), [filters, updateQuery]);

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  const isCollector = React.useMemo(() => userRoles.includes(SystemRole.SY_COLLECTOR), [userRoles]);

  const { data: dataInvoices, isLoading: isLoadingInvoices } = useQuery(
    [
      'debtor_invoices',
      {
        ...filters,
        companies_id__in: id,
        ...(isCollector && { status: InvoiceStatus.REMAINED.toLowerCase() }),
      },
    ],
    invoices.getList,
  );

  const deleteInvoice = useMutation(invoices.delete, {
    onMutate: async (id: number) => {
      const query = ['debtor_invoices', id, { ...filters }];
      const prevData = queryClient.getQueryData(query) as Results<Invoice>;

      queryClient.setQueryData(query, {
        ...prevData,
        results: prevData.results.filter((i) => i.id !== id),
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('invoices');
      notify.success({ title: t('invoices'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const defaultColumns = useInvoiceColumns();

  const columns: Column<Invoice>[] = React.useMemo(
    () => [
      ...defaultColumns,
      {
        title: null,
        action: true,
        render: (props) => (
          <Actions>
            <Actions.Item onClick={() => push(`/invoices/${props.id}`)}>
              {t('details')}
            </Actions.Item>
            {can.edit?.invoice && (
              <Actions.Item onClick={() => push(`/invoices/${props.id}/edit`)}>
                {t('edit')}
              </Actions.Item>
            )}
            {can.delete?.invoice && (
              <Actions.Item onClick={() => setConfirmDelete(props)}>{t('delete')}</Actions.Item>
            )}
          </Actions>
        ),
      },
    ],
    [t, push, can, defaultColumns],
  );

  const {
    onChange: onColumnsChange,
    getOptions,
    columns: selectedColumns,
  } = useColumns('invoices', columns);

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  const onAbortDelete = () => setConfirmDelete(undefined);

  const onConfirmDelete = React.useCallback(() => {
    deleteInvoice.mutate(confirmDelete!.id);

    onAbortDelete();
  }, [deleteInvoice, confirmDelete]);

  const confirmDeleteTitle = React.useMemo(
    () => `${t('invoice')} ${confirmDelete?.number}`,
    [t, confirmDelete],
  );

  return (
    <Layout>
      <DebtorLayout>
        <Loader loading={isLoading}>{data && <DebtorProfile data={data} />}</Loader>
        <Card className="overflow-visible">
          <Card.Header>
            <Space justify="space-between">
              <Space>
                <h4 className="card-title">{`${t('invoices')} (${dataInvoices?.count || 0})`}</h4>

                <SmartSelect
                  options={Object.values(InvoiceStatus).map((item) => {
                    const status = item.toLowerCase();

                    return { value: status, text: t(status) };
                  })}
                  value={filters.status}
                  onChange={(status) => setFilters(() => ({ status, page: 1 }))}
                  className="select-min-width"
                />
              </Space>
              <Space>
                <MultipleCheckBoxSelect
                  options={getOptions(columns)}
                  placeHolder={t('choose_column')}
                  label="title"
                  callback={onColumnsChange}
                />

                <SortBy
                  options={sortOptions}
                  value={filters?.ordering}
                  onChange={(ordering) => setFilters({ ordering })}
                />
              </Space>
            </Space>
          </Card.Header>
          <Card.Body className="p-0">
            {isLoadingInvoices ? (
              <Loader loading={isLoadingInvoices} />
            ) : (
              <Table
                className="table-no-border"
                columns={selectedColumns}
                data={dataInvoices?.results}
                emptyCell="---"
              />
            )}
          </Card.Body>
          <Card.Footer>
            <Pagination data={dataInvoices} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </DebtorLayout>
      {confirmDelete && (
        <ConfirmationModal
          title={confirmDeleteTitle}
          description={t('are_you_sure_to_want_delete')}
          onConfirm={onConfirmDelete}
          onCancel={onAbortDelete}
          visible={true}
        />
      )}
    </Layout>
  );
};
