import * as React from 'react';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import cn from 'classnames';
import { Container, Row, Col, Label, Button, Input, Icon } from 'ebs-design';
import { company } from 'api';
import { useQueryParams, usePermissions } from 'hooks';
import { Properties, CompanyType, Permissions } from 'types';
import { DatePicker, SmartSelect } from 'components';

interface SearchBarProps {
  className?: string;
  style?: React.CSSProperties;
  onSearch: (value: Properties) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ className, style, onSearch }) => {
  const { t } = useIntl();
  const params = useQueryParams();
  const can = usePermissions(Permissions.INVOICES);
  const [search, setSearch] = useSetState<Properties>({});

  React.useEffect(() => {
    Object.keys(params).forEach((i) => {
      if (!['limit', 'page', 'ordering'].includes(i)) {
        const isPeriod = i.match(/(date)/);
        const isRange = i === 'timestamp__range';

        setSearch((props) => ({
          ...props,
          ...(isPeriod && { dataType: i }),
          [isPeriod || isRange ? 'period' : i]:
            isPeriod || isRange ? params[i].split('__') : params[i],
          ...(i === 'company_id' && { company_id: parseInt(params[i]) }),
          ...(i === 'companies_id' && { companies_id: parseInt(params[i]) }),
        }));
      }
    });
  }, [params, setSearch]);

  const dataTypeOptions = React.useMemo(
    () => [
      { value: 'assignment_date', text: t('assignment_date') },
      { value: 'issuing_date', text: t('issue_date') },
      { value: 'payment_date', text: t('payment_date') },
    ],
    [t],
  );

  const onSearchHandler = React.useCallback(() => onSearch(search), [search, onSearch]);

  return (
    <div className={cn('pc-searchbar', className)} style={style}>
      <Container>
        <Row gy={3}>
          <Col size={12} lg={can.perform.searchByAdherent ? 2 : 3}>
            <Label text={t('direct_search')} />
            <Input
              placeholder={t('search')}
              suffix={<Icon type="search" />}
              value={search.search}
              onChange={(search) => setSearch({ search })}
              isClearable
            />
          </Col>
          {can.perform.searchByAdherent && (
            <Col size={6} sm={2} lg={2}>
              <Label text={t('adherent')} />
              <SmartSelect
                search
                api={company.getList}
                filters={{ types: CompanyType.ADHERENT.toLowerCase() }}
                value={search.company_id}
                transform={(results) =>
                  results.map(({ id, title }) => ({
                    value: id,
                    text: title || '---',
                  }))
                }
                onChange={(company_id) => setSearch({ company_id })}
              />
            </Col>
          )}
          <Col size={6} sm={2} lg={2}>
            <Label text={t('debtor')} />
            <SmartSelect
              search
              api={company.getList}
              filters={{ types: CompanyType.DEBTOR.toLowerCase() }}
              value={search.companies_id}
              transform={(results) =>
                results.map(({ id, title }) => ({
                  value: id,
                  text: title || '---',
                }))
              }
              onChange={(companies_id) => setSearch({ companies_id })}
            />
          </Col>
          <Col size={can.perform.searchByAdherent ? 3 : 6} sm={2} lg={2}>
            <Label text={t('date_type')} />
            <SmartSelect
              options={dataTypeOptions}
              value={search.dataType}
              onChange={(dataType) => setSearch({ dataType })}
            />
          </Col>
          <Col
            size={can.perform.searchByAdherent ? 9 : 12}
            sm={6}
            lg={can.perform.searchByAdherent ? 3 : 4}
          >
            <Label text="Period" />
            <DatePicker
              type="period"
              value={search.period}
              onChange={(period) => setSearch({ period })}
            />
          </Col>
          <Col
            size={12}
            sm={can.perform.searchByAdherent ? 12 : 2}
            lg={1}
            className="align-flex-end"
          >
            <Button type="dark" className="full-width" onClick={onSearchHandler}>
              {t('search')}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
