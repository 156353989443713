import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Space, Loader, Row, Col, DatePicker, Label } from 'ebs-design';
import { useReports } from 'hooks';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfMonthDateFormat,
  formatDateFilters,
} from 'libs';
import { ReportType, ReportInvoicesRatios } from 'types';
import { InvoicesRatiosChart } from './charts';
import { InvoicesRatiosTable } from './tables';
import { RangePickerStateProps } from './utils';

export const InvoicesRatiosReport: React.FC = () => {
  const { t } = useIntl();
  const [range, setRange] = React.useState<RangePickerStateProps>([null, null]);

  const { data, isLoading } = useReports<ReportInvoicesRatios[], ReportInvoicesRatios[]>(
    ReportType.INVOICES_RATIOS,
    {
      ...(range?.[0] && { date_from: range?.[0] && formatDateFilters(range?.[0]) }),
      ...(range?.[1] && { date_to: range?.[1] && formatDateFilters(range?.[1]) }),
    },
    { keepPreviousData: true },
  );

  React.useEffect(() => {
    setRange([firstDayOfMonthDateFormat, currentDayDateFormat]);
  }, []);

  return (
    <>
      <Space wrap className="mb-15" align="start" direction="vertical">
        <h4>{t('type_invoices_of_regression')}</h4>

        <Space>
          <Label text="Period" />

          <DatePicker.RangeInput
            value={range}
            onChange={(value) => setRange(value as any)}
            isClearable
            placeholderText={t('choose_period')}
            dateFormat={dateFormat}
          />
        </Space>
      </Space>
      <Loader loading={isLoading}>
        <Row>
          <Col size={5}>
            <InvoicesRatiosTable data={data} />
          </Col>
          <Col size={7}>
            <InvoicesRatiosChart data={data} />
          </Col>
        </Row>
      </Loader>
    </>
  );
};
