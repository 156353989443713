export const menu = {
  home: {
    en: `Home`,
    ro: `Acasă`,
    ru: `Домой`,
  },
  dashboard: {
    en: `Dashboard`,
    ro: `Panou de control`,
    ru: `Панель управления`,
  },
  adherents: {
    en: `Adherents`,
    ro: `Aderenti`,
    ru: `Последователи`,
  },
  requests: {
    en: `Requests`,
    ro: `Solicitari`,
    ru: `Заявки`,
  },
  debtors: {
    en: `Debtors`,
    ro: `Debitori`,
    ru: `Должники`,
  },
  invoices: {
    en: `Invoices`,
    ro: `Facturi`,
    ru: `Счета`,
  },
  users: {
    en: `Users`,
    ro: `Utilizatori`,
    ru: `Пользователи`,
  },
  notifications: {
    en: `Notifications`,
    ro: `Notificări`,
    ru: `Уведомления`,
  },
};
