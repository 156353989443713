import * as React from 'react';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  InputPhone,
  Row,
  Col,
  Checkbox,
  useForm,
  Space,
  useNotify,
  Loader,
} from 'ebs-design';
import { users } from 'api';
import { Tokens, UserSignUp } from 'types';
import { extractResponseProps, getRoute, validateResponse } from 'utils';

import AuthenticationModal from '../AuthenticationModal';
import RequestCallModal from '../RequestCallModal';
import { stringifyUrl } from 'libs';
import { routes } from 'routes';

export const SignUpPage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const [form] = useForm();
  const [agree, setAgree] = React.useState(false);
  const notify = useNotify();

  const { mutate, isLoading } = useMutation<Tokens, unknown, UserSignUp>(
    (data) => users.register(data),
    {
      onSuccess: () =>
        push(getRoute(routes, 'ActivationPage', { query: { email: form.getFieldValue('email') } })),
      onError: (err, values) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        ),
          validateResponse(form, err, values);
      },
    },
  );

  const handleSubmit = (data: UserSignUp): void => mutate(data);

  return (
    <AuthenticationModal
      title={t('create_the_account')}
      description={t('please_enter_personal')}
      footer={
        <>
          {t('already_registered')}{' '}
          <Link to={stringifyUrl(getRoute(routes, 'SignInPage'))}>{t('access_your_account')}</Link>
        </>
      }
    >
      <Form
        form={form}
        type="horizontal"
        onFinish={handleSubmit}
        controlOptions={{ col: { size: 9 } }}
        labelOptions={{ col: { size: 3 } }}
        autoComplete="off"
      >
        <Form.Field
          name="first_name"
          label={t('first_name')}
          className="pc-fix-field"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Field>

        <Form.Field
          name="last_name"
          label={t('last_name')}
          className="pc-fix-field"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Field>

        <Form.Field
          name="email"
          label={t('email')}
          className="pc-fix-field"
          rules={[{ required: true }]}
        >
          <Input type="email" />
        </Form.Field>

        <Form.Field
          name="idnp"
          label={t('idnp')}
          className="pc-fix-field"
          rules={[{ required: true, len: 13 }]}
        >
          <Input type="number" />
        </Form.Field>

        <Form.Field
          name="phone"
          label={t('phone_number')}
          className="pc-fix-field"
          rules={[{ required: true }]}
        >
          <InputPhone country="md" />
        </Form.Field>

        <div
          className="form-divider mt-0"
          style={{ position: 'absolute', width: '100%', left: 30 }}
        />

        <Form.Field
          name="password"
          label={t('password')}
          className="pc-fix-field mt-25 pt-5"
          rules={[{ required: true }]}
        >
          <Input styleType="grey" type="password" />
        </Form.Field>

        <Form.Field
          className="pc-fix-field no-wrap"
          name="confirm_password"
          label={t('confirm_password')}
          rules={[{ required: true }]}
        >
          <Input styleType="grey" type="password" />
        </Form.Field>

        <div
          className="form-divider mt-0"
          style={{ position: 'absolute', width: '100%', left: 30 }}
        />

        <Row className="mt-15">
          <Col size={3}></Col>

          <Col size={9}>
            <div className="mt-25 mb-15">
              <Checkbox
                text={t('i_am_agree')}
                checked={agree}
                onChange={() => setAgree((i) => !i)}
              />
            </div>
            <div className="authentication-modal-form-actions">
              <Space justify="space-between">
                <Button
                  type="primary"
                  submit
                  buttonClass="uppercase"
                  disabled={!agree || isLoading}
                >
                  <Space>
                    {isLoading && <Loader.Spinner size="small" />}

                    {t('create_account')}
                  </Space>
                </Button>

                <RequestCallModal>
                  <Button type="ghost" buttonClass="uppercase">
                    {t('request_call')}
                  </Button>
                </RequestCallModal>
              </Space>
            </div>
          </Col>
        </Row>
      </Form>
    </AuthenticationModal>
  );
};
