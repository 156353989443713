import * as React from 'react';
import { Column } from 'types';
import { prepareColumns } from 'utils';

interface Props<T> {
  columns: Column<T>[];
  onChange: (selected: string[]) => void;
  getOptions: (columns: Column<T>[]) => Column<T>[];
}

export const useColumns = <T = object>(name: string, tableColumns: Column<T>[]): Props<T> => {
  const cache = localStorage.getItem(`columns-${name}`);

  const [checked, setChecked] = React.useState<string[]>((cache && JSON.parse(cache)) || []);

  const onChange = React.useCallback(
    (selected: string[]) => {
      localStorage.setItem(`columns-${name}`, JSON.stringify(selected));
      setChecked(selected);
    },
    [name],
  );

  const columns = React.useMemo(
    () =>
      tableColumns.filter((item, i) =>
        item.checked !== undefined
          ? checked.length
            ? checked.includes(i.toString())
            : item.checked
          : true,
      ),
    [tableColumns, checked],
  );

  const getOptions = React.useCallback(
    (columns: Column<T>[]) => prepareColumns<T>(name, columns),
    [name],
  );

  return {
    columns,
    onChange,
    getOptions,
  };
};
