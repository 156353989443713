import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Loader } from 'ebs-design';
import { Layout, NomenclatureRates, IndividualRates } from 'components/organisms';
import { company } from 'api';
import { UserContext } from 'contexts';

import { Description } from '../Description/';
import { RequestStatus, SystemRole } from 'types';

export const LimitsAndRates: React.FC = () => {
  const { user } = useContext(UserContext);
  const companyId = user?.company?.id;

  const { data: dataCompany, isLoading: isLoadingCompany } = useQuery(
    ['company', companyId],
    () => company.get(companyId!),
    {
      enabled: !!companyId,
    },
  );

  const showIndividualRates = useMemo(
    () =>
      !!companyId &&
      dataCompany?.request?.status === RequestStatus.APPROVED &&
      dataCompany?.request?.stage === SystemRole.SY_CREDIT_COMMITTEE,
    [dataCompany, companyId],
  );

  return (
    <Layout>
      <Description />
      <NomenclatureRates />
      <Loader loading={isLoadingCompany}>{showIndividualRates && <IndividualRates />}</Loader>
    </Layout>
  );
};
