import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { Button, Card, Icon, ListGroup, Select, Space, Upload } from 'ebs-design';
import { attachments, company, invoices } from 'api';
import { usePermissions, useUpload } from 'hooks';
import { downloadFile } from 'utils';
import {
  Attachment,
  AttachmentType,
  CustomerRole,
  Invoice,
  Permissions,
  Properties,
  SystemRole,
} from 'types';
import { Document, Download, Plus } from 'resources';
import { UserContext } from 'contexts';

interface Props {
  data?: Invoice;
  onUpdate: () => void;
}

export const SignDocuments: React.FC<Props> = ({ data, onUpdate }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const can = usePermissions(Permissions.INVOICES);
  const uploadProps = useUpload();
  const { user, userRoles } = React.useContext(UserContext);
  const [toSign, setToSign] = React.useState<number | undefined>();
  const [signingType, setSigningType] = React.useState(AttachmentType.ANNEX as string);

  const { data: dataCompany } = useQuery(
    ['company', user?.company?.id],
    () => company.get(user!.company!.id),
    {
      enabled: !!user?.company?.id,
    },
  );

  const updateInvoice = useMutation(
    (data: Properties) => invoices.patchInvoice(id as string, data),
    {
      onSuccess: onUpdate,
    },
  );

  const docs = React.useMemo(
    () =>
      data?.attachments
        ? data.attachments.filter((doc) =>
            [AttachmentType.ASSIGNMENT_OF_RECEIVABLES, AttachmentType.ANNEX].includes(doc.type),
          )
        : [],
    [data],
  );

  const { mutate, isLoading: isLoadingSign } = useMutation(
    ({ id, redirect_url }: Properties) => attachments.getSign(id, redirect_url),
    {
      onSuccess: (response) => {
        window.open(response.redirect_url, '_self');
      },
    },
  );

  const onSignClick = React.useCallback(
    (id) => {
      setToSign(id);
      mutate({ id: id, redirect_url: window.location.href });
    },
    [mutate],
  );

  const onUploadAttachment = React.useCallback(
    (file) => {
      if (data) {
        updateInvoice.mutate({
          attachments_id: [...data.attachments.map((attachment) => attachment.id), file[0].id],
        });
      }
    },
    [data, updateInvoice],
  );

  const documentSigned = (document: Attachment): boolean => {
    const currentUserRoleCoordinates = userRoles.find((i) => i in CustomerRole)
      ? CustomerRole
      : userRoles.find((i) => i in SystemRole)
      ? SystemRole
      : undefined;
    if (!currentUserRoleCoordinates) {
      return true;
    }
    return Boolean(
      document.signatures?.find((i) =>
        i.user?.roles?.find((ii) => ii.name in currentUserRoleCoordinates),
      ),
    );
  };

  const hasSignedContract = React.useMemo(() => {
    return Boolean(
      dataCompany?.contract?.attachment?.signatures?.find((i) =>
        Boolean(i.user.roles.find((i) => i.name in CustomerRole)),
      ),
    );
  }, [dataCompany]);

  const hasPFSignContract = React.useMemo(() => {
    return Boolean(
      dataCompany?.contract?.attachment?.signatures?.find((i) =>
        Boolean(i.user.roles.find((i) => i.name in SystemRole)),
      ),
    );
  }, [dataCompany]);

  return (
    <Card>
      <Card.Header bordered>
        <Space justify="space-between">
          <h4>{t('documents_signing')}</h4>

          <Space>
            {can.create?.signingDocuments && hasSignedContract && hasPFSignContract && (
              <>
                <Upload
                  data={{ type: signingType }}
                  onSuccess={(fileData) => onUploadAttachment(fileData)}
                  {...uploadProps}
                >
                  <Button type="fill" prefix={<Icon component={Plus} />}>
                    {t('upload')}
                  </Button>
                </Upload>
                <Select
                  className="select-min-width"
                  value={signingType}
                  options={[
                    { value: AttachmentType.ANNEX, text: t('annex') },
                    {
                      value: AttachmentType.ASSIGNMENT_OF_RECEIVABLES,
                      text: t('assignment_of_receivables'),
                    },
                  ]}
                  onChange={(value) => setSigningType(value as string)}
                />
              </>
            )}
          </Space>
        </Space>
      </Card.Header>
      <Card.Body className="p-0 bg-white">
        {docs.length ? (
          <ListGroup className="no-border">
            {docs.map((document) => (
              <ListGroup.Item key={document.id}>
                <Space justify="space-between">
                  <Space>
                    <Icon component={Document} />
                    {document.name}
                  </Space>

                  <Space>
                    <Button
                      prefix={<Icon component={Download} />}
                      onClick={() => downloadFile(document.url, document.name)}
                    >
                      {t('download')}
                    </Button>
                    {can.perform?.signingDocuments && (
                      <Button
                        type="primary"
                        prefix={<Icon type="check" model="bold" />}
                        onClick={() => onSignClick(document.id)}
                        loading={toSign === document.id && isLoadingSign}
                        disabled={toSign === document.id || documentSigned(document)}
                      >
                        {t(documentSigned(document) ? 'signed' : 'sign_electronically')}
                      </Button>
                    )}
                  </Space>
                </Space>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <div className="p-10 text-center">{t('no_data')}</div>
        )}
      </Card.Body>
    </Card>
  );
};
