import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Space, Icon } from 'ebs-design';
import { ModalSize } from 'ebs-design/dist/components/organisms/Modal/Modal';

interface ConfirmationModalProps {
  title?: string;
  description?: React.ReactNode;
  icon?: string | React.ReactNode;
  visible?: boolean;
  size?: ModalSize;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  description,
  size = 'small',
  icon = 'warning',
  visible: isVisible = false,
  onConfirm,
  onCancel,
  children,
}) => {
  const { t } = useIntl();
  const [visible, setVisible] = React.useState(isVisible);

  const onToggleHandler = () => setVisible((i) => !i);

  const onCancelHandler = React.useCallback(() => {
    if (onCancel) {
      onCancel();
    }

    onToggleHandler();
  }, [onCancel]);

  const onConfirmHandler = React.useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }

    onToggleHandler();
  }, [onConfirm]);

  const isIconType = typeof icon === 'string';

  return (
    <>
      <Modal open={visible} size={size} className="pc-confirmation-modal" onClose={onCancelHandler}>
        <Modal.Content>
          <Space size="small" direction="vertical">
            <Icon
              type={isIconType ? (icon as string) : undefined}
              component={!isIconType ? icon : undefined}
            />
            <h2>{title}</h2>
            {description}
          </Space>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onCancelHandler}>{t('cancel')}</Button>

            <Button
              type="primary"
              prefix={<Icon type="check" model="bold" />}
              onClick={onConfirmHandler}
            >
              {t('confirm')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
      <div onClick={onToggleHandler}>{children}</div>
    </>
  );
};
