import { Attachment, Company, Invoice, User } from 'types';

export enum NotificationType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  SYSTEM = 'SYSTEM',
}

export enum NotificationTypeLabel {
  SMS = 'warning',
  EMAIL = 'info',
  PHONE = 'success',
  SYSTEM = 'danger',
}

export interface Notification {
  id: number;
  companies_id: number[];
  companies: Company[];
  attachments_id: number[];
  attachments: Attachment[];
  invoice: Pick<Invoice, 'id' | 'number' | 'series' | 'code'>;
  invoice_id: number;
  user: User;
  user_id: number;
  target: User;
  target_id: number;
  comment: string;
  timestamp: string;
  edited_timestamp: string;
  title: string;
  metadata: {
    title: string;
    description: string;
  };
  code_name: string;
  read: boolean;
  type: NotificationType;
  data: unknown;
}

export type NotificationCreate = Pick<
  Notification,
  'companies_id' | 'invoice_id' | 'comment' | 'attachments_id'
>;
