import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, Loader, Space, Table, useNotify } from 'ebs-design';
import { nomenclature } from 'api';
import { FactoringType } from 'types';
import { RatesLayout } from 'features/profile/RatesLayout';
import { extractResponseProps, formatCategoryRates, getCategoryRatesByType, getRoute } from 'utils';
import { formatDate } from 'libs';
import { routes } from 'routes';

export const RatesFuture: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const notify = useNotify();

  const { data, isLoading } = useQuery(
    ['nomenclatures', 'future-rates'],
    () => nomenclature.getCategoriesFutureRatesList(),
    {
      select: (data) => ({
        regression: formatCategoryRates(getCategoryRatesByType(data)),
        noRegression: formatCategoryRates(
          getCategoryRatesByType(data, FactoringType.NO_REGRESSION),
        ),
      }),
    },
  );

  const { mutate, isLoading: isLoadingMutate } = useMutation(
    () => nomenclature.setCategoriesHistoryRatesList(),
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        notify.success({ title: t('rates'), description: t('success_data_save') });
        push(getRoute(routes, 'Rates'));
      },
    },
  );

  const childrenColumns = [
    {
      title: t('category'),
      render: ({ category_name, rates_length }) => ({
        children: category_name,
        props: { rowSpan: rates_length },
      }),
    },
    {
      title: t('days'),
      render: ({ value }) => (value >= 0 ? `${value} ${t('days')}` : '-'),
    },
    {
      title: t('commission'),
      dataIndex: 'percent',
      render: (percent) => `${percent}%`,
    },
    {
      title: t('guarantee'),
      dataIndex: 'guarantee',
      render: (guarantee) => `${guarantee}%`,
    },
    {
      title: `${t('commission')} + ${t('guarantee')}`,
      dataIndex: 'gc_percent',
      render: (gc_percent) => `${gc_percent}%`,
    },
  ];

  const columnsRegression = React.useMemo(
    () => [
      {
        title: t('with_right_refund'),
        children: childrenColumns,
      },
    ],
    [t, isLoading],
  );

  const columnsNoRegression = React.useMemo(
    () => [
      {
        title: t('without_right_refund'),
        children: childrenColumns,
      },
    ],
    [t, isLoading],
  );

  return (
    <RatesLayout>
      <Space align="center" justify="space-between" className="mt-20 mb-4" wrap>
        <h4>{t('future_rates')}</h4>
        <Button
          type="primary"
          onClick={() => mutate()}
          disabled={(!data?.noRegression?.length && !data?.regression?.length) || isLoadingMutate}
          loading={isLoadingMutate}
        >
          {t('apply_rates_now')}
        </Button>
      </Space>

      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <>
          <>
            {data?.regression?.[0]?.timestamp && (
              <p className="mb-12">{`${t('date')}: ${formatDate(
                data?.regression?.[0]?.timestamp,
              )}`}</p>
            )}
            <Card>
              <Table
                className="table-no-border pc-rates__table"
                columns={columnsRegression}
                data={data?.regression}
              />
            </Card>
          </>

          <>
            {data?.noRegression?.[0]?.timestamp && (
              <p className="mt-12">
                {`${t('date')}: ${formatDate(data?.noRegression?.[0]?.timestamp)}`}
              </p>
            )}
            <Card className="mt-12">
              <Table
                className="table-no-border pc-rates__table"
                columns={columnsNoRegression}
                data={data?.noRegression}
              />
            </Card>
          </>
        </>
      )}
    </RatesLayout>
  );
};
