import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Space, Button } from 'ebs-design';
import { Properties } from 'types';

export const Pagination: React.FC<Properties> = ({ data, filters, setFilters, className }) => {
  const { t } = useIntl();

  const state = React.useMemo(
    () =>
      data?.count ? `${filters.page} of ${Math.ceil(data.count / parseInt(filters.limit))}` : null,
    [data, filters],
  );

  const disabled = React.useMemo(
    () => ({
      previous: !data?.previous,
      next: !data?.next,
    }),
    [data],
  );

  const onClickPrevious = React.useCallback(
    () => setFilters((current) => ({ page: parseInt(current.page) - 1 })),
    [setFilters],
  );
  const onClickNext = React.useCallback(
    () => setFilters((current) => ({ page: parseInt(current.page) + 1 })),
    [setFilters],
  );

  return (
    <Space align="center" justify="space-between" className={className}>
      <span className="no-wrap">{state}</span>
      <Space>
        <Button size="small" disabled={disabled.previous} type="ghost" onClick={onClickPrevious}>
          {t('previous')}
        </Button>

        <Button size="small" disabled={disabled.next} type="ghost" onClick={onClickNext}>
          {t('next')}
        </Button>
      </Space>
    </Space>
  );
};
