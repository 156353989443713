import React, { FC, useMemo, useCallback } from 'react';
import { useIntl } from 'estafette-intl';
import { useQueries, UseMutationResult } from 'react-query';
import { Form, FormInstance, Input } from 'ebs-design';
import { nomenclature, users } from 'api';
import { SystemRole, Committee, Properties } from 'types';
import { useSetState } from 'react-use';
import { defaultFilters } from 'utils';
import { getInitialValues } from './utils';
import { SmartSelect, InputNumericForm } from 'components';

export interface CommitteeFormValues {
  title: string;
  factoring_range: {
    maximum: string;
    minimum: string;
    currency_id: number;
  };
  users_id: number[];
  votes_required: string;
}

export interface CommitteeFormProps {
  committee: Committee | null;
  form: FormInstance;
  createCommittee: UseMutationResult<Committee, any, Properties, any>;
  updateCommittee: UseMutationResult<Committee, any, [number, Properties], any>;
}

export const CommitteeForm: FC<CommitteeFormProps> = ({
  form,
  committee,
  createCommittee,
  updateCommittee,
}) => {
  const { t } = useIntl();
  const [filters] = useSetState({
    ...defaultFilters,
    roles__in: [SystemRole.SY_CREDIT_COMMITTEE],
  });

  const [members, currencies]: any[] = useQueries([
    {
      queryKey: ['users', filters],
      queryFn: users.getList,
    },
    {
      queryKey: ['currencies'],
      queryFn: () => nomenclature.getCurrencies(),
    },
  ]);

  const currencyOptions = useMemo(() => {
    if (!currencies.data?.results) {
      return [];
    }

    return currencies.data?.results.map((currency) => ({
      value: currency.id,
      text: currency.code_name,
    }));
  }, [currencies.data]);

  const membersOptions = useMemo(() => {
    if (!members.data?.results) {
      return [];
    }

    return members.data?.results.map(({ id, first_name, last_name, email }) => ({
      value: id,
      text: !first_name && !last_name ? email : `${last_name} ${first_name}`,
    }));
  }, [members.data]);

  const initialValues = useMemo(() => getInitialValues(committee), [committee]);

  const handleSubmit = useCallback(
    (values: CommitteeFormValues) => {
      if (committee) {
        updateCommittee.mutate([committee.id, values]);
        return;
      }
      createCommittee.mutate(values);
    },
    [committee, updateCommittee, createCommittee],
  );

  return (
    <Form
      form={form}
      type="horizontal"
      onFinish={handleSubmit}
      initialValues={initialValues}
      labelOptions={{ col: { size: 4 } }}
      controlOptions={{ col: { size: 8 } }}
      fieldRow={{ gy: 3 }}
      className="pc-committee-form"
    >
      <Form.Field name="title" label={t('committee_name')} rules={[{ required: true }]}>
        <Input />
      </Form.Field>
      <Form.Field
        name="votes_required"
        label={t('minimum_nr_of_votes')}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Field>
      <Form.Group
        label={t('approval_limit')}
        labelOptions={{ className: 'ebs-form__field__label--custom-height align-items--center' }}
        required
      >
        <InputNumericForm
          name={['factoring_range', 'minimum']}
          label={t('minimum')}
          rules={[{ required: true }]}
          controlOptions={{ col: { size: 8 } }}
        />

        <InputNumericForm
          name={['factoring_range', 'maximum']}
          label={t('maximum')}
          rules={[{ required: true }]}
          controlOptions={{ col: { size: 8 } }}
        />

        <Form.Field
          name={['factoring_range', 'currency_id']}
          label={t('currency')}
          rules={[{ required: true }]}
          controlOptions={{ col: { size: 8 } }}
        >
          <SmartSelect options={currencyOptions} />
        </Form.Field>
      </Form.Group>
      {/* Todo: replace below with async smart select */}
      <Form.Field
        name="users_id"
        label={t('members')}
        rules={[
          (form) => ({
            type: 'array',
            required: true,
            min: +form.getFieldValue('votes_required'),
          }),
        ]}
      >
        <SmartSelect mode="multiple" options={membersOptions} />
      </Form.Field>
    </Form>
  );
};
