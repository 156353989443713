interface Options {
  padding?: boolean;
  symbols?: string[];
}
const defaultOptions = {
  padding: true,
  symbols: ['', 'K', 'M', 'B', 'T', 'Q', 'Qi', 'Sx'],
};

export function abbreviateNumber(
  num: number,
  digit = 1,
  options?: Options | Options['symbols'],
): string {
  num = parseFloat(num.toString().replace(/\s+/g, ''));

  // Previous options style
  if (Array.isArray(options)) {
    options = { symbols: options };
  }

  const { symbols, padding }: Required<Options> = Object.assign({}, defaultOptions, options);

  // handle negatives
  const sign = Math.sign(num) >= 0;
  num = Math.abs(num);

  // what tier? (determines SI symbol)
  const tier = (Math.log10(num) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return num.toString();

  // get suffix and determine scale
  const suffix = symbols[tier];
  if (!suffix) throw new RangeError();

  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = num / scale;

  let rounded = scaled.toFixed(digit);
  if (!padding) {
    rounded = String(Number(rounded));
  }

  // format number and add suffix
  return (!sign ? '-' : '') + rounded + suffix;
}

export const isNumber = (value): boolean => Number.isInteger(value as number);

export const formatNumber = (
  value?: number | string | null,
  currency = '',
  fixedValue = 2,
): any => {
  // Check if the value it's not number or string
  if ((!value && typeof value !== 'number') || typeof value === 'object') {
    return value;
  }

  // Format value
  const formattedValue = parseFloat(value.toString())
    .toFixed(fixedValue)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

  // Return value with currency
  if (currency) {
    return `${formattedValue} ${currency}`;
  }

  return formattedValue;
};
