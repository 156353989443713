import * as React from 'react';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Select } from 'ebs-design';
import type {
  OptionValue,
  SelectMode,
} from 'ebs-design/dist/components/molecules/Select/interfaces';
import type { SelectProps } from 'ebs-design/dist/components/molecules/Select/components/Component';
import { Results, Properties, SelectOption } from 'types';
import { defaultFilters, generateString, transformResults } from 'utils';

interface Props extends SelectProps {
  queryKey?: string;
  className?: string;
  placeholder?: string;
  newPlaceholder?: string;
  searchPlaceholder?: string;
  rootRef?: React.MutableRefObject<HTMLDivElement | null>;
  api?: ({ queryKey }: Properties) => Promise<Results<Properties>>;
  filters?: Properties;
  options?: SelectOption[];
  value?: OptionValue | OptionValue[];
  selected?: SelectOption | SelectOption[];
  search?: boolean;
  searchKey?: string;
  enabled?: boolean;
  mode?: SelectMode;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  disabled?: boolean;
  isClearable?: boolean;
  loading?: boolean;
  transform?: (results: Properties[]) => Properties[];
  onChange?: (value: OptionValue | OptionValue[]) => void;
  onSelect?: (value: SelectOption[]) => void;
  onAddNew?: (value: string) => void;
}

export const SmartSelect: React.FC<Props> = ({
  queryKey,
  placeholder: placeholderText,
  newPlaceholder,
  searchPlaceholder,
  rootRef,
  className,
  api,
  value,
  selected,
  options: optionsList = [],
  filters: baseFilters = {},
  enabled = true,
  search = false,
  searchKey = 'search',
  mode,
  suffix,
  prefix,
  disabled,
  transform = transformResults,
  isClearable = true,
  loading,
  onChange,
  onSelect,
  onAddNew,
  ...props
}) => {
  const key = React.useMemo(() => queryKey || generateString(), [queryKey]);

  const { t, locale } = useIntl();
  const [filters, setFilters] = useSetState<Properties>({ ...defaultFilters });

  const { data, isLoading, isFetching } = useQuery(
    [key, filters, locale],
    api || ((): any => new Promise(() => null)),
    {
      enabled: !!api && enabled,
    },
  );

  React.useEffect(() => {
    if (Object.keys(baseFilters).some((key) => baseFilters[key] !== filters[key])) {
      setFilters({ ...baseFilters, page: 1 });
    }
  }, [filters, baseFilters, setFilters]);

  const placeholder = React.useMemo(() => placeholderText || t('select'), [t, placeholderText]);

  const options = React.useMemo(
    () => (!api ? optionsList : (((data && transform(data?.results)) || []) as SelectOption[])),
    [data, optionsList, api, transform],
  );

  return (
    <Select
      className={className}
      placeholder={placeholder}
      newPlaceholder={newPlaceholder}
      loading={api ? loading || isLoading || isFetching : loading || false}
      value={value}
      selected={selected}
      mode={mode}
      rootRef={rootRef}
      prefix={prefix}
      suffix={suffix}
      disabled={disabled}
      onChange={onChange}
      onSelect={onSelect}
      onAddNew={onAddNew}
      options={options}
      onSearch={(val) =>
        setFilters({
          ...((val.length || (filters[searchKey]?.length && !val.length)) && { [searchKey]: val }),
          page: 1,
        })
      }
      isClearable={isClearable}
      {...props}
    >
      {!!api && search && (
        <Select.Search
          placeholder={searchPlaceholder}
          value={filters.search}
          onSearch={(val) =>
            setFilters({
              ...((val.length || (filters[searchKey]?.length && !val.length)) && {
                [searchKey]: val,
              }),
              page: 1,
            })
          }
        />
      )}

      {!!api && (
        <Select.Pagination
          count={data?.count || 0}
          limit={defaultFilters.limit}
          page={filters.page}
          setPage={(page) => setFilters({ page })}
          mode="scroll"
        />
      )}
    </Select>
  );
};
