import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { UserContext } from 'contexts';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  DatePicker,
  Form,
  Icon,
  Input,
  Label,
  Row,
  Space,
  Upload,
  useForm,
  useNotify,
} from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { Properties, ContractUpload } from 'types';
import { dateTimePickerFormat, getAPIDateFormat } from 'libs';
import { useUpload } from 'hooks';
import { company, contracts } from 'api';
import { extractResponseProps } from 'utils';
import { FileUpload } from 'resources';
import { Layout, ContractSign } from 'components/organisms';

export const ContractSignUpload = () => {
  const uploadProps = useUpload();
  const queryClient = useQueryClient();
  const { user } = React.useContext(UserContext);

  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();

  const [uploadFileId, setUploadFileId] = React.useState<number | null>(null);
  const hasCompany = React.useMemo(() => !!user?.company, [user]);

  const { data, isLoading, refetch } = useQuery(
    ['contract', user?.company?.id],
    () =>
      user?.company?.id ? company.getContract(user?.company?.id, { generate_number: true }) : null,
    { enabled: hasCompany },
  );

  const { mutate: postContractMutate, isLoading: isLoadingPostContract } = useMutation(
    (data: ContractUpload) => contracts.postContract(data),
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },

      onSuccess: () => {
        queryClient.invalidateQueries(['company', user?.company?.id]);
        queryClient.invalidateQueries(['company-contract', user?.company?.id]);
        notify.success({ title: t('company'), description: t('success_data_save') });
      },
    },
  );

  const handleUplodFile = (id) => {
    setUploadFileId(id);
  };

  const handleSubmit = (formData: Properties) => {
    if (uploadFileId && user?.company?.id && uploadFileId)
      postContractMutate(
        {
          number: formData.number,
          number_instance: { order: Number(formData.order) },
          company: user?.company?.id,
          signed_date: getAPIDateFormat(formData.signed_date),
          attachment: uploadFileId,
        },
        {
          onSuccess: () => {
            notify.success({ title: t('contract'), description: t('success_data_change') });
          },
        },
      );
    form.resetFields();
  };

  return (
    <Layout>
      <Container>
        <Card>
          <Card.Body className="p-0">
            <Row g={0}>
              <Col size={12} md={6} className="py-20 px-30 form-col--border-right">
                <h3 className="form-heading">{t('sign')}</h3>
                <div className="form-divider" />

                <Space justify="space-between">
                  <span>{t('sign_contract')}</span>
                  <ContractSign
                    attachment={data?.attachment}
                    isLoading={isLoading}
                    onChange={() => refetch()}
                  >
                    <Button
                      type="primary"
                      prefix={<Icon type="check" model="bold" />}
                      disabled={data?.attachment?.signatures?.length !== 1}
                    >
                      {t('sign')}
                    </Button>
                  </ContractSign>
                </Space>
                {data?.attachment?.signatures &&
                  data?.attachment?.signatures?.length > 1 &&
                  !data.meta && (
                    <Alert className="mt-30" type="success">
                      {t('contract_signed_success')}
                    </Alert>
                  )}
              </Col>

              <Col size={12} md={6} className="py-20 px-30 form-col--border-right">
                <h3 className="form-heading">{t('upload')}</h3>

                <div className="form-divider" />

                <Space justify="space-between" className="mb-12">
                  <Label text={<span className="ml-30">{t('upload_contract')}</span>} />
                  {data?.meta ? (
                    <Alert type="success">{t('contract_upload_success')}</Alert>
                  ) : (
                    <Upload
                      data={{
                        type: 'CONTRACT',
                      }}
                      {...uploadProps}
                      onSuccess={(e) => {
                        handleUplodFile(e[0].id);
                      }}
                    >
                      <Button
                        disabled={
                          (data?.attachment?.signatures &&
                            data?.attachment?.signatures?.length > 0) ||
                          !!data?.meta
                        }
                        className="no-wrap"
                        prefix={<Icon component={FileUpload} />}
                        type="ghost"
                      >
                        {t('upload')}
                      </Button>
                    </Upload>
                  )}
                </Space>

                <Form
                  form={form}
                  type="horizontal"
                  onFinish={handleSubmit}
                  labelOptions={{ col: { size: 3 } }}
                  controlOptions={{ col: { size: 9 } }}
                  fieldRow={{ gy: 3 }}
                >
                  <Form.Field
                    name="number"
                    label={t('contract_number')}
                    rules={[{ required: true }]}
                  >
                    <Input
                      disabled={
                        (data?.attachment?.signatures &&
                          data?.attachment?.signatures?.length > 0) ||
                        !!data?.meta
                      }
                    />
                  </Form.Field>
                  <Form.Field name="order" label={t('order')} rules={[{ required: true }]}>
                    <Input
                      type="number"
                      disabled={
                        (data?.attachment?.signatures &&
                          data?.attachment?.signatures?.length > 0) ||
                        !!data?.meta
                      }
                    />
                  </Form.Field>
                  <Form.Field
                    name="signed_date"
                    label={t('date_signed')}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      disabled={
                        (data?.attachment?.signatures &&
                          data?.attachment?.signatures?.length > 0) ||
                        !!data?.meta
                      }
                      showTimeSelect
                      dateFormat={dateTimePickerFormat}
                    />
                  </Form.Field>
                  <Space justify="end">
                    <Button
                      loading={isLoadingPostContract}
                      type="primary"
                      prefix={<Icon type="check" model="bold" />}
                      onClick={() => form.submit()}
                      disabled={!uploadFileId}
                    >
                      {t('save')}
                    </Button>
                  </Space>

                  {data?.attachment?.signatures &&
                    data?.attachment?.signatures?.length > 1 &&
                    data.meta && (
                      <Alert className="mt-30" type="success">
                        {t('contract_confirmed')}
                      </Alert>
                    )}
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </Layout>
  );
};
