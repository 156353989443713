import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Icon, Button, Card, Space, ListGroup } from 'ebs-design';
import { downloadFile } from 'utils';
import { Invoice, AttachmentType } from 'types';
import { Document, Download } from 'resources';

export const MandatoryDocuments: React.FC<{ data?: Invoice }> = ({ data }) => {
  const { t } = useIntl();

  const mandatoryDocuments = React.useMemo(
    () =>
      data?.attachments
        ? data.attachments.filter((attachment) =>
            [AttachmentType.INVOICE, AttachmentType.CONTRACT, AttachmentType.XML].includes(
              attachment.type,
            ),
          )
        : [],
    [data],
  );

  return (
    <Card className="mb-25">
      <Card.Header bordered>
        <h4>{t('mandatory_documents')}</h4>
      </Card.Header>
      <Card.Body className="p-0">
        {mandatoryDocuments.length ? (
          <ListGroup className="no-border">
            {mandatoryDocuments.map((document) => (
              <ListGroup.Item key={document.id}>
                <Space justify="space-between">
                  <Space>
                    <Icon component={Document} />
                    {document.name}
                  </Space>

                  <Button
                    prefix={<Icon component={Download} />}
                    onClick={() => downloadFile(document.url, document.name)}
                  >
                    {t('download')}
                  </Button>
                </Space>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <div className="p-10 text-center">{t('no_data')}</div>
        )}
      </Card.Body>
    </Card>
  );
};
