import * as React from 'react';
import { useSetState } from 'react-use';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { Button, Modal, Icon, Space } from 'ebs-design';
import { company as companyAPI, reports } from 'api';
import { usePermissions } from 'hooks';
import { Properties, Permissions, ReportCompanyType, CompanyType } from 'types';
import { downloadFile } from 'utils';
import { Eye, Download } from 'resources';

import { ReportKYC } from '../molecules/ReportKYC';

const defaultState: {
  url?: string;
  companyId?: string;
  know_your_client?: string;
  know_your_client_confirmed: boolean;
} = {
  know_your_client_confirmed: false,
};

interface ReportKYCModalProps {
  type: CompanyType;
  additional_data: Properties;
}

export const ReportKYCModal: React.FC<ReportKYCModalProps> = ({ type, additional_data }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const can = usePermissions(Permissions[`${type}S`]);

  const [state, setState] = useSetState(defaultState);

  React.useEffect(() => {
    if (!id && !!state.companyId) {
      setState({ url: undefined });
    }
  }, [id, state.companyId, setState]);

  const company = useMutation(
    (params: { companyId?: string; data: Properties }) => companyAPI.update(params),
    {
      onSuccess: () => {
        onCancel();
        queryClient.invalidateQueries('adherent_invoices_no_draft');
        queryClient.invalidateQueries('report-kyc');
        queryClient.invalidateQueries('company-request');
      },
    },
  );

  useQuery(['report-kyc'], () => reports.downloadReports({ id, type: ReportCompanyType.KYC }), {
    enabled: !!id && !!state.companyId,
    onSuccess: (data) => {
      if (data.attachment) {
        setState({ url: data.attachment.url });
      }
    },
  });

  const onCancel = (): void => setState({ companyId: undefined });

  const onChangeInfo = (know_your_client) => setState({ know_your_client });

  const onConfirm = React.useCallback(() => {
    const { companyId, know_your_client, know_your_client_confirmed } = state;

    company.mutate({
      companyId: companyId,
      data: {
        additional_data: {
          ...(!!know_your_client?.length && { know_your_client }),
          know_your_client_confirmed,
        },
      },
    });
  }, [company, state]);

  const onOpen = React.useCallback(
    () =>
      setState((prevState) => ({
        companyId: id,
        know_your_client: prevState.know_your_client || additional_data?.know_your_client || '',
        know_your_client_confirmed: true,
      })),
    [id, additional_data, setState],
  );

  return (
    <>
      <Button prefix={<Icon component={Eye} />} onClick={onOpen}>
        {t('report_kyc')}
      </Button>

      <Modal
        open={!!state.companyId}
        size="large"
        className="pc-confirmation-modal"
        header={t('know_your_client')}
        onClose={onCancel}
      >
        <Modal.Content className="p-0">
          <ReportKYC data={state} onChange={onChangeInfo} readOnly={!can.perform?.confirmKYC} />
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onCancel}>{t('cancel')}</Button>

            <Space>
              <Button
                type="ghost"
                prefix={<Icon component={Download} />}
                onClick={() => downloadFile(state.url!, 'report.pdf')}
              >
                {t('download')}
              </Button>

              {can.perform?.confirmKYC && (
                <Button
                  type="primary"
                  prefix={<Icon type="check" model="bold" />}
                  onClick={onConfirm}
                  loading={company.isLoading}
                >
                  {t('save')}
                </Button>
              )}
            </Space>
          </Space>
        </Modal.Footer>
      </Modal>
    </>
  );
};
