import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Icon, Space, Form, Input, useForm, useNotify } from 'ebs-design';
import { UserContext } from 'contexts';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { company, nomenclature } from 'api';
import { Properties, Results, User } from 'types';
import { routes } from 'routes';
import { SmartSelect } from 'components';
import { validateResponse, extractResponseProps, getRoute, baseName } from 'utils';

interface Props {
  filters: Properties;
  onChangeFilters: (value: Properties) => void;
}

export const InviteModal: React.FC<Props> = ({ filters, onChangeFilters, children }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const { updateQuery } = useQueryUpdate();
  const queryClient = useQueryClient();
  const { invite, ...params } = useQueryParams();
  const { user } = React.useContext(UserContext);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (invite) {
      setVisible(true);
      updateQuery(params);
      onChangeFilters({ ...filters, invite: undefined });
    }
  }, [invite, params, filters, updateQuery, onChangeFilters]);

  const rolesList = useQuery(['nomenclature', 'roles'], () => nomenclature.getRoles());

  const inviteUser = useMutation(
    (data: Properties) => company.inviteUser({ id: user?.company?.id, ...data }),
    {
      onMutate: async (data: Properties) => {
        const query = ['users_invitations', user!.company?.id, filters];
        const prevData = queryClient.getQueryData(query) as Results<User<string[]>>;

        queryClient.setQueryData(query, {
          ...prevData,
          results: [data, ...prevData.results],
        });

        return () => queryClient.invalidateQueries('users_invitations');
      },
      onError: (err, values, rollback: any) => {
        validateResponse(form, err, values);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
        rollback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries('users_invitations');
        notify.success({ title: t('invitations'), description: t('success_invitations_send') });
        onToggleHandler();
      },
    },
  );

  const onToggleHandler = React.useCallback(() => setVisible((i) => !i), []);

  const handleSubmit = (data: Properties) => {
    inviteUser.mutate({
      ...data,
      roles_id: [data.roles_id],
      redirect_url: `${document.location.origin}${baseName}${getRoute(routes, 'ActivationPage')}`,
    });
  };

  return (
    <>
      <Modal open={visible} size="small" onClose={onToggleHandler} title={t('invite_new_user')}>
        <Modal.Content className="pc-invite-user">
          <h3 className="mb-15">{t('enter_data_user_invitation')}</h3>
          <Form
            form={form}
            type="horizontal"
            onFinish={handleSubmit}
            labelOptions={{ col: { size: 3 } }}
            controlOptions={{ col: { size: 8 } }}
          >
            <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
              <Input />
            </Form.Field>

            <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
              <Input />
            </Form.Field>

            <Form.Field name="email" label={t('email')} rules={[{ required: true }]}>
              <Input type="email" />
            </Form.Field>

            <Form.Field name="roles_id" label={t('role')} rules={[{ required: true }]}>
              <SmartSelect
                loading={rolesList.isLoading}
                options={rolesList.data?.map((role) => ({
                  ...role,
                  text: t(role.text as string),
                }))}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onToggleHandler}>{t('cancel')}</Button>

            <Button
              type="primary"
              prefix={<Icon type="check" model="bold" />}
              onClick={() => form.submit()}
              loading={inviteUser.isLoading}
            >
              {t('invite')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
      <div onClick={onToggleHandler}>{children}</div>
    </>
  );
};
