import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Button, DatePicker, Form, Modal, Space, Table, useForm, useNotify } from 'ebs-design';
import { nomenclature } from 'api';
import { FactoringType } from 'types';
import { extractResponseProps, formatCategoryRates, getCategoryRatesByType } from 'utils';
import { toISO } from 'libs';
import { InputNumericForm } from 'components';

type Props = {
  factoringType?: FactoringType;
  isOpen: boolean;
  onClose: () => void;
};

export const AddFutureRatesModal = ({ factoringType, isOpen, onClose }: Props) => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [form] = useForm();

  const { data, isLoading } = useQuery(
    ['nomenclatures', 'rates'],
    () => nomenclature.getCategoriesRatesList(),
    {
      enabled: !!factoringType,
      select: (data) => formatCategoryRates(getCategoryRatesByType(data, factoringType)),
    },
  );

  const { mutate, isLoading: isLoadingMutate } = useMutation(
    nomenclature.createCategoriesFutureRate,
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([`company`, 'future-rates']);
        notify.success({ title: t('rates'), description: t('success_data_save') });
        onClose();
      },
    },
  );

  const columns = React.useMemo(
    () => [
      {
        title: (
          <Space justify="space-between">
            {t(
              FactoringType.REGRESSION === factoringType
                ? 'with_right_refund'
                : 'without_right_refund',
            )}
            <Form.Field name="timestamp" rules={[{ required: true }]}>
              <DatePicker className="rates-date-picker" isClearable />
            </Form.Field>
          </Space>
        ),
        children: [
          {
            title: t('category'),
            render: ({ id, factoring_type_id, category_name, category_id }) => (
              <>
                <span>{category_name}</span>
                <Form.Field name={[id, 'factoring_type']} initialValue={factoring_type_id} />
                <Form.Field name={[id, 'rate']} initialValue={id} />
                <Form.Field name={[id, 'category']} initialValue={category_id} />
              </>
            ),
          },
          {
            title: t('days'),
            render: ({ id, value }) => (
              <InputNumericForm
                name={[id, 'value']}
                initialValue={value}
                inputProps={{ suffix: <>{t('days')}</> }}
              />
            ),
          },
          {
            title: t('commission'),
            render: ({ id, percent }) => (
              <InputNumericForm
                name={[id, 'percent']}
                initialValue={percent}
                inputProps={{ suffix: <>%</> }}
              />
            ),
          },
          {
            title: `${t('commission')} + ${t('guarantee')}`,
            render: ({ id, gc_percent }) => (
              <InputNumericForm
                name={[id, 'gc_percent']}
                initialValue={gc_percent}
                inputProps={{ suffix: <>%</> }}
              />
            ),
          },
        ],
      },
    ],
    [t, isLoading],
  );

  const handleSubmit = ({ timestamp, ...values }) => {
    Object.values(values).forEach((item) => {
      mutate({
        timestamp: toISO(timestamp),
        ...item,
      });
    });
  };

  return (
    <Modal size="small" header={`${t('add')} ${t('future_rates')}`} open={isOpen} onClose={onClose}>
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Content className="p-0">
          <Table className="table-no-border" columns={columns} data={data} />
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onClose}>{t('cancel')}</Button>
            <Button type="primary" submit loading={isLoadingMutate} disabled={isLoadingMutate}>
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
