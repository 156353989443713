import * as React from 'react';
import {
  Modal,
  Form,
  Space,
  Button,
  useForm,
  Upload,
  Icon,
  Input,
  Select,
  useNotify,
} from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { ModalProps } from 'ebs-design/dist/components/organisms/Modal/Modal';
import { useMutation } from 'react-query';

import { Plus } from 'resources';
import { externals } from 'api';
import { extractResponseProps } from 'utils';
import { CertifiedType } from 'types';

export const UploadModal = ({ ...props }: ModalProps) => {
  const [form] = useForm();
  const { t } = useIntl();
  const notify = useNotify();

  const commonMutationField = {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      props.onClose?.();

      notify.success({ title: t('certificates'), description: t('success_data_save') });
    },
  };

  const mutationCer = useMutation(externals.addCer, commonMutationField);
  const mutationPfx = useMutation(externals.addPfx, commonMutationField);

  const handleSubmit = (values) => {
    const formData = new FormData();
    if (values.certified_type === CertifiedType.CER) {
      formData.append('private_file', values?.file?.[0]);

      mutationCer.mutate(formData);
    } else {
      formData.append('pfx_file', values?.file?.[0]);
      formData.append('pfx_password', values?.pfx_password);

      mutationPfx.mutate(formData);
    }
  };

  return (
    <Modal size="small" header={t('upload_certified')} {...props}>
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Content>
          <Form.Field
            rules={[{ required: true }]}
            label={t('certified_type')}
            name="certified_type"
            initialValue={CertifiedType.CER}
          >
            <Select
              options={Object.values(CertifiedType).map((value) => ({
                value,
                text: value.toUpperCase(),
              }))}
            />
          </Form.Field>

          <Form.Field dependencies={['certified_type']}>
            {(_, __, form) => {
              const certifiedType = form.getFieldValue('certified_type') as CertifiedType;
              const isPfxCertified = certifiedType === CertifiedType.PFX;

              return (
                <>
                  <Form.Field
                    label={t('upload_certified')}
                    name="file"
                    rules={[{ required: true }]}
                  >
                    {(control) => (
                      <Upload
                        accept={isPfxCertified ? '.pfx' : '.cer'}
                        beforeUpload={(file) => {
                          control.onChange(file);
                          return false;
                        }}
                        {...control}
                      >
                        <Button type="fill" prefix={<Icon component={Plus} />}>
                          {t('upload')}
                        </Button>
                      </Upload>
                    )}
                  </Form.Field>

                  {isPfxCertified && (
                    <Form.Field
                      label={t('password')}
                      name="pfx_password"
                      rules={[{ required: true }]}
                    >
                      <Input type="password" />
                    </Form.Field>
                  )}
                </>
              );
            }}
          </Form.Field>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={props.onClose}>{t('cancel')}</Button>
            <Button type="primary" submit loading={mutationCer.isLoading || mutationPfx.isLoading}>
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
