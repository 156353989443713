import { useEffect } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { common } from 'api';
import { Properties } from 'types';

export const useFileFetch = (
  url: string | null | false | undefined,
  options?: Properties,
): UseQueryResult<string> => {
  const file = useQuery(['file', url], () => common.getFile(url || ''), {
    enabled: !!url,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    ...options,
  });

  useEffect(
    () => () => {
      if (file.data) {
        window.URL.revokeObjectURL(file.data);
      }
    },
    [file.data],
  );

  return file;
};
