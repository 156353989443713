import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useSetState } from 'react-use';
import cn from 'classnames';
import { useIntl } from 'estafette-intl';
import { Container, Table, Label, Card, Space, Button, Icon, SortBy, Loader } from 'ebs-design';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { invoices } from 'api';
import { formatDate } from 'libs';
import { UserContext } from 'contexts';
import {
  Invoice,
  Status,
  Column,
  CompanyType,
  Properties,
  InvoiceStatus,
  CustomerRole,
} from 'types';
import { defaultFilters, getSortOptions, arrayContainsArray } from 'utils';
import { Layout, Pagination, SmartSelect } from 'components';
import { Bell, Invoices } from 'resources';

import Filters from '../Filters';
import NotificationsModal from '../NotificationsModal';

export const Notifications: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const params = useQueryParams();
  const { userRoles } = React.useContext(UserContext);
  const { updateQuery } = useQueryUpdate();
  const [filters, setFilters] = useSetState<Properties>({
    ...defaultFilters,
    ...params,

    // FIXME: Fix companies on initial state, depends of InputSelect default value
    companies_id: [],
  });
  const [notify, setNotify] = useSetState({ visible: false, data: null });

  const { data, isLoading } = useQuery(
    ['invoices', { ...filters, status__exclude: InvoiceStatus.DRAFT.toLowerCase() }],
    invoices.getList,
  );

  const handleSubmit = React.useCallback(
    (additionalFilters) =>
      setFilters((prevState) => ({
        ...prevState,
        ...additionalFilters,
      })),
    [setFilters],
  );

  // eslint-disable-next-line
  React.useEffect(() => updateQuery(filters), [filters]);

  const columns: Column<Invoice>[] = React.useMemo(
    () => [
      {
        title: null,
        key: 'invoice-icon',
        width: 30,
        render: () => {
          return (
            <div className="pc-avatar--small">
              <Icon component={Invoices} />
            </div>
          );
        },
      },
      {
        title: t('invoice_number'),
        dataIndex: 'number',
        filter: 'invoice_number',
        width: 100,
        onCell: (rowData) => ({
          className: 'ebs-table__cell--clickable',
          onClick: () => push(`/invoices/${rowData.id}`),
        }),
        render: (value, rowData) =>
          rowData?.series || value ? `${rowData?.series || ''}${value || ''}` : '---',
      },
      {
        title: t('status'),
        filter: 'status',
        render: ({ status }) => (
          <Label status={Status[status]} type="fill" circle text={t(status.toLowerCase())} />
        ),
      },
      {
        title: t('adherent'),
        dataIndex: ['company', 'title'],
      },
      {
        title: t('debtor'),
        render: (rowData) =>
          rowData.companies?.find(
            (company) =>
              company.types.includes(CompanyType.DEBTOR) && company.id !== rowData.company?.id,
          )?.title || '---',
      },
      {
        title: t('term_date'),
        filter: 'term_date',
        render: ({ term_date }) => (term_date ? formatDate(term_date) : '---'),
      },
      {
        title: t('amount'),
        dataIndex: 'amount',
        filter: 'amount',
        render: (value, rowData) => `${value} ${rowData.currency.code_name}`,
      },
      {
        title: t('action'),
        action: true,
        width: 150,
        render: (rowData) => (
          <Space justify="end">
            <Button
              size="small"
              prefix={<Bell />}
              onClick={() => setNotify({ visible: true, data: rowData })}
            >
              {t('notifications')}
            </Button>
          </Space>
        ),
      },
    ],
    [t, push, setNotify],
  );

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);
  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  return (
    <Layout>
      <Filters onSubmit={handleSubmit} />
      <Container>
        <Space align="center" justify="space-between" className="mt-20 mb-20">
          <Space>
            <h3 className="page-title">
              {t('invoices')} ({data?.count || 0})
            </h3>

            <SmartSelect
              options={Object.values(InvoiceStatus)
                .filter((status) => (isAdherent ? true : status !== InvoiceStatus.DRAFT))
                .map((item) => {
                  const status = item.toLowerCase();

                  return { value: status, text: t(status) };
                })}
              value={filters.status}
              onChange={(status) => setFilters(() => ({ status, page: 1 }))}
              placeholder={t('select_status')}
              className="select-min-width"
            />
          </Space>

          <SortBy
            options={[
              { value: 'company_title', title: t('adherent') },
              { value: 'debtor_title', title: t('debtor') },
              ...sortOptions,
            ]}
            value={filters?.ordering}
            onChange={(ordering) => setFilters({ ordering })}
          />
        </Space>
        <Card>
          <Card.Body className="p-0">
            <Loader loading={isLoading}>
              <Table
                className={cn('table-no-border')}
                columns={columns}
                data={data?.results}
                emptyCell="---"
              />
            </Loader>
            <NotificationsModal
              data={notify.data}
              visible={notify.visible}
              onClose={() => setNotify({ visible: false, data: null })}
            />
          </Card.Body>
          <Card.Footer>
            <Pagination data={data} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </Container>
    </Layout>
  );
};
