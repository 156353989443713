import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { Space, Select, Loader, Table } from 'ebs-design';
import { nomenclature } from 'api';
import { FactoringType, Properties } from 'types';
import { formatHistoryCategoryRates } from 'utils';
import { formatDate } from 'libs';
import { RatesLayout } from 'features/profile/RatesLayout';

export const RatesHistory: React.FC = () => {
  const { t } = useIntl();

  const [filters, setFilters] = useSetState<Properties>({
    factoring_type: FactoringType.NO_REGRESSION,
    timestamp: null,
  });

  const { data: historyDates, isLoading: isLoadingHistoryDates } = useQuery(
    ['nomenclatures', 'history-dates', { factoring_type: filters.factoring_type }],
    () => nomenclature.getHistoryDatesList({ factoring_type: filters.factoring_type }),
    {
      select: (data) => data?.map(({ date }) => ({ text: formatDate(date), value: date })),
    },
  );

  const { data, isLoading } = useQuery(
    ['nomenclatures', 'history-rates', filters],
    () => nomenclature.getCategoriesHistoryRatesList(filters),
    { select: (data) => formatHistoryCategoryRates(data) },
  );

  const columns = React.useMemo(
    () => [
      {
        title: t(
          filters.factoring_type === FactoringType.NO_REGRESSION
            ? 'without_right_refund'
            : 'with_right_refund',
        ),

        children: [
          {
            title: t('category'),
            render: ({ category_info, rates_length }) => ({
              children: category_info,
              props: { rowSpan: rates_length },
            }),
          },

          {
            title: t('days'),
            render: ({ value }) => (value >= 0 ? `${value} ${t('days')}` : '-'),
          },
          {
            title: t('commission'),
            dataIndex: 'percent',
            render: (percent) => `${percent}%`,
          },
          {
            title: t('guarantee'),
            dataIndex: 'guarantee',
            render: (guarantee) => `${guarantee}%`,
          },
          {
            title: t('total'),
            dataIndex: 'gc_percent',
            render: (gc_percent) => `${gc_percent}%`,
          },
        ],
      },
    ],
    [t, isLoading],
  );

  return (
    <RatesLayout>
      <Space align="center" justify="space-between" className="mt-20 mb-2" wrap>
        <h4>{t('history_rates')}</h4>
        <Space>
          <Select
            className="rates-select"
            value={filters.timestamp}
            onChange={(timestamp) =>
              setFilters((prevState) => ({
                ...prevState,
                timestamp,
              }))
            }
            loading={isLoadingHistoryDates}
            options={historyDates}
            isClearable
          />
          <Select
            className="rates-select"
            value={filters.factoring_type}
            onChange={(factoring_type) =>
              setFilters((prevState) => ({
                ...prevState,
                factoring_type,
              }))
            }
            options={[
              { value: FactoringType.NO_REGRESSION, text: t('without_right_refund') },
              {
                value: FactoringType.REGRESSION,
                text: t('with_right_refund'),
              },
            ]}
          />
        </Space>
      </Space>

      <Space className="mb-8">
        {data?.[0]?.user_change && <p>{`${t('email')}: ${data?.[0]?.user_change}`}</p>}
        {data?.[0]?.timestamp && <p>{`${t('date')}: ${formatDate(data?.[0]?.timestamp)}`}</p>}
      </Space>

      <Loader loading={isLoading}>
        <Table className="pc-rates__table" columns={columns} data={data} />
      </Loader>
    </RatesLayout>
  );
};
