import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';
import { Icon } from 'ebs-design';
import { usePermissions } from 'hooks';
import { Permissions } from 'types';
import {
  AlertBell,
  Badge,
  Penalties,
  Settings,
  Team,
  User,
  PaperEdit,
  Bell,
  RatesAndLimits,
  List,
} from 'resources';
import { stringifyUrl } from 'libs';
import { getRoute, isRouteActive } from 'utils';
import { routes } from 'routes';

export const SideMenu: React.FC = () => {
  const { t } = useIntl();
  const { pathname } = useLocation();
  const params = useParams();
  const can = usePermissions(Permissions.PROFILE);

  return (
    <div className="my-profile__side-menu">
      <div className="my-profile__side-menu__title">{t('profile_settings')}</div>
      <div className="my-profile__side-menu__list">
        <Link to={stringifyUrl(getRoute(routes, 'MyProfile'))}>
          <div
            className={cn('my-profile__side-menu__list-item', {
              active: isRouteActive(pathname, params, ['MyProfile']),
            })}
          >
            <Icon component={User} />
            {t('my_profile')}
          </div>
        </Link>
        {can.read?.menu?.users && (
          <Link to={stringifyUrl(getRoute(routes, 'MyUsers'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['MyUsers', 'MyUsersRole']),
              })}
            >
              <Icon component={Team} />
              {t('users')}
            </div>
          </Link>
        )}
        {can.read?.menu?.alerts && (
          <Link to={stringifyUrl(getRoute(routes, 'Alerts'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['Alerts']),
              })}
            >
              <Icon component={AlertBell} />
              {t('alert_settings')}
            </div>
          </Link>
        )}
        {can.read?.menu?.penalities && (
          <Link to={stringifyUrl(getRoute(routes, 'Penalties'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['Penalties']),
              })}
            >
              <Icon component={Penalties} />
              {t('penalties')}
            </div>
          </Link>
        )}
        {can.read?.menu?.classifiers && (
          <Link to={stringifyUrl(getRoute(routes, 'Classifiers'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['Classifiers', 'Categories']),
              })}
            >
              <Icon component={Badge} />
              {t('classifiers')}
            </div>
          </Link>
        )}
        {can.read?.menu?.committee && (
          <Link to={stringifyUrl(getRoute(routes, 'Committees'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['Committees']),
              })}
            >
              <Icon component={Settings} />
              {t('committee_settings')}
            </div>
          </Link>
        )}
        {can.read?.menu?.contracts && (
          <Link to={stringifyUrl(getRoute(routes, 'Contracts'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['Contracts']),
              })}
            >
              <Icon component={PaperEdit} />
              {t('contracts')}
            </div>
          </Link>
        )}
        {can.read?.menu?.notifications && (
          <Link to={stringifyUrl(getRoute(routes, 'AdminNotifications'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['AdminNotifications']),
              })}
            >
              <Icon component={Bell} />
              {t('alerts')}
            </div>
          </Link>
        )}
        {can.read?.menu?.rates && (
          <Link to={stringifyUrl(getRoute(routes, 'Rates'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['Rates']),
              })}
            >
              <Icon component={RatesAndLimits} />
              {t('rates')}
            </div>
          </Link>
        )}
        {can.read?.menu?.contractsNumber && (
          <Link to={stringifyUrl(getRoute(routes, 'ContractsNumber'))}>
            <div
              className={cn('my-profile__side-menu__list-item', {
                active: isRouteActive(pathname, params, ['ContractsNumber']),
              })}
            >
              <Icon component={List} />
              {t('contracts_number')}
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
