import * as React from 'react';
import { Container, Row, Col } from 'ebs-design';
import { useIntl } from 'estafette-intl';

import { Link as LinkIcon, FileUpload } from 'resources';
import { Layout } from 'components/organisms';
import DashboardCard from '../DashboardCard';
import { UploadModal, ValidateCertifiedModal } from '../certified';

export const Certified = () => {
  const { t } = useIntl();

  const [isOpenUploadModal, setIsOpenUploadModal] = React.useState(false);
  const [isOpenValidateModal, setIsOpenValidateModal] = React.useState(false);

  return (
    <>
      <Layout>
        <Container>
          <Row className="mb-20">
            <Col>
              <h3>{t('certificates')}</h3>
            </Col>
          </Row>

          <Row className="mb-20 g-4">
            <Col size={12} md={6}>
              <DashboardCard
                style={{ backgroundImage: `url(${require('resources/images/upload.png')})` }}
                icon={FileUpload}
                title={t('upload_certified')}
                onClick={() => setIsOpenUploadModal(true)}
              />
            </Col>
            <Col size={12} md={6}>
              <DashboardCard
                style={{ backgroundImage: `url(${require('resources/images/sign.png')})` }}
                icon={LinkIcon}
                title={t('check_validation')}
                onClick={() => setIsOpenValidateModal(true)}
              />
            </Col>
          </Row>
        </Container>
      </Layout>

      <UploadModal open={isOpenUploadModal} onClose={() => setIsOpenUploadModal(false)} />

      <ValidateCertifiedModal
        open={isOpenValidateModal}
        onClose={() => setIsOpenValidateModal(false)}
      />
    </>
  );
};
