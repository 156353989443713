import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Alert } from 'ebs-design';
import { usePermissions } from 'hooks';
import { Permissions } from 'types';
import { SideMenu } from './SideMenu/SideMenu';
import { Layout } from 'components/organisms';
import { isRouteActive } from 'utils';

export const MyProfileLayout: React.FC = ({ children }) => {
  const { t } = useIntl();
  const can = usePermissions(Permissions.PROFILE);
  const params = useParams();
  const { pathname } = useLocation();

  const isVisible =
    isRouteActive(pathname, params, 'MyProfile') ||
    (isRouteActive(pathname, params, ['MyUsers', 'MyUsersRole']) && can.read?.menu?.users) ||
    (isRouteActive(pathname, params, ['Alerts']) && can.read?.menu?.alerts) ||
    (isRouteActive(pathname, params, ['AdminNotifications']) && can.read?.menu?.notifications) ||
    (isRouteActive(pathname, params, ['Penalties']) && can.read?.menu?.penalities) ||
    (isRouteActive(pathname, params, ['Classifiers', 'Categories']) &&
      can.read?.menu?.classifiers) ||
    (isRouteActive(pathname, params, ['Committees']) && can.read?.menu?.committee) ||
    (isRouteActive(pathname, params, ['Rates']) && can.read?.menu?.rates) ||
    (isRouteActive(pathname, params, ['RatesHistory']) && can.read?.menu?.rates) ||
    (isRouteActive(pathname, params, ['RatesFuture']) && can.read?.menu?.rates) ||
    (isRouteActive(pathname, params, ['Contracts']) && can.read?.menu?.contracts) ||
    (isRouteActive(pathname, params, ['ContractsNumber']) && can.read?.menu?.contractsNumber);

  return (
    <Layout className="my-profile">
      <SideMenu />
      <div className="my-profile__content-wrapper">
        <Container>
          {isVisible ? children : <Alert message={t('not_access')} type="error" />}
        </Container>
      </div>
    </Layout>
  );
};
