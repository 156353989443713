export const profile = {
  nbm_rate: {
    en: `NBM rate`,
    ro: `Rată BNM`,
    ru: `курс НБМ`,
  },
  per_day: {
    en: `per day`,
    ro: `pe zi`,
    ru: `в день`,
  },
  set_penalties: {
    en: `Set penalties`,
    ro: `Setare penalități`,
    ru: `Установить штрафы`,
  },
  set_days: {
    en: 'Set days',
    ro: 'Setare zile',
    ru: 'Установить дни',
  },
  add_turnover: {
    en: `Add turnover`,
    ro: `Adăugare rulaj`,
    ru: `Добавить оборот`,
  },
  edit_turnovers: {
    en: `Edit turnovers`,
    ro: `Modificare rulaje`,
    ru: `Изменить обороты`,
  },
  edit_penalties: {
    en: `Edit penalties`,
    ro: `Modificare penalități`,
    ru: `Изменить штрафы`,
  },
  turnover_date: {
    en: `Turnover date`,
    ro: `Data rulajului`,
    ru: `Дата оборота`,
  },
  payments: {
    en: `Payments`,
    ro: `Achitări`,
    ru: `Платежи`,
  },
  final_balance: {
    en: `Final balance`,
    ro: `Sold final`,
    ru: `Окончательный баланс`,
  },
  delay_interest: {
    en: `Delay interest`,
    ro: `Dobânda de întârziere`,
    ru: `Задержка интереса`,
  },
  outstanding: {
    en: `Remaining days`,
    ro: `Zile restante`,
    ru: `Оставшиеся дни`,
  },
  nr_contract: {
    en: `Contract nr.`,
    ro: `Nr. contract`,
    ru: `Номер контракта`,
  },
  alerts: {
    en: 'Alerts',
    ro: 'Alerte',
    ru: 'Оповещения',
  },
  successfully_added_to_company: {
    en: 'Successfully added to company',
    ro: 'Adăugat cu succes la companie',
    ru: 'Успешно добавлен в компанию',
  },
  add_rate: {
    en: `Add rate`,
    ro: `Adăugați rată`,
    ru: `Добавить курс`,
  },
  add_future_rates: {
    en: `Add future rates`,
    ro: `Adăugați rate viitoare`,
    ru: `Добавить будущие курсы`,
  },
  apply_rates_now: {
    en: `Apply rates now`,
    ro: `Aplică ratele acum`,
    ru: `Применить сейчас тарифы`,
  },
  penalty: {
    en: `Penalty`,
    ro: `Penalitate`,
    ru: `Штраф`,
  },
  no_value: {
    en: `No value`,
    ro: `Fără valoare`,
    ru: `Нет значения`,
  },
  no_penalty: {
    en: `No penalty`,
    ro: `Fără penalităte`,
    ru: `Нет штрафа`,
  },
  administrator_required_identity_card: {
    en: 'The administrator must upload the Identity Card',
    ro: 'Administratorul trebuie să încarce Buletinul de Identitate',
    ru: 'Администратор должен загрузить удостоверение личности',
  },
};
