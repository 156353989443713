import { axios, cancelTokenHandler } from 'libs';
import { Results, Committee, Properties } from 'types';
import { transformResponse, formatNumber } from 'utils';

export const committees = {
  getList: async (params: Properties) => {
    const { data } = await axios.get<Results<Committee>>(`/committees/list/`, {
      params,
      transformResponse: (response) =>
        transformResponse(response, {
          properties: ['factoring_range.maximum', 'factoring_range.minimum'],
          wrapper: (value) => formatNumber(value),
        }),
      cancelToken: cancelToken('getList'),
    });

    return data;
  },
  delete: async (id: number) =>
    axios.delete<undefined>(`committees/${id}/`, {
      cancelToken: cancelToken('delete'),
    }),
  create: async (body: Properties) => {
    const { data } = await axios.post<Committee>(`committees/`, body, {
      cancelToken: cancelToken('create'),
    });
    return data;
  },
  update: async ([id, body]: [number, Properties]) => {
    const { data } = await axios.patch<Committee>(`committees/${id}/`, body, {
      cancelToken: cancelToken('update'),
    });
    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(committees);
