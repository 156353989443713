import { Permissions } from 'types';
import adherents from './adherents';
import debtors from './debtors';
import home from './home';
import modules from './modules';
import invoices from './invoices';
import profile from './profile';
import requests from './requests';
import users from './users';
import reports from './reports';
import turnovers from './turnovers';

export default {
  [Permissions.ADHERENTS]: adherents,
  [Permissions.DEBTORS]: debtors,
  [Permissions.HOME]: home,
  [Permissions.MODULES]: modules,
  [Permissions.INVOICES]: invoices,
  [Permissions.PROFILE]: profile,
  [Permissions.REQUESTS]: requests,
  [Permissions.USERS]: users,
  [Permissions.REPORTS]: reports,
  [Permissions.TURNOVERS]: turnovers,
};
