import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Space, Textarea, Loader } from 'ebs-design';
import { useFileFetch } from 'hooks';
import { Properties } from 'types';
import { PDFViewer } from 'components';

export interface ReportKYCProps {
  data: Properties;
  readOnly?: boolean;
  onChange: (value: string) => void;
}

export const ReportKYC: React.FC<ReportKYCProps> = ({ data, readOnly = false, onChange }) => {
  const { t } = useIntl();

  const file = useFileFetch(data?.url || '');

  return (
    <>
      <Loader loading={!data?.url?.length || file.isLoading}>
        <PDFViewer file={file.data!} className="pc-kyc__report" />
      </Loader>

      <div className="p-20">
        {t('additional_information')}
        <Space className="pc-kyc" align="start">
          <Textarea
            value={data.know_your_client}
            placeholder={`${t('type_of_company_activity')}...`}
            onChange={(value) => onChange(value)}
            disabled={readOnly}
          />
        </Space>
      </div>
    </>
  );
};
