export const getDeepKeys = (obj): string[] => {
  const keys: string[] = [];

  for (const key in obj) {
    keys.push(key);

    if (typeof obj[key] === 'object') {
      const subkeys = getDeepKeys(obj[key]);

      keys.push(...subkeys);
    }
  }

  return keys;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const validateResponse = (form, err, values) => {
  if (err.response) {
    const errors = err.response.data;

    // FIXME: Add functionality to handle errors for the array and object value
    const fieldsErrors = Object.keys(errors || {}).map((k) => {
      return {
        name: k,
        errors: errors[k],
      };
    });

    form.setFields(fieldsErrors);
  } else if (err.request) {
    // client never received a response, or request never left
    console.error('Request error:', err.request);
  } else {
    // anything else
    console.log('Validation error', err);
  }
};
