import React, { FC, useCallback } from 'react';
import { useIntl } from 'estafette-intl';
import { UseMutationResult } from 'react-query';
import { FormInstance, Form, Input } from 'ebs-design';
import { Category, Properties } from 'types';

export interface CategoryFormProps {
  form: FormInstance;
  category: Category | null;
  createCategory: UseMutationResult<Category, any, Properties>;
  updateCategory: UseMutationResult<Category, any, [number, Properties]>;
}

export interface CategoryFormValues {
  code_name: string;
}

export const CategoryForm: FC<CategoryFormProps> = ({
  form,
  category,
  createCategory,
  updateCategory,
}) => {
  const { t } = useIntl();

  const handleSumbit = useCallback(
    (data: CategoryFormValues) => {
      if (category) {
        updateCategory.mutate([category.id, data]);
        return;
      }
      createCategory.mutate(data);
    },
    [category, createCategory, updateCategory],
  );

  return (
    <Form form={form} initialValues={category || undefined} onFinish={handleSumbit}>
      <Form.Field name="code_name" label={t('category_name')} rules={[{ required: true }]}>
        <Input />
      </Form.Field>
    </Form>
  );
};
