import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import {
  Actions,
  Card,
  InputSearch,
  SortBy,
  Space,
  Table,
  Loader,
  AvatarInline,
  firstLetters,
} from 'ebs-design';
import { company } from 'api/company';
import { Layout, Pagination } from 'components/organisms';
import { defaultFilters, getRoute, getSortOptions } from 'utils';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { Column } from 'types';
import { AdherentLayout } from '../AdherentLayout';
import { AdherentProfile } from '../AdherentProfile';
import { routes } from 'routes';

export const Debtors: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const { push } = useHistory();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  React.useEffect(() => updateQuery(filters), [filters, updateQuery]);

  const { data: debtors, isLoading: debtorsLoading } = useQuery(
    ['companies', { ...filters, adherents_id: id }],
    company.getList,
  );

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('name'),
        filter: 'title',
        render: ({ title, email }) => (
          <AvatarInline circle alt={title} shortAlt={firstLetters(title)} description={email} />
        ),
        width: 250,
      },
      {
        title: t('status'),
        dataIndex: 'status',
        filter: 'status',
        width: 90,
      },
      {
        title: t('sum_of_active_invoices'),
        filter: 'sum_of_active_invoices',
        render: ({ sum_of_active_invoices }) => sum_of_active_invoices || 0,
      },
      {
        title: t('available_limit'),
        filter: 'limit.accessible',
        render: ({ limit }) => limit.accessible || 0,
      },
      {
        title: t('total_active_invoices'),
        dataIndex: 'total_active_invoices',
        filter: 'total_active_invoices',
      },
      {
        title: t('amount_of_assigned_invoices'),
        filter: 'sum_of_remained_invoices',
        render: ({ sum_of_remained_invoices }) => sum_of_remained_invoices || 0,
      },
      {
        title: t('total_assigned_invoices'),
        dataIndex: 'total_remained_invoices',
        filter: 'total_remained_invoices',
      },
      {
        title: null,
        action: true,
        render: ({ id }) => (
          <Actions>
            <Actions.Item onClick={() => push(getRoute(routes, 'DebtorDetails', { id }))}>
              {t('details')}
            </Actions.Item>
            <Actions.Item>{t('edit')}</Actions.Item>
            <Actions.Item>{t('delete')}</Actions.Item>
          </Actions>
        ),
      },
    ],
    [t, push],
  );
  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  return (
    <Layout>
      <AdherentLayout>
        <Loader loading={isLoading}>{data && <AdherentProfile data={data} />}</Loader>

        <Card className="company-details__main">
          <Card.Header>
            <Space justify="space-between">
              <Space align="center">
                <h3 className="page-title">
                  {t('debtors')} ({debtors?.count || 0})
                </h3>
                <InputSearch
                  placeholder={t('search')}
                  styleType="fill"
                  value={filters.search}
                  onSearch={(search) => setFilters({ search, page: 1 })}
                  isClearable
                />
              </Space>

              <SortBy
                options={sortOptions}
                value={filters?.ordering}
                onChange={(ordering) => setFilters({ ordering })}
              />
            </Space>
          </Card.Header>
          <Card.Body className="p-0">
            <Loader loading={debtorsLoading}>
              <Table className="table-no-border" columns={columns} data={debtors?.results} />
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Pagination data={debtors} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </AdherentLayout>
    </Layout>
  );
};
