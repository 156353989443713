import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Button, Form, Input, Modal, Space, useForm, useNotify, Row, Col } from 'ebs-design';
import { nomenclature } from 'api';
import { extractResponseProps } from 'utils';
import { FullPenalty } from 'types';

type Props = {
  penaltyId?: number;
  onClose: () => void;
};

export const PenaltyFormModal = ({ penaltyId, onClose }: Props) => {
  const { t } = useIntl();
  const notify = useNotify();
  const [form] = useForm();
  const queryClient = useQueryClient();

  const [checkedPenalty, setCheckedPenalty] = React.useState<{
    [key: string]: boolean | undefined;
  }>({});

  const { data } = useQuery(['penalty', { id: penaltyId }], nomenclature.getPenaltyById, {
    enabled: !!penaltyId,
  });

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        rate: data?.penalty?.percent || 0,
        daily: data?.penalty?.daily?.percent || 0,
        amount: data?.penalty?.daily?.currency?.amount || 0,
      });

      setCheckedPenalty({
        rate: data?.penalty?.state,
        daily: data?.penalty?.daily?.state,
        currency: data?.penalty?.daily?.currency?.state,
      });
    }
  }, [data]);

  const { mutate, isLoading: isLoadingMutate } = useMutation(
    (data: Partial<FullPenalty>) =>
      penaltyId ? nomenclature.updatePenalty(penaltyId, data) : nomenclature.createPenalty(data),

    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['penalties']);
        notify.success({ title: t('rates'), description: t('success_data_save') });
        onClose();
      },
    },
  );

  const handleRadioChange = (name, e: React.SyntheticEvent<HTMLInputElement>) => {
    setCheckedPenalty({ [name]: e.currentTarget.checked });
  };

  const handleSubmit = ({ name, rate, daily, amount }) => {
    mutate({
      name: name,
      penalty: {
        penalty_from_contract:
          !!checkedPenalty?.rate || !!checkedPenalty?.daily || !!checkedPenalty?.currency,
        percent: rate || 0,
        state: !!checkedPenalty?.rate,
        rate: {
          percent: data?.penalty?.rate?.percent,
          state: !!checkedPenalty?.rate,
        },
        daily: {
          percent: daily || 0,
          state: !!checkedPenalty?.daily,
          currency: {
            amount: amount || 0,
            code_name: 'MDL',
            state: !!checkedPenalty?.currency,
          },
        },
      },
    });
  };

  return (
    <Modal
      size="small"
      header={`${t(!penaltyId ? 'add' : 'edit')} ${t('penalty')}`}
      open={true}
      onClose={onClose}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Content>
          <Row>
            <Col size={6}>
              <Form.Field label={t('name')} name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Field>
            </Col>
            <Col size={6}>
              <div className="pc-penalties__settings">
                <h5 className="pc-penalties__settings__label">
                  {t('set_penalties')} <span className="ebs-form__field__required">*</span>
                </h5>
                <Space size={10}>
                  <input
                    className="pc-radio"
                    type="radio"
                    name="penalty"
                    defaultChecked={checkedPenalty['rate']}
                    onChange={(e) => handleRadioChange('rate', e)}
                  />
                  <Form.Field name="rate" rules={[{ required: !!checkedPenalty['rate'] }]}>
                    <Input type="text" size="small" />
                  </Form.Field>
                  <span className="pc-penalties__input-label">{`% + ${t('nbm_rate')}`}</span>
                </Space>

                <Space size={10}>
                  <input
                    className="pc-radio"
                    type="radio"
                    name="penalty"
                    defaultChecked={checkedPenalty['daily']}
                    onChange={(e) => handleRadioChange('daily', e)}
                  />
                  <Form.Field name="daily" rules={[{ required: !!checkedPenalty['daily'] }]}>
                    <Input type="text" size="small" />
                  </Form.Field>
                  <span className="pc-penalties__input-label">% {t('per_day')}</span>
                </Space>

                <Space size={10}>
                  <input
                    className="pc-radio"
                    type="radio"
                    name="penalty"
                    defaultChecked={checkedPenalty['currency']}
                    onChange={(e) => handleRadioChange('currency', e)}
                  />
                  <Form.Field name="amount" rules={[{ required: !!checkedPenalty['currency'] }]}>
                    <Input type="text" size="small" />
                  </Form.Field>

                  <span className="pc-penalties__input-label">{`${
                    data?.penalty?.daily?.currency?.code_name ?? ''
                  } ${t('per_day')}`}</span>
                </Space>
              </div>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onClose}>{t('cancel')}</Button>

            <Button type="primary" submit loading={isLoadingMutate} disabled={isLoadingMutate}>
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
