import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Tabs } from 'ebs-design';
import { HistoryTable } from 'components';
import { HistoryModels } from 'types';

interface AdherentHistoryProps {
  id?: number;
  onClose: () => void;
}

export const AdherentHistory: React.FC<AdherentHistoryProps> = ({ id, onClose }) => {
  const { t } = useIntl();

  const [open, setOpen] = React.useState(true);
  const [active, setActive] = React.useState(HistoryModels.COMPANY);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const historyTabs = React.useMemo(
    () => [
      {
        label: <span>{t('files')}</span>,
        key: HistoryModels.ATTACHMENT,
        content: (
          <HistoryTable
            model={HistoryModels.ATTACHMENT}
            columns={['url', 'name', 'signed', 'signed_timestamp', 'edited_timestamp']}
            query={{
              company_id: id,
              users_company: true,
            }}
          />
        ),
      },
      {
        label: <span>{t('users')}</span>,
        key: HistoryModels.USER,
        content: (
          <HistoryTable
            model={HistoryModels.USER}
            columns={[
              'state',
              'is_superuser',
              'is_staff',
              'is_active',
              'last_login',
              'date_joined',
              'bonus_percentage',
              'phone',
              'idnp',
              'email',
              'first_name',
              'last_name',
              'username',
            ]}
            query={{
              company_id: id,
              users_company: true,
            }}
          />
        ),
      },
      {
        label: <span>{t('company')}</span>,
        key: HistoryModels.COMPANY,
        content: (
          <HistoryTable
            model={HistoryModels.COMPANY}
            columns={[
              'state',
              'complete',
              'code',
              'idno',
              'title',
              'iban',
              'status',
              'type',
              'category',
              'position',
              'status',
            ]}
            query={{
              company_id: id,
              users_company: true,
            }}
          />
        ),
      },
      {
        label: <span>{t('invoices')}</span>,
        key: HistoryModels.INVOICE,
        content: (
          <HistoryTable
            model={HistoryModels.INVOICE}
            columns={[
              'assignment_date',
              'delivery_date',
              'payment_date',
              'signed_date',
              'recession_date',
              'issuing_date',
              'term_date',
              'term_number',
              'series',
              'state',
              'sync_ready',
              'status',
              'confirmed',
              'conditions',
              'amount',
              'balance',
              'collected',
              'commission',
              'verified',
              'number',
              'warrant',
              'contract',
              'guarantee',
              'minimum',
              'payed',
              'penalty',
            ]}
            query={{
              company_id: id,
              users_company: true,
            }}
          />
        ),
      },
      {
        label: <span>{t('requests')}</span>,
        key: HistoryModels.REQUEST,
        content: (
          <HistoryTable
            model={HistoryModels.REQUEST}
            columns={['limit', 'conditions', 'status', 'committee_json', 'factoring_range']}
            query={{
              company_id: id,
              users_company: true,
            }}
          />
        ),
      },
      {
        label: <span>{t('comments')}</span>,
        key: HistoryModels.COMMENT,
        content: (
          <HistoryTable
            model={HistoryModels.COMMENT}
            columns={['comment']}
            query={{
              company_id: id,
              users_company: true,
            }}
          />
        ),
      },
      {
        label: <span>{t('invitations')}</span>,
        key: HistoryModels.INVITE,
        content: (
          <HistoryTable
            model={HistoryModels.INVITE}
            columns={['data', 'email', 'first_name', 'last_name']}
            query={{
              company_id: id,
              users_company: true,
            }}
          />
        ),
      },
    ],
    [t, id],
  );

  return (
    <Modal
      open={open && !!id}
      size="large"
      title={t('history')}
      className="pc-history-modal"
      onClose={handleClose}
    >
      <Modal.Content className="p-0 pc-history-table">
        <Tabs activeTab={active} setActiveTab={(value) => setActive(value as HistoryModels)}>
          {[...historyTabs].map(({ key, ...item }) => (
            <Tabs.Tab key={key} tabKey={key} {...item} />
          ))}
          {historyTabs.map((item) => (
            <Tabs.Panel key={item.key} tabKey={item.key}>
              {item.content}
            </Tabs.Panel>
          ))}
        </Tabs>
      </Modal.Content>
    </Modal>
  );
};
