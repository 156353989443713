export const roles = {
  ADMIN: {
    en: `Administrator`,
    ro: `Administrator`,
    ru: `Администратор`,
  },
  ADMINISTRATOR: {
    en: `Administrator`,
    ro: `Administrator`,
    ru: `Администратор`,
  },
  ADHERENT: {
    en: `Adherent `,
    ro: `Aderent`,
    ru: `Сторонник`,
  },
  DEBTOR: {
    en: `Debtor`,
    ro: `Debitor`,
    ru: `Должник`,
  },
  BENEFICIARY: {
    en: `Beneficiary`,
    ro: `Beneficiar`,
    ru: `Бенефициар`,
  },
  REPRESENTATIVE: {
    en: `Representative`,
    ro: `Reprezentant`,
    ru: `Представитель`,
  },
  CONTACT: {
    en: `Contact person`,
    ro: `Persoană de contact`,
    ru: `Контакт`,
  },
  MANAGER: {
    en: `Manager`,
    ro: `Manager`,
    ru: `Менеджер`,
  },
  FOUNDER: {
    en: `Founder`,
    ro: `Fondator`,
    ru: `Основатель`,
  },
  ACCOUNTANT: {
    en: `Accountant`,
    ro: `Contabil`,
    ru: `Бухгалтер`,
  },
  RESPONSIBLE: {
    en: `Responsible person`,
    ro: `Persoana responsabilă`,
    ru: `Ответственное лицо`,
  },
  CHIEF_ACCOUNTANT: {
    en: `Chief accountant`,
    ro: `Contabil șef`,
    ru: `Главный бухгалтер`,
  },
  USERS: {
    en: `Users `,
    ro: `Utilizatori`,
    ru: `Пользователи`,
  },
  SY_ROOT: {
    en: `Super Admin`,
    ro: `Super Admin`,
    ru: `Супер Admin`,
  },
  SY_ADMIN: {
    en: `Administrator`,
    ro: `Administrator`,
    ru: `Администратор`,
  },
  SY_COLLECTOR: {
    en: `Collector`,
    ro: `Colector`,
    ru: `Коллектор`,
  },
  SY_CREDIT_OFFICER_SUPERIOR: {
    en: `Credit officer superior`,
    ro: `Ofițer de credit superior`,
    ru: `Начальник кредитного специалиста`,
  },
  SY_CREDIT_OFFICER: {
    en: `Credit officer`,
    ro: `Ofițer de credit`,
    ru: `Кредитный специалист`,
  },
  SY_RISK_MANAGER: {
    en: `Risk manager`,
    ro: `Manager de risc`,
    ru: `Риск-менеджер`,
  },
  SY_CREDIT_COMMITTEE: {
    en: `Credit committee`,
    ro: `Comitet de credit`,
    ru: `Кредитный комитет`,
  },
  SY_IT_MANAGER: {
    en: `IT manager`,
    ro: `Manager IT`,
    ru: `IT менеджер`,
  },
};
