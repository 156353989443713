import * as React from 'react';
import cn from 'classnames';
import { Card, Icon } from 'ebs-design';

interface Props {
  className?: string;
  icon: React.ReactNode;
  title: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
}

export const DashboardCard: React.FC<Props> = ({
  icon,
  title,
  className,
  style,
  disabled,
  onClick,
}) => {
  return (
    <Card
      className={cn('dashboard-card', className, { 'dashboard-card--disabled': disabled })}
      onClick={!disabled ? onClick : undefined}
    >
      <div className="dashboard-card__cover" style={style}>
        <div className="dashboard-card__icon">
          <Icon component={icon} />
        </div>
        <div className="dashboard-card__title">{title}</div>
        <div className="dashboard-card__cover-container" />
      </div>
    </Card>
  );
};
