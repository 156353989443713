import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import { Table, Loader, Card } from 'ebs-design';
import { nomenclature } from 'api';

import { formatCategoryRates, formatNumber, getCategoryRatesByType } from 'utils';

export const NomenclatureRates = () => {
  const { t } = useIntl();

  const { data, isLoading } = useQuery(
    ['nomenclatures', 'rates'],
    () => nomenclature.getCategoriesRatesList(),
    {
      select: (data) => ({
        regression: formatCategoryRates(getCategoryRatesByType(data)),
      }),
    },
  );

  const columns = [
    {
      title: t('risk_category'),
      className: 'text-center',
      render: ({ category_name, rates_length }) => ({
        children: category_name,
        props: { rowSpan: rates_length },
      }),
    },
    {
      title: t('days'),
      className: 'text-center',
      render: ({ value }) => (value >= 0 ? `${value} ${t('days')}` : '-'),
    },
    {
      title: t('commission'),
      dataIndex: 'percent',
      className: 'text-center',
      render: (percent) => `${formatNumber(percent)}%`,
    },
    {
      title: t('guarantee'),
      dataIndex: 'guarantee',
      className: 'text-center',
      render: (guarantee) => `${formatNumber(guarantee)}%`,
    },
    {
      title: `${t('commission')} + ${t('guarantee')}`,
      dataIndex: 'gc_percent',
      className: 'text-center',
      render: (gc_percent) => `${formatNumber(gc_percent)}%`,
    },
  ];

  return (
    <Card className="pc-rates__card mb-25" collapsible>
      <Card.Header bordered>
        <h4>{t('standard_rates')}</h4>
      </Card.Header>
      <Card.Body className="p-0">
        {isLoading ? (
          <Loader loading={isLoading} />
        ) : (
          <Table className="pc-rates__table-standard" columns={columns} data={data?.regression} />
        )}
      </Card.Body>
    </Card>
  );
};
