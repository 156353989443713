import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Space,
  AvatarInline,
  Label,
  Card,
  Table,
  Loader,
  Actions,
  Icon,
  Alert,
  firstLetters,
  useNotify,
} from 'ebs-design';
import { useMutation, useQueryClient } from 'react-query';
import { company } from 'api';
import { UserContext } from 'contexts';
import { CustomerRole, Column } from 'types';
import { dateTimeFormat, formatDate } from 'libs';
import { extractResponseProps } from 'utils';
import { useCompanyUsers } from 'hooks';
import { Plus, ArrowRight, ArrowLeft } from 'resources';
import { AddUserModal } from './AddUserModal';
import { FormWizardContext } from '../FormWizard';

const UsersData: React.FC = () => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const { user } = React.useContext(UserContext);
  const ctx = React.useContext(FormWizardContext);
  const [visible, setVisible] = React.useState(false);
  const companyUsers = useCompanyUsers({
    roles__in: [
      CustomerRole.REPRESENTATIVE,
      CustomerRole.ACCOUNTANT,
      CustomerRole.ADMINISTRATOR,
    ].join('__'),
    is_active: true,
    limit: 50,
  });

  // It's used to edit selected user
  const [userId, setUserId] = React.useState<number>();

  // Handle remove user
  const deleteUser = useMutation(
    (userId: number) => company.deleteUser({ companyId: user!.company?.id, userId }),
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company-users');
        notify.success({ title: t('company_users'), description: t('success_data_delete') });
      },
    },
  );

  const isSomeExcludeAttachment = React.useMemo(
    () =>
      companyUsers.data?.results.some(
        (el) => el.roles.includes(CustomerRole.ADMINISTRATOR) && !el.passport.attachment,
      ),
    [companyUsers.data],
  );

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('name'),
        filter: 'first_name',
        render: ({ first_name, last_name, email }) => {
          const title = [first_name, last_name].join(' ');

          return (
            <AvatarInline
              circle
              alt={title}
              shortAlt={((title && firstLetters(title)) || email[0]).toUpperCase()}
              description={email}
            />
          );
        },
        width: 250,
      },
      {
        title: t('role'),
        filter: 'role',
        dataIndex: 'roles',
        key: 'roles',
        render: (roles: string[]) => <span>{Array.isArray(roles) ? roles.join(', ') : '---'}</span>,
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        key: 'is_active',
        render: (status: boolean) => (
          <Label
            type="fill"
            status={status ? 'success' : 'danger'}
            circle
            text={t(status ? 'active' : 'inactive')}
          />
        ),
      },
      {
        title: t('last_activity'),
        dataIndex: 'last_login',
        key: 'last_login',
        render: (date: string) => (date ? formatDate(date, dateTimeFormat) : '---'),
      },
      {
        title: t('info'),
        key: 'passport_attachments',
        render: (record) =>
          !record?.passport?.attachments?.length ? t('administrator_required_identity_card') : null,
      },
      {
        title: null,
        action: true,
        render: (rowData) => {
          return (
            <Actions>
              <Actions.Item
                onClick={() => {
                  if (user?.id === rowData.id) {
                    push('/profile?go_to=/company-form?step=3');
                  } else {
                    setUserId(rowData.id);
                    setVisible(true);
                  }
                }}
              >
                {t('edit')}
              </Actions.Item>
              <Actions.Item onClick={() => deleteUser.mutate(rowData.id)}>
                {t('delete')}
              </Actions.Item>
            </Actions>
          );
        },
      },
    ],
    [t, deleteUser, user, push],
  );

  return (
    <div className="company-form__users">
      <Card className="mb-25">
        <Card.Header bordered>
          <Space justify="space-between">
            <h4 className="card-title">{t('users_data')}</h4>
            <Button prefix={<Icon component={Plus} />} onClick={() => setVisible(true)}>
              {t('add_new')}
            </Button>
          </Space>
        </Card.Header>
        <Card.Body className="p-0">
          <Loader loading={companyUsers.isLoading}>
            <Table
              size="big"
              className="table-no-border"
              columns={columns}
              data={companyUsers.data?.results}
            />
          </Loader>
        </Card.Body>
      </Card>

      <AddUserModal
        visible={visible}
        onClose={() => {
          setVisible((v) => !v);
          setUserId(undefined);
        }}
        title={t(userId ? 'edit_user' : 'users_data')}
        userId={userId}
      />

      {companyUsers.data?.results.length === 0 && (
        <Alert type="warning" message={t('users_warning')} className="mb-25" />
      )}

      {isSomeExcludeAttachment && (
        <Alert type="warning" message={t('admin_warning')} className="mb-25" />
      )}

      <Card className="px-20 py-15 mb-25">
        <Space justify="end">
          <Button type="ghost" className="text-nowrap" onClick={() => ctx.prevStep()}>
            <span className="company-btn__arrow mr-10">
              <ArrowLeft />
            </span>
            {t('prev_step')}
          </Button>
          <Button
            type="primary"
            className="text-nowrap"
            disabled={companyUsers.data?.results.length === 0 || isSomeExcludeAttachment}
            onClick={() => ctx.nextStep()}
          >
            {t('next_step')}
            <span className="company-btn__arrow ml-10">
              <ArrowRight />
            </span>
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default UsersData;
