import { CustomerRole, SystemRole } from 'types';

export default {
  read: {
    invoices: [
      SystemRole.SY_ADMIN,
      SystemRole.SY_RISK_MANAGER,
      CustomerRole.ADMINISTRATOR,
      CustomerRole.ACCOUNTANT,
      CustomerRole.ADHERENT,
      CustomerRole.BENEFICIARY,
      CustomerRole.CHIEF_ACCOUNTANT,
      CustomerRole.CONTACT,
      CustomerRole.DEBTOR,
      CustomerRole.MANAGER,
      CustomerRole.REPRESENTATIVE,
      CustomerRole.RESPONSIBLE,
    ],
    sales: [SystemRole.SY_ADMIN],
    portfolio: [SystemRole.SY_ADMIN],
    register_of_payments: [SystemRole.SY_ADMIN],
  },
};
