import * as React from 'react';
import { Modal, Button, Space, Icon, Form, useForm, useNotify } from 'ebs-design';
import { ModalProps } from 'ebs-design/dist/components/organisms/Modal/Modal';
import { useIntl } from 'estafette-intl';
import { useMutation, useQueryClient } from 'react-query';

import { CompanyManagement, SystemRole } from 'types';
import { users, company } from 'api';
import { extractResponseProps } from 'utils';

import { SmartSelect } from '../SmartSelect';

interface ChangeManagerModalProps extends ModalProps {
  manager?: CompanyManagement | null;
}

export const ChangeManagerModal = ({ manager, onClose, ...props }: ChangeManagerModalProps) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose?.();
    form.resetFields();
  };

  const mutation = useMutation(
    (data: ChangeManagerModalProps) =>
      company.updateManagement(manager?.company, manager?.id, data),
    {
      onSuccess: () => {
        notify.success({ title: t('company'), description: t('success_data_change') });
        handleClose();
        queryClient.invalidateQueries(['company', 'management', manager?.company]);
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  return (
    <Modal open={!!manager} onClose={handleClose} {...props}>
      <Modal.Content>
        <Form form={form} onFinish={mutation.mutate}>
          <Form.Field
            name="user_id"
            label={t('user')}
            initialValue={manager?.user?.id}
            rules={[{ required: true }]}
          >
            <SmartSelect
              api={users.getList}
              filters={{ roles: [SystemRole.SY_CREDIT_OFFICER] }}
              transform={(results) =>
                results.map(({ id, first_name, last_name }) => ({
                  value: id,
                  text: ((first_name || last_name) && `${first_name} ${last_name}`) || '---',
                }))
              }
              enabled={!!manager}
              isClearable={false}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleClose}> {t('cancel')} </Button>

          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={form.submit}
            loading={mutation.isLoading}
          >
            {t('send')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
