import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { formatNumber } from 'utils';
import { InvoicesTurnover } from 'types';

interface PaymentRowProps {
  data: InvoicesTurnover;
  hasActions: boolean;
}

export const PaymentRow = ({
  data,
  hasActions,
  children,
  ...props
}: React.PropsWithChildren<PaymentRowProps>) => {
  const { t } = useIntl();

  return (
    <>
      <tr {...props} style={{ verticalAlign: 'top' }}>
        {children}
      </tr>
      <tr key={`${props['data-row-key']}-1`} className="rc-table-row pc-table__subrows__total">
        <td className="rc-table-cell" colSpan={2} />
        <td className="rc-table-cell fw-700">{t('total')}</td>
        <td className="rc-table-cell" colSpan={3} />
        <td className="rc-table-cell text-right fw-700" style={{ width: '160px' }}>
          {formatNumber(data?.report_turnovers?.totals?.total_collected)}
        </td>
        <td className="rc-table-cell text-right fw-700">
          {formatNumber(data?.report_turnovers?.totals?.total_warranty)}
        </td>
        <td className="rc-table-cell text-right fw-700">
          {formatNumber(data?.commission?.amount)}
        </td>
        <td className="rc-table-cell text-right fw-700">
          {formatNumber(data?.report_turnovers?.totals?.total_penalty)}
        </td>
        <td className="rc-table-cell text-right fw-700">
          {data?.report_turnovers?.totals?.total_days}
        </td>
        {hasActions && <td className="rc-table-cell" />}
      </tr>
    </>
  );
};
