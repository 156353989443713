import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Collapse, Space, AvatarInline, Form, useForm, Loader } from 'ebs-design';
import { Option } from 'ebs-design/dist/components/molecules/Select/interfaces';
import { SmartSelect } from 'components';
import { profile } from 'api';
import { Properties, SettingType } from 'types';

const checkModes = [
  SettingType.CHECK_CATEGORY_FIRST_VALUE,
  SettingType.CHECK_CATEGORY_SECOND_VALUE,
];
const current = new Date().getFullYear();

export const NegativeHistory: React.FC = () => {
  const { t } = useIntl();
  const [form] = useForm();

  const { data, isLoading } = useQuery(['settings'], () => profile.getSettings({ limit: 1000 }));

  // Save settings
  const userSettings = useMutation<unknown, unknown, Properties>((data) =>
    profile.setSettings(data),
  );

  React.useEffect(() => {
    // Map settings value to the form
    if (data && !isLoading) {
      const settings = {};
      data.results.forEach(
        (setting) =>
          checkModes.includes(setting.code_name) &&
          (settings[setting.code_name] = {
            years: setting.value,
            categories: setting.values,
          }),
      );

      form.setFieldsValue(settings);
    }
  }, [data, form, isLoading]);

  const optionsYears: Option[] = React.useMemo(
    () => [
      ...Array(5)
        .fill(null)
        .map((_, i) => {
          const value = current - i - 1;

          return { value, text: value };
        })
        .reverse(),
      { value: current, text: current },
    ],
    [],
  );

  const optionsCategories: Option[] = React.useMemo(
    () => [
      ...Array(6)
        .fill(null)
        .map((_, i) => {
          const value = i + 1;

          return {
            value: value.toString(),
            text: (
              <AvatarInline
                type="primary"
                className={`pc-notifications--type-${value}`}
                shortAlt={value.toString()}
                alt={`${t('category')} +${value * 30 + 1} ${t('days')}`}
              />
            ),
          };
        }),
      {
        value: 'G',
        text: <AvatarInline type="primary" shortAlt="G" alt={t('account_given_at_collection')} />,
      },
      {
        value: 'L',
        text: <AvatarInline type="primary" shortAlt="L" alt={t('off_balance_sheet')} />,
      },
    ],
    [t],
  );

  const handleFinish = React.useCallback(
    (values) => {
      const key = Object.keys(values)[0];

      userSettings.mutate({
        code_name: key,
        ...(values[key].years && { value: values[key].years }),
        ...(values[key].categories && { values: values[key].categories }),
      });
    },
    [userSettings],
  );

  return (
    <Collapse className="pc-notifications overflow-visible">
      <Collapse.Header>{t('display_negative_history')}</Collapse.Header>
      <Collapse.Body>
        <Loader loading={isLoading}>
          <Form form={form} onValuesChange={handleFinish} type="vertical">
            <Space direction="vertical" align="start">
              <Space>
                <Space size="small" direction="vertical" className="no-wrap">
                  <> </>
                  {t('if_company_has_latter')}
                </Space>
                <Form.Field
                  label={t('c_years')}
                  name={[SettingType.CHECK_CATEGORY_FIRST_VALUE, 'years']}
                >
                  <SmartSelect
                    isClearable={false}
                    options={optionsYears}
                    className="select-min-width"
                  />
                </Form.Field>
                <Form.Field
                  label={t('categories')}
                  name={[SettingType.CHECK_CATEGORY_FIRST_VALUE, 'categories']}
                >
                  <SmartSelect
                    isClearable={false}
                    options={optionsCategories}
                    mode="multiple"
                    className="pc-notifications__category select-min-width"
                  />
                </Form.Field>
              </Space>
              <Space>
                <Space size="small" direction="vertical" className="no-wrap">
                  <> </>
                  {t('if_company_has_latter')}
                </Space>
                <Form.Field
                  label={t('c_years')}
                  name={[SettingType.CHECK_CATEGORY_SECOND_VALUE, 'years']}
                >
                  <SmartSelect
                    isClearable={false}
                    options={optionsYears}
                    className="select-min-width"
                  />
                </Form.Field>
                <Form.Field
                  label={t('categories')}
                  name={[SettingType.CHECK_CATEGORY_SECOND_VALUE, 'categories']}
                >
                  <SmartSelect
                    isClearable={false}
                    options={optionsCategories}
                    mode="multiple"
                    className="pc-notifications__category select-min-width"
                  />
                </Form.Field>
              </Space>
            </Space>
          </Form>
        </Loader>
      </Collapse.Body>
    </Collapse>
  );
};
