import axios from 'axios';
import { Properties } from 'types';
import { cancelTokenHandler } from 'libs';

const transformResponse = (response: string): Properties => {
  const newData = {};
  const data = JSON.parse(response);

  Object.keys(data).forEach((item) => {
    newData[item] = data[item].response;
  });

  return newData;
};

export const gateway = {
  externals: async (dataBody) =>
    await axios.post(`${process.env.REACT_APP_GATEWAY_API}/externals/`, dataBody, {
      transformResponse,
      cancelToken: cancelToken(gateway.externals.name),
    }),
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(gateway);
