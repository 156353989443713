import * as React from 'react';
import { ButtonGroup, Button } from 'ebs-design';

type OptionValue = string | number | boolean | null;

interface Props {
  options: { text: string; value?: OptionValue }[];
  value?: OptionValue;
  defaultValue?: OptionValue;
  onChange?: (value?: any) => void;
}

export const RadioButtons: React.FC<Props> = ({ options, onChange, ...props }) => {
  const [value, setValue] = React.useState(props.value || props.defaultValue);

  React.useEffect(() => onChange && onChange(value), [value, onChange]);

  return (
    <ButtonGroup className="flex-nowrap">
      {options.map((option, idx) => (
        <Button
          key={`${option.value}-${idx}`}
          type={value === option.value ? 'primary' : 'ghost'}
          onClick={() => setValue(option.value)}
        >
          {option.text}
        </Button>
      ))}
    </ButtonGroup>
  );
};
