import * as React from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { company } from 'api';
import { UserContext } from 'contexts';
import { Properties, Results, User } from 'types';

export const useCompanyUsers = (
  { id: companyId, ...params }: Properties,
  config?,
): UseQueryResult<Results<User>, any> => {
  const { user } = React.useContext(UserContext);
  const { id } = useParams();

  return useQuery(
    ['company-users', { companyId: companyId || id || user?.company?.id, ...params }],
    company.getUsers,
    {
      enabled: !!companyId || !!id || !!user?.company,
      ...config,
    },
  );
};
