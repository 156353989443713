import * as React from 'react';
import { MyProfileLayout } from 'features/profile/MyProfileLayout';
import {
  NegativeHistory,
  NotifyCOSuperior,
  DistributionToAnotherCO,
  AlertAddressing,
} from './types';

export const Notifications: React.FC = () => (
  <MyProfileLayout>
    <NegativeHistory />
    <NotifyCOSuperior />
    <DistributionToAnotherCO />
    <AlertAddressing />
  </MyProfileLayout>
);
