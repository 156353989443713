import * as React from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import {
  Table,
  AvatarInline,
  Card,
  Space,
  Button,
  ButtonGroup,
  InputSearch,
  SortBy,
  Actions,
  Loader,
  firstLetters,
  Label,
  Badge,
  Switch,
  useNotify,
} from 'ebs-design';
import { users, company } from 'api';
import { useQueryParams, useQueryUpdate, useRoles } from 'hooks';
import { formatDate, dateTimeFormat } from 'libs';
import { Results, User, Properties } from 'types';
import { defaultFilters, getSortOptions, extractResponseProps } from 'utils';
import { Pagination, ConfirmationModal } from 'components';

import { AddCustomerModal } from './AddCustomerModal';

enum UserView {
  LIST = 'list',
  INVITATIONS = 'invitations',
}

export const Customers: React.FC = () => {
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const { t } = useIntl();
  const { updateQuery } = useQueryUpdate();
  const { customers } = useRoles();
  const [confirmDelete, setConfirmDelete] = React.useState<User | undefined>();
  const [view, setView] = React.useState<UserView>(UserView.LIST);
  const [sended, setSended] = React.useState(0);
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });
  const notify = useNotify();

  // Add new customer state for the modal form
  const [customer, setCustomer] = useSetState<{ visible: boolean; data: Partial<User<string>> }>({
    visible: false,
    data: {},
  });

  React.useEffect(() => updateQuery(filters), [filters, updateQuery]);

  React.useEffect(() => {
    setFilters({ roles__in: customers.map((customer) => customer.text).join('__') });
  }, [customers, setFilters]);

  const { data, isLoading } = useQuery(['customers', filters], users.getUsers);

  const resendInvitation = useMutation(company.resendInvitationUsers, {
    onSuccess: () => {
      notify.success({ title: t('invitations'), description: t('success_invitations_send') });
    },
  });

  const user = useMutation((params: Properties) => users.update(params), {
    onMutate: async ({ id, ...params }) => {
      const query = ['customers', filters];
      const prevData = queryClient.getQueryData(query) as Results<User>;

      queryClient.setQueryData(query, {
        ...prevData,
        results: prevData.results.map((result) =>
          result.id === id ? { ...result, ...params } : result,
        ),
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onError: ({ response }, _, rollback: any) => {
      if (response?.data) {
        Object.keys(response.data).map((i) =>
          notify.error({ title: i, description: response.data[i].join('<br>') }),
        );
      }

      rollback();
    },
    onSuccess: () => {
      queryClient.invalidateQueries('customers');
    },
  });

  const deleteUser = useMutation((id: number) => users.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('customers');
      notify.success({ title: t('users'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const { data: dataInvitations, isLoading: isLoadingInvitations } = useQuery(
    ['customers_invitations', filters],
    users.getInvitationsList,
  );

  const onChangeStatus = React.useCallback(
    (is_active?: boolean) => setFilters(() => ({ is_active })),
    [setFilters],
  );

  const onResend = React.useCallback(
    (id: number) => {
      setSended(id);
      resendInvitation.mutate(id);
    },
    [resendInvitation],
  );

  const isList = React.useMemo(() => view === UserView.LIST, [view]);

  const columns = React.useMemo(
    () => [
      {
        title: t('name'),
        filters: ['desc', 'asc'],
        filter: 'first_name',
        render: ({ first_name, last_name, patronymic, email }) => {
          const title = [first_name, last_name, patronymic].join(' ');

          return (
            <AvatarInline
              circle
              alt={title}
              shortAlt={(title && firstLetters(title)) || email[0].toUpperCase()}
              description={email}
            />
          );
        },
        width: 250,
      },
      {
        title: t('roles'),
        render: ({ roles }) => roles.map((role) => t(role.name || role)).join(', '),
      },
      {
        title: t('status'),
        render: ({ is_active }) => (
          <Label
            type="fill"
            status={is_active ? 'success' : 'danger'}
            circle
            text={t(is_active ? 'active' : 'inactive')}
          />
        ),
      },
      ...(isList
        ? [
            {
              title: t('registred_at'),
              render: ({ date_joined }) => date_joined && formatDate(date_joined, dateTimeFormat),
            },
          ]
        : []),
      ...(isList
        ? [
            {
              title: t('block'),
              action: true,
              render: (rowData) => (
                <Space>
                  <Switch
                    onChange={() => user.mutate({ id: rowData.id, state: !rowData.state })}
                    checked={!rowData.state}
                  />
                </Space>
              ),
            },
            {
              title: null,
              action: true,
              render: (rowData) => (
                <Actions>
                  <Actions.Item onClick={() => setCustomer({ visible: true, data: rowData })}>
                    {t('edit')}
                  </Actions.Item>
                  <Actions.Item onClick={() => setConfirmDelete(rowData)}>
                    {t('delete')}
                  </Actions.Item>
                </Actions>
              ),
            },
          ]
        : [
            {
              title: null,
              render: (props) => (
                <Space justify="end">
                  <Button
                    type="fill"
                    loading={sended === props.id && resendInvitation.isLoading}
                    onClick={() => onResend(props.id)}
                  >
                    {t('resend')}
                  </Button>
                </Space>
              ),
            },
          ]),
    ],
    [t, setCustomer, user, isList, onResend, sended, resendInvitation.isLoading],
  );

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  const showData = React.useMemo(
    () => ({
      count: (isList ? data?.count : dataInvitations?.count) || 0,
      data: isList ? data : dataInvitations,
    }),
    [isList, data, dataInvitations],
  );

  const onChangeView = React.useCallback(
    (type: UserView) => {
      setView(type);
      setFilters({ page: 1 });
    },
    [setFilters],
  );

  const onAbortDelete = () => setConfirmDelete(undefined);

  const onConfirmDelete = React.useCallback(() => {
    deleteUser.mutate(confirmDelete!.id);

    onAbortDelete();
  }, [deleteUser, confirmDelete]);

  const confirmDeleteTitle = React.useMemo(
    () =>
      `${t('user')} ${[
        confirmDelete?.first_name,
        confirmDelete?.last_name,
        confirmDelete?.patronymic,
      ]
        .filter((i) => i)
        .join(' ')}`,
    [t, confirmDelete],
  );

  return (
    <>
      <Space align="center" justify="space-between" className="mt-5 mb-20 pt-20">
        <Space align="center">
          <h3 className="page-title">
            {t('users')} ({showData.count})
          </h3>
          <InputSearch
            placeholder={t('search')}
            styleType="fill"
            value={filters.search}
            onSearch={(search) => setFilters({ search, page: 1 })}
            isClearable
          />
        </Space>
        <Space align="center">
          {isList && (
            <ButtonGroup>
              <Button
                type={filters?.is_active === undefined ? 'primary' : 'ghost'}
                onClick={() => onChangeStatus()}
              >
                {t('all')}
              </Button>

              <Button
                type={filters?.is_active === true ? 'primary' : 'ghost'}
                onClick={() => onChangeStatus(true)}
              >
                {t('active')}
              </Button>

              <Button
                type={filters?.is_active === false ? 'primary' : 'ghost'}
                onClick={() => onChangeStatus(false)}
              >
                {t('inactive')}
              </Button>
            </ButtonGroup>
          )}

          <ButtonGroup>
            <Button type={isList ? 'primary' : 'ghost'} onClick={() => onChangeView(UserView.LIST)}>
              {t('list')}
            </Button>

            <Badge text={(dataInvitations?.count || 0).toString()} type="danger">
              <Button
                type={!isList ? 'primary' : 'ghost'}
                onClick={() => onChangeView(UserView.INVITATIONS)}
              >
                {t('invitations')}
              </Button>
            </Badge>
          </ButtonGroup>

          <SortBy
            options={sortOptions}
            value={filters?.ordering}
            onChange={(ordering) => setFilters({ ordering })}
          />
        </Space>
      </Space>
      <Card>
        <Card.Body className="p-0">
          <Loader loading={isLoading || isLoadingInvitations}>
            <Table
              className="table-no-border"
              columns={columns}
              data={showData.data?.results}
              emptyCell="---"
            />

            {customer.visible && (
              <AddCustomerModal
                data={customer.data}
                onClose={() => setCustomer({ visible: false, data: {} })}
              />
            )}
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination data={showData.data} filters={filters} setFilters={setFilters} />
        </Card.Footer>
      </Card>
      {confirmDelete && (
        <ConfirmationModal
          title={confirmDeleteTitle}
          description={t('are_you_sure_to_want_delete')}
          onConfirm={onConfirmDelete}
          onCancel={onAbortDelete}
          visible={true}
        />
      )}
    </>
  );
};
