import React from 'react';
import { Row, Col } from 'ebs-design';

export interface BigAvatarProps {
  icon?: React.ReactNode;
  title: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
}

export const BigAvatar: React.FC<BigAvatarProps> = ({ icon, title, subtitle }) => (
  <Row gx={0}>
    <Col>{icon && <div className="pc-big-avatar__icon">{icon}</div>}</Col>

    <Col>
      <h2 className="text-nowrap">{title}</h2>
      {subtitle && <span className="pc-big-avatar__subtitle">{subtitle}</span>}
    </Col>
  </Row>
);
