export const common = {
  edit: {
    en: `Edit`,
    ro: `Editarea`,
    ru: `Редактировать`,
  },
  delete: {
    en: `Delete`,
    ro: `Șterge`,
    ru: `Удалить`,
  },
  contract: {
    en: `Contract`,
    ro: `Contract`,
    ru: `Договор`,
  },
  factoring_contract: {
    en: `Factoring contract`,
    ro: `Contract de factoring`,
    ru: `Договор факторинга`,
  },
  issuing_date: {
    en: `Issuing date`,
    ro: `Data emiterii`,
    ru: `Дата выдачи`,
  },
  status: {
    en: `Status`,
    ro: `Statutul`,
    ru: `Статус`,
  },
  term_date: {
    en: `Term date`,
    ro: `Data termenului`,
    ru: `Срок действия`,
  },
  term_pay: {
    en: `Payment term`,
    ro: `Termen achitare`,
    ru: `Срок оплаты`,
  },
  penalty: {
    en: `Penalty`,
    ro: `Penalizare`,
    ru: `Штраф`,
  },
  rate: {
    en: `Rate`,
    ro: `Rată`,
    ru: `Ставка`,
  },
  currency: {
    en: `Currency`,
    ro: `Valută`,
    ru: `Валюта`,
  },
  type: {
    en: `Type`,
    ro: `Tip`,
    ru: `Тип`,
  },
  factoring_type: {
    en: `Type of factoring`,
    ro: `Tipul de factoring`,
    ru: `Тип факторинга`,
  },
  with_or_without_regression: {
    en: `with or without regression`,
    ro: `cu sau fara regres`,
    ru: `с регрессом или без`,
  },
  with_regression: {
    en: `with regression`,
    ro: `cu regres`,
    ru: `с регрессом`,
  },
  without_regression: {
    en: `Without regression`,
    ro: `fara regres`,
    ru: `без регресса`,
  },
  view_more: {
    en: `View more`,
    ro: `Vezi mai mult`,
    ru: `Посмотреть больше`,
  },
  tax: {
    en: `Tax`,
    ro: `Taxa`,
    ru: `Налог`,
  },
  taxes_paid: {
    en: `Taxes paid`,
    ro: `Taxe achitate`,
    ru: `Уплаченные налоги`,
  },
  commission: {
    en: `Commission`,
    ro: `Comision`,
    ru: `Комиссия`,
  },
  total_commission: {
    en: `Total commission`,
    ro: `Comision total`,
    ru: `Общая комиссия`,
  },
  average_commission: {
    en: `Average commission`,
    ro: `Comision mediu`,
    ru: `Средняя комиссия`,
  },
  money: {
    en: `Money`,
    ro: `Bani`,
    ru: `Деньги`,
  },
  penalties: {
    en: `Penalties`,
    ro: `Penalități`,
    ru: `Штрафы`,
  },
  series: {
    en: `Series`,
    ro: `Serie`,
    ru: `Серия`,
  },
  action: {
    en: `Action`,
    ro: `Acțiune`,
    ru: `Действие`,
  },
  actions: {
    en: `Actions`,
    ro: `Acțiuni`,
    ru: `Действия`,
  },
  adherent: {
    en: `Adherent`,
    ro: `Aderent`,
    ru: `Последователь`,
  },
  search: {
    en: `Search`,
    ro: `Căutare`,
    ru: `Поиск`,
  },
  direct_search: {
    en: `Direct search`,
    ro: `Căutare directă`,
    ru: `Прямой поиск`,
  },
  debtor: {
    en: `Debtor`,
    ro: `Debitor`,
    ru: `Должник`,
  },
  debtor_name: {
    en: `Debtor name`,
    ro: `Numele debitorului`,
    ru: `Имя должника`,
  },
  debtor_data: {
    en: `Debtor data`,
    ro: `Datele debitorului`,
    ru: `Данные должника`,
  },
  unselect: {
    en: `Unselect`,
    ro: `Deselectați`,
    ru: `Отменить выбор`,
  },
  select: {
    en: `Select`,
    ro: `Selectați`,
    ru: `Выбрать`,
  },
  details: {
    en: `Details`,
    ro: `Detalii`,
    ru: `Детали`,
  },
  all: {
    en: `All`,
    ro: `Toate`,
    ru: `Все`,
  },
  active: {
    en: `Active`,
    ro: `Activ`,
    ru: `Активнен`,
  },
  inactive: {
    en: `Inactive`,
    ro: `Inactiv`,
    ru: `Неактивный`,
  },
  offline: {
    en: `Offline`,
    ro: `Deconectat`,
    ru: `Не в сети`,
  },
  name: {
    en: `Name`,
    ro: `Nume`,
    ru: `Имя`,
  },
  date_type: {
    en: `Date type`,
    ro: `Tip dată`,
    ru: `Тип даты`,
  },
  assignment_date: {
    en: `Date assignment`,
    ro: `Data cesionarii`,
    ru: `Дата присвоения`,
  },
  issue_date: {
    en: `Date issue`,
    ro: `Data emiterii`,
    ru: `Дата выпуска`,
  },
  delivery_date: {
    en: `Delivery date`,
    ro: `Data livrarii`,
    ru: `Дата доставки`,
  },
  payment_date: {
    en: `Date payment`,
    ro: `Data achitarii`,
    ru: `Дата оплаты`,
  },
  disabled_date: {
    en: `Disabled date`,
    ro: `Data dezactivare`,
    ru: `Дата отключения`,
  },
  sold: {
    en: `Balance`,
    ro: `Sold`,
    ru: `Продано`,
  },
  guarantee_balance: {
    en: `Guarantee balance`,
    ro: `Sold Garanție`,
    ru: `Гарантийный баланс`,
  },
  factoring_commission: {
    en: `Factoring commission`,
    ro: `Comisionul de factoring`,
    ru: `Комиссия факторинга`,
  },
  guaranteed_refund: {
    en: `Guaranteed refund`,
    ro: `Garanție restituită`,
    ru: `Гарантированный возврат`,
  },
  conditions: {
    en: `Conditions`,
    ro: `Condiții`,
    ru: `Условия`,
  },
  condition: {
    en: `Condition`,
    ro: `Condiție`,
    ru: `Условие`,
  },
  mandatory_documents: {
    en: `Mandatory documents`,
    ro: `Acte obligatorii`,
    ru: `Обязательные документы`,
  },
  download: {
    en: `Download`,
    ro: `Descarca`,
    ru: `Скачать`,
  },
  download_report: {
    en: `Download report`,
    ro: `Descărcați raportul`,
    ru: `Скачать отчет`,
  },
  other_documents: {
    en: `Other documents`,
    ro: `Alte documente`,
    ru: `Другие документы`,
  },
  documents_signing: {
    en: `Documents for signing`,
    ro: `Acte spre semnare`,
    ru: `Документы на подпись`,
  },
  electronically_sign: {
    en: `Sign electronically`,
    ro: `Semneaza electronic`,
    ru: `Подпишите электронно`,
  },
  contact_data: {
    en: `Contact Data`,
    ro: `Datele de contact`,
    ru: `Контактные данные`,
  },
  actual_beneficiaries: {
    en: `Actual beneficiaries`,
    ro: `Beneficiari activi`,
    ru: `Активные бенефициары`,
  },
  bank: {
    en: `Bank`,
    ro: `Banca`,
    ru: `Банк`,
  },
  category: {
    en: `Category`,
    ro: `Categorie`,
    ru: `Категория`,
  },
  risk_category: {
    en: `Risk category`,
    ro: `Categoria de risc`,
    ru: `Категория риска`,
  },
  bank_details: {
    en: `Bank details`,
    ro: `Rechizitele bancare`,
    ru: `Банковские реквизиты`,
  },
  iban: {
    en: `IBAN`,
    ro: `IBAN`,
    ru: `IBAN`,
  },
  admin: {
    en: `Administrator`,
    ro: `Administrator`,
    ru: `Администратор`,
  },
  new: {
    en: `New`,
    ro: `Nou`,
    ru: `Новый`,
  },
  limit: {
    en: `Limit`,
    ro: `Limita`,
    ru: `Лимит`,
  },
  approved_limit: {
    en: `Approved limit`,
    ro: `Limita aprobata`,
    ru: `Утвержденный лимит`,
  },
  available_limit: {
    en: `Available Limit`,
    ro: `Limita disponibila`,
    ru: `Доступный предел`,
  },
  factoring_inspector: {
    en: `Factoring inspector`,
    ro: `Inspectorul factoring`,
    ru: `Инспектор факторинга`,
  },

  total_turnover: {
    en: `Total turnover`,
    ro: `Rulajul total`,
    ru: `Общий оборот`,
  },
  average_monthly_turnover: {
    en: `Average monthly turnover`,
    ro: `Rulajul mediu lunar`,
    ru: `Среднемесячный оборот`,
  },
  collected: {
    en: `Total collected`,
    ro: `Total colectat`,
    ru: `Всего собрано`,
  },
  total_penalties: {
    en: `Total penalties`,
    ro: `Total penalități`,
    ru: `Всего штрафов`,
  },
  total_collected_commission: {
    en: `Total collected commission`,
    ro: `Total comision incasat`,
    ru: `Всего собранных комиссионных`,
  },
  total_collected_penalties: {
    en: `Total collected penalties`,
    ro: `Total penalități incasate`,
    ru: `Всего собранных штрафов`,
  },
  total_warranty_returned: {
    en: `Total warranty returned`,
    ro: `Total garanție restituită`,
    ru: `Всего возвращенных гарантий`,
  },
  max_nr_of_remaining_days: {
    en: `Max nr of remaining days`,
    ro: `Nr max de zile restante`,
    ru: `Макс. количество оставшихся дней`,
  },
  amount_of_active_warranty: {
    en: `Amount of active warranty`,
    ro: `Suma garantiei active`,
    ru: `Сумма активной гарантии`,
  },
  idno: {
    en: `IDNO`,
    ro: `IDNO`,
    ru: `IDNO`,
  },
  industry: {
    en: `Industry`,
    ro: `Industria`,
    ru: `Индустрия`,
  },
  location: {
    en: `Location`,
    ro: `Amplasarea`,
    ru: `Место`,
  },
  urban: {
    en: `Urban`,
    ro: `Urban`,
    ru: `Городской`,
  },
  rural: {
    en: `Rural`,
    ro: `Rural`,
    ru: `Сельский`,
  },
  legal_address: {
    en: 'Legal address',
    ro: 'Adresa juridică',
    ru: 'Юридический адрес',
  },
  postal_address: {
    en: 'Postal address',
    ro: 'Adresa poștală',
    ru: 'Почтовый адрес',
  },
  phone: {
    en: `Phone`,
    ro: `Telefon`,
    ru: `Телефон`,
  },
  step: {
    en: `Step`,
    ro: `Pasul`,
    ru: `Шаг`,
  },
  initial_classification: {
    en: `Initial classification`,
    ro: `Clasificare initiala`,
    ru: `Первоначальная классификация`,
  },
  rules: {
    en: `Rules`,
    ro: `Reguli`,
    ru: 'Правила',
  },
  norm: {
    en: `Norm`,
    ro: `Norma`,
    ru: `Норма`,
  },
  weight_score: {
    en: `Weight score`,
    ro: `Greutate`,
    ru: `Оценка веса`,
  },
  accumulated: {
    en: 'Accumulated',
    ro: 'Acumulat',
    ru: 'Накопил',
  },
  email: {
    en: `Email`,
    ro: `Email`,
    ru: `Email`,
  },
  warranty_returned: {
    en: `Warranty returned`,
    ro: `Garantia restituita`,
    ru: `Возвращенная гарантия`,
  },
  registred_at: {
    en: `Registred at`,
    ro: `Inregistrat la`,
    ru: `Дата регистрации`,
  },
  last_activity: {
    en: `Last activity`,
    ro: `Ultima activitate`,
    ru: `Последняя активность`,
  },
  role: {
    en: `Role`,
    ro: `Rol`,
    ru: `Роль`,
  },
  total: {
    en: 'Total',
    ro: 'Total',
    ru: 'Итог',
  },
  roles: {
    en: `Roles`,
    ro: `Roluri`,
    ru: `Роли`,
  },
  other: {
    en: `Other`,
    ro: `Alte`,
    ru: `Другое`,
  },
  general_information: {
    en: `General information`,
    ro: `Informatii generale`,
    ru: `Общая информация`,
  },
  affiliates_company: {
    en: `Affiliates company`,
    ro: `Companii afiliate`,
    ru: `Дочернии компании`,
  },
  rates: {
    en: `Rates`,
    ro: `Tarife`,
    ru: `Тарифы`,
  },
  enterprise_name: {
    en: `Enterprise name`,
    ro: `Denumirea intreprinderii`,
    ru: `Название предприятия`,
  },
  affiliates_type: {
    en: `Affiliates type`,
    ro: `Modul de afiliere`,
    ru: `Тип филиалов`,
  },
  limits_and_rates: {
    en: `Limits and Rates`,
    ro: `Limite si Tarife`,
    ru: `Лимиты и Тарифы`,
  },
  factoring_with_regress_right: {
    en: `Factoring with regress right (By category)`,
    ro: `Factoring cu drept de regres (In dependenta de categorie)`,
    ru: `Факторинг с правом возврата (по категории)`,
  },
  factoring_without_regress_right: {
    en: `Factoring without regress right (By category)`,
    ro: `Factoring fara drept de regres (In dependenta de categorie)`,
    ru: `Факторинг без права на возврат (по категории)`,
  },
  previous: {
    en: `Previous`,
    ro: `Precedent`,
    ru: `Предыдущий`,
  },
  next: {
    en: `Next`,
    ro: `Următor`,
    ru: `Следующий`,
  },
  idnp: {
    en: `IDNP`,
    ro: `IDNP`,
    ru: `IDNP`,
  },
  add: {
    en: `Add`,
    ro: `Adaugă`,
    ru: `Добавить`,
  },
  add_new: {
    en: `Add new`,
    ro: `Adaugă nou`,
    ru: `Добавить новое`,
  },
  general_rates: {
    en: `General rates`,
    ro: `Tarifele generale`,
    ru: `Общие тарифы`,
  },
  no_data: {
    en: `No data`,
    ro: `Nu există date`,
    ru: `Нет данных`,
  },
  no_notify: {
    en: `No notifications`,
    ro: `Nicio notificare`,
    ru: `Нет уведомлений`,
  },
  days: {
    en: `days`,
    ro: `zile`,
    ru: `дней`,
  },
  cdays: {
    en: `Days`,
    ro: `Zile`,
    ru: `Дней`,
  },
  hours: {
    en: `Hours`,
    ro: `Ore`,
    ru: `Часы`,
  },
  minutes: {
    en: `Minutes`,
    ro: `Minute`,
    ru: `Минуты`,
  },
  company: {
    en: `Company`,
    ro: `Companie`,
    ru: `Компания`,
  },
  cod_tva: {
    en: `VAT code`,
    ro: `Cod TVA`,
    ru: `Код НДС`,
  },
  first_name: {
    en: `First name`,
    ro: `Nume`,
    ru: `Имя`,
  },
  last_name: {
    en: `Last name`,
    ro: `Familie`,
    ru: `Фамилия`,
  },
  patronymic: {
    en: `Patronymic`,
    ro: `Patronimic`,
    ru: `Отчество`,
  },
  birthday: {
    en: `Birthday`,
    ro: `Data nașterii`,
    ru: `День рождения`,
  },
  birthplace: {
    en: `Birthplace`,
    ro: `Locul nașterii`,
    ru: `Место рождения`,
  },
  citizenship: {
    en: 'Citizenship',
    ro: 'Cetăţenie',
    ru: 'Гражданство',
  },
  serial_number: {
    en: 'Serial number',
    ro: 'Seria, numărul',
    ru: 'Серийный номер',
  },
  date_receipt: {
    en: 'Date of issue',
    ro: 'Data emiterii',
    ru: 'Дата выдачи',
  },
  receiving_office: {
    en: 'The issuing authority',
    ro: 'Autoritatea emitentă',
    ru: 'Орган выдачи',
  },
  date_signed: {
    en: 'Date signed',
    ro: 'Data semnării',
    ru: 'Дата подписания',
  },
  date_joined: {
    en: 'Date joined',
    ro: 'Data înscrierii',
    ru: 'Дата присоединения',
  },
  is_superuser: {
    en: `Superuser`,
    ro: `Superutilizator`,
    ru: `Суперпользователь`,
  },
  is_staff: {
    en: `Staff`,
    ro: `Personal`,
    ru: `Персонал`,
  },
  state: {
    en: `State`,
    ro: `Stat`,
    ru: `Состояние`,
  },
  available_to: {
    en: 'Expiration date',
    ro: 'Data expirării',
    ru: 'Дата окончания',
  },
  file: {
    en: `File`,
    ro: `Fişier`,
    ru: `Файл`,
  },
  files: {
    en: `Files`,
    ro: `Fişiere`,
    ru: `Файлы`,
  },
  additional_files: {
    en: `Additional files`,
    ro: `Fișiere suplimentare`,
    ru: `Дополнительные файлы`,
  },
  date: {
    en: `Date`,
    ro: `Data`,
    ru: `Дата`,
  },
  limits: {
    en: `Limits`,
    ro: `Limite`,
    ru: `Лимиты`,
  },
  track_history: {
    en: `Track History`,
    ro: `Urmariti Istoric`,
    ru: `История треков`,
  },
  personal_data_responsible_person: {
    en: `Personal data of the responsible person`,
    ro: `Date personale ale persoanei responsabile`,
    ru: `Персональные данные ответственного лица`,
  },
  upload: {
    en: `Upload`,
    ro: `Încarcă`,
    ru: `Загрузить`,
  },
  save: {
    en: `Save`,
    ro: `Salvează`,
    ru: `Сохранить`,
  },
  confirm: {
    en: `Confirm`,
    ro: `Confirm`,
    ru: `Подтвердить`,
  },
  save_draft: {
    en: `Save draft`,
    ro: `Salveaza schita`,
    ru: `Сохранить черновик`,
  },
  send_to_factoring: {
    en: `Send to factoring`,
    ro: `Expediază la factoring`,
    ru: `Отправить на факторинг`,
  },
  send_to_approve: {
    en: `Send to approve`,
    ro: `Trimite spre aprobare`,
    ru: `Отправить на одобрение`,
  },
  send_to_payment: {
    en: `Send for payment`,
    ro: `Trimiteți spre plată`,
    ru: `Отправить на оплату`,
  },
  factoring_type_term: {
    en: `Factoring type and term`,
    ro: `Tipul și termenul de factoring`,
    ru: `Тип и срок факторинга`,
  },
  switch_role: {
    en: `Switch role`,
    ro: `Schimbă rolul`,
    ru: `Сменить роль`,
  },
  administrator: {
    en: `Administrator`,
    ro: `Administrator`,
    ru: `Администратор`,
  },
  not_access: {
    en: `You do not have access to this page.`,
    ro: `Nu aveți acces la această pagină.`,
    ru: `У вас нет доступа к данной странице`,
  },
  cancel: {
    en: `Cancel`,
    ro: `Anulare`,
    ru: `Отмена`,
  },
  next_step: {
    en: `Next`,
    ro: `Pasul următor`,
    ru: `Следующий`,
  },
  prev_step: {
    en: `Previous`,
    ro: `Pasul precedent`,
    ru: `Предыдущий`,
  },
  model_factoring_cotract: {
    en: `Model factoring contract between Adherent and Prime Capital`,
    ro: `Model contract de factoring intre Aderent si Prime Capital`,
    ru: `Типовой договор факторинга между Последователем и Prime Capital`,
  },
  what_needs_for_funding: {
    en: `What needs to be done for funding`,
    ro: `Ce trebuie făcut pentru finanțare`,
    ru: `Что нужно сделать для финансирования`,
  },
  debtor_limit: {
    en: `Debtor limit`,
    ro: `Limita debitor`,
    ru: `Лимит должника`,
  },
  months: {
    en: `months`,
    ro: `luni`,
    ru: `месяцы`,
  },
  years: {
    en: `years`,
    ro: `ani`,
    ru: `годы`,
  },
  c_years: {
    en: `Years`,
    ro: `Ani`,
    ru: `Годы`,
  },
  standard_rates: {
    en: `Standard Rates`,
    ro: `Tarife standarte`,
    ru: `Стандартные тарифы`,
  },
  future_rates: {
    en: `Future Rates`,
    ro: `Tarife viitoare`,
    ru: `Будущие тарифы`,
  },
  history_rates: {
    en: `History Rates`,
    ro: `Tarife istorice`,
    ru: `История тарифов`,
  },
  individual_rates: {
    en: `Individual Rates`,
    ro: `Tarife individuale`,
    ru: `Индивидуальные тарифы`,
  },
  invite_new_user: {
    en: `Invite new user`,
    ro: `Invită utilizator nou`,
    ru: `Пригласить нового пользователя`,
  },
  invite: {
    en: `Invite`,
    ro: `Invita`,
    ru: `Пригласить`,
  },
  enter_data_user_invitation: {
    en: `Enter data for user invitation`,
    ro: `Introduceți date pentru invitația utilizatorului`,
    ru: `Введите данные для приглашения пользователя`,
  },
  blocked: {
    en: `Blocked`,
    ro: `Blocat`,
    ru: `Заблокировано`,
  },
  block: {
    en: `Block`,
    ro: `Blocare`,
    ru: `Блокировать`,
  },
  list: {
    en: `List`,
    ro: `Listă`,
    ru: `Список`,
  },
  invitations: {
    en: `Invitations`,
    ro: `Invitații`,
    ru: `Приглашения`,
  },
  invite_user: {
    en: `Invite user`,
    ro: `Invită utilizator`,
    ru: `Пригласить пользователя`,
  },
  add_comment: {
    en: `Add comment`,
    ro: `Adauga comentariu`,
    ru: `Добавить комментарий`,
  },
  company_management: {
    en: `Company management`,
    ro: `Managementul companiei`,
    ru: `Руководство фирмы`,
  },
  edit_company: {
    en: `Edit company`,
    ro: `Editare companie`,
    ru: `Редактирование компании`,
  },
  edit_user: {
    en: `Edit user`,
    ro: `Editare utilizator`,
    ru: `Редактирование пользователя`,
  },
  add_user: {
    en: `Add user`,
    ro: `Adăugați utilizator`,
    ru: `Добавить пользователя`,
  },
  select_user: {
    en: `Select user`,
    ro: `Selectați utilizator`,
    ru: `Выбрать пользователя`,
  },
  select_status: {
    en: `Select status`,
    ro: `Selectați statutul`,
    ru: `Выберите статус`,
  },
  profile_settings: {
    en: `Profile settings`,
    ro: `Setarile profilului`,
    ru: `Настройки профиля`,
  },
  my_profile: {
    en: `My profile`,
    ro: `Profilul meu`,
    ru: `Мой профиль`,
  },
  alert_settings: {
    en: `Alert settings`,
    ro: `Setări de alertă`,
    ru: `Настройки уведомлений`,
  },
  notification: {
    en: `Notification`,
    ro: `Notificare`,
    ru: `Уведомление`,
  },
  classifiers: {
    en: `Classifiers`,
    ro: `Clasificatoare`,
    ru: `Классификаторы`,
  },
  committee_settings: {
    en: `Committee settings`,
    ro: `Setările comitetului`,
    ru: `Настройки комитета`,
  },
  logout: {
    en: `Logout`,
    ro: `Deconectați-vă`,
    ru: `Выйти`,
  },
  upload_avatar: {
    en: `Upload your picture`,
    ro: `Încarcă poza`,
    ru: `Upload your picture`,
  },
  upload_avatar_condition: {
    en: `The maximum file size allowed is 2.5 MB.`,
    ro: `Dimensiunea maximă permisă a fișierului este de 2.5 MB`,
    ru: `Максимально допустимый размер файла - 2.5 МБ.`,
  },
  current_password: {
    en: `Current password`,
    ro: `Parola actuală`,
    ru: `Текущий пароль`,
  },
  password: {
    en: `Password`,
    ro: `Parola`,
    ru: `Пароль`,
  },
  new_password: {
    en: `New password`,
    ro: `Parolă Nouă`,
    ru: `Новый пароль`,
  },
  change_password: {
    en: `Change password`,
    ro: `Schimbați parola`,
    ru: `Изменить пароль`,
  },
  change_image: {
    en: `Change image`,
    ro: `Schimbați imaginea`,
    ru: `Изменить изображение`,
  },
  user_image: {
    en: `User avatar`,
    ro: `Avatar utilizator`,
    ru: `Аватар пользователя`,
  },
  term: {
    en: `Term`,
    ro: `Termen`,
    ru: `Срок`,
  },
  sms_confirmation: {
    en: `Sms confirmation`,
    ro: `Confirmare SMS`,
    ru: `Смс подтверждение`,
  },
  email_confirmation: {
    en: `Email confirmation`,
    ro: `Confirmare email`,
    ru: `Email подтверждение`,
  },
  confirmation_code_sent: {
    en: `Confirmation code sent to your phone.`,
    ro: `Cod de confirmare a fost trimis pe telefonul dvs.`,
    ru: `Код подтверждения отправлен на ваш телефон.`,
  },
  contact_by_sms: {
    en: `Contact via SMS`,
    ro: `Contacteaza prin SMS`,
    ru: `Связаться по SMS`,
  },
  contact_by_phone: {
    en: `Contact by Phone`,
    ro: `Contacteaza prin Telefon`,
    ru: `Связаться по телефону`,
  },
  contact_by_email: {
    en: `Contact by Email`,
    ro: `Contacteaza prin Email`,
    ru: `Связаться по email`,
  },
  i_am_agree: {
    en: `I agree with Non-disclosure agreement.`,
    ro: `Sunt de acord cu acordul de nedivulgare.`,
    ru: `Я согласен с соглашением о неразглашении.`,
  },
  i_read_and_agree: {
    en: `I have read and accept the terms of use and the agreement for the processing of personal data.`,
    ro: `Am citit si accept termenii de utilizare si acordul de procesare a datelor cu caracter personal.`,
    ru: `Я прочитал и принимаю условия использования и соглашение на обработку персональных данных.`,
  },
  create_account: {
    en: `Create Account`,
    ro: `Creeaza cont`,
    ru: `Зарегистрироваться`,
  },
  select_contact_mode: {
    en: `Select contact mode`,
    ro: `Selectati mod de contact`,
    ru: `Выберите режим контакта`,
  },
  send: {
    en: `Send`,
    ro: `Expediaza`,
    ru: `Отправить`,
  },
  finish: {
    en: 'Finish',
    ro: 'Finalizare',
    ru: 'Завершить',
  },
  request_call: {
    en: `Request a call`,
    ro: `Solicita un apel`,
    ru: `Заказать звонок`,
  },
  not_have_account: {
    en: `Don't have an account?`,
    ro: `Nu aveţi un cont?`,
    ru: `Нет учетной записи?`,
  },
  creact_account_now: {
    en: `Create an account now`,
    ro: `Creează cont acum`,
    ru: `Зарегистрируйтесь сейчас`,
  },
  loggin_in: {
    en: `Logging in`,
    ro: `Logare`,
    ru: `Вход в систему`,
  },
  access_your_account: {
    en: `Access your account`,
    ro: `Accesați contul dumneavoastră`,
    ru: `Войдите в свой аккаунт`,
  },
  remember_me: {
    en: `Remember me`,
    ro: `Tine-ma minte`,
    ru: `Запомните меня`,
  },
  forgot_password: {
    en: `Forgot password?`,
    ro: `Ai uitat parola?`,
    ru: `Забыли пароль?`,
  },
  create_the_account: {
    en: `Create the account`,
    ro: `Creaza contul`,
    ru: `Создать учетную запись`,
  },
  please_enter_personal: {
    en: `Please enter your personal data`,
    ro: `Te rugam sa introduceti date personale`,
    ru: `Пожалуйста, введите ваши личные данные`,
  },
  already_registered: {
    en: `Already registered?`,
    ro: `Deja sunteți înregistrat?`,
    ru: `Уже зарегистрированы?`,
  },
  phone_number: {
    en: `Phone number`,
    ro: `Nr. de telefon`,
    ru: `Номер телефона`,
  },
  confirm_password: {
    en: `Confirm password`,
    ro: `Confirma Parola`,
    ru: `Подтвердите пароль`,
  },
  confirm_password_reset: {
    en: `Confirm password reset`,
    ro: `Confirmare resetarea parolei`,
    ru: `Подтвердите сброс пароля`,
  },
  enter_new_password: {
    en: `Enter new password`,
    ro: `Introduceti parola noua`,
    ru: `Введите новый пароль`,
  },
  code: {
    en: `Code`,
    ro: `Cod`,
    ru: `Код`,
  },
  activate_account: {
    en: `Activate account`,
    ro: `Activează contul`,
    ru: `Активировать аккаунт`,
  },
  password_reset: {
    en: `Password reset`,
    ro: `Resetarea parolei`,
    ru: `Сброс пароля`,
  },
  enter_your_email_to_receive_new_password: {
    en: `Enter your email to receive the new password`,
    ro: `Introduceti email Dvs. pentru a primi parola noua`,
    ru: `Введите свой email, чтобы получить новый пароль`,
  },
  then_enter_new_password: {
    en: `Then enter a new password`,
    ro: `Apoi introduceți o nouă parolă`,
    ru: `Затем введите новый пароль`,
  },
  see_comments: {
    en: `See comments`,
    ro: `Vezi comentariile`,
    ru: `См. Комментарии`,
  },
  comments: {
    en: `Comments`,
    ro: `Comentariile`,
    ru: `Комментарии`,
  },
  hide: {
    en: `Hide`,
    ro: `Ascunde`,
    ru: `Скрыть`,
  },
  no_comments: {
    en: `No comments yet`,
    ro: `Nici un comentariu`,
    ru: `Нет комментариев`,
  },
  add_new_classifier: {
    en: `Add new classifier`,
    ro: `Adauga clasificator nou`,
    ru: `Добавить новый классификатор`,
  },
  edit_classifier: {
    en: `Edit classifier`,
    ro: `Editeaza clasificator`,
    ru: `Изменить классификатор`,
  },
  classifier: {
    en: `Classifier`,
    ro: `Clasificator`,
    ru: `Классификатор`,
  },
  minimum_sum: {
    en: `Minimum sum`,
    ro: `Suma minimă`,
    ru: `Минимальная сумма`,
  },
  minimum_value: {
    en: `Minimum value`,
    ro: `Valoare minima`,
    ru: `Минимальное значение`,
  },
  maximum_value: {
    en: `Maximum value`,
    ro: `Valoare maxima`,
    ru: `Максимальное значение`,
  },
  value: {
    en: `Value`,
    ro: `Valoare`,
    ru: `Значение`,
  },
  units: {
    en: `Units`,
    ro: `Unitati`,
    ru: `Единицы`,
  },
  add_classifier: {
    en: `Add classifier`,
    ro: `Adauga clasificator`,
    ru: `Добавить классификатор`,
  },
  add_category: {
    en: `Add category`,
    ro: `Adauga categorie`,
    ru: `Добавить категорию`,
  },
  minimum_operator: {
    en: `Minimum operator`,
    ro: `Operator minimum`,
    ru: `Минимальный оператор`,
  },
  maximum_operator: {
    en: `Maximum operator`,
    ro: `Operator maximum`,
    ru: `Максимальный оператор`,
  },
  categories: {
    en: `Categories`,
    ro: `Categorii`,
    ru: `Категории`,
  },
  district: {
    en: `District`,
    ro: `Raion`,
    ru: `Округ`,
  },
  city: {
    en: `City`,
    ro: `Oras`,
    ru: `Город`,
  },
  postal_code: {
    en: `Postal code`,
    ro: `Cod postal`,
    ru: `Почтовый индекс`,
  },
  user: {
    en: `User`,
    ro: `Utilizator`,
    ru: `Пользователь`,
  },
  function: {
    en: `Function`,
    ro: `Funcția`,
    ru: `Функция`,
  },
  period: {
    en: `Period`,
    ro: `Perioadă`,
    ru: `Период`,
  },
  choose_period: {
    en: 'Choose period',
    ro: 'Alegeți perioada',
    ru: 'Выберите период',
  },
  from: {
    en: `From`,
    ro: `De la`,
    ru: `От`,
  },
  to: {
    en: `To`,
    ro: `Până la`,
    ru: `До`,
  },
  sign_electronically: {
    en: `Sign electronically`,
    ro: `Semnați electronic`,
    ru: `Подписать электронно`,
  },
  request: {
    en: `Request`,
    ro: `Anexa`,
    ru: `Заявление`,
  },
  sign: {
    en: `Sign`,
    ro: `Semnați`,
    ru: `Подписать`,
  },
  sign_contract: {
    en: `Sign contract`,
    ro: `Semnează contract`,
    ru: `Подписать контракт`,
  },
  signed: {
    en: `Signed`,
    ro: `Semnat`,
    ru: `Подписано`,
  },
  signatures: {
    en: `Signatures`,
    ro: `Semnaturi`,
    ru: `Подписи`,
  },
  edit_category: {
    en: `Edit category`,
    ro: `Editeaza categorie`,
    ru: `Edit category`,
  },
  id: {
    en: `Id`,
    ro: `Id`,
    ru: `Id`,
  },
  category_name: {
    en: `Category name`,
    ro: `Denumire categorie`,
    ru: `Название категории`,
  },
  loading: {
    en: `Loading`,
    ro: `Se încarcă`,
    ru: `Загрузка`,
  },
  tva: {
    en: `TVA`,
    ro: `TVA`,
    ru: `TVA`,
  },
  document: {
    en: `Document`,
    ro: `Document`,
    ru: `Документ`,
  },
  contracts: {
    en: `Contracts`,
    ro: `Contracte`,
    ru: `Контракты`,
  },
  signed_contracts: {
    en: `Signed contracts`,
    ro: `Contracte semnate`,
    ru: `Контракты`,
  },
  unsigned_contracts: {
    en: `UnsignedContracts`,
    ro: `Contracte nesemnate`,
    ru: `Контракты`,
  },
  created_by: {
    en: `Created by`,
    ro: `Creat de`,
    ru: `Создано`,
  },
  modified_date: {
    en: `Modified date`,
    ro: `Modificat`,
    ru: `Дата изменения`,
  },
  receives_tasks: {
    en: `Receives tasks`,
    ro: `Primește sarcini`,
    ru: `Получает задания`,
  },
  no: {
    en: `No`,
    ro: `Nu`,
    ru: `Нет`,
  },
  yes: {
    en: `Yes`,
    ro: `Da`,
    ru: `Да`,
  },
  sum: {
    en: `Sum`,
    ro: `Suma`,
    ru: `Сумма`,
  },
  amount: {
    en: `Amount`,
    ro: `Cantitate`,
    ru: `Количество`,
  },
  percent: {
    en: `Percent`,
    ro: `Procent`,
    ru: `Процент`,
  },
  average_total: {
    en: `Average total`,
    ro: `Total mediu`,
    ru: `Средняя сумма`,
  },
  sales_report: {
    en: `Sales report`,
    ro: `Raport pe vanzari`,
    ru: `Отчет о продажах`,
  },
  activities: {
    en: `Activities`,
    ro: `Activități`,
    ru: `Деятельность`,
  },
  average_sales_report: {
    en: `Average sales report`,
    ro: `Raport mediu de vânzări`,
    ru: `Отчет средних продаж`,
  },
  xml: {
    en: `XML`,
    ro: `XML`,
    ru: `XML`,
  },
  or: {
    en: `or`,
    ro: `sau`,
    ru: `или`,
  },
  create_additional_act: {
    en: `Create an additional act`,
    ro: `Creează un act suplimentar`,
    ru: `Создать дополнительный акт`,
  },
  additional_act: {
    en: `Additional act`,
    ro: `Act suplimentar`,
    ru: `Дополнительный акт`,
  },
  range: {
    en: `Range`,
    ro: `Gamă`,
    ru: `Диапазон`,
  },
  create: {
    en: `Create`,
    ro: `Creează`,
    ru: `Создать`,
  },
  committee: {
    en: `Committee`,
    ro: `Comitet`,
    ru: `Комитет`,
  },
  members: {
    en: `Members`,
    ro: `Membri`,
    ru: `Участники`,
  },
  approval_limit: {
    en: `Approval limit`,
    ro: `Limita aprobarii`,
    ru: `Лимит утверждения`,
  },
  minimum_nr_of_votes_in_order_to_approve_the_decision: {
    en: `Minimum nr of votes in order to approve the decision`,
    ro: `Nr minim de voturi pentru aprobarea deciziei`,
    ru: `Минимальное кол. голосов для принятия решения`,
  },
  add_committee: {
    en: `Add committee`,
    ro: `Adauga comitet`,
    ru: `Добавить комитет`,
  },
  edit_committee: {
    en: `Edit committee`,
    ro: `Editeaza comitet`,
    ru: `Редактировать комитет`,
  },
  committee_name: {
    en: `Committee name`,
    ro: `Denumire comitet`,
    ru: `Название комитета`,
  },
  minimum_nr_of_votes: {
    en: `Minimum nr of votes`,
    ro: `Nr. minim de voturi`,
    ru: `Минимальное кол. голосов`,
  },
  minimum: {
    en: `Minimum`,
    ro: `Minim`,
    ru: `Минимум`,
  },
  maximum: {
    en: `Maximum`,
    ro: `Maxim`,
    ru: `Максимум`,
  },
  fax: {
    en: `Fax`,
    ro: `Fax`,
    ru: `Факс`,
  },
  website: {
    en: `Web site`,
    ro: `Site-ul web`,
    ru: `Веб сайт`,
  },
  date_state_registration: {
    en: `Date of state registration`,
    ro: `Data înregistrării de stat`,
    ru: `Дата государственной регистрации`,
  },
  social_capital: {
    en: `Social capital`,
    ro: `Capitalul social`,
    ru: `Социальный капитал`,
  },
  purpose_nature_of_relationshop: {
    en: `Purpose and nature of relationship with O.C.N. "Prime Capital" SRL`,
    ro: `Scopul și natura relației cu O.C.N. "Prime Capital" SRL`,
    ru: `Цель и характер отношений с O.C.N. "Prime Capital" SRL`,
  },
  confirm_legal_origin_of_funds: {
    en: `We confirm the legal origin of the funds used in the business relations with O.C.N. „Prime Capital" SRL`,
    ro: `Confirmăm proveniența legală a mijloacelor bănești utilizate în relațiile de afaceri cu O.C.N.„Prime Capital" SRL`,
    ru: `Подтверждаем законное происхождение средств, используемых в деловых отношениях с O.C.N. "Prime Capital" SRL.`,
  },
  contract_number: {
    en: `Nr. contract`,
    ro: `Nr. contract`,
    ru: `№ контракта`,
  },
  contract_date: {
    en: `Contract date`,
    ro: `Data contractului`,
    ru: `Дата контракта`,
  },
  are_you_sure_to_want_delete: {
    en: `Are you sure to want delete this?`,
    ro: `Sigur doriți să ștergeți această?`,
    ru: `Вы уверены, что хотите удалить это?`,
  },
  are_you_sure_to_want_decline: {
    en: `Are you sure to want decline this?`,
    ro: `Sigur doriți să refuzați această?`,
    ru: `Вы уверены, что хотите отклонить это?`,
  },
  message: {
    en: `Message`,
    ro: `Mesaj`,
    ru: `Сообщение`,
  },
  information_analyzed: {
    en: `Information analyzed`,
    ro: `Informații analizate`,
    ru: `Проанализированная информация`,
  },
  scoring: {
    en: `Scoring`,
    ro: `Punctaj`,
    ru: `Оценка`,
  },
  formulas: {
    en: `Formulas`,
    ro: `Formule`,
    ru: `Формулы`,
  },
  request_additional_information: {
    en: `Request additional information`,
    ro: `Solicita informatie adaugatoare`,
    ru: `Запросить дополнительную информацию`,
  },
  adherent_status: {
    en: `Adherent status`,
    ro: `Statut aderent`,
    ru: `Статус последователя`,
  },
  existent: {
    en: `Existent`,
    ro: `Existent`,
    ru: `Существующий`,
  },
  somethingWrong: {
    en: `Something went wrong. Please contact technical support.`,
    ro: `Ceva n-a mers bine. Vă rugăm să contactați asistența tehnică.`,
    ru: `Что-то пошло не так. Обратитесь в службу технической поддержки.`,
  },
  choose_column: {
    en: `Choose columns`,
    ro: `Alege coloane`,
    ru: `Выбрать столбцы`,
  },
  choose: {
    en: `Choose`,
    ro: `Alege`,
    ru: `Выбрать`,
  },
  created_request: {
    en: `Request Date`,
    ro: `Data solicitării`,
    ru: `Дата заявки`,
  },
  created_at: {
    en: `Created at`,
    ro: `Creat la`,
    ru: `Создано в`,
  },
  adherent_name: {
    en: `Adherent name`,
    ro: `Denumirea Aderentului`,
    ru: `Имя последователь`,
  },
  report_bns_bic: {
    en: `Report BNS/BIC`,
    ro: `Raport BNS/BIC`,
    ru: `Отчет BNS/BIC`,
  },
  report_info_debit: {
    en: `Report InfoDebit`,
    ro: `Raport InfoDebit`,
    ru: `Отчет InfoDebit`,
  },
  factoring_contract_number: {
    en: `Factoring contract number`,
    ro: `Nr contractului de factoring`,
    ru: `Номер факторного контракта`,
  },
  contract_sign_date: {
    en: `Contract sign date`,
    ro: `Data semnarii contractului`,
    ru: `Дата подписания контракта`,
  },
  expired_contract_date: {
    en: `Expire date of contract`,
    ro: `Date expirarii contractului de factoring`,
    ru: `Срок действия договора факторинга`,
  },
  request_card: {
    en: `Request card`,
    ro: `Cartela solicitarii`,
    ru: `Карточка приложения`,
  },
  ceiling: {
    en: `Ceiling`,
    ro: `Plafonul`,
    ru: `Потолок`,
  },
  ceiling_available: {
    en: `Available ceiling`,
    ro: `Plafonul disponibil`,
    ru: `Доступный потолок`,
  },
  debtor_status: {
    en: `Debtor status`,
    ro: `Statut de debitor`,
    ru: `Статус должника`,
  },
  sign_date: {
    en: `Sign date`,
    ro: `Data semnarii`,
    ru: `Дата подписания`,
  },
  scanned_invoice: {
    en: `Scanned invoice`,
    ro: `Factura scanata`,
    ru: `Отсканированный счет`,
  },
  scanned_contract: {
    en: `Scanned contract`,
    ro: `Contract scanat`,
    ru: `Отсканированный договор`,
  },
  grade_risk_manager: {
    en: `Grade risk manager`,
    ro: `Nota Risc Manager`,
    ru: `Оценка риск-менеджера`,
  },
  change_limit: {
    en: `Change limit`,
    ro: `Modifică limita`,
    ru: `Изменить предел`,
  },
  send_for_approval: {
    en: `Send for approval`,
    ro: `Trimite spre aprobare`,
    ru: `Отправить на утверждение`,
  },
  approve: {
    en: `Approve`,
    ro: `Aprobă`,
    ru: `Одобрить`,
  },
  revote: {
    en: `Revote`,
    ro: `Votează din nou`,
    ru: `Проголосовать еще раз`,
  },
  decline: {
    en: `Decline`,
    ro: `Declină`,
    ru: `Отклонить`,
  },
  history: {
    en: `History`,
    ro: `Istorie`,
    ru: `История`,
  },
  who: {
    en: `Who`,
    ro: `Cine`,
    ru: `Кто`,
  },
  when: {
    en: `When`,
    ro: `Când`,
    ru: `Когда`,
  },
  old: {
    en: `Old`,
    ro: `Vechi`,
    ru: `Старые`,
  },
  update: {
    en: `Update`,
    ro: `Actualizari`,
    ru: `Обновления`,
  },
  view: {
    en: `Update`,
    ro: `Vizualizare`,
    ru: `Просмотры`,
  },
  unknown: {
    en: `Unknown`,
    ro: `Necunoscut`,
    ru: `Неизвестный`,
  },
  changed: {
    en: `Changed`,
    ro: `Schimbat`,
    ru: `Измененный`,
  },
  bonus_earned: {
    en: `Earned bonus`,
    ro: `Bonus câștigat`,
    ru: `Заработанный бонус`,
  },
  edited_timestamp: {
    en: `Updated at`,
    ro: `Editat la`,
    ru: `Отредактировано`,
  },
  signed_timestamp: {
    en: `Signed at`,
    ro: `Semnat la`,
    ru: `Подписан в`,
  },
  title: {
    en: `Title`,
    ro: `Titlu`,
    ru: `Заголовок`,
  },
  reportFactoringPortfolio: {
    en: `Report of factoring portfolio`,
    ro: `Raportul portofoliului de factoring`,
    ru: `Отчет факторингового портфеля`,
  },
  requests_sent: {
    en: `The request has been sent for approval`,
    ro: `Solicitarea a fost trimisa spre aprobare`,
    ru: `Запрос отправлен на согласование`,
  },
  requests_declined: {
    en: `The request has been decline`,
    ro: `Solicitarea a fost declinata`,
    ru: `Запрос был отклонен`,
  },
  request_status: {
    en: `Request status`,
    ro: `Statut solicitare`,
    ru: `Статус запроса`,
  },
  request_stage: {
    en: `Request stage`,
    ro: `Etapa solicitarii`,
    ru: `Этап запроса`,
  },
  propose_limit: {
    en: `Propose limit`,
    ro: `Propune limita`,
    ru: `Предложить предел`,
  },
  additional_deed_assignment: {
    en: `Additional deed of assignment`,
    ro: `Actul adițional de cesiune`,
    ru: `Дополнительный передаточный акт`,
  },
  notification_assignment_receivables: {
    en: `Notification of Assignment of Receivables`,
    ro: `Notificarea cesiunii de creanțe`,
    ru: `Уведомление об уступке дебиторской задолженности`,
  },
  chat: {
    en: `Chat`,
    ro: `Conversație`,
    ru: `Чат`,
  },
  url: {
    en: `URL`,
    ro: `Link`,
    ru: `Ссылка`,
  },
  data: {
    en: `Data`,
    ro: `Date`,
    ru: `Данные`,
  },
  generate_password: {
    en: `Generate password`,
    ro: `Generați parola`,
    ru: `Сгенерировать пароль`,
  },
  file_size_exceeds: {
    en: 'File size exceeds',
    ro: 'Dimensiunea fișierului depășește',
    ru: 'Размер файла превышает',
  },
  kyc_approved: {
    en: `Confirmed KYC`,
    ro: `Confirmat KYC`,
    ru: `Подтвержден KYC`,
  },
  waiting_approval: {
    en: `Waiting for approval`,
    ro: `Se așteaptă confirmarea`,
    ru: `Ожидание подтверждения`,
  },
  confirm_kyc: {
    en: `Confirm KYC`,
    ro: `Confirmarea KYC`,
    ru: `Подтверждение KYC`,
  },
  report_kyc: {
    en: `Report KYC`,
    ro: `Raport KYC`,
    ru: `Отчет KYC`,
  },
  know_your_client: {
    en: `Know your client`,
    ro: `Cunoaște-ți clientul`,
    ru: `Знай своего клиента`,
  },
  additional_information: {
    en: `Additional information`,
    ro: `Informatii suplimentare`,
    ru: `Дополнительная информация`,
  },
  type_of_company_activity: {
    en: `Type of company activity`,
    ro: `Tipul activității companiei`,
    ru: `Вид деятельности компании`,
  },
  add_guarantee: {
    en: `Add refund`,
    ro: `Adăugați rambursare`,
    ru: `Добавить возврат`,
  },
  add_conclusion: {
    en: `Add conclusion`,
    ro: `Adauga concluzia`,
    ru: `Добавить заключение`,
  },
  conclusion: {
    en: `Conclusion`,
    ro: `Concluzia`,
    ru: `Заключение`,
  },
  re_examination: {
    en: 'Re-examination',
    ro: 'Reexaminare',
    ru: 'Повторная экспертиза',
  },
  select_year: {
    en: `Select year`,
    ro: `Selectați anul`,
    ru: `Выберите год`,
  },
  required_field: {
    en: 'This field is required.',
    ro: 'Acest câmp este obligatoriu.',
    ru: 'Это поле обязательно к заполнению.',
  },
  outstanding_days: {
    en: `Outstanding days`,
    ro: `Zile restante`,
    ru: `Оставшиеся дни`,
  },
  person: {
    en: `Person`,
    ro: `Persoană`,
    ru: `Личность`,
  },
  display_negative_history: {
    en: `Displays negative history`,
    ro: `Afișează istoricul negativ`,
    ru: `Отображает отрицательную историю`,
  },
  if_company_has_latter: {
    en: `If Adherent / Debtor has for last`,
    ro: `Dacă Aderentul / Debitorul are in ultimii`,
    ru: `Если у Сторонника / Должника есть за последние`,
  },
  account_given_at_collection: {
    en: `Account given at collection`,
    ro: `Cont dat la colectare`,
    ru: `Аккаунт предоставлен при сборе`,
  },
  off_balance_sheet: {
    en: `Off balance sheet`,
    ro: `Extrabilantier`,
    ru: `Забалансовый отчет`,
  },
  bonus_percentage: {
    en: `Bonus percentage`,
    ro: `Procentul bonus`,
    ru: `Бонусный процент`,
  },
  request_bic: {
    en: `Request BIC`,
    ro: `Solicitați BIC`,
    ru: `Запросить BIC`,
  },
  request_infodebit: {
    en: `Request Infodebit`,
    ro: `Solicitați Infodebit`,
    ru: `Запросить Infodebit`,
  },
  no_data_found: {
    en: `No data found`,
    ro: `Nu s-au gasit date`,
    ru: `Данные не найдены`,
  },
  company_users: {
    en: `Company users`,
    ro: `Utilizatorii companiei`,
    ru: `Пользователи компании`,
  },
  proposed_limits: {
    en: `Proposed limits`,
    ro: `Limitele propuse`,
    ru: `Предлагаемые пределы`,
  },
  missing_bic_data: {
    en: `Missing BIC data`,
    ro: `Date BIC lipsă`,
    ru: `Отсутствуют данные BIC`,
  },
  debtor_invoice_verified: {
    en: `Debtor invoice has verified`,
    ro: `Factura verificata la debitor`,
    ru: `Фактура дебитора проверена`,
  },
  verified: {
    en: `Verified`,
    ro: `Verificat`,
    ru: `Проверено`,
  },
  request_recession: {
    en: `Request recession`,
    ro: `Solicita recesionare`,
    ru: `Запросить рецессию`,
  },
  accept_recession: {
    en: `Accept recession`,
    ro: `Accepta recesionare`,
    ru: `Принять рецессию`,
  },
  refuse_recession: {
    en: `Refuse recession`,
    ro: `Refuza recesionare`,
    ru: `Отклонить рецессию`,
  },
  set_inactive: {
    en: `Set inactive`,
    ro: `Setare inactiv`,
    ru: `Сделать неактивным`,
  },
  set_active: {
    en: `Set active`,
    ro: `Setare activ`,
    ru: `Сделать активным`,
  },
  same_juridical_address: {
    en: `Address is same as juridical one`,
    ro: `Adresa este aceeași cu cea juridică`,
    ru: `Адрес такой же, как и юридический.`,
  },
  key: {
    en: `Key`,
    ro: `Cheie`,
    ru: `Ключ`,
  },
  row: {
    en: `Row`,
    ro: `Rând`,
    ru: `Строка`,
  },
  source: {
    en: `Source`,
    ro: `Sursă`,
    ru: `Источник`,
  },
  indicators: {
    en: `Indicators`,
    ro: `Indicatori`,
    ru: `Индикаторы`,
  },
  edit_indicators: {
    en: `Edit indicators`,
    ro: `Editați indicatorii`,
    ru: `Изменить индикаторы`,
  },
  description: {
    en: `Description`,
    ro: `Descriere`,
    ru: `Описание`,
  },
  formula: {
    en: `Formula`,
    ro: `Formulă`,
    ru: `Формула`,
  },
  back_to_list: {
    en: `Back to list`,
    ro: `Înapoi la listă`,
    ru: `Обратно к списку`,
  },
  back: {
    en: `Back`,
    ro: `Înapoi`,
    ru: `Назад`,
  },
  change_rates: {
    en: `Change rates`,
    ro: `Schimbare ratele`,
    ru: `Изменить ставки`,
  },
  register_of_payments: {
    en: `Register of payments made`,
    ro: `Registrul plăților efectuate`,
    ru: `Реестр произведенных платежей`,
  },
  and: {
    en: `and`,
    ro: `și`,
    ru: `и`,
  },
  contact_person: {
    en: `Contact person`,
    ro: `Persoana de contact`,
    ru: `Контактное лицо`,
  },
  debtor_must_be_selected_or_created: {
    en: `Debtor must be selected or created`,
    ro: `Debitorul trebuie selectat sau creat`,
    ru: `Должник должен быть выбран или создан`,
  },
  token_has_expired: {
    en: `Token has expired`,
    ro: `Token a expirat`,
    ru: `Срок действия токена истек`,
  },
  visit_our_website: {
    en: `Visit our website`,
    ro: `Vizitați site-ul nostru`,
    ru: `Посетите наш сайт`,
  },
  notify_credit_officer_superior: {
    en: `Notify Credit Officer Superior`,
    ro: `Notificare la Credit Officer Superior`,
    ru: `Уведомить старшего кредитного специалиста`,
  },
  if_inspector_does_not_take_action_within: {
    en: `If inspector does not take action within`,
    ro: `Dacă inspectorul nu face acțiuni in decurs de`,
    ru: `Если инспектор не предпримет никаких действий в течение`,
  },
  distribution_to_another_credit_officer: {
    en: `Distribution to another Credit Officer`,
    ro: `Repartizare la alt Credit Officer`,
    ru: `Передача другому кредитному офицеру`,
  },
  if_inspector_does_not_react_to_new_customer_within: {
    en: `If inspector does not react to new customer within`,
    ro: `Dacă inspectorul nu reacționează la client nou în decurs de`,
    ru: `Если инспектор не реагирует на нового клиента в течение`,
  },
  alert_addressing: {
    en: `Alert addressing`,
    ro: `Alert adresanți`,
    ru: `Адресация предупреждений`,
  },
  you_have_not_yet_completed_company_information: {
    en: `You have not yet completed company information`,
    ro: `Nu ați completat încă informațiile despre companie`,
    ru: `Вы еще не заполнили информацию о компании`,
  },
  assignment_of_receivables: {
    en: `Assignment of receivables`,
    ro: `Alocarea creanțelor`,
    ru: `Уступка дебиторской задолженности`,
  },
  annex: {
    en: `Annex`,
    ro: `Anexa`,
    ru: `Приложение`,
  },
  add_contact: {
    en: `Add contact`,
    ro: `Adaugă contact`,
    ru: `Добавить контакт`,
  },
  autocomplete: {
    en: `Autocomplete`,
    ro: `Completare automată`,
    ru: `Автозаполнение`,
  },
  complete_fields_with_data_of_user: {
    en: `Complete fields with data of user`,
    ro: `Completați câmpurile cu datele utilizatorului`,
    ru: `Заполните поля данными пользователя`,
  },
  driver_license: {
    en: `Driver's license`,
    ro: `Permis de conducere`,
    ru: `Водительское удостоверение`,
  },
  internal_passport: {
    en: `Bulletin`,
    ro: `Buletin`,
    ru: `Бюллетень`,
  },
  international_passport: {
    en: `Passport`,
    ro: `Pasaport`,
    ru: `Пасспорт`,
  },
  bulletin: {
    en: `Bulletin`,
    ro: `Buletin`,
    ru: `Бюллетень`,
  },
  passport: {
    en: `Passport`,
    ro: `Pasaport`,
    ru: `Пасспорт`,
  },
  complete: {
    en: `Completed`,
    ro: `Efectuat`,
    ru: `Завершенный`,
  },
  confirmed: {
    en: `Confirmed`,
    ro: `Confirmat`,
    ru: `Подтвержденный`,
  },
  recession_date: {
    en: `Recession date`,
    ro: `Data recesiunii`,
    ru: `Дата рецессии`,
  },
  sync_ready: {
    en: `Sync ready`,
    ro: `Sincronizat`,
    ru: `Синхронизировано`,
  },
  warrant: {
    en: `Warranty`,
    ro: `Garanție`,
    ru: `Гарантия`,
  },
  selected: {
    en: `Selected`,
    ro: `Selectat`,
    ru: `Выбран`,
  },
  until: {
    en: `until`,
    ro: `până la`,
    ru: `до`,
  },
  political_role: {
    en: `Political role`,
    ro: `Rol politic`,
    ru: `Политическая роль`,
  },
  request_increase_in_limit: {
    en: `Request an increase in limit`,
    ro: `Solicita majorarea limitei`,
    ru: `Запросить увеличение лимита`,
  },
  request_call_from_credit_officer: {
    en: `Request a call from credit officer`,
    ro: `Solicita apel de la ofiterul de credite`,
    ru: `Запросите звонок от кредитного специалиста`,
  },
  invoice_exceeds_limit: {
    en: `Invoice exceeds limit`,
    ro: `Factura depaseste limita`,
    ru: `Счет превышает лимит`,
  },
  increase_limit: {
    en: `Increase limit`,
    ro: `Mări limita`,
    ru: `Увеличить лимит`,
  },
  resend: {
    en: `Resend`,
    ro: `Retrimiteți`,
    ru: `Отправить еще раз`,
  },
  searchByIDNP: {
    en: `Search by IDNP`,
    ro: `Căutare după IDNP`,
    ru: `Поиск по IDNP`,
  },
  searchByIDNO: {
    en: `Search by IDNO`,
    ro: `Căutare după IDNO`,
    ru: `Поиск по IDNO`,
  },
  userWithIDNPWasNotFound: {
    en: `User with this IDNP was not found`,
    ro: `Utilizatorul cu acest IDNP nu a fost găsit`,
    ru: `Пользователь с таким IDNP не найден`,
  },
  createNew: {
    en: `Create new`,
    ro: `Creaza nou`,
    ru: `Создать новый`,
  },
  userAlreadyExists: {
    en: `User already exists`,
    ro: `Utilizatorul deja există`,
    ru: `Пользователь уже существует`,
  },
  moldova: {
    en: `Moldova`,
    ro: `Moldova`,
    ru: `Молдова`,
  },
  procure: {
    en: 'Procure',
    ro: `Procură`,
    ru: `доверенность`,
  },
  'additional_data.number': {
    en: 'Number',
    ro: 'Numar',
    ru: 'Номер',
  },
  'additional_data.date': {
    en: 'Date',
    ro: 'Data',
    ru: 'Дата',
  },
  'additional_data.position': {
    en: 'Position',
    ro: 'Poziția',
    ru: 'Должность',
  },
  client_template: {
    en: 'Template:',
    ro: 'Template ',
    ru: '',
  },
  procure_template: {
    en: 'Procure template:',
    ro: 'Template cu procură',
    ru: '',
  },
  client_procure_template: {
    en: 'Client procure template:',
    ro: 'Model cu procură pentru client',
    ru: '',
  },
  prime_client_procure_template: {
    en: 'Common procure template:',
    ro: 'Model cu procură comun',
    ru: '',
  },
  last_active_contract_number: {
    en: 'Last active contract number :',
    ro: 'Ultimul număr de contract activ :',
    ru: 'Номер последнего активного контракта :',
  },
  contracts_number: {
    en: 'Contracts number',
    ro: 'Numărul de contracte',
    ru: 'Номер контрактов',
  },
  order: {
    en: 'Order',
    ro: 'Ordin',
    ru: 'Порядок',
  },
  upload_contract: {
    en: 'Upload contract',
    ro: 'Încarcă contract',
    ru: 'Загрузить контракт',
  },
  enter_data_contract: {
    en: `Enter data of the contract`,
    ro: `Introduceți datele contractului`,
    ru: `Введите контракта`,
  },
  approved_contract: {
    en: 'Approved contract',
    ro: 'Contract aprobat',
    ru: 'Утвержденный контракт',
  },
  delivery_contract: {
    en: 'Delivery contract',
    ro: 'Contract de livrare',
    ru: 'Договор поставки',
  },
  turnovers: {
    en: 'Payments',
    ro: 'Achitări',
    ru: 'Платежи',
  },
  turnover: {
    en: 'payment',
    ro: 'achitare',
    ru: 'платёж',
  },
  edit_turnovers: {
    en: 'Edit payment',
    ro: 'Editați achitarea',
    ru: 'Редактировать платёж',
  },
  create_turnovers: {
    en: 'Create turnover',
    ro: 'Creați achitare',
    ru: 'Создать платёж',
  },
  delete_turnovers: {
    en: 'Delete Turnover',
    ro: 'Ștergeți achitarea',
    ru: 'Удалить платёж',
  },
  delete_turnover_confirm: {
    en: 'Are you sure you want to delete this payment ?',
    ro: 'Sigur doriți să ștergeți această achitare ?',
    ru: 'Вы уверены, что хотите удалить этот платеж ?',
  },
  timestamp: {
    en: 'Timestamp',
    ro: 'Timestamp',
    ru: 'Временная метка',
  },
  payment_type: {
    en: 'Payment type',
    ro: 'Tipul de plată',
    ru: 'Способ оплаты',
  },
  operation_type: {
    en: 'Operation type',
    ro: 'Tipul operațiunii',
    ru: 'Тип операции',
  },
  balance_total: {
    en: 'Balance Total',
    ro: 'Sold Total',
    ru: 'Итог Баланса',
  },
  collected_total: {
    en: 'Collected Total',
    ro: 'Total Colectat',
    ru: 'Всего Собрано',
  },
  penalty_total: {
    en: 'Penalty Total',
    ro: 'Total Penalizări',
    ru: 'Сумма штрафов',
  },
  warranty_total: {
    en: 'Warranty Total',
    ro: 'Total Garanții',
    ru: 'Сумма Гарантий',
  },
  paidOff: {
    en: 'Payd off',
    ro: 'Plătit integral',
    ru: 'Оплачено полностью',
  },
  InvoicePayment: {
    en: `Invoice financing`,
    ro: `Finantare factură`,
    ru: `Финансирование счета`,
  },
  AderentRepayment: {
    en: 'Adherent payment',
    ro: 'Achitare aderent',
    ru: 'Оплата адерента',
  },
  DebitorRepayment: {
    en: 'Debitor repayment',
    ro: 'Achitare debitor',
    ru: 'Оплата дебитора',
  },
  WarrantyRefund: {
    en: 'Warranty refund',
    ro: 'Restituire garanție',
    ru: 'Гарантийный возврат',
  },
  DailyPenalty: {
    en: 'Calculated penalty',
    ro: 'Penalitate calculata',
    ru: 'Рассчитанный штраф',
  },
  WarrantyUse: {
    en: 'Warranty use',
    ro: 'Utilizare garanție',
    ru: 'Гарантийное использование',
  },
  paid_penalty: {
    en: 'Paid penalty',
    ro: 'Penalitate achitată',
    ru: 'Уплаченный штраф',
  },
  penalty_sold: {
    en: 'Penalty sold',
    ro: 'Soldul penalităţii',
    ru: 'Баланс штрафа',
  },
  payments_turn: {
    en: 'Payments in turn',
    ro: 'Plăți pe rând',
    ru: 'Платежи по очереди',
  },
  missing_expiration_date: {
    en: 'Expiration date is missing',
    ro: 'Lipsește data de expirare',
    ru: 'Отсутствует lата окончания',
  },
  management: {
    en: 'Management',
    ro: 'Management',
    ru: 'Управление',
  },
  verification: {
    en: 'Verification',
    ro: 'Verificare',
    ru: 'Проверка',
  },
};
