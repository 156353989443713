import * as React from 'react';
import useSetState from 'react-use/esm/useSetState';
import { Input, Row, Col } from 'ebs-design';

export interface OTPCodeProps {
  onChange?: (value: string) => void;
}

enum CancelKeys {
  BACKSPACE = 8,
  DELETE = 46,
}

export const OTPCode: React.FC<OTPCodeProps> = ({ onChange }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [value, setValue] = useSetState({
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  });

  React.useEffect(() => {
    if (onChange) {
      onChange(
        Object.keys(value)
          .map((i) => value[i])
          .join(''),
      );
    }
  }, [onChange, value]);

  const onSetSms = React.useCallback(
    (position: number, val: string) => {
      if (val.replace(/\D/g, '').length) {
        setValue({ [position]: val.replace(value[position], '') });

        if (ref.current) {
          const elements = ref.current.childNodes[0].childNodes;
          for (let i = 0; i < elements.length; i++) {
            if (position < elements.length && i === position + 1) {
              (elements[i] as HTMLDivElement).getElementsByTagName('input')[0].focus();
              break;
            }
          }
        }
      }
    },
    [value, setValue],
  );

  const onSMSKeyDown = React.useCallback(
    (position, e) => {
      if (Object.values(CancelKeys).indexOf(e.keyCode) > -1 && ref.current) {
        const elements = ref.current.childNodes[0].childNodes;

        setValue({ [position]: '' });

        for (let i = elements.length - 1; i > -1; i--) {
          if (position < elements.length && i === position - 1) {
            (elements[i] as HTMLDivElement).getElementsByTagName('input')[0].focus();
            break;
          }
        }
      }
    },
    [setValue],
  );

  return (
    <div ref={ref} className="pc-otp__code">
      <Row>
        <Col>
          <Input
            styleType="grey"
            size="large"
            value={value[0] || ''}
            onChange={(val) => onSetSms(0, val as string)}
            onKeyDown={(e) => onSMSKeyDown(0, e)}
          />
        </Col>
        <Col>
          <Input
            className="input-otp__code"
            styleType="grey"
            size="large"
            value={value[1] || ''}
            onChange={(val) => onSetSms(1, val as string)}
            onKeyDown={(e) => onSMSKeyDown(1, e)}
          />
        </Col>
        <Col>
          <Input
            className="input-otp__code"
            styleType="grey"
            size="large"
            value={value[2] || ''}
            onChange={(val) => onSetSms(2, val as string)}
            onKeyDown={(e) => onSMSKeyDown(2, e)}
          />
        </Col>
        <Col>
          <Input
            className="input-otp__code"
            styleType="grey"
            size="large"
            value={value[3] || ''}
            onChange={(val) => onSetSms(3, val as string)}
            onKeyDown={(e) => onSMSKeyDown(3, e)}
          />
        </Col>
        <Col>
          <Input
            className="input-otp__code"
            styleType="grey"
            size="large"
            value={value[4] || ''}
            onChange={(val) => onSetSms(4, val as string)}
            onKeyDown={(e) => onSMSKeyDown(4, e)}
          />
        </Col>
        <Col>
          <Input
            className="input-otp__code"
            styleType="grey"
            size="large"
            value={value[5] || ''}
            onChange={(val) => onSetSms(5, val as string)}
            onKeyDown={(e) => onSMSKeyDown(5, e)}
          />
        </Col>
      </Row>
    </div>
  );
};
