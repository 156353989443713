import * as React from 'react';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useHistory, useParams, Link } from 'react-router-dom';
import { users } from 'api/users';
import { Properties, Tokens } from 'types';
import { Button, Form, Input, Row, Col, useForm, useNotify } from 'ebs-design';
import AuthenticationModal from '../../AuthenticationModal';
import { stringifyUrl } from 'libs';
import { getRoute } from 'utils';
import { routes } from 'routes';

type ConfirmPassword = { password: string; confirm_password: string };

export const ConfirmPage: React.FC = () => {
  const { t } = useIntl();
  const { token } = useParams();
  const { push } = useHistory();
  const [form] = useForm();
  const notify = useNotify();

  const resetPasswordConfirmRequest = useMutation<Tokens, unknown, Properties>(
    (formData) => users.resetPasswordConfirm(formData),
    {
      onSuccess: () => {
        push(getRoute(routes, 'SignInPage'));

        notify.success({
          title: t('password_reset'),
          description: t('success_password_change'),
        });
      },
    },
  );

  const handleSubmit = (data: ConfirmPassword) => {
    resetPasswordConfirmRequest.mutate({
      token,
      password: data.password,
      confirm_password: data.confirm_password,
    });
  };

  return (
    <AuthenticationModal
      title={t('confirm_password_reset')}
      description={t('enter_new_password')}
      footer={
        <>
          {t('already_registered')}{' '}
          <Link to={stringifyUrl(getRoute(routes, 'SignInPage'))}>{t('access_your_account')}</Link>
        </>
      }
    >
      <Form
        form={form}
        type="horizontal"
        onFinish={handleSubmit}
        controlOptions={{ col: { size: 9 } }}
        labelOptions={{ col: { size: 3 } }}
      >
        <Form.Field name="password" label={t('password')} rules={[{ required: true }]}>
          <Input type="password" />
        </Form.Field>
        <Form.Field
          name="confirm_password"
          label={t('confirm_password')}
          className="no-wrap"
          rules={[{ required: true }]}
        >
          <Input type="password" />
        </Form.Field>
        <Row>
          <Col size={3}></Col>
          <Col size={9}>
            <div className="authentication-modal-form-actions">
              <Button type="primary" submit buttonClass="uppercase">
                {t('save')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </AuthenticationModal>
  );
};
