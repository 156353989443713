import * as React from 'react';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Space, Icon, Loader } from 'ebs-design';
import { attachments } from 'api';
import { useFileFetch } from 'hooks';
import { PDFViewer } from 'components';
import { Attachment, CustomerRole, Properties } from 'types';

interface ContractSignProps {
  attachment?: Attachment;
  isLoading: boolean;
  disabled?: boolean;
  onChange: () => void;
}

export const ContractSign: React.FC<ContractSignProps> = ({
  attachment,
  isLoading,
  disabled,
  children,
}) => {
  const { t } = useIntl();
  const [visible, setVisible] = React.useState(false);

  const { mutate, isLoading: isLoadingSign } = useMutation(
    ({ id, redirect_url }: Properties) => attachments.getSign(id, redirect_url),
    {
      onSuccess: (response) => {
        window.open(response.redirect_url, '_self');
      },
    },
  );

  const canSign = React.useMemo(
    () =>
      !attachment?.signatures?.find((item) => item.user.roles?.find((i) => i.name in CustomerRole)),
    [attachment],
  );

  const onToggleHandler = React.useCallback(() => !disabled && setVisible((i) => !i), [disabled]);

  const onSignClick = React.useCallback(
    () => mutate({ id: attachment!.id, redirect_url: window.location.href }),
    [mutate, attachment],
  );

  const file = useFileFetch(attachment?.url);

  return (
    <>
      <Modal open={visible} size="large" onClose={onToggleHandler} header={t('request')}>
        <Modal.Content className="p-0">
          <Loader loading={isLoading || file.isLoading}>
            <PDFViewer file={file.data!} style={{ marginBottom: -4 }} />
          </Loader>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onToggleHandler}>{t('cancel')}</Button>
            <Button
              type="primary"
              prefix={<Icon type="check" model="bold" />}
              onClick={onSignClick}
              loading={isLoadingSign}
              disabled={!canSign}
            >
              {t(canSign ? 'sign_electronically' : 'signed')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
      <div onClick={onToggleHandler}>{children}</div>
    </>
  );
};
