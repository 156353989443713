import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useMutation } from 'react-query';
import { Button, Card, Icon, Space, Modal, Form, Textarea, useForm } from 'ebs-design';
import { requests } from 'api';
import { usePermissions } from 'hooks';
import { Plus } from 'resources';
import { validateResponse } from 'utils';
import { Comment, Permissions } from 'types';

interface Props {
  requestId: number;
  data: Comment[];
}

export const CommentsCard: React.FC<Props> = ({ requestId, data }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const can = usePermissions(Permissions.REQUESTS);
  const [visible, setVisible] = React.useState(false);
  const [comments, setComments] = React.useState<any>(data);

  const patchRequest = useMutation(requests.createComment, {
    onError: validateResponse.bind(null, form),
    onSuccess: ({ data }) => {
      setVisible(false);
      setComments((prevState) => [data, ...prevState]);
      form.resetFields();
    },
  });

  const handleSubmit = (values) => {
    patchRequest.mutate({ ...values, id: requestId });
  };

  return (
    <>
      <Card className="mb-20" collapsed={false} collapsible>
        <Card.Header bordered>
          <Space justify="space-between">
            <>{t('grade_risk_manager')}</>

            {can.create?.riskManagerComment && (
              <Button onClick={() => setVisible(true)} prefix={<Icon component={Plus} />}>
                {t('add_comment')}
              </Button>
            )}
          </Space>
        </Card.Header>
        <Card.Body className="p-0">
          {comments.map((item) => (
            <div key={item.id} className="request-comment__item p-20">
              {item.comment}
            </div>
          ))}
        </Card.Body>
      </Card>

      <Modal open={visible} size="small" title={t('add_comment')} onClose={() => setVisible(false)}>
        <Modal.Content>
          <Form form={form} onFinish={handleSubmit} id="add-comment-form">
            <Form.Field name="comment">
              <Textarea />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="end">
            <Button
              submit
              loading={patchRequest.isLoading}
              form="add-comment-form"
              prefix={<Icon type="check" model="bold" />}
            >
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
    </>
  );
};
