import * as React from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Space, Button, Row, Col, Icon, Loader, Card, Modal, Tabs } from 'ebs-design';
import { company } from 'api';
import { UserContext } from 'contexts';
import { UpdateArrow } from 'resources';
import { capitalize, arrayContainsArray } from 'utils';
import { HistoryTable } from 'components';
import { InvoiceStatus, Properties, HistoryModels, RequestType, CustomerRole } from 'types';

export const Description: React.FC = () => {
  const { t } = useIntl();
  const { user, userRoles } = React.useContext(UserContext);

  const [showHistory, setShowHistory] = React.useState(false);
  const [active, setActive] = React.useState(HistoryModels.REQUEST);

  const enabled = React.useMemo(() => !!user?.company, [user]);

  const { data: dataCompany, isLoading: isLoadingCompany } = useQuery(
    ['company', user!.company?.id],
    () => company.get(user!.company?.id),
    {
      enabled,
    },
  );

  const { data, isLoading } = useQuery<Properties>(
    ['company-report-rates', user!.company?.id],
    () => company.getReportRates(user!.company?.id, true),
    {
      enabled,
    },
  );

  const totalData = React.useMemo(
    () => data && data.find((item) => item.status === InvoiceStatus.TOTAL),
    [data],
  );
  const sentData = React.useMemo(
    () => data && data.find((item) => item.status === InvoiceStatus.SENT),
    [data],
  );
  const remainedData = React.useMemo(
    () => data && data.find((item) => item.status === InvoiceStatus.REMAINED),
    [data],
  );
  const activeInvoices = React.useMemo(() => {
    if (data) {
      const activeData = data
        .filter((item) =>
          [
            InvoiceStatus.PARTIALLY_PAID,
            InvoiceStatus.RECESSIONED,
            InvoiceStatus.REMAINED,
          ].includes(item.status),
        )
        .map(({ invoices_count_total }) => invoices_count_total);

      if (activeData.length) {
        return activeData.reduce((a, b) => a + b);
      }
    }

    return 0;
  }, [data]);

  const historyTabs = React.useMemo(
    () => [
      {
        label: <span>{t('limits')}</span>,
        key: HistoryModels.REQUEST,
        content: (
          <HistoryTable
            model={HistoryModels.REQUEST}
            columns={['limit']}
            query={{ old_company: user!.company?.id, old_type: RequestType.CARD }}
            excludeAdherents
          />
        ),
      },
      {
        label: <span>{t('rates')}</span>,
        key: HistoryModels.COMPANYRATE,
        content: (
          <HistoryTable
            model={HistoryModels.COMPANYRATE}
            columns={['commission', 'guarantee']}
            query={{ old_company: user!.company?.id }}
            excludeAdherents
          />
        ),
      },
    ],
    [t, user],
  );

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  return (
    <>
      <Card className="pc-limits-descripton mb-20">
        <Card.Header bordered>
          <Space align="center" justify="space-between">
            <h4>{t('limits')}</h4>
            <Button
              type="primary"
              prefix={<Icon component={UpdateArrow} />}
              onClick={() => setShowHistory((i) => !i)}
              disabled={showHistory}
            >
              {t('track_history')}
            </Button>
          </Space>
        </Card.Header>
        <Card.Body>
          <Loader loading={isLoading || isLoadingCompany}>
            <Row className="m-0">
              <Col
                className="pc-limits-descripton__col pc-limits-descripton__col--border-right py-25 px-20"
                size={8}
              >
                <Row className="mb-25">
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">{t('approved_limit')}</div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && dataCompany?.limit?.current) || 0} MDL
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">{t('available_limit')}</div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && dataCompany?.limit?.accessible) || 0} MDL
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('total_nr_of_invoices')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && totalData?.invoices_count_total) || 0}
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">{t('total_turnover')}</div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && totalData?.invoices_amount_total) || 0} MDL
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('average_monthly_turnover')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && totalData?.invoices_amount_average_monthly_total) || 0} MDL
                    </div>
                  </Col>
                </Row>
                <Row className="mb-25">
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t(`total${!isAdherent ? '_collected' : ''}_commission`)}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && totalData?.invoices_commission_amount_total) || 0} MDL
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t(`total${!isAdherent ? '_collected' : ''}_penalties`)}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && totalData?.invoices_penalties_amount_total) || 0} MDL
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('total_warranty_returned')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && totalData?.invoices_guarantee_return_amount_total) || 0} MDL
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('max_nr_of_remaining_days')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && totalData?.invoices_remained_outstanding_max_total) || 0}{' '}
                      {t('days')}
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('amount_on_invoices_under_examination')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && sentData?.invoices_amount_total) || 0} MDL
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('nr_of_active_invoices')}
                    </div>
                    <div className="pc-limits-descripton__value">{activeInvoices}</div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('nr_of_remaining_invoices')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && remainedData?.invoices_count_total) || 0}
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('amount_on_remaining_invoices')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && remainedData?.invoices_amount_total) || 0} MDL
                    </div>
                  </Col>
                  <Col>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('nr_of_invoices_under_examination')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && sentData?.invoices_count_total) || 0}
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
              </Col>
              <Col className="pc-limits-descripton__col py-25 px-20" size={4}>
                <Space className="flex" direction="vertical" align="start" justify="space-between">
                  <div>
                    <div className="pc-limits-descripton__label mb-8">{t('average_invoice')}</div>
                    <div className="pc-limits-descripton__value">
                      {`${capitalize(t('amount'))}: ${
                        (enabled && totalData?.invoices_amount_average_total) || 0
                      } MDL`}{' '}
                      /
                      {`${capitalize(t('term'))}: ${
                        (enabled && totalData?.invoices_term_average_total) || 0
                      } ${t('days')}`}
                    </div>
                  </div>
                  <div>
                    <div className="pc-limits-descripton__label mb-8">
                      {t('amount_of_active_warranty')}
                    </div>
                    <div className="pc-limits-descripton__value">
                      {(enabled && totalData?.invoices_guarantee_amount_total) || 0} MDL
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
          </Loader>
        </Card.Body>
      </Card>

      <Modal
        open={showHistory}
        size="large"
        title={t('history')}
        onClose={() => setShowHistory(() => false)}
      >
        <Modal.Content className="p-0 pc-history-table">
          <Tabs activeTab={active} setActiveTab={(value) => setActive(value as HistoryModels)}>
            {[...historyTabs].map(({ key, ...item }) => (
              <Tabs.Tab key={key} tabKey={key} {...item} />
            ))}
            {historyTabs.map((item) => (
              <Tabs.Panel key={item.key} tabKey={item.key}>
                {item.content}
              </Tabs.Panel>
            ))}
          </Tabs>
        </Modal.Content>
      </Modal>
    </>
  );
};
