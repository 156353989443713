import * as React from 'react';
import { Button, Form, Modal, Space, useForm, useNotify } from 'ebs-design';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { CodeName, FactoringType } from 'types';
import { extractResponseProps } from 'utils';
import { nomenclature } from 'api';
import { InputNumericForm } from 'components';

type Props = {
  category?: {
    categoryId?: number;
    categoryName?: CodeName;
    factoringType?: FactoringType;
    factoringTypeId?: number;
  };
  isOpen: boolean;
  onClose: () => void;
};

export const AddRateModal = ({ category, isOpen, onClose }: Props) => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [form] = useForm();

  const { mutate, isLoading } = useMutation(nomenclature.createCategoriesRate, {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['nomenclatures', 'rates']);
      notify.success({ title: t('rates'), description: t('success_data_save') });
      onClose();
    },
  });

  const handleSubmit = (values) => {
    mutate({
      ...values,
      categories_id: [category?.categoryId],
      factoring_type_id: category?.factoringTypeId,
    });
  };
  return (
    <Modal
      size="small"
      header={
        <>
          <p>
            {`${t('type')}:  ${t(
              category?.factoringType === FactoringType.REGRESSION
                ? 'with_right_refund'
                : 'without_right_refund',
            )}`}
          </p>
          <p>{`${t('category')}:  ${category?.categoryName}`}</p>
        </>
      }
      open={isOpen}
      onClose={onClose}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Content>
          <Space>
            <InputNumericForm
              name="value"
              label={t('cdays')}
              rules={[{ required: true }]}
              inputProps={{ suffix: <>{t('days')}</> }}
            />

            <InputNumericForm
              name="percent"
              label={t('commission')}
              rules={[{ required: true }]}
              inputProps={{ suffix: <>%</> }}
            />

            <InputNumericForm
              name="gc_percent"
              label={`${t('commission')} + ${t('guarantee')}`}
              rules={[{ required: true }]}
              inputProps={{ suffix: <>%</> }}
            />
          </Space>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onClose}>{t('cancel')}</Button>
            <Button type="primary" submit loading={isLoading} disabled={isLoading}>
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
