import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Space, Button, Actions } from 'ebs-design';
import { useSetState } from 'react-use';
import { MemberEntity } from 'types';
import AddMemberModal from './AddMemberModal';

export type MemberType = 'family' | 'associates';

interface Props {
  type: MemberType;
  value?: any;
  onChange?: any;
}

const MemberList = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useIntl();
  const [visible, setVisibility] = React.useState(false);
  const [members, setMembers] = React.useState<MemberEntity[]>([]);
  const [selectedMember, setSelectMember] = useSetState<{
    data: MemberEntity | null;
    index: number | null; // It's needed to update local member
  }>({
    data: null,
    index: null,
  });

  React.useEffect(() => {
    setMembers(props.value || []);
  }, [props.value]);

  const handleSumbit = (data: MemberEntity) => {
    if (selectedMember.data) {
      const updatedMembers = members.map((member, idx) =>
        selectedMember.index === idx ? data : member,
      );

      props.onChange(updatedMembers);
    } else {
      props.onChange([...members, data]);
    }

    // Close the modal
    setVisibility(false);
    setSelectMember({ data: null, index: null });
  };

  // Actions
  const handleEdit = (data: MemberEntity, idx: number) => {
    // Index - is needed to update member
    setSelectMember({ data, index: idx });
    setVisibility(true);
  };

  const handleDelete = (data: MemberEntity, idx: number) => {
    const updatedMember = members.filter((member, index) => data.id !== member.id || idx !== index);

    props.onChange(updatedMember);
  };

  const typeTitle = props.type === 'family' ? t('add_family_member') : t('add_associates_member');

  return (
    <>
      <div ref={ref} className="beneficiary__member mb-10">
        {members && members.length > 0 ? (
          members.map((member, idx) => (
            <div key={member.id || idx} className="beneficiary__member__item">
              <Space justify="space-between">
                <Space className="flex--fill">
                  <div className="beneficiary__member__count">{idx + 1}</div>
                  <div className="beneficiary__member__title">{`${member.first_name || ''} ${
                    member.last_name || ''
                  }`}</div>
                </Space>

                <Actions>
                  <Actions.Item onClick={() => handleEdit(member, idx)}>{t('edit')}</Actions.Item>
                  <Actions.Item onClick={() => handleDelete(member, idx)}>
                    {t('delete')}
                  </Actions.Item>
                </Actions>
              </Space>
            </div>
          ))
        ) : (
          <div className="p-10 text-center">{t('no_data')}</div>
        )}
      </div>

      <Button onClick={() => setVisibility(true)}>{typeTitle}</Button>

      <AddMemberModal
        type={props.type}
        title={typeTitle}
        visible={visible}
        data={selectedMember.data}
        onClose={() => setVisibility(false)}
        onSave={handleSumbit}
      />
    </>
  );
});

export default MemberList;
