import * as React from 'react';
import { Container } from 'ebs-design';
import { Layout } from 'components/organisms';
import { FactoringPortfolioReport } from 'features/reports';

export function PortfolioReport() {
  return (
    <Layout>
      <Container className="credit-officer-home">
        <FactoringPortfolioReport />
      </Container>
    </Layout>
  );
}
