import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Card } from 'ebs-design';
import {
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import { abbreviateNumber } from 'utils';
import { ReportInspectorGraph } from 'types';
import { chartColors } from '../utils';

export const InspectorsChart: React.FC<{ data?: ReportInspectorGraph }> = ({ data }) => {
  const { t } = useIntl();

  return (
    <Card className="report-chart__card">
      <Card.Body>
        {!data ? (
          <p>{t('select_credit_officer')}</p>
        ) : (
          <ResponsiveContainer width={700} height={400}>
            <ComposedChart data={data?.data || []}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" scale="band" />
              <YAxis type="number" tickFormatter={(tick) => abbreviateNumber(tick)} />
              <YAxis
                type="number"
                tickFormatter={(tick) => abbreviateNumber(tick)}
                yAxisId="right"
                orientation="right"
                scale="auto"
              />
              <Tooltip />
              <Legend />
              <Bar name={t('sales_sum')} dataKey="amount" barSize={20} fill={chartColors.bar} />
              <Line
                type="monotone"
                name={t('sales_nr')}
                yAxisId="right"
                dataKey="count"
                stroke={chartColors.line}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Card.Body>
    </Card>
  );
};
