import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import { Table, Loader, Card, Space, Actions } from 'ebs-design';
import { company } from 'api';
import { FactoringType } from 'types';
import { AddFutureRatesModal } from 'components/molecules';
import { UserContext } from 'contexts';

interface Props {
  isCreateFutureRates?: boolean;
}

export const IndividualRates: React.FC<Props> = ({ isCreateFutureRates = false }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const { user } = React.useContext(UserContext);

  const userId = id || user?.company?.id;

  const [futureRatesType, setFutureRatesType] = React.useState<FactoringType>();

  const { data: dataRegression, isLoading: isLoadingRegression } = useQuery(
    ['individual', 'rates', { factoring_type__type: FactoringType.REGRESSION, ordering: 'value' }],
    () =>
      company.getRates(userId, {
        factoring_type__type: FactoringType.REGRESSION,
        ordering: 'value',
      }),
    {
      enabled: !!userId,
    },
  );

  const { data: dataNoRegression, isLoading: isLoadingNoRegression } = useQuery(
    [
      'individual',
      'rates',
      { factoring_type__type: FactoringType.NO_REGRESSION, ordering: 'value' },
    ],
    () =>
      company.getRates(userId, {
        factoring_type__type: FactoringType.NO_REGRESSION,
        ordering: 'value',
      }),
    {
      enabled: !!userId,
    },
  );

  const childrenColumns = React.useMemo(
    () => [
      {
        title: t('days'),
        render: (data) => (data?.value >= 0 ? `${data?.value} ${t('days')}` : '-'),
      },
      {
        title: t('commission'),
        render: (data) =>
          data?.personalized?.commission >= 0 ? `${data?.personalized?.commission}%` : '-',
      },
      {
        title: t('guarantee'),
        render: (data) =>
          data?.personalized?.guarantee >= 0 ? `${data?.personalized?.guarantee}%` : '-',
      },
      {
        title: t('total'),
        render: (data) =>
          data?.personalized?.gc_percent >= 0 ? `${data?.personalized?.gc_percent}%` : '-',
      },
    ],

    [t],
  );

  const columnsRegression = React.useMemo(
    () => [
      {
        title: t('with_right_refund'),
        children: [
          {
            title: t('category'),
            render: (data, _, index) => ({
              children: data?.personalized?.category ? data?.personalized?.category : `-`,
              props: {
                rowSpan: index === 0 ? dataRegression.results.length : 0,
              },
            }),
          },
          ...childrenColumns,
          isCreateFutureRates
            ? {
                title: null,
                action: true,
                render: (data, _, index) => ({
                  props: {
                    rowSpan: index === 0 ? dataRegression.results.length : 0,
                  },

                  children: (
                    <Actions>
                      <Actions.Item onClick={() => setFutureRatesType(data?.factoring_type?.type)}>
                        {t('add_future_rates')}
                      </Actions.Item>
                    </Actions>
                  ),
                }),
              }
            : {},
        ],
      },
    ],
    [t, isLoadingRegression],
  );

  const columnsNoRegression = React.useMemo(
    () => [
      {
        title: t('without_right_refund'),
        children: [
          {
            title: t('category'),
            render: (data, _, index) => ({
              children: data?.personalized?.category ? data?.personalized?.category : `-`,
              props: {
                rowSpan: index === 0 ? dataNoRegression.results.length : 0,
              },
            }),
          },
          ...childrenColumns,
          isCreateFutureRates
            ? {
                title: null,
                action: true,
                render: (data, _, index) => ({
                  props: {
                    rowSpan: index === 0 ? dataNoRegression.results.length : 0,
                  },
                  children: (
                    <Actions>
                      <Actions.Item onClick={() => setFutureRatesType(data?.factoring_type?.type)}>
                        {t('add_future_rates')}
                      </Actions.Item>
                    </Actions>
                  ),
                }),
              }
            : {},
        ],
      },
    ],
    [t, isLoadingNoRegression],
  );

  return (
    <>
      {isCreateFutureRates && (
        <AddFutureRatesModal
          factoringType={futureRatesType}
          companyId={userId}
          isOpen={!!futureRatesType}
          onClose={() => setFutureRatesType(undefined)}
        />
      )}
      <Card className="pc-rates__card mb-25">
        <Card.Header bordered>
          <Space justify="space-between">
            <h4>{t('individual_rates')}</h4>
          </Space>
        </Card.Header>
        <Card.Body className="p-0">
          {isLoadingRegression || isLoadingNoRegression ? (
            <Loader loading={isLoadingRegression || isLoadingNoRegression} />
          ) : (
            <>
              <Table
                className="pc-rates__table"
                columns={columnsRegression}
                data={dataRegression?.results}
              />

              <Table
                className="pc-rates__table"
                columns={columnsNoRegression}
                data={dataNoRegression?.results}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
