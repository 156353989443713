import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import { Loader, Card, Space, Label, Row, Col } from 'ebs-design';
import { company } from 'api';
import { UserContext } from 'contexts';
import { formatDate } from 'libs';
import { Layout } from 'components';
import { arrayContainsArray } from 'utils';
import { CustomerRole } from 'types';

import { DebtorLayout } from '../DebtorLayout';
import { DebtorProfile } from '../DebtorProfile';

export const Contract: React.FC = () => {
  const { id } = useParams();
  const { t } = useIntl();
  const { userRoles } = React.useContext(UserContext);

  const { data: dataCompany, isLoading: isLoadingCompany } = useQuery(
    ['company', id],
    () => company.get(id),
    {
      enabled: Boolean(id),
    },
  );

  const { data, isLoading } = useQuery(
    ['company-report-rates', id],
    () => company.getReportRates(id),
    {
      enabled: Boolean(id),
    },
  );

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  return (
    <Layout>
      <DebtorLayout>
        <Loader loading={isLoadingCompany}>
          {dataCompany && <DebtorProfile data={dataCompany} />}
        </Loader>
        <Card>
          <Card.Header bordered>{t('limits_and_rates')}</Card.Header>

          <Card.Body className="bg-white p-0">
            <Loader loading={isLoading || isLoadingCompany}>
              <Row className="overflow-hidden" g={0}>
                <Col className="pb-20">
                  <Row g={4} className="pt-20 px-20">
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label text={<span className="fw-600">{t('status')}</span>} />
                        {data && data.status ? t(data.status.toLowerCase()) : '---'}
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label
                          text={<span className="fw-600">{t('nr_of_active_invoices')}</span>}
                        />
                        {data?.invoices_active_totals || 0}
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label text={<span className="fw-600">{t('date_signed')}</span>} />
                        {(dataCompany?.contract?.signed_date &&
                          formatDate(dataCompany?.contract?.signed_date)) ||
                          '---'}
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label text={<span className="fw-600">{t('available_to')}</span>} />
                        {(dataCompany?.contract?.expiring_date &&
                          formatDate(dataCompany?.contract?.expiring_date)) ||
                          '---'}
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label text={<span className="fw-600">{t('available_limit')}</span>} />
                        <>{dataCompany?.limit?.accessible || 0} MDL</>
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label text={<span className="fw-600">{t('total_nr_of_invoices')}</span>} />
                        {data?.invoices_count_total || 0}
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label text={<span className="fw-600">{t('total_turnover')}</span>} />
                        <>{data?.invoices_amount_total || 0} MDL</>
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label
                          text={<span className="fw-600">{t('average_monthly_turnover')}</span>}
                        />
                        <>{data?.invoices_amount_average_monthly_total || 0} MDL</>
                      </Space>
                    </Col>
                  </Row>

                  <Row g={4} className="pt-20 px-20">
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label
                          text={
                            <span className="fw-600">
                              {t(`total${!isAdherent ? '_collected' : ''}_commission`)}
                            </span>
                          }
                        />
                        <>{data?.invoices_commission_amount_total || 0} MDL</>
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label
                          text={
                            <span className="fw-600">
                              {t(`total${!isAdherent ? '_collected' : ''}_penalties`)}
                            </span>
                          }
                        />
                        <>{data?.invoices_penalties_amount_total || 0} MDL</>
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label
                          text={<span className="fw-600">{t('total_warranty_returned')}</span>}
                        />
                        <>{data?.invoices_guarantee_return_amount_total || 0} MDL</>
                      </Space>
                    </Col>
                    <Col size={12} sm={6} lg={3}>
                      <Space align="start" direction="vertical" size="small">
                        <Label
                          text={
                            <span className="fw-600">{t('nr_of_invoices_under_examination')}</span>
                          }
                        />
                        {data?.invoices_remained_outstanding_max_total || 0}
                      </Space>
                    </Col>
                  </Row>
                </Col>

                <Col style={{ maxWidth: 0 }}>
                  <div className="form-divider vertical" />
                </Col>

                <Col className="p-20" lg={4}>
                  <Space
                    align="start"
                    direction="vertical"
                    justify="space-between"
                    style={{ height: '100%' }}
                  >
                    <Space size="small" align="start" direction="vertical">
                      <Label text={<span className="fw-600">{t('average_invoice')}</span>} />
                      <>
                        {t('amount')}: {data?.invoices_amount_average_total || 0} MDL / {t('term')}:{' '}
                        {data?.invoices_term_average_total || 0} {t('days')}
                      </>
                    </Space>
                    <Space justify="space-between" align="start" size="large">
                      <Space size="small" align="start" direction="vertical">
                        <Label
                          text={<span className="fw-600">{t('amount_of_active_warranty')}</span>}
                        />
                        <>{data?.invoices_guarantee_amount_total || 0} MDL</>
                      </Space>
                      <Space size="small" align="start" direction="vertical">
                        <Label text={<span className="fw-600">{t('warranty_for_refund')}</span>} />
                        <>{data?.invoices_guarantee_return_amount_total || 0} MDL</>
                      </Space>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </Loader>
          </Card.Body>
        </Card>
      </DebtorLayout>
    </Layout>
  );
};
