import { Unit, Classifier, Properties, ClassifierCategory } from 'types';

export const minimumOperatorOptions = [
  {
    value: '>',
    text: '>',
  },
  {
    value: '>=',
    text: '>=',
  },
];

export const maximumOperatorOptions = [
  {
    value: '<',
    text: '<',
  },
  {
    value: '<=',
    text: '<=',
  },
];

export const getInitialValues = (classifier: Classifier | null): undefined | Properties => {
  if (!classifier) {
    return;
  }
  const { categories, ...rest } = classifier;
  return {
    ...rest,
    _units: classifier.period === Unit.MINUS ? classifier.currency?.id : classifier.period,
    categories: categories.reduce<any[]>((acc, item) => {
      acc[item.category_id] = {
        ...item,
        _units: item.period === Unit.MINUS ? item.currency?.id : item.period,
      };
      return acc;
    }, []),
  };
};

export const transformValues = (values: Properties): Properties => {
  const { categories, minimum_operator, maximum_operator, minimum, maximum, ...rest } = values;
  const transform = ({ _units, ...obj }: Properties): object => {
    if (typeof _units === 'number') {
      return { ...obj, period: Unit.MINUS, currency_id: _units };
    }
    return { ...obj, period: _units };
  };

  const body = {
    minimum_operator: minimum_operator || null,
    maximum_operator: maximum_operator || null,
    minimum: minimum || null,
    maximum: maximum || null,
    ...transform(rest),
    categories: Object.keys(categories).reduce<Partial<ClassifierCategory>[]>((acc, categoryId) => {
      if (!categories[categoryId]) {
        return acc.map((i) => ({
          ...i,
          minimum_operator: i.minimum_operator || null,
          maximum_operator: i.maximum_operator || null,
          minimum: i.minimum || null,
          maximum: i.maximum || null,
        }));
      }
      return acc
        .concat({
          category_id: +categoryId,
          ...transform(categories[categoryId]),
        })
        .map((i) => ({
          ...i,
          minimum_operator: i.minimum_operator || null,
          maximum_operator: i.maximum_operator || null,
          minimum: i.minimum || null,
          maximum: i.maximum || null,
        }));
    }, []),
  };
  return body;
};
