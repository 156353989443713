import * as React from 'react';
import { useIntl } from 'estafette-intl';
import {
  Row,
  Col,
  Button,
  Space,
  Checkbox,
  Form,
  Input,
  Upload,
  InputPhone,
  Loader,
  useForm,
  useNotify,
} from 'ebs-design';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { nomenclature, company, tenancy } from 'api';
import { Properties, Role, DocumentType, CustomerRole } from 'types';
import { useUpload } from 'hooks';
import { UserContext } from 'contexts';
import { formatDate, getAPIDateFormat } from 'libs';
import {
  validateResponse,
  hasOwnProperty,
  getDeepKeys,
  extractResponseProps,
  defaultFilters,
} from 'utils';
import { DatePicker, SmartSelect, UserSearch } from 'components';

import MemberList from './MemberList';

interface Props {
  className?: string;
  userId?: number;
  onSuccess: () => void;
}

const BeneficiaryForm: React.FC<Props> = ({ userId, onSuccess }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const { user } = React.useContext(UserContext);
  const uploadProps = useUpload();
  const queryClient = useQueryClient();

  const [tenancyFilters, setTenancyFilters] = useSetState<Properties>({
    ...defaultFilters,
    beneficiary: false,
  });
  const [politicallyExposed, setPoliticallyExposed] = React.useState(false);
  const [canFill, setCanFill] = React.useState(false);
  const [memberState, setMemberState] = useSetState({
    family_members: false,
    associates_members: false,
  });
  const [hasAvailableTo, setHasAvailableTo] = React.useState(true);

  const beneficiaryRole = useQuery(['nomenclature', 'roles'], () => nomenclature.getRoles(), {
    select: (roles) => roles.find((role) => CustomerRole.BENEFICIARY === role.text)?.value,
  });

  const enabled = React.useMemo(() => !!userId && !!user?.company, [userId, user]);
  const enabledTenancy = React.useMemo(
    () => !!tenancyFilters.idnp && tenancyFilters.idnp.length > 12,
    [tenancyFilters],
  );

  // Fetch company user
  const companyUser = useQuery(
    ['company-user', user?.company?.id as number, userId],
    company.getUser,
    {
      enabled,
    },
  );

  const { data: tenancyData, isLoading: isLoadingTenancy } = useQuery(
    ['tenancy-companies-list', tenancyFilters],
    () => tenancy.getUserByIDNP(tenancyFilters.idnp),
    {
      retry: 0,
      enabled: enabledTenancy,
    },
  );

  const addUser = useMutation(
    (data: Properties) => company.addUser(user?.company?.id as number, data),
    {
      onError: (err) => {
        validateResponse.bind(null, form, err);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries('company-users');
        notify.success({ title: t('company_users'), description: t('success_data_save') });
      },
    },
  );

  const updateUser = useMutation(
    ({ data, userId }: Properties) => company.patchUser(user?.company?.id as number, userId, data),
    {
      onError: (err) => {
        validateResponse.bind(null, form, err);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries('company-users');
        notify.success({ title: t('company_users'), description: t('success_data_change') });
      },
    },
  );

  const setTenancyUser = useMutation(({ id }: Properties) => tenancy.setUser(id), {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: (_, { id, data }) => {
      updateUser.mutate({
        data: {
          ...data,
          additional_data: { beneficiary: true, id: tenancyData?.additional_data?.id },
        },
        userId: id,
      });
    },
  });

  // Fill form initial values on edit users
  React.useEffect(() => {
    if (enabled && !companyUser.isLoading && companyUser.data) {
      const { roles = [], additional_data } = companyUser.data;

      // Set show/hide member forms
      setMemberState({
        family_members: additional_data?.family_members,
        associates_members: additional_data?.associates_members,
      });

      setPoliticallyExposed(additional_data?.politically_exposed);

      form.setFieldsValue({
        ...companyUser.data,
        additional_data: {
          ...additional_data,
          occupation_id: additional_data?.occupation?.id,
          main_activity_id: additional_data?.main_activity?.id,
          birthday: additional_data?.birthday ? formatDate(additional_data.birthday) : undefined,
        },
        ...(companyUser?.data?.passport && {
          passport: {
            ...companyUser?.data?.passport,
            ...(companyUser?.data?.passport?.citizenship && {
              citizenship_id: companyUser?.data?.passport?.citizenship.id,
            }),
            ...(!!companyUser?.data?.passport?.attachments?.length && {
              attachments_id: companyUser?.data?.passport?.attachments,
            }),
            ...(!companyUser?.data?.passport?.attachments?.length &&
              companyUser?.data?.passport?.attachment && {
                attachments_id: companyUser?.data?.passport?.attachment,
              }),
            ...(companyUser?.data?.passport?.date_receipt && {
              date_receipt: formatDate(companyUser?.data?.passport?.date_receipt),
            }),
            ...(companyUser?.data?.passport?.available_to && {
              available_to: formatDate(companyUser?.data?.passport?.available_to),
            }),
          },
        }),
        ...((roles || beneficiaryRole?.data) && {
          roles_id: (roles && (roles as Role[]).map((r) => r.id)) || [beneficiaryRole?.data],
        }),
      });

      setCanFill(true);
    } else {
      form.resetFields();

      setCanFill(false);
    }
    // eslint-disable-next-line
  }, [companyUser.data, form, enabled, beneficiaryRole?.data]);

  const handleSubmit = ({ ...data }: Properties) => {
    const updatedData: Properties = {
      ...data,
      passport: {
        ...data.passport,
        attachments_id:
          data?.passport?.attachments_id && data.passport?.attachments_id.map(({ id }) => id),
        ...(data?.passport?.date_receipt && {
          date_receipt: getAPIDateFormat(data.passport?.date_receipt),
        }),
        ...(data?.passport?.available_to && {
          available_to: getAPIDateFormat(data.passport?.available_to),
        }),
      },
      additional_data: {
        ...data?.additional_data,
        beneficiary: true,
        ...(data.additional_data.birthday && {
          birthday: getAPIDateFormat(data.additional_data.birthday),
        }),
        associates_members: !!data.additional_data.associates?.length,
        ...(data.additional_data.associates && {
          associates: data.additional_data.associates.map((associate) => {
            if (associate.function_end) {
              associate.function_end = getAPIDateFormat(associate.function_end);
            }

            if (associate.function_start) {
              associate.function_start = getAPIDateFormat(associate.function_start);
            }

            return associate;
          }),
        }),
        family_members: !!data.additional_data.families?.length,
        ...(data.additional_data.families && {
          families: data.additional_data.families.map((family) => {
            if (family.function_end) {
              family.function_end = getAPIDateFormat(family.function_end);
            }

            if (family.function_start) {
              family.function_start = getAPIDateFormat(family.function_start);
            }

            return family;
          }),
        }),
      },
    };

    if (userId && companyUser.data) {
      updateUser.mutate({
        data: {
          ...updatedData,
          id: companyUser.data.id,
          additional_data: {
            ...updatedData.additional_data,
            id: companyUser.data.additional_data.id,
          },
          passport: {
            ...updatedData.passport,
            id: companyUser.data.passport?.id,
          },
        },
        userId,
      });
    } else if (!updatedData.id) {
      addUser.mutate(updatedData);
    } else {
      const { id, ...data } = updatedData;

      setTenancyUser.mutate({ id, data });
    }
  };

  const handleValuesChange = (field) => {
    const fieldName: string[] = getDeepKeys(field);
    const fieldValue = form.getFieldValue(fieldName);

    // Set member state to show/hide the form
    if (
      hasOwnProperty(field, 'additional_data') &&
      (hasOwnProperty(field.additional_data, 'family_members') ||
        hasOwnProperty(field.additional_data, 'associates_members'))
    ) {
      setMemberState({ [fieldName[fieldName.length - 1]]: fieldValue });
    } else if (
      hasOwnProperty(field, 'additional_data') &&
      hasOwnProperty(field.additional_data, 'politically_exposed')
    ) {
      setPoliticallyExposed(fieldValue);
    } else if (hasOwnProperty(field, 'idnp')) {
      setTenancyFilters({ idnp: fieldValue });
      setCanFill(false);
    }
  };

  const docTypeOptions = React.useMemo(
    () => [
      {
        text: t('driver_license'),
        value: DocumentType.DRIVER_LICENSE,
      },
      {
        text: t('bulletin'),
        value: DocumentType.INTERNAL_PASSPORT,
      },
      {
        text: t('passport'),
        value: DocumentType.INTERNATIONAL_PASSPORT,
      },
    ],
    [t],
  );

  const onSelectUser = (id: number): void => {
    setTenancyUser.mutate({ id, additional_data: { beneficiary: true } });
  };

  const checkedFields = React.useCallback(
    ({ checked, onChange }) => (
      <>
        <Checkbox
          disabled={!canFill}
          checked={checked}
          onChange={(checked) => onChange(checked)}
          text={t('yes')}
        />
        <Checkbox
          disabled={!canFill}
          checked={!checked}
          onChange={(checked) => onChange(!checked)}
          text={t('no')}
        />
      </>
    ),
    [t, canFill],
  );

  return (
    <Loader loading={beneficiaryRole.isLoading || companyUser.isLoading}>
      <UserSearch
        open={enabledTenancy && !isLoadingTenancy && !canFill}
        data={tenancyData}
        onChange={onSelectUser}
        onClose={() => setTenancyFilters({ idnp: undefined })}
        onCreateNew={() => setCanFill(true)}
        loading={setTenancyUser.isLoading || updateUser.isLoading}
      />

      <Form
        form={form}
        type="horizontal"
        className="company-form"
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
        labelOptions={{ col: { size: 3 } }}
        controlOptions={{ col: { size: 9 } }}
        fieldRow={{ gy: 3 }}
      >
        <Row g={0}>
          <Col size={6} className="py-20 px-30 form-col--border-right">
            <h3 className="form-heading">{t('general_data')}</h3>

            <div className="form-divider" />

            <Form.Field name="idnp" label={t('idnp')} rules={[{ required: true, len: 13 }]}>
              <Input type="number" loading={isLoadingTenancy} />
            </Form.Field>

            <div className="form-divider" />

            <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
              <Input disabled={!canFill} />
            </Form.Field>

            <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
              <Input disabled={!canFill} />
            </Form.Field>

            <Form.Field name="patronymic" label={t('patronymic')} rules={[{ required: true }]}>
              <Input disabled={!canFill} />
            </Form.Field>

            <Form.Field name="email" label={t('email')} rules={[{ required: true }]}>
              <Input type="email" disabled={!canFill} />
            </Form.Field>

            <Form.Field name="phone" label={t('phone')} rules={[{ required: true }]}>
              <InputPhone country="md" disabled={!canFill} />
            </Form.Field>

            <Form.Field name={['additional_data', 'birthday']} label={t('birthday')}>
              <DatePicker disabled={!canFill} />
            </Form.Field>

            <Form.Field
              name={['additional_data', 'place_of_birth']}
              label={t('birthplace')}
              rules={[{ required: true }]}
            >
              <Input disabled={!canFill} />
            </Form.Field>

            <div className="form-divider" />

            <Form.Field name={['additional_data', 'occupation_id']} label={t('occupation')}>
              <SmartSelect
                queryKey="occupations"
                api={nomenclature.getOccupations}
                selected={companyUser.data?.additional_data.occupation}
                disabled={!canFill}
              />
            </Form.Field>

            <Form.Field
              name={['additional_data', 'main_activity_id']}
              label={t('field_of_activity')}
            >
              <SmartSelect
                queryKey="activities"
                api={nomenclature.getActivities}
                selected={{
                  value: companyUser.data?.additional_data.main_activity?.id,
                  text: `${companyUser.data?.additional_data.main_activity?.title}`,
                }}
                disabled={!canFill}
              />
            </Form.Field>

            {/* It's required by API but visually should not be */}
            <Form.Field name="roles_id" initialValue={[]} />
            <Form.Field name="id" />

            <Form.Field
              name={['additional_data', 'politically_exposed']}
              label={t('politically_exposed')}
              valuePropName="checked"
              initialValue={false}
            >
              {checkedFields}
            </Form.Field>

            {politicallyExposed && (
              <Form.Field name={['additional_data', 'political_role']} label={t('political_role')}>
                <Input disabled={!canFill} />
              </Form.Field>
            )}

            <Form.Field name={['additional_data', 'beneficiary']} initialValue={true} />

            <div className="form-divider" />

            <Form.Field
              name={['additional_data', 'family_members']}
              label={t('family_members')}
              valuePropName="checked"
              initialValue={false}
            >
              {checkedFields}
            </Form.Field>

            {canFill && (
              <>
                {memberState.family_members && (
                  <Form.Field label=" " name={['additional_data', 'families']} className="pt-10">
                    <MemberList type="family" />
                  </Form.Field>
                )}

                <div className="form-divider" />

                <Form.Field
                  name={['additional_data', 'associates_members']}
                  label={t('associates_members')}
                  valuePropName="checked"
                  initialValue={false}
                >
                  {checkedFields}
                </Form.Field>

                {memberState.associates_members && (
                  <Form.Field label=" " name={['additional_data', 'associates']} className="pt-10">
                    <MemberList type="associates" />
                  </Form.Field>
                )}
              </>
            )}
          </Col>
          <Col size={6} className="py-20 px-30">
            <h3 className="form-heading">{t('identity_documents')}</h3>
            <Form.Field
              name={['passport', 'doc_type']}
              label={t('document_type')}
              rules={[{ required: true }]}
            >
              <SmartSelect
                options={docTypeOptions}
                selected={
                  companyUser.data?.passport?.doc_type && {
                    value: companyUser.data?.passport?.doc_type,
                    text: docTypeOptions.find(
                      ({ value }) => value === companyUser.data?.passport?.doc_type,
                    )?.text,
                  }
                }
                disabled={!canFill}
              />
            </Form.Field>

            <Form.Field
              name={['passport', 'attachments_id']}
              label={t('identity_card')}
              rules={[{ required: true }]}
            >
              {(control) => (
                <Upload
                  multiple
                  {...uploadProps}
                  {...control}
                  disabled={!canFill || control?.value?.length >= 2}
                >
                  <Button disabled={!canFill || control?.value?.length >= 2}>{t('upload')}</Button>
                </Upload>
              )}
            </Form.Field>

            <Form.Field
              name={['passport', 'citizenship_id']}
              label={t('citizenship')}
              rules={[{ required: true }]}
            >
              <SmartSelect
                queryKey="countries"
                api={nomenclature.getCountries}
                search
                selected={
                  companyUser.data?.passport?.citizenship && {
                    value: companyUser.data?.passport?.citizenship.id,
                    text: companyUser.data?.passport?.citizenship.name,
                  }
                }
                filters={{ limit: 300 }}
                disabled={!canFill}
              />
            </Form.Field>

            <Form.Field
              name={['passport', 'serial_number']}
              label={t('serial_number')}
              rules={[{ required: true }]}
            >
              <Input disabled={!canFill} />
            </Form.Field>

            <Form.Field
              name={['passport', 'date_receipt']}
              label={t('date_receipt')}
              rules={[{ required: true }]}
            >
              <DatePicker disabled={!canFill} />
            </Form.Field>

            <Form.Field
              name={['passport', 'receiving_office']}
              label={t('receiving_office')}
              rules={[{ required: true }]}
            >
              <Input disabled={!canFill} />
            </Form.Field>

            <Form.Group label={t('available_to')} required>
              <Form.Field
                name={['passport', 'available_to']}
                label={t('available_to')}
                hideLabel
                rules={[{ required: hasAvailableTo }]}
              >
                <DatePicker disabled={!canFill || !hasAvailableTo} />
              </Form.Field>

              <Checkbox
                text={t('missing_expiration_date')}
                checked={!hasAvailableTo}
                onChange={(v) => setHasAvailableTo(!v)}
              />
            </Form.Group>

            <div className="form-divider" />
          </Col>
        </Row>

        <div className="form-actions">
          <Space justify="end">
            <Button
              type="primary"
              submit
              loading={setTenancyUser.isLoading || addUser.isLoading || updateUser.isLoading}
            >
              {t('save')}
            </Button>
          </Space>
        </div>
      </Form>
    </Loader>
  );
};

export default BeneficiaryForm;
