import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { AvatarInline, Button, Label, Icon, firstLetters } from 'ebs-design';
import { Column, Status, History } from 'types';
import { dateTimeFormat, formatDate } from 'libs';
import { abbreviateNumber, downloadFile, formatNumber } from 'utils';
import { User, Download } from 'resources';

import { rowSpan, isEqual } from './utils';

export const useColumns = (columns): Column<History>[] => {
  const { t } = useIntl();

  const renderChecked = (value) => (
    <Label
      className="icon"
      status={value ? 'success' : 'danger'}
      type="fill"
      text={value ? <Icon type="check" model="bold" /> : <Icon type="close" />}
    />
  );

  const renderDownload = (url, type) => (
    <Button
      type={type === 'old' ? 'fill' : 'primary'}
      prefix={<Icon component={Download} />}
      onClick={() => downloadFile(url)}
    >
      {t('download')}
    </Button>
  );

  const getColumn = (history, type) => {
    const status = type === 'old' ? 'danger' : undefined;
    const otherType = type === 'old' ? 'data' : 'old';

    switch (history.column) {
      case 'url':
        return renderDownload(history[type], type);

      case 'balance':
      case 'limit':
      case 'amount':
      case 'collected':
      case 'guarantee':
      case 'commission':
      case 'minimum':
      case 'payed':
      case 'warrant':
      case 'bonus_percentage':
        return formatNumber(history[type]);

      case 'signed':
      case 'state':
      case 'confirmed':
      case 'verified':
      case 'sync_ready':
      case 'is_active':
      case 'is_superuser':
      case 'is_staff':
        return renderChecked(history[type]);

      case 'factoring_range':
        return history[type] ? history[type].maximum : '---';

      case 'conditions':
        return history[type]?.length ? history[type].join(', ') : '---';

      case 'contract':
        return (
          <div className="pc-history__cols">
            {history[type]?.date !== history[otherType]?.date && (
              <div className="pc-history__item">
                <Label type="fill" status={status} text={t('date')} />{' '}
                {history[type]?.date || '---'}
              </div>
            )}
            {history[type]?.number !== history[otherType]?.number && (
              <div className="pc-history__item">
                <Label type="fill" status={status} text="№" /> {history[type]?.number || '---'}
              </div>
            )}
          </div>
        );

      case 'status':
        return history[type] ? (
          <Label type="fill" status={Status[history[type]]} text={t(history[type].toLowerCase())} />
        ) : (
          '---'
        );

      case 'penalty':
        return history[type] ? (
          <div className="pc-history__cols">
            <div className="pc-history__item">
              <Label type="fill" status={status} text={t('daily')} />

              <div className="pc-history__cols">
                {history[type]?.daily?.currency?.amount !==
                  history[otherType]?.daily?.currency?.amount && (
                  <div className="pc-history__item">
                    <Label type="ghost" status={status} text={t('currency')} />{' '}
                    {(history[type]?.daily?.currency?.amount &&
                      `${history[type].daily.currency.amount} ${history[type]?.daily?.currency?.code_name}`) ||
                      '---'}
                  </div>
                )}
                {history[type]?.daily?.percent !== history[otherType]?.daily?.percent && (
                  <div className="pc-history__item">
                    <Label type="ghost" status={status} text={t('percent')} />{' '}
                    {history[type]?.daily?.percent || '---'}
                  </div>
                )}
                {history[type]?.daily?.state !== history[otherType]?.daily?.state && (
                  <div className="pc-history__item">
                    <Label type="ghost" status={status} text={t('state')} />{' '}
                    {renderChecked(history[type]?.daily?.state)}
                  </div>
                )}
              </div>
            </div>
            <div className="pc-history__item">
              <Label type="fill" status={status} text={t('rate')} />

              <div className="pc-history__cols">
                {history[type]?.rate?.percent !== history[otherType]?.rate?.percent && (
                  <div className="pc-history__item">
                    <Label type="ghost" status={status} text={t('percent')} />{' '}
                    {history[type]?.rate?.percent || '---'}
                  </div>
                )}
                {history[type]?.rate?.state !== history[otherType]?.rate?.state && (
                  <div className="pc-history__item">
                    <Label type="ghost" status={status} text={t('state')} />{' '}
                    {renderChecked(history[type]?.rate?.state)}
                  </div>
                )}
              </div>
            </div>
            {history[type]?.percent !== history[otherType]?.percent && (
              <div className="pc-history__item">
                <Label type="fill" status={status} text={t('percent')} />{' '}
                {history[type]?.percent || '---'}
              </div>
            )}
            {history[type]?.state !== history[otherType]?.state && (
              <div className="pc-history__item">
                <Label type="fill" status={status} text={t('state')} />{' '}
                {renderChecked(history[type]?.state)}
              </div>
            )}
          </div>
        ) : (
          '---'
        );

      case 'committee_json':
        return history[type] ? (
          <div className="pc-history__cols">
            {history[type]?.fields?.title !== history[otherType]?.fields?.title && (
              <div className="pc-history__item">
                <Label type="fill" status={status} text={t('title')} />{' '}
                {history[type]?.fields?.title || '---'}
              </div>
            )}
            <div className="pc-history__item">
              <Label type="fill" status={status} text={t('range')} />

              <div className="pc-history__cols">
                {history[type]?.fields?.factoring_range?.maximum !==
                  history[otherType]?.fields?.factoring_range?.maximum && (
                  <div className="pc-history__item">
                    <Label type="ghost" status={status} text={t('maximum')} />{' '}
                    {(history[type]?.fields?.factoring_range?.maximum &&
                      abbreviateNumber(history[type]?.fields?.factoring_range?.maximum)) ||
                      '---'}
                  </div>
                )}
                {history[type]?.fields?.factoring_range?.minimum !==
                  history[otherType]?.fields?.factoring_range?.minimum && (
                  <div className="pc-history__item">
                    <Label type="ghost" status={status} text={t('minimum')} />{' '}
                    {(history[type]?.fields?.factoring_range?.minimum &&
                      abbreviateNumber(history[type]?.fields?.factoring_range?.minimum)) ||
                      '---'}
                  </div>
                )}
              </div>
            </div>

            {history[type]?.fields?.votes_required !==
              history[otherType]?.fields?.votes_required && (
              <div className="pc-history__item">
                <Label type="fill" status={status} text={t('minimum_nr_of_votes')} />{' '}
                {history[type]?.fields?.votes_required || '---'}
              </div>
            )}
          </div>
        ) : (
          '---'
        );

      case 'data':
        return history[type] ? (
          <div className="pc-history__cols">
            {history[type].redirect_url ? (
              <div className="pc-history__item">
                <Label type="fill" status={status} text={t('url')} />{' '}
                {renderDownload(history[type].redirect_url, type)}
              </div>
            ) : null}
          </div>
        ) : (
          '---'
        );

      default:
        return history[type] || '---';
    }
  };

  const getType = (history) => {
    switch (history.column) {
      case 'url':
        return 'file';

      case 'is_active':
        return 'active';

      case 'last_login':
        return 'last_activity';

      case 'balance':
        return 'sold';

      case 'committee_json':
        return 'committee';

      case 'payed':
        return 'paid';

      case 'term_number':
        return 'term_date';

      case 'signed_date':
        return 'signed_timestamp';

      default:
        return history.column;
    }
  };

  const getHistoryColumns = (): Column<History>[] => [
    {
      title: t('who'),
      render: ({ type, user, history, data, old }) =>
        rowSpan(
          <AvatarInline
            circle
            alt={user?.name || '---'}
            shortAlt={user?.name ? firstLetters(user?.name) : <Icon component={User} />}
            description={user?.email}
          />,
          history.index === 0
            ? Object.keys(data).filter(
                (i) =>
                  (type === 'CREATE' || !isEqual(data[i], old[i])) &&
                  (!columns.length || columns.includes(i)),
              ).length
            : 0,
        ),
    },
    {
      title: t('when'),
      width: 120,
      render: ({ type, timestamp, history, data, old }) =>
        rowSpan(
          formatDate(timestamp, dateTimeFormat),
          history.index === 0
            ? Object.keys(data).filter(
                (i) =>
                  (type === 'CREATE' || !isEqual(data[i], old[i])) &&
                  (!columns.length || columns.includes(i)),
              ).length
            : 0,
        ),
    },
    {
      title: t('type'),
      width: 90,
      render: ({ type, history, data, old }) =>
        rowSpan(
          t(type.toLowerCase()),
          history.index === 0
            ? Object.keys(data).filter(
                (i) =>
                  (type === 'CREATE' || !isEqual(data[i], old[i])) &&
                  (!columns.length || columns.includes(i)),
              ).length
            : 0,
        ),
    },
    {
      title: t('changed'),
      width: 100,
      render: ({ history }) => t(getType(history)),
    },
    {
      title: t('old'),
      render: ({ type, history }) => (type !== 'CREATE' ? getColumn(history, 'old') : '---'),
    },
    {
      title: t('new'),
      render: ({ history }) => getColumn(history, 'data'),
    },
  ];

  return getHistoryColumns();
};
