import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Icon, Space, Form, Input, useForm, Loader, useNotify } from 'ebs-design';
import { company, nomenclature } from 'api';
import { useCompany } from 'hooks';
import { AssociateType, Properties } from 'types';
import { SmartSelect, InputNumericForm } from 'components';
import { extractResponseProps } from 'utils';

interface Props {
  data: Properties;
  onClose: () => void;
}

export const LegalPartnerForm: React.FC<Props> = React.memo(({ data, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const { data: companyData } = useCompany();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        country_id: data?.country?.id,
        amount: parseFloat(data.amount),
        percent: parseFloat(data.percent),
      });
    }
  }, [form, data]);

  const { mutate, isLoading } = useMutation(company.update, {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries('use-company');
      onClose();
      notify.success({ title: t('company'), description: t('success_data_save') });
    },
  });

  const handleClose = React.useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const onSubmit = React.useCallback(
    (dataBody) =>
      companyData &&
      mutate({
        companyId: companyData.id,
        data: {
          associates: [
            ...companyData.associates,
            { ...dataBody, id: data.id, type: AssociateType.LEGAL },
          ],
        },
      }),
    [companyData, mutate, data],
  );

  return (
    <Loader loading={isLoading}>
      <Form
        form={form}
        onFinish={onSubmit}
        type="horizontal"
        labelOptions={{ className: 'text-right', col: { size: 3 } }}
      >
        <Form.Field name="title" label={t('full_title')} rules={[{ required: true }]}>
          <Input />
        </Form.Field>

        <Form.Field name="idno" label={t('idno')} rules={[{ required: true }]}>
          <Input type="number" />
        </Form.Field>

        <Form.Field name="country_id" label={t('country_origin')} rules={[{ required: true }]}>
          <SmartSelect
            search
            api={nomenclature.getCountries}
            selected={{ value: data?.country?.id, text: data?.country?.name }}
          />
        </Form.Field>

        <Form.Field name="address_alternative" label={t('office')} rules={[{ required: true }]}>
          <Input />
        </Form.Field>

        <Space justify="center" className="mb-15">
          {t('amount_of_share')}
        </Space>

        <InputNumericForm name="amount" label={t('amount')} rules={[{ required: true }]} />

        <InputNumericForm name="percent" label={t('percent')} rules={[{ required: true }]} />

        <Modal.Footer style={{ margin: '0 -20px -30px' }}>
          <Space justify="space-between">
            <Button onClick={handleClose}>{t('cancel')}</Button>

            <Button
              type="primary"
              prefix={<Icon type="check" model="bold" />}
              onClick={() => form.submit()}
            >
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Loader>
  );
});
