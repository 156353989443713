import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import { Card, Loader, Table, Label } from 'ebs-design';
import { company, nomenclature } from 'api';
import { stringifyCategoryData, mergeClassifiersData } from 'utils';
import { Layout } from 'components';
import { ClassifierEntity, Column } from 'types';

import { AdherentLayout } from '../AdherentLayout';
import { AdherentProfile } from '../AdherentProfile';

export const Scoring: React.FC = () => {
  const { id } = useParams();
  const { t } = useIntl();

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  const { data: classifiers = [] } = useQuery(
    ['classifiers'],
    () => nomenclature.getClassifiers(),
    {
      enabled: Boolean(id),
      select: (data) => data.results,
    },
  );

  const columns: Column<ClassifierEntity>[] = [
    {
      title: t('step'),
      dataIndex: 'step',
      className: 'text-center',
      render: (value, rowData, index) => {
        const rowProps = {
          children: value,
          props: { rowSpan: 1 },
        };

        if (index === 1) {
          rowProps.props.rowSpan = tableData.length - 2; // first and last row
        }

        if (index > 1 && index < tableData.length - 1) {
          rowProps.props.rowSpan = 0;
          rowProps.children = '';
        }

        if (typeof value === 'boolean') {
          rowProps.props.rowSpan = 1;
          rowProps.children = <span className="fw-600">{t('total')}</span>;
        }

        return rowProps;
      },
    },
    {
      title: t('classifier'),
      dataIndex: 'title',
    },
    {
      title: t('formulas'),
      dataIndex: 'renderFormula',
      render: (value) =>
        value
          ? value.map((item) => {
              if (!isNaN(Number(item as string))) {
                return (
                  <Label
                    status="danger"
                    type="ghost"
                    text={`rd.${item}`}
                    className="pc-classifier__formula"
                  />
                ) as React.ReactNode;
              }

              return item;
            })
          : [],
    },
    {
      title: t('norm'),
      dataIndex: 'classifier',
      render: (value) => (value ? stringifyCategoryData(value, t) : '---'),
    },
    {
      title: t('scoring'),
      dataIndex: 'score',
    },
    {
      title: t('weight_score'),
      dataIndex: 'value',
    },
    {
      title: t('accumulated'),
      dataIndex: 'evaluate',
    },
    {
      title: t('category'),
      dataIndex: ['category', 'code_name'],
      className: 'text-center',
      render: (value, _, index) => {
        const rowProps = {
          children: '',
          props: { rowSpan: 0 },
        };

        if (index === 0) {
          rowProps.props.rowSpan = tableData.length;
          rowProps.children = value;
        }
        return rowProps;
      },
    },
  ];

  const tableData = mergeClassifiersData(data, classifiers);

  return (
    <Layout>
      <AdherentLayout>
        <Loader loading={isLoading}>{data && <AdherentProfile data={data} />}</Loader>
        <Card collapsible>
          <Card.Header bordered>{t('information_analyzed')}</Card.Header>
          <Card.Body className="p-0">
            <Table className="table-no-border" columns={columns} data={tableData} emptyCell="---" />
          </Card.Body>
        </Card>
      </AdherentLayout>
    </Layout>
  );
};
