import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Icon, Select, Upload } from 'ebs-design';
import { AttachmentType, Contract } from 'types';
import { Plus } from 'resources';
import { useUpload } from 'hooks';
import { SelectedContractTemplateObject } from './Contracts';
import { useQueryClient } from 'react-query';

interface CustomCompanyTemplateInterface {
  contract: Contract;
  attachmentsDataOptions: any[];
  onTemplateChange: Dispatch<SetStateAction<SelectedContractTemplateObject>>;
  isTemplatesLoading: boolean;
  isLoading: boolean;
}

export const CustomCompanyTemplate = ({
  contract,
  attachmentsDataOptions,
  onTemplateChange,
  isTemplatesLoading,
  isLoading,
}: CustomCompanyTemplateInterface) => {
  const queryClient = useQueryClient();
  const uploadProps = useUpload();

  const [value, setValue] = useState(contract.contract_template?.attachment.id);

  const onValueChange = (val) => {
    if (!contract.contract_template) {
      const foundAttachment = attachmentsDataOptions.find((i) => i.value === val);

      setValue(val);

      if (onTemplateChange)
        onTemplateChange({
          contract: contract,
          attachment: { id: val, name: foundAttachment.text },
          isNew: true,
        });

      return;
    }

    setValue(val);

    if (onTemplateChange) onTemplateChange({ contract: contract, attachment: { id: val } });
  };

  const onNewAttachmentUpload = async (files) => {
    await queryClient.invalidateQueries(['template-attachments']);

    if (files[0]) {
      setValue(files[0].id);

      onTemplateChange({
        contract: contract,
        attachment: { name: files[0].name, id: files[0].id },
        isNew: !contract.contract_template,
      });
    }
  };

  return (
    <Select
      value={value}
      onChange={onValueChange}
      suffix={
        <Upload
          data={{
            type: AttachmentType.TEMPLATE,
          }}
          onSuccess={onNewAttachmentUpload}
          className="h-100"
          {...uploadProps}
        >
          <Button>
            <Icon component={Plus} />
          </Button>
        </Upload>
      }
      options={attachmentsDataOptions}
      loading={isTemplatesLoading || isLoading}
    />
  );
};
