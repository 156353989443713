import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from 'ebs-design';
import { UserContext } from 'contexts';
import { useModules } from 'hooks';
import { getRoute } from 'utils';
import { routes } from 'routes';

export const IndexEntry: React.FC = () => {
  const history = useHistory();
  const { indexModule } = useModules();
  const { logged } = React.useContext(UserContext);

  React.useEffect(() => {
    // Redirect user to dashboard
    if (logged && indexModule.route) {
      return history.push(getRoute(routes, indexModule.route));
    }

    return history.push(getRoute(routes, 'SignInPage'));
  }, [logged, history, indexModule]);

  return <Loader loading fixed />;
};
