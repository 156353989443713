import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Button, ButtonGroup } from 'ebs-design';

import { ExistingUserForm } from './ExistingUserForm';
import { NewUserForm } from './NewUserForm';

interface Props {
  title: string;
  userId?: number;
  companyId?: string;
  visible: boolean;
  onClose: () => void;
}

type UserType = 'new' | 'existing';

export const AddUserModal: React.FC<Props> = ({
  visible = false,
  title,
  userId,
  companyId,
  onClose,
}) => {
  const { t } = useIntl();
  const [userType, setUserType] = React.useState<UserType>('new');

  const handleClose = (): void => {
    // Reset user type
    setUserType('new');
    onClose();
  };

  return (
    <Modal open={visible} onClose={handleClose} title={title} size="small">
      <Modal.Content className="px-20 py-30">
        <ButtonGroup className="mb-20 justify-content--center">
          <Button
            type={userType === 'new' ? 'primary' : 'ghost'}
            onClick={() => setUserType('new')}
          >
            {t('add_user')}
          </Button>
          <Button
            type={userType === 'existing' ? 'primary' : 'ghost'}
            onClick={() => setUserType('existing')}
          >
            {t('select_user')}
          </Button>
        </ButtonGroup>

        {userType === 'new' ? (
          <NewUserForm userId={userId} companyId={companyId} onClose={handleClose} />
        ) : (
          <ExistingUserForm userId={userId} companyId={companyId} onClose={handleClose} />
        )}
      </Modal.Content>
    </Modal>
  );
};
