import env from '@beam-australia/react-env';
import { Properties } from 'types';

export const defaultFilters: Properties = {
  page: 1,
  limit: +env('REQUEST_LIMIT') || 15,
};

export const turnoverDefaultFilters: Properties = {
  page: 1,
  limit: 50,
};

export const defaultCompanyFilters = {
  know_your_client_confirmed: null,
  waiting_for_approval: null,
  request_status: null,
};
