import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { Loader, Card, Space, Row, Col, AvatarInline, Label, Badge } from 'ebs-design';
import { company, requests } from 'api';
import { Layout } from 'components';

import { DebtorLayout } from '../DebtorLayout';
import { DebtorProfile } from '../DebtorProfile';
import { formatNumber, abbreviateNumber } from 'utils';
import { Status } from 'types';

export const CreditCommittee: React.FC = () => {
  const { id } = useParams();
  const { t } = useIntl();

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
  });

  const { data: dataRequest, isLoading: isLoadingVotes } = useQuery(
    ['company-request'],
    () => requests.get(data!.request.id.toString(), true),
    {
      enabled: !!data?.request,
    },
  );

  return (
    <Layout>
      <DebtorLayout>
        <Loader loading={isLoading}>{data && <DebtorProfile data={data} />}</Loader>
        <Card collapsible>
          <Card.Header bordered>
            <Space justify="space-between">
              <>
                {t('SY_CREDIT_COMMITTEE')} (
                {(!!data?.request && dataRequest?.committee?.votes_required) || 0})
              </>
            </Space>
          </Card.Header>

          <Card.Body>
            <Loader loading={isLoadingVotes}>
              <Row gy={4}>
                {!!data?.request && dataRequest?.committee ? (
                  dataRequest.committee.users.map((user, i) => {
                    const vote = dataRequest.votes.find((vote) => vote.user.id === user.id);

                    return (
                      <Col key={i} size={12} sm={6} md={4}>
                        <Badge
                          text={vote && t(vote.status)}
                          type={vote ? (Status[vote.status] as any) : undefined}
                        >
                          <Card>
                            <Card.Header>
                              <AvatarInline
                                circle
                                alt={`${user.first_name} ${user.last_name}`}
                                description={`${dataRequest.committee.title} - ${t(
                                  'until',
                                )} ${abbreviateNumber(
                                  dataRequest.committee.factoring_range.maximum,
                                )} MDL`}
                              />
                            </Card.Header>
                            {vote?.comment && (
                              <Card.Body className="bg-white">
                                <b>{t('comment')}</b>
                                <p>{vote.comment.comment}</p>
                              </Card.Body>
                            )}
                            <Card.Footer className={cn({ 'no-border': !vote?.comment })}>
                              <Space justify="space-between">
                                <Space size="small" align="start" direction="vertical">
                                  <Label
                                    status="danger"
                                    text={<span className="fw-600">{t('limit')}</span>}
                                  />
                                  <>{vote ? `${formatNumber(vote?.limit)} MDL` : '---'}</>
                                </Space>
                              </Space>
                            </Card.Footer>
                          </Card>
                        </Badge>
                      </Col>
                    );
                  })
                ) : (
                  <Space justify="center">{t('no_data')}</Space>
                )}
              </Row>
            </Loader>
          </Card.Body>
        </Card>
      </DebtorLayout>
    </Layout>
  );
};
