// eslint-disable-next-line import/default
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(customParseFormat);

export const today = dayjs();
export const timeFormat = 'HH:mm';
export const dateFormat = 'dd.MM.yyyy';
export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateTimePickerFormat = 'dd.MM.yyyy HH:mm';
export const yearMothDayFormat = 'YYYY-MM-DD';
export const dayMothYearFormat = 'DD.MM.YYYY';

export const formatDate = (date?: Date | string, format?: string): string =>
  dayjs(date).format(format || dateFormat.toUpperCase());

export const formatDateTime = (date?: Date | string, format?: string): string =>
  dayjs(date).format(format || dateTimeFormat.toUpperCase());

export const formatDateFilters = (
  date?: Date | string | null,
  format?: string,
  initialFormat?: string,
): string => dayjs(date, initialFormat || dayMothYearFormat).format(format || yearMothDayFormat);

export const toISO = (date: Date | string): string => new Date(date).toISOString();
export const getYear = (date: Date | string): string => dayjs(date).format('YYYY');
export const getMonth = (date: Date | string): string => dayjs(date).format('MMM');
export const getFullMonth = (date: Date | string): string => dayjs(date).format('MMMM');
export const getDay = (date: Date | string): string => dayjs(date).format('D');
export const getTime = (date: Date | string): string => dayjs(date).format('HH:mm');

export const isAfter = (date: Date | string): boolean => dayjs().isAfter(date);
export const isBefore = (date: Date | string): boolean => dayjs().isBefore(date);
export const add = (count: number): dayjs.Dayjs => dayjs().add(count, 'day');

export const getAPIDateFormat = (date: string): string => {
  const data = date.split(' ');

  return `${data[0].split('.').reverse().join('-')}${data.length > 1 ? ` ${data[1]}` : ``}`;
};

export const tomorrow = dayjs().add(1, 'day');
export const yesterday = dayjs().subtract(1, 'day');
export const currentDay = dayjs().format(yearMothDayFormat);
export const firstDayOfYear = dayjs().startOf('year').format(yearMothDayFormat);
export const currentDayDateFormat = dayjs().format(dayMothYearFormat);
export const firstDayOfYearDateFormat = dayjs().startOf('year').format(dayMothYearFormat);
export const firstDayOfMonthDateFormat = dayjs().startOf('month').format(dayMothYearFormat);

export const isDate = (value: any): boolean => {
  return dayjs(value).isValid();
};
