import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import {
  Table,
  AvatarInline,
  Card,
  Space,
  InputSearch,
  SortBy,
  Actions,
  Loader,
  firstLetters,
  Label,
  Switch,
  useNotify,
} from 'ebs-design';
import { Pagination } from 'components';
import { users } from 'api';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { formatDate, dateTimeFormat } from 'libs';
import { Results, User, Properties, SettingType, Unit, SystemRole } from 'types';
import { defaultFilters, getSortOptions, extractResponseProps } from 'utils';

import { AddUserModal } from './AddUserModal';
import { InviteSystemUserModal } from './InviteSystemUserModal';

const keyUsers = 'USERS';

export const Users: React.FC = () => {
  const { t } = useIntl();
  const { role } = useParams();
  const notify = useNotify();
  const { invite, add, ...params } = useQueryParams();
  const queryClient = useQueryClient();
  const { updateQuery } = useQueryUpdate();
  const [inviteUser, setInviteUser] = React.useState(false);
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });

  // Add new user state for the modal form
  const [user, setUser] = useSetState<{ visible: boolean; data: Partial<User<string>> }>({
    visible: false,
    data: {},
  });

  React.useEffect(() => updateQuery(filters), [filters, updateQuery]);
  React.useEffect(() => setFilters({ page: 1 }), [role, setFilters]);
  React.useEffect(() => {
    if (invite) {
      setInviteUser(true);
      updateQuery({ ...filters, invite: undefined });
    }
    // eslint-disable-next-line
  }, [invite]);

  React.useEffect(() => {
    if (add) {
      updateQuery(filters);

      setUser({ visible: true });
    }
  }, [add, filters, updateQuery, setUser]);

  const { data, isLoading } = useQuery<Results<User>>(
    ['users', { ...filters, roles: role }],
    users.getUsers,
    {
      enabled: !!role && role !== keyUsers,
    },
  );

  const deleteUser = useMutation((id: number) => users.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      notify.success({ title: t('users'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const patchUser = useMutation((params: Properties) => users.updateSettings(params), {
    onMutate: async (params: Properties) => {
      const query = ['users', { ...filters, roles: role }];
      const prevData = queryClient.getQueryData(query) as Results<User>;

      queryClient.setQueryData(query, {
        ...prevData,
        results: prevData.results.map((user) => {
          if (user.id === params.id) {
            user.settings[params.code_name] = params.state;
          }

          return user;
        }),
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onError: (err, _, rollback: any) => rollback(),
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

  const userUpdate = useMutation((params: Properties) => users.update(params), {
    onMutate: async ({ id, ...params }) => {
      const query = ['users', { ...filters, roles: role }];
      const prevData = queryClient.getQueryData(query) as Results<User>;

      queryClient.setQueryData(query, {
        ...prevData,
        results: prevData.results.map((result) =>
          result.id === id ? { ...result, ...params } : result,
        ),
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onError: ({ response }, _, rollback: any) => {
      if (response?.data) {
        Object.keys(response.data).map((i) =>
          notify.error({ title: i, description: response.data[i].join('<br>') }),
        );
      }

      rollback();
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

  const onCanReceiveChange = React.useCallback(
    (id, state) => patchUser.mutate({ id, code_name: SettingType.TAKE_TASKS, state }),
    [patchUser],
  );

  // Tip: to check role [SystemRole.SY_ADMIN].includes(role as SystemRole)
  const columns = React.useMemo(
    () => [
      {
        title: t('name'),
        filters: ['desc', 'asc'],
        filter: 'first_name',
        render: ({ first_name, last_name, email }) => {
          const title = [first_name, last_name].join(' ');

          return (
            <AvatarInline
              circle
              alt={title}
              shortAlt={(title && firstLetters(title)) || email[0].toUpperCase()}
              description={email}
            />
          );
        },
        width: 250,
      },
      {
        title: t('roles'),
        render: ({ roles }) => roles.map((role) => t(role.name || role)).join(', '),
      },
      {
        title: t('status'),
        render: ({ id, is_active }) => (
          <Label
            type="fill"
            status={is_active ? 'success' : 'danger'}
            circle
            text={is_active ? t('active') : t('inactive')}
            onClick={() => userUpdate.mutate({ id: id, is_active: !is_active })}
            className="cursor-pointer"
          />
        ),
      },
      {
        title: t('registred_at'),
        render: ({ date_joined }) => date_joined && formatDate(date_joined, dateTimeFormat),
      },
      {
        title: t('receives_tasks'),
        render: ({ id, settings }) => {
          const state = settings.find((i) => i.code_name === SettingType['TAKE_TASKS'])?.state;

          return (
            <Space>
              <Switch onChange={() => onCanReceiveChange(id, !state)} checked={state} />
            </Space>
          );
        },
        width: 100,
      },
      ...([SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR].includes(
        SystemRole[role as string],
      )
        ? [
            {
              title: t('bonus_earned'),
              children: [
                {
                  title: Unit.PERCENT,
                  dataIndex: 'bonus_percentage',
                },
                {
                  title: t('amount'),
                  dataIndex: 'bonus_percentage_amount',
                },
              ],
            },
          ]
        : []),
      {
        title: null,
        action: true,
        render: (rowData) => (
          <Actions>
            <Actions.Item onClick={() => setUser({ visible: true, data: rowData })}>
              {t('edit')}
            </Actions.Item>
            <Actions.Item onClick={() => deleteUser.mutate(rowData.id)}>{t('delete')}</Actions.Item>
          </Actions>
        ),
      },
    ],
    [t, setUser, deleteUser, onCanReceiveChange, role],
  );

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  return (
    <>
      <Space align="center" justify="space-between" className="mt-5 mb-20 pt-20">
        <Space align="center">
          <h3 className="page-title">
            {t(role as string)} ({data?.count || 0})
          </h3>
          <InputSearch
            placeholder={t('search')}
            styleType="fill"
            value={filters.search}
            onSearch={(search) => setFilters({ search, page: 1 })}
            isClearable
          />
        </Space>

        <SortBy
          options={sortOptions}
          value={filters?.ordering}
          onChange={(ordering) => setFilters({ ordering })}
        />
      </Space>
      <Card>
        <Card.Body className="p-0">
          <Loader loading={isLoading}>
            <Table
              className="table-no-border"
              columns={columns}
              data={data?.results}
              emptyCell="---"
            />

            {user.visible && (
              <AddUserModal
                role={role as string}
                data={user.data}
                onClose={() => setUser({ visible: false, data: {} })}
              />
            )}
            {inviteUser && (
              <InviteSystemUserModal role={role as string} onCancel={() => setInviteUser(false)} />
            )}
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination data={data} filters={filters} setFilters={setFilters} />
        </Card.Footer>
      </Card>
    </>
  );
};
