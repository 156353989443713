import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Space, Loader, Row, Col, DatePicker, Label } from 'ebs-design';
import { useReports } from 'hooks';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfMonthDateFormat,
  formatDateFilters,
} from 'libs';
import { ReportType, ReportInspectorGraph } from 'types';
import { InspectorsChart } from './charts';
import { InspectorsTable } from './tables';
import { RangePickerStateProps } from './utils';

export const InspectorsReport: React.FC = () => {
  const { t } = useIntl();
  const [range, setRange] = React.useState<RangePickerStateProps>([null, null]);
  const [inspector, setInspector] = React.useState<ReportInspectorGraph>();

  const { data, isLoading } = useReports<ReportInspectorGraph[], ReportInspectorGraph[]>(
    ReportType.GRAPHS_INSPECTORS,
    {
      ...(range?.[0] && { date_from: range?.[0] && formatDateFilters(range?.[0]) }),
      ...(range?.[1] && { date_to: range?.[1] && formatDateFilters(range?.[1]) }),
    },
    {
      keepPreviousData: true,
      select: (data = []) => {
        const updatedData = data.reduce((acc, cur) => {
          acc[cur.users_id] = {
            ...cur,
            ...acc[cur.users_id],
            invoices_amounts: (acc[cur.users_id]?.invoices_amounts || 0) + cur.invoices_amounts,
            invoices_counts: (acc[cur.users_id]?.invoices_counts || 0) + cur.invoices_counts,
            data: [
              // eslint-disable-next-line
              ...(acc[cur.users_id]?.data ? acc[cur.users_id]?.data : []),
              {
                month: cur.month,
                name: t(`month${cur.month}`),
                amount: cur.invoices_amounts,
                count: cur.invoices_counts,
              },
            ],
          };

          return acc;
        }, {});

        return Object.values(updatedData);
      },
    },
  );

  React.useEffect(() => {
    setRange([firstDayOfMonthDateFormat, currentDayDateFormat]);
  }, []);

  return (
    <>
      <Space wrap className="mb-15" align="start" direction="vertical">
        <h4>{t('inspector_sales')}</h4>

        <Space>
          <Label text="Period" />
          <DatePicker.RangeInput
            value={range}
            onChange={(value) => setRange(value as any)}
            isClearable
            placeholderText={t('choose_period')}
            dateFormat={dateFormat}
          />
        </Space>
      </Space>
      <Loader loading={isLoading}>
        <Row>
          <Col size={5}>
            <InspectorsTable data={data} onRowClick={setInspector} />
          </Col>
          <Col size={7}>
            <InspectorsChart data={inspector} />
          </Col>
        </Row>
      </Loader>
    </>
  );
};
