import { default as queryString } from 'query-string';
import { axios, cancelTokenHandler } from 'libs';
import { Results, Properties, Attachment } from 'types';
import { transformResponse } from 'utils';

export const attachments = {
  getList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Attachment>>(
      queryString.stringifyUrl(
        {
          url: `/attachments/list/`,
          query: queryKey[1],
        },
        { skipEmptyString: true },
      ),
      {
        cancelToken: cancelToken(attachments.getList.name),
      },
    );

    return data;
  },

  get: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Attachment>(`/attachments/${queryKey[1]}/`, {
      cancelToken: cancelToken(attachments.getList.name),
    });

    return data;
  },

  getXML: async (id: number) => {
    const { data } = await axios.get<Properties>(`/attachments/${id}/xml-dictionary/`, {
      cancelToken: cancelToken(attachments.getXML.name),
    });

    return data;
  },

  getSign: async (id: number, redirect_url) => {
    const { data } = await axios.get<Properties>(
      `/attachments/${id}/sign/?redirect_url=${redirect_url}`,
      {
        cancelToken: cancelToken(attachments.getXML.name),
      },
    );

    return data;
  },

  create: async (bodyData) => {
    const { data } = await axios.post<Attachment>(`/attachments/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(attachments.create.name),
    });

    return data;
  },

  delete: async (id: number) => {
    const { data } = await axios.delete(`/attachments/${id}/`);

    return data;
  },

  getInvoiceParsedData: async (id: number) => {
    const { data } = await axios.get(`attachments/${id}/pdf-dictionary/`);

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(attachments);
