import { Properties } from 'types';

export enum Permissions {
  ADHERENTS = 'adherents',
  DEBTORS = 'debtors',
  HOME = 'home',
  MODULES = 'modules',
  INVOICES = 'invoices',
  PROFILE = 'profile',
  REQUESTS = 'requests',
  USERS = 'users',
  REPORTS = 'reports',
  TURNOVERS = 'turnovers',
}

export interface PermissionEntity {
  module?: 'string';

  // CRUD operations
  read: Properties;
  create: Properties;
  edit: Properties;
  delete: Properties;

  // Other actions to perform
  perform: Properties;
}
