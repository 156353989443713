import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Container } from 'ebs-design';
import { Layout } from 'components/organisms';
import { UserContext } from 'contexts';
import FormWizard from '../FormWizard';
import GeneralDataForm from '../GeneralDataForm/GeneralDataForm';
import Beneficiaries from '../Beneficiaries/Beneficiaries';
import UsersData from '../UsersData/UsersData';
import FoundersData from '../FoundersData/FoundersData';
import { useCompanyUsers } from 'hooks';
import { CustomerRole } from 'types';

export const CompanyForm: React.FC = () => {
  const { t } = useIntl();
  const { user } = React.useContext(UserContext);
  const companyUsers = useCompanyUsers({
    roles__in: [
      CustomerRole.REPRESENTATIVE,
      CustomerRole.ACCOUNTANT,
      CustomerRole.ADMINISTRATOR,
    ].join('__'),
    is_active: true,
    limit: 50,
  });

  const isSomeExcludeAttachment = React.useMemo(
    () =>
      companyUsers.data?.results.some(
        (el) => el.roles.includes(CustomerRole.ADMINISTRATOR) && !el.passport.attachment,
      ),
    [companyUsers.data],
  );

  const steps = React.useMemo(
    () => [
      {
        title: t('general_data'),
        component: <GeneralDataForm />,
        completed: !!user?.company?.id,
      },
      {
        title: t('founders_data'),
        component: <FoundersData />,
        disabled: !user?.company,
      },
      {
        title: t('users_data'),
        component: <UsersData />,
        disabled: !user?.company,
      },
      {
        title: t('beneficiaries_data'),
        component: <Beneficiaries />,
        disabled: !user?.company || isSomeExcludeAttachment,
        description: t('politically_exposed_info'),
      },
    ],
    [user, t, isSomeExcludeAttachment],
  );

  return (
    <Layout>
      <Container>
        <FormWizard steps={steps} currentStep={0} />
      </Container>
    </Layout>
  );
};
