import { axios, cancelTokenHandler } from 'libs';
import { Properties } from 'types';

export const common = {
  getFile: async (url: string, options?: Properties) => {
    const { data } = await axios.get<string>(url, {
      responseType: 'blob',
      ...options,
    });
    return window.URL.createObjectURL(data);
  },

  requestCall: async (bodyData: Properties) => {
    const { data } = await axios.post(`/commons/requests/calls/`, bodyData, {
      cancelToken: cancelToken(common.requestCall.name),
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(common);
