import { ReactNode } from 'react';
import { OrderBy, Column } from 'types';

export const sortByType = (a, b, type, order: OrderBy = OrderBy.DESC): number => {
  if (a[type] < b[type]) {
    return order === OrderBy.DESC ? -1 : 1;
  }

  if (a[type] > b[type]) {
    return order === OrderBy.DESC ? 1 : -1;
  }

  return 0;
};

export const getSortOptions = <T = any>(
  columns: Column<T>[],
): { title: ReactNode; value: string }[] =>
  columns
    .filter((column) => column.filter)
    .map((column) => ({
      title: column.title,
      value: column.filter as string,
    }));
