import cn from 'classnames';
import { Results, Committee, Properties } from 'types';

export const transformData = (data: Results<Committee>): Properties => ({
  ...data,
  results: data.results.reduce<Properties[]>((acc, item) => {
    if (!item.users.length) {
      return [
        ...acc,
        {
          ...item,
          user: null,
          _cellData: {
            rowSpan: 1,
            className: '',
          },
        },
      ];
    }

    return [
      ...acc,
      ...item.users.map((user, index) => ({
        ...item,
        _user: user,
        _cellData: {
          rowSpan: index === 0 ? item.users.length : 0,
          className: cn({ 'table-cell--border-bottom-default': index !== item.users.length - 1 }),
        },
      })),
    ];
  }, []),
});
