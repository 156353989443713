import { History, Properties } from 'types';

interface HistoryExtended extends History {
  history: { old: Properties; data: Properties; column: string; index: number };
}

export const isEqual = (old, data): boolean =>
  old && typeof old === 'object' ? JSON.stringify(old) === JSON.stringify(data) : old === data;

const getHistoryObject = (item: History, i: string, index: number) => {
  let old,
    oldData = {},
    data,
    newData = {};

  if (!i.endsWith('_json')) {
    if (item.old[i] && typeof item.old[i] === 'object') {
      Object.keys(item.old[i]).forEach((key) => {
        if (!isEqual(item.old[i][key], item.data[i][key])) {
          oldData[key] = item.old[i][key];
          newData[key] = item.data[i][key];
        }
      });
    } else {
      oldData = item.old[i];
      newData = item.data[i];
    }

    return { old: oldData, data: newData, column: i, index };
  }

  if (item.old[i]) {
    try {
      old = JSON.parse(item.old[i]);
    } catch {
      old = undefined;
    }
  }

  if (item.data[i]) {
    try {
      data = JSON.parse(item.data[i]);
    } catch {
      data = undefined;
    }
  }

  Object.keys(old).forEach((key) => {
    if (!isEqual(old[key], data[key])) {
      oldData[key] = old[key];
      newData[key] = data[key];
    }
  });

  return { old: oldData, data: newData, column: i, index };
};

export const formatData = (items: History[], columns: string[]) => {
  const response: HistoryExtended[] = [];

  items.forEach((item) => {
    let index = 0;

    Object.keys(item.data).forEach((i) => {
      if (
        (item.type === 'CREATE' || !isEqual(item.old[i], item.data[i])) &&
        (!columns.length || columns.includes(i))
      ) {
        const history = getHistoryObject(item, i, index);

        response.push({
          ...item,
          history,
        });

        index++;
      }
    });
  });

  return response;
};

export const rowSpan = (value, length) => ({
  children: value,
  props: {
    rowSpan: length,
    className: 'px-20 table-cell--border-right-none',
  },
});
