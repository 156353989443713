import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Card } from 'ebs-design';
import {
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import { abbreviateNumber } from 'utils';
import { chartColors } from '../utils';
import { InvoicesMonthlyChartItem } from '../interfaces';

export const InvoicesMonthlyChart: React.FC<{ data?: InvoicesMonthlyChartItem[] }> = ({
  data = [],
}) => {
  const { t } = useIntl();

  return (
    <Card className="report-chart__card">
      <Card.Body>
        <ResponsiveContainer width={800} height={350}>
          <ComposedChart data={data}>
            <defs>
              <linearGradient id="colorLine1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartColors.line} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartColors.line} stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" scale="band" />
            <YAxis type="number" tickFormatter={(value) => abbreviateNumber(value)} />
            <YAxis
              yAxisId="right"
              orientation="right"
              scale="auto"
              type="number"
              tickFormatter={(value) => abbreviateNumber(value)}
            />
            <Tooltip />
            <Legend />
            <Bar name={t('sales_sum')} dataKey="amount" barSize={20} fill={chartColors.bar} />
            <Area
              type="monotone"
              name={t('sales_nr')}
              yAxisId="right"
              dataKey="total"
              stroke={chartColors.line}
              fillOpacity={1}
              fill="url(#colorLine1)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};
