import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Document, Download } from 'resources';
import { useIntl } from 'estafette-intl';
import {
  Table,
  Card,
  Space,
  Button,
  InputSearch,
  Icon,
  SortBy,
  Loader,
  Modal,
  useNotify,
} from 'ebs-design';
import { useSetState } from 'react-use';
import { formatDate } from 'libs';
import { useFileFetch, useFilters } from 'hooks';
import { defaultFilters, downloadFile, getSortOptions } from 'utils';
import { Pagination, PDFViewer } from 'components';
import { attachments, contracts as contractsAPI } from 'api';
import { Attachment, Column, Contract, Properties } from 'types';

import { MyProfileLayout } from '../../MyProfileLayout';
import { templates } from 'api/templates';
import { CustomCompanyTemplate } from './CustomCompanyTemplate';
import { TemplateRow } from './TemplateRow';

type defaultCreateTemplateDataType = {
  id?: number;
  name: string;
  attachment: number;
  company: number;
};

export interface SelectedContractTemplateObject {
  contract?: Contract;
  attachment?: { id: number; name?: string };
  isNew?: boolean;
}

export const Contracts: React.FC = () => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [filters, setFilters] = useFilters(defaultFilters);
  const [unsignedFilters, setUnsignedFilters] = useSetState({
    ...defaultFilters,
    ordering: '-edited_timestamp',
    search: '',
  });

  const unsignedContracts = useQuery(['unsigned-contracts', unsignedFilters], () =>
    contractsAPI.getList({ ...unsignedFilters, signed: false }),
  );

  const signedContracts = useQuery(['signed-contracts', filters], () =>
    contractsAPI.getList({ ...filters, signed: true }),
  );

  const { data: defaultTemplatesData, isLoading: isDefaultTemplatesLoading } = useQuery(
    ['default-templates'],
    () => templates.getList({ is_default: true }),
  );

  const { data: attachmentsData, isLoading: isAttachmentsLoading } = useQuery(
    ['template-attachments', { type: 'TEMPLATE' }],
    attachments.getList,
  );

  const [contract, setContract] = React.useState<Attachment | null>(null);
  const [customContractTemplateHolder, setCustomContractTemplateHolder] =
    React.useState<SelectedContractTemplateObject>({ contract: undefined, attachment: undefined });

  const file = useFileFetch(contract?.url);

  const { mutate: createTemplateMutation } = useMutation(
    (data: Properties) => (data.id ? templates.save(data) : templates.add(data)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['unsigned-contracts', filters]);
        queryClient.invalidateQueries(['template-attachments']);

        setCustomContractTemplateHolder({});

        notify.success({ title: t('client_template'), description: t('success_data_change') });
      },
      onError: () => {
        setCustomContractTemplateHolder({});

        notify.error({ title: t('client_template'), description: t('error_data_change') });
      },
    },
  );

  React.useEffect(() => {
    const onCustomContractChange = () => {
      if (customContractTemplateHolder.contract) {
        let createTemplateData: defaultCreateTemplateDataType = {
          name: '',
          attachment: 0,
          company: customContractTemplateHolder.contract.company.id,
        };

        if (!customContractTemplateHolder.isNew) {
          const foundAttachment = attachmentsData?.results.find(
            (i) => i.id === customContractTemplateHolder.attachment?.id,
          );

          if (foundAttachment) {
            createTemplateData = {
              ...createTemplateData,
              id: customContractTemplateHolder.contract.contract_template.id,
            };
          }
        }

        createTemplateMutation({
          ...createTemplateData,
          name: `${customContractTemplateHolder.contract.id}-${customContractTemplateHolder.attachment?.name}`,
          attachment: customContractTemplateHolder.attachment?.id || 0,
        });
      }
    };

    onCustomContractChange();
  }, [customContractTemplateHolder]);

  const onChangeSort = (ordering) => setFilters({ ordering });
  const onChangeSearch = (search) => setFilters({ search });
  const onUnsignedSortChange = (ordering) => setUnsignedFilters({ ordering });
  const onUnsignedSearchChange = (search) => setUnsignedFilters({ search });

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('contract_number'),
        dataIndex: 'number',
        filter: 'number',
        width: '12%',
      },
      {
        title: t('document'),
        filter: 'attachment.name',
        render: (item) => (
          <Button
            type="text"
            size="small"
            prefix={<Icon component={Document} />}
            className="pc-contracts__table__button--link"
            onClick={() => {
              if (item.attachment) {
                setContract(item.attachment);
              }
            }}
          >
            {item.attachment?.name}
          </Button>
        ),
      },
      {
        title: t('created_by'),
        dataIndex: ['company', 'title'],
        filter: 'company.title',
      },
      {
        title: t('modified_date'),
        filter: 'edited_timestamp',
        render: ({ edited_timestamp }) => (edited_timestamp ? formatDate(edited_timestamp) : '---'),
      },
    ],
    [],
  );

  const attachmentsDataOptions = React.useMemo(
    () =>
      (attachmentsData && attachmentsData.results.map((i) => ({ value: i.id, text: i.name }))) ||
      [],
    [attachmentsData],
  );

  const unsignedColumns: Column<Contract>[] = React.useMemo(
    () => [
      {
        title: t('document'),
        filter: 'attachment.name',
        render: (item) => (
          <Button
            type="text"
            size="small"
            prefix={<Icon component={Document} />}
            className="pc-contracts__table__button--link"
            onClick={() => {
              if (item.attachment) {
                setContract(item.attachment);
              }
            }}
          >
            {item.attachment?.name}
          </Button>
        ),
      },
      {
        title: t('created_by'),
        dataIndex: ['company', 'title'],
        filter: 'company.title',
      },
      {
        title: t('modified_date'),
        filter: 'edited_timestamp',
        render: ({ edited_timestamp }) => (edited_timestamp ? formatDate(edited_timestamp) : '---'),
      },
      {
        title: 'Actions',
        width: 250,
        className: 'actions-col',
        render: (item) => (
          <div key={item.id}>
            <CustomCompanyTemplate
              contract={item}
              attachmentsDataOptions={attachmentsDataOptions}
              onTemplateChange={setCustomContractTemplateHolder}
              isTemplatesLoading={isAttachmentsLoading}
              isLoading={customContractTemplateHolder.contract?.id === item.id}
            />
          </div>
        ),
      },
    ],
    [attachmentsDataOptions, unsignedContracts, isAttachmentsLoading, unsignedFilters],
  );

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);
  const unsignedSortOptions = React.useMemo(
    () => getSortOptions(unsignedColumns),
    [unsignedColumns],
  );

  return (
    <MyProfileLayout>
      <Modal
        open={!!contract}
        size="large"
        onClose={() => setContract(null)}
        header={contract?.name}
      >
        <Modal.Content className="p-0">
          <Loader loading={file.isLoading}>
            <PDFViewer file={file.data!} />
          </Loader>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button
              onClick={() => contract && downloadFile(contract.url, contract.name)}
              prefix={<Icon component={Download} />}
            >
              {t('download')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>

      {defaultTemplatesData?.results.length && (
        <Space align="start" direction="vertical" className="mb-20">
          {defaultTemplatesData.results.map((i) => (
            <TemplateRow template={i} key={i.id} />
          ))}
        </Space>
      )}

      <Space align="center" justify="space-between" className="mt-5 mb-20">
        <Space align="center">
          <h3 className="page-title">
            {t('unsigned_contracts')} ({unsignedContracts.data?.count || 0})
          </h3>
          <InputSearch
            placeholder={t('search')}
            styleType="fill"
            value={unsignedFilters.search}
            onSearch={onUnsignedSearchChange}
            isClearable
          />
        </Space>
        <Space align="center">
          <SortBy
            options={unsignedSortOptions}
            value={unsignedFilters?.ordering}
            onChange={onUnsignedSortChange}
          />
        </Space>
      </Space>
      <Card>
        <Card.Body className="p-0">
          <Loader loading={unsignedContracts.isLoading}>
            <Table
              className="table-no-border pc-contracts__table"
              columns={unsignedColumns}
              data={unsignedContracts.data?.results}
              emptyCell="---"
            />
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination
            data={unsignedContracts.data}
            filters={unsignedFilters}
            setFilters={setUnsignedFilters}
          />
        </Card.Footer>
      </Card>

      <Space align="center" justify="space-between" className="mt-30 mb-20">
        <Space align="center">
          <h3 className="page-title">
            {t('signed_contracts')} ({signedContracts.data?.count || 0})
          </h3>
          <InputSearch
            placeholder={t('search')}
            styleType="fill"
            value={filters.search}
            onSearch={onChangeSearch}
            isClearable
          />
        </Space>
        <Space align="center">
          <SortBy options={sortOptions} value={filters?.ordering} onChange={onChangeSort} />
        </Space>
      </Space>
      <Card>
        <Card.Body className="p-0">
          <Loader loading={signedContracts.isLoading || isDefaultTemplatesLoading}>
            <Table
              className="table-no-border pc-contracts__table"
              columns={columns}
              data={signedContracts.data?.results}
              emptyCell="---"
            />
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination data={signedContracts.data} filters={filters} setFilters={setFilters} />
        </Card.Footer>
      </Card>
    </MyProfileLayout>
  );
};
