import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { Card, Select, Space, Table } from 'ebs-design';
import { company } from 'api';
import { formatDate } from 'libs';
import { CompanyType, FactoringType, Properties } from 'types';
import { formatHistoryCategoryRates } from 'utils';

interface Props {
  companyType?: CompanyType;
}

export const HistoryRates = ({ companyType = CompanyType.ADHERENT }: Props) => {
  const { t } = useIntl();
  const { id } = useParams();

  const [filters, setFilters] = useSetState<Properties>({
    type: companyType,
    factoring_type: FactoringType.NO_REGRESSION,
    timestamp: null,
  });

  const { data: companyHistoryDates, isLoading: isLoadingCompanyHistoryDates } = useQuery(
    [
      'nomenclatures',
      'history-dates',
      { type: companyType, factoring_type: filters.factoring_type },
    ],
    () =>
      company.getCompanyHistoryDatesList(id, {
        type: companyType,
        factoring_type: filters.factoring_type,
      }),
    {
      enabled: !!id,
      select: (data) => data?.map(({ date }) => ({ text: formatDate(date), value: date })),
    },
  );

  const { data, isLoading } = useQuery(
    ['company', 'history-rates', filters],
    () => company.getCompanyHistoryRatesById(id, filters),
    { enabled: !!id, select: (data) => formatHistoryCategoryRates(data) },
  );

  const columns = React.useMemo(
    () => [
      {
        title: t(
          filters.factoring_type === FactoringType.NO_REGRESSION
            ? 'without_right_refund'
            : 'with_right_refund',
        ),
        children: [
          {
            title: t('category'),
            render: ({ category_info, rates_length }) => ({
              children: category_info,
              props: { rowSpan: rates_length },
            }),
          },

          {
            title: t('days'),
            render: ({ value }) => (value >= 0 ? `${value} ${t('days')}` : '-'),
          },
          {
            title: t('commission'),
            dataIndex: 'commission',
            render: (commission) => `${commission}%`,
          },
          {
            title: t('guarantee'),
            dataIndex: 'guarantee',
            render: (guarantee) => `${guarantee}%`,
          },
          {
            title: t('total'),
            dataIndex: 'gc_percent',
            render: (gc_percent) => `${gc_percent}%`,
          },
        ],
      },
    ],
    [t, isLoading],
  );

  return (
    <Card>
      <Card.Header>
        <Space align="center" justify="space-between" wrap>
          <h4>{t('history_rates')}</h4>
          <Space>
            <Select
              className="pc-company-rates-select"
              value={filters.timestamp}
              onChange={(timestamp) =>
                setFilters((prevState) => ({
                  ...prevState,
                  timestamp,
                }))
              }
              loading={isLoadingCompanyHistoryDates}
              options={companyHistoryDates}
              isClearable
            />
            <Select
              className="pc-company-rates-select"
              value={filters.factoring_type}
              onChange={(factoring_type) =>
                setFilters((prevState) => ({
                  ...prevState,
                  factoring_type,
                }))
              }
              options={[
                { value: FactoringType.NO_REGRESSION, text: t('without_right_refund') },
                {
                  value: FactoringType.REGRESSION,
                  text: t('with_right_refund'),
                },
              ]}
            />
          </Space>
        </Space>
        <Space className="mt-8 mb-8">
          {data?.[0]?.user_change && <p>{`${t('email')}: ${data?.[0]?.user_change}`}</p>}
          {data?.[0]?.timestamp && <p>{`${t('date')}: ${formatDate(data?.[0]?.timestamp)}`}</p>}
        </Space>
      </Card.Header>
      <Card.Body className="p-0">
        <Table className="table-no-border pc-rates__table" columns={columns} data={data} />
      </Card.Body>
    </Card>
  );
};
