import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { Icon, Button, Collapse, Card, Space, Textarea } from 'ebs-design';
import { useSetState } from 'react-use';
import { usePermissions } from 'hooks';
import { invoices } from 'api/invoices';
import { Invoice, Properties, Permissions } from 'types';
import { Meed, Question, Delete } from 'resources';

const defaultNewCondition = {
  value: '',
  visible: false,
};

export const InvoiceConditions: React.FC<{ data?: Invoice }> = ({ data }) => {
  const refDetails = React.useRef<HTMLDivElement | null>(null);
  const { t } = useIntl();
  const { id } = useParams();
  const can = usePermissions(Permissions.INVOICES);
  const queryClient = useQueryClient();

  // New conditions state
  const [newCondition, setNewCondition] = useSetState(defaultNewCondition);

  const updateInvoice = useMutation((data: Properties) => invoices.patchInvoice(id, data), {
    onSuccess: () => {
      setNewCondition(defaultNewCondition);
      queryClient.invalidateQueries('invoice');
    },
  });

  const onScrollToDetails = React.useCallback(() => {
    if (refDetails.current) {
      document.getElementsByClassName('ebs-layout__container')[0].scrollTo({
        top: refDetails.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [refDetails]);

  // Scroll to new condition textarea
  React.useEffect(() => {
    if (newCondition.visible) {
      onScrollToDetails();
    }
  }, [newCondition.visible, refDetails, onScrollToDetails]);

  const handleAddCondition = React.useCallback(() => {
    updateInvoice.mutate({
      conditions: [...(data?.conditions || []), newCondition.value],
    });
  }, [updateInvoice, newCondition.value, data]);

  const deleteCondition = React.useCallback(
    (index) => {
      if (data) {
        updateInvoice.mutate({ conditions: data.conditions.filter((_, i) => i !== index) });
      }
    },
    [data, updateInvoice],
  );

  // Conditions
  const renderConditions = data?.conditions?.map((condition, i) => (
    <Collapse key={i} collapsed>
      <Collapse.Header>
        <Space justify="space-between">
          <Space>
            <Icon component={Meed} />
            <>
              {t('condition')} {i + 1}
            </>
          </Space>
          {can.delete?.condition && (
            <Button size="small" onClick={() => deleteCondition(i)}>
              <Icon component={Delete} />
            </Button>
          )}
        </Space>
      </Collapse.Header>
      <Collapse.Body>{condition}</Collapse.Body>
    </Collapse>
  ));

  return (
    <div ref={refDetails} className="mb-25">
      <Card>
        <Card.Header bordered>
          <Space justify="space-between">
            <h4>{t('conditions')}</h4>

            {can.create?.condition && (
              <Button
                type={newCondition.visible ? 'fill' : 'ghost'}
                prefix={<Icon component={Question} />}
                onClick={() =>
                  setNewCondition((prevState) => ({ ...prevState, visible: !prevState.visible }))
                }
              >
                {t('request_additional_information')}
              </Button>
            )}
          </Space>
        </Card.Header>
        <Card.Body className="p-0 bg-white">
          <Collapse.Group className="no-border">
            {data?.conditions?.length
              ? renderConditions
              : !newCondition.visible && <div className="p-10 text-center">{t('no_data')}</div>}

            {can.create?.condition && newCondition.visible && (
              <Collapse>
                <Collapse.Header>
                  <Space>
                    <Icon component={Meed} />
                    <>
                      {t('condition')} {(data?.conditions?.length || 0) + 1}
                    </>
                  </Space>
                </Collapse.Header>
                <Collapse.Body>
                  <Textarea
                    value={newCondition.value}
                    onChange={(value) => setNewCondition({ value })}
                  />

                  <Space justify="end" className="pt-10">
                    <Button
                      type="primary"
                      onClick={handleAddCondition}
                      disabled={!newCondition.value.length || updateInvoice.isLoading}
                    >
                      {t('add')}
                    </Button>
                  </Space>
                </Collapse.Body>
              </Collapse>
            )}
          </Collapse.Group>
        </Card.Body>
      </Card>
    </div>
  );
};
