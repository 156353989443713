import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Card, Space, Icon, Table, Loader } from 'ebs-design';
import { useQuery, useMutation } from 'react-query';
import { Invoices, Bell, Document } from 'resources/icons';
import { invoices, notifications } from 'api';
import { downloadBlobFile } from 'utils';
import { formatDate, dateTimeFormat } from 'libs';
import { Invoice, CompanyType, Column } from 'types';

interface Props {
  data: Pick<Invoice, 'id' | 'number' | 'factoring_type' | 'companies' | 'company'> | null;
  visible: boolean;
  onClose: () => void;
}

const NotificationsModal: React.FC<Props> = ({ visible = false, data, onClose }) => {
  const { t } = useIntl();
  const history = useHistory();

  // Get invoice's notifications list
  const { data: notificationList, isLoading } = useQuery(
    'invoice-notifications',
    () => invoices.getNotificationsList(data!.id),
    {
      enabled: !!data,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  // Get notifications ZIP
  const download = useMutation((id: number) => notifications.downloadZip(id), {
    onSuccess: (blobFile) => downloadBlobFile(blobFile),
  });

  const columns: Column[] = [
    {
      title: t('date'),
      dataIndex: 'timestamp',
      width: 200,
      render: (v) => formatDate(new Date(v), dateTimeFormat),
    },
    {
      title: t('notification'),
      dataIndex: 'comment',
    },
    {
      title: t('files'),
      dataIndex: 'attachments',
      width: 150,
      ellipsis: true,
      render: (attachments = []) =>
        attachments.map((attachment) => (
          <div key={attachment.id}>
            <Space>
              <Icon component={Document} />
              <a
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                className="truncate"
                style={{ width: 100, display: 'block' }}
                title={attachment.name}
              >
                {attachment.name}
              </a>
            </Space>
          </div>
        )),
    },
    {
      title: t('download'),
      dataIndex: 'id',
      width: 120,
      render: (id) => {
        return (
          <Button
            size="small"
            type="primary"
            onClick={() => download.mutate(id)}
            loading={download.isLoading}
          >
            {t('download')}
          </Button>
        );
      },
    },
  ];

  const companyId = React.useMemo(
    () => ({
      [CompanyType.DEBTOR]: data?.companies?.find(
        (company) => company.types.includes(CompanyType.DEBTOR) && data?.company.id !== company.id,
      )?.id,
      [CompanyType.ADHERENT]: data?.companies?.find(
        (company) =>
          company.types.includes(CompanyType.ADHERENT) && data?.company.id === company.id,
      )?.id,
    }),
    [data],
  );

  const handleClick = (type: CompanyType) => {
    if (companyId[type]) {
      onClose();
      history.push(`/notifications/create/?companyId=${companyId[type]}&invoiceId=${data!.id}`);
    }
  };

  return (
    <Modal open={visible} onClose={onClose} title={t('notifications')}>
      <Modal.Content className="p-0">
        <Card.Body className="m-10 notification-card">
          <Space justify="space-between">
            <Space>
              <div className="pc-avatar--small pc-avatar__invoice">
                <Icon component={Invoices} />
              </div>
              <div>
                <h4>{data?.number}</h4>
                <p>{data?.factoring_type.type && t(data?.factoring_type.type.toLowerCase())}</p>
              </div>
            </Space>
            <Space>
              <Button
                type="primary"
                prefix={<Bell />}
                onClick={handleClick.bind(null, CompanyType.ADHERENT)}
                disabled={!(companyId[CompanyType.ADHERENT] && data?.id)}
              >
                {t('notify_adherent')}
              </Button>
              <Button
                type="primary"
                prefix={<Bell />}
                onClick={handleClick.bind(null, CompanyType.DEBTOR)}
                disabled={!(companyId[CompanyType.DEBTOR] && data?.id)}
              >
                {t('notify_debtor')}
              </Button>
            </Space>
          </Space>
        </Card.Body>
        <Loader loading={isLoading}>
          <Table
            data={(data && notificationList?.results) || []}
            columns={columns}
            className="ebs-table--breakable-cell table-no-border"
            scroll={{ y: 300 }}
          />
        </Loader>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="end">
          <Button onClick={onClose}>{t('cancel')}</Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationsModal;
