import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import cn from 'classnames';
import { Button, Card, Label, Space, Icon } from 'ebs-design';
import { LabelStatus } from 'ebs-design/dist/components/atoms/Label/Label';
import { usePermissions } from 'hooks';
import { Adherent, Edit, Eye } from 'resources';
import {
  FullCompany,
  Permissions,
  SystemRole,
  ReportCompanyType,
  Status,
  InvoiceStatus,
  CustomerRole,
  CompanyType,
} from 'types';
import { ReportKYCModal, ActionsCompany, SelectYearModal } from 'components';
import { UserContext } from 'contexts';
import { company, reports } from 'api';
import { downloadFile } from 'utils';

import { AdherentDetails } from './AdherentDetails';

interface Props {
  data: FullCompany;
  isEditCategory?: boolean;
}

export const AdherentProfile: React.FC<Props> = ({ data, isEditCategory }) => {
  const { t } = useIntl();
  const { id } = useParams();
  const { userRoles } = React.useContext(UserContext);
  const can = usePermissions(Permissions.ADHERENTS);

  const { data: dataInvoices } = useQuery(
    ['adherent_invoices_no_draft', id, { status__exclude: InvoiceStatus.DRAFT.toLowerCase() }],
    company.getInvoices,
  );

  // Download reports
  const download = useMutation(
    ({ type, year }: { type: ReportCompanyType; year?: number }) =>
      reports.downloadReports({ id: data.id, type }, { ...(year && { year }), current: true }),
    {
      onSuccess: ({ attachment }) => downloadFile(attachment.url, attachment.name),
    },
  );

  const hasNoDraftInvoice = React.useMemo(() => {
    return !!dataInvoices?.count;
  }, [dataInvoices]);

  const hasSignedContract = React.useMemo(() => {
    return Boolean(
      data?.contract?.attachment?.signatures?.find((i) =>
        Boolean(i.user.roles.find((i) => i.name in CustomerRole)),
      ),
    );
  }, [data]);

  const hasPFSignContract = React.useMemo(() => {
    return Boolean(
      data?.contract?.attachment?.signatures?.find((i) =>
        Boolean(i.user.roles.find((i) => i.name in SystemRole)),
      ),
    );
  }, [data]);

  return (
    <Card className="company-details__main">
      <Card.Header>
        <Space align="start" justify="space-between">
          <Space align="center">
            <div>
              <div className="company-details__icon">
                <Icon component={Adherent} />
              </div>
              <div className="company-details__title-box">
                <h2 className="company-details__title">{data?.title}</h2>
                <Label
                  status={
                    data?.request ? (Status[data?.request?.status] as LabelStatus) : 'warning'
                  }
                  type="fill"
                  text={
                    data?.request
                      ? t(data?.request.status.toLowerCase())
                      : data?.status && t(data?.status.toLowerCase())
                  }
                  className="company-details__subtitle"
                />
              </div>
            </div>
          </Space>

          {can.edit?.adherent && <Button prefix={<Icon component={Edit} />}>{t('edit')}</Button>}
        </Space>
      </Card.Header>
      <Card.Body className="p-0">
        <AdherentDetails data={data} isEditCategory={isEditCategory} />
      </Card.Body>
      <Card.Footer className={cn({ 'd-none': userRoles.includes(SystemRole.SY_COLLECTOR) })}>
        <Space wrap justify="space-between">
          <Space>
            {can.read?.reports && hasSignedContract && hasPFSignContract && hasNoDraftInvoice ? (
              <SelectYearModal
                onSelected={(year: number) =>
                  download.mutate({ type: ReportCompanyType.BIC, year })
                }
              >
                <Button prefix={<Icon component={Eye} />}>{t('report_bns_bic')}</Button>
              </SelectYearModal>
            ) : null}
            {can.read?.reports && hasSignedContract && hasPFSignContract && hasNoDraftInvoice ? (
              <Button
                onClick={() => download.mutate({ type: ReportCompanyType.INFODEBIT })}
                prefix={<Icon component={Eye} />}
              >
                {t('report_info_debit')}
              </Button>
            ) : null}

            {can.read?.kyc && (
              <ReportKYCModal type={CompanyType.ADHERENT} additional_data={data.additional_data} />
            )}
          </Space>

          <ActionsCompany data={data} permissions={Permissions.ADHERENTS} />
        </Space>
      </Card.Footer>
    </Card>
  );
};
