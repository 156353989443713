import * as React from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import {
  Container,
  Card,
  Space,
  Icon,
  Row,
  Col,
  Form,
  useForm,
  Loader,
  Textarea,
  Upload,
  Button,
  useNotify,
} from 'ebs-design';
import { useQueryParams, useUpload } from 'hooks';
import { extractResponseProps } from 'utils';
import { Layout } from 'components/organisms';
import { Invoices } from 'resources/icons';
import { notifications, invoices } from 'api';
import { Attachment, NotificationCreate } from 'types';

export const NotificationForm: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const [form] = useForm();
  const notify = useNotify();
  const params = useQueryParams();
  const uploadProps = useUpload();

  const { data, isLoading, isFetching } = useQuery(
    ['notifications', params.invoiceId],
    () => invoices.get(params.invoiceId),
    {
      enabled: params && !!params.invoiceId,
    },
  );

  // Redirect to notifications list
  const redirect = React.useCallback(() => push('/notifications'), [push]);

  // Create new notification
  const createNotification = useMutation(
    (values: NotificationCreate) => notifications.create(values),
    {
      onSuccess: () => {
        redirect();
        notify.success({ title: t('notifications'), description: t('send_notification_data') });
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  // Redirect to notifications if there is no params
  React.useEffect(() => {
    if (params && (!params.invoiceId || !params.companyId)) {
      redirect();
    }
  }, [params, redirect]);

  const handleSubmit = (values: { comment: string; attachments_id: Attachment[] }) => {
    if (params && params.invoiceId && params.companyId) {
      createNotification.mutate({
        companies_id: [Number(params.companyId)],
        invoice_id: Number(params.invoiceId),
        comment: values.comment,
        attachments_id: values.attachments_id
          ? values.attachments_id.map((attachment) => attachment.id)
          : [],
      });
    }
  };

  return (
    <Layout>
      <Container>
        <h3 className="page-title mt-5 mb-20">{t('send_notification')}</h3>
        <Card>
          <Loader loading={isLoading || isFetching}>
            <Card.Header>
              <Space>
                <div className="pc-avatar--small pc-avatar__invoice">
                  <Icon component={Invoices} />
                </div>
                <div>
                  <h3>{data?.number}</h3>
                  <p>{data?.factoring_type.type && t(data?.factoring_type.type.toLowerCase())}</p>
                </div>
              </Space>
            </Card.Header>
            <Card.Body className="p-0">
              <Form
                form={form}
                type="horizontal"
                onFinish={handleSubmit}
                labelOptions={{ col: { size: 3 } }}
                controlOptions={{ col: { size: 7 } }}
              >
                <Row g={0}>
                  <Col size={6} className="py-20 px-30 form-col--border-right">
                    <h3 className="form-heading">{t('notification_data')}</h3>
                    <Form.Field
                      name="comment"
                      label={t('comment')}
                      rules={[{ required: true }]}
                      labelOptions={{ align: 'start' }}
                    >
                      <Textarea />
                    </Form.Field>
                  </Col>
                  <Col size={6} className="py-20 px-30">
                    <h3 className="form-heading">{t('additional_files')}</h3>

                    <Form.Field name={'attachments_id'} label={t('files')}>
                      <Upload {...uploadProps}>
                        <Button>{t('upload')}</Button>
                      </Upload>
                    </Form.Field>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Space justify="space-between">
                <Button onClick={() => redirect()}>{t('cancel')}</Button>

                <Button
                  type="primary"
                  loading={createNotification.isLoading}
                  disabled={createNotification.isLoading}
                  prefix={<Icon type="check" model="bold" />}
                  onClick={() => form.submit()}
                >
                  {t('send')}
                </Button>
              </Space>
            </Card.Footer>
          </Loader>
        </Card>
      </Container>
    </Layout>
  );
};
