import * as React from 'react';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import {
  Row,
  Col,
  Card,
  Button,
  Icon,
  Space,
  Loader,
  AvatarInline,
  Badge,
  Alert,
} from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { contracts } from 'api';
import { useQueryParams, useFileFetch } from 'hooks';
import { defaultFilters } from 'utils';
import { Message, Download, Plus } from 'resources';
import { PDFViewer } from 'components/molecules';
import { CommentModal } from './CommentModal';

interface ContractModelProps {
  contractId?: number;
  url: string;
  isLoading: boolean;
}

export const ContractModel: React.FC<ContractModelProps> = ({
  contractId,
  url,
  isLoading: isLoadingContract,
}) => {
  const { t } = useIntl();
  const params = useQueryParams();
  const ref = React.useRef<HTMLDivElement>(null);
  const [filters] = useSetState({ ...defaultFilters, ...params });
  const [visibleComments, setVisibleComments] = React.useState(false);
  const [PDFLoaded, setPDFLoaded] = React.useState(false);

  const { data, isLoading, refetch } = useQuery(
    ['comments', filters],
    () => contracts.getComments({ id: contractId, ...filters }),
    { enabled: !!contractId },
  );

  const onToggleHandler = React.useCallback(
    (value?) => setVisibleComments((i) => (typeof value === 'boolean' ? value : !i)),
    [],
  );
  const onLoaded = React.useCallback(() => setPDFLoaded(true), []);

  const commentsCount = React.useMemo(
    () => (data && contractId ? data.count : 0),
    [data, contractId],
  );
  const commentsList = React.useMemo(
    () =>
      contractId && data && data.results.length > 0 ? (
        data.results.map((comment) => (
          <Col size={12} key={comment.id}>
            <Card size="small">
              {comment.user && (
                <Card.Header>
                  <AvatarInline
                    alt={[comment.user.first_name, comment.user.last_name].join(' ')}
                    status="active"
                    description={comment.user.roles.map((role) => t(role.name)).join(', ')}
                    circle
                  />
                </Card.Header>
              )}
              <Card.Body>{comment.comment}</Card.Body>
            </Card>
          </Col>
        ))
      ) : (
        <Col size={12}>
          <Alert type="info" message={t('no_comments')} />
        </Col>
      ),
    [t, data, contractId],
  );

  const file = useFileFetch(url);

  return (
    <Card collapsible>
      <Card.Header>{t('model_factoring_cotract')}</Card.Header>
      <Card.Body className="p-0">
        <Loader loading={isLoading || isLoadingContract || file.isLoading}>
          {url ? (
            <Row className="justify-space-between overflow-hidden pc-comments__container" g={0}>
              <Col size={12} sm={visibleComments ? 7 : 12} className="transition-width">
                <div ref={ref} style={{ height: '100%' }}>
                  <PDFViewer file={file.data!} style={{ marginBottom: -4 }} onLoad={onLoaded} />
                </div>
              </Col>
              <Col size={12} sm={5} gy={3} gx={4} className="pc-comments transition-width">
                {visibleComments && !PDFLoaded ? (
                  <Loader loading={!PDFLoaded} />
                ) : (
                  <Row gy={3}>{commentsList}</Row>
                )}
              </Col>
            </Row>
          ) : (
            <Alert
              type="warning"
              message={t('you_have_not_yet_completed_company_information')}
              className="no-border"
            />
          )}
        </Loader>
      </Card.Body>
      {!isLoading && !isLoadingContract && !file.isLoading && (
        <Card.Footer>
          <Space justify="space-between">
            <Badge type="danger" text={!url ? t('no_data') : undefined}>
              <Button
                onClick={() =>
                  file.data ? window.open(url) : window.open('/contract.docx', '_blank')
                }
                prefix={<Icon component={Download} />}
                disabled={!url}
              >
                {t('download')}
              </Button>
            </Badge>

            <Space>
              {contractId && (
                <CommentModal contractId={contractId} onChange={refetch}>
                  <Button
                    type="primary"
                    prefix={<Icon component={Plus} />}
                    className="ml-10"
                    onClick={() => onToggleHandler(true)}
                  >
                    {t('add_comment')}
                  </Button>
                </CommentModal>
              )}
              {commentsCount || visibleComments ? (
                <Badge text={commentsCount.toString()} type="danger">
                  <Button
                    type={visibleComments ? 'fill' : 'ghost'}
                    prefix={<Icon component={Message} />}
                    onClick={onToggleHandler}
                  >
                    {t(visibleComments ? 'hide' : 'see_comments')}
                  </Button>
                </Badge>
              ) : null}
            </Space>
          </Space>
        </Card.Footer>
      )}
    </Card>
  );
};
