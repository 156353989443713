import { createMessages } from 'estafette-intl';

import { alerts } from './alerts';
import { menu } from './menu';
import { roles } from './roles';
import { common } from './common';
import { date } from './date';
import { profile } from './profile';
import { company } from './company';
import { invoice } from './invoice';
import { reports } from './reports';
import { dashboard } from './dashboard';
import { nomenclatures } from './nomenclatures';
import { notifications } from './notifications';
import { languages } from './languages';
import { validations } from './validations';
import { certified } from './certified';

export const messages = createMessages([
  alerts,
  dashboard,
  company,
  common,
  nomenclatures,
  menu,
  date,
  roles,
  profile,
  notifications,
  languages,
  invoice,
  reports,
  validations,
  certified,
]);
