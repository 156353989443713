import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table, Space, Loader, DatePicker, Label } from 'ebs-design';
import { useReports } from 'hooks';

import { getIndexes, formatByIndexes } from 'utils';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfMonthDateFormat,
  formatDateFilters,
} from 'libs';
import { ReportType, ReportInvoicesTotals, Unit } from 'types';
import { RangePickerStateProps } from '../utils';

const key = 'status';

export const InvoicesTotals: React.FC = () => {
  const { t } = useIntl();

  const [range, setRange] = React.useState<RangePickerStateProps>([null, null]);

  const { data, isLoading } = useReports<ReportInvoicesTotals[]>(ReportType.INVOICES_TOTALS, {
    ...(range?.[0] && { date_from: range?.[0] && formatDateFilters(range?.[0]) }),
    ...(range?.[1] && { date_to: range?.[1] && formatDateFilters(range?.[1]) }),
  });

  const indexes = React.useMemo(() => (data ? getIndexes({ data, key }) : {}), [data]);

  const columns = React.useMemo(
    () => [
      {
        title: t('status'),
        dataIndex: 'status',
        width: 100,
        render: (children) => ({
          children: t(children?.toLowerCase()),
          props: {
            rowSpan: indexes[children] ? indexes[children] : 0,
          },
        }),
      },
      {
        title: t('type'),
        width: 100,
        dataIndex: 'type',
        render: (value) => t(value.toLowerCase()),
      },
      {
        title: t('total_nr_of_invoices'),
        dataIndex: 'number_total',
        width: 100,
      },
      {
        title: t('sum'),
        dataIndex: 'amount_total',
      },
      {
        title: t('total_commission'),
        children: [
          {
            title: t('amount'),
            dataIndex: 'amount_commission_total',
          },
          {
            title: Unit.PERCENT,
            dataIndex: 'amount_commission_total_percent',
          },
        ],
      },
      {
        title: t('total_guarantee'),
        children: [
          {
            title: t('amount'),
            dataIndex: 'amount_guarantee_total',
          },
          {
            title: Unit.PERCENT,
            dataIndex: 'amount_guarantee_total_percent',
          },
        ],
      },
      {
        title: t('average_commission'),
        children: [
          {
            title: t('amount'),
            dataIndex: 'amount_commission_average',
          },
          {
            title: Unit.PERCENT,
            dataIndex: 'amount_commission_average_percent',
          },
        ],
      },
      {
        title: t('average_guarantee'),
        children: [
          {
            title: t('amount'),
            dataIndex: 'amount_guarantee_average',
          },
          {
            title: Unit.PERCENT,
            dataIndex: 'amount_guarantee_average_percent',
          },
        ],
      },
    ],
    [t, indexes],
  );

  const dataTable = React.useMemo(
    () => (data ? formatByIndexes({ data, indexes, key }) : []),
    [data, indexes],
  );

  React.useEffect(() => {
    setRange([firstDayOfMonthDateFormat, currentDayDateFormat]);
  }, []);

  return (
    <Loader loading={isLoading}>
      <>
        <Space direction="vertical" align="start" className="mb-15 mt-5">
          <Space>
            <h4>{t('total_invoices')}</h4>
          </Space>

          <Space>
            <Label text="Period" />

            <DatePicker.RangeInput
              value={range}
              onChange={(value) => setRange(value as any)}
              isClearable
              placeholderText={t('choose_period')}
              showYearDropdown
              dateFormat={dateFormat}
            />
          </Space>
        </Space>

        <Table data={dataTable} columns={columns} scroll={{ y: 500 }} emptyCell="---" />
      </>
    </Loader>
  );
};
