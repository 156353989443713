import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Collapse, Loader, Icon } from 'ebs-design';
import { UserContext } from 'contexts';
import { Adherent } from 'resources';
import { tenancy } from 'api';

export const Multitenancy: React.FC = () => {
  const { t } = useIntl();
  const { hash, onChangeHash: setHash } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const [localHash, setLocalHash] = React.useState(hash);

  const { data, isLoading } = useQuery(
    ['tenancy-companies-list-tooltip', { limit: 200 }],
    tenancy.getCompaniesList,
    {
      enabled: !!localHash,
    },
  );
  const onChangeHash = React.useCallback(
    (value: string): void => {
      setHash(value);
      setLocalHash(value);
      queryClient.clear();
      // queryClient.invalidateQueries();
    },
    [queryClient, setHash, setLocalHash],
  );

  const selectedCompany = React.useMemo(
    () => data?.results?.find((result) => result.hash === localHash),
    [data, localHash],
  );

  return (
    <div className="multitenancy">
      <Loader style={{ minHeight: 'auto' }} size="small" loading={isLoading}>
        {(data?.count && data.count > 1) || isLoading ? (
          <Collapse collapsed className="ebs-tooltip__companies">
            <Collapse.Header>
              <li className="multitenancy__company-item word-break">
                <Icon component={Adherent} />
                {selectedCompany?.title || `${t('loading')}...`}
              </li>
            </Collapse.Header>
            <Collapse.Body>
              <div className="multitenancy__list-box">
                {data?.results
                  .filter(({ id }) => id !== selectedCompany?.id)
                  .map(({ id, hash: companyHash, title }) => (
                    <li
                      key={id}
                      className="multitenancy__company-item word-break"
                      onClick={() => onChangeHash(companyHash)}
                    >
                      {title}
                    </li>
                  ))}
              </div>
            </Collapse.Body>
          </Collapse>
        ) : null}
      </Loader>
    </div>
  );
};
