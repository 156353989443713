import * as React from 'react';
import cn from 'classnames';
import { useIntl } from 'estafette-intl';
import { useClickAway } from 'react-use';
import { Checkbox, Form, Icon } from 'ebs-design';
import { Arrows } from 'resources';

interface MultipleCheckBoxSelectProps {
  callback: (selected: string[]) => void;
  placeHolder: string;
  options: Option[];
  label?: string;
}

interface Option {
  [key: string]: any;

  checked?: boolean;
}

export const MultipleCheckBoxSelect: React.FC<MultipleCheckBoxSelectProps> = ({
  placeHolder,
  options,
  callback,
  label = 'label',
}) => {
  const ref = React.useRef(null);
  const { t } = useIntl();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);

  React.useEffect(() => {
    setSelected(
      options
        .map((item, key) => (item.checked ? key.toString() : false))
        .filter((item) => item) as string[],
    );
  }, [options]);

  useClickAway(ref, () => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen((s) => !s);
  };

  const handleFormChange = (changedValues) => {
    Object.keys(changedValues).forEach((item) => {
      let selectedOptions;
      if (changedValues[item] && !selected.find((i) => i === item)) {
        selectedOptions = [...selected, item];
      } else {
        selectedOptions = selected.filter((i) => i !== item);
      }
      setSelected(selectedOptions);
      callback(selectedOptions);
    });
  };

  const renderForm = React.useMemo(
    () =>
      options
        .map((item, key) => ({ ...item, key: key.toString() }))
        .filter((item) => item.checked !== undefined)
        .map((item, key) => (
          <Form.Field
            key={key}
            name={item.key}
            className="multiple-check-box-select__select-options__label"
          >
            <Checkbox
              text={item[label]}
              value={item.key}
              disabled={selected.length <= 1 && selected.includes(item.key)}
              checked={selected.includes(item.key)}
            />
          </Form.Field>
        )),
    [options, selected, label],
  );

  return (
    <div ref={ref} className={cn(`multiple-check-box-select`, { active: open })}>
      <span className="multiple-check-box-select__select" onClick={handleOpen}>
        {placeHolder}
        <Icon component={Arrows} />
      </span>
      <div className="multiple-check-box-select__select-options">
        <div className="multiple-check-box-select__select-options__header">{t('choose')}</div>
        <div className="multiple-check-box-select__select-options__body">
          <Form onValuesChange={handleFormChange}>{renderForm}</Form>
        </div>
      </div>
    </div>
  );
};
