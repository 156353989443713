import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Col, Row, Space, Label } from 'ebs-design';
import { FullCompany, SystemRole, AddressType, ReportCompanyType } from 'types';
import { renderCellByRole, getFullAddress, formatNumber } from 'utils';
import { UserContext } from 'contexts';
import { EditCompanyCategory } from 'components';

interface AdherentDetailsProps {
  data: FullCompany;
  isEditCategory?: boolean;
}

export const AdherentDetails: React.FC<AdherentDetailsProps> = ({
  data,
  isEditCategory = false,
}) => {
  const { t } = useIntl();
  const { userRoles } = React.useContext(UserContext);

  // Prioritize if the credit officer is able to operate
  const creditOfficer = renderCellByRole(SystemRole.SY_CREDIT_OFFICER, data?.management);

  const evaluated = React.useMemo(
    () =>
      data?.scoring.find((scoring) => scoring.type === ReportCompanyType.INFODEBIT.toUpperCase())
        ?.evaluated,
    [data],
  );

  const identity = React.useMemo(
    () => ({
      [ReportCompanyType.BIC]: data?.identities.find(({ type }) => type === 'BIC')?.mapped.ratio,
      [ReportCompanyType.INFODEBIT]: data?.identities.find(({ type }) => type === 'INFODEBIT')
        ?.mapped.ratio,
    }),
    [data],
  );

  return (
    <>
      <Row className="company-details__description__row bg-white ">
        <Col size={6} sm={6} md="auto" className="flex-grow">
          <div className="company-details__description company-details__description--heading">
            <h5 className="company-details__description__label color-primary">
              {t('approved_limit')}
            </h5>
            <p className="company-details__description__value">{data?.limit.current || '---'}</p>
          </div>
        </Col>
        <Col size={6} sm={6} md="auto" className="flex-grow">
          <div className="company-details__description company-details__description--heading">
            <h5 className="company-details__description__label">{t('available_limit')}</h5>
            <p className="company-details__description__value">{data?.limit.accessible || '---'}</p>
          </div>
        </Col>
        <Col size={6} sm={6} md="auto" className="flex-grow">
          <div className="company-details__description company-details__description--heading">
            <h5 className="company-details__description__label">{t('scoring')}</h5>
            <p className="company-details__description__value">
              {(evaluated && `${formatNumber(evaluated)} %`) || '---'}
            </p>
          </div>
        </Col>
        <Col size={6} sm={6} md="auto" className="flex-grow">
          <div className="company-details__description company-details__description--heading">
            <h5 className="company-details__description__label color-success">
              {t('coincidence')}
            </h5>
            {identity[ReportCompanyType.BIC] || identity[ReportCompanyType.INFODEBIT] ? (
              <Space size="small" justify="space-between">
                <Space size="small" align="start" direction="vertical">
                  <Label type="fill" text={ReportCompanyType.BIC.toUpperCase()} />
                  <p className="company-details__description__value">
                    {identity[ReportCompanyType.BIC]
                      ? `${identity[ReportCompanyType.BIC]}%`
                      : '---'}
                  </p>
                </Space>
                <Space size="small" align="end" direction="vertical">
                  <Label type="fill" text={ReportCompanyType.INFODEBIT.toUpperCase()} />
                  <p className="company-details__description__value">
                    {identity[ReportCompanyType.INFODEBIT]
                      ? `${identity[ReportCompanyType.INFODEBIT]}%`
                      : '---'}
                  </p>
                </Space>
              </Space>
            ) : (
              <p className="company-details__description__value">---</p>
            )}
          </div>
        </Col>
        <Col size={6} sm={6} md="auto" className="flex-grow">
          <div className="company-details__description company-details__description--heading">
            <h5 className="company-details__description__label">{t('factoring_inspector')}</h5>
            <p className="company-details__description__value">{creditOfficer || '---'}</p>
          </div>
        </Col>
      </Row>

      <Row className="company-details__description__row">
        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('idno')}</h5>
            <p className="company-details__description__value">{data?.idno || '---'}</p>
          </div>
        </Col>
        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('legal_address')}</h5>
            <p className="company-details__description__value">
              {getFullAddress(
                data?.addresses.find((address) => address.type === AddressType.LEGAL),
              )}
            </p>
          </div>
        </Col>
        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('location')}</h5>
            <p className="company-details__description__value">{t(data.position.toLowerCase())}</p>
          </div>
        </Col>
        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('phone')}</h5>
            <p className="company-details__description__value">
              {data?.additional_data?.phone || '---'}
            </p>
          </div>
        </Col>
      </Row>

      <Row className="company-details__description__row">
        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('industry')}</h5>
            <p className="company-details__description__value">
              {data?.additional_data?.activity_field?.title || '---'}
            </p>
          </div>
        </Col>

        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('postal_address')}</h5>
            <p className="company-details__description__value">
              {getFullAddress(
                data?.addresses.find((address) => address.type === AddressType.POSTAL),
              )}
            </p>
          </div>
        </Col>

        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('tva')}</h5>
            <p className="company-details__description__value">{data?.code || '---'}</p>
          </div>
        </Col>

        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('email')}</h5>
            <p className="company-details__description__value">
              {data?.additional_data?.email || '---'}
            </p>
          </div>
        </Col>
        <Col size={6} sm={6} md={3}>
          <div className="company-details__description">
            <h5 className="company-details__description__label">{t('category')}</h5>
            {isEditCategory &&
            (userRoles.includes(SystemRole.SY_RISK_MANAGER) ||
              userRoles.includes(SystemRole.SY_ADMIN)) ? (
              <EditCompanyCategory category={data?.category} />
            ) : (
              <p className="company-details__description__value">
                {data?.category?.code_name || '---'}
              </p>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
