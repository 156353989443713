import * as React from 'react';
import { MyProfileLayout } from 'features/profile/MyProfileLayout';
import { AlertsSettings } from '../alerts/AlertsSettings';

export const Alerts: React.FC = () => {
  return (
    <MyProfileLayout>
      <AlertsSettings />
    </MyProfileLayout>
  );
};
