import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { Container, Row, Col, Alert, Badge } from 'ebs-design';
import { UserContext } from 'contexts';
import { usePermissions } from 'hooks';
import { company, invoices } from 'api';
import { CustomerRole, Permissions, SystemRole } from 'types';
import { Bag, BagChecked, BagFilledChecked, Write, Link as LinkIcon, FileUpload } from 'resources';
import { Layout, ContractModel } from 'components/organisms';

import DashboardCard from '../DashboardCard';
import { getRoute } from 'utils';
import { routes } from 'routes';

export const Adherent: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const can = usePermissions(Permissions.HOME);
  const { user } = React.useContext(UserContext);

  const hasCompany = React.useMemo(() => !!user?.company, [user]);

  const { data, isLoading, isFetching } = useQuery(
    ['contract', user?.company?.id],
    () =>
      user?.company?.id ? company.getContract(user?.company?.id, { generate_number: true }) : null,
    { enabled: hasCompany },
  );

  const { data: hasInvoice, isLoading: isLoadingInvoice } = useQuery(
    [
      'invoices',
      {
        page: 1,
        limit: 1,
        status: 'sent',
        company_id: [user?.company?.id],
      },
    ],
    invoices.getList,
    {
      enabled: !!user?.company?.id,
      select: ({ results }) => !!results[0],
    },
  );

  const hasSignedContract = React.useMemo(() => {
    return Boolean(
      data?.attachment?.signatures?.find((i) =>
        Boolean(i.user.roles.find((i) => i.name in CustomerRole)),
      ),
    );
  }, [data]);

  const hasPFSignContract = React.useMemo(() => {
    return Boolean(
      data?.attachment?.signatures?.find((i) =>
        Boolean(i.user.roles.find((i) => i.name in SystemRole)),
      ),
    );
  }, [data]);

  return (
    <Layout>
      <Container>
        <Row className="mb-20">
          <Col>
            <h3>{t('what_needs_for_funding')}:</h3>
          </Col>
        </Row>
        <Row className="mb-20 g-4">
          <Col size={12} sm={6} md={4}>
            <DashboardCard
              style={{ backgroundImage: `url(${require('resources/images/business.png')})` }}
              icon={Bag}
              title={t('fill_in_info_about_company')}
              onClick={() => push(getRoute(routes, 'CompanyForm'))}
              disabled={!can.perform?.navigation?.companyForm}
            />
          </Col>
          <Col size={12} sm={6} md={4}>
            <Badge
              text={
                isLoading || isFetching
                  ? `${t('loading')}...`
                  : hasSignedContract
                  ? t('signed')
                  : ''
              }
              type={isLoading || isFetching ? 'warning' : 'success'}
              className="pc-fix-badge-card"
            >
              <DashboardCard
                style={{ backgroundImage: `url(${require('resources/images/sign.png')})` }}
                icon={LinkIcon}
                title={t('get_electronic_signature_sign_contract')}
                onClick={() => push(getRoute(routes, 'ContractSignUpload'))}
                disabled={!hasCompany}
              />
            </Badge>
          </Col>
          <Col size={12} sm={6} md={4}>
            <DashboardCard
              style={{ backgroundImage: `url(${require('resources/images/upload.png')})` }}
              icon={FileUpload}
              title={t('upload_invoice_contract')}
              onClick={() => push(getRoute(routes, 'AddInvoice'))}
              disabled={!hasCompany || !hasSignedContract || !hasPFSignContract}
            />
          </Col>
          <Col size={12} sm={6} md={4}>
            <DashboardCard
              style={{ backgroundImage: `url(${require('resources/images/response.png')})` }}
              icon={BagChecked}
              title={t('receive_answer_about_acceptance_invoice')}
              disabled={
                !hasCompany ||
                isLoadingInvoice ||
                !hasInvoice ||
                !hasSignedContract ||
                !hasPFSignContract
              }
            />
          </Col>
          <Col size={12} sm={6} md={4}>
            <DashboardCard
              style={{ backgroundImage: `url(${require('resources/images/phone.png')})` }}
              icon={Write}
              title={t('sign_documents_electronically_for_funding')}
              disabled={
                !hasCompany ||
                isLoadingInvoice ||
                !hasInvoice ||
                !hasSignedContract ||
                !hasPFSignContract
              }
            />
          </Col>
          <Col size={12} sm={6} md={4}>
            <DashboardCard
              style={{ backgroundImage: `url(${require('resources/images/money.png')})` }}
              icon={BagFilledChecked}
              title={t('receive_money_in_account_for_assigned_invoices')}
              disabled={
                !hasCompany ||
                isLoadingInvoice ||
                !hasInvoice ||
                !hasSignedContract ||
                !hasPFSignContract
              }
            />
          </Col>
        </Row>
        <Row g={4}>
          <Col size={12}>
            <a href="https://msign.gov.md/" target="_blank" rel="noopener noreferrer">
              <Alert message={t('how_do_get_electronic_signature')} type="info" />
            </a>
          </Col>
          <Col size={12}>
            <ContractModel
              contractId={data?.id}
              url={data?.attachment?.url || ''}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
