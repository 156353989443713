import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Container, Row, Col, Space } from 'ebs-design';
import type { OptionValue } from 'ebs-design/dist/components/molecules/Select/interfaces';
import { Layout, SmartSelect } from 'components/organisms';
import { useReports } from 'hooks/useReports';
import {
  ReportType,
  ReportInvoicesStatuses,
  ReportCompanyStatistic,
  InvoiceStatus,
  Period,
} from 'types';
import { reduceCompanyStatistic, reduceInvoiceStatistic } from '../utils';
import { StatisticCard } from 'components';
import { InvoicesTable } from '../collector';

export function Collector() {
  const { t } = useIntl();
  const [period, setPeriod] = React.useState<OptionValue>(Period.YEAR);

  const periodOptions = React.useMemo(
    () =>
      Object.values([Period.DAY, Period.WEEK, Period.MONTH, Period.YEAR]).map((v) => ({
        value: v,
        text: t(`${v.toLowerCase()}`),
      })),
    [t],
  );

  const { data, refetch } = useReports<
    ReportInvoicesStatuses[],
    { [key: string]: Partial<ReportInvoicesStatuses> }
  >(
    ReportType.INVOICES_STATUSES,
    { period },
    {
      select: (data = []) => ({
        approved: reduceInvoiceStatistic(data, [InvoiceStatus.APPROVED]),
        remained: reduceInvoiceStatistic(data, [InvoiceStatus.REMAINED]),
        active: reduceInvoiceStatistic(data, [
          InvoiceStatus.PARTIALLY_PAID,
          InvoiceStatus.REMAINED,
          InvoiceStatus.RECESSIONED,
        ]),
      }),
    },
  );

  // Fetch companies statistic data
  const { data: companiesData, refetch: refetchCompaniesData } = useReports<
    ReportCompanyStatistic[],
    Partial<ReportCompanyStatistic>
  >(
    ReportType.COMPANIES_STATISTICS,
    { period },
    {
      select: (data = []) => reduceCompanyStatistic(data),
    },
  );

  React.useEffect(() => {
    refetch();
    refetchCompaniesData();
  }, [period, refetch, refetchCompaniesData]);

  return (
    <Layout>
      <Container className="collector-home">
        <Space justify="space-between" className="mb-20">
          <h3 className="page-title">{t('statistic')}</h3>

          <Space>
            <>{t('period')}</>
            <SmartSelect
              options={periodOptions}
              value={period}
              onChange={(v) => setPeriod(v as OptionValue)}
              placeholder={t('period')}
              className="statistic-period__select"
            />
          </Space>
        </Space>
        <Row>
          <Col size={6}>
            <StatisticCard
              title={t('outstanding_customers')}
              total={companiesData?.companies_outstanding_totals}
            >
              <div className="statistic-card__child statistic-card__child--bordered">
                <h5>{t('monthly')}</h5>
                <p>{companiesData?.companies_outstanding_monthly}</p>
              </div>
              <div className="statistic-card__child statistic-card__child--bordered">
                <h5>{t('yearly')}</h5>
                <p>{companiesData?.companies_outstanding_yearly}</p>
              </div>
            </StatisticCard>
          </Col>

          <Col size={6}>
            <StatisticCard title={t('overdue_invoices')} total={data?.remained.total}>
              <div className="statistic-card__child statistic-card__child--bordered">
                <h5>{t('daily')}</h5>
                <p>{data?.remained?.daily}</p>
              </div>
              <div className="statistic-card__child statistic-card__child--bordered">
                <h5>{t('monthly')}</h5>
                <p>{data?.remained?.monthly}</p>
              </div>
            </StatisticCard>
          </Col>
        </Row>

        <InvoicesTable />
      </Container>
    </Layout>
  );
}
