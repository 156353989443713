import { Properties } from 'types';

export const hasOwnProperty = (obj, prop): boolean =>
  Object.prototype.hasOwnProperty.call(obj, prop);

// Get array unique values by key
export function uniqByKeepLast<T>(arr: Array<T>, key: (k: T) => void) {
  return [...new Map(arr.map((x) => [key(x), x])).values()] as Array<T>;
}

export const getFieldsErrors = (obj: Properties, message: string) => {
  const errors: { name: string; errors: string[] }[] = [];
  let keys: string[] = [];

  const recursive = (obj: Properties, key: string[] = []) => {
    keys = [...key];

    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        recursive(obj[key], [...keys, key]);
      } else {
        if (!obj[key]) {
          errors.push({
            name: keys.length ? `${keys.join('.')}.${key}` : key,
            errors: [message],
          });
        }
      }
    }

    keys = [];
  };

  recursive(obj);

  return errors;
};
