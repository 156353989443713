import { axios, cancelTokenHandler } from 'libs';
import { Results, Contract, Properties, Template } from 'types';

export const templates = {
  get: async (params) => {
    const { data } = await axios.get<Contract>('/contracts/templates/list/', {
      params,
      cancelToken: cancelToken(templates.get.name),
    });

    return data;
  },
  add: async (data: Properties): Promise<Template> => {
    return await axios.post('/contracts/templates/', data, {
      cancelToken: cancelToken(templates.add.name),
    });
  },
  save: async ({ id, ...data }: Properties): Promise<Template> => {
    return await axios.patch(`/contracts/templates/${id}/`, data, {
      cancelToken: cancelToken(templates.save.name),
    });
  },
  getList: async (params: Properties = {}) => {
    const { data } = await axios.get<Results<Template>>(`/contracts/templates/list/`, {
      params,
      cancelToken: cancelToken(templates.getList.name),
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(templates);
