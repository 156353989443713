import * as React from 'react';
import { AvatarInline, Card, Space } from 'ebs-design';

interface Props {
  title: string;
  description: string;
  icon: React.ReactNode;
  actions: React.ReactNode;
}

export const CompanyCard: React.FC<Props> = ({ title, description, icon, actions, children }) => {
  return (
    <Card className="mb-20">
      <Card.Header bordered>
        <Space justify="space-between">
          <AvatarInline
            circle
            className="company-card__avatar"
            icon={icon}
            alt={title}
            description={description}
          />

          <Space>{actions}</Space>
        </Space>
      </Card.Header>
      <Card.Body className="company-card__body p-0">{children}</Card.Body>
    </Card>
  );
};
