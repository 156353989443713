import { User } from 'types';

export enum HistoryModels {
  ATTACHMENT = 'Attachment',
  USER = 'User',
  COMPANY = 'Company',
  INVOICE = 'Invoice',
  NOTIFICATION = 'Notification',
  REQUEST = 'Request',
  COMPANYRATE = 'CompanyRate',
  COMMITTEE = 'Committee',
  CompanyRate = 'CompanyRate',
  COMMENT = 'Comment',
  INVITE = 'Invite',
}

export interface History {
  id: number;
  timestamp: string;
  edited_timestamp: string;
  model: HistoryModels;
  reference: number;
  type: HistoryType;
  old: { [key: string]: any };
  data: { [key: string]: any };
  user: User;
}

export type HistoryType = 'UPDATE' | 'DELETE' | 'VIEW' | 'CREATE' | 'UNKNOWN';
