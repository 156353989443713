import * as React from 'react';
import { Form, Input } from 'ebs-design';
import { NumericFormat } from 'react-number-format';
import { FormFieldProps } from 'ebs-design/dist/components/organisms/Form/FormField';
import { InputProps } from 'ebs-design/dist/components/atoms/Input/Input';

interface InputNumericFormProps extends FormFieldProps {
  inputProps?: InputProps;
  separator?: string;
}

export const InputNumericForm = ({
  inputProps,
  separator = ' ',
  ...props
}: InputNumericFormProps) => {
  return (
    <Form.Field
      {...props}
      normalize={(value) => {
        const formattedValue = value?.replaceAll(separator, '');

        return formattedValue;
      }}
    >
      <NumericFormat
        decimalScale={2}
        allowLeadingZeros
        thousandSeparator={separator}
        allowNegative
        customInput={(props) => {
          const control = props as React.InputHTMLAttributes<HTMLInputElement>;

          return <Input value={control.value} onChangeCapture={control.onChange} {...inputProps} />;
        }}
      />
    </Form.Field>
  );
};
