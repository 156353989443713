import * as React from 'react';
import { useSetState } from 'react-use';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card,
  Space,
  Checkbox,
  InputPhone,
  useForm,
  useNotify,
} from 'ebs-design';
import { nomenclature, company, requests } from 'api';
import { formatDate, getAPIDateFormat } from 'libs';
import { AddressType, FullCompany, Properties } from 'types';
import { useCompany } from 'hooks';
import { validateResponse, getDeepKeys, extractResponseProps } from 'utils';
import { ArrowRight } from 'resources';
import { SmartSelect } from 'components';

import { FormWizardContext } from '../FormWizard';

// District's paths
const legalDistrictPath = ['addresses', AddressType.LEGAL, 'district_id'];
const postalDistrictPath = ['addresses', AddressType.POSTAL, 'district_id'];

const defaultSelected: Properties = {
  organization_form_id: undefined,
  activity_field_id: undefined,
  addresses: {
    [AddressType.LEGAL]: {
      district_id: [],
      location_id: [],
    },
    [AddressType.POSTAL]: {
      district_id: [],
      location_id: [],
    },
  },
};

const GeneralDataForm: React.FC = () => {
  const { id } = useParams();
  const [form] = useForm();
  const [agree, setAgree] = React.useState(false);
  const { t } = useIntl();
  const ctx = React.useContext(FormWizardContext);
  const notify = useNotify();
  const { data: companyData } = useCompany(id, true);
  const queryClient = useQueryClient();

  const [loaded, setLoaded] = React.useState(false);

  const [selected, setSelected] = useSetState({ ...defaultSelected });
  const [district, setDistrict] = useSetState({
    legal: 0,
    postal: 0,
  });
  const [alternative, setAlternative] = useSetState({
    organization_form_id: false,
    activity_field_id: false,
  });

  React.useEffect(() => {
    if (!ctx.currentStep) {
      setLoaded(false);
    }
  }, [ctx]);

  React.useEffect(() => {
    if (companyData && (!loaded || form.getFieldValue('id') !== companyData.id)) {
      const { addresses = [], additional_data } = companyData;
      const selectedData = { ...defaultSelected };

      // Map attachmets to form
      const addressesObjects = {};
      addresses.forEach((address) => {
        addressesObjects[address.type] = {
          ...address,
          district_id: address.district?.id,
          location_id: address.location?.id,
        };

        selectedData.addresses[address.type] = {
          district_id: [
            ...selectedData.addresses[address.type].district_id,
            { value: address.district.id, text: address.district.name },
          ],
          location_id: [
            ...selectedData.addresses[address.type].location_id,
            { value: address.location.id, text: address.location.name },
          ],
        };
      });

      const additionalData = {
        ...additional_data,
        ...(additional_data && {
          organization_form_id: additional_data.organization_form_alternative
            ? t('other')
            : additional_data?.organization_form?.id,
          activity_field_id: additional_data.activity_field_alternative
            ? t('other')
            : additional_data?.activity_field?.id,
          bank_id: additional_data?.bank?.id,
          capital: parseFloat(additional_data?.capital),
          factoring_range_id: additional_data?.factoring_range?.id,
          ...(additional_data.registered_at && {
            registered_at: formatDate(additional_data.registered_at),
          }),
        }),
      };

      setSelected({
        ...selectedData,
        ...(additional_data?.organization_form && {
          organization_form_id: {
            value: additional_data?.organization_form?.id,
            text: additional_data?.organization_form?.title,
          },
        }),
        ...(additional_data?.activity_field && {
          activity_field_id: {
            value: additional_data?.activity_field?.id,
            text: additional_data?.activity_field?.title,
          },
        }),
      });

      if (
        additional_data?.activity_field_alternative ||
        additional_data?.organization_form_alternative
      ) {
        setAlternative({
          activity_field_id: !!additional_data?.activity_field_alternative,
          organization_form_id: !!additional_data?.organization_form_alternative,
        });
      }

      if (addressesObjects[AddressType.LEGAL]) {
        setDistrict({ legal: addressesObjects[AddressType.LEGAL].district_id });
      }

      if (addressesObjects[AddressType.POSTAL]) {
        setDistrict({ postal: addressesObjects[AddressType.POSTAL].district_id });
      }

      form.resetFields();
      form.setFieldsValue({
        ...companyData,
        additional_data: additionalData,
        addresses: addressesObjects,
      });

      setLoaded(true);
    }
  }, [t, companyData, form, loaded, setAlternative, setDistrict, setSelected]);

  // Add company to history
  const requestCompany = useMutation(requests.create);

  const postCompany = useMutation(company.create, {
    onError: (err, values, rollback: any) => {
      validateResponse(form, err, values);
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
      rollback();
    },
    onSuccess: () => {
      requestCompany.mutate({ type: 'CARD' });
      queryClient.invalidateQueries('userProfile').then(() => {
        ctx.nextStep();
      });
    },
  });

  const updateCompany = useMutation(company.update, {
    onError: (err, values, rollback: any) => {
      validateResponse(form, err, values);
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
      rollback();
    },
    onSuccess: () => {
      queryClient.invalidateQueries('userProfile').then(() => {
        ctx.nextStep();
      });
    },
  });

  const handleSubmit = (data: FullCompany) => {
    const updatedData = {
      ...data,
      addresses: Object.values(data.addresses),
      additional_data: {
        ...data.additional_data,
        ...(companyData?.additional_data?.id && { id: companyData.additional_data?.id }),
        ...(data.additional_data.registered_at && {
          registered_at: getAPIDateFormat(data.additional_data.registered_at),
        }),
        ...(alternative.activity_field_id && { activity_field_id: undefined }),
        ...(alternative.organization_form_id && { organization_form_id: undefined }),
      },
      ...(!data?.management?.length && { assign_company_responsible: true }),
    };

    if (companyData && companyData.id) {
      updateCompany.mutate({
        companyId: companyData.id,
        data: {
          ...updatedData,
          id: companyData.id,
        },
      });
    } else {
      postCompany.mutate(updatedData);
    }
  };

  const handleValuesChange = (field) => {
    const fieldName: string[] = getDeepKeys(field);
    const fieldValue = form.getFieldValue(fieldName);
    const fieldError = form.getFieldError(fieldName);

    // Reset field errors on change
    if (fieldError.length > 0) {
      form.setFields([
        {
          name: fieldName,
          value: fieldValue,
          errors: [],
        },
      ]);
    }

    if (fieldName.includes(AddressType.LEGAL) && fieldName.includes('district_id')) {
      setDistrict({ legal: fieldValue });

      form.setFieldsValue({ addresses: { [AddressType.LEGAL]: { location_id: undefined } } });
    }

    if (fieldName.includes(AddressType.POSTAL) && fieldName.includes('district_id')) {
      setDistrict({ postal: fieldValue });

      form.setFieldsValue({ addresses: { [AddressType.POSTAL]: { location_id: undefined } } });
    }

    if (Array.isArray(fieldName) && fieldName.length > 1) {
      const alternativeField = fieldName.pop() as string;

      if (['organization_form_id', 'activity_field_id'].includes(alternativeField)) {
        setAlternative({ [alternativeField]: !!(fieldValue === t('other')) });
      }
    }
  };

  const onToggleAgree = () => setAgree((i) => !i);

  const onToggleSameJuridicalAddress = (value) => {
    setSelected(({ addresses }) => ({
      addresses: {
        ...addresses,
        [AddressType.POSTAL]: addresses[AddressType.LEGAL],
      },
    }));

    form.setFieldsValue({
      addresses: {
        [AddressType.POSTAL]: value
          ? {
              ...form.getFieldValue(['addresses', AddressType.LEGAL]),
              type: AddressType.POSTAL,
            }
          : {
              district_id: null,
              location_id: null,
              postal_code: null,
              text: null,
            },
      },
    });

    setDistrict({
      postal: value ? form.getFieldValue(['addresses', AddressType.LEGAL]).district_id : 0,
    });
  };

  const onSelectDistrict = (type: AddressType, district_id) => {
    setSelected(({ addresses }) => ({
      addresses: {
        ...addresses,
        [type]: {
          ...addresses[type],
          district_id,
        },
      },
    }));
  };

  const onSelectLocation = (type: AddressType, location_id) => {
    setSelected(({ addresses }) => ({
      addresses: {
        ...addresses,
        [type]: {
          ...addresses[type],
          location_id,
        },
      },
    }));
  };

  return (
    <Form
      form={form}
      type="horizontal"
      className="company-form"
      onFinish={handleSubmit}
      onValuesChange={handleValuesChange}
      labelOptions={{ col: { size: 3 } }}
      controlOptions={{ col: { size: 9 } }}
      fieldRow={{ gy: 3 }}
    >
      <Card>
        <Card.Body className="p-0">
          <Row g={0}>
            <Col size={12} md={6} className="py-20 px-30 form-col--border-right">
              <h3 className="form-heading">{t('company_data')}</h3>
              <Form.Field name="title" label={t('company_name')} rules={[{ required: true }]}>
                <Input />
              </Form.Field>

              <Form.Field name="idno" label="IDNO" rules={[{ required: true }]}>
                <Input type="number" />
              </Form.Field>

              <Form.Field name="code" label={t('vat_code')} rules={[{ required: true }]}>
                <Input />
              </Form.Field>

              <Form.Field
                name={['additional_data', 'phone']}
                label={t('phone')}
                rules={[{ required: true }]}
              >
                <InputPhone country="md" />
              </Form.Field>

              <Form.Field name={['additional_data', 'fax']} label={t('fax')}>
                <InputPhone country="md" />
              </Form.Field>

              <Form.Field name={['additional_data', 'email']} label={t('email')}>
                <Input type="email" />
              </Form.Field>

              <Form.Field name={['additional_data', 'website']} label={t('website')}>
                <Input placeholder="https://..." />
              </Form.Field>

              <div className="form-divider" />

              <h3 className="form-heading">{t('general_data')}</h3>

              <Form.Field name="iban" label="IBAN">
                <Input />
              </Form.Field>

              <Form.Field name={['additional_data', 'bank_id']} label={t('bank')}>
                <SmartSelect
                  api={nomenclature.getBanks}
                  selected={{
                    value: companyData?.additional_data?.bank?.id,
                    text: companyData?.additional_data?.bank?.title,
                  }}
                />
              </Form.Field>
            </Col>
            <Col size={12} md={6} className="py-20 px-30">
              <h3 className="form-heading">{t('company_address')}</h3>

              <Form.Field name="position" label={t('position')}>
                <SmartSelect
                  options={[
                    { value: 'URBAN', text: t('urban') },
                    { value: 'RURAL', text: t('rural') },
                  ]}
                />
              </Form.Field>

              <Form.Group label={t('legal_address')}>
                <Form.Field
                  name={['addresses', AddressType.LEGAL, 'type']}
                  initialValue={AddressType.LEGAL}
                />
                <Row gy={3} className="mb-16">
                  <Col size={6} md={12} lg={6}>
                    <Form.Field
                      label={t('district')}
                      hideLabel
                      rules={[{ required: true }]}
                      name={['addresses', AddressType.LEGAL, 'district_id']}
                      controlOptions={{ col: { size: 12 } }}
                    >
                      <SmartSelect
                        queryKey="districts"
                        api={nomenclature.getDistricts}
                        placeholder={t('district')}
                        selected={selected.addresses[AddressType.LEGAL].district_id}
                        onSelect={(value) => onSelectDistrict(AddressType.LEGAL, value)}
                      />
                    </Form.Field>
                  </Col>

                  <Col size={6} md={12} lg={6}>
                    <Form.Field
                      label={t('city')}
                      hideLabel
                      rules={[{ required: true }]}
                      name={['addresses', AddressType.LEGAL, 'location_id']}
                      controlOptions={{ col: { size: 12 } }}
                      dependencies={legalDistrictPath}
                    >
                      <SmartSelect
                        api={nomenclature.getLocationByDistrictId}
                        queryKey="locations-legal"
                        filters={{ districtId: district.legal, ordering: 'name' }}
                        enabled={!!district.legal}
                        placeholder={t('city')}
                        selected={selected.addresses[AddressType.LEGAL].location_id}
                        onSelect={(value) => onSelectLocation(AddressType.LEGAL, value)}
                      />
                    </Form.Field>
                  </Col>
                </Row>
                <Form.Field
                  label={t('complete_address')}
                  hideLabel
                  name={['addresses', AddressType.LEGAL, 'text']}
                  controlOptions={{ col: { size: 12 } }}
                >
                  <Input placeholder={t('complete_address')} />
                </Form.Field>
                <Form.Field
                  label={t('postal_code')}
                  hideLabel
                  rules={[{ required: true }]}
                  name={['addresses', AddressType.LEGAL, 'postal_code']}
                  controlOptions={{ col: { size: 6 } }}
                  labelOptions={{ col: { size: 12 } }}
                >
                  <Input placeholder={t('postal_code')} />
                </Form.Field>
              </Form.Group>

              <div className="form-divider" />
              <Form.Group label={t('postal_address')} required>
                <Form.Field
                  rules={[{ required: true }]}
                  name={['addresses', AddressType.POSTAL, 'type']}
                  initialValue={AddressType.POSTAL}
                />
                <Row gy={3} className="mb-16">
                  <Col size={6} md={12} lg={6}>
                    <Form.Field
                      label={t('district')}
                      hideLabel
                      rules={[{ required: true }]}
                      name={['addresses', AddressType.POSTAL, 'district_id']}
                      controlOptions={{ col: { size: 12 } }}
                    >
                      <SmartSelect
                        queryKey="districts"
                        api={nomenclature.getDistricts}
                        placeholder={t('district')}
                        selected={selected.addresses[AddressType.POSTAL].district_id}
                        onSelect={(value) => onSelectDistrict(AddressType.POSTAL, value)}
                      />
                    </Form.Field>
                  </Col>

                  <Col size={6} md={12} lg={6}>
                    <Form.Field
                      label={t('city')}
                      hideLabel
                      rules={[{ required: true }]}
                      name={['addresses', AddressType.POSTAL, 'location_id']}
                      controlOptions={{ col: { size: 12 } }}
                      dependencies={postalDistrictPath}
                    >
                      <SmartSelect
                        api={nomenclature.getLocationByDistrictId}
                        queryKey="locations-postal"
                        filters={{ districtId: district.postal, ordering: 'name' }}
                        enabled={!!district.postal}
                        placeholder={t('city')}
                        selected={selected.addresses[AddressType.POSTAL].location_id}
                        onSelect={(value) => onSelectLocation(AddressType.POSTAL, value)}
                      />
                    </Form.Field>
                  </Col>
                </Row>
                <Form.Field
                  label={t('complete_address')}
                  hideLabel
                  name={['addresses', AddressType.POSTAL, 'text']}
                  controlOptions={{ col: { size: 12 } }}
                >
                  <Input placeholder={t('complete_address')} />
                </Form.Field>

                <Form.Field
                  label={t('postal_code')}
                  hideLabel
                  rules={[{ required: true }]}
                  name={['addresses', AddressType.POSTAL, 'postal_code']}
                  controlOptions={{ col: { size: 6 } }}
                  labelOptions={{ col: { size: 12 } }}
                >
                  <Input placeholder={t('postal_code')} />
                </Form.Field>

                <Checkbox
                  text={t('same_juridical_address')}
                  onChange={onToggleSameJuridicalAddress}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Space justify="space-between">
            <Space>
              <Checkbox checked={agree || !!companyData} onChange={onToggleAgree} />
              <div onClick={onToggleAgree}>
                <Space align="start" size="small" direction="vertical">
                  {t('i_read_and_agree')}
                  {t('confirm_legal_origin_of_funds')}
                </Space>
              </div>
            </Space>

            <Button
              type="primary"
              submit
              className="text-nowrap"
              loading={postCompany.isLoading || updateCompany.isLoading}
              disabled={!agree && !companyData}
            >
              {t('next_step')}
              <span className="company-btn__arrow ml-10">
                <ArrowRight />
              </span>
            </Button>
          </Space>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default GeneralDataForm;
