import { axios, cancelTokenHandler, stringifyUrl } from 'libs';
import { Results, FullCompany, User, Properties } from 'types';

export const tenancy = {
  getCompaniesUsersList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<User>>(
      stringifyUrl(`/companies/users/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(tenancy.getCompaniesUsersList.name),
      },
    );

    return data;
  },
  getCompaniesList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<FullCompany>>(
      stringifyUrl(`/users/tenancy/companies/list/`, query),
      {
        cancelToken: cancelToken(tenancy.getCompaniesList.name),
      },
    );

    return data;
  },
  getUserByIDNP: async (idnp: string) => {
    const { data } = await axios.get<User>(`/users/tenancy/users/search/${idnp}/`, {
      cancelToken: cancelToken(tenancy.getUserByIDNP.name),
    });

    return data;
  },
  getUser: async (id: number) => {
    const { data } = await axios.get<User>(`/users/tenancy/users/${id}/`, {
      cancelToken: cancelToken(tenancy.getUser.name),
    });

    return data;
  },
  setUser: async (id: number) => {
    const { data } = await axios.put<User>(
      `/users/tenancy/users/${id}/`,
      {},
      {
        cancelToken: cancelToken(tenancy.setUser.name),
      },
    );

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(tenancy);
