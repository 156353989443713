import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import {
  Modal,
  Button,
  Icon,
  Space,
  Form,
  Input,
  useForm,
  Upload,
  useNotify,
  InputPhone,
} from 'ebs-design';
import { SmartSelect } from 'components';
import { UserContext } from 'contexts';
import { company, nomenclature } from 'api';
import { Properties, AttachmentType, Role, CustomerRole } from 'types';
import { useUpload } from 'hooks';
import { validateResponse, hasOwnProperty, extractResponseProps, flatten } from 'utils';

interface Props {
  userId?: number;
  companyId?: string;
  onClose: () => void;
}

const REPRESENTATIVE_ID = 11;
const userRoles = [
  CustomerRole.ACCOUNTANT,
  CustomerRole.ADMINISTRATOR,
  CustomerRole.REPRESENTATIVE,
];

export const NewUserForm: React.FC<Props> = React.memo(({ userId, companyId, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const uploadProps = useUpload();
  const queryClient = useQueryClient();

  const { user } = React.useContext(UserContext);
  const { id } = useParams();
  const [isRepresentative, setIsRepresentative] = React.useState(false);

  // Fetch user's roles
  const rolesList = useQuery(['nomenclature', 'roles'], () => nomenclature.getRoles(), {
    select: (roles) => roles.filter((role) => userRoles.includes(role.text as CustomerRole)),
  });

  // Fetch company user
  const companyUser = useQuery(
    ['company-user', (companyId || id || user?.company?.id) as number, userId],
    company.getUser,
    {
      enabled: !!userId,
    },
  );

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  // Fill form initial values on edit users
  React.useEffect(() => {
    if (!!userId && companyUser && companyUser.data) {
      const { roles = [], attachments = [] } = companyUser.data;

      // Finds roles id by their name
      const rolesIds = (roles as Role[]).map((r) => r.id);

      // Check if is representative user
      setIsRepresentative(rolesIds.includes(REPRESENTATIVE_ID));

      // Map attachments to form
      const attachmentsObjects = {};
      attachments.forEach((attachment) => (attachmentsObjects[attachment.type] = [attachment]));

      form.setFieldsValue({
        ...companyUser.data,
        roles_id: rolesIds || [],
        attachments_id: attachmentsObjects,
      });
    }
  }, [userId, companyUser, form]);

  const addUser = useMutation(
    (data: Properties) => company.addUser(user?.company?.id as number, data),
    {
      onError: (err) => {
        validateResponse.bind(null, form, err);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company-users');
        handleClose();
        notify.success({ title: t('company_users'), description: t('success_data_save') });
      },
    },
  );

  const updateUser = useMutation(
    ({ data, userId }: Properties) => company.updateUser(user?.company?.id as number, userId, data),
    {
      onError: (err) => {
        validateResponse.bind(null, form, err);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company-users');
        handleClose();
        notify.success({ title: t('company_users'), description: t('success_data_change') });
      },
    },
  );

  const handleAddSubmit = (data: Properties) => {
    const attachments = data?.attachments_id ? Object.values(data?.attachments_id) : [];

    const attachmentsIds = attachments?.map(
      (attachment: any) => attachment?.map((item) => item.id) || [],
    );

    const updatedData = {
      ...data,
      attachments_id: flatten(attachmentsIds),
    };

    if (userId) {
      updateUser.mutate({
        data: updatedData,
        userId,
      });
    } else {
      addUser.mutate(updatedData);
    }
  };

  const handleValuesChange = (field) => {
    if (hasOwnProperty(field, 'roles_id')) {
      setIsRepresentative(field.roles_id.includes(REPRESENTATIVE_ID));
    }
  };

  return (
    <Form
      form={form}
      type="horizontal"
      onFinish={handleAddSubmit}
      onValuesChange={handleValuesChange}
      labelOptions={{ className: 'text-right', col: { size: 3 } }}
    >
      <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
        <Input />
      </Form.Field>

      <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
        <Input />
      </Form.Field>

      <Form.Field name="idnp" label={t('idnp')} rules={[{ required: true, len: 13 }]}>
        <Input type="number" />
      </Form.Field>

      <Form.Field name="roles_id" label={t('function')} rules={[{ required: true }]}>
        <SmartSelect
          mode="multiple"
          loading={rolesList.isLoading}
          options={rolesList.data?.map(({ value, text }) => ({ value, text: t(text as string) }))}
        />
      </Form.Field>

      <Form.Field name="phone" label={t('phone')} rules={[{ required: true }]}>
        <InputPhone country="md" />
      </Form.Field>

      <Form.Field name="email" label={t('email')} rules={[{ required: true }]}>
        <Input type="email" />
      </Form.Field>

      <Form.Field
        name={['attachments_id', AttachmentType.BULLETIN]}
        label={t('identity_card')}
        rules={[{ required: true }]}
      >
        {(control) => (
          <Upload
            data={{ type: AttachmentType.BULLETIN }}
            multiple
            disabled={control?.value?.length >= 2}
            {...uploadProps}
            {...control}
          >
            <Button disabled={control?.value?.length >= 2}>{t('upload')}</Button>
          </Upload>
        )}
      </Form.Field>

      <Form.Field
        name={['attachments_id', AttachmentType.POWER_OF_ATTORNEY]}
        label={t('attorney_power')}
        rules={[{ required: isRepresentative }]}
        style={{ display: isRepresentative ? 'block' : 'none' }}
      >
        <Upload data={{ type: AttachmentType.POWER_OF_ATTORNEY }} {...uploadProps}>
          <Button>{t('upload')}</Button>
        </Upload>
      </Form.Field>

      <Modal.Footer style={{ margin: '0 -20px -30px' }}>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Form>
  );
});
