import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Row, Col, Button, Form, Input, Space, Icon, Modal, useForm } from 'ebs-design';
import { nomenclature } from 'api';
import { MemberEntity } from 'types';
import { DatePicker, SmartSelect } from 'components';

import { MemberType } from './MemberList';

interface Props {
  type: MemberType;
  title: string;
  visible: boolean;
  data?: MemberEntity | null;
  onClose: () => void;
  onSave: (values) => void;
}

const AddMemberModal: React.FC<Props> = ({
  visible = false,
  title,
  type,
  data,
  onClose,
  onSave,
}) => {
  const { t } = useIntl();
  const [form] = useForm();

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [form, data]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <>
      <Modal open={visible} onClose={handleClose} title={title} size="small">
        <Modal.Content className="px-20 py-30">
          <Form
            form={form}
            type="horizontal"
            onFinish={onSave}
            labelOptions={{ col: { size: 4 } }}
            controlOptions={{ col: { size: 7 } }}
            fieldRow={{ gy: 3 }}
          >
            <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
              <Input />
            </Form.Field>

            <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
              <Input />
            </Form.Field>

            <Form.Field name="function" label={t('function')} rules={[{ required: true }]}>
              <Input />
            </Form.Field>

            {type === 'family' ? (
              <Form.Field name="degree_of_kinship_id" label={t('degree_of_kinship')}>
                <SmartSelect
                  queryKey="degreeOfKinships"
                  api={nomenclature.getDegreeOfKinships}
                  transform={(results) =>
                    results.map(({ id, code_name }) => ({
                      value: id,
                      text: t(code_name),
                    }))
                  }
                />
              </Form.Field>
            ) : (
              <Form.Field name="degree_of_kinship_alternative" label={t('degree_of_kinship')}>
                <Input />
              </Form.Field>
            )}

            <Form.Group label={t('period')}>
              <Row>
                <Col size={6}>
                  <Form.Field name="function_start" controlOptions={{ col: { size: 12 } }}>
                    <DatePicker placeholder={t('from')} />
                  </Form.Field>
                </Col>
                <Col size={6}>
                  <Form.Field name="function_end" controlOptions={{ col: { size: 12 } }}>
                    <DatePicker placeholder={t('to')} />
                  </Form.Field>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={handleClose}>{t('cancel')}</Button>

            <Button
              type="primary"
              prefix={<Icon type="check" model="bold" />}
              onClick={() => form.submit()}
            >
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddMemberModal;
