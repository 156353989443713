import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Space, Icon, Textarea, Input, Label } from 'ebs-design';
import { Currencies } from 'types';

interface Props {
  proposed_limit?: string;
  disabled?: boolean;
  disabledLimit?: boolean;
  onConfirm: (value: string, limit: string) => void;
}

export const CommentModal: React.FC<Props> = ({
  proposed_limit = '0',
  disabled,
  disabledLimit,
  onConfirm,
  children,
}) => {
  const { t } = useIntl();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [limit, setLimit] = React.useState(proposed_limit);

  const onToggleHandler = () => setOpen((i) => !i);

  const onSave = () => {
    onConfirm(value, limit);
    onToggleHandler();
  };

  return (
    <>
      <Modal
        open={open}
        className="pc-modal--extra-small"
        size="small"
        header={t('change_limit')}
        onClose={onToggleHandler}
      >
        <Modal.Content>
          <Space justify="center" className="mb-15">
            {t('limit')}
            <Input
              type="number"
              suffix={<Label text={Currencies.MDL} disabled />}
              value={limit}
              onChange={(v) => setLimit(v as string)}
              disabled={disabledLimit}
            />
          </Space>
          {t('comment')}
          <Textarea className="mt-10" value={value} onChange={setValue} />
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onToggleHandler}> {t('cancel')} </Button>

            <Button type="primary" prefix={<Icon type="check" model="bold" />} onClick={onSave}>
              {t('send')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
      <div onClick={!disabled ? onToggleHandler : undefined}>{children}</div>
    </>
  );
};
