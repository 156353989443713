import { axios, cancelTokenHandler } from 'libs';
import { Properties, Results, Request, Comment, Conclusion, Vote } from 'types';
import { default as queryString } from 'query-string';
import { AxiosResponse } from 'axios';
import { transformResponse, formatNumber } from 'utils';

export const requests = {
  create: async (bodyData: Properties) => {
    return await axios.post(`/requests/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(requests.create.name),
    });
  },

  getList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Request>>(
      queryString.stringifyUrl(
        {
          url: `/requests/list/`,
          query: queryKey[1],
        },
        { skipEmptyString: true, skipNull: true },
      ),
      {
        transformResponse: (response) =>
          transformResponse(response, {
            properties: [
              'limit',
              'proposed_limit',
              'company.limit.accessible',
              'company.limit.current',
              'company.limit.inaccessible',
              'company.request.limit',
              'company.request.proposed_limit',
              'company.statistics.invoices.remained.amount',
              'company.statistics.invoices.remained.count',
              'committee.factoring_range.maximum',
              'committee.factoring_range.minimum',
            ],
            wrapper: (value) => formatNumber(value),
          }),
        cancelToken: cancelToken(requests.getList.name),
      },
    );

    return data;
  },

  get: async (id: string, mergeVotes?: boolean, transform = true) => {
    const { data } = await axios.get<Request>(`/requests/${id}/`, {
      cancelToken: cancelToken(requests.get.name),
      transformResponse: (response) => {
        response = transformResponse(response);

        if (mergeVotes && response.committee) {
          response.committee.users = response.committee.users.map((user) => {
            const vote = response.votes.find((vote) => vote.user.id === user.id);

            if (vote) {
              user.vote = vote;
            }

            return user;
          });
        }

        return transform
          ? transformResponse(response, {
              properties: [
                'limit',
                'proposed_limit',
                'company.limit.accessible',
                'company.limit.current',
                'company.limit.inaccessible',
                'company.request.limit',
                'company.request.proposed_limit',
                'company.statistics.invoices.remained.amount',
                'company.statistics.invoices.remained.count',
                'committee.factoring_range.maximum',
                'committee.factoring_range.minimum',
              ],
              wrapper: (value) => formatNumber(value),
            })
          : response;
      },
    });

    return data;
  },

  patch: async ({ id, ...bodyData }: Properties) => {
    return await axios.patch(`/requests/${id}/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(requests.patch.name),
    });
  },

  getVotes: async (id: number) => {
    const { data } = await axios.get<Results<Vote>>(`/requests/${id}/votes/list/`, {
      cancelToken: cancelToken(requests.getVotes.name),
    });

    return data;
  },

  createVote: async (id: number, bodyData: Properties) => {
    return await axios.post(`/requests/${id}/votes/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(requests.createVote.name),
    });
  },

  updateVote: async (id: number, voteId, bodyData: Properties) => {
    return await axios.patch(`/requests/${id}/votes/${voteId}/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(requests.createVote.name),
    });
  },

  addConclusion: async (id: number, bodyData: Conclusion) => {
    return await axios.post(`/requests/${id}/conclusions/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(requests.addConclusion.name),
    });
  },

  createComment: async ({ id, ...bodyData }: Properties) => {
    return await axios.post<AxiosResponse<Comment>>(`/requests/${id}/comments/`, bodyData, {
      transformResponse,
      cancelToken: cancelToken(requests.createComment.name),
    });
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(requests);
