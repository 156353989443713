import * as React from 'react';
// import { useIntl } from 'estafette-intl';
import { Container, Row, Col } from 'ebs-design';
import { Layout } from 'components/organisms';
// import { Period } from 'types';
import { InvoicesSales, InvoicesTotals } from 'features/reports/tables';
import {
  BacklogsReport,
  DynamicsReport,
  InspectorsReport,
  PortfoliosReport,
  InvoicesActivitiesReport,
  InvoicesMonthlyReport,
  InvoicesRatiosReport,
} from 'features/reports';

export const Admin: React.FC = () => (
  <Layout>
    <Container className="mt-10">
      <Row g={4}>
        <Col size={12}>
          <InvoicesTotals />
        </Col>
        <Col size={12}>
          <InvoicesSales />
        </Col>
        <Col size={12}>
          <InvoicesRatiosReport />
        </Col>
        <Col size={12}>
          <InvoicesMonthlyReport />
        </Col>
        <Col size={12}>
          <InvoicesActivitiesReport />
        </Col>
        <Col size={12}>
          <PortfoliosReport />
        </Col>
        <Col size={12}>
          <BacklogsReport />
        </Col>
        <Col size={12}>
          <DynamicsReport />
        </Col>
        <Col size={12}>
          <InspectorsReport />
        </Col>
      </Row>
    </Container>
  </Layout>
);
