import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Button, Table } from 'ebs-design';
import { formatNumber } from 'utils';
import { Column, Currencies, ReportInspectorGraph } from 'types';

interface Props {
  data?: ReportInspectorGraph[];
  onRowClick: (data: ReportInspectorGraph) => void;
}

export const InspectorsTable: React.FC<Props> = ({ data, onRowClick }) => {
  const { t } = useIntl();

  const columns: Column<ReportInspectorGraph>[] = React.useMemo(
    () => [
      {
        title: t('SY_CREDIT_OFFICER'),
        render: (rowData) => `${rowData.users_first_name} ${rowData.users_last_name}`,
      },
      {
        title: t('amount'),
        dataIndex: 'invoices_counts',
        render: (value) => formatNumber(value, '', 0),
      },
      {
        title: t('sum'),
        dataIndex: 'invoices_amounts',
        render: (value) => formatNumber(value, Currencies.MDL),
      },
      {
        title: t('status'),
        dataIndex: 'active',
        render: (status) => t(status ? 'active' : 'inactive'),
      },
      {
        title: null,
        width: 150,
        render: (rowData) => (
          <Button size="small" onClick={() => onRowClick(rowData)}>
            {t('see_chart')}
          </Button>
        ),
      },
    ],
    [t, onRowClick],
  );

  return <Table data={data} columns={columns} emptyCell="---" scroll={{ y: 365 }} />;
};
