import * as React from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import {
  Table,
  Card,
  Space,
  Button,
  InputSearch,
  Icon,
  SortBy,
  Actions,
  Loader,
  useNotify,
} from 'ebs-design';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { Classifier, Column } from 'types';
import { defaultFilters, getSortOptions, stringifyCategoryData, extractResponseProps } from 'utils';
import { Pagination } from 'components/organisms';
import { Plus } from 'resources/icons';
import { nomenclature } from 'api/nomenclature';
import { ClassifierModal } from './ClassifierModal';
import { IndicatorsModal } from './IndicatorsModal';
import { ClassifiersLayout } from 'features/profile/ClassifiersLayout';

export const Classifiers: React.FC = () => {
  const params = useQueryParams();
  const { t } = useIntl();
  const { updateQuery } = useQueryUpdate();
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });

  const [modalVisible, setModalVisible] = React.useState(false);
  const [classifier, setClassifier] = React.useState<Classifier | null>(null);

  const [modalIndicatorsVisible, setModalIndicatorsVisible] = React.useState(false);

  const notify = useNotify();

  React.useEffect(() => updateQuery(filters), [filters, updateQuery]);

  const [categories, classifiers]: any[] = useQueries([
    {
      queryKey: ['categories'],
      queryFn: () => nomenclature.getCategories(),
    },
    {
      queryKey: ['classifiers', filters],
      queryFn: () => nomenclature.getClassifiers(filters),
      select: (data: any) => {
        const totalScore = data.results?.reduce((acc, item) => acc + item.score, 0);
        return {
          ...data,
          results: data.results.concat({
            title: '-',
            formulae: '-',
            score: totalScore,
            isTotal: true,
          }),
        };
      },
    },
  ]);

  const queryClient = useQueryClient();
  const { mutate: deleteClassifier } = useMutation(nomenclature.deleteClassifier, {
    onSuccess: () => {
      queryClient.invalidateQueries('classifiers');
      notify.success({ title: t('classifiers'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const handleModalClose = React.useCallback(() => {
    setModalVisible(false);
    setModalIndicatorsVisible(false);
    setClassifier(null);
  }, []);

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('step'),
        filter: 'id',
        width: '8%',
        render: (item, row, index) => {
          if (item.isTotal) {
            return <h4>{t('total').toUpperCase()}</h4>;
          }
          return `${(filters.page - 1) * filters.limit + index + 1}`.padStart(2, '0');
        },
      },
      {
        title: t('initial_classification'),
        dataIndex: 'title',
        filter: 'title',
      },
      {
        title: t('rules'),
        dataIndex: 'formulae',
        filter: 'formulae',
      },
      {
        title: t('norm'),
        width: '10%',
        render: (item) => {
          if (item.isTotal) {
            return '-';
          }
          return stringifyCategoryData(item, t);
        },
      },
      {
        title: t('category'),
        align: 'center',
        children: categories.data?.results?.map((category) => ({
          title: category.code_name,
          dataIndex: 'categories',
          align: 'center',
          width: '10%',
          render: (classifierCategories) => {
            if (!classifierCategories) {
              return '-';
            }
            const classifierCategory = classifierCategories.find(
              (classifierCategory) => category.code_name === classifierCategory.code_name,
            );
            return !classifierCategory ? '-' : stringifyCategoryData(classifierCategory, t);
          },
        })),
      },
      {
        title: t('weight_score'),
        filter: 'weight_score',
        dataIndex: 'score',
        render: (weightScore) => `${weightScore || '-'}`,
      },
      {
        title: '',
        action: true,
        render: (item) => {
          if (item.isTotal) {
            return null;
          }
          return (
            <Actions>
              <Actions.Item
                onClick={() => {
                  setModalVisible(true);
                  setClassifier(item);
                }}
              >
                {t('edit')}
              </Actions.Item>
              <Actions.Item
                onClick={() => {
                  setModalIndicatorsVisible(true);
                  setClassifier(item);
                }}
              >
                {t('indicators')}
              </Actions.Item>
              <Actions.Item
                onClick={() => {
                  deleteClassifier(item.id);
                }}
              >
                {t('delete')}
              </Actions.Item>
            </Actions>
          );
        },
      },
    ],
    [t, categories.data, filters, deleteClassifier],
  );

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  return (
    <>
      {modalVisible && <ClassifierModal classifier={classifier} onClose={handleModalClose} />}
      {modalIndicatorsVisible && (
        <IndicatorsModal
          visible={modalIndicatorsVisible}
          classifier={classifier}
          onClose={handleModalClose}
        />
      )}

      <ClassifiersLayout>
        <Space align="center" justify="space-between" className="mt-5 mb-20 mt-20">
          <Space align="center">
            <h3 className="page-title">
              {t('classifiers')} ({classifiers.data?.count || 0})
            </h3>
            <InputSearch
              placeholder={t('search')}
              styleType="fill"
              value={filters.search}
              onSearch={(search) => setFilters({ search, page: 1 })}
              isClearable
            />
          </Space>
          <Space align="center">
            <SortBy
              options={sortOptions}
              value={filters?.ordering}
              onChange={(ordering) => setFilters({ ordering })}
            />
            <Button
              type="ghost"
              prefix={<Icon component={Plus} />}
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {t('add_new')}
            </Button>
          </Space>
        </Space>
        <Card>
          <Card.Body className="p-0">
            <Loader loading={categories.isLoading || classifiers.isLoading}>
              <Table
                className="table-no-border pc-classifiers__table"
                columns={columns}
                data={classifiers.data?.results}
              />
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Pagination data={classifiers.data} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </ClassifiersLayout>
    </>
  );
};
