import * as React from 'react';
import { Modal } from 'ebs-design';
import { AssociateType, Properties } from 'types';
import { IndividualPartnerForm } from './IndividualPartnerForm';
import { LegalPartnerForm } from './LegalPartnerForm';

interface Props {
  title: string;
  userId?: number;
  type: AssociateType;
  data: Properties;
  onClose: () => void;
}

export const AddPartnerModal: React.FC<Props> = ({ type: typeAdd, title, data, onClose }) => {
  const [type, setType] = React.useState<AssociateType>(AssociateType.LEGAL);

  React.useEffect(() => {
    setType(typeAdd);
  }, [typeAdd]);

  return (
    <Modal open={true} onClose={onClose} title={title} size="small">
      <Modal.Content className="px-20 py-30">
        {type === AssociateType.LEGAL ? (
          <LegalPartnerForm data={data} onClose={onClose} />
        ) : (
          <IndividualPartnerForm data={data} onClose={onClose} />
        )}
      </Modal.Content>
    </Modal>
  );
};
