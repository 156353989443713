import React, { FC, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { getRoute } from 'utils';
import { routes } from 'routes';
import { MyProfileLayout } from './MyProfileLayout';

export interface RatesLayoutProps {
  children?: ReactNode;
}

export const RatesLayout: FC = ({ children }) => {
  const { t } = useIntl();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const handleTabClick = (tabKey: string) => () => {
    push(getRoute(routes, tabKey));
  };

  return (
    <MyProfileLayout>
      <Tabs activeTab={pathname} className="company-tab">
        <Tabs.Tab
          tabKey="/profile/rates"
          label={t('standard_rates')}
          onClick={handleTabClick('Rates')}
        />
        <Tabs.Tab
          tabKey="/profile/rates/future"
          label={t('future_rates')}
          onClick={handleTabClick('RatesFuture')}
        />
        <Tabs.Tab
          tabKey="/profile/rates/history"
          label={t('history_rates')}
          onClick={handleTabClick('RatesHistory')}
        />
      </Tabs>
      {children}
    </MyProfileLayout>
  );
};
