import { default as queryString } from 'query-string';
import { axios, cancelTokenHandler } from 'libs';
import { Results, Comment, Properties } from 'types';

export const comments = {
  getList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Comment>>(
      queryString.stringifyUrl(
        {
          url: `/comments/list/`,
          query: queryKey[1],
        },
        { skipEmptyString: true },
      ),
      {
        cancelToken: cancelToken(comments.getList.name),
      },
    );

    return data;
  },
  get: async (id: number) => {
    const { data } = await axios.get<Comment>(`/comments/${id}/`, {
      cancelToken: cancelToken(comments.get.name),
    });

    return data;
  },
  update: async ({ id, ...props }: Properties) => {
    const { data } = await axios.patch<Comment>(`/comments/${id}/`, props, {
      cancelToken: cancelToken(comments.update.name),
    });

    return data;
  },
  delete: async ({ id }) => {
    const { data } = await axios.delete<Comment>(`/comments/${id}/`, {
      cancelToken: cancelToken(comments.delete.name),
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(comments);
