import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'react-router-dom';
import { AvatarInline, firstLetters } from 'ebs-design';
import { Column, Properties } from 'types';
import { Adherent as CompanyIcon } from 'resources';
import { getRoute } from 'utils';
import { routes } from 'routes';

export const useDebtorColumns = () => {
  const { t } = useIntl();
  const { push } = useHistory();

  const columns: Column<Properties>[] = React.useMemo(
    () => [
      {
        title: t('name'),
        filter: 'title',
        render: ({ title, email }) => (
          <AvatarInline
            icon={<CompanyIcon />}
            circle
            alt={title}
            shortAlt={firstLetters(title)}
            description={email}
          />
        ),
        onCell: (rowData) => ({
          className: 'ebs-table__cell--clickable',
          onClick: () => push(getRoute(routes, 'DebtorDetails', { id: rowData.id })),
        }),
        width: 250,
      },
      {
        title: t('status'),
        dataIndex: 'status',
        filter: 'status',
        width: 90,
      },
      {
        title: t('total_active_invoices'),
        dataIndex: 'total_active_invoices',
        filter: 'total_active_invoices',
      },
      {
        title: t('available_limit'),
        dataIndex: ['limit', 'accessible'],
        filter: 'limit.accessible',
      },
      {
        title: t('sum_of_active_invoices'),
        dataIndex: 'sum_of_active_invoices',
        filter: 'sum_of_active_invoices',
      },
      {
        title: t('total_assigned_invoices'),
        dataIndex: 'total_remained_invoices',
        filter: 'total_remained_invoices',
      },
      {
        title: t('amount_of_assigned_invoices'),
        dataIndex: 'sum_of_remained_invoices',
        filter: 'sum_of_remained_invoices',
      },
    ],
    [t, push],
  );

  return columns;
};
