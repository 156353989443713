import * as React from 'react';
import cn from 'classnames';
import { useIntl } from 'estafette-intl';
import { Card, Space } from 'ebs-design';
import { Currencies } from 'types';

interface Props {
  title: string;
  total?: number;
  currency?: Currencies;
  primary?: boolean;
  info?: {
    title?: string | number;
    description?: string;
  };
}

export const StatisticCard: React.FC<Props> = ({
  title,
  currency,
  total,
  primary,
  info,
  children,
}) => {
  const { t } = useIntl();
  const withCurrency = currency ? `(${currency})` : null;

  return (
    <Card className={cn('statistic-card', { 'statistic-card--primary': primary })}>
      <Card.Header bordered>
        {title} {withCurrency}
      </Card.Header>
      <Card.Body className="p-0">
        <div className="statistic-card__info">
          <Space justify="space-between" align="start">
            <div>
              <h3>{total || 0}</h3>
              <p className="color-primary">{t('total')}</p>
            </div>
            {info && (
              <div>
                <h3>{info.title || 0}</h3>
                <p className="color-primary">{info.description}</p>
              </div>
            )}
          </Space>
        </div>
        <div className="statistic-card__children">{children}</div>
      </Card.Body>
    </Card>
  );
};
