import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { NotifyProvider, NotifyContainer, FormProvider } from 'ebs-design';
import { routes } from 'routes';
import { useValidateMessages } from 'hooks';
import { CreateIntl } from 'estafette-intl';
import { messages } from './locales';
import { UserProvider } from './contexts';
import { baseName } from 'utils';

export const App: React.FC = () => {
  const validateMessages = useValidateMessages();

  return (
    <BrowserRouter basename={baseName}>
      <UserProvider>
        {({ language }) => (
          <CreateIntl defaultLocale={language} messages={messages}>
            <NotifyProvider>
              <NotifyContainer />
              <FormProvider validateMessages={validateMessages}>
                {routes.map(({ component: Component, path, exact, name }, key) => (
                  <Route key={key} name={name} path={path} exact={exact}>
                    <Component />
                  </Route>
                ))}
              </FormProvider>
            </NotifyProvider>
          </CreateIntl>
        )}
      </UserProvider>
    </BrowserRouter>
  );
};
