import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table } from 'ebs-design';
import { Column, ReportSalePayment, Period } from 'types';
import { formatDate } from 'libs';
import { formatNumber } from 'utils';

export const InvoiceRepaymentsTable: React.FC<{ data?: ReportSalePayment[]; period: Period }> = ({
  data = [],
  period,
}) => {
  const { t } = useIntl();

  const renderFormattedDate = React.useCallback(
    (value, rowData) => {
      const formattedDate = {
        [Period.DAY]: formatDate(rowData.date) || value,
        [Period.WEEK]: `${t('the_week')} ${value}`,
        [Period.MONTH]: t(`month${value}`),
        [Period.YEAR]: value,
      };

      return formattedDate[period] || value;
    },
    [period, t],
  );

  const columns: Column<ReportSalePayment>[] = React.useMemo(
    () => [
      {
        title: t('number'),
        dataIndex: 'number',
        render: renderFormattedDate,
      },
      {
        title: t('total_invoices'),
        dataIndex: 'invoices_total',
        render: (value) => formatNumber(value, '', 0),
      },
      {
        title: t('invoice_amount'),
        dataIndex: 'amount',
        render: (value) => formatNumber(value),
      },
      {
        title: t('warranty_returned'),
        dataIndex: 'guarantee_return_amount_total',
        render: (value) => formatNumber(value),
      },
      {
        title: t('average_irr'),
        dataIndex: 'irr',
        render: (value) => formatNumber(value),
      },
      {
        title: t('collected_penalties'),
        dataIndex: 'statistic_penalty_interest_total',
        render: (value) => formatNumber(value),
      },
    ],
    [t, renderFormattedDate],
  );

  return <Table data={data} columns={columns} scroll={{ y: 500 }} emptyCell="---" />;
};
