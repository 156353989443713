import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Select, useNotify } from 'ebs-design';
import { company, nomenclature } from 'api';
import { CategoryInfo } from 'types';
import { extractResponseProps } from 'utils';
import { ConfirmationModal } from 'components/organisms';

type Props = {
  category: CategoryInfo | null;
};

export const EditCompanyCategory = ({ category }: Props) => {
  const { t } = useIntl();
  const notify = useNotify();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [isConfirmToSetNewRates, setIsConfirmToSetNewRates] = React.useState(false);

  const { data, isLoading } = useQuery(['categories'], () => nomenclature.getCategories(), {
    select: (data) => data.results.map(({ id, code_name }) => ({ value: id, text: code_name })),
  });

  const { mutate, isLoading: isLoadingMutate } = useMutation<
    unknown,
    unknown,
    { category: number }
  >((category) => company.updateCompanyCategory(id, category), {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['company', id]);
      queryClient.invalidateQueries(['categories']);

      setIsConfirmToSetNewRates(true);
      notify.success({ title: t('category'), description: t('success_data_save') });
    },
  });

  const { mutate: setNewRatesMutate } = useMutation(company.setCompanyCategoryNewRates, {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['individual', 'rates']);
      notify.success({ title: t('rate'), description: t('success_data_save') });
      setIsConfirmToSetNewRates(false);
    },
  });

  return (
    <>
      <Select
        className="select-category"
        value={category?.id}
        onChange={(value) => mutate({ category: value as number })}
        options={data}
        loading={isLoading}
        disabled={isLoadingMutate}
      />
      {isConfirmToSetNewRates && (
        <ConfirmationModal
          title={t('rates')}
          description={t('do_you_want_to_set_new_company_rate')}
          onConfirm={() => setNewRatesMutate(id)}
          onCancel={() => setIsConfirmToSetNewRates(false)}
          visible={true}
        />
      )}
    </>
  );
};
