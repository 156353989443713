import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Space, Loader, DatePicker } from 'ebs-design';
import { RadioButtons } from 'components';
import { useReports } from 'hooks';
import { dateFormat, formatDateFilters } from 'libs';
import { ReportType, ReportSalesPayments, Period } from 'types';
import { InvoicePaymentsTable, InvoiceRepaymentsTable } from './tables';

export const InvoicesPaymentsReport: React.FC = () => {
  const { t } = useIntl();
  const [period, setPeriod] = React.useState(Period.WEEK);
  const [range, setRange] = React.useState([]);

  const { data, isLoading } = useReports<ReportSalesPayments, ReportSalesPayments>(
    ReportType.SALES_PAYMENTS,
    {
      date_from: range?.[0] && formatDateFilters(range?.[0]),
      date_to: range?.[1] && formatDateFilters(range?.[1]),
      period,
    },
  );

  const periodOptions = React.useMemo(
    () =>
      Object.values([Period.DAY, Period.WEEK, Period.MONTH, Period.YEAR]).map((v) => ({
        value: v,
        text: t(`${v.toLowerCase()}`),
      })),
    [t],
  );

  return (
    <>
      <Space className="mb-15 mt-5" justify="space-between">
        <Space>
          <h4>{t('sales')}</h4>
          <RadioButtons options={periodOptions} value={period} onChange={setPeriod} />
        </Space>

        <DatePicker.RangeInput
          value={range}
          onChange={(value) => setRange(value as any)}
          isClearable
          placeholderText={t('choose_period')}
          showYearDropdown
          dateFormat={dateFormat}
        />
      </Space>
      <Loader loading={isLoading}>
        <InvoicePaymentsTable data={data?.sales} period={period} />

        <h4 className="mb-15 mt-30">{t('repayments')}</h4>
        <InvoiceRepaymentsTable data={data?.repayments} period={period} />
      </Loader>
    </>
  );
};
