import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import {
  Container,
  Table,
  Card,
  Space,
  Button,
  Actions,
  Loader,
  Label,
  useNotify,
  DatePicker,
} from 'ebs-design';
import { company, invoices, turnovers } from 'api';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { Column, CompanyType, ListTurnover } from 'types';
import {
  extractResponseProps,
  turnoverDefaultFilters,
  formatNumber,
  transformResults,
} from 'utils';
import { ConfirmationModal, Layout, Pagination, SmartSelect } from 'components';
import {
  currentDayDateFormat,
  dateFormat,
  firstDayOfYearDateFormat,
  formatDate,
  formatDateFilters,
} from 'libs';
import { TurnoversForm } from '../components/TurnoversForm';

export const Turnovers = () => {
  const { t } = useIntl();

  const notify = useNotify();

  const { updateQuery } = useQueryUpdate();

  const queryClient = useQueryClient();
  const params = useQueryParams();

  const [selectedTurnover, setSelectedTurnover] = React.useState<ListTurnover | undefined>();
  const [deleteTurnoverId, setDeleteTurnoverId] = React.useState<number>();
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [filters, setFilters] = useSetState({ ...turnoverDefaultFilters, ...params });
  const [range, setRange] = React.useState<[Date | string | null, Date | string | null]>([
    null,
    null,
  ]);

  const rangeFiltersApi =
    range?.[0] && range?.[1]
      ? {
          timestamp__range: `${range?.[0] ? formatDateFilters(range?.[0]) : null}__${
            range?.[1] ? formatDateFilters(range?.[1]) : null
          }`,
        }
      : {};

  React.useEffect(() => updateQuery(filters), [filters, updateQuery]);
  React.useEffect(() => {
    setRange([firstDayOfYearDateFormat, currentDayDateFormat]);
  }, []);

  const { data: turnoversList, isLoading: isLoadingTurnoversList } = useQuery(
    [
      'turnovers',
      {
        ...filters,
        ...rangeFiltersApi,
      },
    ],
    turnovers.getTurnoversList,
  );

  const { data: turnoversCalculate } = useQuery(
    [
      'turnovers-calculate',
      {
        ...filters,
        ...rangeFiltersApi,
      },
    ],
    turnovers.getCalculate,
    { select: (data) => [data] },
  );

  const { mutate: removeTurnoverMutate, isLoading: isLoadingTurnoverMutate } = useMutation(
    (turnoverId?: number) => turnovers.deleteTurnovers?.(turnoverId),
    {
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('turnovers');
        notify.success({ title: t('turnover'), description: t('success_data_delete') });
      },
    },
  );

  const columns: Column[] = React.useMemo(
    () => [
      { title: t('payment_type'), dataIndex: 'payment_type' },
      {
        title: t('operation_type'),
        dataIndex: 'operation_type',
        render: (payment_type) => t(payment_type),
      },
      {
        title: t('invoice'),
        dataIndex: ['invoice', 'series_number'],
      },
      {
        title: t('timestamp'),
        render: (turnover) => formatDate?.(turnover.date),
      },
      {
        title: t('collected'),
        dataIndex: 'collected',
        render: (collected: string) => formatNumber(collected),
      },
      {
        title: t('warrant'),
        dataIndex: 'warranty',
        render: (warranty: string) => formatNumber(warranty),
      },
      {
        title: t('penalty'),
        dataIndex: 'penalty',
        render: (penalty: string) => formatNumber(penalty),
      },
      {
        title: t('status'),
        dataIndex: 'status',
      },
      {
        title: t('adherent'),
        dataIndex: 'adherent',
      },
      {
        title: t('debtor'),
        dataIndex: 'debtor',
      },
      {
        title: t('sold'),
        dataIndex: 'balance',
        render: (amount: string) => formatNumber(amount),
      },
      {
        title: null,
        action: true,
        render: (turnover) => (
          <Actions>
            <Actions.Item onClick={() => setSelectedTurnover(turnover)}>{t('edit')}</Actions.Item>
            <Actions.Item onClick={() => setDeleteTurnoverId(turnover?.id)}>
              {t('delete')}
            </Actions.Item>
          </Actions>
        ),
      },
    ],
    [t],
  );

  const renderTotalAmount = React.useCallback(() => {
    const firstTotalAmountElement = turnoversCalculate?.[0] || {};
    const totalAmountKeys = Object.keys(firstTotalAmountElement).map((key) =>
      key.replace('_total', ''),
    );
    return (
      <tr>
        {columns.map((column) => {
          return (
            <td className="rc-table-cell" key={column.key}>
              <strong>
                {column.dataIndex && totalAmountKeys.includes(column.dataIndex as string)
                  ? formatNumber(firstTotalAmountElement[`${column.dataIndex}_total`])
                  : null}
              </strong>
            </td>
          );
        })}
      </tr>
    );
  }, [turnoversCalculate]);

  return (
    <Layout>
      <Container>
        <Space align="center" justify="space-between" className="mt-5 mb-20">
          <h3 className="page-title">
            {t('turnovers')} ({turnoversList?.count || 0})
          </h3>

          <Space align="center" wrap>
            <Space>
              <Label text={t('invoice')} />

              <SmartSelect
                search
                api={invoices.getList}
                value={filters.invoice__series_number}
                transform={(response) =>
                  transformResults(response, 'series_number', 'series_number')
                }
                onChange={(invoice__series_number) =>
                  setFilters({ invoice__series_number, page: 1 })
                }
                className="select-min-width"
              />
            </Space>

            <Space>
              <Label text={t('adherent')} />

              <SmartSelect
                search
                api={company.getList}
                filters={{ types: CompanyType.ADHERENT.toLowerCase() }}
                value={filters.invoice__company__id}
                transform={(response) => transformResults(response)}
                onChange={(invoice__company__id) => setFilters({ invoice__company__id, page: 1 })}
                className="select-min-width"
              />
            </Space>

            <Space>
              <Label text={t('debtor')} />

              <SmartSelect
                search
                api={company.getList}
                filters={{ types: CompanyType.DEBTOR.toLowerCase() }}
                value={filters.invoice__companies__id}
                transform={(response) => transformResults(response)}
                onChange={(invoice__companies__id) =>
                  setFilters({ invoice__companies__id, page: 1 })
                }
                className="select-min-width"
              />
            </Space>

            <Space>
              <Label text="Period" />

              <DatePicker.RangeInput
                value={range}
                onChange={(value) => setRange(value as any)}
                placeholderText={t('choose_period')}
                isClearable
                showYearDropdown
                dateFormat={dateFormat}
              />
            </Space>
            <Button onClick={() => setIsOpenModal(true)}>{t('create')}</Button>
          </Space>
        </Space>

        <Card>
          <Card.Body className="p-0">
            <Loader loading={isLoadingTurnoversList || isLoadingTurnoverMutate}>
              <Table
                className="table-no-border"
                columns={columns}
                data={turnoversList?.results}
                emptyCell="---"
                {...(filters?.invoice__series_number ||
                filters?.invoice__company__id ||
                filters?.invoice__companies__id
                  ? { summary: renderTotalAmount }
                  : {})}
              />
            </Loader>
          </Card.Body>

          <Card.Footer>
            <Pagination data={turnoversList} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </Container>

      {(selectedTurnover || isOpenModal) && (
        <TurnoversForm
          onClose={() => {
            setIsOpenModal(false);
            setSelectedTurnover(undefined);
          }}
          isOpen={true}
          initialData={selectedTurnover}
        />
      )}

      {deleteTurnoverId && (
        <ConfirmationModal
          title={t('delete_turnovers')}
          description={t('delete_turnover_confirm')}
          onConfirm={() => removeTurnoverMutate(deleteTurnoverId)}
          onCancel={() => setDeleteTurnoverId(undefined)}
          visible={true}
        />
      )}
    </Layout>
  );
};
