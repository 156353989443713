import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Icon, Space, Form, useForm, Upload, useNotify } from 'ebs-design';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts';
import { company, nomenclature } from 'api';
import { Properties, AttachmentType, Role, CustomerRole } from 'types';
import { useUpload } from 'hooks';
import { validateResponse, hasOwnProperty, extractResponseProps } from 'utils';
import { SmartSelect } from 'components';

interface Props {
  userId?: number;
  companyId?: string;
  onClose: () => void;
}

const REPRESENTATIVE_ID = 11;
const userRoles = [
  CustomerRole.ACCOUNTANT,
  CustomerRole.ADMINISTRATOR,
  CustomerRole.REPRESENTATIVE,
];

export const ExistingUserForm: React.FC<Props> = React.memo(({ userId, companyId, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();
  const uploadProps = useUpload();
  const queryClient = useQueryClient();

  const { user } = React.useContext(UserContext);
  const { id } = useParams();
  const [isRepresentative, setIsRepresentative] = React.useState(false);

  // Fetch user's roles
  const rolesList = useQuery(['nomenclature', 'roles'], () => nomenclature.getRoles(), {
    select: (roles) => roles.filter((role) => userRoles.includes(role.text as CustomerRole)),
  });

  // Fetch company user
  const companyUser = useQuery(
    ['company-user', (companyId || id || user?.company?.id) as number, userId],
    company.getUser,
    {
      enabled: !!userId,
    },
  );

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  // Fill form initial values on edit users
  React.useEffect(() => {
    if (!!userId && companyUser && companyUser.data) {
      const { roles = [], attachments = [] } = companyUser.data;

      // Finds roles id by their name
      const rolesIds = (roles as Role[]).map((r) => r.id);

      // Check if is representative user
      setIsRepresentative(rolesIds.includes(REPRESENTATIVE_ID));

      // Map attachments to form
      const attachmentsObjects = {};
      attachments.forEach((attachment) => (attachmentsObjects[attachment.type] = [attachment]));

      form.setFieldsValue({
        user_id: companyUser.data.id,
        roles_id: rolesIds || [],
        attachments_id: attachmentsObjects,
      });
    }
  }, [userId, companyUser, form]);

  const patchUser = useMutation(
    ({ data, userId }: Properties) => company.patchUser(user?.company?.id as number, userId, data),
    {
      onError: (err) => {
        validateResponse.bind(null, form, err);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('company-users');
        handleClose();
        notify.success({ title: t('company_users'), description: t('success_data_change') });
      },
    },
  );

  // Handle submit for select existing user
  const handleSubmit = (data: Properties) => {
    const attachments = data?.attachments_id
      ? data?.attachments_id[AttachmentType.POWER_OF_ATTORNEY]
      : [];

    patchUser.mutate({
      data: {
        ...data,
        attachments_id: attachments.map(({ id }) => id),
      },
      userId: data.user_id,
    });
  };

  const handleValuesChange = (field) => {
    if (hasOwnProperty(field, 'roles_id')) {
      setIsRepresentative(field.roles_id.includes(REPRESENTATIVE_ID));
    }
  };

  return (
    <Form
      form={form}
      type="horizontal"
      onFinish={handleSubmit}
      onValuesChange={handleValuesChange}
      labelOptions={{ className: 'text-right', col: { size: 3 } }}
    >
      <Form.Field name="user_id" label={t('user')} rules={[{ required: true }]}>
        <SmartSelect
          api={company.getUsers}
          filters={{ companyId: user?.company?.id }}
          transform={(results) =>
            results.map(({ id, first_name, last_name, email }) => ({
              value: id,
              text:
                ((first_name || last_name) &&
                  `${first_name} ${last_name} ${email ? `(${email})` : ``}`) ||
                '---',
            }))
          }
          enabled={!!user?.company?.id}
        />
      </Form.Field>

      <Form.Field name="roles_id" label={t('function')} rules={[{ required: true }]}>
        <SmartSelect
          mode="multiple"
          loading={rolesList.isLoading}
          options={rolesList.data?.map(({ value, text }) => ({ value, text: t(text as string) }))}
        />
      </Form.Field>

      {isRepresentative && (
        <Form.Field
          name={['attachments_id', AttachmentType.POWER_OF_ATTORNEY]}
          label={t('attorney_power')}
          rules={[{ required: true }]}
        >
          <Upload data={{ type: AttachmentType.POWER_OF_ATTORNEY }} {...uploadProps}>
            <Button>{t('upload')}</Button>
          </Upload>
        </Form.Field>
      )}

      <Modal.Footer style={{ margin: '0 -20px -30px' }}>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Form>
  );
});
