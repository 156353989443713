import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table } from 'ebs-design';
import { formatNumber } from 'utils';
import { Column } from 'types';
import { BacklogsChartItem } from '../interfaces';

export const PortfoliosTable: React.FC<{ data?: BacklogsChartItem[] }> = ({ data }) => {
  const { t } = useIntl();

  const columns: Column<BacklogsChartItem>[] = React.useMemo(
    () => [
      {
        title: t('month'),
        dataIndex: 'name',
      },
      {
        title: t('managed_portfolio'),
        dataIndex: 'amount',
        render: (value) => formatNumber(value),
      },
      {
        title: t('total_nr_of_invoices'),
        dataIndex: 'total',
        render: (value) => formatNumber(value, '', 0),
      },
      {
        title: t('portfolio_profitability'),
        dataIndex: 'average',
        render: (value) => `${formatNumber(value)}%`,
      },
    ],
    [t],
  );

  return <Table data={data} columns={columns} emptyCell="---" />;
};
