import * as React from 'react';
import {
  Modal,
  Space,
  Button,
  useForm,
  Form,
  Select,
  useNotify,
  dateTimeFormat,
  Row,
  Col,
} from 'ebs-design';
import { ModalProps } from 'ebs-design/dist/components/organisms/Modal/Modal';
import { useIntl } from 'estafette-intl';
import { useMutation } from 'react-query';

import { CertifiedType } from 'types';
import { extractResponseProps } from 'utils';
import { externals } from 'api';
import { formatDate } from 'libs';

export const ValidateCertifiedModal = ({ ...props }: ModalProps) => {
  const { t } = useIntl();
  const [form] = useForm();
  const notify = useNotify();

  const commonMutationField = {
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  };

  const mutationCer = useMutation(externals.validateCer, commonMutationField);
  const mutationPfx = useMutation(externals.validatePfx, commonMutationField);

  const handleSubmit = (values) => {
    if (values.certified_type === CertifiedType.CER) {
      mutationCer.reset();
      mutationCer.mutate();
    } else {
      mutationPfx.reset();
      mutationPfx.mutate();
    }
  };

  return (
    <Modal size="small" header={t('check_validation')} {...props}>
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Content>
          <Form.Field
            rules={[{ required: true }]}
            label={t('certified_type')}
            name="certified_type"
            initialValue={CertifiedType.CER}
          >
            <Select
              options={Object.values(CertifiedType).map((value) => ({
                value,
                text: value.toUpperCase(),
              }))}
            />
          </Form.Field>

          <Form.Field dependencies={['certified_type']}>
            {(_, __, form) => {
              const certifiedType = form.getFieldValue('certified_type') as CertifiedType;
              const data =
                certifiedType === CertifiedType.CER ? mutationCer.data : mutationPfx.data;

              return (
                <Row>
                  {data && (
                    <Col>
                      <div>{t('valid')}:</div>
                      {data?.is_valid ? t('yes') : t('no')}
                    </Col>
                  )}
                  {data?.valid_from && (
                    <Col>
                      <div>{t('from')}:</div>
                      {formatDate(data.valid_from, dateTimeFormat)}
                    </Col>
                  )}
                  {data?.valid_to && (
                    <Col>
                      <div>{t('to')}:</div>
                      {formatDate(data.valid_to, dateTimeFormat)}
                    </Col>
                  )}
                </Row>
              );
            }}
          </Form.Field>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={props.onClose}>{t('cancel')}</Button>
            <Button type="primary" submit loading={mutationCer.isLoading || mutationPfx.isLoading}>
              {t('verification')}
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
