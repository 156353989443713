export const date = {
  month1: {
    en: `January`,
    ro: `Ianuarie`,
    ru: `Январь`,
  },
  month2: {
    en: `February`,
    ro: `Februarie`,
    ru: `Февраль`,
  },
  month3: {
    en: `March`,
    ro: `Martie`,
    ru: `Март`,
  },
  month4: {
    en: `April`,
    ro: `Aprilie`,
    ru: `Апрель`,
  },
  month5: {
    en: `May`,
    ro: `Mai`,
    ru: `Май`,
  },
  month6: {
    en: `June`,
    ro: `Iunie`,
    ru: `Июнь`,
  },
  month7: {
    en: `July`,
    ro: `Iulie`,
    ru: `Июль`,
  },
  month8: {
    en: `August`,
    ro: `August`,
    ru: `Август`,
  },
  month9: {
    en: `September`,
    ro: `Septembrie`,
    ru: `Сентябрь`,
  },
  month10: {
    en: `October`,
    ro: `Octombrie`,
    ru: `Октябрь`,
  },
  month11: {
    en: `November`,
    ro: `Noiembrie`,
    ru: `Ноябрь`,
  },
  month12: {
    en: `December`,
    ro: `Decembrie`,
    ru: `Декабрь`,
  },
  day: {
    en: 'Day',
    ro: 'Zi',
    ru: 'День',
  },
  the_day: {
    en: 'Day',
    ro: 'Ziua',
    ru: 'День',
  },
  week: {
    en: 'Week',
    ro: 'Săptămână',
    ru: 'Неделю',
  },
  the_week: {
    en: 'Week',
    ro: 'Săptămâna',
    ru: 'Неделя',
  },
  month: {
    en: 'Month',
    ro: 'Lună',
    ru: 'Месяц',
  },
  the_month: {
    en: 'Month',
    ro: 'Luna',
    ru: 'Месяц',
  },
  year: {
    en: 'Year',
    ro: 'An',
    ru: 'Год',
  },
  the_year: {
    en: 'Year',
    ro: 'Anul',
    ru: 'Год',
  },
  daily: {
    en: 'Daily',
    ro: 'Zilnic',
    ru: 'Повседневная',
  },
  weekly: {
    en: 'Weekly',
    ro: 'Săptămânal',
    ru: 'Еженедельно',
  },
  monthly: {
    en: 'Monthly',
    ro: 'Lunar',
    ru: 'Ежемесячно',
  },
  quarterly: {
    en: 'Quarterly',
    ro: 'Trimestrial',
    ru: 'Ежеквартальный',
  },
  semester: {
    en: 'Semester',
    ro: 'Semestrial',
    ru: 'Семестр',
  },
  yearly: {
    en: 'Yearly',
    ro: 'Anual',
    ru: 'Ежегодно',
  },
};
