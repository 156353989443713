import { CustomerRole, SystemRole } from 'types';

export default {
  read: {
    menu: {
      users: [SystemRole.SY_ADMIN],
      alerts: [...Object.keys(CustomerRole)],
      notifications: [SystemRole.SY_ADMIN],
      penalities: [SystemRole.SY_ADMIN],
      classifiers: [SystemRole.SY_ADMIN],
      committee: [SystemRole.SY_ADMIN],
      contracts: [SystemRole.SY_ADMIN],
      rates: [SystemRole.SY_ADMIN],
      contractsNumber: [SystemRole.SY_ADMIN],
    },
    profileMenu: {
      editCompany: [CustomerRole.ADMINISTRATOR, CustomerRole.RESPONSIBLE],
      inviteUser: [CustomerRole.ADMINISTRATOR, CustomerRole.RESPONSIBLE],
      addUser: [SystemRole.SY_ADMIN],
    },
  },
};
