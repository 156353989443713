import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { AxiosError } from 'axios';
import {
  Modal,
  Button,
  useForm,
  Form,
  Input,
  Space,
  DatePicker,
  Icon,
  Row,
  Col,
  Loader,
  Collapse,
} from 'ebs-design';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { invoices } from 'api';
import { PaymentOrder, PaymentOrderUpdate } from 'types';
import { getAPIDateFormat, formatDateTime, dateTimePickerFormat, toISO } from 'libs';
import { InputNumericForm } from 'components';

interface Props {
  invoiceId: number;
  visible: boolean;
  onClose: () => void;
}

const fieldsToFormat = ['payed', 'balance', 'commission', 'guarantee', 'interest', 'tax'];

export const EditPaymentsModal: React.FC<Props> = ({ visible = false, invoiceId, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  // Get invoice's payments orders list
  const { data, isLoading } = useQuery(
    ['invoice-payments', invoiceId],
    () => invoices.getPaymentsOrdersList(invoiceId),
    {
      enabled: !!invoiceId,
      cacheTime: 0,
      staleTime: 0,
      select: (data) => ({
        ...data,
        results: data.results.map((payment) => {
          for (const [key, value] of Object.entries(payment)) {
            if (fieldsToFormat.includes(key)) {
              payment[key] = Number(value).toFixed(2);
            }
          }

          return payment;
        }),
      }),
    },
  );

  const updatePaymentsOrders = useMutation(
    (data: PaymentOrderUpdate) => invoices.patchPaymentsOrders(invoiceId, data),
    {
      onError: (err: AxiosError) => {
        const errors = err?.response?.data?.payments_orders || [];
        const fieldsErrors = errors.map((error, idx) => {
          return {
            name: [idx, ...Object.keys(error)],
            errors: Object.values(error),
          };
        });

        form.setFields(fieldsErrors);
      },
      onSuccess: () => {
        queryClient.invalidateQueries('invoices').then(() => handleClose());
      },
    },
  );

  const handleSubmit = (data: { [key: number]: PaymentOrder }) => {
    updatePaymentsOrders.mutate({
      id: invoiceId,
      payments_orders: Object.values(data).map((item) => ({
        ...item,
        date: toISO(getAPIDateFormat(item.date)),
      })),
    });
  };

  return (
    <Modal open={visible} onClose={handleClose} title={t('edit_payments')}>
      <Modal.Content className="p-0" style={{ maxHeight: '50vh', overflow: 'auto' }}>
        <Loader loading={isLoading}>
          <Form form={form} onFinish={handleSubmit}>
            <Collapse.Group className="collapse-no-border">
              {data && data.results.length ? (
                data.results.map((item, idx) => (
                  <Collapse key={item.id} collapsed={idx !== 0} size="small">
                    <Collapse.Header>
                      <span className="fw-600">{t('payment_order')}:</span> {item.number}
                    </Collapse.Header>
                    <Collapse.Body>
                      <Row className="mb-10">
                        <Col size={4}>
                          <Form.Field
                            label=""
                            name={[idx, 'id']}
                            initialValue={item?.id}
                            style={{ display: 'none' }}
                          />
                          <Form.Field
                            name={[idx, 'number']}
                            label={t('payment_order')}
                            initialValue={item.number}
                            rules={[{ required: true, message: t('required_field') }]}
                          >
                            <Input size="small" />
                          </Form.Field>
                        </Col>
                        <Col size={4}>
                          <Form.Field
                            name={[idx, 'date']}
                            label={t('date')}
                            initialValue={formatDateTime(item.date)}
                          >
                            <DatePicker
                              showTimeSelect
                              dateFormat={dateTimePickerFormat}
                              size="small"
                            />
                          </Form.Field>
                        </Col>
                        <Col size={4}>
                          <InputNumericForm
                            name={[idx, 'payed']}
                            label={`${t('invoice_financing')} (${t('payment_made')})`}
                            initialValue={item.payed}
                            rules={[{ required: true, message: t('required_field') }]}
                            inputProps={{ size: 'small' }}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-10">
                        <Col size={4}>
                          <InputNumericForm
                            name={[idx, 'balance']}
                            label={t('invoice_payment')}
                            initialValue={item.balance}
                            inputProps={{ size: 'small' }}
                          />
                        </Col>
                        <Col size={4}>
                          <InputNumericForm
                            name={[idx, 'commission']}
                            label={t('commission')}
                            initialValue={item.commission}
                            inputProps={{ size: 'small' }}
                          />
                        </Col>
                        <Col size={4}>
                          <InputNumericForm
                            name={[idx, 'guarantee']}
                            label={t('guarantee')}
                            initialValue={item.guarantee}
                            inputProps={{ size: 'small' }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col size={4}>
                          <InputNumericForm
                            name={[idx, 'interest']}
                            label={t('penalties')}
                            initialValue={item.interest}
                            inputProps={{ size: 'small' }}
                          />
                        </Col>
                        <Col size={4}>
                          <InputNumericForm
                            name={[idx, 'outstanding']}
                            label={t('outstanding')}
                            initialValue={item.outstanding}
                            inputProps={{ size: 'small' }}
                          />
                        </Col>
                        <Col size={4}>
                          <InputNumericForm
                            name={[idx, 'tax']}
                            label={t('taxes_paid')}
                            initialValue={item.tax}
                            inputProps={{ size: 'small' }}
                          />
                        </Col>
                      </Row>
                    </Collapse.Body>
                  </Collapse>
                ))
              ) : (
                <div style={{ textAlign: 'center', padding: 40 }}>
                  <h4>{t('no_data')}</h4>
                </div>
              )}
            </Collapse.Group>
          </Form>
        </Loader>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            loading={updatePaymentsOrders.isLoading}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
