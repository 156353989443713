import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Modal,
  Button,
  Icon,
  Space,
  Form,
  Input,
  useForm,
  Upload,
  useNotify,
  InputPhone,
} from 'ebs-design';
import { SmartSelect, UserSearch } from 'components';
import { UserContext } from 'contexts';
import { tenancy, company, nomenclature } from 'api';
import { Properties, AttachmentType, Role, CustomerRole, Attachment } from 'types';
import { useUpload } from 'hooks';
import {
  validateResponse,
  hasOwnProperty,
  extractResponseProps,
  flatten,
  getDeepKeys,
} from 'utils';

interface Props {
  tenancyFilters: Properties;
  userId?: number;
  onClose: () => void;
  onSelectUser: (value: number) => void;
  onChangeTenancyFilters: (
    patch: Partial<Properties> | ((prevState: Properties) => Partial<Properties>),
  ) => void;
}

const REPRESENTATIVE_ID = 11;
const userRoles = [
  CustomerRole.CHIEF_ACCOUNTANT,
  CustomerRole.ADMINISTRATOR,
  CustomerRole.REPRESENTATIVE,
];

export const NewUserForm: React.FC<Props> = React.memo(
  ({ tenancyFilters, userId, onClose, onSelectUser, onChangeTenancyFilters }) => {
    const { t } = useIntl();
    const [form] = useForm();
    const notify = useNotify();
    const uploadProps = useUpload();
    const queryClient = useQueryClient();

    const { user } = React.useContext(UserContext);
    const [isRepresentative, setIsRepresentative] = React.useState(false);
    const [canFill, setCanFill] = React.useState(false);

    const enabledTenancy = React.useMemo(
      () => !!tenancyFilters.idnp && tenancyFilters.idnp.length > 12,
      [tenancyFilters],
    );

    const { data: tenancyData, isLoading: isLoadingTenancy } = useQuery(
      ['tenancy-companies-list', tenancyFilters],
      () => tenancy.getUserByIDNP(tenancyFilters.idnp),
      {
        retry: 0,
        enabled: enabledTenancy,
      },
    );

    // Fetch user's roles
    const rolesList = useQuery(['nomenclature', 'roles'], () => nomenclature.getRoles(), {
      select: (roles) => roles.filter((role) => userRoles.includes(role.text as CustomerRole)),
    });

    // Fetch company user
    const { data: companyUser } = useQuery(
      ['company-user', user?.company?.id as number, userId],
      company.getUser,
      {
        enabled: !!userId,
      },
    );

    const handleClose = () => {
      form.resetFields();
      onClose();
    };

    // Fill form initial values on edit users
    React.useEffect(() => {
      if (!!userId && companyUser) {
        const { roles = [], attachments = [] } = companyUser;

        // Finds roles id by their name
        const rolesIds = (roles as Role[]).map((r) => r.id);

        // Check if is representative user
        setIsRepresentative(rolesIds.includes(REPRESENTATIVE_ID));

        // Map attachments to form
        const attachmentsObjects = {};
        attachments.forEach((attachment) => (attachmentsObjects[attachment.type] = [attachment]));

        form.setFieldsValue({
          ...companyUser,
          roles_id: rolesIds || [],
          attachments_id: attachmentsObjects,
          ...(companyUser.passport?.attachment && {
            passport: { attachment_id: [companyUser.passport?.attachment] },
          }),
        });
      }
      // eslint-disable-next-line
    }, [userId, companyUser]);

    const addUser = useMutation(
      (data: Properties) => company.addUser(user?.company?.id as number, data),
      {
        onError: (err) => {
          validateResponse.bind(null, form, err);
          extractResponseProps(err, (title, description) =>
            notify.error({ title: t(title), description: t(description) }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries('company-users');
          handleClose();
          notify.success({ title: t('company_users'), description: t('success_data_save') });
        },
      },
    );

    const updateUser = useMutation(
      ({ data, userId }: Properties) =>
        company.updateUser(user?.company?.id as number, userId, data),
      {
        onError: (err) => {
          validateResponse.bind(null, form, err);
          extractResponseProps(err, (title, description) =>
            notify.error({ title: t(title), description: t(description) }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries('company-users');
          handleClose();
          notify.success({ title: t('company_users'), description: t('success_data_change') });
        },
      },
    );

    const handleAddSubmit = (data: Properties) => {
      const attachments: Attachment[] = data?.attachments_id
        ? Object.values(data?.attachments_id)
        : [];

      const attachmentsIds = attachments?.map(
        (attachment: any) => attachment?.map((item) => item.id) || [],
      );

      const updatedData = {
        ...data,
        attachments_id: flatten(attachmentsIds),
        passport: {
          attachments_id: data.passport?.attachments_id?.map(({ id }) => id),
        },
      };

      if (userId) {
        updateUser.mutate({
          data: updatedData,
          userId,
        });
      } else {
        addUser.mutate(updatedData);
      }
    };

    const handleValuesChange = (field) => {
      const fieldName: string[] = getDeepKeys(field);
      const fieldValue = form.getFieldValue(fieldName);

      if (hasOwnProperty(field, 'roles_id')) {
        setIsRepresentative(field.roles_id.includes(REPRESENTATIVE_ID));
      } else if (hasOwnProperty(field, 'idnp')) {
        onChangeTenancyFilters({ idnp: fieldValue });
        setCanFill(false);
      }
    };

    return (
      <>
        <UserSearch
          open={enabledTenancy && !isLoadingTenancy && !canFill}
          data={tenancyData}
          onChange={onSelectUser}
          onClose={() => onChangeTenancyFilters({ idnp: undefined })}
          onCreateNew={() => setCanFill(true)}
          loading={true}
        />

        <Form
          form={form}
          type="horizontal"
          onFinish={handleAddSubmit}
          onValuesChange={handleValuesChange}
          labelOptions={{ className: 'text-right', col: { size: 3 } }}
        >
          <Form.Field name="idnp" label={t('idnp')} rules={[{ required: true, len: 13 }]}>
            <Input type="number" loading={isLoadingTenancy} />
          </Form.Field>

          <Form.Field name="first_name" label={t('first_name')} rules={[{ required: true }]}>
            <Input disabled={!canFill} />
          </Form.Field>

          <Form.Field name="last_name" label={t('last_name')} rules={[{ required: true }]}>
            <Input disabled={!canFill} />
          </Form.Field>

          <Form.Field name="roles_id" label={t('function')} rules={[{ required: true }]}>
            <SmartSelect
              mode="multiple"
              loading={rolesList.isLoading}
              options={rolesList.data?.map(({ value, text }) => ({
                value,
                text: t(text as string),
              }))}
              disabled={!canFill}
            />
          </Form.Field>

          <Form.Field name="phone" label={t('phone')} rules={[{ required: true }]}>
            <InputPhone country="md" disabled={!canFill} />
          </Form.Field>

          <Form.Field name="email" label={t('email')} rules={[{ required: true }]}>
            <Input type="email" disabled={!canFill} />
          </Form.Field>

          <Form.Field
            name={['passport', 'attachments_id']}
            label={t('identity_card')}
            rules={[{ required: true }]}
          >
            {(control) => (
              <Upload
                data={{ type: AttachmentType.BULLETIN }}
                multiple
                disabled={!canFill || control?.value?.length >= 2}
                {...uploadProps}
                {...control}
              >
                <Button disabled={!canFill || control?.value?.length >= 2}>{t('upload')}</Button>
              </Upload>
            )}
          </Form.Field>

          <Form.Field
            name={['attachments_id', AttachmentType.POWER_OF_ATTORNEY]}
            label={t('attorney_power')}
            rules={[{ required: isRepresentative }]}
            style={{ display: isRepresentative ? 'block' : 'none' }}
          >
            <Upload data={{ type: AttachmentType.POWER_OF_ATTORNEY }} {...uploadProps}>
              <Button disabled={!canFill}>{t('upload')}</Button>
            </Upload>
          </Form.Field>

          <Modal.Footer style={{ margin: '0 -20px -30px' }}>
            <Space justify="space-between">
              <Button onClick={handleClose}>{t('cancel')}</Button>

              <Button
                type="primary"
                prefix={<Icon type="check" model="bold" />}
                onClick={() => form.submit()}
              >
                {t('save')}
              </Button>
            </Space>
          </Modal.Footer>
        </Form>
      </>
    );
  },
);
