import { axios, cancelTokenHandler } from 'libs';
import {
  Results,
  Contract,
  Comment,
  Properties,
  ContractSignerProcure,
  ContractNumber,
  ContractNumberList,
  ContractUpload,
} from 'types';
import { transformResponse, formatNumber } from 'utils';

export const contracts = {
  get: async (id: string) => {
    const { data } = await axios.get<Contract>(`/contracts/${id}/`, {
      transformResponse: (response) =>
        transformResponse(response, {
          properties: [
            'bonus_percentage',
            'bonus_percentage_amount',
            'bonus_percentage_amount_sum',
          ],
          wrapper: (value) => formatNumber(value),
        }),
      cancelToken: cancelToken(contracts.get.name),
    });

    return data;
  },

  postContract: async (data: ContractUpload) => {
    return await axios.post('/contracts/', data, {
      cancelToken: cancelToken(contracts.postContract.name),
    });
  },

  add: async ({ id, ...data }: Properties) => {
    return await axios.put(`/contracts/${id}/`, data, {
      cancelToken: cancelToken(contracts.add.name),
    });
  },
  save: async ({ id, ...data }: Properties) => {
    return await axios.patch(`/contracts/${id}/`, data, {
      cancelToken: cancelToken(contracts.save.name),
    });
  },
  getComments: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get<Results<Comment>>(`contracts/${id}/comments/list/`, {
      params,
      cancelToken: cancelToken(contracts.getComments.name),
    });

    return data;
  },
  addComment: async ({ id, ...params }: Properties) => {
    const { data } = await axios.post<Comment>(`/contracts/${id}/comments/`, params, {
      cancelToken: cancelToken(contracts.addComment.name),
    });

    return data;
  },
  getList: async (params: Properties) => {
    const { data } = await axios.get<Results<Contract>>(`/contracts/list/`, {
      params,
      cancelToken: cancelToken(contracts.getList.name),
    });

    return data;
  },
  getProcureData: async (id: number) => {
    const { data } = await axios.get<ContractSignerProcure>(`/contracts/${id}/procure/`);
    return data;
  },

  getNumberList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<ContractNumberList>>(`/contracts/list/numbers/`, {
      params: queryKey[1],
      cancelToken: cancelToken(contracts.getNumberList.name),
    });

    return data;
  },

  getLastNumber: async (params: Properties) => {
    const { data } = await axios.get<Results<ContractNumber>>(`/contracts/number/list/`, {
      params,
      cancelToken: cancelToken(contracts.getLastNumber.name),
    });

    return data;
  },

  addLastNumber: async ({ ...params }: Properties) => {
    const { data } = await axios.post<Comment>(`/contracts/number/`, params, {
      cancelToken: cancelToken(contracts.addLastNumber.name),
    });

    return data;
  },

  patchNumber: async ({ id, ...data }: Properties) => {
    return await axios.patch(`/contracts/number/${id}/`, data, {
      cancelToken: cancelToken(contracts.patchNumber.name),
    });
  },

  patchConfirmContract: async ({ id, ...data }: Properties) => {
    return await axios.patch(`/contracts/${id}/confirm/`, data, {
      cancelToken: cancelToken(contracts.patchConfirmContract.name),
    });
  },

  patchApproveContract: async ({ id, ...data }: Properties) => {
    return await axios.patch(`/contracts/${id}/approve/`, data, {
      cancelToken: cancelToken(contracts.patchApproveContract.name),
    });
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(contracts);
