import * as React from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Container, Loader, Tabs } from 'ebs-design';
import { Layout } from 'components';
import { usePermissions } from 'hooks';
import { company } from 'api';
import { UserContext } from 'contexts';
import { CompanyType, Permissions } from 'types';

import { Payments } from '../payments';
import { FactoringPortfolioReport } from '../FactoringPortfolioReport';
import { InvoicesPaymentsReport } from '../InvoicesPaymentsReport';
import { InvoicesSales } from '../tables/InvoicesSales';

export const Reports = () => {
  const { t } = useIntl();
  const { user } = React.useContext(UserContext);
  const can = usePermissions(Permissions.REPORTS);

  const [activeTab, setActiveTab] = React.useState('invoices');

  const companyId = user?.company?.id;

  const {
    data: companyData,
    isLoading: isLoadingCompanyData,
    isFetching,
  } = useQuery(['company', companyId], () => company.get(companyId), {
    enabled: !!companyId,
    onSuccess: (data) => setActiveTab(data?.types?.[0]),
  });

  const activeInvoiceType = companyData?.types.includes(activeTab as CompanyType)
    ? activeTab
    : 'invoices';

  return (
    <Layout>
      <Container>
        <Loader loading={isLoadingCompanyData || isFetching}>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} className="company-tab">
            {can.read?.invoices && companyData ? (
              companyData?.types?.map((type) => (
                <Tabs.Tab
                  key={type}
                  tabKey={type}
                  label={`${t('invoices')} - ${t(type.toLowerCase())}`}
                />
              ))
            ) : (
              <Tabs.Tab key="invoices" tabKey="invoices" label={t('invoices')} />
            )}

            {can.read?.sales && (
              <Tabs.Tab key="sales" tabKey="sales" label={t('sales_repayments')} />
            )}

            {can.read?.portfolio && (
              <Tabs.Tab key="portfolio" tabKey="portfolio" label={t('factoring_portfolio')} />
            )}
            {can.read?.register_of_payments && (
              <Tabs.Tab
                key="register_of_payments"
                tabKey="register_of_payments"
                label={t('register_of_payments')}
              />
            )}

            {can.read?.invoices && (
              <Tabs.Panel tabKey={activeInvoiceType}>
                <Payments
                  activeInvoiceType={activeInvoiceType}
                  activeInvoiceId={companyData?.id}
                  isLoadingCompany={isLoadingCompanyData || isFetching}
                />
              </Tabs.Panel>
            )}

            {can.read?.sales && (
              <Tabs.Panel tabKey="sales">
                <InvoicesPaymentsReport />
              </Tabs.Panel>
            )}

            {can.read?.portfolio && (
              <Tabs.Panel tabKey="portfolio">
                <FactoringPortfolioReport />
              </Tabs.Panel>
            )}

            {can.read?.register_of_payments && (
              <Tabs.Panel tabKey="register_of_payments">
                <InvoicesSales />
              </Tabs.Panel>
            )}
          </Tabs>
        </Loader>
      </Container>
    </Layout>
  );
};
