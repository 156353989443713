import * as React from 'react';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Button, Card, Label, Space, Icon } from 'ebs-design';
import { LabelStatus } from 'ebs-design/dist/components/atoms/Label/Label';
import { Adherent, Edit, Eye } from 'resources';
import { usePermissions } from 'hooks';
import { UserContext } from 'contexts';
import {
  FullCompany,
  Permissions,
  ReportCompanyType,
  SystemRole,
  CustomerRole,
  Status,
  CompanyType,
} from 'types';
import { ReportKYCModal, ActionsCompany, SelectYearModal } from 'components';
import { reports } from 'api';
import { downloadFile, arrayContainsArray, getRoute } from 'utils';

import { DebtorDetails } from './DebtorDetails';
import { stringifyUrl } from 'libs';
import { routes } from 'routes';

interface Props {
  data: FullCompany;
  isEditCategory?: boolean;
}

export const DebtorProfile: React.FC<Props> = ({ data, isEditCategory }) => {
  const { t } = useIntl();
  const { userRoles } = React.useContext(UserContext);
  const can = usePermissions(Permissions.DEBTORS);

  // Download reports
  const download = useMutation(
    ({ type, year }: { type: ReportCompanyType; year?: number }) =>
      reports.downloadReports({ id: data.id, type }, { ...(year && { year }), current: true }),
    {
      onSuccess: ({ attachment }) => downloadFile(attachment.url, attachment.name),
    },
  );

  const hasActions = arrayContainsArray(
    [...Object.keys(CustomerRole), SystemRole.SY_COLLECTOR],
    userRoles,
  );

  return (
    <Card className="company-details__main">
      <Card.Header>
        <Space align="start" justify="space-between">
          <Space align="center">
            <div>
              <div className="company-details__icon">
                <Icon component={Adherent} />
              </div>
              <div className="company-details__title-box">
                <h2 className="company-details__title">{data?.title}</h2>
                <Label
                  status={data?.request ? (Status[data?.request.status] as LabelStatus) : 'warning'}
                  type="fill"
                  text={
                    data?.request
                      ? t(data?.request.status.toLowerCase())
                      : data?.status && t(data?.status.toLowerCase())
                  }
                  className="company-details__subtitle"
                />
              </div>
            </div>
          </Space>
          {can.edit?.debtor && (
            <Link to={stringifyUrl(getRoute(routes, 'DebtorForm', { id: data?.id }))}>
              <Button prefix={<Icon component={Edit} />}>{t('edit')}</Button>
            </Link>
          )}
        </Space>
      </Card.Header>
      <Card.Body className="p-0">
        <DebtorDetails data={data} isEditCategory={isEditCategory} />
      </Card.Body>
      <Card.Footer className={cn({ 'd-none': hasActions })}>
        <Space wrap justify="space-between">
          <Space>
            {can.read?.reports && (
              <SelectYearModal
                onSelected={(year: number) =>
                  download.mutate({ type: ReportCompanyType.BIC, year })
                }
              >
                <Button prefix={<Icon component={Eye} />}>{t('report_bns_bic')}</Button>
              </SelectYearModal>
            )}
            {can.read?.reports && (
              <Button
                onClick={() => download.mutate({ type: ReportCompanyType.INFODEBIT })}
                prefix={<Icon component={Eye} />}
              >
                {t('report_info_debit')}
              </Button>
            )}

            {can.read?.kyc && (
              <ReportKYCModal type={CompanyType.DEBTOR} additional_data={data.additional_data} />
            )}
          </Space>

          <ActionsCompany data={data} permissions={Permissions.DEBTORS} />
        </Space>
      </Card.Footer>
    </Card>
  );
};
