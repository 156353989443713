import * as React from 'react';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import cn from 'classnames';
import { Container, Row, Col, Label, Button, Input, Icon } from 'ebs-design';
import { company } from 'api';
import { Properties, CompanyType, SystemRole } from 'types';
import { useQueryParams } from 'hooks';
import { defaultFilters } from 'utils';
import { DatePicker, SmartSelect } from 'components';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  onSearch: (value: Properties) => void;
}

export const Filters: React.FC<Props> = ({ className, style, onSearch }) => {
  const { t } = useIntl();
  const params = useQueryParams();
  const [search, setSearch] = useSetState<Properties>({ ...defaultFilters, ...params });

  const { data } = useQuery(
    [CompanyType.ADHERENT, { types: CompanyType.ADHERENT.toLowerCase() }],
    company.getList,
  );

  const adherentOptions = React.useMemo(
    () =>
      (data?.results || []).map((adherent) => ({
        value: adherent.id,
        text: adherent.title,
      })),
    [data],
  );

  const companyRequestOptions = React.useMemo(
    () => [
      { value: SystemRole.SY_RISK_MANAGER.toLowerCase(), text: t(SystemRole.SY_RISK_MANAGER) },
      {
        value: SystemRole.SY_CREDIT_COMMITTEE.toLowerCase(),
        text: t(SystemRole.SY_CREDIT_COMMITTEE),
      },
    ],
    [t],
  );

  const onSearchHandler = React.useCallback(() => onSearch(search), [search, onSearch]);

  return (
    <div className={cn('pc-searchbar', className)} style={style}>
      <Container>
        <Row gy={3}>
          <Col size={12} lg={3}>
            <Label text={t('direct_search')} />
            <Input
              placeholder={t('search')}
              suffix={<Icon type="search" />}
              value={search.search}
              onChange={(search) => setSearch({ search })}
            />
          </Col>
          <Col size={4} sm={2} lg={2}>
            <Label text={t('invoice_number')} />
            <Input
              placeholder={t('invoice_number')}
              value={search.invoices_code}
              onChange={(invoices_code) => setSearch({ invoices_code })}
            />
          </Col>
          <Col size={4} sm={2} lg={2}>
            <Label text={t('adherent')} />
            {/* TODO: need to improve */}
            <SmartSelect
              options={adherentOptions}
              placeholder={t('select')}
              value={search.company_id && parseInt(search.company_id)}
              onChange={(company_id) => setSearch({ company_id })}
            />
          </Col>
          <Col size={4} sm={2} lg={2}>
            <Label text={t('request_stage')} />
            <SmartSelect
              placeholder={t('select')}
              options={companyRequestOptions}
              value={search.stage}
              onChange={(stage) => setSearch({ stage })}
            />
          </Col>
          <Col size={12} sm={6} lg={2}>
            <Label text="Period" />
            <DatePicker type="period" onChange={(period) => setSearch({ period })} />
          </Col>
          <Col size={12} sm={12} lg={1} className="align-flex-end">
            <Button type="dark" className="full-width" onClick={onSearchHandler}>
              {t('search')}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
