import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Row, Col, Card, Button, Space, useForm, Form, Checkbox, useNotify } from 'ebs-design';
import { UserContext } from 'contexts';
import { profile } from 'api';
import { ContactMode, Properties, User } from 'types';
import { extractResponseProps, validateResponse } from 'utils';

const query = 'userProfile';
const contactModes: string[] = [ContactMode.SMS, ContactMode.PHONE, ContactMode.EMAIL];

export const AlertsSettings: React.FC = () => {
  const { t } = useIntl();
  const [form] = useForm();
  const { user, logged } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const notify = useNotify();

  // Save settings
  const userSettings = useMutation<unknown, unknown, Properties>(
    (data) => profile.updateSettings(data),
    {
      onMutate: async (data) => {
        const prevData = queryClient.getQueryData(query) as User;

        queryClient.setQueryData(query, {
          ...prevData,
          settings: prevData.settings.map((setting) => {
            if (setting.code_name === data.code_name) {
              return { ...setting, ...data };
            }

            return setting;
          }),
        });

        return () => queryClient.setQueryData(query, prevData);
      },
      onError: (err, values, rollback: any) => {
        validateResponse(form, err, values);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
        rollback();
      },
    },
  );

  React.useEffect(() => {
    // Map settings value to the form
    if (logged) {
      const settings = {};

      (queryClient.getQueryData(query) as User).settings.forEach((setting) => {
        if (contactModes.includes(setting.code_name as string)) {
          settings[setting.code_name] = setting.state;
        }
      });

      form.resetFields();
      form.setFieldsValue(settings);
    }
  }, [queryClient, logged, form]);

  const handleFinish = React.useCallback(
    (values) => {
      const settings = user!.settings.filter((setting) => contactModes.includes(setting.code_name));

      settings.forEach((setting) => {
        const state = values[setting.code_name];

        if (state !== setting.state) {
          userSettings.mutate({
            code_name: setting.code_name,
            state,
          });
        }
      });

      notify.success({ title: t('alerts'), description: t('success_data_change') });
    },
    [user, userSettings, notify, t],
  );

  return (
    <div className="alerts-settings">
      <h3 className="page-title mt-5 mb-25">{t('alerts')}</h3>
      <Card>
        <Card.Body>
          <Row>
            <Col size={12} sm={9} md={8} lg={7} xl={6} xxl={5} className="py-20 px-30">
              <Form form={form} onFinish={handleFinish} type="horizontal">
                <h3 className="form-heading mb-20">{t('select_contact_mode')}</h3>

                <Form.Field label=" " name={ContactMode.SMS} valuePropName="checked">
                  <Checkbox text={t('contact_by_sms')} />
                </Form.Field>

                <Form.Field label=" " name={ContactMode.PHONE} valuePropName="checked">
                  <Checkbox text={t('contact_by_phone')} />
                </Form.Field>

                <Form.Field label=" " name={ContactMode.EMAIL} valuePropName="checked">
                  <Checkbox text={t('contact_by_email')} />
                </Form.Field>
              </Form>
            </Col>
            <Col size={12} sm={3} md={4} lg={5} xl={6} xxl={7} />
          </Row>
        </Card.Body>
        <Card.Footer>
          <Space justify="end">
            <Button onClick={() => form.submit()}>{t('save')}</Button>
          </Space>
        </Card.Footer>
      </Card>
    </div>
  );
};
