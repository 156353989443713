import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Table,
  Card,
  Space,
  InputSearch,
  Button,
  ButtonGroup,
  Switch,
  SortBy,
  Actions,
  Loader,
  Label,
  Icon,
  useNotify,
} from 'ebs-design';
import { company as companyAPI } from 'api';
import { useQueryParams, useQueryUpdate, usePermissions } from 'hooks';
import { Column, CompanyType, Permissions, Properties, RequestStatus, SystemRole } from 'types';
import {
  defaultFilters,
  defaultCompanyFilters,
  getSortOptions,
  getCellIconType,
  extractResponseProps,
  getRoute,
} from 'utils';
import { Layout, Pagination } from 'components';
import { UserContext } from 'contexts';

import { AdherentHistory } from '../AdherentHistory';
import { useAdherentColumns } from '../useAdherentColumns';
import { routes } from 'routes';

export const Adherents: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const notify = useNotify();
  const { user, userRoles } = React.useContext(UserContext);
  const { updateQuery } = useQueryUpdate();

  const queryClient = useQueryClient();
  const params = useQueryParams();
  const can = usePermissions(Permissions.ADHERENTS);

  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });

  const isCollector = React.useMemo(() => userRoles.includes(SystemRole.SY_COLLECTOR), [userRoles]);
  const isCreditOfficer = React.useMemo(
    () => userRoles.some((role) => [SystemRole.SY_CREDIT_OFFICER].includes(role as SystemRole)),
    [userRoles],
  );

  React.useEffect(() => {
    setFilters(() => ({
      ...(isCreditOfficer && { management_id: user?.id }),
      ...(isCollector && { sum_of_remained_invoices__gte: 1 }),
      page: 1,
    }));
  }, [isCreditOfficer, user, isCollector, setFilters]);

  React.useEffect(() => updateQuery(filters), [filters, updateQuery]);

  const { data, isLoading } = useQuery(
    ['companies', { ...filters, types: CompanyType.ADHERENT.toLowerCase() }],
    companyAPI.getList,
  );

  const company = useMutation(
    ({ companyId, data }: Properties) => companyAPI.update({ companyId, data }),
    {
      onError: (err, _, rollback: any) => {
        rollback();
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('companies');
        notify.success({ title: t('company'), description: t('success_data_save') });
      },
    },
  );

  const defaultColumns = useAdherentColumns();

  const columns: Column[] = React.useMemo(
    () => [
      ...defaultColumns,
      ...(can.read?.columns?.kyc
        ? [
            {
              title: t('kyc_approved'),
              className: 'text-center',
              render: ({ know_your_client_confirmed }) => {
                const iconType = getCellIconType(know_your_client_confirmed);

                return (
                  <Space justify="center">
                    <Label
                      status={iconType.status}
                      type="fill"
                      circle
                      icon={<Icon type={iconType.type} className="base-font-size" />}
                    />
                  </Space>
                );
              },
            },
          ]
        : []),
      ...(can?.perform?.blockAdherent
        ? [
            {
              title: t('block'),
              render: ({ id, state }) => (
                <Switch
                  onChange={() => company.mutate({ companyId: id, data: { state: !state } })}
                  checked={!state}
                />
              ),
            },
          ]
        : []),
      {
        title: null,
        action: true,
        render: ({ id }) => (
          <Actions>
            {can?.read?.history && (
              <Actions.Item onClick={() => setSelectedId(id)}>{t('history')}</Actions.Item>
            )}
            <Actions.Item onClick={() => push(getRoute(routes, 'AdherentDetails', { id }))}>
              {t('details')}
            </Actions.Item>
          </Actions>
        ),
      },
    ],
    [t, can, push, defaultColumns, company],
  );

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);
  const onChangeFilters = React.useCallback(
    (type?, value?) =>
      setFilters({ ...defaultCompanyFilters, page: 1, ...(type && { [type]: value }) }),
    [setFilters],
  );

  return (
    <Layout>
      <Container>
        <Space align="center" justify="space-between" className="mt-5 mb-20">
          <Space align="center">
            <h3 className="page-title">
              {t('adherents')} ({data?.count || 0})
            </h3>
            <InputSearch
              placeholder={t('search')}
              styleType="fill"
              value={filters.search}
              onSearch={(search) => setFilters({ search, page: 1 })}
              isClearable
            />
          </Space>
          <Space>
            {can.read.filterByStatus && (
              <ButtonGroup className="flex-nowrap">
                <Button
                  type={
                    !Object.keys(defaultCompanyFilters)
                      .map((item) => filters[item])
                      .filter((i) => i).length
                      ? 'primary'
                      : 'ghost'
                  }
                  onClick={() => onChangeFilters()}
                >
                  {t('all')}
                </Button>
                <Button
                  type={
                    [true, 'true'].includes(filters?.know_your_client_confirmed)
                      ? 'primary'
                      : 'ghost'
                  }
                  onClick={() => onChangeFilters('know_your_client_confirmed', true)}
                  prefix={
                    <Label
                      status="success"
                      type="fill"
                      circle
                      icon={<Icon type="check" model="bold" />}
                    />
                  }
                >
                  KYC
                </Button>
                <Button
                  type={
                    [false, 'false'].includes(filters?.know_your_client_confirmed)
                      ? 'primary'
                      : 'ghost'
                  }
                  onClick={() => onChangeFilters('know_your_client_confirmed', false)}
                  prefix={
                    <Label
                      status="danger"
                      type="fill"
                      circle
                      icon={<Icon type="info" className="base-font-size" />}
                    />
                  }
                >
                  KYC
                </Button>
                <Button
                  type={
                    filters?.request_status === RequestStatus.APPROVED.toLowerCase()
                      ? 'primary'
                      : 'ghost'
                  }
                  onClick={() =>
                    onChangeFilters('request_status', RequestStatus.APPROVED.toLowerCase())
                  }
                >
                  {t('approved')}
                </Button>
                <Button
                  type={
                    filters?.request_status === RequestStatus.DENIED.toLowerCase()
                      ? 'primary'
                      : 'ghost'
                  }
                  onClick={() =>
                    onChangeFilters('request_status', RequestStatus.DENIED.toLowerCase())
                  }
                >
                  {t('denied')}
                </Button>
                <Button
                  type={filters?.waiting_for_approval ? 'primary' : 'ghost'}
                  onClick={() => onChangeFilters('waiting_for_approval', true)}
                >
                  {t('waiting_approval')}
                </Button>
              </ButtonGroup>
            )}

            {can.read.filterByRequestStatus && (
              <ButtonGroup className="flex-nowrap">
                <Button
                  type={!filters?.status ? 'primary' : 'ghost'}
                  onClick={() => setFilters(() => ({ status: '', page: 1 }))}
                >
                  {t('all')}
                </Button>
                <Button
                  type={filters?.status === RequestStatus.REVIEW ? 'primary' : 'ghost'}
                  onClick={() => setFilters(() => ({ status: RequestStatus.REVIEW, page: 1 }))}
                >
                  {t('review')}
                </Button>
                <Button
                  type={filters?.status === RequestStatus.DENIED ? 'primary' : 'ghost'}
                  onClick={() => setFilters(() => ({ status: RequestStatus.DENIED, page: 1 }))}
                >
                  {t('denied')}
                </Button>
              </ButtonGroup>
            )}

            <SortBy
              options={sortOptions}
              value={filters?.ordering}
              onChange={(ordering) => setFilters({ ordering })}
            />
          </Space>
        </Space>
        <Card>
          <Card.Body className="p-0">
            <Loader loading={isLoading}>
              <Table className="table-no-border" columns={columns} data={data?.results} />
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Pagination data={data} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </Container>
      {selectedId && <AdherentHistory id={selectedId} onClose={() => setSelectedId(null)} />}
    </Layout>
  );
};
