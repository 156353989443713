import * as React from 'react';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Modal, Textarea, Button, Space, Icon } from 'ebs-design';
import { contracts } from 'api';

interface CommentModalProps {
  contractId: number;
  onChange: () => void;
}

export const CommentModal: React.FC<CommentModalProps> = ({ contractId, onChange, children }) => {
  const { t } = useIntl();
  const [visible, setVisible] = React.useState(false);
  const [comment, setComment] = React.useState('');

  const addComment = useMutation(contracts.addComment, {
    onSuccess: () => {
      setVisible(false);
      setComment('');
      onChange();
    },
  });

  const onToggleHandler = React.useCallback(() => setVisible((i) => !i), []);
  const onSave = React.useCallback(() => {
    addComment.mutate({
      id: contractId,
      comment,
    });
  }, [comment, contractId, addComment]);

  return (
    <>
      <Modal open={visible} size="small" onClose={onToggleHandler} header={t('add_comment')}>
        <Modal.Content>
          <Textarea value={comment} onChange={setComment} />
        </Modal.Content>
        <Modal.Footer>
          <Space justify="end">
            <Button type="primary" prefix={<Icon type="check" model="bold" />} onClick={onSave}>
              {t('save')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
      <div onClick={onToggleHandler}>{children}</div>
    </>
  );
};
