import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { Tabs, Loader, Button, Icon, Row, Col } from 'ebs-design';
import { useRoles } from 'hooks';
import { Plus } from 'resources';

import { MyProfileLayout } from './MyProfileLayout';
import { stringifyUrl } from 'libs';
import { getRoute } from 'utils';
import { routes } from 'routes';

const keyUsers = 'USERS';

export const MyUsersLayout: React.FC = ({ children }) => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { role } = useParams();
  const { users, customers, isLoading } = useRoles();

  React.useEffect(() => {
    if (!role && customers.length) {
      push(
        getRoute(routes, 'MyUsersRole', {
          role: keyUsers,
        }),
      );
    }
  }, [role, customers, push]);

  return (
    <MyProfileLayout>
      <Row className="flex-nowrap justify-space-between align-items-end">
        <Col size={role !== keyUsers ? 9 : 12}>
          <Tabs activeTab={role} className="company-tab">
            {!isLoading ? (
              <Tabs.Tab
                key={keyUsers}
                tabKey={keyUsers}
                label={
                  <Link to={stringifyUrl(getRoute(routes, 'MyUsersRole', { role: keyUsers }))}>
                    {t(keyUsers)}
                  </Link>
                }
              />
            ) : null}
            {isLoading ? (
              <Tabs.Tab
                tabKey="LoadingRoles"
                label={<Loader.Inline>{t('loading')} ...</Loader.Inline>}
                disabled
              />
            ) : (
              users.map(({ value, text }) => (
                <Tabs.Tab
                  key={value}
                  tabKey={text as string}
                  label={
                    <Link to={stringifyUrl(getRoute(routes, 'MyUsersRole', { role: text }))}>
                      {t(text as string)}
                    </Link>
                  }
                />
              ))
            )}
          </Tabs>
        </Col>

        {role !== keyUsers && (
          <>
            <Col size="auto">
              <Button
                type="primary"
                prefix={<Icon component={Plus} />}
                className="mr-10"
                onClick={() =>
                  push(getRoute(routes, 'MyUsersRole', { role, query: { invite: true } }))
                }
              >
                {t('invite_new_user')}
              </Button>
              <Button
                type="primary"
                prefix={<Icon component={Plus} />}
                onClick={() =>
                  push(getRoute(routes, 'MyUsersRole', { role, query: { add: true } }))
                }
              >
                {t('add_new')}
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Loader loading={isLoading}>{children}</Loader>
    </MyProfileLayout>
  );
};
