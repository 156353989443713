import * as React from 'react';
import cn from 'classnames';
import { useIntl } from 'estafette-intl';
import { AvatarInline, Select, Modal, Space, Button, Loader } from 'ebs-design';
import { User, Role } from 'types';

interface Props {
  open: boolean;
  data?: User<Role[]>;
  onChange: (value: number) => void;
  onCreateNew: () => void;
  onClose: () => void;
  loading: boolean;
}

export const UserSearch: React.FC<Props> = ({
  open,
  data,
  onChange,
  onCreateNew,
  onClose,
  loading,
}) => {
  const { t } = useIntl();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const onChangeHandler = React.useCallback(
    (id) => {
      onChange(id);
    },
    [onChange],
  );

  const options = React.useMemo(
    () =>
      data
        ? [
            {
              value: data.id,
              text: (
                <div className="ebs-avatar__container">
                  <AvatarInline
                    alt={`${data.first_name} ${data.last_name}`}
                    description={data.email}
                  />
                  {loading && (
                    <div className="spinner-container">
                      <Loader.Spinner size="regular" />
                    </div>
                  )}
                </div>
              ),
            },
          ]
        : [],
    [data, loading],
  );

  return (
    <Modal
      open={isOpen}
      size="small"
      header={options?.length ? t('userAlreadyExists') : undefined}
      onClose={options?.length ? onClose : undefined}
      closeOnClickOutside={false}
    >
      <Modal.Content className={cn('pc-user-search', 'p-0')}>
        {options?.length ? (
          <Select options={options} optionsMode="box" onChange={onChangeHandler} />
        ) : (
          <Space direction="vertical" className="p-16">
            {t('userWithIDNPWasNotFound')}
          </Space>
        )}
      </Modal.Content>
      {!options?.length && (
        <Modal.Footer>
          <Space justify="space-between">
            <Button type="ghost" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button type="fill" onClick={onCreateNew}>
              {t('createNew')}
            </Button>
          </Space>
        </Modal.Footer>
      )}
    </Modal>
  );
};
