import { ContractAttachment, AttachmentType } from './';

export enum ProcureAttachmentType {
  LEAD = 'LEAD',
  PROCURE = 'PROCURE',
}

export type AttachmentData = {
  invoices_ids: string[];
};

export interface ProcureAttachment extends Omit<ContractAttachment, 'type'> {
  type: AttachmentType | ProcureAttachmentType;
  signed_timestamp: string;
  data: AttachmentData;
}

export interface ContractSignerProcure {
  procure: {
    number?: string;
    date?: string;
    attachment_id?: number;
    attachment?: ProcureAttachment;
  };
}
