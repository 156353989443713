import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Space, Icon, Select } from 'ebs-design';

interface Props {
  onSelected: (value: number) => void;
}

const current = new Date().getFullYear();

export const SelectYearModal: React.FC<Props> = ({ onSelected, children }) => {
  const { t } = useIntl();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(current);

  const onToggleHandler = () => setOpen((i) => !i);

  const onDownload = () => {
    onSelected(selected);
    onToggleHandler();
  };

  return (
    <>
      <Modal
        open={open}
        className="pc-modal--extra-small"
        size="small"
        header={t('select_year')}
        onClose={onToggleHandler}
      >
        <Modal.Content className="p-0 no-border">
          <Select
            className="pc-select--hide-value"
            value={selected}
            options={[
              ...Array(5)
                .fill(null)
                .map((_, i) => {
                  const value = current - i - 1;

                  return { value, text: value };
                })
                .reverse(),
              { value: current, text: current },
            ]}
            optionsMode="box"
            onChange={(value) => setSelected(value as number)}
          >
            <Select.Options />
          </Select>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={onToggleHandler}> {t('cancel')} </Button>

            <Button type="primary" prefix={<Icon type="check" model="bold" />} onClick={onDownload}>
              {t('download')}
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
      <div onClick={onToggleHandler}>{children}</div>
    </>
  );
};
