import { axios, cancelTokenHandler, stringifyUrl } from 'libs';
import {
  Properties,
  ReportInvoicesRatios,
  ReportInvoicesActivities,
  ReportInvoicesMonthlyStatuses,
  ReportInvoicesStatuses,
  ReportInvoicesStatusesExtends,
  ReportInvoicesSales,
  ReportInvoicesTotals,
  ReportUsersSales,
  ReportCompany,
  ReportCompanyStatistic,
  ReportInspectorGraph,
  ReportBacklogGraph,
  ReportDynamicGraph,
  ReportSalesGraph,
  ReportSalePayment,
  ReportSalesPayments,
} from 'types';
import { transformResponse, formatNumber } from 'utils';

export const reports = {
  getInvoicesRatios: async (query?: Properties) => {
    const { data } = await axios.get<ReportInvoicesRatios[]>(
      stringifyUrl('reports/invoices-ratios/', query),
      {
        cancelToken: cancelToken(reports.getInvoicesRatios.name),
      },
    );

    return data;
  },

  getInvoicesActivities: async (query?: Properties) => {
    const { data } = await axios.get<ReportInvoicesActivities[]>(
      stringifyUrl('reports/invoices-activities/', query),
      {
        cancelToken: cancelToken(reports.getInvoicesActivities.name),
      },
    );

    return data;
  },

  getInvoicesMonthlyStatuses: async (query?: Properties) => {
    const { data } = await axios.get<ReportInvoicesMonthlyStatuses[]>(
      stringifyUrl('reports/invoices-monthly-statuses/', query),
      {
        cancelToken: cancelToken(reports.getInvoicesMonthlyStatuses.name),
      },
    );

    return data;
  },

  getInvoicesStatuses: async (query?: Properties) => {
    const { data } = await axios.get<ReportInvoicesStatuses[]>(
      stringifyUrl('reports/invoices-statuses/', query),
      {
        cancelToken: cancelToken(reports.getInvoicesStatuses.name),
      },
    );

    return data;
  },

  getInvoicesStatusesExtends: async (query?: Properties) => {
    const { data } = await axios.get<ReportInvoicesStatusesExtends[]>(
      stringifyUrl('reports/invoices-statuses-extends/', query),
      {
        transformResponse: (response) =>
          transformResponse(response, {
            properties: ['amounts', 'averages', 'commissions'],
            wrapper: (value) => formatNumber(value),
          }),
        cancelToken: cancelToken(reports.getInvoicesStatusesExtends.name),
      },
    );

    return data;
  },

  getInvoicesSales: async (query?: Properties) => {
    const { data } = await axios.get<ReportInvoicesSales[]>(
      stringifyUrl('reports/invoices-sales/', query),
      {
        transformResponse: (response) =>
          transformResponse(response, {
            properties: [
              'amount_average_total',
              'amount_total',
              'commission_average_total',
              'commission_total',
              'guarantee_average_total',
              'guarantee_total',
            ],
            wrapper: (value) => formatNumber(value),
          }),
        cancelToken: cancelToken(reports.getInvoicesSales.name),
      },
    );

    return data;
  },

  getInvoicesTotals: async (query?: Properties) => {
    const { data } = await axios.get<ReportInvoicesTotals[]>(
      stringifyUrl('reports/invoices-totals/', query),
      {
        transformResponse: (response) =>
          transformResponse(response, {
            properties: [
              'amount_total',
              'amount_average_total',
              'amount_commission_total',
              'amount_commission_average',
              'amount_guarantee_total',
              'amount_guarantee_average',
              'amount_commission_total_percent',
              'amount_commission_average_percent',
              'amount_guarantee_total_percent',
              'amount_guarantee_average_percent',
            ],
            wrapper: (value) => formatNumber(value),
          }),
        cancelToken: cancelToken(reports.getInvoicesTotals.name),
      },
    );

    return data;
  },

  getUsersSales: async (query?: Properties) => {
    const { data } = await axios.get<ReportUsersSales[]>(
      stringifyUrl('reports/users-sales/', query),
      {
        cancelToken: cancelToken(reports.getUsersSales.name),
      },
    );

    return data;
  },

  getCompaniesStatistics: async (query?: Properties) => {
    const { data } = await axios.get<ReportCompanyStatistic[]>(
      stringifyUrl('reports/companies-statistics/', query),
      {
        cancelToken: cancelToken(reports.getCompaniesStatistics.name),
      },
    );

    return data;
  },

  getSalesPayments: async (query?: Properties) => {
    const { data } = await axios.get<ReportSalesPayments | ReportSalePayment[]>(
      stringifyUrl('reports/sales-payments/', query),
      {
        cancelToken: cancelToken(reports.getSalesPayments.name),
      },
    );

    return data;
  },

  getBacklogsGraphs: async (query?: Properties) => {
    const { data } = await axios.get<ReportBacklogGraph[]>(
      stringifyUrl('reports/graphs-backlogs/', query),
      {
        cancelToken: cancelToken(reports.getBacklogsGraphs.name),
      },
    );

    return data;
  },

  getDynamicsGraphs: async (query?: Properties) => {
    const { data } = await axios.get<ReportDynamicGraph[]>(
      stringifyUrl('reports/graphs-dynamics/', query),
      {
        cancelToken: cancelToken(reports.getDynamicsGraphs.name),
      },
    );

    return data;
  },

  getInspectorsGraphs: async (query?: Properties) => {
    const { data } = await axios.get<ReportInspectorGraph[]>(
      stringifyUrl('reports/graphs-inspectors/', query),
      {
        cancelToken: cancelToken(reports.getInspectorsGraphs.name),
      },
    );

    return data;
  },

  getPortfoliosGraphs: async (query?: Properties) => {
    const { data } = await axios.get<ReportBacklogGraph[]>(
      stringifyUrl('reports/graphs-portfolios/', query),
      {
        cancelToken: cancelToken(reports.getPortfoliosGraphs.name),
      },
    );

    return data;
  },

  getSalesGraphs: async (query?: Properties) => {
    const { data } = await axios.get<ReportSalesGraph[]>(
      stringifyUrl('reports/graphs-sales/', query),
      {
        cancelToken: cancelToken(reports.getSalesGraphs.name),
      },
    );

    return data;
  },

  downloadReports: async ({ id, type }, query?: Properties) => {
    const { data } = await axios.get<ReportCompany>(
      stringifyUrl(`/reports/companies/${id}/${type}/`, query),
      {
        cancelToken: cancelToken(reports.downloadReports.name),
      },
    );

    return data;
  },

  requestReports: async ({ id, type }, query?: Properties) => {
    const { data } = await axios.get<Properties>(
      stringifyUrl(`/reports/companies/${id}/affiliates/${type}/`, query),
      {
        cancelToken: cancelToken(reports.requestReports.name),
      },
    );

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(reports);
