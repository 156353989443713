import React, { FC } from 'react';
import { Tabs } from 'ebs-design';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { MyProfileLayout } from './MyProfileLayout';
import { getRoute } from 'utils';
import { routes } from 'routes';

export const ClassifiersLayout: FC = ({ children }) => {
  const { t } = useIntl();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const handleTabClick = (tabKey: string) => () => {
    push(getRoute(routes, tabKey));
  };

  return (
    <MyProfileLayout>
      <Tabs activeTab={pathname} className="company-tab">
        <Tabs.Tab
          tabKey="/profile/classifiers"
          label={t('classifiers')}
          onClick={handleTabClick('Classifiers')}
        />
        <Tabs.Tab
          tabKey="/profile/classifiers/categories"
          label={t('categories')}
          onClick={handleTabClick('Categories')}
        />
      </Tabs>
      {children}
    </MyProfileLayout>
  );
};
