import {
  Address,
  Contract,
  Attachments,
  FactoringRange,
  RatesEntity,
  User,
  Request,
  ClassifierEntity,
  CategoryInfo,
} from 'types';
import { Properties } from './common';

export enum CompanyType {
  DEBTOR = 'DEBTOR',
  ADHERENT = 'ADHERENT',
}

export enum DebtorType {
  NEW = 'NEW',
  EXISTENT = 'EXISTENT',
}

export enum AssociateType {
  NATURAL = 'NATURAL',
  LEGAL = 'LEGAL',
}

export interface Company {
  id: number;
  title: string;
  idno: string;
  additional_data: Properties;
  addresses: Address[];
  status: DebtorType;
  types: CompanyType[];
  hash: string;
  management: User[];
}

export interface RequestCompany extends Company {
  companies: RequestCompany[];
  limit?: Limit;
  contract?: Contract;
  statistics?: Statistics;
}

export interface Statistics {
  invoices?: {
    remained?: {
      amount: number;
      count: number;
    };
  };
}

export interface Associate extends User {
  idno: string;
  type: AssociateType;
  amount: number;
  percent: number;
  address_alternative: string;
}

export interface FullCompany extends Company {
  attachments: Attachments[];
  category: CategoryInfo;
  classifiers: ClassifierEntity[];
  code: string;
  email: string;
  comments: any[];
  management: any[];
  associates: Associate[];
  contacts: User[];
  contacts_roles: User[];
  contract: Contract;
  iban: string;
  proposed_limits: {
    BIC?: number;
  };
  identities: Properties[];
  rates: RatesEntity[];
  requests: any[];
  scoring: Scoring[];
  user: User;
  users: number[];
  timestamp: string;
  edited_timestamp: string | null;
  limit: Limit;
  position: string;
  request: Request;
}

export interface CompanyData {
  id: number;
  know_your_client: null | string;
  know_your_client_confirmed: boolean;
  clients: string;
  email: null | string;
  phone: null | string;
  scope: null | string;
  website: null | string;
  activity_field_alternative: string | null;
  organization_form_alternative: string | null;
  activity_field: {
    id: number;
    title: string;
    code_name: null | string;
    description: null | string;
    edited_timestamp: null | string;
  };
  bank: {
    id: number;
    title: string;
  };
  bank_id: number;
  factoring_range: FactoringRange;
  organization_form: {
    id: number;
    title: null | string;
    code_name: null | string;
    description: null | string;
    edited_timestamp: null | string;
    timestamp: string;
  };
  registered_at: string;
}

export interface Scoring {
  id: number;
  company: number;
  data: null; // todo maybe is object
  evaluated: string | null;
  type: string; // todo maybe is enum;
  timestamp: string;
  edited_timestamp: string | null;
}

export interface Debtor {
  id: number;
  email: string | null;
  know_your_client_confirmed: boolean;
  sum_of_active_invoices: number | null;
  sum_of_remained_invoices: number | null;
  total_active_invoices: number;
  total_remained_invoices: number;
  waiting_for_approval: boolean;
  title: string;
  types: CompanyType[];
  state: boolean;
  limit: {
    current: number;
    accessible: number;
    inaccessible: number;
  };
  status: DebtorType;
}

export interface Adherent {
  id: number;
  email: string | null;
  know_your_client_confirmed: boolean;
  sum_of_active_invoices: number | null;
  sum_of_remained_invoices: number | null;
  total_active_invoices: number;
  total_remained_invoices: number;
  waiting_for_approval: boolean;
  state: boolean;
  title: string;
  limit: {
    current: number;
    accessible: number;
    inaccessible: number;
  };
  status: DebtorType;
}

export interface Limit {
  accessible: number;
  current: number;
  inaccessible: number;
}

export interface CompanyRates {
  status: string;
  invoices_count_total: number | null;
  invoices_amount_average_monthly_total: number | null;
  invoices_amount_total: number | null;
  invoices_commission_amount_total: number | null;
  invoices_penalties_amount_total: number | null;
  invoices_guarantee_amount_total: number | null;
  invoices_guarantee_return_amount_total: number | null;
  invoices_remained_outstanding_max_total: number | null;
  invoices_active_totals: number | null;
  invoices_amount_average_total: number | null;
  invoices_term_average_total: number | null;
  invoices_term_total: number | null;
}

export interface CompanyManagement {
  company: number;
  edited_timestamp: string;
  id: number;
  notified: boolean;
  operated: boolean;
  timestamp: string;
  user: User;
}
