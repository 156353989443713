import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Collapse, useNotify } from 'ebs-design';
import { SmartSelect } from 'components';
import { users, nomenclature } from 'api';
import { Properties, SystemRole } from 'types';
import { OptionValue } from 'ebs-design/dist/components/molecules/Select/interfaces';
import { extractResponseProps } from 'utils';

const transform = (response: string | Properties[]) => {
  const isJson = typeof response === 'string';
  const data = isJson ? JSON.parse(response as string) : response;
  const results = isJson ? data.results || [] : response;

  return results.map(({ id, first_name, last_name, email }) => ({
    value: id,
    text: first_name || last_name ? `${first_name} ${last_name} (${email})` : email,
  }));
};

export const AlertAddressing: React.FC = () => {
  const { t } = useIntl();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [values, setValues] = React.useState<OptionValue[]>([]);

  const { data: dataAlertAddresses, isLoading: isLoadingAlertAddresses } = useQuery(
    ['alert-addresses'],
    () => nomenclature.getAlertAddresses({ limit: 1000 }),
  );

  const { mutate: createAddress, isLoading: isLoadingAddress } = useMutation(
    ({ email, user_id }: Properties) =>
      nomenclature.createAlertAddresses({
        email,
        user_id,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries('alert-addresses'),
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const { mutate: deleteAddress, isLoading: isLoadingDelete } = useMutation(
    (id: number) => nomenclature.deleteAlertAddresses(id),
    {
      onSuccess: () => queryClient.invalidateQueries('alert-addresses'),
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  React.useEffect(() => {
    if (dataAlertAddresses?.results) {
      setValues(dataAlertAddresses.results.map(({ user, email }) => (user ? user?.id : email)));
    }
  }, [dataAlertAddresses]);

  const selected = React.useMemo(
    () =>
      dataAlertAddresses?.results
        .filter(({ user, email }) => values.includes(user?.id || email))
        .map(({ user, email }) => ({
          value: user ? user?.id : email,
          text: user ? `${user.first_name} ${user.last_name} ${email ? `(${email})` : ''}` : email,
        })),
    [dataAlertAddresses, values],
  );

  const onChange = React.useCallback(
    (value) => {
      let diff = value.filter((x) => !values.includes(x));

      if (diff.length) {
        createAddress({ user_id: diff[0] });
      } else {
        diff = values.filter((x) => !value.includes(x));

        if (diff.length) {
          const address = dataAlertAddresses?.results.find(
            ({ email, user }) => (email || user?.id) === diff[0],
          );

          if (address) {
            deleteAddress(address.id);
          }
        }
      }

      setValues(value as OptionValue[]);
    },
    [values, dataAlertAddresses, createAddress, deleteAddress],
  );

  return (
    <Collapse className="pc-notifications overflow-visible">
      <Collapse.Header>{t('alert_addressing')}</Collapse.Header>
      <Collapse.Body>
        <SmartSelect
          api={users.getList}
          isClearable={false}
          filters={{ roles: Object.keys(SystemRole).join('__') }}
          className="pc-notifications__category select-max-width"
          mode="tags"
          loading={isLoadingAlertAddresses || isLoadingAddress || isLoadingDelete}
          newPlaceholder={`${t('add_new')}...`}
          selected={selected}
          value={values}
          transform={transform}
          onChange={onChange}
          onAddNew={(email) => createAddress({ email })}
        />
      </Collapse.Body>
    </Collapse>
  );
};
