import { axios, cancelTokenHandler, stringifyUrl } from 'libs';
import {
  RatesEntity,
  Classifier,
  Category,
  Currency,
  CategoryRatesList,
  CategoryHistoryRatesList,
} from 'types/nomenclature';
import { Results, Properties, SelectOption, FullPenalty } from 'types';
import { transformResults, transformResponse } from 'utils';

export const getOptionsByName = (response): SelectOption[] => {
  if (response && response.results) {
    return response.results.map((item) => ({
      value: item.id,
      text: item.name,
    }));
  }

  return [];
};

export const nomenclature = {
  getOrganizationForms: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/organization-forms/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getOrganizationForms.name),
      },
    );

    return data;
  },

  getActivities: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/activities/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getActivities.name),
      },
    );

    return data;
  },

  getBanks: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/banks/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getBanks.name),
      },
    );

    return data;
  },

  getRates: async (params: Properties = {}) => {
    const { data } = await axios.get<Results<RatesEntity>>('nomenclatures/rates/list/', {
      params,
      transformResponse,
      cancelToken: cancelToken(nomenclature.getRates.name),
    });

    return data;
  },

  updateRate: async ([id, body]: [number, Properties]) => {
    const { data } = await axios.patch<RatesEntity>(`nomenclatures/rates/${id}/`, body, {
      cancelToken: cancelToken(nomenclature.updateRate.name),
    });
    return data;
  },

  getFactoringRanges: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/factoring-ranges/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getFactoringRanges.name),
      },
    );

    return {
      ...data,
      results: data.results.map((item) => ({
        ...item,
        code_name: `${item.minimum || 0} - ${item.maximum || 0}`,
      })),
    };
  },

  getRoles: async (query?: Properties) => {
    const { data } = await axios.get<SelectOption[]>(
      stringifyUrl('nomenclatures/roles/list/', query?.queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getRoles.name),
      },
    );

    return getOptionsByName(data);
  },

  getOccupations: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/occupations/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getOccupations.name),
      },
    );

    return data;
  },

  getFactoringTypes: async () => {
    const { data } = await axios.get<SelectOption[]>('nomenclatures/factoring-types/list/', {
      transformResponse: (response: string): SelectOption[] => {
        const data = JSON.parse(response);
        const results = data.results || [];

        return results.map(({ id, type }) => ({ value: id, text: type }));
      },
      cancelToken: cancelToken(nomenclature.getFactoringTypes.name),
    });

    return data;
  },

  getDistricts: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/districts/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getDistricts.name),
      },
    );

    return data;
  },

  getLocationByDistrictId: async ({ queryKey }: Properties) => {
    const { districtId, ...params } = queryKey[1];
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/districts/${districtId}/locations/list/`, params),
      {
        cancelToken: cancelToken(nomenclature.getLocationByDistrictId.name),
      },
    );

    return data;
  },
  getClassifiersById: async ({ queryKey }: Properties) => {
    const { id } = queryKey[1];
    const { data } = await axios.get<Classifier>(`nomenclatures/classifiers/${id}/`, {
      cancelToken: cancelToken(nomenclature.getClassifiersById.name),
    });

    return data;
  },
  getClassifiers: async (params: Properties = { limit: 20 }) => {
    const { data } = await axios.get<Results<Classifier>>(`nomenclatures/classifiers/list/`, {
      params,
      cancelToken: cancelToken(nomenclature.getClassifiers.name),
    });

    return data;
  },
  createClassifier: async (body: Properties) => {
    const { data } = await axios.post<Classifier>(`nomenclatures/classifiers/`, body, {
      cancelToken: cancelToken(nomenclature.createClassifier.name),
    });
    return data;
  },
  updateClassifier: async ([id, body]: [number, Properties]) => {
    const { data } = await axios.patch<Classifier>(`nomenclatures/classifiers/${id}/`, body, {
      cancelToken: cancelToken(nomenclature.updateClassifier.name),
    });
    return data;
  },
  deleteClassifier: async (id: number) =>
    axios.delete<undefined>(`nomenclatures/classifiers/${id}/`, {
      cancelToken: cancelToken(nomenclature.deleteClassifier.name),
    }),
  getCurrencies: async (params: Properties = { limit: 100 }) => {
    const { data } = await axios.get<Results<Currency>>(`nomenclatures/currencies/list/`, {
      params,
      cancelToken: cancelToken(nomenclature.getCurrencies.name),
    });
    return data;
  },
  getDegreeOfKinships: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/degree-of-kinships/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getDegreeOfKinships.name),
      },
    );

    return data;
  },
  getCategories: async (params?: Properties) => {
    const { data } = await axios.get<Results<Category>>(`nomenclatures/categories/list/`, {
      params: {
        limit: 100,
        ...params,
      },
      cancelToken: cancelToken(nomenclature.getCategories.name),
    });
    return data;
  },
  createCategory: async (body: Properties) => {
    const { data } = await axios.post<Category>(`nomenclatures/categories/`, body, {
      cancelToken: cancelToken(nomenclature.createCategory.name),
    });
    return data;
  },
  updateCategory: async ([id, body]: [number, Properties]) => {
    const { data } = await axios.patch<Category>(`nomenclatures/categories/${id}/`, body, {
      cancelToken: cancelToken(nomenclature.updateCategory.name),
    });
    return data;
  },
  deleteCategory: async (id: number) =>
    axios.delete<undefined>(`nomenclatures/categories/${id}/`, {
      cancelToken: cancelToken(nomenclature.deleteCategory.name),
    }),

  getCountries: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Properties>>(
      stringifyUrl(`nomenclatures/countries/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(nomenclature.getCountries.name),
      },
    );

    return data;
  },

  getCities: async () => {
    const { data } = await axios.get<SelectOption[]>('nomenclatures/cities/list/', {
      transformResponse: transformResults,
      cancelToken: cancelToken(nomenclature.getCities.name),
    });

    return data;
  },

  getAlertAddresses: async (params?: Properties) => {
    const { data } = await axios.get<Results<Properties>>(`nomenclatures/alert-addresses/list/`, {
      params: {
        limit: 100,
        ...params,
      },
      cancelToken: cancelToken(nomenclature.getAlertAddresses.name),
    });
    return data;
  },
  createAlertAddresses: async (body: Properties) => {
    const { data } = await axios.post<Properties>(`nomenclatures/alert-addresses/`, body, {
      cancelToken: cancelToken(nomenclature.createAlertAddresses.name),
    });
    return data;
  },
  updateAlertAddresses: async ([id, body]: [number, Properties]) => {
    const { data } = await axios.patch<Properties>(`nomenclatures/alert-addresses/${id}/`, body, {
      cancelToken: cancelToken(nomenclature.updateAlertAddresses.name),
    });
    return data;
  },
  deleteAlertAddresses: async (id: number) =>
    axios.delete<undefined>(`nomenclatures/alert-addresses/${id}/`, {
      cancelToken: cancelToken(nomenclature.deleteAlertAddresses.name),
    }),

  getFoundOutSources: async (params: Properties = { limit: 20 }) => {
    const { data } = await axios.get<Results<Classifier>>(`nomenclatures/found-out-sources/list/`, {
      params,
      cancelToken: cancelToken(nomenclature.getFoundOutSources.name),
    });

    return data;
  },

  getCategoriesRatesList: async (params: Properties = {}) => {
    const { data } = await axios.get<CategoryRatesList[]>('nomenclatures/categories/rates/list/', {
      params,
      cancelToken: cancelToken(nomenclature.getCategoriesRatesList.name),
    });
    return data;
  },

  createCategoriesRate: async (body: Properties) => {
    const { data } = await axios.post<Properties>('nomenclatures/rates/', body, {
      cancelToken: cancelToken(nomenclature.createCategoriesRate.name),
    });
    return data;
  },

  deleteCategoriesRate: async (id: number) =>
    axios.delete<undefined>(`nomenclatures/rates/${id}/`, {
      cancelToken: cancelToken(nomenclature.deleteCategoriesRate.name),
    }),

  getCategoriesFutureRatesList: async (params: Properties = {}) => {
    const { data } = await axios.get<CategoryRatesList[]>(
      'nomenclatures/categories/future/rates/list/',
      {
        params,
        cancelToken: cancelToken(nomenclature.getCategoriesFutureRatesList.name),
      },
    );
    return data;
  },

  createCategoriesFutureRate: async (body: Properties) => {
    return await axios.post<Properties>('nomenclatures/future/logs/rates/', body, {
      transformResponse,
    });
  },

  getCategoriesHistoryRatesList: async (params: Properties = {}) => {
    const { data } = await axios.get<CategoryHistoryRatesList[]>('nomenclatures/rates/logs/list/', {
      params,
      cancelToken: cancelToken(nomenclature.getCategoriesHistoryRatesList.name),
    });
    return data;
  },

  getHistoryDatesList: async (params: Properties = {}) => {
    const { data } = await axios.get<Properties[]>('nomenclatures/rates/logs/dates/list/', {
      params,
      cancelToken: cancelToken(nomenclature.getHistoryDatesList.name),
    });
    return data;
  },

  setCategoriesHistoryRatesList: async (params: Properties = {}) => {
    const { data } = await axios.get('nomenclatures/rates/set/', {
      params,
      cancelToken: cancelToken(nomenclature.setCategoriesHistoryRatesList.name),
    });
    return data;
  },

  getPenalties: async (params: Properties = {}) => {
    const { data } = await axios.get<Results<FullPenalty>>('nomenclatures/penalties/', {
      params,
      cancelToken: cancelToken(nomenclature.getPenalties.name),
    });

    return data;
  },

  getPenaltyById: async ({ queryKey }: Properties) => {
    const { id } = queryKey[1];
    const { data } = await axios.get<FullPenalty>(`nomenclatures/penalties/${id}/`, {
      cancelToken: cancelToken(nomenclature.getPenaltyById.name),
    });

    return data;
  },

  createPenalty: async (body: Properties) => {
    const { data } = await axios.post('nomenclatures/penalties/', body, {
      cancelToken: cancelToken(nomenclature.createPenalty.name),
    });
    return data;
  },

  updatePenalty: async (id: number, body: Properties) => {
    const { data } = await axios.patch(`nomenclatures/penalties/${id}/`, body, {
      cancelToken: cancelToken(nomenclature.updatePenalty.name),
    });
    return data;
  },
  deletePenalty: async (id: number) =>
    axios.delete<undefined>(`nomenclatures/penalties/${id}/`, {
      cancelToken: cancelToken(nomenclature.deletePenalty.name),
    }),
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(nomenclature);
