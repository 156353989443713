import React, { useRef, useMemo, useContext } from 'react';
import { load } from 'react-cookies';
import cn from 'classnames';
import { CreateConnect } from '@ebs-platform/connect';
import { arrayContainsArray } from 'utils';
import { CustomerRole } from 'types';
import { UserContext } from 'contexts';

interface Props {
  open: boolean;
  onChangeCount?: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
}

export const Chat: React.FC<Props> = ({ open, onChangeCount, onClose }) => {
  const ref = useRef(null);
  const token = load('jwt-access-token');
  const { userRoles, user } = useContext(UserContext);

  const isAdherent = useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  const chat = useMemo(
    () => (
      <CreateConnect
        config={{
          token,
          saasAppToken: process.env.REACT_APP_saasAppToken,
          secretKey: process.env.REACT_APP_THUMBOR_KEY,
          API_URL: process.env.REACT_APP_CHAT_API,
          API_FILE_SERVICE: process.env.REACT_APP_THUMBOR_SERVER,
          isFullScreen: false,
          permissions: {
            createConversation: !isAdherent,
          },
          ...(user?.company?.management.length && {
            defaultConversation: user.company.management[0].messaging_id,
          }),
        }}
        onChangeCount={onChangeCount}
      />
    ),
    [token, isAdherent, onChangeCount, user],
  );

  return token && process.env.REACT_APP_CHAT_API ? (
    <>
      <div ref={ref} className={cn('connect-wrapper', { 'is-open': open })}>
        {chat}
      </div>
      {open && <div className="ebs-sidebar__mask" onClick={onClose}></div>}
    </>
  ) : null;
};
