import { CustomerRole, SystemRole } from 'types';

export default {
  read: {
    columns: {
      debtor: [...Object.keys(SystemRole), CustomerRole.ADMINISTRATOR],
      adherent: Object.keys(SystemRole),
      outstanding: [SystemRole.SY_COLLECTOR],
      risk_manager: Object.keys(SystemRole),
    },
  },
  create: {
    invoice: [CustomerRole.ADMINISTRATOR, CustomerRole.RESPONSIBLE],
    condition: [
      SystemRole.SY_RISK_MANAGER,
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
    ],
    additionalAct: [CustomerRole.ADMINISTRATOR, CustomerRole.RESPONSIBLE],
    otherDocuments: [
      ...Object.keys(CustomerRole),
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_RISK_MANAGER,
      SystemRole.SY_ADMIN,
    ],
    signingDocuments: [
      ...Object.keys(CustomerRole),
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_RISK_MANAGER,
      SystemRole.SY_ADMIN,
    ],
  },
  edit: {
    invoice: [CustomerRole.ADMINISTRATOR, CustomerRole.RESPONSIBLE],
  },
  delete: {
    condition: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_ADMIN,
    ],
    invoice: [CustomerRole.ADMINISTRATOR, CustomerRole.RESPONSIBLE],
  },
  perform: {
    recession: [SystemRole.SY_RISK_MANAGER, CustomerRole.ADMINISTRATOR],
    reviewRecession: [SystemRole.SY_RISK_MANAGER],
    sendToApprove: [SystemRole.SY_CREDIT_OFFICER, SystemRole.SY_CREDIT_OFFICER_SUPERIOR],
    sendToPay: [SystemRole.SY_RISK_MANAGER, SystemRole.SY_CREDIT_OFFICER],
    approve: [SystemRole.SY_RISK_MANAGER],
    decline: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_RISK_MANAGER,
    ],
    searchByAdherent: [...Object.keys(SystemRole)],
    notify: {
      collector: [...Object.keys(SystemRole)],
      debtor: [...Object.keys(SystemRole)],
    },
    verify: [SystemRole.SY_RISK_MANAGER],
    signingDocuments: [...Object.keys(CustomerRole), SystemRole.SY_RISK_MANAGER],
    export: [SystemRole.SY_RISK_MANAGER, SystemRole.SY_ADMIN],
  },
};
