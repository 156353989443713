import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table, Card, Space, Button, AvatarInline, Icon, firstLetters } from 'ebs-design';
import { User, Column } from 'types';
import { Plus } from 'resources';

import { AddContactModal } from './AddContactModal';

interface Props {
  id: number;
  data: User[];
  selected?: string;
  onSelect: (value: string) => void;
}

export const ContactsData: React.FC<Props> = ({ id, selected, data, onSelect }) => {
  const { t } = useIntl();
  const [visible, setVisible] = React.useState(false);
  const [created, setCreated] = React.useState(false);

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('name'),
        filter: 'first_name',
        render: ({ first_name, last_name, email }) => {
          const title = [first_name, last_name].join(' ');

          return (
            <AvatarInline
              circle
              alt={title}
              shortAlt={((title && firstLetters(title)) || email[0]).toUpperCase()}
              description={email}
            />
          );
        },
        width: 250,
      },
      {
        title: null,
        action: true,
        render: ({ email }) => (
          <Button type="ghost" onClick={() => onSelect(email)} disabled={selected === email}>
            {t(selected === email ? 'selected' : 'select')}
          </Button>
        ),
      },
    ],
    [t, selected, onSelect],
  );

  return (
    <>
      <Card className="mb-25">
        <Card.Header bordered>
          <Space justify="space-between">
            <h4 className="card-title">{t('users_data')}</h4>
            {!created && (
              <Button
                prefix={<Icon component={Plus} />}
                onClick={() => setVisible(true)}
                className="responsive-col__button"
              >
                {t('add_new')}
              </Button>
            )}
          </Space>
        </Card.Header>
        <Card.Body className="p-0">
          <Table size="big" className="table-no-border" columns={columns} data={data} />
        </Card.Body>
      </Card>
      <AddContactModal
        id={id}
        data={data}
        visible={visible}
        onClose={() => setVisible(false)}
        onCreate={() => setCreated(true)}
      />
    </>
  );
};
