import { ReportInvoicesStatuses, ReportCompanyStatistic, InvoiceStatus } from 'types';

const defaultInvoiceStatistic = {
  amount_average: 0,
  amount_commission_average: 0,
  amount_commission_total: 0,
  amount_total: 0,
  daily: 0,
  monthly: 0,
  percent: 0,
  total: 0,
  totals: 0,
  weekly: 0,
  yearly: 0,
};

export const reduceCompanyStatistic = (
  data: ReportCompanyStatistic[],
): Partial<ReportCompanyStatistic> =>
  data.reduce((acc, cur) => {
    Object.keys(cur).map((key) => (acc[key] = (acc[key] || 0) + cur[key]));

    return acc;
  }, {});

export const reduceInvoiceStatistic = (
  data: ReportInvoicesStatuses[] = [],
  statuses: InvoiceStatus[],
): Partial<ReportInvoicesStatuses> =>
  data
    .filter(({ status }) => statuses.includes(status))
    .reduce(
      (acc, cur) => {
        Object.keys(cur).map((key) =>
          typeof cur[key] === 'number'
            ? (acc[key] = (acc[key] || 0) + cur[key])
            : (acc[key] = cur[key]),
        );

        return acc;
      },
      { ...defaultInvoiceStatistic },
    );
