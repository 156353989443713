import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Card } from 'ebs-design';
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { abbreviateNumber } from 'utils';
import { DynamicsChartItem } from '../interfaces';
import { chartColors } from '../utils';

export const DynamicsChart: React.FC<{ data?: DynamicsChartItem[] }> = ({ data = [] }) => {
  const { t } = useIntl();

  return (
    <Card className="report-chart__card">
      <Card.Body>
        <ResponsiveContainer width={700} height={250}>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorLine1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartColors.line} stopOpacity={0} />
                <stop offset="95%" stopColor={chartColors.line} stopOpacity={0.8} />
              </linearGradient>
              <linearGradient id="colorLine2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartColors.bar} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartColors.bar} stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" tickFormatter={(tick) => abbreviateNumber(tick)} />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              name={t('with_right_refund')}
              dataKey="regression"
              stroke={chartColors.line}
              fillOpacity={1}
              fill="url(#colorLine1)"
            />
            <Area
              type="monotone"
              name={t('without_right_refund')}
              dataKey="no_regression"
              stroke={chartColors.bar}
              fillOpacity={1}
              fill="url(#colorLine2)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};
