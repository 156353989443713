import { Committee } from 'types';

export const getInitialValues = (committee: Committee | null): Committee | undefined => {
  if (!committee) {
    return;
  }
  return {
    ...committee,
    factoring_range: {
      ...committee?.factoring_range,
      maximum: parseFloat(committee?.factoring_range.maximum.toString().replace(/\s/g, '')),
      minimum: parseFloat(committee?.factoring_range.minimum.toString().replace(/\s/g, '')),
      currency_id: committee.factoring_range?.currency?.id,
    },
    users_id: committee.users.map((user) => user.id!),
  };
};
