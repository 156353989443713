import { uniqByKeepLast, formatNumber } from 'utils';
import { ReportInvoicesStatusesExtends, FactoringType } from 'types';

type ReducedStatusesStatistic = {
  [FactoringType.NO_REGRESSION]?: ReportInvoicesStatusesExtends;
  [FactoringType.REGRESSION]?: ReportInvoicesStatusesExtends;
  BREAKDOWN?: ReportInvoicesStatusesExtends[];
  TOTAL?: ReportInvoicesStatusesExtends;
};

export interface ReportInvoicesReducedStatistic {
  ACTIVE?: ReducedStatusesStatistic;
  EXPIRE?: ReducedStatusesStatistic;
}

export const reduceInvoiceExtendsStatistic = (
  data: ReportInvoicesStatusesExtends[],
): ReportInvoicesReducedStatistic =>
  data.reduce((acc, cur) => {
    if (acc[cur.block] && acc[cur.block][cur.factoring]) {
      acc[cur.block] = {
        ...acc[cur.block],
        [cur.factoring]: [cur, ...acc[cur.block][cur.factoring]],
      };
    } else if (cur.factoring === 'BREAKDOWN') {
      acc[cur.block] = {
        ...acc[cur.block],
        [cur.factoring]: [cur],
      };
    } else {
      acc[cur.block] = {
        ...acc[cur.block],
        [cur.factoring]: cur,
      };
    }

    return acc;
  }, {});

// Default ranges
const invoiceRanges = [
  { range: '1-15', percent: 0 },
  { range: '16-30', percent: 0 },
  { range: '31-60', percent: 0 },
  { range: '61-90', percent: 0 },
  { range: '91-120', percent: 0 },
  { range: '121 +', percent: 0 },
];

export const getInvoicesByRange = (data: Partial<ReportInvoicesStatusesExtends>[] = []) => {
  return uniqByKeepLast<Partial<ReportInvoicesStatusesExtends>>(
    [...invoiceRanges, ...data],
    (it) => it.range,
  );
};

export const calcPercentOfExpiredInvoice = (data?: ReportInvoicesReducedStatistic) => {
  const expire = data?.EXPIRE?.TOTAL?.total || 0;
  const active = data?.ACTIVE?.TOTAL?.total || 0;
  const value = Number(formatNumber((expire / active) * 100));

  return Number.isNaN(value) ? 0 : value;
};

export const chartColors = {
  line: '#00a1b1',
  bar: '#333333',
};

export const isNumber = (value: string): boolean => {
  return !isNaN(parseFloat(value)) && isFinite(+value);
};

export type RangePickerStateProps = [Date | string | null, Date | string | null];
