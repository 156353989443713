import { SystemRole } from 'types';

export default {
  read: {
    creditCommittee: [SystemRole.SY_CREDIT_COMMITTEE],
  },
  create: {
    riskManagerComment: [SystemRole.SY_RISK_MANAGER],
  },
};
