import { SystemRole, User } from './users';
import { Company, CompanyType } from './company';
import { Attachment } from './attachment';
import { PaymentOrder } from './payments';

export enum FactoringType {
  NO_REGRESSION = 'NO_REGRESSION',
  REGRESSION = 'REGRESSION',
  OTHER = 'OTHER',
}

export enum InvoiceStatus {
  SENT = 'SENT',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
  CLOSED = 'CLOSED',
  SENT_TO_PAYMENT = 'SENT_TO_PAYMENT',
  RECEIPTED = 'RECEIPTED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  REMAINED = 'REMAINED',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  RECESSIONED = 'RECESSIONED',
  DRAFT = 'DRAFT',
  TOTAL = 'TOTAL',
}

export type PercentType = {
  percent: number;
  amount: number;
  returns?: {
    amount: number;
    current: number;
    to_return?: number;
  };
};

export type RawType = { raw: number; amount: number };

export interface Turnover {
  id: number;
  metadata: any;
  outstanding: number;
  timestamp: string;
  edited_timestamp: string;
  balance: string | number;
  collected: string | number;
  penalty: string | number;
  date: string;
  data: any;
  invoice_id: number;
  invoice?: number;
}

export interface TurnoverInvoice {
  id: number;
  series_number: string;
}

export interface ListTurnover extends Omit<Turnover, 'invoice'> {
  operation_type: string;
  payment_type: string;
  status: string;
  invoice: TurnoverInvoice;
  debtor: string;
  adherent: string;
  warranty: string;
  days: number;
  sum_day?: number;
}

export interface TurnoverTotal {
  balance_total: string;
  collected_total: string;
  penalty_total: string;
  warranty_total: string;
}

export type DailyPenalty = {
  currency: {
    amount: number;
    code_name: string;
    state?: boolean;
  };
  percent?: number;
  state?: boolean;
};

export interface Penalty {
  penalty_from_contract?: boolean;
  amount?: number | string;
  percent?: number | string;
  state: boolean;
  daily: DailyPenalty;
  rate: {
    percent?: number | string;
    state: boolean;
  };
}

export interface FullPenalty {
  edited_timestamp: string;
  id: number;
  name?: string;
  penalty: Penalty;
  timestamp?: string;
}

export interface Invoice {
  assignment_date: string;
  attachments: Attachment[];
  balance: string;
  delivery_date: string;
  edited_timestamp: string;
  factoring_type: { id: number; type: FactoringType };
  commission: PercentType;
  guarantee: PercentType;
  id: number;
  issuing_date: string;
  verified: boolean;
  stage: SystemRole;
  recession_initiator: User | null;
  recession_executor: User | null;
  contact_name: string;
  metadata: {
    penalty: Penalty;
    turnovers: {
      collected: number;
      penalty: number;
    };
  };
  sync_ready: boolean;
  number: string;
  outstanding: string;
  payed: string;
  days: string | number;
  minimum: string | number;
  payments_orders: PaymentOrder[];
  payment_date: string | null;
  penalty_paid?: string;
  penalty_sold?: string;
  currency: { id: number; code_name: string };
  rate: {
    percent: number;
    id: number;
  };
  request: {
    id: number;
    status: string;
    type: 'INVOICE';
  };
  series: string | null;
  status: InvoiceStatus;
  term_date: string;
  timestamp: string;
  turnovers: Turnover[];
  users: any[];
  code: string;
  actions: any;
  amount: string;
  term_number: string;
  conditions: string[];
  contract: {
    number: string;
    date: string;
  };
  collected: string;
  confirmed: boolean;
  data: string | null;
  meta: any;
  company: Company;
  companies: Company[];
  state: boolean;
  user: User;
  warrant: string;
  delivery_contract: { number: number; date: string; attachment: number; penalty: Penalty };
}

export enum TurnoverStatusType {
  PAID = 'paid',
  PAIDOFF = 'paidOff',
  PENALTY = 'penalty',
}

export enum TurnoverPaymentType {
  IN = 'IN',
  OUT = 'OUT',
}

export enum TurnoverOperationType {
  INVOICE_PAYMENT = 'InvoicePayment',
  ADERENT_REPAYMENT = 'AderentRepayment',
  DEBITOR_REPAYMENT = 'DebitorRepayment',
  WARRANTY_REFUND = 'WarrantyRefund',
  DAILY_PENALTY = 'DailyPenalty',
  WARRANTY_USE = 'WarrantyUse',
  INVOICE_CANCELATION = 'InvoiceCancelation',
  PENALTY_CANCELATION = 'PenaltyCancelation',
}

export interface InvoicesCompanyType {
  id: number;
  title: string;
  idno: string;
  type: string;
}

export interface CommissionInvoicesType {
  percent: number;
  amount: number;
}

export interface PaymentsTotals {
  total_collected: number;
  total_penalty: number;
  total_warranty: number;
  total_days: number;
  total_paid: number;
}

export interface InvoicesCompaniesType extends InvoicesCompanyType {
  status: string;
  types: CompanyType;
}

export interface InvoicesTurnoverCompanyType extends InvoicesCompaniesType {
  contract: {
    id: number;
    number: string;
  };
  management: any[];
}

export interface InvoicesTurnover {
  id: number;
  series_number: string;
  adherent: InvoicesCompanyType;
  debtor: InvoicesCompanyType;
  commission: CommissionInvoicesType;
  amount: string;
  guarantee: PercentType;
  companies: InvoicesCompaniesType[];
  company: InvoicesTurnoverCompanyType;
  report_turnovers: {
    turnovers: ListTurnover[];
    totals: PaymentsTotals;
  };
  assignment_date?: string | null;
  invoice_financed_amount: number;
}
