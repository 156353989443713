import * as React from 'react';
import { Button, Icon, Space } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { useMutation } from 'react-query';
import { reports } from 'api';
import { downloadFile } from 'utils';
import { ReportCompanyType } from 'types';

interface Props {
  id: number;
}

export const CompanyActions: React.FC<Props> = ({ id }) => {
  const { t } = useIntl();

  const download = useMutation(
    (type: ReportCompanyType) => reports.downloadReports({ id, type }, { current: true }),
    {
      onSuccess: ({ attachment }) => downloadFile(attachment.url, attachment.name),
    },
  );

  return (
    <Space>
      <Button onClick={() => download.mutate(ReportCompanyType.BIC)} prefix={<Icon type="eye" />}>
        {t('report_bns_bic')}
      </Button>
      <Button
        onClick={() => download.mutate(ReportCompanyType.INFODEBIT)}
        prefix={<Icon type="eye" />}
      >
        {t('report_info_debit')}
      </Button>
    </Space>
  );
};
