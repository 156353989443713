import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Button, useForm, Form, Input, Space, DatePicker, Icon, Row, Col } from 'ebs-design';
import { useMutation, useQueryClient } from 'react-query';
import { payments } from 'api';
import { PaymentOrderCreate } from 'types';
import { validateResponse } from 'utils';
import { getAPIDateFormat, dateTimePickerFormat, toISO } from 'libs';
import { InputNumericForm } from 'components';

interface Props {
  invoiceId: number;
  visible: boolean;
  onClose: () => void;
}

export const AddPaymentModal: React.FC<Props> = ({ visible = false, invoiceId, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const queryClient = useQueryClient();

  const addPayment = useMutation((data: Partial<PaymentOrderCreate>) => payments.create(data), {
    onError: validateResponse.bind(null, form),
    onSuccess: () => {
      queryClient.invalidateQueries('invoices').then(() => handleClose());
    },
  });

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  React.useEffect(() => () => form.resetFields(), [form]);

  const handleSubmit = (payment: PaymentOrderCreate) => {
    addPayment.mutate({
      ...payment,
      date: payment.date ? toISO(getAPIDateFormat(payment.date)) : payment.date,
      invoice_id: invoiceId,
    });
  };

  return (
    <Modal open={visible} onClose={handleClose} title={t('add_payment')}>
      <Modal.Content className="px-20 py-30">
        <Form form={form} onFinish={handleSubmit}>
          <Row className="mb-20">
            <Col size={4}>
              <Form.Field name="number" label={t('payment_order')} rules={[{ required: true }]}>
                <Input />
              </Form.Field>
            </Col>
            <Col size={4}>
              <Form.Field name="date" label={t('date')}>
                <DatePicker showTimeSelect dateFormat={dateTimePickerFormat} />
              </Form.Field>
            </Col>
            <Col size={4}>
              <InputNumericForm
                name="payed"
                label={`${t('invoice_financing')} (${t('payment_made')})`}
                rules={[{ required: true }]}
              />
            </Col>
          </Row>

          <Row className="mb-20">
            <Col size={4}>
              <InputNumericForm name="balance" label={t('invoice_payment')} />
            </Col>
            <Col size={4}>
              <InputNumericForm name="commission" label={t('commission')} />
            </Col>
            <Col size={4}>
              <InputNumericForm name="guarantee" label={t('guarantee')} />
            </Col>
          </Row>

          <Row>
            <Col size={4}>
              <InputNumericForm name="interest" label={t('penalties')} />
            </Col>
            <Col size={4}>
              <InputNumericForm name="outstanding" label={t('outstanding')} />
            </Col>
            <Col size={4}>
              <InputNumericForm name="tax" label={t('taxes_paid')} />
            </Col>
          </Row>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            loading={addPayment.isLoading}
            onClick={() => form.submit()}
          >
            {t('add')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
