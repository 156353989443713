import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Label } from 'ebs-design';
import { LabelType } from 'ebs-design/dist/components/atoms/Label/Label';
import { Status } from 'types';

interface Props {
  status: string;
  type?: LabelType;
  circle?: boolean;
}

export const StatusLabel: React.FC<Props> = ({ status, type = 'fill', circle = true }) => {
  const { t } = useIntl();

  return (
    <Label status={Status[status]} type={type} circle={circle} text={t(status.toLowerCase())} />
  );
};
