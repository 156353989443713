export const nomenclatures = {
  in_progress: {
    en: `In progress`,
    ro: `În progres`,
    ru: `В процессе`,
  },
  approved: {
    en: `Approved`,
    ro: `Aprobat`,
    ru: `Утверждено`,
  },
  denied: {
    en: `Denied`,
    ro: `Refuzat`,
    ru: `Отказано`,
  },
  sent: {
    en: `Sent`,
    ro: `Expediat`,
    ru: `Отправлено`,
  },
  transferred: {
    en: `Transferred`,
    ro: `Transferat`,
    ru: `Перенесено`,
  },
  paid: {
    en: `Paid`,
    ro: `Achitată`,
    ru: `Оплачен`,
  },
  receipted: {
    en: `FUNDED`,
    ro: `Finanțată`,
    ru: `Финансируется`,
  },
  draft: {
    en: `Draft`,
    ro: `Schiţă`,
    ru: `Черновик`,
  },
  no_regression: {
    en: `No regression`,
    ro: `Fără regres`,
    ru: `Без регресса`,
  },
  regression: {
    en: `Regression`,
    ro: `Regres`,
    ru: `Регресс`,
  },
  partially_paid: {
    en: `Partially paid`,
    ro: `Achitat parțial`,
    ru: `Частично оплачено`,
  },
  closed: {
    en: `Closed`,
    ro: `Închis`,
    ru: `Закрыто`,
  },
  remained: {
    en: `Remained`,
    ro: `Restant`,
    ru: `Остался`,
  },
  cancelled: {
    en: `Cancelled`,
    ro: `Anulat`,
    ru: `Отменено`,
  },
  sent_to_payment: {
    en: `Sent to payment`,
    ro: `Trimis spre achitare`,
    ru: `Отправлено на оплату`,
  },
  recessioned: {
    en: `Recessioned`,
    ro: `Recesionat`,
    ru: `Рецессия`,
  },
  with_right_refund: {
    en: `With the right to refund`,
    ro: `Cu drept de regres`,
    ru: `С правом возврата`,
  },
  without_right_refund: {
    en: `Without the right to refund`,
    ro: `Fără drept de regres`,
    ru: `Без права на возврат`,
  },
  pending: {
    en: `Pending`,
    ro: `In asteptarea`,
    ru: `В ожидании`,
  },
  review: {
    en: `Review`,
    ro: `Revizuire`,
    ru: `Рассмотрение`,
  },
  info: {
    en: `Info`,
    ro: `Informație`,
    ru: `Информация`,
  },
  SMS: {
    en: `SMS`,
    ro: `SMS`,
    ru: `СМС`,
  },
  EMAIL: {
    en: `Email`,
    ro: `Email`,
    ru: `Почта`,
  },
  PHONE: {
    en: `Phone`,
    ro: `Telefon`,
    ru: `Телефон`,
  },
  SYSTEM: {
    en: `System`,
    ro: `Sistem`,
    ru: `Система`,
  },
  GRANDPARENTS: {
    en: `Grandparents`,
    ro: `Bunicii`,
    ru: `Бабушка и дедушка`,
  },
  DAUGHTER: {
    en: `Daughter`,
    ro: `Fiică`,
    ru: `Дочь`,
  },
  HUSBAND: {
    en: `Husband`,
    ro: `Soț`,
    ru: `Муж`,
  },
  WIFE: {
    en: `Wife`,
    ro: `Soție`,
    ru: `Жена`,
  },
  'BROTHER-IN-LAW': {
    en: `Brother-in-law`,
    ro: `Cumnat`,
    ru: `Шурин`,
  },
  BROTHER: {
    en: `Brother`,
    ro: `Frate`,
    ru: `Брат`,
  },
};
