import * as React from 'react';
import { Container } from 'ebs-design';
import { Layout } from 'components';

import { AdherentsTable, DebtorsTable } from '../committee';

export const CreditCommittee: React.FC = () => {
  return (
    <Layout>
      <Container>
        <AdherentsTable />
        <DebtorsTable />
      </Container>
    </Layout>
  );
};
