import * as React from 'react';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Button, Form, Space, Icon, Modal, useForm, Select } from 'ebs-design';
import { users, nomenclature } from 'api';
import { SmartSelect } from 'components';
import { SystemRole, Properties } from 'types';
import { defaultFilters } from 'utils';

interface Props {
  visible: boolean;
  onSave: (data: { management_id: number; found_out_source_id: number }) => void;
  onClose: () => void;
}

const SourceModal: React.FC<Props> = ({ visible = false, onSave, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const [visibleCO, setVisibleCO] = React.useState(false);
  const [filters, setFilters] = useSetState<Properties>({ ...defaultFilters });

  const { data, isLoading } = useQuery(['found-out-sources', filters], () =>
    nomenclature.getFoundOutSources(filters),
  );

  const sourceOptions = React.useMemo(
    () => (data?.results || []).map(({ id, title }) => ({ value: id, text: title })),
    [data],
  );
  const requiredCO = React.useMemo(() => [1, 2, 3], []);

  const handleClose = React.useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const onValuesChange = React.useCallback(
    ({ found_out_source_id }) => {
      if (found_out_source_id) {
        setVisibleCO(requiredCO.includes(found_out_source_id));
        form.resetFields(['management_id']);
      }
    },
    [form, requiredCO],
  );

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      closeOnClickOutside={false}
      title={t('information_source')}
      size="small"
    >
      <Modal.Content className="px-20 py-30">
        <Form form={form} onValuesChange={onValuesChange} onFinish={onSave}>
          <Form.Field
            name="found_out_source_id"
            label={t('select_source_info')}
            rules={[{ required: true }]}
          >
            <Select options={sourceOptions} loading={isLoading}>
              <Select.Pagination
                mode="scroll"
                count={data?.count || 0}
                page={filters.page}
                limit={filters.limit}
                setPage={(page) => setFilters({ page })}
              />
            </Select>
          </Form.Field>

          {visibleCO && (
            <Form.Field
              name="management_id"
              label={t('select_credit_officer')}
              rules={[{ required: true }]}
            >
              <SmartSelect
                api={users.getList}
                filters={{ roles: [SystemRole.SY_CREDIT_OFFICER] }}
                transform={(results) =>
                  results.map(({ id, first_name, last_name }) => ({
                    value: id,
                    text: ((first_name || last_name) && `${first_name} ${last_name}`) || '---',
                  }))
                }
                enabled={visible}
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={() => form.submit()}
          >
            {t('send')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default SourceModal;
