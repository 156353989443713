import * as React from 'react';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import { Container, Row, Col, Label, Button, Input, Icon } from 'ebs-design';
import { useQueryParams } from 'hooks';
import { company } from 'api';
import { Properties, CompanyType } from 'types';
import { DatePicker, SmartSelect } from 'components';
import { defaultFilters } from 'utils';

interface FiltersProps {
  onSubmit: (filters: Properties) => void;
}

const Filters: React.FC<FiltersProps> = ({ onSubmit }) => {
  const { t } = useIntl();
  const params = useQueryParams();
  const [filters, setFilters] = useSetState<Properties>({ ...defaultFilters, ...params });

  const onSubmitHandler = React.useCallback(() => onSubmit(filters), [filters, onSubmit]);

  return (
    <div className="pc-searchbar">
      <Container>
        <Row gy={3}>
          <Col size={12} lg={4}>
            <Label text={t('direct_search')} />
            <Input
              placeholder={t('search')}
              suffix={<Icon type="search" />}
              value={filters.search}
              onChange={(search) => setFilters({ search, page: 1 })}
              isClearable
            />
          </Col>
          <Col size={6} sm={3} lg={2}>
            <Label text={t('adherent')} />
            {/* TODO: need to improve */}
            <SmartSelect
              search
              api={company.getList}
              filters={{ types: CompanyType.ADHERENT.toLowerCase() }}
              placeholder={t('select')}
              value={filters.company_id}
              onChange={(company_id) => setFilters({ company_id, page: 1 })}
            />
          </Col>
          <Col size={6} sm={3} lg={2}>
            <Label text={t('debtor')} />
            {/* TODO: need to improve */}
            <SmartSelect
              search
              api={company.getList}
              filters={{ types: CompanyType.DEBTOR.toLowerCase() }}
              placeholder={t('select')}
              value={filters.companies_id}
              onChange={(companies_id) => setFilters({ companies_id, page: 1 })}
            />
          </Col>
          <Col size={12} sm={6} lg={3}>
            <Label text="Period" />
            <DatePicker
              type="period"
              value={filters?.timestamp__range?.split('__')}
              onChange={(period) => {
                const startDate = period && period[0] ? period[0] : null;
                const endDate = period && period[1] ? period[1] : null;
                const range = startDate && endDate ? `${startDate}__${endDate}` : null;

                setFilters({ timestamp__range: range, page: 1 });
              }}
            />
          </Col>
          <Col size={12} sm={12} lg={1} className="align-flex-end">
            <Button type="dark" className="full-width" onClick={onSubmitHandler}>
              {t('search')}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Filters;
