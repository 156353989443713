import { axios, cancelTokenHandler, stringifyUrl } from 'libs';
import { Results, Properties, Notification, NotificationCreate } from 'types';

export const notifications = {
  getList: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Results<Notification>>(
      stringifyUrl(`/notifications/list/`, queryKey[1]),
      {
        cancelToken: cancelToken(notifications.getList.name),
      },
    );

    return data;
  },

  get: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<Notification>(`/notifications/${queryKey[1]}/`, {
      cancelToken: cancelToken(notifications.get.name),
    });

    return data;
  },

  create: async (bodyData: NotificationCreate) => {
    const { data } = await axios.post<Notification>(`/notifications/`, bodyData, {
      cancelToken: cancelToken(notifications.create.name),
    });

    return data;
  },

  delete: async (id: number) => {
    return await axios.delete(`/notifications/${id}/`, {
      cancelToken: cancelToken(notifications.delete.name),
    });
  },

  update: async (id: number, bodyData: Properties) => {
    return await axios.patch(`/notifications/${id}/`, bodyData, {
      cancelToken: cancelToken(notifications.update.name),
    });
  },

  downloadZip: async (id: number) => {
    const { data } = await axios.get<Blob>(`/notifications/${id}/downloads/zips/?file=true`, {
      responseType: 'blob',
      cancelToken: cancelToken(notifications.downloadZip.name),
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(notifications);
