import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import cn from 'classnames';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { default as queryString } from 'query-string';
import { useIntl } from 'estafette-intl';
import {
  Button,
  Form,
  Checkbox,
  Row,
  Col,
  useForm,
  useNotify,
  Loader,
  Badge,
  InputPhone,
  Input,
} from 'ebs-design';
import { UserContext } from 'contexts';
import { profile, users } from 'api';
import { Tokens, Properties, ContactMode, User, ActivationUser } from 'types';
import { OTPCode } from 'components';
import { validateResponse, extractResponseProps, getRoute } from 'utils';

import AuthenticationModal from '../AuthenticationModal';
import { stringifyUrl } from 'libs';
import { routes } from 'routes';

const query = 'userProfile';

export const ActivationPage: React.FC = () => {
  const { t } = useIntl();
  const { search } = useLocation();
  const { push } = useHistory();
  const { token, email } = queryString.parse(search);
  const { onRedirect, logged } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [form] = useForm();
  const [sms, setSms] = React.useState('');
  const [agree, setAgree] = React.useState(false);

  // Save settings
  const userSettings = useMutation<Tokens, unknown, Properties>(
    (params) => profile.updateSettings(params),
    {
      onMutate: async (params) => {
        const prevData = queryClient.getQueryData(query) as User;

        queryClient.setQueryData(query, {
          ...prevData,
          settings: prevData.settings.map((setting) => {
            if (setting.code_name === params.code_name) {
              return { ...setting, ...params };
            }

            return setting;
          }),
        });

        return () => queryClient.setQueryData(query, prevData);
      },
      onError: (err, values, rollback: any) => {
        validateResponse(form, err, values);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
        rollback();
      },
      onSuccess: () => queryClient.invalidateQueries(query),
    },
  );

  const { data, isLoading } = useQuery('profile', () => users.getInvitation(token as string), {
    enabled: !!token,
  });

  const { data: userExist } = useQuery(
    'check-token',
    () => users.registerInvitation({}, token as string, true),
    {
      enabled: !!token,
    },
  );

  React.useEffect(() => {
    if (userExist) {
      form.setFieldsValue({ idnp: userExist.idnp, phone: userExist.phone });
    }
  }, [form, userExist]);

  // Register request
  const registerRequest = useMutation<Tokens, any, ActivationUser>(
    (data) => users.registerInvitation(data, token as string),
    {
      onSuccess: () => push(getRoute(routes, 'ActivationPage', { query: { email: data?.email } })),
      onError: (err) => {
        if (err?.response?.status === 409) {
          notify.success({ title: t('successfully_added_to_company') });
          window.location.href = '/public/sign-in';
        } else {
          extractResponseProps(err, (title, description) =>
            notify.error({ title: t(title), description: t(description) }),
          );
        }
      },
    },
  );

  // OTP confirmation request
  const loginRequest = useMutation<Tokens, unknown, Properties>(
    (formData) => users.confirm(formData),
    {
      onSuccess: (tokens: Tokens) => tokens && push(getRoute(routes, 'SignInPage')),
      onError: (err, values, rollback: any) => {
        validateResponse(form, err, values);
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
        rollback();
      },
    },
  );

  React.useEffect(() => {
    if (logged) {
      const values = form.getFieldsValue();

      Object.keys(values).forEach((code_name) => {
        const state = values[code_name];
        if (state) {
          userSettings.mutate({
            code_name,
            state,
          });
        }
      });

      if (!userSettings.isLoading) {
        onRedirect();
      }
    }
  }, [logged, form, userSettings, onRedirect]);

  const onConfirm = React.useCallback(() => {
    if (sms) {
      loginRequest.mutate({
        username: email,
        token: sms,
      });
    } else if (token) {
      const { phone, idnp, password, confirm_password } = form.getFieldsValue();

      registerRequest.mutate({ phone, idnp, password, confirm_password });
    }
  }, [token, sms, form, email, loginRequest, registerRequest]);

  const canActivate = React.useMemo(
    () => (token ? agree && true : sms.length > 5),
    [agree, token, sms],
  );

  return (
    <AuthenticationModal
      className="justify-start"
      title={t(token ? 'email_confirmation' : 'sms_confirmation')}
      description={!token && t('confirmation_code_sent')}
      footer={
        <>
          {t('already_registered')}{' '}
          <Link to={stringifyUrl(getRoute(routes, 'SignInPage'))}>{t('access_your_account')}</Link>
        </>
      }
    >
      <Loader loading={isLoading}>
        <Form
          form={form}
          onFinish={onConfirm}
          autoComplete="off"
          type="horizontal"
          controlOptions={{ col: { size: 9 } }}
          labelOptions={{ col: { size: 3 } }}
        >
          <input autoComplete="false" type="password" style={{ display: 'none' }} />
          {token ? (
            <>
              <Form.Field
                name="phone"
                label={t('phone_number')}
                className="pc-fix-field"
                rules={[{ required: true }]}
              >
                <InputPhone disabled={userExist?.phone} country="md" />
              </Form.Field>
              <Form.Field
                name="idnp"
                label={t('idnp')}
                className="pc-fix-field"
                rules={[{ required: true, len: 13 }]}
              >
                <Input disabled={userExist?.idnp} type="number" />
              </Form.Field>
              <Form.Field
                name="password"
                label={t('password')}
                className="pc-fix-field"
                rules={[{ required: true }]}
              >
                <Input styleType="grey" type="password" />
              </Form.Field>

              <Form.Field
                className="pc-fix-field no-wrap"
                name="confirm_password"
                label={t('confirm_password')}
                rules={[{ required: true }]}
              >
                <Input styleType="grey" type="password" />
              </Form.Field>

              <div
                className="form-divider mt-0"
                style={{ position: 'absolute', width: '100vw', left: '-50%' }}
              />
            </>
          ) : (
            <>
              <Row className="mb-25">
                <Col size={3}>
                  <div className="ebs-form__field__label align-items--center justify-content--end">
                    {t('code')}
                    <span className="ebs-form__field__required">*</span>
                  </div>
                </Col>
                <Col size={9}>
                  <OTPCode onChange={(value) => setSms(value)} />
                </Col>
              </Row>
              <div
                className="form-divider mt-0"
                style={{ position: 'absolute', width: '100vw', left: '-50%' }}
              />
            </>
          )}

          <Row>
            <Col size={3}></Col>
            <Col size={9}>
              {!token && (
                <>
                  <h3 className={cn('form-header', 'mb-20', 'mt-20')}>
                    {t('select_contact_mode')}
                  </h3>

                  <Form.Field name={ContactMode.SMS} valuePropName="checked" initialValue={false}>
                    <Checkbox text={t('contact_by_sms')} />
                  </Form.Field>

                  <Form.Field name={ContactMode.PHONE} valuePropName="checked" initialValue={false}>
                    <Checkbox text={t('contact_by_phone')} />
                  </Form.Field>

                  <Form.Field name={ContactMode.EMAIL} valuePropName="checked" initialValue={false}>
                    <Checkbox text={t('contact_by_email')} />
                  </Form.Field>

                  {!email && (
                    <div
                      className="form-divider mt-0"
                      style={{ position: 'absolute', width: '100vw', left: '-50%' }}
                    />
                  )}
                </>
              )}

              {!email && (
                <div className="mt-25 mb-15">
                  <Checkbox
                    text={t('i_am_agree')}
                    checked={agree}
                    onChange={() => setAgree((i) => !i)}
                  />
                </div>
              )}

              <div className="authentication-modal-form-actions mt-25">
                <Badge
                  type="danger"
                  text={token && !data?.email ? t('token_has_expired') : undefined}
                >
                  <Button
                    submit
                    type="primary"
                    buttonClass="uppercase"
                    disabled={!canActivate || (!email && !!token && !data?.email)}
                  >
                    {t('activate_account')}
                  </Button>
                </Badge>
              </div>
            </Col>
          </Row>
        </Form>
      </Loader>
    </AuthenticationModal>
  );
};
