export const dashboard = {
  fill_in_info_about_company: {
    en: `Fill in the information about the company`,
    ro: `Completeaza informatia despre companie`,
    ru: `Заполните информацию о компании`,
  },
  get_electronic_signature_sign_contract: {
    en: `Get an electronic * signature and sign the contract`,
    ro: `Procura semnatura electronica* si semneaza contractul`,
    ru: `Получи электронную* подпись и подпишите контракт`,
  },
  upload_invoice_contract: {
    en: `Upload the invoice and the contract`,
    ro: `Incarca factura si contractul`,
    ru: `Загрузите счет и договор`,
  },
  receive_answer_about_acceptance_invoice: {
    en: `Receive the answer about the acceptance of the invoice`,
    ro: `Primeste raspunsul despre aprobarea facturii`,
    ru: `Получите ответ о принятии счета`,
  },
  sign_documents_electronically_for_funding: {
    en: `Sign documents electronically for funding`,
    ro: `Semneaza electronic actele pentru finantare`,
    ru: `Подпиши документы электронно для финансирования`,
  },
  receive_money_in_account_for_assigned_invoices: {
    en: `Receive the money in the account for the assigned invoices`,
    ro: `Primeste banii in cont pentru facturile cesionate`,
    ru: `Получите деньги на счет по выставленным фактурам`,
  },
  how_do_get_electronic_signature: {
    en: `How do you get an electronic signature?`,
    ro: `Cum obțineți o semnătură electronică?`,
    ru: `Как получить электронную подпись?`,
  },
};
