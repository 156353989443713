import React from 'react';
import cn from 'classnames';
import { Tooltip } from 'ebs-design';
import { Info, CheckFilled } from 'resources/icons';

export interface StepProps {
  count?: number;
  title: string;
  status?: string;
  description?: string;
  className?: string;
  onClick?: () => void;
}

const Step: React.FC<StepProps> = ({ title, status, count, description, className, onClick }) => {
  const statusIcon = status === 'completed' ? <CheckFilled /> : count;

  return (
    <div
      className={cn('step-item', { [`step-item--${status}`]: status }, className)}
      onClick={onClick}
    >
      <div className="step-item__count">{statusIcon}</div>
      <div className="step-item__title">{title}</div>
      {description && (
        <div className="step-item__info">
          <Tooltip
            bodyClass="step-item__desc"
            placement="bottom"
            trigger="hover"
            tooltip={description}
            offset={[-8, -2]}
          >
            <Info />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Step;
