import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Button, ButtonGroup } from 'ebs-design';
import { useSetState } from 'react-use';
import { Properties } from 'types';
import { defaultFilters } from 'utils';

import { ExistingUserForm } from './ExistingUserForm';
import { NewUserForm } from './NewUserForm';

interface Props {
  title: string;
  userId?: number;
  visible: boolean;
  onClose: () => void;
}

type UserType = 'new' | 'existing';

export const AddUserModal: React.FC<Props> = ({ visible = false, title, userId, onClose }) => {
  const { t } = useIntl();
  const [userType, setUserType] = React.useState<UserType>('new');
  const [initialUserId, setUserId] = React.useState<number>();

  const [tenancyFilters, setTenancyFilters] = useSetState<Properties>({
    ...defaultFilters,
    beneficiary: false,
  });

  const handleClose = () => {
    // Reset user type
    setUserType('new');
    onClose();
  };

  const onSelectUser = (value: number) => {
    setUserId(value);
    setUserType('existing');
  };

  return (
    <Modal open={visible} onClose={handleClose} title={title} size="small">
      <Modal.Content className="px-20 py-30">
        {!userId && (
          <ButtonGroup className="mb-20 justify-content--center">
            <Button
              type={userType === 'new' ? 'primary' : 'ghost'}
              onClick={() => setUserType('new')}
            >
              {t('add_user')}
            </Button>
            <Button
              type={userType === 'existing' ? 'primary' : 'ghost'}
              onClick={() => setUserType('existing')}
            >
              {t('select_user')}
            </Button>
          </ButtonGroup>
        )}

        {userType === 'new' ? (
          <NewUserForm
            userId={userId}
            tenancyFilters={tenancyFilters}
            onClose={handleClose}
            onSelectUser={onSelectUser}
            onChangeTenancyFilters={setTenancyFilters}
          />
        ) : (
          <ExistingUserForm
            initialUserId={initialUserId}
            tenancyFilters={tenancyFilters}
            userId={userId}
            onClose={handleClose}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};
