export const validations = {
  is_not_valid: {
    en: `is not a valid`,
    ro: `nu este valabil`,
    ru: `не действительный`,
  },
  validation_error_field: {
    en: `Validation error on field`,
    ro: `Eroare de validare pe câmp`,
    ru: `Ошибка проверки в поле`,
  },
  is_required: {
    en: `is required`,
    ro: `este obligatoriu`,
    ru: `обязательное для заполнения`,
  },
  must_be_one_of: {
    en: `must be one of`,
    ro: `trebuie să fie unul dintre`,
    ru: `должен быть одним из`,
  },
  cannot_be_empty: {
    en: `cannot be empty`,
    ro: `nu poate fi gol`,
    ru: `не может быть пустым`,
  },
  is_invalid_format_date: {
    en: `is invalid for format date`,
    ro: `este nevalid pentru data formatului`,
    ru: `недействителен для даты формата`,
  },
  could_not_be_parsed_as_date: {
    en: `could not be parsed as date`,
    ro: `nu a putut fi folosit ca dată`,
    ru: `не может быть использован как дата`,
  },
  is_invalid_date: {
    en: `is invalid date`,
    ro: `este o dată nevalidă`,
    ru: `неверная дата`,
  },
  must_be_exactly: {
    en: `must be exactly`,
    ro: `trebuie să fie exact`,
    ru: `должно быть точно`,
  },
  characters: {
    en: `characters`,
    ro: `caractere`,
    ru: `символы`,
  },
  in_length: {
    en: `in length`,
    ro: `în lungime`,
    ru: `в длину`,
  },
  does_not_match_pattern: {
    en: `does not match pattern`,
    ro: `nu se potrivește cu modelul`,
    ru: `не соответствует шаблону`,
  },
  must_be_at_least: {
    en: `must be at least`,
    ro: `trebuie sa fie cel putin`,
    ru: `должен быть не менее`,
  },
  cannot_be_longer_than: {
    en: `cannot be longer than`,
    ro: `nu poate fi mai lung de`,
    ru: `не может быть длиннее, чем`,
  },
  must_be_between: {
    en: `must be between`,
    ro: `trebuie să fie între`,
    ru: `должно быть между`,
  },
  must_equal: {
    en: `must equal`,
    ro: `trebuie să fie egală`,
    ru: `должен равняться`,
  },
  cannot_be_less_than: {
    en: `cannot be less than`,
    ro: `nu poate fi mai mic de`,
    ru: `не может быть меньше чем`,
  },
  cannot_be_greater_than: {
    en: `cannot be greater than`,
    ro: `nu poate fi mai mare decât`,
    ru: `не может быть больше чем`,
  },

  /* Custom fields */
  attachments_id: {
    en: `Invoice`,
    ro: `Factura`,
    ru: `Счет`,
  },
  number: {
    en: `Invoice №`,
    ro: `Factura №`,
    ru: `Счет №`,
  },
  factoring_type_id: {
    en: `Factoring type and term`,
    ro: `Tipul și termenul de factoring`,
    ru: `Тип и срок факторинга`,
  },
};
