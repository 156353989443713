import React, { FC } from 'react';
import { useIntl } from 'estafette-intl';
import { Modal, Button, Icon, useForm, Space } from 'ebs-design';
import { Classifier } from 'types';
import { ClassifierForm, ClassifierFormValues } from './ClassifierForm';

export interface ClassifierModalProps {
  classifier: Classifier | null;
  onClose(): void;
}

export const ClassifierModal: FC<ClassifierModalProps> = ({ classifier, onClose }) => {
  const { t } = useIntl();
  const [classifierForm] = useForm<ClassifierFormValues>();

  const title = t(classifier ? 'edit_classifier' : 'add_classifier');

  return (
    <Modal open={true} size="large" onClose={onClose} title={title}>
      <Modal.Content>
        <ClassifierForm classifier={classifier} form={classifierForm} closeModal={onClose} />
      </Modal.Content>
      <Modal.Footer>
        <Space justify="end">
          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={classifierForm.submit}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
