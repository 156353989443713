import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  Space,
  Row,
  Col,
  useNotify,
  useForm,
} from 'ebs-design';
import { useMutation } from 'react-query';
import { useIntl } from 'estafette-intl';
import { users } from 'api';
import { Tokens, UserSignIn } from 'types';
import { UserContext } from 'contexts';
import { extractResponseProps, getRoute } from 'utils';

import AuthenticationModal from '../AuthenticationModal';
import RequestCallModal from '../RequestCallModal';
import { stringifyUrl } from 'libs';
import { routes } from 'routes';

export const SignInPage: React.FC = () => {
  const { t } = useIntl();
  const [form] = useForm();
  const { onVerifyUser } = React.useContext(UserContext);
  const notify = useNotify();

  // Login request
  const loginRequest = useMutation<Tokens, unknown, UserSignIn>(
    (formData) => users.login(formData),
    {
      onSuccess: (tokens: Tokens) => tokens && onVerifyUser(tokens),
      onError: (err) =>
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        ),
    },
  );

  const handleSubmit = (data: UserSignIn) => {
    loginRequest.mutate(data);

    if (data.remember_me) {
      localStorage.setItem('remember', JSON.stringify(data.remember_me));
    } else {
      localStorage.removeItem('remember');
    }
  };

  return (
    <AuthenticationModal
      title={t('loggin_in')}
      description={t('access_your_account')}
      footer={
        <>
          {t('not_have_account')}{' '}
          <Link to={stringifyUrl(getRoute(routes, 'SignUpPage'))}>{t('creact_account_now')}</Link>
        </>
      }
    >
      <Form
        form={form}
        type="horizontal"
        onFinish={handleSubmit}
        controlOptions={{ col: { size: 9 } }}
        labelOptions={{ col: { size: 3 } }}
      >
        <Form.Field name="email" label={t('email')}>
          <Input type="email" />
        </Form.Field>

        <Form.Field name="password" label={t('password')}>
          <Input type="password" />
        </Form.Field>

        <Row>
          <Col size={3}></Col>

          <Col size={9}>
            <Space justify="space-between">
              <Form.Field
                name="remember_me"
                valuePropName="checked"
                initialValue={localStorage.getItem('remember') !== null}
              >
                <Checkbox text={t('remember_me')} />
              </Form.Field>

              <Form.Field className="authentication-modal-form-redirect">
                <>
                  <Icon type="lock" />
                  <Link to={stringifyUrl(getRoute(routes, 'ResetPasswordPage'))}>
                    {t('forgot_password')}
                  </Link>
                </>
              </Form.Field>
            </Space>
          </Col>
        </Row>

        <Row className="mt-15">
          <Col size={3}></Col>

          <Col size={9}>
            <div className="authentication-modal-form-actions">
              <Space justify="space-between">
                <Button type="primary" submit buttonClass="uppercase" className="full-width">
                  {t('send')}
                </Button>

                <RequestCallModal>
                  <Button type="ghost" buttonClass="uppercase" className="full-width">
                    {t('request_call')}
                  </Button>
                </RequestCallModal>
              </Space>
            </div>
          </Col>
        </Row>
      </Form>
    </AuthenticationModal>
  );
};
