import { Properties, Route } from 'types';
import { routes } from 'routes';

export const parseObject = (obj: { [key: string]: any }): string => {
  const str: string[] = [];

  for (const p in obj) {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p]) || ''}`);
  }

  return `${str.length > 0 ? '?' : ''}${str.join('&')}`;
};

export const getRoute = (routes: Route[], name: string, params?: Properties): string => {
  const route = routes.find((item) => item.name === name);

  if (route) {
    let { path } = { ...route };

    if (params) {
      Object.keys(params).map((key) => (path = path.replace(`:${key}`, params[key])));

      return params.query ? `${path}${parseObject(params.query)}` : path;
    }

    return path;
  }

  return '';
};

export const isRouteActive = (
  pathname: string,
  params: Properties,
  names: string | string[],
  defaultParams?: Properties,
): boolean => {
  if (Array.isArray(names)) {
    return (
      names.filter((name) => pathname === getRoute(routes, name, { ...params, ...defaultParams }))
        .length > 0
    );
  }

  return pathname === getRoute(routes, names, { ...params, ...defaultParams });
};
