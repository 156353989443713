import * as React from 'react';
import { useQuery } from 'react-query';
import { company } from 'api';
import { FullCompany } from 'types';
import { UserContext } from 'contexts';

interface Props {
  data?: FullCompany;
  isLoading: boolean;
}

export const useCompany = (id?, refetchOnMount = false): Props => {
  const { user } = React.useContext(UserContext);

  const enabled = React.useMemo(() => !!id || !!user?.company, [id, user]);

  const { data, isLoading, isFetching } = useQuery(
    ['use-company', id],
    () => company.get((id || user?.company?.id) as number),
    { enabled, refetchOnReconnect: true, refetchOnMount },
  );

  return { data: enabled ? data : undefined, isLoading: isLoading || isFetching };
};
