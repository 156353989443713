export const company = {
  general_data: {
    en: 'General data',
    ro: 'Date generale',
    ru: 'Общие данные',
  },
  users_data: {
    en: 'Users data',
    ro: 'Date utilizatori',
    ru: 'Данные пользователей',
  },
  founders_data: {
    en: 'Founders data',
    ro: 'Date fondatori',
    ru: 'Данные основателей',
  },
  legal_entities: {
    en: 'Legal entities',
    ro: 'Persoane juridice',
    ru: 'Юридические лица',
  },
  individuals: {
    en: 'Individuals',
    ro: 'Persoane fizice',
    ru: 'Физические лица',
  },
  legal_entity: {
    en: 'Legal entity',
    ro: 'Entitate legală',
    ru: 'Юридическое лицо',
  },
  add_legal_entity: {
    en: 'Add legal entity',
    ro: 'Adaugă persoană juridică',
    ru: 'Добавить юридическое лицо',
  },
  edit_legal_entity: {
    en: 'Edit legal entity',
    ro: 'Editare persoană juridică',
    ru: 'Редактировать юридическое лицо',
  },
  individual: {
    en: 'Individual',
    ro: 'Persoană fizică',
    ru: 'Физическое лицо',
  },
  add_individual: {
    en: 'Add an individual',
    ro: 'Adaugă o persoană',
    ru: 'Добавить физическое лицо',
  },
  edit_individual: {
    en: 'Edit an individual',
    ro: 'Editare persoană fizică',
    ru: 'Редактировать физическое лицо',
  },
  beneficiaries_data: {
    en: 'Effective beneficiaries',
    ro: 'Beneficiarii efectivi',
    ru: 'Эффективные бенефициары',
  },
  company_data: {
    en: 'Company data',
    ro: 'Date companie',
    ru: 'Данные компании',
  },
  company_name: {
    en: 'Company name',
    ro: 'Denumirea companiei',
    ru: 'Название компании',
  },
  vat_code: {
    en: 'VAT Code',
    ro: 'Cod TVA',
    ru: 'Код НДС',
  },
  organizational_form: {
    en: 'Organizational form',
    ro: 'Forma organizatorică',
    ru: 'Организационная форма',
  },
  field_of_activity: {
    en: 'Field of activity',
    ro: 'Domeniul de activitate',
    ru: 'Сфера деятельности',
  },
  factoring_range: {
    en: 'Monthly volume of factoring operations',
    ro: 'Volum lunar de operațiuni factoring',
    ru: 'Ежемесячный объем факторинговых операций',
  },
  company_clients: {
    en: 'Clients',
    ro: 'Clienți companie',
    ru: 'Клиенты компании',
  },
  company_address: {
    en: 'Company addresses',
    ro: 'Adrese companie',
    ru: 'Адреса компаний',
  },
  complete_address: {
    en: 'Complete address',
    ro: 'Adresa completă',
    ru: 'Полный адрес',
  },
  position: {
    en: 'Location',
    ro: 'Amplasare',
    ru: 'Место расположения',
  },
  attorney_power: {
    en: 'Power of attorney',
    ro: 'Act de împuternicire',
    ru: 'Доверенность',
  },
  identity_card: {
    en: 'Identity card',
    ro: 'Act de identitate',
    ru: 'Удостоверение личности',
  },
  degree_of_kinship: {
    en: 'Degree of kinship',
    ro: 'Modul de asociere',
    ru: 'Степень родства',
  },
  effective_beneficiary: {
    en: 'Effective beneficiary',
    ro: 'Beneficiar efectiv',
    ru: 'Эффективный бенефициар',
  },
  add_beneficiary: {
    en: 'Add effective beneficiary',
    ro: 'Adăugați beneficiar efectiv',
    ru: 'Добавить эффективного получателя',
  },
  occupation: {
    en: 'Occupation',
    ro: 'Ocupația',
    ru: 'Род занятий',
  },
  politically_exposed: {
    en: 'Is the person politically exposed?',
    ro: 'Este persoana expusă politic?',
    ru: 'Является ли человек политически значимым?',
  },
  family_members: {
    en: 'Family members of a Politically Exposed Person?',
    ro: 'Membrii familiei unei Persoane Expuse Politic?',
    ru: 'Члены семьи политически значимого человека?',
  },
  add_family_member: {
    en: 'Add a family member of the PEP',
    ro: 'Adaugă membru al familiei PEP',
    ru: 'Добавить члена семьи ПЗЧ',
  },
  associates_members: {
    en: 'Persons Associated with a Politically Exposed Person?',
    ro: 'Persoane asociate unei Persoane Expuse Politic?',
    ru: 'Лица, связанные с политически значимым лицом?',
  },
  add_associates_member: {
    en: 'Add person associated with PEP',
    ro: 'Adaugă persoană asociată unei PEP',
    ru: 'Добавить связанного человека ПЗЧ',
  },
  identity_documents: {
    en: 'Identity documents',
    ro: 'Acte de indentitate',
    ru: 'Удостоверение личности',
  },
  document_type: {
    en: 'Document type',
    ro: 'Tipul documentului',
    ru: 'Тип документа',
  },
  country_origin: {
    en: 'Country origin',
    ro: 'Originea țării',
    ru: 'Страна происхождения',
  },
  office: {
    en: 'Office',
    ro: 'Sediul',
    ru: 'Офис',
  },
  amount_of_share: {
    en: 'Amount of share',
    ro: 'Cuantumul părții sociale',
    ru: 'Размер доли',
  },
  full_title: {
    en: 'Full title',
    ro: 'Denumirea completă',
    ru: 'Полное название',
  },
  select_credit_officer: {
    en: 'Please select the credit officer',
    ro: 'Vă rugăm să selectați ofițerul de credit',
    ru: 'Пожалуйста, выберите кредитного специалиста',
  },
  select_source_info: {
    en: 'Please select source of information',
    ro: 'Vă rugăm să selectați source of information',
    ru: 'Пожалуйста, выберите источник информации',
  },
  information_source: {
    en: 'Information source',
    ro: 'Sursa de informare',
    ru: 'Источник информации',
  },
  politically_exposed_info: {
    en: 'Politically exposed persons are natural persons who hold or have held important public office.',
    ro: 'Persoane expuse politic sunt persoanele fizice care exercită sau au exercitat funcții public importante.',
    ru: 'Политически значимые лица - это физические лица, которые занимают или занимали важные государственные должности.',
  },
  associates_warning: {
    en: 'To be able to continue, please add at least one Legal entity or Individual!',
    ro: 'Pentru a putea continua, vă rugăm să adăugați cel puțin o persoană juridică sau o persoană fizică!',
    ru: 'Чтобы продолжить, добавьте хотя бы одно юридическое или физическое лицо!',
  },
  users_warning: {
    en: 'To be able to continue, please add at least one user!',
    ro: 'Pentru a putea continua, vă rugăm să adăugați cel puțin o persoană juridică sau o persoană fizică!',
    ru: 'Чтобы продолжить, добавьте хотя бы одного пользователя!',
  },
  admin_warning: {
    en: 'In order to continue, please upload your administrator ID!',
    ro: 'Pentru a putea continua, vă rugăm să încărcați actul de identitate pentru administrator!',
    ru: 'Чтобы продолжить, загрузите свой ID администратора!',
  },
  coincidence: {
    en: 'Coincidence',
    ro: 'Coincidență',
    ru: 'Совпадение',
  },
  create_adherent: {
    en: 'Create adherent',
    ro: 'Creați aderent',
    ru: 'Создать cторонник',
  },
  confirm_edit_company_information: {
    en: 'I confirm that it is the same company and I want to edit the information.',
    ro: 'Confirm că este aceeași companie și doresc editare informației.',
    ru: 'Я подтверждаю, что это та же самая компания и хочу отредактировать информацию.',
  },
  do_you_want_to_set_new_company_rate: {
    en: 'Do you want to set rates according to the new associated category?',
    ro: 'Doriți să stabiliți tarife in conformitate cu noua categorie asociata?',
    ru: 'Хотите установить тарифы в соответствии с новой связанной категорией?',
  },
  change_manager: {
    en: 'Change manager',
    ro: 'Modifică managerul',
    ru: 'Сменить менеджера',
  },
};
