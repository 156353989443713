import { SystemRole as AllSystemRole, CustomerRole } from 'types';

const excludeSystemRoles = [AllSystemRole.SY_IT_MANAGER];

const SystemRole = Object.fromEntries(
  Object.entries(AllSystemRole).filter((roles) => !excludeSystemRoles.includes(roles[1])),
);

export default {
  read: {
    home: [...Object.keys(AllSystemRole), ...Object.keys(CustomerRole)],
    users: Object.keys(CustomerRole),
    adherents: Object.keys(SystemRole),
    debtors: [...Object.keys(SystemRole), ...Object.keys(CustomerRole)],
    requests: [SystemRole.SY_RISK_MANAGER, SystemRole.SY_CREDIT_COMMITTEE],
    reports: [
      SystemRole.SY_ADMIN,
      SystemRole.SY_RISK_MANAGER,
      CustomerRole.ADMINISTRATOR,
      CustomerRole.ACCOUNTANT,
      CustomerRole.ADHERENT,
      CustomerRole.BENEFICIARY,
      CustomerRole.CHIEF_ACCOUNTANT,
      CustomerRole.CONTACT,
      CustomerRole.DEBTOR,
      CustomerRole.MANAGER,
      CustomerRole.REPRESENTATIVE,
      CustomerRole.RESPONSIBLE,
    ],
    invoices: [
      ...Object.keys(CustomerRole),
      ...Object.keys(SystemRole).filter((i) => i !== SystemRole.SY_CREDIT_COMMITTEE),
    ],
    notifications: [SystemRole.SY_COLLECTOR],
    penalties: [
      SystemRole.SY_CREDIT_OFFICER,
      SystemRole.SY_CREDIT_OFFICER_SUPERIOR,
      SystemRole.SY_RISK_MANAGER,
    ],
    'limits-and-rates': Object.keys(CustomerRole),
    'company-form': [...Object.keys(CustomerRole), ...Object.keys(SystemRole)].filter(
      (i) => !([CustomerRole.ADMINISTRATOR, CustomerRole.RESPONSIBLE] as string[]).includes(i),
    ),
    turnovers: [SystemRole.SY_ADMIN, SystemRole.SY_RISK_MANAGER],
  },
};
