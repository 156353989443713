import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  AvatarInline,
  Card,
  Collapse,
  Icon,
  ListGroup,
  Space,
  firstLetters,
  Loader,
  Button,
} from 'ebs-design';
import { company } from 'api';
import { Eye, Mail, Phone } from 'resources';
import { Layout } from 'components';
import { usePermissions, useCompanyUsers } from 'hooks';
import { SystemRole, Permissions, Passport, DocumentType, Attachment } from 'types';
import { routes } from 'routes';

import { AdherentProfile } from '../AdherentProfile';
import { AdherentLayout } from '../AdherentLayout';
import { AddConclusionModal } from '../AddConclusionModal';
import cn from 'classnames';
import { downloadFile, getRoute } from 'utils';

export const Adherent: React.FC = () => {
  const { t } = useIntl();
  const history = useHistory();
  const { id } = useParams();
  const can = usePermissions(Permissions.ADHERENTS);

  const { data, isLoading } = useQuery(['company', id], () => company.get(id), {
    enabled: Boolean(id),
    onError: (e: any) => {
      if (e?.response?.status === 404) {
        history.push(getRoute(routes, 'NotFoundPage'));
      }
    },
  });

  const { data: companyUsers } = useCompanyUsers({
    id,
    beneficiary: true,
    limit: 50,
  });

  const conclusions = React.useMemo(
    () => ({
      [SystemRole.SY_CREDIT_OFFICER]:
        data && data.request
          ? data.request.conclusions.filter((conclusion) =>
              conclusion.user.roles.some((role) => role.name === SystemRole.SY_CREDIT_OFFICER),
            )
          : [],
      [SystemRole.SY_RISK_MANAGER]:
        data && data.request
          ? data.request.conclusions.filter((conclusion) =>
              conclusion.user.roles.some((role) => role.name === SystemRole.SY_RISK_MANAGER),
            )
          : [],
    }),
    [data],
  );

  const handleDownloadPassport = (
    passport?: Passport,
    attachment?: Attachment,
    preview?: boolean,
  ): void => {
    if (attachment?.url) {
      downloadFile(attachment?.url, attachment?.name, preview);
      return;
    }

    if (passport?.attachment?.url) {
      downloadFile(passport.attachment?.url, passport.attachment?.name);
      return;
    }
  };

  return (
    <Layout>
      <AdherentLayout>
        <Loader loading={isLoading}>
          {data && <AdherentProfile data={data} />}

          <Collapse className="company-details__collapse">
            <Collapse.Header>
              <Space justify="space-between">
                <h4 className="card-title">{`${t('conclusion')} ${t(
                  SystemRole.SY_RISK_MANAGER,
                )}`}</h4>

                {can.create?.conclusion &&
                  can.create.conclusion[SystemRole.SY_RISK_MANAGER] &&
                  data?.request && <AddConclusionModal id={data.request.id} />}
              </Space>
            </Collapse.Header>
            <Collapse.Body className="company-details__collapse__conclusions">
              <ListGroup>
                {conclusions[SystemRole.SY_RISK_MANAGER].length ? (
                  conclusions[SystemRole.SY_RISK_MANAGER].map((conclusion, i) => {
                    const title = [conclusion.user.first_name, conclusion.user.last_name].join(' ');

                    return (
                      <ListGroup.Item key={i}>
                        <Space justify="space-between">
                          {conclusion.comment}
                          <AvatarInline
                            circle
                            alt={title}
                            shortAlt={
                              (title && firstLetters(title)) ||
                              conclusion.user.email[0].toUpperCase()
                            }
                            description={conclusion.user.email}
                          />
                        </Space>
                      </ListGroup.Item>
                    );
                  })
                ) : (
                  <ListGroup.Item>
                    <Space justify="center">{t('no_data')}</Space>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Collapse.Body>
          </Collapse>

          <Collapse className="company-details__collapse">
            <Collapse.Header>
              <Space justify="space-between">
                <h4 className="card-title">{`${t('conclusion')} ${t(
                  SystemRole.SY_CREDIT_OFFICER,
                )}`}</h4>

                {can.create?.conclusion &&
                  can.create.conclusion[SystemRole.SY_CREDIT_OFFICER] &&
                  data?.request && <AddConclusionModal id={data.request.id} />}
              </Space>
            </Collapse.Header>
            <Collapse.Body className="company-details__collapse__conclusions">
              <ListGroup>
                {conclusions[SystemRole.SY_CREDIT_OFFICER].length ? (
                  conclusions[SystemRole.SY_CREDIT_OFFICER].map((conclusion, i) => {
                    const title = [conclusion.user.first_name, conclusion.user.last_name].join(' ');

                    return (
                      <ListGroup.Item key={i}>
                        <Space justify="space-between">
                          {conclusion.comment}
                          <AvatarInline
                            circle
                            alt={title}
                            shortAlt={
                              (title && firstLetters(title)) ||
                              conclusion.user.email[0].toUpperCase()
                            }
                            description={conclusion.user.email}
                          />
                        </Space>
                      </ListGroup.Item>
                    );
                  })
                ) : (
                  <ListGroup.Item>
                    <Space justify="center">{t('no_data')}</Space>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Collapse.Body>
          </Collapse>

          <Collapse className="company-details__collapse background">
            <Collapse.Header>
              <h4 className="card-title">{t('contact_data')}</h4>
            </Collapse.Header>
            <Collapse.Body>
              <div className="company-details__contact-cards">
                {data?.contacts_roles.length ? (
                  data?.contacts_roles.map((item, key) => (
                    <Card
                      key={key}
                      className={cn('company-details__contact-card', {
                        'has-passport': item.passport,
                      })}
                    >
                      <AvatarInline
                        circle
                        className="company-details__contact-avatar"
                        alt={`${item.first_name} ${item.last_name}`}
                        description={item.roles?.map((role) => t(role.name)).join(', ')}
                      />
                      <ul className="company-details__contact-list">
                        <li>
                          <Icon component={Mail} />
                          {item.email}
                        </li>
                        <li>
                          <Icon component={Phone} />
                          {item.phone}
                        </li>
                      </ul>

                      <div className="company-details__contact-passports">
                        {item?.passport &&
                        item?.passport?.doc_type === DocumentType.INTERNAL_PASSPORT ? (
                          item?.passport?.attachments?.map((passport) => (
                            <Button
                              className="company-details__buttons"
                              size="small"
                              block
                              key={passport?.id}
                              onClick={() => handleDownloadPassport(passport, passport, true)}
                            >
                              <Icon component={Eye} />
                              {t(item?.passport?.doc_type?.toLowerCase() || 'passport')}
                            </Button>
                          ))
                        ) : (
                          <Button
                            onClick={() => handleDownloadPassport(item?.passport)}
                            className="company-details__contact-passport"
                          >
                            <Icon component={Eye} />
                            {t(item?.passport?.doc_type?.toLowerCase() || 'passport')}
                          </Button>
                        )}
                      </div>
                    </Card>
                  ))
                ) : (
                  <Space justify="center">{t('no_data')}</Space>
                )}
              </div>
            </Collapse.Body>
          </Collapse>

          <Collapse className="company-details__collapse background">
            <Collapse.Header>
              <h4 className="card-title">{t('actual_beneficiaries')}</h4>
            </Collapse.Header>
            <Collapse.Body>
              <div className="company-details__contact-cards">
                {companyUsers?.results.length ? (
                  companyUsers.results.map((item, key) => (
                    <Card
                      key={key}
                      className={cn('company-details__contact-card', {
                        'has-passport': item.passport,
                      })}
                    >
                      <AvatarInline
                        circle
                        className="company-details__contact-avatar"
                        alt={`${item.first_name} ${item.last_name}`}
                        description={item.roles?.map((role) => t(role.name)).join(', ')}
                      />
                      <ul className="company-details__contact-list">
                        <li>
                          <Icon component={Mail} />
                          {item.email}
                        </li>
                        <li>
                          <Icon component={Phone} />
                          {item.phone}
                        </li>
                      </ul>
                      <div className="company-details__contact-passports">
                        {item?.passport &&
                        item?.passport?.doc_type === DocumentType.INTERNAL_PASSPORT ? (
                          item?.passport?.attachments?.map((passport) => (
                            <Button
                              className="company-details__buttons"
                              size="small"
                              block
                              key={passport?.id}
                              onClick={() => handleDownloadPassport(passport, passport, true)}
                            >
                              <Icon component={Eye} />
                              {t(item?.passport?.doc_type?.toLowerCase() || 'passport')}
                            </Button>
                          ))
                        ) : (
                          <Button
                            onClick={() => handleDownloadPassport(item.passport)}
                            className="company-details__contact-passport"
                          >
                            <Icon component={Eye} />
                            {t(item?.passport?.doc_type?.toLowerCase() || 'passport')}
                          </Button>
                        )}
                      </div>
                    </Card>
                  ))
                ) : (
                  <Space justify="center">{t('no_data')}</Space>
                )}
              </div>
            </Collapse.Body>
          </Collapse>

          <Collapse className="company-details__collapse">
            <Collapse.Header>
              <h4 className="card-title">{t('bank_details')}</h4>
            </Collapse.Header>
            <Collapse.Body>
              <div className="company-details__bank">
                <div className="company-details__bank__cell">
                  <div className="company-details__bank__label">{t('bank')}</div>
                  <div className="company-details__bank__value">
                    {data?.additional_data?.bank?.title || '---'}
                  </div>
                </div>
                <div className="company-details__bank__cell">
                  <div className="company-details__bank__label">{t('iban')}</div>
                  <div className="company-details__bank__value">{data?.iban || '---'}</div>
                </div>
              </div>
            </Collapse.Body>
          </Collapse>
        </Loader>
      </AdherentLayout>
    </Layout>
  );
};
