export const alerts = {
  confirmation_code_phone: {
    en: `Confirmation code has been sent to your number!`,
    ro: `Codul de confirmare a fost trimis la numărul dvs.!`,
    ru: `Код подтверждения отправлен на ваш номер!`,
  },
  confirmation_link_email: {
    en: `Recovery link has been sent to your email!`,
    ro: `Link-ul de recuperare a fost trimis la adresa dvs. de e-mail!`,
    ru: `Ссылка для восстановления отправлена на вашу электронную почту!`,
  },
  success_password_change: {
    en: `Password has been successfully changed!`,
    ro: `Parola a fost schimbată cu succes!`,
    ru: `Пароль успешно изменен!`,
  },
  success_request_call: {
    en: `Request has been successfully sent`,
    ro: `Solicitarea a fost trimisă cu succes`,
    ru: `Запрос был успешно отправлен`,
  },
  success_data_change: {
    en: `Data was successfully updated!`,
    ro: `Datele au fost actualizate cu succes!`,
    ru: `Данные успешно обновлены!`,
  },
  success_data_save: {
    en: `Data was successfully saved!`,
    ro: `Datele au fost salvate cu succes!`,
    ru: `Данные успешно сохранены!`,
  },
  success_data_delete: {
    en: `Data was successfully deleted!`,
    ro: `Datele au fost șterse cu success!`,
    ru: `Данные успешно удалены!`,
  },
  success_invitations_send: {
    en: `Invitation was sent!`,
    ro: `Invitația a fost trimisă!`,
    ru: `Приглашение отправлено!`,
  },
  has_been_sent_for_payment: {
    en: `Has been sent for payment`,
    ro: `A fost trimis spre plată`,
    ru: `Отправлено на оплату`,
  },
  has_been_cancelled: {
    en: `Has been cancelled`,
    ro: `A fost anulat`,
    ru: `Был отменен`,
  },
  has_been_declined: {
    en: `Has been declined`,
    ro: `A fost refuzat`,
    ru: `Был отклонен`,
  },
  has_been_approved: {
    en: `Has been approved`,
    ro: `A fost aprobat`,
    ru: `Был одобрен`,
  },
  has_been_sent_approve: {
    en: `Has been sent to approve`,
    ro: `A fost trimisă spre aprobare`,
    ru: `Отправлено на утверждение`,
  },
  detail: {
    en: `Detail`,
    ro: `Detaliu`,
    ru: `Деталь`,
  },
  username: {
    en: `Email`,
    ro: `Email`,
    ru: `Почта`,
  },
  [`No active account found with the given credentials`]: {
    en: `No active account found with the given credentials`,
    ro: `Nu s-a găsit niciun cont activ cu acreditările date`,
    ru: `Активная учетная запись с указанными учетными данными не найдена`,
  },
  [`Confirm password does not match.`]: {
    en: `Confirm password does not match.`,
    ro: `Confirmați parola nu se potrivește.`,
    ru: `Подтверждение пароля не совпадает.`,
  },
  error_data_change: {
    en: `Data was not successfully updated!`,
    ro: `Datele nu au fost actualizate cu succes!`,
    ru: `Данные небыли успешно обновлены!`,
  },
  contract_upload_success: {
    en: `Your contract has been successfully uploaded!`,
    ro: `Contractul dvs. a fost încărcat cu succes!`,
    ru: `Ваш контракт успешно загружен!`,
  },
  contract_signed_success: {
    en: 'Contract successfully signed by both parties.',
    ro: 'Contract semnat cu succes de către ambele părți.',
    ru: 'Контракт успешно подписан обеими сторонами.',
  },
  contract_confirmed: {
    en: 'Contract confirmed',
    ro: 'Contract confirmat',
    ru: 'Контракт подтвержден',
  },
  failed_information_extracted: {
    en: 'The information cannot be extracted',
    ro: 'Informația nu poate fi extrasă',
    ru: 'Информация не может быть извлечена',
  },
  field_not_extracted: {
    en: 'This field could not be extracted',
    ro: 'Acest cîmp nu a putut fi extras',
    ru: 'Это поле не может быть извлечено',
  },
};
