import * as React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Container, Loader } from 'ebs-design';
import { invoices } from 'api/invoices';
import { Layout } from 'components/organisms';
import { InvoiceProfile } from '../InvoiceProfile';
import { InvoiceConditions } from '../InvoiceConditions';
import { MandatoryDocuments } from '../MandatoryDocuments';
import { OtherDocuments } from '../OtherDocuments';
import { SignDocuments } from '../SignDocuments';

export const Invoice: React.FC = () => {
  const { id } = useParams();

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['invoice', id],
    () => invoices.get(id as string),
    {
      enabled: !!id,
    },
  );

  return (
    <Layout>
      <Container>
        <Loader loading={isLoading || isFetching}>
          <InvoiceProfile data={data} />

          <InvoiceConditions data={data} />

          <MandatoryDocuments data={data} />

          <OtherDocuments data={data} onUpdate={refetch} />

          <SignDocuments data={data} onUpdate={refetch} />
        </Loader>
      </Container>
    </Layout>
  );
};
