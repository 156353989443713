import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { Card, Loader, Table, Space, SortBy } from 'ebs-design';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { invoices } from 'api';
import { InvoiceStatus } from 'types';
import { defaultFilters, getSortOptions } from 'utils';
import { Pagination } from 'components';
import { useInvoiceColumns } from 'features/invoices/useInvoiceColumns';

export const InvoicesTable: React.FC = () => {
  const { t } = useIntl();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });

  React.useEffect(() => updateQuery({ ...filters }), [filters, updateQuery]);

  const { data, isLoading } = useQuery(
    ['invoices', { ...filters, status: InvoiceStatus.REMAINED.toLowerCase() }],
    invoices.getList,
  );

  const columns = useInvoiceColumns();
  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  return (
    <div className="mt-15">
      <Space justify="space-between">
        <h2 className="page-title my-20">
          {t('top_outstanding_invoices')} ({data?.results.length || 0})
        </h2>

        <SortBy
          options={sortOptions}
          value={filters?.ordering}
          onChange={(ordering) => setFilters({ ordering })}
        />
      </Space>

      <Card>
        <Card.Body className="p-0">
          <Loader loading={isLoading}>
            <Table
              className="table-no-border"
              columns={columns}
              data={data?.results}
              emptyCell="---"
            />
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination data={data} filters={filters} setFilters={setFilters} />
        </Card.Footer>
      </Card>
    </div>
  );
};
