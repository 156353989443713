import { Role } from './users';
import { Penalty } from './invoices';

export enum AttachmentType {
  CONTRACT = 'CONTRACT',
  INVOICE = 'INVOICE',
  ASSIGNMENT_OF_RECEIVABLES = 'ASSIGNMENT_OF_RECEIVABLES',
  BULLETIN = 'BULLETIN',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  ANNEX = 'ANNEX',
  OTHER = 'OTHER',
  REPORT = 'REPORT',
  PICTURE = 'PICTURE',
  XML = 'XML',
  TEMPLATE = 'TEMPLATE',
}

export interface Attachment {
  file_name: string;
  mime_type: string;
  name: string;
  height: string;
  id: number;
  type: AttachmentType;
  url: string;
  width: string;
  signed?: boolean;
  signatures?: Signatures[];
}

export interface ContractAttachment extends Attachment {
  delivery_contract: {
    id?: number;
    timestamp?: string;
    edited_timestamp?: string;
    number?: string;
    date?: string;
    attachment?: number;
  };
}

export interface Signatures {
  id: number;
  signature_id: string;
  signed: boolean;
  signed_timestamp: string;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    name: string;
    email: string;
    roles: Role[];
  };
  data: { redirect_url: string };
}

export interface DeliveryContract {
  id: number;
  penalty?: Penalty;
  timestamp: string;
  edited_timestamp: string;
  number: string;
  date: string;
  attachment: number;
}

export enum DocumentType {
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  INTERNAL_PASSPORT = 'INTERNAL_PASSPORT',
  INTERNATIONAL_PASSPORT = 'INTERNATIONAL_PASSPORT',
}
