import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import {
  Button,
  Form,
  Input,
  Space,
  Icon,
  Modal,
  useForm,
  useNotify,
  DatePicker,
} from 'ebs-design';
import { contracts } from 'api';
import { ContractNumberList, Properties } from 'types';
import { extractResponseProps } from 'utils';
import { formatDate, getAPIDateFormat } from 'libs';

interface UpdateNumberModalProps {
  data: Partial<ContractNumberList>;
  visible: boolean;
  onClose: () => void;
}

export const UpdateNumberModal: React.FC<UpdateNumberModalProps> = ({ data, visible, onClose }) => {
  const { t } = useIntl();
  const [form] = useForm();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const { mutate: changeContractNumber, isLoading: isLoadingContractMutate } = useMutation(
    (data: Properties) => contracts.patchNumber(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contracts-number']);
        queryClient.invalidateQueries(['contracts-list']);
        handleClose();
        notify.success({ title: t('contracts'), description: t('success_data_change') });
      },
      onError: (err) => {
        extractResponseProps(err, (title, description) =>
          notify.error({ title: t(title), description: t(description) }),
        );
      },
    },
  );

  const handleSubmit = (values) => {
    changeContractNumber({
      id: data?.id,
      number: values?.number,
      number_instance: { order: values?.number_instance },
      timestamp: new Date(getAPIDateFormat(values?.timestamp)),
    });
  };

  return (
    <Modal open={visible} onClose={handleClose} title={t('contracts_number')} size="small">
      <Modal.Content className="px-20 py-30">
        <Form
          form={form}
          type="horizontal"
          onFinish={handleSubmit}
          labelOptions={{ col: { size: 4 } }}
          controlOptions={{ col: { size: 7 } }}
          fieldRow={{ gy: 3 }}
        >
          <Form.Field
            initialValue={data?.number}
            name="number"
            label={t('contracts_number')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>

          <Form.Field
            initialValue={data?.number_instance?.order}
            name="number_instance"
            label={t('order')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>

          <Form.Field
            initialValue={formatDate(data?.timestamp)}
            name="timestamp"
            label={t('created_at')}
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleClose}>{t('cancel')}</Button>

          <Button
            loading={isLoadingContractMutate}
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
