import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import {
  Container,
  Table,
  AvatarInline,
  Card,
  Space,
  Button,
  ButtonGroup,
  InputSearch,
  Icon,
  SortBy,
  Actions,
  Loader,
  firstLetters,
  Label,
  Badge,
  useNotify,
} from 'ebs-design';
import { Layout, Pagination, ConfirmationModal } from 'components/organisms';
import { UserContext } from 'contexts';
import { company } from 'api';
import { useQueryParams, useQueryUpdate, usePermissions } from 'hooks';
import { formatDate, dateTimeFormat } from 'libs';
import { Results, User, Column, Permissions } from 'types';
import { defaultFilters, getSortOptions, extractResponseProps } from 'utils';
import { Plus } from 'resources';

import InviteModal from '../InviteModal';
import EditUserModal from '../EditUserModal';

enum UserView {
  LIST = 'list',
  INVITATIONS = 'invitations',
}

export const Users: React.FC = () => {
  const { t } = useIntl();
  const notify = useNotify();
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const can = usePermissions(Permissions.USERS);
  const { user } = React.useContext(UserContext);
  const { updateQuery } = useQueryUpdate();
  const [view, setView] = React.useState<UserView>(UserView.LIST);
  const [edit, setEdit] = React.useState<User<string[]> | undefined>();
  const [sended, setSended] = React.useState(0);
  const [confirmDelete, setConfirmDelete] = React.useState<User<string[]> | undefined>();
  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });

  React.useEffect(() => updateQuery(filters), [filters, updateQuery]);

  const enabled = React.useMemo(() => !!user?.company?.id, [user]);

  const { data, isLoading } = useQuery(
    ['users', { companyId: user!.company?.id, ...filters }],
    company.getUsers,
    {
      enabled: enabled && view === UserView.LIST,
    },
  );

  const { data: dataInvitations, isLoading: isLoadingInvitations } = useQuery(
    ['users_invitations', user!.company?.id, filters],
    company.getUsersInvitations,
    {
      enabled: enabled,
    },
  );

  const resendInvitation = useMutation(company.resendInvitation, {
    onSuccess: () => {
      notify.success({ title: t('invitations'), description: t('success_invitations_send') });
    },
  });

  const deleteUser = useMutation(company.deleteUser, {
    onMutate: async ({ userId }) => {
      const query = ['users', { companyId: user!.company?.id, ...filters }];
      const prevData = queryClient.getQueryData(query) as Results<User<string[]>>;

      queryClient.setQueryData(query, {
        ...prevData,
        results: prevData.results.filter((i) => i.id !== userId),
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onError: (err, _, rollback: any) => {
      rollback();
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      notify.success({ title: t('users'), description: t('success_data_delete') });
    },
  });

  const onChangeStatus = React.useCallback(
    (is_active?: boolean) => setFilters(() => ({ is_active })),
    [setFilters],
  );

  const isList = React.useMemo(() => view === UserView.LIST, [view]);

  const onResend = React.useCallback(
    (companyId: number, id: number) => {
      setSended(id);
      resendInvitation.mutate({ companyId, id });
    },
    [resendInvitation],
  );

  const columns: Column<User<string[]>>[] = React.useMemo(
    () => [
      {
        title: t('name'),
        filter: 'first_name',
        render: ({ first_name, last_name, email }) => {
          const title = [first_name, last_name].join(' ');

          return (
            <AvatarInline
              circle
              alt={title}
              shortAlt={(title && firstLetters(title)) || (email && email[0].toUpperCase())}
              description={email}
            />
          );
        },
        width: 250,
      },
      {
        title: t('roles'),
        render: ({ roles }) => roles?.map((role) => t(role.name || role)).join(', ') || '---',
      },
      {
        title: t('status'),
        render: ({ is_active }) => (
          <Label
            type="fill"
            status={is_active ? 'success' : 'danger'}
            circle
            text={t(is_active ? 'active' : 'inactive')}
          />
        ),
      },
      ...(isList
        ? [
            {
              title: t('registred_at'),
              render: ({ date_joined }) => date_joined && formatDate(date_joined, dateTimeFormat),
            },
          ]
        : []),
      ...(isList
        ? [
            {
              title: 'Actions',
              action: true,
              render: (props) => (
                <Actions>
                  <Actions.Item onClick={() => setEdit(props)}>{t('edit')}</Actions.Item>
                  <Actions.Item onClick={() => setConfirmDelete(props)}>{t('delete')}</Actions.Item>
                </Actions>
              ),
            },
          ]
        : [
            {
              title: null,
              render: (props) => (
                <Space justify="end">
                  <Button
                    type="fill"
                    loading={sended === props.id && resendInvitation.isLoading}
                    onClick={() => onResend(props.company.id, props.id)}
                  >
                    {t('resend')}
                  </Button>
                </Space>
              ),
            },
          ]),
    ],
    [t, isList, onResend, sended, resendInvitation.isLoading],
  );

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  const showData = React.useMemo(
    () => ({
      count: (enabled && (isList ? data?.count : dataInvitations?.count)) || 0,
      data: isList ? data : dataInvitations,
    }),
    [enabled, isList, data, dataInvitations],
  );

  const hasCompany = React.useMemo(() => !!user?.company, [user]);

  const confirmDeleteTitle = React.useMemo(
    () =>
      `${t('user')} ${[
        confirmDelete?.first_name,
        confirmDelete?.last_name,
        confirmDelete?.patronymic,
      ].join(' ')}`,
    [t, confirmDelete],
  );

  const onAbortDelete = () => setConfirmDelete(undefined);

  const onConfirmDelete = React.useCallback(() => {
    deleteUser.mutate({ companyId: user!.company?.id, userId: confirmDelete!.id });

    onAbortDelete();
  }, [deleteUser, confirmDelete, user]);

  const onChangeView = React.useCallback(
    (type: UserView) => {
      setView(type);
      setFilters({ page: 1 });
    },
    [setFilters],
  );

  return (
    <Layout>
      <Container>
        <Space align="center" justify="space-between" className="mt-5 mb-20">
          <Space align="center">
            <h3 className="page-title">
              {t('users')} ({showData.count})
            </h3>
            <InputSearch
              placeholder={t('search')}
              styleType="fill"
              value={filters.search}
              onSearch={(search) => setFilters({ search, page: 1 })}
              isClearable
            />
          </Space>
          <Space align="center">
            {isList && (
              <ButtonGroup>
                <Button
                  type={filters?.is_active === undefined ? 'primary' : 'ghost'}
                  onClick={() => onChangeStatus()}
                >
                  {t('all')}
                </Button>

                <Button
                  type={filters?.is_active === true ? 'primary' : 'ghost'}
                  onClick={() => onChangeStatus(true)}
                >
                  {t('active')}
                </Button>

                <Button
                  type={filters?.is_active === false ? 'primary' : 'ghost'}
                  onClick={() => onChangeStatus(false)}
                >
                  {t('inactive')}
                </Button>
              </ButtonGroup>
            )}

            <ButtonGroup>
              <Button
                type={isList ? 'primary' : 'ghost'}
                onClick={() => onChangeView(UserView.LIST)}
              >
                {t('list')}
              </Button>

              <Badge text={(dataInvitations?.count || 0).toString()} type="danger">
                <Button
                  type={!isList ? 'primary' : 'ghost'}
                  onClick={() => onChangeView(UserView.INVITATIONS)}
                >
                  {t('invitations')}
                </Button>
              </Badge>
            </ButtonGroup>

            <SortBy
              options={sortOptions}
              value={filters?.ordering}
              onChange={(ordering) => setFilters({ ordering })}
            />

            {can.perform?.invite && (
              <InviteModal filters={filters} onChangeFilters={setFilters}>
                <Button type="primary" prefix={<Icon component={Plus} />} disabled={!hasCompany}>
                  {t('invite_user')}
                </Button>
              </InviteModal>
            )}
          </Space>
        </Space>
        <Card>
          <Card.Body className="p-0">
            {isLoading || isLoadingInvitations ? (
              <Loader loading={isLoading || isLoadingInvitations} />
            ) : (
              <Table
                className="table-no-border"
                columns={columns}
                data={enabled ? showData.data?.results : []}
                emptyCell="---"
              />
            )}
          </Card.Body>
          <Card.Footer>
            <Pagination
              data={enabled ? showData.data : {}}
              filters={filters}
              setFilters={setFilters}
            />
          </Card.Footer>
        </Card>
      </Container>
      <EditUserModal
        edit={edit}
        filters={filters}
        visible={!!edit}
        onClose={() => setEdit(undefined)}
      />
      {confirmDelete && (
        <ConfirmationModal
          title={confirmDeleteTitle}
          description={t('are_you_sure_to_want_delete')}
          onConfirm={onConfirmDelete}
          onCancel={onAbortDelete}
          visible={true}
        />
      )}
    </Layout>
  );
};
