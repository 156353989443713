import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Table } from 'ebs-design';
import { formatNumber } from 'utils';
import { Column, Currencies } from 'types';
import { InvoicesMonthlyChartItem } from '../interfaces';

export const InvoicesMonthlyTable: React.FC<{ data?: InvoicesMonthlyChartItem[] }> = ({
  data = [],
}) => {
  const { t } = useIntl();

  const columns: Column<InvoicesMonthlyChartItem>[] = React.useMemo(
    () => [
      {
        title: t('month'),
        dataIndex: 'name',
      },
      {
        title: t('amount'),
        dataIndex: 'total',
        render: (value) => formatNumber(value, '', 0),
      },
      {
        title: t('sum'),
        dataIndex: 'amount',
        render: (value) => formatNumber(value, Currencies.MDL),
      },
    ],
    [t],
  );

  return <Table data={data} columns={columns} emptyCell="---" scroll={{ y: 365 }} />;
};
