export const notifications = {
  send_notification: {
    en: `Send notification`,
    ro: `Trimiteți o notificare`,
    ru: `Отправить уведомление`,
  },
  notification_data: {
    en: `Notification data`,
    ro: `Informații despre notificare`,
    ru: `Данные уведомления`,
  },
  comment: {
    en: `Comment`,
    ro: `Comentariu`,
    ru: `Комментарий`,
  },
  notify_adherent: {
    en: `Notify adherent`,
    ro: `Notificare aderentul`,
    ru: `Уведомить сторонника`,
  },
  notify_collector: {
    en: `Collector notification`,
    ro: `Notificare colector`,
    ru: `Уведомление коллектора`,
  },
  notify_debtor: {
    en: `Debtor notification`,
    ro: `Notificare debitor`,
    ru: `Уведомление должника`,
  },
  send_notification_data: {
    en: `Notification was sent!`,
    ro: `Notificarea a fost trimisă!`,
    ru: `Уведомление отправлено!`,
  },
  non_field_errors: {
    en: `Error`,
    ro: `Eroare`,
    ru: `Ошибка`,
  },
  [`Passwords does not match`]: {
    en: `Passwords does not match`,
    ro: `Parolele nu se potrivesc`,
    ru: `Пароли не совпадают`,
  },
  [`This field may not be blank.`]: {
    en: `This field may not be blank`,
    ro: `Acest câmp nu poate fi gol`,
    ru: `Это поле не может быть пустым`,
  },
  [`User is not a member of request committee.`]: {
    en: `User is not a member of request committee.`,
    ro: `Utilizatorul nu este membru al comitetului de solicitare.`,
    ru: `Пользователь не является членом комиссии по запросам.`,
  },
};
