import * as React from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import { Loader, Container } from 'ebs-design';
import { Layout } from 'components';
import { requests } from 'api';
import { Adherent, Timer } from 'resources';
import { usePermissions } from 'hooks';
import { RequestCompany, Permissions } from 'types';
import { CompanyCard } from '../CompanyCard';
import { CompanyActions } from '../CompanyActions';
import { AdherentTable } from '../AdherentTable';
import { DebtorsTable } from '../DebtorsTable';
import { InvoicesTable } from '../InvoicesTable';
import { CommentsCard } from '../CommentsCard';
import { CreditCommitteeCard } from '../CreditCommitteeCard';
import { RequestActions } from '../RequestActions';

export const Request: React.FC = () => {
  const { t } = useIntl();
  const { id } = useParams();
  const can = usePermissions(Permissions.REQUESTS);
  const { data, isLoading } = useQuery(['request', id], () => requests.get(id as string), {
    enabled: !!id,
  });

  return (
    <Layout>
      <Container className="pt-20">
        <Loader loading={isLoading}>
          {data?.company && (
            <CompanyCard
              icon={<Adherent />}
              title={t('adherent')}
              description={data.company.title}
              actions={<CompanyActions id={data.company.id} />}
            >
              <AdherentTable data={data.company} />
            </CompanyCard>
          )}

          {data?.company?.companies.map((company: RequestCompany, idx) => (
            <CompanyCard
              key={company.id}
              icon={<Timer />}
              title={`${t('debtor')} ${idx + 1}`}
              description={company.title}
              actions={<CompanyActions id={company.id} />}
            >
              <DebtorsTable data={company} />
            </CompanyCard>
          ))}

          {data?.invoices && <InvoicesTable data={data.invoices} />}

          {data?.comments && <CommentsCard requestId={data.id} data={data.comments} />}

          {data?.committee && can.read?.creditCommittee && <CreditCommitteeCard data={data} />}

          {data && <RequestActions data={data} />}
        </Loader>
      </Container>
    </Layout>
  );
};
