import { FactoringType, InvoiceStatus, Attachment } from 'types';

export enum ReportType {
  COMPANIES_STATISTICS = 'companies-statistics',
  INVOICES_ACTIVITIES = 'invoices-activities',
  INVOICES_MONTHLY_STATUSES = 'invoices-monthly-statuses',
  INVOICES_RATIOS = 'invoices-ratios',
  INVOICES_SALES = 'invoices-sales',
  INVOICES_STATUSES = 'invoices-statuses',
  INVOICES_STATUSES_EXTENDS = 'invoices-statuses-extends',
  INVOICES_TOTALS = 'invoices-totals',
  SALES_PAYMENTS = 'sales-payments',
  USERS_SALES = 'users-sales',
  GRAPHS_BACKLOGS = 'graphs-backlogs',
  GRAPHS_DYNAMICS = 'graphs-dynamics',
  GRAPHS_INSPECTORS = 'graphs-inspectors',
  GRAPHS_PORTFOLIOS = 'graphs-portfolios',
  GRAPHS_SALES = 'graphs-sales',
}

export enum ReportCompanyType {
  INFODEBIT = 'infodebit',
  BIC = 'bic',
  KYC = 'know-your-client',
}

export interface ReportInvoicesRatios {
  amount: number;
  total: number;
  type: FactoringType | string;
  percentage: number;
  totals?: number;
  amounts?: number;
}

export interface ReportInvoicesActivities {
  amount: number;
  activity: string;
  total: number;
  totals: number;
  percentage: number;
}

export interface ReportInvoicesMonthlyStatuses {
  name: string; // month name
  month: number;
  amount_total: number;
  total: number;
}

export interface ReportInvoicesStatuses {
  status: InvoiceStatus;
  code_name: FactoringType;
  amount_average: number;
  amount_total: number;
  amount_commission_average: number;
  amount_commission_total: number;
  total: number;
  totals: number;
  percent: number;
  daily: number;
  monthly: number;
  weekly: number;
  yearly: number;
}

export interface ReportInvoicesStatusesExtends {
  amounts: number;
  averages: number;
  block: 'ACTIVE' | 'EXPIRED';
  commissions: number;
  counts: number;
  factoring: keyof typeof FactoringType | 'BREAKDOWN' | 'TOTAL';
  percent: number;
  range: string;
  total: number;
}

export interface ReportInvoicesSales {
  amount_average_total: number;
  amount_total: number;
  commission_average_total: number;
  commission_total: number;
  guarantee_average_total: number;
  guarantee_total: number;
  type: FactoringType;
  number: number;
}

export interface ReportInvoicesTotals {
  number_total: number;
  amount_total: number;
  amount_average_total: number;
  amount_commission_total: number;
  amount_commission_average: number;
  amount_guarantee_total: number;
  amount_guarantee_average: number;
  amount_commission_total_percent: number;
  amount_commission_average_percent: number;
  amount_guarantee_total_percent: number;
  amount_guarantee_average_percent: number;
  status: InvoiceStatus;
  type: FactoringType;
}

export interface ReportUsersSales {
  amount_total: number;
  amount_average_total: number;
  commission_total: number;
  commission_average_total: number;
  guarantee_total: number;
  guarantee_average_total: number;
  number: number;
  total: number;
  _email: string;
  _first_name: string;
  _last_name: string;
}

export interface ReportSalePayment {
  amount: number;
  number: number;
  days: number;
  irr: number;
  date: string;
  invoices_total: number;
  commission_amount_total: number;
  commission_percent_total: number;
  guarantee_amount_total: number;
  guarantee_return_amount_total: number;
  statistic_penalty_interest_total: number;
}

export interface ReportSalesPayments {
  sales: ReportSalePayment[];
  repayments: ReportSalePayment[];
}

export interface ReportCompanyStatistic {
  companies_daily: number;
  companies_monthly: number;
  companies_outstanding_daily: number;
  companies_outstanding_monthly: number;
  companies_outstanding_totals: number;
  companies_outstanding_weekly: number;
  companies_outstanding_yearly: number;
  companies_totals: number;
  companies_weekly: number;
  companies_yearly: number;
}

export interface ReportCompany {
  id: number;
  attachment: Attachment;
  timestamp: string;
  edited_timestamp: string;
  code_name: string;
  title: string;
  description: string;
  type: string;
  data: {
    id: number;
    xml: string;
    data: string;
    json: string;
    company: number;
    pdf_url: string;
    html_url: string;
    timestamp: string;
    edited_timestamp: string;
    timestamp_parsed: string;
  };
  xml: string;
  user: number;
  company: number;
}

export interface ReportInspectorGraph {
  invoices_amounts: number;
  invoices_counts: number;
  month: number;
  users_email: string;
  users_first_name: string;
  users_id: number;
  users_idnp: string;
  users_last_name: string;
  data?: { amount: number; count: number; month: number; name: string }[];
}

export interface ReportDynamicGraph {
  amounts: number;
  factoring: FactoringType;
  month: number;
}

export interface ReportBacklogGraph {
  invoices_amounts: number;
  invoices_average_weighted: number;
  invoices_percents_amounts: number;
  invoices_totals: number;
  month: number;
}

export interface ReportSalesGraph {
  expires_amount: number;
  expires_average: number;
  expires_total: number;
  invoices_amount: number;
  invoices_average: number;
  invoices_total: number;
  month: number;
  sales_amount: number;
  sales_average: number;
  sales_total: number;
}
