import { Company, DocumentType, Setting, SettingType } from 'types';
import { Attachment } from './attachment';
import { Vote } from './requests';

export interface Tokens {
  access_token: string;
  refresh_token: string;
}

export interface UserSignIn {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface ActivationUser {
  phone: string;
  idnp: string;
  password: string;
  confirm_password: string;
}

export interface UserSignUp {
  email: string;
  idnp: string;
  phone: string;
}

export interface User<T = Role[] | any> {
  id: number;
  idnp: string;
  first_name: string;
  last_name: string;
  passport: Passport | any;
  patronymic: any;
  phone: string;
  additional_data: any;
  attachments: any[];
  company: Company;
  company_id: number;
  is_active: boolean;
  beneficiary: boolean;
  email: string;
  roles: T;
  vote?: Vote;
  roles_id: number[];
  settings: Setting[];
  picture?: Attachment;
  messaging_id?: string;
}

export interface Passport {
  id: number;
  serial_number?: string;
  birthday?: string;
  place_of_birth?: string;
  doc_type: DocumentType;
  citizenship?: Citizenship;
  attachment?: Attachment;
  attachments?: Attachment[];
  receiving_office?: string;
  date_receipt?: string;
  available_to?: string;
}

export interface Citizenship {
  id: number;
  name?: string;
  phone?: string;
}

export interface Role {
  id: number;
  name: UserRole;
}

export enum CustomerRole {
  ADHERENT = 'ADHERENT',
  DEBTOR = 'DEBTOR',
  ADMINISTRATOR = 'ADMINISTRATOR',
  ACCOUNTANT = 'ACCOUNTANT',
  CHIEF_ACCOUNTANT = 'CHIEF_ACCOUNTANT',
  MANAGER = 'MANAGER',
  CONTACT = 'CONTACT',
  REPRESENTATIVE = 'REPRESENTATIVE',
  BENEFICIARY = 'BENEFICIARY',
  RESPONSIBLE = 'RESPONSIBLE',
}

export enum SystemRole {
  SY_ROOT = 'SY_ROOT',
  SY_ADMIN = 'SY_ADMIN',
  SY_RISK_MANAGER = 'SY_RISK_MANAGER',
  SY_CREDIT_COMMITTEE = 'SY_CREDIT_COMMITTEE',
  SY_CREDIT_OFFICER = 'SY_CREDIT_OFFICER',
  SY_CREDIT_OFFICER_SUPERIOR = 'SY_CREDIT_OFFICER_SUPERIOR',
  SY_COLLECTOR = 'SY_COLLECTOR',
  SY_IT_MANAGER = 'SY_IT_MANAGER',
}

export type UserRole = CustomerRole | SystemRole;

export interface UsersSetting {
  id: number;
  code_name: string;
  value: string;
  values: string[];
  period: string;
  state: boolean;
}

export interface UserSetting {
  id: number;
  timestamp: string;
  edited_timestamp: string;
  code_name: SettingType;
  title: string;
  description: boolean;
  value: string;
  values: string[];
  period: string;
}

export interface MemberEntity {
  degree_of_kinship_id: number;
  degree_of_kinship_alternative: string;
  edited_timestamp: string;
  first_name: string;
  function: string;
  function_end: string;
  function_start: string;
  id: number;
  idnp: string | number;
  last_name: string;
  roles: number[];
  timestamp: string;
}
