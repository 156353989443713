import { axios, cancelTokenHandler } from 'libs';
import { Properties, PaymentOrder, PaymentOrderCreate } from 'types';

export const payments = {
  get: async ({ queryKey }: Properties) => {
    const { data } = await axios.get<PaymentOrder>(`/payments-orders/${queryKey[1]}/`, {
      cancelToken: cancelToken(payments.get.name),
    });

    return data;
  },

  create: async (bodyData: Partial<PaymentOrderCreate>) => {
    const { data } = await axios.post<PaymentOrderCreate>(`/payments-orders/`, bodyData, {
      cancelToken: cancelToken(payments.create.name),
    });

    return data;
  },

  delete: async (id: number) => {
    return await axios.delete(`/payments-orders/${id}/`, {
      cancelToken: cancelToken(payments.delete.name),
    });
  },

  update: async (id: number, bodyData: Partial<PaymentOrder>) => {
    const { data } = await axios.patch<PaymentOrder>(`/payments-orders/${id}/`, bodyData, {
      cancelToken: cancelToken(payments.update.name),
    });

    return data;
  },
};

// creating the cancel token handler object
const cancelToken = cancelTokenHandler(payments);
