import * as React from 'react';
import { useIntl } from 'estafette-intl';
import { Link } from 'react-router-dom';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Icon,
  Label,
  Loader,
  SortBy,
  Space,
  Table,
} from 'ebs-design';
import { requests } from 'api';
import { Layout, Pagination, MultipleCheckBoxSelect } from 'components';
import { useQueryParams, useQueryUpdate, useColumns } from 'hooks';
import { formatDate, stringifyUrl } from 'libs';
import { Column, Request, Status, Invoice, RequestType, SystemRole } from 'types';
import { defaultFilters, getRoute, getSortOptions } from 'utils';

import { Filters } from '../Filters';
import { routes } from 'routes';

export const Requests: React.FC = () => {
  const { t } = useIntl();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const [filters, setFilters] = useSetState({
    ...defaultFilters,
    ...params,
  });

  React.useEffect(() => updateQuery(filters), [filters, updateQuery]);

  const { data, isLoading } = useQuery(
    [
      'requests',
      {
        ...filters,
        type: RequestType.INCREASE_LIMIT.toLowerCase(),
        stage__exclude: SystemRole.SY_CREDIT_OFFICER.toLowerCase(),
      },
    ],
    requests.getList,
  );

  const onSearch = React.useCallback(
    (search) => {
      const { period, ...dataFilters } = search;

      if (period && period.filter((i) => i).length) {
        dataFilters.timestamp__range = `${search.period[0]}__${search.period[1]}`;
      }

      setFilters(dataFilters);
    },
    [setFilters],
  );

  const columns: Column[] = React.useMemo(
    () => [
      {
        title: t('created_request'),
        dataIndex: 'timestamp',
        checked: true,
        filter: 'timestamp',
        render: (timestamp) => formatDate(timestamp),
      },
      {
        title: t('adherent_name'),
        dataIndex: ['company', 'title'],
        checked: true,
        filter: 'company_title',
      },
      {
        title: t('invoice_number'),
        dataIndex: 'invoices',
        checked: true,
        filter: 'invoices_number',
        render: (invoices: Invoice[]) =>
          invoices.map((i) => `${i.series || ''} ${i.number || ''}`).join(', '),
      },
      {
        title: t('sum_of_invoice'),
        dataIndex: 'invoices',
        checked: true,
        filter: 'invoices_amount',
        render: (invoices: Invoice[]) => invoices.map((i) => i.amount).join(', '),
      },
      {
        title: t('request_status'),
        dataIndex: 'status',
        checked: true,
        filter: 'status',
        render: (status) => (
          <Label status={Status[status]} text={t(status.toLowerCase())} type="fill" circle />
        ),
      },
      {
        title: t('request_stage'),
        dataIndex: 'stage',
        filter: 'stage',
        render: (stage) => t(stage),
      },
      {
        title: null,
        action: true,
        render: ({ id }: Request) => (
          <Space justify="end">
            <Link to={stringifyUrl(getRoute(routes, 'RequestDetails', { id }))}>
              <Button size="small" prefix={<Icon type="eye" />}>
                {t('request_card')}
              </Button>
            </Link>
          </Space>
        ),
      },
    ],
    [t],
  );

  const {
    onChange: onColumnsChange,
    getOptions,
    columns: selectedColumns,
  } = useColumns('requests', columns);

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  return (
    <Layout>
      <Filters onSearch={onSearch} />
      <Container>
        <Space justify="space-between" className="mt-20 mb-20">
          <Space>
            <h3 className="page-title">
              {t('requests')} ({data?.count || 0})
            </h3>
            <ButtonGroup>
              <Button
                type={!filters?.status || filters.status === '' ? 'primary' : 'ghost'}
                onClick={() => setFilters(() => ({ status: '', page: 1 }))}
              >
                {t('all')}
              </Button>

              <Button
                type={filters?.status === 'review' ? 'primary' : 'ghost'}
                onClick={() => setFilters(() => ({ status: 'review', page: 1 }))}
              >
                {t('pending')}
              </Button>

              <Button
                type={filters?.status === 'approved' ? 'primary' : 'ghost'}
                onClick={() => setFilters(() => ({ status: 'approved', page: 1 }))}
              >
                {t('approved')}
              </Button>

              <Button
                type={filters?.status === 'denied' ? 'primary' : 'ghost'}
                onClick={() => setFilters(() => ({ status: 'denied', page: 1 }))}
              >
                {t('denied')}
              </Button>
            </ButtonGroup>
          </Space>
          <Space>
            <MultipleCheckBoxSelect
              options={getOptions(columns)}
              placeHolder={t('choose_column')}
              label="title"
              callback={onColumnsChange}
            />

            <SortBy
              options={sortOptions}
              value={filters?.ordering}
              onChange={(ordering) => setFilters({ ordering })}
            />
          </Space>
        </Space>
        <Card>
          <Card.Body className="p-0">
            <Loader loading={isLoading}>
              <Table
                emptyCell="---"
                className="table-no-border"
                columns={selectedColumns}
                data={data?.results}
              />
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Pagination data={data} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </Container>
    </Layout>
  );
};
