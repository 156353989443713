import * as React from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { reports } from 'api';
import { Properties, ReportType } from 'types';

export const useReports = <TQueryFnData = unknown, TData = TQueryFnData, TError = unknown>(
  type: string,
  params?: Properties,
  queryOptions?: UseQueryOptions<TQueryFnData, TError, TData>,
): UseQueryResult<TData, TError> => {
  const reportByType = React.useMemo(
    () => ({
      [ReportType.COMPANIES_STATISTICS]: reports.getCompaniesStatistics,
      [ReportType.INVOICES_ACTIVITIES]: reports.getInvoicesActivities,
      [ReportType.INVOICES_MONTHLY_STATUSES]: reports.getInvoicesMonthlyStatuses,
      [ReportType.INVOICES_RATIOS]: reports.getInvoicesRatios,
      [ReportType.INVOICES_SALES]: reports.getInvoicesSales,
      [ReportType.INVOICES_STATUSES]: reports.getInvoicesStatuses,
      [ReportType.INVOICES_STATUSES_EXTENDS]: reports.getInvoicesStatusesExtends,
      [ReportType.INVOICES_TOTALS]: reports.getInvoicesTotals,
      [ReportType.SALES_PAYMENTS]: reports.getSalesPayments,
      [ReportType.USERS_SALES]: reports.getUsersSales,
      [ReportType.GRAPHS_BACKLOGS]: reports.getBacklogsGraphs,
      [ReportType.GRAPHS_DYNAMICS]: reports.getDynamicsGraphs,
      [ReportType.GRAPHS_INSPECTORS]: reports.getInspectorsGraphs,
      [ReportType.GRAPHS_PORTFOLIOS]: reports.getPortfoliosGraphs,
      [ReportType.GRAPHS_SALES]: reports.getSalesGraphs,
    }),
    [],
  );

  return useQuery<TQueryFnData, TError, TData>(
    ['reports', type, params],
    () => reportByType[type](params),
    queryOptions,
  );
};
