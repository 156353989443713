import * as React from 'react';
import { useMutation } from 'react-query';
import { useSetState } from 'react-use';
import { useIntl } from 'estafette-intl';
import { useHistory, Link } from 'react-router-dom';
import { Button, Form, Input, Row, Col, InputPhone, useForm, useNotify } from 'ebs-design';
import { users } from 'api';
import { Tokens, Properties } from 'types';

import AuthenticationModal from '../../AuthenticationModal';
import { stringifyUrl } from 'libs';
import { getRoute } from 'utils';
import { routes } from 'routes';

type ResetPassword = { email: string; phone: string };

export const ResetPasswordPage: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const [form] = useForm();
  const notify = useNotify();
  const [state, setState] = useSetState<Properties>({});

  const resetPasswordRequest = useMutation<Tokens, unknown, Properties>((formData) =>
    users.resetPassword(formData),
  );

  const handleSubmit = React.useCallback(
    (data: ResetPassword) => {
      resetPasswordRequest.mutate({
        value: data.email ? data.email : data.phone,
        reset_password_type: data.email ? 'email' : 'phone_number',
      });

      if (!data.email && data.phone.length > 4) {
        push(getRoute(routes, 'OTPVerifyPage'));

        notify.warning({
          title: t('password_reset'),
          description: t('confirmation_code_phone'),
        });
      } else {
        push(getRoute(routes, 'SignInPage'));

        notify.warning({
          title: t('password_reset'),
          description: t('confirmation_link_email'),
        });
      }
    },
    [resetPasswordRequest, notify, t, push],
  );

  const handleValuesChange = (field) => setState(field);

  const canSend = React.useMemo(() => state?.email?.length || state?.phone?.length > 4, [state]);

  return (
    <AuthenticationModal
      title={t('password_reset')}
      description={t('enter_your_email_to_receive_new_password')}
      footer={
        <>
          {t('already_registered')}{' '}
          <Link to={stringifyUrl(getRoute(routes, 'SignInPage'))}>{t('access_your_account')}</Link>
        </>
      }
    >
      <Form
        form={form}
        type="horizontal"
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
        controlOptions={{ col: { size: 9 } }}
        labelOptions={{ col: { size: 3 } }}
      >
        <Form.Field name="email" label={t('email')}>
          <Input type="email" />
        </Form.Field>
        <Row className="mb-15">
          <Col size={3}></Col>
          <Col size={9}>- {t('or')} -</Col>
        </Row>
        <Form.Field name="phone" label={t('phone_number')}>
          <InputPhone country="md" />
        </Form.Field>
        <Row>
          <Col size={3}></Col>
          <Col size={9}>
            <div className="authentication-modal-form-actions">
              <Button type="primary" submit buttonClass="uppercase" disabled={!canSend}>
                {t('send')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </AuthenticationModal>
  );
};
