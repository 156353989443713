import React, { FC } from 'react';
import { useIntl } from 'estafette-intl';
import { useMutation, useQueryClient } from 'react-query';
import { Modal, Button, Icon, useForm, Space, useNotify } from 'ebs-design';
import { committees } from 'api';
import { Committee } from 'types';
import { CommitteeForm, CommitteeFormValues } from './CommitteeForm';
import { extractResponseProps } from 'utils';

export interface CommitteeModalProps {
  committee: Committee | null;
  onClose(): void;
}

export const CommitteeModal: FC<CommitteeModalProps> = ({ committee, onClose }) => {
  const { t } = useIntl();
  const queryClient = useQueryClient();
  const [committeeForm] = useForm<CommitteeFormValues>();
  const notify = useNotify();

  const createCommittee = useMutation(committees.create, {
    onSuccess: () => {
      queryClient.invalidateQueries('committees');
      onClose();
      notify.success({ title: t('committees'), description: t('success_data_save') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const updateCommittee = useMutation(committees.update, {
    onSuccess: () => {
      queryClient.invalidateQueries('committees');
      onClose();
      notify.success({ title: t('committees'), description: t('success_data_change') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const title = t(committee ? 'edit_committee' : 'add_committee');

  return (
    <Modal open={true} size="regular" onClose={onClose} title={title}>
      <Modal.Content className="px-20 py-30">
        <CommitteeForm
          committee={committee}
          form={committeeForm}
          createCommittee={createCommittee}
          updateCommittee={updateCommittee}
        />
      </Modal.Content>
      <Modal.Footer>
        <Space justify="end">
          <Button
            type="primary"
            prefix={<Icon type="check" model="bold" />}
            onClick={committeeForm.submit}
            loading={createCommittee.isLoading || updateCommittee.isLoading}
          >
            {t('save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
