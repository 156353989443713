import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useSetState } from 'react-use';
import cn from 'classnames';
import { useIntl } from 'estafette-intl';
import {
  Container,
  Table,
  Card,
  Space,
  Button,
  Icon,
  SortBy,
  Actions,
  Loader,
  Checkbox,
  useNotify,
  Badge,
} from 'ebs-design';
import { UserContext } from 'contexts';
import { useQueryParams, useQueryUpdate, usePermissions, useColumns } from 'hooks';
import { invoices, company } from 'api';
import {
  Results,
  Invoice,
  Properties,
  Column,
  InvoiceStatus,
  Permissions,
  Status,
  CompanyType,
  AttachmentType,
  SystemRole,
  CustomerRole,
} from 'types';
import {
  defaultFilters,
  getSortOptions,
  extractResponseProps,
  arrayContainsArray,
  downloadFile,
} from 'utils';
import {
  Layout,
  Pagination,
  ConfirmationModal,
  MultipleCheckBoxSelect,
  SmartSelect,
} from 'components';
import { Plus, EditFile, Download } from 'resources';

import { SearchBar } from '../SearchBar';
import { AdditionalActModal } from '../AdditionalActModal';
import { useInvoiceColumns } from '../useInvoiceColumns';
import { getSignPermission } from '../utils';
import { useInvoiceColumnsAdherent } from '../useInvoiceColumnsAdherent';

export const Invoices: React.FC = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const can = usePermissions(Permissions.INVOICES);
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const { user, userRoles } = React.useContext(UserContext);
  const { updateQuery } = useQueryUpdate();

  const [filters, setFilters] = useSetState({ ...defaultFilters, ...params });
  const [enabledSelect, setEnabledSelect] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState<Invoice | undefined>();
  const [reSign, setReSign] = React.useState<number | undefined>();

  const { data: dataCompany, isLoading: isLoadingCompany } = useQuery(
    ['company', user?.company?.id],
    () => company.get(user!.company!.id),
    {
      enabled: !!user?.company?.id,
    },
  );

  const { refetch, isLoading: isLoadingFile } = useQuery(
    ['invoices-file', filters],
    invoices.getExportFile,
    {
      enabled: false,
      onSuccess: (data) => downloadFile(data?.url, data?.name),
    },
  );

  const isAdherent = React.useMemo(
    () => arrayContainsArray(Object.keys(CustomerRole), userRoles),
    [userRoles],
  );

  const isCollector = React.useMemo(() => userRoles.includes(SystemRole.SY_COLLECTOR), [userRoles]);
  const isCreditOfficer = React.useMemo(
    () => userRoles.some((role) => [SystemRole.SY_CREDIT_OFFICER].includes(role as SystemRole)),
    [userRoles],
  );

  React.useEffect(() => {
    updateQuery(filters);
    // eslint-disable-next-line
  }, [filters]);

  React.useEffect(() => {
    setFilters(() => ({
      ...(!isAdherent && { status__exclude: InvoiceStatus.DRAFT.toLowerCase() }),
      ...(isCollector && { status: InvoiceStatus.REMAINED.toLowerCase() }),
      ...(isCreditOfficer && user && { company_management_id: user.id }),
      page: 1,
    }));
    // eslint-disable-next-line
  }, [isAdherent, isCollector, isCreditOfficer, user]);

  React.useEffect(() => {
    setFilters(() => ({ additional_act_generated: enabledSelect ? false : undefined }));
  }, [enabledSelect, setFilters]);

  const enabled = React.useMemo(() => !isAdherent || !!user?.company, [isAdherent, user]);

  const { data, isLoading } = useQuery(
    [
      'invoices',
      {
        ...filters,
        status: filters.status !== 'total' ? filters.status : undefined,
        company_id: [
          ...(isAdherent ? [user?.company?.id] : []),
          ...(filters.company_id ? [filters.company_id] : []),
        ],
      },
    ],
    invoices.getList,
    {
      enabled,
    },
  );

  const deleteInvoice = useMutation(invoices.delete, {
    onMutate: async (id: number) => {
      const query = [
        'invoices',
        {
          ...filters,
          company_id: [
            ...(isAdherent ? [user?.company?.id] : []),
            ...(filters.company_id ? [filters.company_id] : []),
          ],
        },
      ];
      const prevData = queryClient.getQueryData(query) as Results<Invoice>;

      queryClient.setQueryData(query, {
        ...prevData,
        results: prevData.results.filter((i) => i.id !== id),
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('invoices');
      notify.success({ title: t('invoices'), description: t('success_data_delete') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  const additionalAct = useMutation(company.generateAdditionalAct, {
    onSuccess: () => setShowModal(true),
  });

  React.useEffect(() => {
    if (params.signId) {
      const value = (Array.isArray(params.signId) ? params.signId : [params.signId]).map((item) =>
        parseInt(item),
      );
      openResign(value);

      updateQuery({ ...defaultFilters });
    }
    // eslint-disable-next-line
  }, [params, user]);

  const onSearch = React.useCallback(
    (search) => {
      const { search: searchValue, dataType, period, ...params } = search;
      const dataFilters: Properties = params;

      dataFilters.search = searchValue;
      dataFilters.page = 1;

      if (period && period.filter((i) => i).length) {
        dataFilters[dataType ? dataType : 'timestamp__range'] = `${period[0]}__${period[1]}`;
      }

      setFilters(dataFilters);
    },
    [setFilters],
  );

  const onToggleInvoice = React.useCallback(
    (id) => {
      setSelected(selected.includes(id) ? selected.filter((i) => i !== id) : [...selected, id]);

      if (selectAll) {
        setSelectAll(false);
      }
    },
    [selected, selectAll],
  );

  const onSelectAll = React.useCallback(() => {
    if (data) {
      setSelectAll(!selectAll);
      setSelected(
        !selectAll
          ? data.results
              .filter(
                (i) =>
                  !i.attachments.some((attachment) =>
                    [AttachmentType.ANNEX, AttachmentType.ASSIGNMENT_OF_RECEIVABLES].includes(
                      attachment.type,
                    ),
                  ) &&
                  Status[i.status] === Status.APPROVED &&
                  i.companies?.some(
                    (company) =>
                      company.types.includes(CompanyType.DEBTOR) && i.company?.id !== company.id,
                  ),
              )
              .map((item) => item.id)
          : [],
      );
    }
  }, [data, selectAll]);

  const defaultColumns = isAdherent ? useInvoiceColumnsAdherent() : useInvoiceColumns();

  const onReSign = React.useCallback(
    (id) => {
      setReSign(id);
      openResign([id]);
    },
    // eslint-disable-next-line
    [push],
  );

  const openResign = (value: number[]): void => {
    setSelected(value);

    if (user && user.company) {
      additionalAct.mutate({
        id: user.company.id,
        invoices_id: value,
        current: true,
      });
    }

    setEnabledSelect(true);
  };

  const isSystemRole = React.useMemo(
    () => userRoles.some((role) => Object.values(SystemRole).includes(role as SystemRole)),
    [userRoles],
  );

  const columns: Column<Invoice>[] = React.useMemo(
    () => [
      ...(enabledSelect
        ? [
            {
              title: (
                <Checkbox
                  checked={selectAll}
                  onChange={onSelectAll}
                  disabled={additionalAct.isLoading}
                />
              ),
              width: 20,
              render: ({ id, ...props }) => {
                const { canSelect, canReSign, total, remaining } = getSignPermission(
                  props,
                  isSystemRole,
                );

                return canSelect ? (
                  <Checkbox
                    checked={selected.includes(id)}
                    onChange={() => onToggleInvoice(id)}
                    disabled={additionalAct.isLoading}
                  />
                ) : canReSign ? (
                  <Badge type="danger" count={total - remaining}>
                    <Button
                      className="pc-btn-icon"
                      loading={reSign === id && additionalAct.isLoading}
                      onClick={() => onReSign(id)}
                    >
                      <Icon component={EditFile} />
                    </Button>
                  </Badge>
                ) : null;
              },
            },
          ]
        : []),
      ...defaultColumns,
      {
        title: null,
        action: true,
        render: (props) => {
          return (
            <Actions>
              <Actions.Item onClick={() => push(`/invoices/${props.id}`)}>
                {t('details')}
              </Actions.Item>
              {can.edit?.invoice && (
                <Actions.Item onClick={() => push(`/invoices/${props.id}/edit`)}>
                  {t('edit')}
                </Actions.Item>
              )}
              {can.delete?.invoice && (
                <Actions.Item onClick={() => setConfirmDelete(props)}>{t('delete')}</Actions.Item>
              )}
            </Actions>
          );
        },
      },
    ],
    [
      t,
      push,
      can,
      enabledSelect,
      defaultColumns,
      selected,
      selectAll,
      reSign,
      isSystemRole,
      additionalAct.isLoading,
      onSelectAll,
      onToggleInvoice,
      onReSign,
    ],
  );

  const {
    onChange: onColumnsChange,
    getOptions,
    columns: selectedColumns,
  } = useColumns('invoices', columns);

  const sortOptions = React.useMemo(() => getSortOptions(columns), [columns]);

  const hasCompany = React.useMemo(() => !!user?.company, [user]);

  const hasSignedContract = React.useMemo(() => {
    return Boolean(
      dataCompany?.contract?.attachment?.signatures?.find((i) =>
        Boolean(i.user.roles.find((i) => i.name in CustomerRole)),
      ),
    );
  }, [dataCompany]);

  const hasPFSignContract = React.useMemo(() => {
    return Boolean(
      dataCompany?.contract?.attachment?.signatures?.find((i) =>
        Boolean(i.user.roles.find((i) => i.name in SystemRole)),
      ),
    );
  }, [dataCompany]);

  const onClickAdd = React.useCallback(() => push('/invoice/create'), [push]);

  const onClickToggleAdditionalAct = React.useCallback(() => {
    setEnabledSelect(!enabledSelect);

    if (!enabledSelect) {
      setSelectAll(false);
      setSelected([]);
    }
  }, [enabledSelect]);

  const onCreateAdditionalAct = React.useCallback(() => {
    if (user && user.company) {
      additionalAct.mutate({
        id: user.company.id,
        invoices_id: selected,
      });
    }
  }, [selected, additionalAct, user]);

  const onAbortDelete = () => setConfirmDelete(undefined);

  const onConfirmDelete = React.useCallback(() => {
    deleteInvoice.mutate(confirmDelete!.id);

    onAbortDelete();
  }, [deleteInvoice, confirmDelete]);

  const confirmDeleteTitle = React.useMemo(
    () => `${t('invoice')} ${confirmDelete?.number}`,
    [t, confirmDelete],
  );

  const onModalCancel = () => {
    setSelected([]);
    setShowModal(false);
  };

  return (
    <Layout>
      <SearchBar onSearch={onSearch} />
      <Container>
        <Space wrap align="center" justify="space-between" className="mt-20 mb-20">
          <Space wrap align="center">
            <h3 className="page-title">
              {t('invoices')} ({data?.count || 0})
            </h3>

            {!enabledSelect ? (
              !isCollector && (
                <SmartSelect
                  options={Object.values(InvoiceStatus)
                    .filter((status) => (isAdherent ? true : status !== InvoiceStatus.DRAFT))
                    .map((item) => {
                      const value = item.toLowerCase();

                      return { value, text: t(value) };
                    })}
                  value={filters.status}
                  onChange={(status) => setFilters(() => ({ status, page: 1 }))}
                  placeholder={t('select_status')}
                  className="select-min-width"
                />
              )
            ) : (
              <Button
                type="fill"
                onClick={onCreateAdditionalAct}
                loading={!reSign && additionalAct.isLoading}
                disabled={!selected.length}
              >
                {t('create')}
              </Button>
            )}
          </Space>
          <Space wrap align="center">
            {can.create?.additionalAct && hasCompany && hasSignedContract && hasPFSignContract ? (
              <Button
                type={enabledSelect ? 'primary' : 'fill'}
                onClick={onClickToggleAdditionalAct}
              >
                {t(enabledSelect ? 'cancel' : 'create_additional_act')}
              </Button>
            ) : null}

            <MultipleCheckBoxSelect
              options={getOptions(columns)}
              placeHolder={t('choose_column')}
              label="title"
              callback={onColumnsChange}
            />

            <SortBy
              options={[
                { value: 'company_title', title: t('adherent') },
                { value: 'debtor_title', title: t('debtor') },
                ...sortOptions,
              ]}
              value={filters?.ordering}
              onChange={(ordering) => setFilters({ ordering })}
            />

            {can.create?.invoice && (
              <Button
                type="primary"
                prefix={<Icon component={Plus} />}
                loading={isLoadingCompany}
                onClick={onClickAdd}
                disabled={!hasCompany || enabledSelect || !hasSignedContract || !hasPFSignContract}
              >
                {t('add_new')}
              </Button>
            )}

            {can?.perform?.export && (
              <Button
                onClick={refetch}
                loading={isLoadingFile}
                prefix={<Icon component={Download} />}
              >
                {t('export_file')}
              </Button>
            )}
          </Space>
        </Space>
        <Card>
          <Card.Body className="p-0">
            <Loader loading={isLoading}>
              <Table
                className={cn('table-no-border', { 'disable--requested': enabledSelect })}
                columns={selectedColumns}
                data={enabled ? data?.results : []}
                emptyCell="---"
              />
            </Loader>
          </Card.Body>
          <Card.Footer>
            <Pagination data={enabled ? data : {}} filters={filters} setFilters={setFilters} />
          </Card.Footer>
        </Card>
      </Container>

      <AdditionalActModal
        open={showModal}
        selected={selected}
        data={additionalAct.data}
        isLoading={additionalAct.isLoading}
        isSystemRole={isSystemRole}
        onClose={onModalCancel}
      />

      {confirmDelete && (
        <ConfirmationModal
          title={confirmDeleteTitle}
          description={t('are_you_sure_to_want_delete')}
          onConfirm={onConfirmDelete}
          onCancel={onAbortDelete}
          visible={!!confirmDelete}
        />
      )}
    </Layout>
  );
};
