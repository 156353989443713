import { User } from './users';
import { FactoringRange } from './nomenclature';

export enum CommitteeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Committee {
  id: number;
  factoring_range: FactoringRange;
  users: Partial<User>[];
  timestamp: string;
  edited_timestamp: string;
  status: CommitteeStatus;
  title: string;
  description: string;
  votes_required: number;
  users_id: number[];
}
