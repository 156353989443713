import * as React from 'react';
import { useClickAway } from 'react-use';
import cn from 'classnames';
import useKeyPress from 'react-use/esm/useKeyPress';
import { Input, Icon } from 'ebs-design';
import { NO_DATA_PLACEHOLDER } from 'utils';

interface Props {
  defaultValue?: string | number;
  onChange: (value: string) => void;
  extra?: string | number;
}

export const InlineEdit: React.FC<Props> = ({ defaultValue = '0', onChange, extra }) => {
  const refContainer = React.useRef<HTMLDivElement | null>(null);
  const ref = React.useRef(null);

  const [isPressedEnter] = useKeyPress('Enter');
  const [visible, setVisible] = React.useState(false);
  const [value, setValue] = React.useState<string | number | null>(null);

  const val = React.useMemo(
    () => ([NO_DATA_PLACEHOLDER, '0'].includes(defaultValue as string) ? null : defaultValue),
    [defaultValue],
  );

  const onVisibilityChange = (state: boolean): void => {
    if (!state && value !== val) {
      onChange(value as string);
    }

    setVisible(state);
  };

  React.useEffect(() => {
    if (visible) {
      setValue(val !== NO_DATA_PLACEHOLDER ? val : '');
    }
  }, [val, visible]);

  React.useEffect(() => {
    if (isPressedEnter && visible) {
      if (value !== val) {
        onChange(value as string);
      }

      setVisible(false);
    }
  }, [isPressedEnter, visible, onChange]);

  useClickAway(ref, () => {
    onVisibilityChange(false);
  });

  return (
    <div
      ref={refContainer}
      className={cn('inline-edit', { 'inline-edit--active': visible })}
      onClick={() => onVisibilityChange(true)}
    >
      {visible ? (
        <Input
          ref={ref}
          type={typeof defaultValue === 'number' ? 'number' : 'text'}
          value={value || ''}
          onChange={setValue}
          autoFocus
        />
      ) : (
        <>
          <Icon type="edit" />
          {defaultValue}
          {extra && extra}
        </>
      )}
    </div>
  );
};
