import React, { FC, useMemo, useState, createRef, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useIntl } from 'estafette-intl';
import { Form, FormInstance, Input, Table, Label, Button, Icon, useNotify } from 'ebs-design';
import { nomenclature } from 'api';
import { Unit, Classifier, ANNEX, Column } from 'types';
import { SmartSelect } from 'components';
import { Delete } from 'resources';
import { extractResponseProps } from 'utils';

import { getInitialValues } from '../ClassifierForm/utils';

export interface IndicatorsFormValues {
  title: string;
  formulae: string;
  maximum: string;
  minimum: string;
  minimum_operator: '>' | '>=';
  maximum_operator: '<' | '<=';
  _units: Unit | number;
  score: string;
  categories: {
    maximum: string;
    minimum: string;
    minimum_operator: '>' | '>=';
    maximum_operator: '<' | '<=';
    _units: Unit | number;
  }[];
}

export interface IndicatorsFormProps {
  form: FormInstance;
  closeModal(): void;
  classifier: Classifier | null;
}

export const IndicatorsForm: FC<IndicatorsFormProps> = ({ form, closeModal, classifier }) => {
  const { t } = useIntl();
  const ref = createRef<HTMLDivElement>();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [data, setData] = useState(Object.entries(classifier?.indicators || []));

  const updateClassifier = useMutation(nomenclature.updateClassifier, {
    onSuccess: () => {
      queryClient.invalidateQueries('classifiers');
      closeModal();
      notify.success({ title: t('classifiers'), description: t('success_data_change') });
    },
    onError: (err) => {
      extractResponseProps(err, (title, description) =>
        notify.error({ title: t(title), description: t(description) }),
      );
    },
  });

  useEffect(() => {
    if (data) {
      ref.current?.scrollTo(0, ref.current.getElementsByTagName('form')[0].offsetHeight);
    }
  }, [ref, data]);

  const sourceOptions = useMemo(
    () => Object.keys(ANNEX).map((item) => ({ value: item, text: item.replace('_', ' ') })),
    [],
  );

  const columns: Column[] = useMemo(
    () => [
      {
        title: t('key'),
        width: 100,
        render: ([i]) => (
          <Form.Field name={['i', i]} initialValue={i.split('i')[1]}>
            <Input
              type="number"
              prefix={<Label text="i" type="ghost" status="danger" disabled />}
            />
          </Form.Field>
        ),
      },
      {
        title: t('row'),
        render: ([i, item]) => {
          return (
            <Form.Field name={['row', i]} initialValue={item.row.toString()}>
              <Input type="number" />
            </Form.Field>
          );
        },
      },
      {
        title: t('source'),
        render: ([i, item]) => {
          return (
            <Form.Field name={['source', i]} initialValue={item.source}>
              <SmartSelect options={sourceOptions} rootRef={ref} />
            </Form.Field>
          );
        },
      },
      {
        width: 40,
        render: ([i]) => {
          return (
            <Button
              type="ghost"
              onClick={() => setData((items) => items.filter(([key]) => key !== i))}
            >
              <Icon component={Delete} />
            </Button>
          );
        },
      },
    ],
    [t, sourceOptions, ref],
  );

  const maxI = useMemo(
    () =>
      data.length
        ? parseInt(
            data
              .reduce(([i, prevItem], [y, item]) =>
                parseInt(i.split('i')[1]) > parseInt(y.split('i')[1]) ? [i, prevItem] : [y, item],
              )[0]
              .split('i')[1],
          )
        : 0,
    [data],
  );
  const initialValues = useMemo(() => getInitialValues(classifier), [classifier]);

  const onAdd = () => {
    setData((items) => [...items, [`i${maxI + 1}`, { row: 0, source: ANNEX.ANNEX_1 }]]);
  };

  const onSubmit = (data) => {
    const indicators = {};
    Object.keys(data['i']).forEach((indicator) => {
      indicators[indicator] = {
        row: parseInt(data['row'][indicator]),
        source: data['source'][indicator],
      };
    });

    if (classifier) {
      updateClassifier.mutate([classifier.id, { indicators }]);
    }
  };

  return (
    <>
      <div className="pc-modal__indicators" ref={ref}>
        <Form form={form} onFinish={onSubmit} initialValues={initialValues}>
          <Table
            data={data}
            columns={columns}
            className="pc-classifier-form__categories__table table-no-border"
          />
        </Form>
      </div>
      <Button type="fill" className="full-width no-border" onClick={onAdd}>
        {t('add')}
      </Button>
    </>
  );
};
